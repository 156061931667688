<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">
            <!-- <div class="col-12 mt-4">
                <el-card  class="box-card">
                    <fieldset class="border p-2 mt-2">
                            <legend class="w-auto badge"><h5>Progress de Projet</h5></legend>
                            <el-progress   el-progress :text-inside="true" :stroke-width="25" :percentage="55"></el-progress>
                    </fieldset>
                </el-card>
            </div> -->
            
            <div class="col-12 mt-4">
                <el-card  class="box-card">
                    <fieldset class="border p-2 mt-2">
                            <legend class="w-auto badge"><h5>Informations de Projet</h5></legend>
                             <el-descriptions border v-for="c in Projet" :key="c.id">
                                <el-descriptions-item><template slot="label"><strong>Nom D'étiquette :</strong></template> {{c.get_devis_e_t_c.nom_etc}}</el-descriptions-item>
                                <el-descriptions-item ><template slot="label"><strong>Client :</strong></template> {{c.get_client.name}}</el-descriptions-item>
                                <el-descriptions-item ><template slot="label"><strong>Format en mm :</strong></template> {{c.get_devis_e_t_c.formatMM_L}}x{{c.get_devis_e_t_c.formatMM_D}} </el-descriptions-item>
                                <el-descriptions-item ><template slot="label"><strong>Form d'étiquette :</strong></template> {{c.get_devis_e_t_c.forme_etq}} </el-descriptions-item>
                                <el-descriptions-item ><template slot="label"><strong>Support :</strong></template> {{c.get_devis_e_t_c.support_papier}} </el-descriptions-item>
                                
                                <el-descriptions-item ><template slot="label"><strong>Impression :</strong></template> {{c.get_devis_e_t_c.impression}}</el-descriptions-item>
                                <el-descriptions-item ><template slot="label"><strong>Nombre Couleurs :</strong></template>{{c.get_devis_e_t_c.nbr_couleur}} </el-descriptions-item>
                                <el-descriptions-item ><template slot="label"><strong>Quantité :</strong></template> {{c.get_devis_e_t_c.quantite_etq}} </el-descriptions-item>
                                <el-descriptions-item ><template slot="label"><strong>Recto :</strong></template> {{c.get_devis_e_t_c.recto}} </el-descriptions-item>
                                <el-descriptions-item ><template slot="label"><strong>Verso :</strong></template> {{c.get_devis_e_t_c.verso}} </el-descriptions-item>

                                <el-descriptions-item ><template slot="label"><strong>Repiquage :</strong></template> {{c.get_devis_e_t_c.repiquage}} </el-descriptions-item>
                                <el-descriptions-item ><template slot="label"><strong>Vernis :</strong></template> {{c.get_devis_e_t_c.vernis}} </el-descriptions-item>
                                <el-descriptions-item ><template slot="label"><strong>Plastification :</strong></template> {{c.get_devis_e_t_c.plastification}} </el-descriptions-item>
                                <el-descriptions-item ><template slot="label"><strong>Dorure :</strong></template> {{c.get_devis_e_t_c.dorure}} </el-descriptions-item>
                                <el-descriptions-item ><template slot="label"><strong>Mandrin :</strong></template> {{c.get_devis_e_t_c.mandrin}} </el-descriptions-item>

                                <el-descriptions-item ><template slot="label"><strong>Nombre Etq/Front :</strong></template> {{c.get_devis_e_t_c.nbr_etq_front}} </el-descriptions-item>
                                <el-descriptions-item ><template slot="label"><strong>Nombre Etq/Bobine :</strong></template> {{c.get_devis_e_t_c.nbr_etq_bobine}} </el-descriptions-item>
                                <el-descriptions-item ><template slot="label"><strong>Poses Etiquette :</strong></template> {{c.get_devis_e_t_c.poses_etq}} </el-descriptions-item>
                                <el-descriptions-item ><template slot="label"><strong>Form De Decoupe :</strong></template> {{c.get_devis_e_t_c.form_decoupe}} </el-descriptions-item>
                                <el-descriptions-item ><template slot="label"><strong>Films et Cliches :</strong></template> {{c.get_devis_e_t_c.films_cliches}} </el-descriptions-item>
                                <el-descriptions-item ><template slot="label"><strong>Sens De Sortie :</strong></template> {{c.get_devis_e_t_c.type_sense_sortie}} </el-descriptions-item>
                            </el-descriptions>
                    </fieldset>
                    
                </el-card>
            </div>
            <!-- Tabse -->
            <div class="col-12 mt-4 mb-4">
                
                <el-tabs tab-position="top" v-for="c in Projet" :key="c.id" label="Plant De Forme De Decoupe" @tab-click="getTypeSense" style="margin-bottom: 30px;">
                    <el-tab-pane v-if="c.get_devis_e_t_c.form_decoupe == 'Non'" label="Plan de forme de decoupe (PFD)">
                         <el-card  class="box-card">
                            <div slot="header" class="clearfix text-center">
                                <h4><strong>PLAN DE FORME DE DECOUPE</strong></h4>
                            </div>
                            <div class="row">

                            <div class="col-3">
                                <fieldset class="border p-2">
                                    <legend class="w-auto badge"><h6><strong> </strong></h6></legend>
                                    <el-descriptions border :column="1">
                                        <el-descriptions-item ><template slot="label"><strong>Format en mm :</strong></template> {{c.get_devis_e_t_c.formatMM_L}}x{{c.get_devis_e_t_c.formatMM_D}} </el-descriptions-item>
                                        <el-descriptions-item ><template slot="label"><strong>Machine:</strong></template> {{c.get_devis_e_t_c.Machine}} </el-descriptions-item>
                                        <el-descriptions-item ><template slot="label"><strong>Z Cylindre:</strong></template> {{c.get_devis_e_t_c.Z_cylindre}} </el-descriptions-item>
                                        <el-descriptions-item ><template slot="label"><strong>Nombre de pose Laize :</strong></template> {{c.get_devis_e_t_c.Nombre_PosesL}} </el-descriptions-item>
                                        <el-descriptions-item ><template slot="label"><strong>Nombre de pose Developpe :</strong></template> {{c.get_devis_e_t_c.nombre_PosesD}} </el-descriptions-item>
                                        <el-descriptions-item ><template slot="label"><strong>Espace laize (mm) :</strong></template> {{c.get_devis_e_t_c.espace_L}} </el-descriptions-item>
                                        <el-descriptions-item ><template slot="label"><strong>Anamorphose :</strong></template> {{c.get_devis_e_t_c.anamorphose}} </el-descriptions-item>
                                        <el-descriptions-item ><template slot="label"><strong>Developpement Calculer (mm) :</strong></template> {{c.get_devis_e_t_c.espace_D}} </el-descriptions-item>
                                        <el-descriptions-item ><template slot="label"><strong>Espace Developpement Calculer (mm) :</strong></template> {{c.get_devis_e_t_c.developer_Calculer}} </el-descriptions-item>
                                        <el-descriptions-item ><template slot="label"><strong>Support (Papier) :</strong></template> {{c.get_devis_e_t_c.support_papier}} </el-descriptions-item>
                                     </el-descriptions>
                                </fieldset>
                            </div>
                            <!--  -->
                                <div class="col-9">
                                    <fieldset class="border p-2">
                                                        <legend class="w-auto badge"><h6><strong> Désign Form de Decoupe  </strong></h6></legend>
                                        <div class="row">
                                            <div class="col-12">
                                                
                                                <div class="demo-image__preview text-center">
                                                    <el-image
                                                        :src="`/upload/Profile/FormDecoupe_${idProjet}.png`"
                                                        >
                                                    </el-image>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                   
                                                    <el-upload
                                                        class="upload-demo"
                                                        ref="upload"
                                                        action=""
                                                        :file-list="file"
                                                        :on-change="onChangeFormDecoupe"
                                                        :auto-upload="false">
                                                        <el-button slot="trigger" size="small" type="primary">Séléctionner la forme de decoupe</el-button>
                                                    </el-upload>
                                            </div>
                                            
                                            <div class="col-6">
                                                <el-button  size="small" class="float-right"  type="success" icon="el-icon-upload" @click="submitForm">Valider</el-button>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                                <!--  -->

                                <div class="col-12">
                                    <el-form label-position="top" :model="Remarquesform" >
                                    <fieldset class="border p-2">

                                        <legend class="w-auto badge badge"><h6><strong> Remarque </strong> </h6></legend>
                                        <el-form-item>
                                            <el-input :autosize="{ minRows: 5}" placeholder="Votre Remarque ici" type="textarea" v-model="Remarquesform.message"></el-input>
                                        </el-form-item>
                                        <el-form-item>
                                            <el-button type="primary" style="float: right;" size="small" @click="SendRemarque('FormDecoupe')">Ajouter Remarque</el-button>
                                        </el-form-item>

                                    </fieldset>
                                    
                                    </el-form>
                                     <fieldset class="border p-2">
                                        <legend class="w-auto badge badge"><h6><strong>  </strong> </h6></legend>
                                        <div class="block">
                                            <el-timeline v-for="r in Remarques.RemarquesFormDecoupe" :key="r.id">
                                                <el-timeline-item>
                                                    <el-card>
                                                        <div class="row">
                                                            <div class="col-12 text-right">
                                                                <el-button style="float: right; padding: 3px 0" v-if="$gate.isAdmin()" @click="deletRemarque(r.id)" data-toggle="tooltip" data-placement="top" title="Supprimer" type="text"><i class="fa fa-trash red"></i></el-button>
                                                            </div>
                                                            <div class="col-1">
                                                                <el-avatar icon="el-icon-user-solid"></el-avatar>
                                                            </div>
                                                            <div class="col-11">
                                                                <h6><strong>{{r.get_user.name}}</strong></h6>
                                                                <p>{{r.message}}</p>
                                                            </div>
                                                            <div class="col-12 text-right">
                                                                {{r.created_at | RemarqueDates}}
                                                            </div>
                                                        </div>
                                                    </el-card>
                                                </el-timeline-item>
                                            </el-timeline>
                                            </div>
                                     </fieldset>
                                     <el-form label-position="top" :model="form2" >
                                    <fieldset class="border p-2">
                                        <legend class="w-auto badge badge"><h6><strong> Validation </strong> </h6></legend>
                                        <div class="row">
                                            <el-form-item 
                                                class="text-center col-lg-6"
                                                prop="validation1"
                                                label="Commercial :"
                                            >
                                            
                                                <el-switch
                                                    v-model="form2.validation1"
                                                    active-value="1"
                                                    inactive-value="0"
                                                    active-color="#13ce66"
                                                    inactive-color="#ff4949"
                                                    inactive-text="Invalide"
                                                    active-text="Valide"
                                                >
                                                </el-switch>
                                            </el-form-item>
                                            <div class="col-12 text-right">
                                                <el-button type="primary" style="float: right;" size="small" @click="ValidationPFD()">Valider PFD</el-button>
                                            </div>
                                        </div>
                                     </fieldset>
                                    </el-form>
                                </div>
                            </div>
                         </el-card>
                        <!-- </el-card> -->
                    </el-tab-pane>
                    <!-- Plaque -->
                    <el-tab-pane v-if="c.get_devis_e_t_c.form_decoupe == 'Non'" label="Plaque">
                        <el-card class="box-card">
                            <div slot="header" class="clearfix text-center">
                                <h4><strong>PLAQUE</strong></h4>
                            </div>
                            <div class="row">
                                <!--  -->
                                <fieldset class=" col-12 border p-2 text-center">
                                    <legend class="w-auto badge badge"><h6><strong> Demande de devis form decoupe </strong></h6></legend>
                                    
                                        <el-button type="primary" size="small" icon="el-icon-file" @click="DemandeDevisFD" plain>Demande de devis (FDP)</el-button>
                                        <el-button type="success" size="small" icon="el-icon-file" @click="ValiderDevisFD" plain>Validation de devis (FDP)</el-button>
                                        <!-- show file get_e_t_c get_fournisseur-->
                                        <div class="row" >
                                            <div class="col-12">
                                                <div class="card-box">
                                                    <div class="row" >
                                                        <div class="col-lg-4" v-for="c in devispfd" :key="c.id">
                                                            <div class="file-man-box"><a @click="deleteDevis(c.id)" class="file-close"><i class="fa fa-times-circle"></i></a>
                                                                <div class="file-img-box">
                                                                    <img v-if="c.type_de_demande != 3" src="/images/pdf_red.png" alt="icon">
                                                                    <img v-else src="/images/pdf_green.png" alt="icon">
                                                                </div><a @click="printC(c.id)" class="file-download"><i class="fa fa-download"></i></a>
                                                                <div v-if="c.type_de_demande == 1" class="file-man-title" >
                                                                    <h5 class="mb-0 text-overflow">Demande_de_prix.pdf<br>({{c.get_fournisseur.name}})</h5>
                                                                    <p></p>
                                                                </div>
                                                                <div v-else-if="c.type_de_demande == 2" class="file-man-title" >
                                                                    <h5 class="mb-0 text-overflow">Demande_de_reduction_prix.pdf<br>({{c.get_fournisseur.name}})</h5>
                                                                    <p></p>
                                                                </div>
                                                                <div v-else class="file-man-title" >
                                                                    <h5 class="mb-0 text-overflow">Bon_de_commande.pdf<br>({{c.get_fournisseur.name}})</h5>
                                                                    <p></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- end col -->
                                        </div>
                                        <!-- End show file -->
                                </fieldset>
                                <fieldset class=" col-12 border p-2 text-center">
                                    <legend class="w-auto badge badge"><h6><strong> Suivi De Commande Form Decoupe </strong></h6></legend>
                                    
                                        <el-button type="primary" size="small" icon="el-icon-file" @click="joindreBLPlaque" plain>Joindre Bon De Livraison (BL)</el-button>
                                        <!-- show file get_e_t_c get_fournisseur-->
                                        <div class="row" >
                                            <div class="col-12">
                                                <div class="card-box">
                                                    <div class="row" >
                                                        <h3>pdf here</h3>
                                                        <!-- <div class="col-lg-4" v-for="c in devispfd" :key="c.id">
                                                            <div class="file-man-box"><a @click="deleteDevis(c.id)" class="file-close"><i class="fa fa-times-circle"></i></a>
                                                                <div class="file-img-box">
                                                                    <img v-if="c.type_de_demande != 3" src="/images/pdf_red.png" alt="icon">
                                                                    <img v-else src="/images/pdf_green.png" alt="icon">
                                                                </div><a @click="printC(c.id)" class="file-download"><i class="fa fa-download"></i></a>
                                                                <div v-if="c.type_de_demande == 1" class="file-man-title" >
                                                                    <h5 class="mb-0 text-overflow">Demande_de_prix.pdf<br>({{c.get_fournisseur.name}})</h5>
                                                                    <p></p>
                                                                </div>
                                                                <div v-else-if="c.type_de_demande == 2" class="file-man-title" >
                                                                    <h5 class="mb-0 text-overflow">Demande_de_reduction_prix.pdf<br>({{c.get_fournisseur.name}})</h5>
                                                                    <p></p>
                                                                </div>
                                                                <div v-else class="file-man-title" >
                                                                    <h5 class="mb-0 text-overflow">Bon_de_commande.pdf<br>({{c.get_fournisseur.name}})</h5>
                                                                    <p></p>
                                                                </div>
                                                            </div>
                                                        </div> -->
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- end col -->
                                        </div>
                                        <!-- End show file -->
                                </fieldset>
                                <fieldset class=" col-6 border p-2 text-center">
                                    <legend class="w-auto badge badge"><h6><strong> Plaque </strong></h6></legend>
                                    
                                        <el-button type="primary" size="small" icon="el-icon-plus" @click="newModalP" plain>Ajouter Nouveau Plaque old</el-button>
                                        <el-button type="primary" size="small" icon="el-icon-plus" @click="newPlaque" plain>Ajouter Nouveau Plaque</el-button>
                                    
                                </fieldset>
                            </div>
                        </el-card>
                    </el-tab-pane>
                    <!-- Conception -->
                    <el-tab-pane v-if="c.get_devis_e_t_c.impression == 'OUI' && c.get_devis_e_t_c.films_cliches == 'Non'" label="Conception d'étiquette">
                        <el-card class="box-card">
                            <div slot="header" class="clearfix text-center">
                                <h4><strong>CONCEPTION</strong></h4>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                <fieldset class="border p-2">
                                    <legend class="w-auto badge"><h6><strong> </strong></h6></legend>
                                        <el-descriptions border :column="1" v-for="c in Projet" :key="c.id">
                                            <el-descriptions-item ><template slot="label"><strong>Format en mm :</strong></template> {{c.get_devis_e_t_c.formatMM_L}}x{{c.get_devis_e_t_c.formatMM_D}} </el-descriptions-item>
                                            <el-descriptions-item ><template slot="label"><strong>Z Cylindre:</strong></template> {{c.get_devis_e_t_c.Z_cylindre}} </el-descriptions-item>
                                            <el-descriptions-item ><template slot="label"><strong>Nombre de pose Laize :</strong></template> {{c.get_devis_e_t_c.Nombre_PosesL}} </el-descriptions-item>
                                            <el-descriptions-item ><template slot="label"><strong>Nombre de pose Developpe :</strong></template> {{c.get_devis_e_t_c.nombre_PosesD}} </el-descriptions-item>
                                            <el-descriptions-item ><template slot="label"><strong>Espace laize (mm) :</strong></template> {{c.get_devis_e_t_c.espace_L}} </el-descriptions-item>
                                            <el-descriptions-item ><template slot="label"><strong>Espace devloppe (mm) :</strong></template> {{c.get_devis_e_t_c.espace_D}} </el-descriptions-item>
                                            <el-descriptions-item ><template slot="label"><strong>Support (Papier) :</strong></template> {{c.get_devis_e_t_c.support_papier}} </el-descriptions-item>
                                        </el-descriptions>
                                </fieldset>
                            </div>
                                <!--  -->
                                <div class="col-6">
                                    <fieldset class="border p-2">
                                                <legend class="w-auto badge"><h6><strong> Conception Image  </strong></h6></legend>
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="demo-image__preview text-center">
                                                <el-image
                                                    :src="`/upload/Profile/Conception_${idProjet}.png`"
                                                    >
                                                </el-image>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                                <el-upload
                                                    class="upload-demo"
                                                    ref="upload"
                                                    action=""
                                                    :file-list="file"
                                                    :on-change="onChangeConception"
                                                    :auto-upload="false">
                                                    <el-button slot="trigger" size="small" type="primary">Séléctionner l'image de Conception</el-button>
                                                    <!-- <div class="el-upload__tip" slot="tip">jpg/png files with a size less than 500kb</div> -->
                                                </el-upload>
                                        </div>
                                        
                                        <div class="col-6">
                                            <el-button  size="small" class="float-right" v-if="file != ''" type="success" icon="el-icon-upload" @click="submitForm">Valider</el-button>
                                        </div>
                                    </div>
                                    </fieldset>
                                </div>
                                        <div class="col-12">
                                        <el-form :model="Remarquesform" >
                                        <fieldset class="border p-2">

                                            <legend class="w-auto badge badge"><h6><strong> Remarque </strong> </h6></legend>
                                            <el-form-item >
                                                <el-input :autosize="{ minRows: 5}" placeholder="Votre Remarque ici" type="textarea" v-model="Remarquesform.message"></el-input>
                                            </el-form-item>
                                            <el-form-item>
                                                <el-button type="primary" style="float: right;" size="small" @click="SendRemarque('Conception')">Ajouter Remarque</el-button>
                                            </el-form-item>

                                        </fieldset>
                                        </el-form>
                                        <fieldset class="border p-2">
                                            <legend class="w-auto badge badge"><h6><strong>  </strong> </h6></legend>
                                            <div class="block">
                                                <el-timeline v-for="r in Remarques.RemarquesConception" :key="r.id">

                                                    <el-timeline-item>
                                                        <el-card>
                                                            <div class="row">
                                                                <div class="col-12 text-right">
                                                                    <el-button style="float: right; padding: 3px 0" v-if="$gate.isAdmin()" @click="deletRemarque(r.id)" data-toggle="tooltip" data-placement="top" title="Supprimer" type="text"><i class="fa fa-trash red"></i></el-button>
                                                                </div>
                                                                <div class="col-1">
                                                                    <el-avatar icon="el-icon-user-solid"></el-avatar>
                                                                </div>
                                                                <div class="col-11">
                                                                    <h6><strong>{{r.get_user.name}}</strong></h6>
                                                                    <p>{{r.message}}</p>
                                                                </div>
                                                                <div class="col-12 text-right">
                                                                    {{r.created_at | RemarqueDates}}
                                                                </div>
                                                            </div>
                                                        </el-card>
                                                    </el-timeline-item>
                                                    
                                                </el-timeline>
                                                </div>
                                        </fieldset>
                                    </div>
                                <!--  -->
                            </div>
                        </el-card>
                    </el-tab-pane>
                    <!-- BAT -->
                    <el-tab-pane v-if="c.get_devis_e_t_c.impression == 'OUI' && c.get_devis_e_t_c.films_cliches == 'Non'" label="Bon A Tirer (BAT)" >
                         <el-card class="box-card">
                            <div slot="header" class="clearfix text-center">
                                <h4><strong>BON A TIRER (BAT)</strong></h4>
                            </div>
                            <div class="row">
                            <div class="col-6">
                            <fieldset class="border p-2">
                                <legend class="w-auto badge"><h6><strong> </strong></h6></legend>
                                
                            <div class="row" v-for="c in Projet" :key="c.id">
                                <el-descriptions border :column="1" class="col-12">
                                    <el-descriptions-item ><template slot="label"><strong>Format en mm :</strong></template> {{c.get_devis_e_t_c.formatMM_L}}x{{c.get_devis_e_t_c.formatMM_D}} </el-descriptions-item>
                                    <el-descriptions-item ><template slot="label"><strong>Nombre Couleurs:</strong></template> {{c.get_devis_e_t_c.nbr_clouleur}} </el-descriptions-item>
                                    <el-descriptions-item ><template slot="label"><strong>Mandrin :</strong></template> {{c.get_devis_e_t_c.mandrin}} </el-descriptions-item>
                                    <el-descriptions-item ><template slot="label"><strong>Quantité Etq/Bobine :</strong></template> {{c.get_devis_e_t_c.nbr_etq_bobine}} </el-descriptions-item>
                                    <el-descriptions-item ><template slot="label"><strong>Support (Papier) :</strong></template> {{c.get_devis_e_t_c.support_papier}} </el-descriptions-item>
                                </el-descriptions>
                            </div>
                            </fieldset>
                        </div>
                            <!--  -->
                            <div class="col-6">
                                <fieldset class="border p-2">
                                            <legend class="w-auto badge"><h6><strong> BAT Image  </strong></h6></legend>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="demo-image__preview text-center">
                                            <el-image
                                                :src="`/upload/Profile/BAT_${idProjet}.png`"
                                                >
                                            </el-image>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                            <el-upload
                                                class="upload-demo"
                                                ref="upload"
                                                action=""
                                                :file-list="file"
                                                :on-change="onChangeBAT"
                                                :auto-upload="false">
                                                <el-button slot="trigger" size="small" type="primary">Séléctionner l'image de BAT</el-button>
                                                <!-- <div class="el-upload__tip" slot="tip">jpg/png files with a size less than 500kb</div> -->
                                            </el-upload>
                                    </div>
                                    
                                    <div class="col-6">
                                        <el-button  size="small" class="float-right" v-if="file != ''" type="success" icon="el-icon-upload" @click="submitForm">Valider</el-button>
                                    </div>
                                </div>
                                </fieldset>
                            </div>
                                    <div class="col-12">
                                        <el-form :model="Remarquesform" >
                                        <fieldset class="border p-2">

                                            <legend class="w-auto badge badge"><h6><strong> Remarque </strong> </h6></legend>
                                            <el-form-item >
                                                <el-input :autosize="{ minRows: 5}" placeholder="Votre Remarque ici" type="textarea" v-model="Remarquesform.message"></el-input>
                                            </el-form-item>
                                            <el-form-item>
                                                <el-button type="primary" style="float: right;" size="small" @click="SendRemarque('BAT')">Ajouter Remarque</el-button>
                                            </el-form-item>

                                        </fieldset>
                                        </el-form>
                                        <fieldset class="border p-2">
                                            <legend class="w-auto badge badge"><h6><strong>  </strong> </h6></legend>
                                            <div class="block">
                                                <el-timeline v-for="r in Remarques.RemarquesBAT" :key="r.id">

                                                    <el-timeline-item>
                                                        <el-card>
                                                            <div class="row">
                                                                <div class="col-12 text-right" v-if="$gate.isAdmin()">
                                                                    <el-button style="float: right; padding: 3px 0" @click="deletRemarque(r.id)" data-toggle="tooltip" data-placement="top" title="Supprimer" type="text"><i class="fa fa-trash red"></i></el-button>
                                                                </div>
                                                                <div class="col-1">
                                                                    <el-avatar icon="el-icon-user-solid"></el-avatar>
                                                                </div>
                                                                <div class="col-11">
                                                                    <h6><strong>{{r.get_user.name}}</strong></h6>
                                                                    <p>{{r.message}}</p>
                                                                </div>
                                                                <div class="col-12 text-right">
                                                                    {{r.created_at | RemarqueDates}}
                                                                </div>
                                                            </div>
                                                        </el-card>
                                                    </el-timeline-item>
                                                    
                                                </el-timeline>
                                                </div>
                                        </fieldset>
                                    </div>
                            <!-- validation -->
                            
                            </div>
                         </el-card>
                    </el-tab-pane>
                    <!-- Cliché-->
                    <el-tab-pane v-if="c.get_devis_e_t_c.impression == 'OUI' && c.get_devis_e_t_c.films_cliches == 'Non'" label="Cliché">
                        <el-card class="box-card">
                            <div slot="header" class="clearfix text-center">
                                <h4><strong>CLICHE</strong></h4>
                            </div>
                            <div class="row">
                                <fieldset class="col-6 border p-2 text-center">
                                    <legend class="w-auto badge badge "><h6><strong> Cliché </strong></h6></legend>
                                    <el-button type="primary" size="small" icon="el-icon-plus" @click="newModal" plain>Ajouter Nouveau Cliché</el-button>
                                </fieldset>
                            </div>
                        </el-card>
                    </el-tab-pane>
                    <!-- Fiche Technique -->
                    <el-tab-pane v-if="$gate.isAdmin()" label="Fiche Technique">
                        <el-card class="box-card">
                            <div slot="header" class="clearfix text-center">
                                <h4><strong>FICHE TECHNIQUE</strong></h4>
                            </div>
                            <div class="row" v-for="c in Projet" :key="c.id">
                                        <div class="col-4">
                                            <fieldset class="border ">
                                                    <legend class="w-auto badge"><h6><strong> Image D'itequette  </strong></h6></legend>
                                                    <div class="col-12">
                                                        <div class="demo-image__preview text-center">
                                                            <el-image
                                                                :src="`/upload/Profile/Conception_${idProjet}.png`"
                                                                
                                                                >
                                                            </el-image>
                                                        </div>
                                                    </div>
                                                </fieldset>
                                        </div>
                                        <div class="col-4"></div>
                                        <div class="col-4 mt-5" >
                                            <fieldset style="text-align:-webkit-center;">
                                                <legend class="w-auto badge"><h6><strong> QrCode : {{QRcode}}  </strong></h6></legend>
                                                    <VueQRCodeComponent :size="100" :text="QRcode"></VueQRCodeComponent>
                                                </fieldset>
                                        </div>
                                        <el-descriptions border class="col-12 mb-4" :column="3" >
                                            <el-descriptions-item ><template slot="label"><strong>Client :</strong></template> {{c.get_client.name}}</el-descriptions-item>
                                            <el-descriptions-item><template slot="label"><strong>Nom D'étiquette :</strong></template> {{c.get_devis_e_t_c.nom_etc}}</el-descriptions-item>
                                            <el-descriptions-item ><template slot="label"><strong>Format en mm :</strong></template> {{c.get_devis_e_t_c.formatMM_L}}x{{c.get_devis_e_t_c.formatMM_D}} </el-descriptions-item>
                                            <el-descriptions-item ><template slot="label"><strong>Form d'étiquette :</strong></template> {{c.get_devis_e_t_c.forme_etq}} </el-descriptions-item>
                                            <el-descriptions-item ><template slot="label"><strong>Support :</strong></template> {{c.get_devis_e_t_c.support_papier}} </el-descriptions-item>
                                            
                                            <el-descriptions-item ><template slot="label"><strong>Impression :</strong></template> {{c.get_devis_e_t_c.impression}}</el-descriptions-item>
                                            <el-descriptions-item ><template slot="label"><strong>Nombre Couleurs :</strong></template>{{c.get_devis_e_t_c.nbr_couleur}} </el-descriptions-item>
                                            <el-descriptions-item ><template slot="label"><strong>Quantité :</strong></template> {{c.get_devis_e_t_c.quantite_etq}} </el-descriptions-item>
                                            <el-descriptions-item ><template slot="label"><strong>Recto :</strong></template> {{c.get_devis_e_t_c.recto}} </el-descriptions-item>
                                            <el-descriptions-item ><template slot="label"><strong>Verso :</strong></template> {{c.get_devis_e_t_c.verso}} </el-descriptions-item>

                                            <el-descriptions-item ><template slot="label"><strong>Repiquage :</strong></template> {{c.get_devis_e_t_c.repiquage}} </el-descriptions-item>
                                            <el-descriptions-item ><template slot="label"><strong>Vernis :</strong></template> {{c.get_devis_e_t_c.vernis}} </el-descriptions-item>
                                            <el-descriptions-item ><template slot="label"><strong>Plastification :</strong></template> {{c.get_devis_e_t_c.plastification}} </el-descriptions-item>
                                            <el-descriptions-item ><template slot="label"><strong>Dorure :</strong></template> {{c.get_devis_e_t_c.dorure}} </el-descriptions-item>
                                            <el-descriptions-item ><template slot="label"><strong>Mandrin :</strong></template> {{c.get_devis_e_t_c.mandrin}} </el-descriptions-item>

                                            <el-descriptions-item ><template slot="label"><strong>Nombre Etq/Front : :</strong></template> {{c.get_devis_e_t_c.nbr_etq_front}} </el-descriptions-item>
                                            <el-descriptions-item ><template slot="label"><strong>Nombre Etq/Bobine :</strong></template> {{c.get_devis_e_t_c.nbr_etq_bobine}} </el-descriptions-item>
                                            <el-descriptions-item ><template slot="label"><strong>Poses Etiquette :</strong></template> {{c.get_devis_e_t_c.poses_etq}} </el-descriptions-item>
                                            <el-descriptions-item ><template slot="label"><strong>Form De Decoupe :</strong></template> {{c.get_devis_e_t_c.form_decoupe}} </el-descriptions-item>
                                            <el-descriptions-item ><template slot="label"><strong>Films et Cliches :</strong></template> {{c.get_devis_e_t_c.films_cliches}} </el-descriptions-item>
                                        </el-descriptions>
                                    
                                        <!--  -->
                                        <fieldset class="col border" v-if="c.get_devis_e_t_c.sens_sortie == 'externe'">
                                            <legend class="w-auto badge badge-warning"><h6> EXTERNE </h6></legend>
                                                <div class="row">
                                                    <div class="col">
                                                    <div class='text-center'>
                                                            <input type="radio" v-model="form.type_sense_sortie" name="avecImpressionEX" id="avecImpressionEXimg1" class="d-none imgbgchk" value="e_up">
                                                            <label for="avecImpressionEXimg1">
                                                                <img src="/images/form1T.png" alt="Image 1">
                                                                <div class="tick_container">
                                                                <div class="tick"><i class="fa fa-check"></i></div>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                    <div class='text-center'>
                                                            <input type="radio" name="avecImpressionEX" v-model="form.type_sense_sortie" id="avecImpressionEXimg2" class="d-none imgbgchk" value="e_down">
                                                            <label for="avecImpressionEXimg2">
                                                                <img src="/images/form1B.png" alt="Image 1">
                                                                <div class="tick_container">
                                                                <div class="tick"><i class="fa fa-check"></i></div>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                    <div class='text-center'>
                                                            <input type="radio" v-model="form.type_sense_sortie" name="avecImpressionEX" id="avecImpressionEXimg3" class="d-none imgbgchk" value="e_left">
                                                            <label for="avecImpressionEXimg3">
                                                                <img src="/images/form1R.png" alt="Image 1">
                                                                <div class="tick_container">
                                                                <div class="tick"><i class="fa fa-check"></i></div>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                    <div class='text-center'>
                                                            <input type="radio" v-model="form.type_sense_sortie" name="avecImpressionEX" id="avecImpressionEXimg4" class="d-none imgbgchk" value="e_right">
                                                            <label for="avecImpressionEXimg4">
                                                                <img src="/images/form1.png" alt="Image 1">
                                                                <div class="tick_container">
                                                                <div class="tick"><i class="fa fa-check"></i></div>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                        </fieldset>
                                        <!-- Intern -->
                                        <fieldset class="col border" v-if="c.get_devis_e_t_c.sens_sortie == 'interne'">
                                            <legend class="w-auto badge badge-warning"><h6> Intern </h6></legend>
                                                <div class="row">
                                                    <div class="col">
                                                        <div class='text-center'>
                                                                <input type="radio" v-model="form.type_sense_sortie" name="avecImpressionIN" id="avecImpressionINimg1" class="d-none imgbgchk" value="i_up">
                                                                <label for="avecImpressionINimg1">
                                                                    <img src="/images/form2T.png" alt="Image 1">
                                                                    <div class="tick_container">
                                                                    <div class="tick"><i class="fa fa-check"></i></div>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div class="col">
                                                        <div class='text-center'>
                                                                <input type="radio" v-model="form.type_sense_sortie" name="avecImpressionIN" id="avecImpressionINimg2" class="d-none imgbgchk" value="i_down">
                                                                <label for="avecImpressionINimg2">
                                                                    <img src="/images/form2B.png" alt="Image 1">
                                                                    <div class="tick_container">
                                                                    <div class="tick"><i class="fa fa-check"></i></div>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div class="col">
                                                        <div class='text-center'>
                                                                <input type="radio" v-model="form.type_sense_sortie" name="avecImpressionIN" id="avecImpressionINimg3" class="d-none imgbgchk" value="i_right">
                                                                <label for="avecImpressionINimg3">
                                                                    <img src="/images/form2R.png" alt="Image 1">
                                                                    <div class="tick_container">
                                                                    <div class="tick"><i class="fa fa-check"></i></div>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div class="col">
                                                        <div class='text-center'>
                                                                <input type="radio" v-model="form.type_sense_sortie" name="avecImpressionIN" id="avecImpressionINimg4" class="d-none imgbgchk" value="i_left">
                                                                <label for="avecImpressionINimg4">
                                                                    <img src="/images/form2L.png" alt="Image 1">
                                                                    <div class="tick_container">
                                                                    <div class="tick"><i class="fa fa-check"></i></div>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                </div>
                                        </fieldset>
                                        <!-- -->
                                        <fieldset class="border col-12 p-2">
                                            <legend class="w-auto badge badge-success"><h6> Composition d'encre </h6></legend>
                                        <el-table
                                            :data="Couleurs"
                                            style="width: 100%">
                                            <el-table-column
                                                prop="couleur"
                                                label="Couleur"
                                                width="180">
                                            </el-table-column>
                                            <el-table-column
                                                prop="ref"
                                                label="Ref"
                                                width="180">
                                            </el-table-column>
                                            <!-- <el-table-column
                                                prop="poste"
                                                label="Poste sur la machine">
                                            </el-table-column>
                                            <el-table-column
                                                prop="Anilox"
                                                label="Anilox">
                                            </el-table-column> -->
                                            <el-table-column
                                                prop="formule"
                                                label="formule">
                                            </el-table-column>
                                            <!-- <el-table-column
                                                prop="observations"
                                                label="observations">
                                            </el-table-column> -->
                                            <el-table-column v-if="$gate.isAdmin()" width="150" align="center">
                                                <template slot-scope="{row}">
                                                    <el-divider direction="vertical"></el-divider>
                                                        <a href="#" @click="editModal(row)" data-toggle="tooltip" data-placement="top" title="Modifier">
                                                                <i class="fa fa-edit blue mr-2"></i>
                                                        </a>
                                                    <el-divider direction="vertical"></el-divider>

                                                </template>
                                            </el-table-column>
                                            </el-table>
                                        </fieldset>
                                        <div class="col mt-3 mb-3">
                                            
                                            <!-- <el-button type="primary" @click="FicheTechniqueModal" class="float-left" size="small" icon="el-icon-printer">Fiche Technique</el-button> -->
                                            <el-button type="primary" @click="printC" class="float-left" size="small" icon="el-icon-printer">Fiche Technique</el-button>
                                        </div>
                            </div>
                        </el-card>
                    </el-tab-pane>
                    <!-- Ordre de fabrication -->
                    <el-tab-pane v-if="$gate.isAdmin()" label="Ordre de Fabrication">
                        <el-card class="box-card"  id="printMeO">
                            <div slot="header" class="clearfix text-center">
                                <h4><strong>ORDRE DE FABRICATION</strong></h4>
                            </div>
                            <div class="row ml-3"  v-for="c in Projet" :key="c.id">
                            <div class="col-12 border  row">
                                <div class="col-4">
                                    <strong>Projet N° : {{c.id}}  </strong>
                                </div>
                                <div class="col-8 border-left">
                                    <strong>Date:  {{c.created_at}} </strong>
                                </div>
                            </div>
                            <div class="col-12 border mb-3 row">
                                    <strong>Etiquette  : {{c.nom_etc}} </strong>
                            </div>
                            <!--  -->
                            <div class="col-12 border  row">
                                <div class="col-4">
                                    <strong>Client :  </strong>
                                </div>
                                <div class="col-8 border-left">
                                    <strong> {{c.get_client.name}} </strong>
                                </div>
                            </div>
                            <div class="col-12 border row">
                                <div class="col-4">
                                    <strong>Format en mm :  </strong>
                                </div>
                                <div class="col-8 border-left">
                                    <strong class="mr-5"> {{c.get_devis_e_t_c.formatMM_L}} </strong> <strong class="mr-5"> x </strong> <strong class="mr-5"> {{c.get_devis_e_t_c.formatMM_D}} </strong> 
                                </div>
                            </div>
                            <div class="col-12 border row">
                                <div class="col-4">
                                    <strong>Quantité :  </strong>
                                </div>
                                <div class="col-8 border-left">
                                    <strong> {{c.get_devis_e_t_c.quantite_etq}} </strong>
                                </div>
                            </div>
                            <div class="col-12 border row">
                                <div class="col-4">
                                    <strong>Support d'impression :  </strong>
                                </div>
                                <div class="col-8 border-left">
                                    <strong> {{c.get_devis_e_t_c.support_papier}} </strong>
                                </div>
                            </div>
                            <div class="col-6 border row">
                                <div class="col-8">
                                    <strong>Nombre de couleur :  </strong>
                                </div>
                                <div class="col-4 border-left">
                                    <strong> {{c.get_devis_e_t_c.nbr_clouleur}} </strong>
                                </div>
                            </div>
                            <div class="col-6 border row">
                                <div class="col-8">
                                    <strong>Nombre de pause en laize :  </strong>
                                </div>
                                <div class="col-4 border-left">
                                    <strong> {{c.get_devis_e_t_c.Nombre_PosesL}} </strong>
                                </div>
                            </div>
                            <div class="col-6 border row">
                                <div class="col-8">
                                    <strong>Repiquage :  </strong>
                                </div>
                                <div class="col-4 border-left">
                                    <strong> {{c.get_devis_e_t_c.repiquage}} </strong>
                                </div>
                            </div>
                            <div class="col-6 border row">
                                <div class="col-8">
                                    <strong>Nombre de pause en DEV :  </strong>
                                </div>
                                <div class="col-4 border-left">
                                    <strong> {{c.get_devis_e_t_c.Nombre_PosesD}} </strong>
                                </div>
                            </div>
                            <div class="col-6 border row">
                                <div class="col-8">
                                    <strong>Constante :  </strong>
                                </div>
                                <div class="col-4 border-left">
                                    <strong> 3.175 </strong>
                                </div>
                            </div>
                            <div class="col-6 border row">
                                <div class="col-8">
                                    <strong>Nombre total de pause :  </strong>
                                </div>
                                <div class="col-4 border-left">
                                    <strong> {{c.get_devis_e_t_c.nombre_poseT}} </strong>
                                </div>
                            </div>
                            <div class="col-6 border row">
                                <div class="col-8">
                                    <strong>Nombre Etiquette / Bobines :  </strong>
                                </div>
                                <div class="col-4 border-left">
                                    <strong> {{c.get_devis_e_t_c.nbr_etq_bobine}} </strong>
                                </div>
                            </div>
                            <div class="col-6 border row">
                                <div class="col-8">
                                    <strong>Nombre de front :  </strong>
                                </div>
                                <div class="col-4 border-left">
                                    <strong> {{c.get_devis_e_t_c.nbr_etq_front}} </strong>
                                </div>
                            </div>
                            <div class="col-6 border row">
                                <div class="col-8">
                                    <strong>Z Cylindre :  </strong>
                                </div>
                                <div class="col-4 border-left">
                                    <strong> {{c.get_devis_e_t_c.Z_cylindre}} </strong>
                                </div>
                            </div>
                            <div class="col-6 border row">
                                <div class="col-8">
                                    <strong>Developpe :  </strong>
                                </div>
                                <div class="col-4 border-left">
                                    <strong> {{c.get_devis_e_t_c.developer_Calculer}} </strong>
                                </div>
                            </div>
                            <div class="col-6 border row">
                                <div class="col-8">
                                    <strong>Reves en mm :  </strong>
                                </div>
                                <div class="col-4 border-left">
                                    <strong> 10 </strong>
                                </div>
                            </div>
                            <div class="col-6 border row">
                                <div class="col-8">
                                    <strong>Laize Support Calculee :  </strong>
                                </div>
                                <div class="col-4 border-left">
                                    <strong> {{c.get_devis_e_t_c.laize_tehorique}} </strong>
                                </div>
                            </div>
                            <!--  -->
                            <div class="col-12 mt-4 mb-4 border row">
                                <div class="col-8">
                                    <strong>Machine d'impression Programmee :  </strong>
                                </div>
                                <div class="col-4 border-left">
                                    <strong> GIDUE 370 </strong>
                                </div>
                            </div>
                            <!--  -->
                            <div class="col-12 mt-4 mb-4 border row">
                                <div class="col-8">
                                    <strong>N° De La Forme De Decoupe :  </strong>
                                </div>
                                <div class="col-4 border-left">
                                    <strong> {{c.get_devis_e_t_c.form_decoupe_id}} </strong>
                                </div>
                            </div>
                            <!--  -->
                            <div class="col-12 mt-4 mb-4 border row">
                                <div class="col-8">
                                    <strong>N° De Cliche :  </strong>
                                </div>
                                <div class="col-4 border-left">
                                    <strong> {{c.get_devis_e_t_c.films_cliches_id}} </strong>
                                </div>
                            </div>
                            <!--  -->
                            <div class="col-12 mt-4 mb-4 border text-center">
                                    <h4><strong>CALCUL DE LA CONSOMMATION NECESSAIRE </strong></h4>
                            </div>
                            <!--  -->
                            <div class="col-12 mt-4 mb-4 border row">
                                <div class="col-8">
                                    <strong>Laize Support Disponible en Stock en mm :  </strong>
                                </div>
                                <div class="col-4 border-left">
                                    <strong> 190 </strong>
                                </div>
                            </div>
                            <!--  -->
                            <div class="col-12 ">
                                <el-descriptions direction="vertical" :column="5" border>
                                    <el-descriptions-item label="Quantité En ML">{{c.get_devis_e_t_c.qtit_PapierML}}</el-descriptions-item>
                                    <el-descriptions-item label="Quantite En M²">{{c.get_devis_e_t_c.qtit_PapierM2}}</el-descriptions-item>
                                    <el-descriptions-item label="Estimation Bobine">{{c.get_devis_e_t_c.nbr_bobine_consomme}}</el-descriptions-item>
                                    <el-descriptions-item label="Format Mandrin">{{c.get_devis_e_t_c.mandrin}}</el-descriptions-item>
                                    <el-descriptions-item label="Quantite Mandrin">{{c.get_devis_e_t_c.nbr_mandrin_consomme}}</el-descriptions-item>
                                </el-descriptions>
                            </div>
                            
                            </div>
                        </el-card>
                        <div class="col mt-3 mb-3">
                            <!-- <el-button type="primary"  @click="OrdreFabricationModal" class="float-left" size="small" icon="el-icon-printer">Ordre De Fabrication</el-button> -->
                            <el-button type="primary" @click="printO()" class="float-left" size="small" icon="el-icon-printer">Ordre De Fabrication</el-button>
                        </div>
                    </el-tab-pane>
                    

                    <!-- <el-tab-pane label="Magasin">
                        <el-card class="box-card">
                            <div slot="header" class="clearfix text-center">
                                <h4><strong>MAGASIN</strong></h4>
                            </div>
                            <div class="row">

                                <fieldset class="border p-2 col-9">
                                        <legend class="w-auto badge"><h6><strong>Liste Des Bobines</strong></h6></legend>

                                        <el-table
                                            :data="BobineMagasin"
                                            style="width: 100%">
                                            <el-table-column
                                                prop="id_bobine"
                                                label="N° Bobine"
                                                width="180"
                                            >
                                            </el-table-column>
                                            <el-table-column
                                                prop="get_bobine.mtl"
                                                label="Quantité (ML)"
                                            >
                                            </el-table-column>
                                            <el-table-column
                                                prop="get_bobine.Laize"
                                                label="Laize (mm)"
                                            >
                                            </el-table-column>
                                            <el-table-column
                                                prop="poids"
                                                label="Poids(KG)">
                                            </el-table-column>
                                            <el-table-column v-if="$gate.isAdmin()" width="150" align="center" label="Actions">
                                                <template slot-scope="{row}">
                                                        <a href="#" @click="deletMagasin(row.id)" data-toggle="tooltip" data-placement="top" title="Supprimer">
                                                            <i class="fa fa-trash red"></i>
                                                        </a>
                                                        <el-divider direction="vertical"></el-divider>
                                                        <a href="#"  data-toggle="tooltip" data-placement="top" title="Confirmer">
                                                            <i class="fa fa-check green"></i>
                                                        </a>
                                                </template>
                                            </el-table-column>
                                        </el-table>

                                </fieldset>

                                <fieldset class="border p-2 col-3">
                                        <legend class="w-auto badge"><h6><strong>Actions</strong></h6></legend>

                                    <fieldset class="border p-2 mt-2">
                                        <legend class="w-auto badge"><h6><strong> </strong></h6></legend>
                                            <el-button type="primary" size="small" class="btn-block" icon="el-icon-plus" @click="newModalMagasin()" >Ajouter une Bobine</el-button>
                                    </fieldset>
                                    <fieldset class="border p-2 mt-5">
                                        
                                                <div class="form-group">
                                                    <label>Forme de découpe :</label>
                                                    <el-select style="display: inline;" v-model="valueFD" filterable placeholder="Select Forme de decoupe">
                                                        <el-option
                                                            v-for="item in optionsFD"
                                                            :key="item.value"
                                                            :label="item.label"
                                                            :value="item.value">
                                                        </el-option>
                                                    </el-select>
                                                </div>
                                        
                                                <div class="form-group">
                                                    <label>Cliché :</label>
                                                    <el-select style="display: inline;" v-model="valueCL" filterable placeholder="Select Cliché">
                                                        <el-option
                                                            v-for="item in optionsCL"
                                                            :key="item.value"
                                                            :label="item.label"
                                                            :value="item.value">
                                                        </el-option>
                                                    </el-select>
                                                </div>
                                        
                                            <div class="form-group">
                                                <label>Quantité Mandrin :</label>
                                                <input v-model="form.numSerie" type="number" placeholder="Quantité Mandrin" name="numSerie"
                                                    class="form-control" :class="{ 'is-invalid': form.errors.has('numSerie') }">
                                            </div>

                                            <div class="form-group">
                                                <el-button type="success" size="small" class="btn-block" icon="el-icon-check">Valider</el-button>
                                            </div>

                                        </fieldset>
                                </fieldset>

                            </div>
                        </el-card>
                    </el-tab-pane> -->

                    <!-- <el-tab-pane label="Production">
                        <el-card class="box-card">
                            <div slot="header" class="clearfix text-center">
                                <h4><strong>PRODUCTION</strong></h4>
                            </div>
                            <div class="row">         
                            
                            <div class="col-12">
                                <h4>Machine : 
                                    <el-tag type="" effect="dark">
                                        <strong>GIDUE 370</strong>
                                    </el-tag> 
                                </h4>
                            </div>

                                <fieldset class="border p-2 col-8">
                                    <legend class="w-auto badge"><h6><strong>Liste Production</strong></h6></legend>
                                    <div class="col-12">
                                    <el-table
                                        :data="tableDataProduction2"
                                        style="width: 100%">
                                        <el-table-column label="Numéro Bobine">
                                            <template slot-scope="scope">
                                                <span>{{ scope.row.num_Bobine }}</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="Durée Production">
                                            <template slot-scope="scope">
                                                <span>{{ scope.row.duree_Production | minutesAndSeconds }}</span>
                                            </template>
                                        </el-table-column>
                                        
                                        <el-table-column v-if="$gate.isAdmin()" width="150" align="center" label="Actions">
                                            <template slot-scope="{row}">
                                                    <a href="#" @click="editModal(row)" data-toggle="tooltip" data-placement="top" title="Modifier">
                                                        <i class="fa fa-edit blue mr-2"></i>
                                                    </a>
                                                    <el-divider direction="vertical"></el-divider>
                                                    <a href="#" @click="deletModal(row.id)" data-toggle="tooltip" data-placement="top" title="Supprimer">
                                                        <i class="fa fa-trash red"></i>
                                                    </a>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </div>
        
                                    <legend class="w-auto badge mt-3"><h6><strong> Déchet + Faut Tirage </strong></h6></legend>
                            <div class="col-12">
                            <el-table
                                :data="tableDataProduction"
                                style="width: 100%">
                                <el-table-column label="Poids Dechet (ML)">
                                    <template slot-scope="scope">
                                        <span>{{ scope.row.Poids_dechet }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Poids Faut Tirage (ML)">
                                    <template slot-scope="scope">
                                        <span>{{ scope.row.Poids_FTirage }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Reste(ML)">
                                    <template slot-scope="scope">
                                        <span>{{ scope.row.Reste }}</span>
                                    </template>
                                </el-table-column>
                            
                                <el-table-column v-if="$gate.isAdmin()" width="150" align="center" label="Actions">
                                    <template slot-scope="{row}">
                                            <a href="#" @click="newModalProduction" data-toggle="tooltip" data-placement="top" title="Modifier">
                                                    <i class="fa fa-edit blue mr-2"></i>
                                            </a>
                                            <el-divider direction="vertical"></el-divider>
                                            <a href="#" @click="deletModal(row.id)" data-toggle="tooltip" data-placement="top" title="Supprimer">
                                                <i class="fa fa-trash red"></i>
                                            </a>
                                    </template>
                                </el-table-column>
                            </el-table>
                            </div>
                                </fieldset>

                                <fieldset class="border p-2 col-4">
                                        <legend class="w-auto badge"><h6><strong> Actions </strong></h6></legend>
                                        
                                        <fieldset class="border p-2">
                                                <legend class="w-auto badge"><h6><strong> Confirmer la réception </strong></h6></legend>

                                                <div class="form-group">
                                                    <h5>Forme de découpe : 
                                                        <el-tag style="float:right" effect="dark">
                                                            <strong>0005 88x71 REC</strong>
                                                        </el-tag> 
                                                    </h5>
                                                </div>
                                        
                                                <div class="form-group">
                                                    <h5>Cliché : 
                                                        <el-tag style="float:right" effect="dark">
                                                            <strong>0009-012-103-2-O-N-N-N</strong>
                                                        </el-tag> 
                                                    </h5>
                                                </div>

                                                <div class="form-group">
                                                    <h5>Quantité Mandrin : 
                                                        <el-tag style="float:right" effect="dark">
                                                            <strong>250</strong>
                                                        </el-tag> 
                                                    </h5>
                                                </div>
                                        
                                            <div class="form-group">
                                                <el-button type="success" size="small" class="btn-block"  @click="ConfermReceptionModal()" icon="el-icon-check">Confirmer la réception</el-button>
                                            </div>

                                        </fieldset>

                                        <fieldset class="border p-2 mt-5">
                                        <legend class="w-auto badge"><h6><strong> Production </strong></h6></legend>
                                            <el-button type="primary" size="small" class="btn-block" icon="el-icon-loading" @click="newModalProduction2">Nouvelle Production</el-button>
                                        </fieldset>
                                        
                                </fieldset> 
                            
                            </div>
                        </el-card>
                    </el-tab-pane> -->

                    <!-- <el-tab-pane label="Conditionement">
                        <el-card class="box-card">
                            <div slot="header" class="clearfix text-center">
                                <h4><strong>CONDITIONEMENT</strong></h4>
                            </div>
                            <div class="row">
                        <div class="col-12">
                            <h4>Machine : 
                                <el-tag type="" effect="dark">
                                    <strong>Machine 1</strong>
                                </el-tag> 
                            </h4>
                        </div>
                        <fieldset class="border p-2 col-8">
                                    <legend class="w-auto badge"><h6><strong>Liste Conditionement</strong></h6></legend>
                                    <div class="col-12">
                                    <el-table
                                        :data="tableDataProduction2"
                                        style="width: 100%">
                                        <el-table-column label="Numéro Bobine">
                                            <template slot-scope="scope">
                                                <span>{{ scope.row.num_Bobine }}</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="Durée Conditionement">
                                            <template slot-scope="scope">
                                                <span>{{ scope.row.duree_Production | minutesAndSeconds }}</span>
                                            </template>
                                        </el-table-column>
                                        
                                        <el-table-column v-if="$gate.isAdmin()" width="150" align="center" label="Actions">
                                            <template slot-scope="{row}">
                                                    <a href="#" @click="editModal(row)" data-toggle="tooltip" data-placement="top" title="Modifier">
                                                        <i class="fa fa-edit blue mr-2"></i>
                                                    </a>
                                                    <el-divider direction="vertical"></el-divider>
                                                    <a href="#" @click="deletModal(row.id)" data-toggle="tooltip" data-placement="top" title="Supprimer">
                                                        <i class="fa fa-trash red"></i>
                                                    </a>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </div>
        
                            </fieldset>
                            <fieldset class="border p-2 col-4">
                                <legend class="w-auto badge"><h6><strong> Actions </strong></h6></legend>
                                    <el-button type="primary" size="small" class="btn-block" icon="el-icon-loading" @click="newModalProduction2">Nouvelle Conditionement</el-button>
                            </fieldset>
                            </div>
                        </el-card>
                    </el-tab-pane> -->
                </el-tabs>
            </div>
        </div>

        <!-- Modal Cliché -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog modal-xl" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Ajouter Nouveau Cliché</h5>
                    <h5 class="modal-title" v-show="editmode">Modifier le Cliché</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form>
                    <div class="modal-body">
                        <fieldset class="border p-2">
                            <!-- <legend class="w-auto badge badge-success"><h6> Informations de Siège </h6></legend> -->
                            <div class="row">
                                <div class="form-group col-6">
                                    <label>N° Serie:</label>
                                    <input v-model="form.numSerie" type="text" name="numSerie"
                                        class="form-control" :class="{ 'is-invalid': form.errors.has('numSerie') }">
                                    <has-error :form="form" field="numSerie"></has-error>
                                </div>
                                <div class="form-group col-6">
                                    <label>Date:</label>
                                    <input v-model="form.datePlaque" type="date" name="datePlaque"
                                        class="form-control" :class="{ 'is-invalid': form.errors.has('datePlaque') }">
                                    <has-error :form="form" field="datePlaque"></has-error>
                                </div>
                               
                                 <div class="form-group col-6">
                                    <label>Fournisseur :</label>
                                    <input v-model="form.fournisseur" placeholder="fournisseur" type="text" name="fournisseur"
                                        class="form-control" :class="{ 'is-invalid': form.errors.has('fournisseur') }">
                                    <has-error :form="form" field="fournisseur"></has-error>
                                </div>
                                 <div class="form-group col-6">
                                    <label>BL / FA N° :</label>
                                    <input v-model="form.blfa" type="text" name="blfa"
                                        class="form-control" :class="{ 'is-invalid': form.errors.has('blfa') }">
                                    <has-error :form="form" field="blfa"></has-error>
                                </div>

                                
                            </div>
                        </fieldset>
                        <!--  -->
                        <fieldset class="border p-2">
                            <!-- <legend class="w-auto badge badge-success"><h6> Résponsable D'Achat </h6></legend> -->
                                <div class="row">
                                    <div class="form-group col-4">
                                        <label>Client :</label>
                                        <input v-model="form.client" type="number" name="client"
                                            class="form-control" :class="{ 'is-invalid': form.errors.has('client') }">
                                        <has-error :form="form" field="client"></has-error>
                                    </div>
                                    <div class="form-group col-4">
                                        <label>Format Etq en mm:</label>
                                            <div class="row">
                                                <div class="col-4">
                                                    <input v-model="form.format1" placeholder="L" type="number" name="format1"
                                                    class="form-control" :class="{ 'is-invalid': form.errors.has('price') }">
                                                </div>
                                                <div class="col-4">
                                                    <input v-model="form.format2" placeholder="D" type="number" name="format2"
                                                    class="form-control" :class="{ 'is-invalid': form.errors.has('price') }">
                                                </div>
                                            </div>
                                        <has-error :form="form" field="nrc"></has-error>
                                    </div>
                                    <div class="col-4">
                                        <label>Nbr de cliché:</label>
                                        <input v-model="form.nbrCliche" type="number" name="nbrCliche"
                                            class="form-control" :class="{ 'is-invalid': form.errors.has('nbrCliche') }">
                                        <has-error :form="form" field="nbrCliche"></has-error>
                                    </div>
                                    <div class="col-12 mb-3">
                                        <fieldset class="border p-2 ">
                                            <legend class="w-auto badge badge"><h6><strong> Nombre de Pose </strong></h6></legend>
                                            <div class="row">
                                                <div class="form-group col-4">
                                                    <div class="input-group mb-2">
                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text">L</div>
                                                        </div>
                                                        <input type="text" class="form-control" name="l" id="inlineFormInputGroup" placeholder="">
                                                    </div>
                                                </div>
                                                <div class="form-group col-4">
                                                    <div class="input-group mb-2">
                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text">D</div>
                                                        </div>
                                                        <input type="text" class="form-control" name="d" id="inlineFormInputGroup" placeholder="">
                                                    </div>
                                                </div>
                                                <div class="form-group col-4">
                                                    <div class="input-group mb-2">
                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text">T</div>
                                                        </div>
                                                        <input type="text" class="form-control" name="t" id="inlineFormInputGroup" placeholder="">
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                    <!--  -->
                                    
                                        <div class="form-group col-3">
                                            <div class="input-group mb-2">
                                                <div class="input-group-prepend">
                                                    <div class="input-group-text">C1</div>
                                                </div>
                                                <input type="text" class="form-control" name="c1" id="inlineFormInputGroup" placeholder="">
                                            </div>
                                        </div>
                                        <div class="form-group col-3">
                                            <div class="input-group mb-2">
                                                <div class="input-group-prepend">
                                                    <div class="input-group-text">C2</div>
                                                </div>
                                                <input type="text" class="form-control" name="c2" id="inlineFormInputGroup" placeholder="">
                                            </div>
                                        </div>
                                        <div class="form-group col-3">
                                            <div class="input-group mb-2">
                                                <div class="input-group-prepend">
                                                    <div class="input-group-text">C3</div>
                                                </div>
                                                <input type="text" class="form-control" name="c3" id="inlineFormInputGroup" placeholder="">
                                            </div>
                                        </div>
                                        <div class="form-group col-3">
                                            <div class="input-group mb-2">
                                                <div class="input-group-prepend">
                                                    <div class="input-group-text">C4</div>
                                                </div>
                                                <input type="text" class="form-control" name="c4" id="inlineFormInputGroup" placeholder="">
                                            </div>
                                        </div>
                                        <div class="form-group col-3">
                                            <div class="input-group mb-2">
                                                <div class="input-group-prepend">
                                                    <div class="input-group-text">C5</div>
                                                </div>
                                                <input type="text" class="form-control" name="c5" id="inlineFormInputGroup" placeholder="">
                                            </div>
                                        </div>
                                        <div class="form-group col-3">
                                            <div class="input-group mb-2">
                                                <div class="input-group-prepend">
                                                    <div class="input-group-text">C6</div>
                                                </div>
                                                <input type="text" class="form-control" name="c6" id="inlineFormInputGroup" placeholder="">
                                            </div>
                                        </div>
                                        <div class="form-group col-3">
                                            <div class="input-group mb-2">
                                                <div class="input-group-prepend">
                                                    <div class="input-group-text">C7</div>
                                                </div>
                                                <input type="text" class="form-control" name="c7" id="inlineFormInputGroup" placeholder="">
                                            </div>
                                        </div>
                                        <div class="form-group col-3">
                                            <div class="input-group mb-2">
                                                <div class="input-group-prepend">
                                                    <div class="input-group-text">C8</div>
                                                </div>
                                                <input type="text" class="form-control" name="c8" id="inlineFormInputGroup" placeholder="">
                                            </div>
                                        </div>
                                    <!--  -->
                                    
                                    <div class="form-group col">
                                        <label>Z Cylindre:</label>
                                        <input v-model="form.ZCylindr" type="number" name="ZCylindr"
                                            class="form-control" :class="{ 'is-invalid': form.errors.has('ZCylindr') }">
                                        <has-error :form="form" field="ZCylindr"></has-error>
                                    </div>
                                    <div class="form-group col">
                                        <label>Espace developpe en mm:</label>
                                        <input v-model="form.espaceDevlop" type="number" name="espaceDevlop"
                                            class="form-control" :class="{ 'is-invalid': form.errors.has('espaceDevlop') }">
                                        <has-error :form="form" field="espaceDevlop"></has-error>
                                    </div>
                                    <div class="form-group col">
                                        <label>Espace Laize en mm :</label>
                                        <input v-model="form.espaceLaize" type="number" name="espaceLaize"
                                            class="form-control" :class="{ 'is-invalid': form.errors.has('espaceLaize') }">
                                        <has-error :form="form" field="espaceLaize"></has-error>
                                    </div>
                                    <div class="form-group col">
                                        <label>Laize papier calculée en mm :</label>
                                        <input v-model="form.laizePapier" type="number" name="laizePapier"
                                            class="form-control" :class="{ 'is-invalid': form.errors.has('laizePapier') }">
                                        <has-error :form="form" field="laizePapier"></has-error>
                                    </div>
                                    <div class="form-group col">
                                        <label>Machine :</label>
                                        <select v-model="form.machine" class="custom-select mr-sm-2" id="machine">
                                            <option disabled selected>choisir la machine</option>
                                            <option value="1">GIDUE 370</option>
                                            <option value="1">MACHINE 2</option>
                                            <option value="1">MACHINE 3</option>
                                        </select>
                                        <has-error :form="form" field="machine"></has-error>
                                    </div>
                                </div>
                        </fieldset>
                    </div>
                    <div class="modal-footer">
                        <el-button type="danger" size="small" data-dismiss="modal" plain>Annuler</el-button>
                        <el-button type="success" v-show="editmode" size="small" @click="updateProduct()" plain>Valider</el-button>
                        <el-button type="success" v-show="!editmode" size="small" @click="createProduct()" plain>Valider</el-button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
        <!-- Modal Plaque  -->
        <el-dialog
            :title="'Ajouter Nouvelle Plaque'"
            :visible.sync="DialogVisiblePlaques"
            width="80%"
            :before-close="handleClose">
            <!-- Body dialog -->
            <el-form :model="form">
                <fieldset class="border p-2 mt-2">
                    <div class="row">
                        <el-form-item 
                            class="text-center col-lg-4"
                            label="N° Référence De La Plaque"
                            prop="num_reference">
                            <el-input type="text" v-model="form.num_reference" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item 
                            class="text-center col-lg-4"
                            label="Date De Production"
                            prop="date_production">
                            <el-date-picker type="date" placeholder="Choisis une date" v-model="form.date_production" format="yyyy-MM-dd" value-format="yyyy-MM-dd" style="width: 100%;"></el-date-picker>
                        </el-form-item>
                        <el-form-item 
                            class="text-center col-lg-4"
                            label="N° Plan De Form de decoupe (PFD)"
                            prop="num_pfd">
                            <el-input type="text" v-model="form.num_pfd" autocomplete="off"></el-input>
                        </el-form-item>
                    </div>
                </fieldset>
                <fieldset class="border p-2 mt-2">
                    <div class="row">
                        <el-form-item class="col-lg-3" prop="formatMM_L" label="FORMAT EN (mm)">
                            <br>
                            <div class="row float-right">
                                <el-input class="col-5" type="number" placeholder="L" v-model="form.formatMM_L" autocomplete="off"></el-input>
                                <p class="col-1"> X </p>
                                <el-input class="col-5" type="number" placeholder="D" v-model="form.formatMM_D" autocomplete="off"></el-input>
                            </div>
                        </el-form-item>
                        <el-form-item 
                            class="col-lg-3" 
                            prop="forme_etq" 
                            label="FORME D'ETIQUETTE"
                        >
                            <el-select v-model="form.forme_etq" style="width: 100%" placeholder="Please select Form d'étiquitte">
                                <el-option label="REC" value="REC"></el-option>
                                <el-option label="OVA" value="OVA"></el-option>
                                <el-option label="CAR" value="CAR"></el-option>
                                <el-option label="RONDER" value="RONDER"></el-option>
                                <el-option label="TRIANGULAIRE" value="TRIANGULAIRE"></el-option>
                                <el-option label="SPECIALE" value="SPECIALE"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item 
                            class="col-lg-3" 
                            prop="machine" 
                            label="MACHINE"
                        >
                            <el-select v-model="form.machine" multiple @change="changeSelectMachine" style="width: 100%" placeholder="Merci de Choisir une machine">
                                <el-option label="GIDUE-370" value="GIDUE-370"></el-option>
                                <el-option label="NILPITER-B200" value="NILPITER-B200"></el-option>
                                <el-option label="ROTOFLEX-400" value="ROTOFLEX-400"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item 
                            class="col-lg-3" 
                            prop="z_cylindre" 
                            label="Z Cylindre"
                        >
                        <el-input type="number" v-model="form.z_cylindre" autocomplete="off"></el-input>

                            <!-- <el-select v-model="form.z_cylindre" style="width: 100%" placeholder="Merci de Choisir Z Cylindre">
                                <el-option
                                    v-for="item in Zcylindre"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                ></el-option>
                            </el-select> -->
                        </el-form-item>
                        <!-- -->
                        <el-form-item 
                            class="col-lg-4" 
                            prop="nbrPoseLaize" 
                            label="Nombre de Pose en laize"
                        >
                            <el-input type="number" v-model="form.nbrPoseLaize" @change="totalPosePlaqueCalculer" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item 
                            class="col-lg-4" 
                            prop="nbrPoseDevlop" 
                            label="Nombre de Pose en Developpe"
                        >
                            <el-input type="number" v-model="form.nbrPoseDevlop" @change="totalPosePlaqueCalculer" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item 
                            class="col-lg-4" 
                            prop="nbrPoseSurPlaq" 
                            label="Nombre de Pose sur la plaque"
                        >
                            <el-input type="number" v-model="form.nbrPoseSurPlaq" autocomplete="off"></el-input>
                        </el-form-item>
                        <!--  -->
                        <!-- <el-form-item 
                            class="col-lg-4" 
                            prop="devlop_mm" 
                            label="FORMAT D'ETIQUETTE EN LAIZE"
                        >
                            <el-input type="number" v-model="form.devlop_mm" autocomplete="off"></el-input>
                        </el-form-item> -->
                        <el-form-item 
                            class="col-lg-4" 
                            prop="laize_mm" 
                            label="FORMAT D'ETIQUETTE EN LAIZE"
                        >
                            <el-input type="number" v-model="form.laize_mm" @change="laizePapierCalculer" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item 
                            class="col-lg-4" 
                            prop="laize_papier_mm" 
                            label="Laize papier calculée en mm"
                        >
                            <el-input type="number" v-model="form.laize_papier_mm" autocomplete="off"></el-input>
                        </el-form-item>
                    </div>
                </fieldset>
                <fieldset class="border p-2 mt-2">
                    <legend class="w-auto badge"><h5> Support d'impression </h5></legend>
                    <el-form-item 
                        class="text-center col"
                        prop="type_support"
                    >
                        <el-radio-group v-model="form.type_support">
                            <el-radio label="Thermique" ></el-radio>
                            <el-radio label="Couché" ></el-radio>
                            <el-radio label="PPT" ></el-radio>
                            <el-radio label="PPB" ></el-radio>
                            <el-radio label="Autres" ></el-radio>
                        </el-radio-group>
                    </el-form-item>
                </fieldset>
            </el-form>
            <!-- show on edit mode -->
            <span slot="footer"  class="dialog-footer">
                <el-button @click="DialogVisiblePlaques = false">Annuler</el-button>
                <el-button type="primary" @click="createPlaque()">Valider</el-button>
            </span>
        </el-dialog>
        <!-- Demande Devis Plaque -->
        <el-dialog
            :title="'Demande Devis Pour Nouvelle Plaque'"
            :visible.sync="DialogVisibleDevisPlaques"
            width="30%"
            :before-close="handleClose">
            <!-- Body dialog -->
            <el-form :model="formDevisPlaque">
                
                <fieldset class="border p-2 mt-2">
                    
                        <el-form-item 
                            prop="type_de_demande"
                            label="Type De Demande"
                        >
                            <el-radio-group v-model="formDevisPlaque.type_de_demande">
                                <el-radio label="1" >DEMANDE DE PRIX</el-radio>
                                <el-radio label="2">DEMANDE DE REDUCTION DE PRIX</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item 
                            prop="id_fournisseur" 
                            label="Fournisseur"
                        >
                            <!-- <el-select v-model="formDevisPlaque.id_fournisseur" v-loading.fullscreen.lock="fullscreenLoading" @change="getFournisseur(formDevisPlaque.id_fournisseur)" style="width: 100%" placeholder="CHOISIR UN FOURNISSEUR"> -->
                            <el-select v-model="formDevisPlaque.id_fournisseur"  style="width: 100%" placeholder="CHOISIR UN FOURNISSEUR">
                                <el-option
                                    v-for="item in fournisseurs"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                        
                    
                </fieldset>
            </el-form>
            <!-- show on edit mode -->
            <span slot="footer"  class="dialog-footer">
                <el-button @click="DialogVisibleDevisPlaques = false">Annuler</el-button>
                <el-button type="primary" @click="createDevisPlaque()">Valider</el-button>
            </span>
        </el-dialog>
        <!-- End Modal -->
        <!-- Valider Devis Plaque -->
        <el-dialog
            :title="'Demande Devis Pour Nouvelle Plaque'"
            :visible.sync="DialogVisibleValideDevisPlaques"
            width="30%"
            :before-close="handleClose">
            <!-- Body dialog -->
            <el-form :model="formDevisPlaque">
                
                <fieldset class="border p-2 mt-2">
                    
                        <!-- <el-form-item 
                            prop="type_de_demande"
                            label="Type De Demande"
                        >
                            <el-radio-group v-model="formDevisPlaque.type_de_demande">
                                <el-radio label="1" >DEMANDE DE PRIX</el-radio>
                                <el-radio label="2">DEMANDE DE REDUCTION DE PRIX</el-radio>
                            </el-radio-group>
                        </el-form-item> -->
                        <el-form-item 
                            prop="id_fournisseur" 
                            label="Fournisseur"
                        >
                            <!-- <el-select v-model="formDevisPlaque.id_fournisseur" v-loading.fullscreen.lock="fullscreenLoading" @change="getFournisseur(formDevisPlaque.id_fournisseur)" style="width: 100%" placeholder="CHOISIR UN FOURNISSEUR"> -->
                            <el-select v-model="formDevisPlaque.id_fournisseur"  style="width: 100%" placeholder="CHOISIR UN FOURNISSEUR">
                                <el-option
                                    v-for="item in fournisseurs"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                        
                    
                </fieldset>
            </el-form>
            <!-- show on edit mode -->
            <span slot="footer"  class="dialog-footer">
                <el-button @click="DialogVisibleValideDevisPlaques = false">Annuler</el-button>
                <el-button type="primary" @click="validerDevisPlaque()">Valider</el-button>
            </span>
        </el-dialog>
        <!-- End Modal -->
        <!-- Invoice -->
        <div
                        class="modal fade"
                        id="Invoice"
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="Invoice"
                        aria-hidden="true"
                    >
                        <div class="modal-dialog modal-xl" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title text-right">Devis</h5>
                                    <button
                                        type="button"
                                        class="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <!-- invoice -->
                                <div class="modal-body" id="printMe">
                                    <div class="row" v-for="d in devisPlaque" :key="d.id">
                                        <div class="col-12">
                                            <div class="card">
                                                <div class="card-body p-0">
                                                    <div class="row">
                                                        <div class="col-12 text-center">
                                                            <img
                                                                class="comapny_logo_invoice"
                                                                src="/images/logo.png"
                                                                alt="brand-logo-for-invoice"
                                                                style="height:90px"
                                                            />
                                                        </div>
                                                    </div>

                                                    <hr class="my-2" />

                                            <div class="page-content container" >
                                                <div class="page-header text-blue-d2">
                                                    <h2 v-if='d.type_de_demande == 3' class="page-title text-center text-secondary-d1">
                                                        A NOTRE CHER FOURNISSEUR
                                                    </h2>
                                                    <h2 v-else-if='d.type_de_demande == 1' class="page-title text-center text-secondary-d1">
                                                        DEMANDE DE PRIX DES PLAQUES MAGNETIQUE
                                                    </h2>
                                                    <h2 v-else class="page-title text-center text-secondary-d1">
                                                        DEMANDE DE REDUCTION DE L'OFFRE DE PRIX DES PLAQUES MAGNETIQUE
                                                    </h2>
                                                </div>
                                                
                                                <div class="row">
                                                    <div class="col-6"></div>
                                                    <div class="text-95 col-6 align-self-start d-sm-flex justify-content-end">
                                                        <p class="page-title text-secondary-d1">
                                                            <strong>Date  :</strong>  -- / -- / --
                                                        </p>
                                                    </div>

                                                </div>
                            
                                                <div class="container px-0" >
                                                    <div class="row mt-4">
                                                        <div class="col-12 col-lg-12">
                                                        
                                                            <hr/>

                                                            <div class="row">
                                                                <div class="col-sm-6">
                                                                    <div>
                                                                        <div class="text-sm text-grey-m2 align-middle">N° DE LA DEMANDE : PL. MA 0001</div>
                                                                        <div class="text-sm text-grey-m2 align-middle">PLAN DE FORME DE DECOUPE N° : --- ,DATE : ---</div>
                                                                        <div class="text-sm text-grey-m2 align-middle ml-5">FOURNISSEUR : {{d.get_fournisseur.name}}</div>
                                                                        <div class="text-sm text-grey-m2 align-middle ml-5">ADRESSE : {{d.get_fournisseur.adressSocial}}</div>
                                                                        <div class="text-sm text-grey-m2 align-middle ml-5">PAYS :</div>
                                                                        <div class="text-sm text-grey-m2 align-middle ml-5">TEL :</div>
                                                                        <div class="text-sm text-grey-m2 align-middle ml-5">E-MAIL :</div>
                                                                        <div class="text-sm text-grey-m2 align-middle">CHARGE D'AFFAIRE</div>
                                                                        <div class="text-sm text-grey-m2 align-middle ml-5">NOM :</div>
                                                                        <div class="text-sm text-grey-m2 align-middle ml-5">E-MAIL :</div>
                                                                        <div class="text-sm text-grey-m2 align-middle ml-5">TEL :</div>
                                                                    </div>
                                                                    
                                                                </div>
                                                                <!-- /.col -->

                                                            </div>
                                                            <hr />
                                                            <div class="mt-4">
                                                                <div class="row border-b-2 brc-default-l2"></div>

                                                                <!-- or use a table instead -->
                                                                <strong>CHER FOURNISSEUR </strong>
                                                                <br>
                                                                <p class="ml-3" v-if="d.type_de_demande == 1">
                                                                    MERCI DE NOUS FAIRE PARVENIR VOTRE MAILLEUR OFFRE DE PRIX CONCERNANT LES ARTICLES SUIVANTS SELON LES <br>
                                                                    SPECIFITES ET CARACTERISTIQUES TECHNIQUES DEMANDEES.
                                                                </p>

                                                                <p class="ml-3" v-else-if="d.type_de_demande == 2">
                                                                    NOUS AVONS BIEN EXAMINER VOTRE OFFRE DE PRIX N° DATE : -- / -- / --<br>
                                                                    NOUS AVONS CONSTATES QUE VOTRE PRIX PROPOSE EST EXCESSIF, ON VOUS DEMANDE CHER FOURNISSEUR
                                                                    DE FAIRE EFFORT POUR NOUS PROPOSER UNE REDUCTION AINSI QU'UN MEILLEUR DELAI PAIEMENT. <br>
                                                                </p>

                                                                <p class="ml-3" v-else>
                                                                    NOUS AVONS L'HONNEUR DE VOUS ADRESSER CE BON DE COMMANDE N° DATE : -- / -- / --<br>
                                                                    AFIN DE COMMENCER L'ORDRE DE SERVICE SELON LES NORMES ET CARACTERISTIQUES TECHNIQUES EXIGÉES.
                                                                    A CET EFFET, NOUS VOUS DEMANDONS DE PREPARER LA FACTURE PRO FORMA QUI VA PORTER TOUT LES INFORMATIONS SUIVANTES A SAVOIR : <br>
                                                                </p>

                                                                <div v-if="d.type_de_demande == 3" class="row mt-3">
                                                                    <div class="col-12 ml-3 text-grey-d2 text-95 mt-2 mt-lg-0">
                                                                        DESIGNATION D'ARTICLE : Forme De Decoupe : {{d.get_e_t_c.formatMM_L}}x{{d.get_e_t_c.formatMM_D}}-{{d.get_e_t_c.forme_etq}}
                                                                    </div>
                                                                    <div class="col-12 ml-3 text-grey-d2 text-95 mt-2 mt-lg-0">
                                                                        QUANTITE : ---
                                                                    </div>
                                                                    <div class="col-12 ml-3 text-grey-d2 text-95 mt-2 mt-lg-0">
                                                                        NOMENCLATURE : ---
                                                                    </div>
                                                                    <div class="col-12 ml-3 text-grey-d2 text-95 mt-2 mt-lg-0">
                                                                        POIDS BRUT ET NET EN KG : ---
                                                                    </div>
                                                                    <div class="col-12 ml-3 text-grey-d2 text-95 mt-2 mt-lg-0">
                                                                        VALEUR EN DEVIS : ---
                                                                    </div>
                                                                    <div class="col-12 ml-3 text-grey-d2 text-95 mt-2 mt-lg-0">
                                                                        PAYS D'ORIGINE, PAYS DE PROVENANCE : ---
                                                                    </div>
                                                                    <div class="col-12 ml-3 text-grey-d2 text-95 mt-2 mt-lg-0">
                                                                        INCOTERM : ---
                                                                    </div>
                                                                    <div class="col-12 ml-3 text-grey-d2 text-95 mt-2 mt-lg-0">
                                                                        TRANSPORT : ---
                                                                    </div>
                                                                    <div class="col-12 ml-3 text-grey-d2 text-95 mt-2 mt-lg-0">
                                                                        COORDONEES BANCAIRES ET MODALITE DE PAIEMENT : ---
                                                                    </div>

                                                                </div>
                                                                <p class="ml-3" v-if="d.type_de_demande == 3">
                                                                    DANS L'ATTENTE DE VOTRE RETOUR, VEUILLEZ ACCEPTER CHER FOURNISSEUR, NOUS SINCERES SALUTATIONS.
                                                                </p>

                                                                <table class="table table-bordered" v-if="d.type_de_demande != 3">
                                                                        <thead>
                                                                            <tr>
                                                                                <th scope="col">Réferance</th>
                                                                                <th scope="col">Désignation</th>
                                                                                <th scope="col">Quantité</th>
                                                                                <th scope="col">P.U.(H.T)</th>
                                                                                <th scope="col">TOTAL.(H.T)</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <th scope="row">{{d.id}}</th>
                                                                                <td>Forme De Decoupe : {{d.get_e_t_c.formatMM_L}}x{{d.get_e_t_c.formatMM_D}}-{{d.get_e_t_c.forme_etq}}
                                                                                    Z-Cylindre : {{d.get_e_t_c.Z_cylindre}}, Nombre De Pose Laize : {{d.get_e_t_c.Nombre_PosesL}} , 
                                                                                    Nombre De Pose Developpe : {{d.get_e_t_c.nombre_PosesD}}, Support (PAPIER) : {{d.get_e_t_c.support_papier}},
                                                                                    Laize Support Plan Du Travail Calcule En mm {{d.get_e_t_c.support_papier}}, Machine : {{d.get_e_t_c.Machine}}
                                                                                </td>
                                                                                <td>1</td>
                                                                                <td>---</td>
                                                                                <td>---</td>
                                                                            </tr>
                                                                        </tbody>
                                                                </table>

                                                                <hr/>

                                                                <div v-if="d.type_de_demande != 3" class="text-center">
                                                                    <strong class="text-secondary-d1 text-105">SIGNATURES :</strong>
                                                                    <p class="text-secondary-d1 text-105">CHARGE COMANDES</p>
                                                                </div>
                                                                <div v-else class="text-center row">
                                                                    <strong class="text-secondary-d1 text-105">SIGNATURES :</strong>

                                                                    <div class="col-6">
                                                                        <p class="text-secondary-d1 text-105">Hassan EL AISSARI</p>
                                                                    </div>
                                                                    <div class="col-6">
                                                                        <p class="text-secondary-d1 text-105">Abdelaali EL AISSARI</p>
                                                                    </div>
                                                                </div>
                                                                <hr />

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr class="my-2" />
                                            <!-- footer -->
                                            <div class=" flex-row-reverse text-center p-4">
                                                <div class="py-3 px-5">
                                                    <div>
                                                        Lot Yamna Ismail,Avenue Med. VI Route de Biougra - Ait Meloul -B.P.N° 1810 Z.I.Ait Melloul - Tél /Fax: 05 28 24 51 90
                                                    </div>
                                                    <div>
                                                        SARL au Capital de 300 000.00 DH - R.C: 3545 Inzegan -lf : 06 95 04 67 - Patente : 49866952- CNSS : 7341845
                                                    </div>
                                                    <div>
                                                        N° Compte B.P .Ait Melloul : 101022212117001481001534 - Email: aakabar@gmail.com
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                </div>
                        <div class="modal-footer">
                            <button
                                class="btn btn-primary"
                                style="float: right;"
                                @click="printC()"
                            >
                                <span class="fa fa-print"></span>
                            </button>
                        </div>
                    </div>
                    <!-- end invoice  -->
                </div>
        </div>
        <!-- End Invoice -->
        <!-- Modal files Bon Livraison -->
        <el-dialog
                title="Ajouter Bon De Livraison De Plaque"
                :visible.sync="DialogVisibleBonLivraison"
                center>

                <div style="max-width: 500px; margin: 0 auto;">
                            
                            <form
                                @submit="submitBonCommande"
                                class="mt-4"
                                enctype="multipart/form-data"
                            >
                                <div class="input-group">
                                    <div class="custom-file">
                                        <input
                                            type="file"
                                            name="filename"
                                            class="custom-file-input"
                                            id="inputFileUpload"
                                            v-on:change="onFileChange"
                                        />
                                        <label
                                            class="custom-file-label"
                                            for="inputFileUpload"
                                            >Séléctionner Bon De Livraison</label
                                        >
                                    </div>
                                    
                                </div>
                                <br />
                                <p class="text-success font-weight-bold">
                                    {{ filename }}
                                </p>
                            
                        <span slot="footer" class="dialog-footer">
                            <el-button type="warning" @click="DialogVisibleBonLivraison = false">Annuler</el-button>
                            <input  type="submit" class="btn btn-success" value="Enregistrer"  />
                        </span>
                    </form>
                </div>
            </el-dialog>
            <!-- end dialog file Bon Livraison -->
        <!-- Invoice Fiche Technique -->
        <div
            class="modal fade"
            id="FicheTechnique"
            tabindex="-1"
            role="dialog"
            aria-labelledby="Fiche Technique"
            aria-hidden="true"
            >
                <div class="modal-dialog modal-xl" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title text-right">Fiche Technique</h5>
                            <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <!-- invoice -->
                        <div class="modal-body" id="printMe" v-for="c in Projet" :key="c.id">
                            <div class="row">
                                <div class="col-12">
                                    <div class="card">
                                        <div class="card-body p-0">
                                            <div class="row p-5">
                                                <div class="col-md-4 text-left">
                                                    <img
                                                        class="comapny_logo_invoice"
                                                        :src="`/upload/Profile/Conception_${idProjet}.png`"
                                                        alt="Image D'itéquette"
                                                        height="200px"
                                                        width="200px"
                                                    />
                                                </div>

                                                <div class="col-md-4 text-center">
                                                    <h2
                                                        style="margin: 0px"
                                                        class="mb-3" >
                                                        FICHE TECHNIQUE
                                                    </h2>
                                                </div>

                                                <div
                                                    class="col-md-4 text-right"
                                                >
                                                <fieldset style="text-align:-webkit-center;">
                                                    <VueQRCodeComponent :size="200" :text="QRcode"></VueQRCodeComponent>
                                                </fieldset>
                                                    
                                                </div>
                                            </div>

                                            <hr class="my-2" />

                                            <hr class="my-2" />

                                            <div class="row flex-row-reverse  p-4 mb-5">

                                            <div class="col-12 py-3 px-5">
                                                <div class="row">
                                                    <div class="col-4">
                                                        <strong>Client :</strong> {{c.get_client.name}}
                                                    </div>
                                                    <div class="col-4"> <strong>Nom D'étiquette :</strong> {{c.nom_etc}} </div>
                                                    <div class="col-4"> <strong>Format en mm :</strong> {{c.formatMM_L}} x {{c.formatMM_D}} </div>
                                                    <!--  -->
                                                    <div class="col-4"> <strong>Form d'étiquette :</strong> {{c.forme_etq}} </div>
                                                    <div class="col-4"> <strong>Support :</strong> {{c.support_papier}} </div>
                                                    <div class="col-4"> <strong>Impression :</strong> {{c.impression}} </div>
                                                    <!--  -->
                                                    <div class="col-4"> <strong>Client :</strong> {{c.get_client.name}} </div>
                                                    <div class="col-4"> <strong>Nombre Couleurs :</strong> {{c.nbr_couleur}} </div>
                                                    <div class="col-4"> <strong>Quantité :</strong> {{c.quantite_etq}} </div>
                                                    <!--  -->
                                                    <div class="col-4"> <strong>Recto :</strong> {{c.recto}} </div>
                                                    <div class="col-4"> <strong>Verso :</strong> {{c.verso}} </div>
                                                    <div class="col-4"> <strong>Repiquage :</strong> {{c.repiquage}} </div>
                                                    <!--  -->
                                                    <div class="col-4"> <strong>Vernis :</strong> {{c.Vernis}} </div>
                                                    <div class="col-4"> <strong>Plastification :</strong> {{c.plastification}} </div>
                                                    <div class="col-4"> <strong>Dorure :</strong> {{c.dorure}} </div>
                                                    <!--  -->
                                                    <div class="col-4"> <strong>Mandrin :</strong> {{c.mandrin}} </div>
                                                    <div class="col-4"> <strong>Nombre Etq/Front :</strong> {{c.nbr_etq_front}} </div>
                                                    <div class="col-4"> <strong>Nombre Etq/Bobine :</strong> {{c.nbr_etq_bobine}} </div>
                                                    <!--  -->
                                                    <div class="col-4"> <strong>Poses Etiquette :</strong> {{c.poses_etq}} </div>
                                                    <div class="col-4"> <strong>Form De Decoupe :</strong> {{c.form_decoupe}} </div>
                                                    <div class="col-4"> <strong>Films et Cliches :</strong> {{c.films_cliches}} </div>
                                                </div>
                                            </div>
                                            </div>
                                            <hr class="my-2" />

                                            <hr class="my-2" />

                                            <div class="row flex-row-reverse  p-4 mb-5">
                                                    <div class="col-4 " v-if="c.sens_sortie == 'interne'">
                                                        <div class="row">
                                                            <div class="col-6">
                                                                <strong> Sens De Sortie : </strong>{{c.sens_sortie}}
                                                            </div>
                                                            <div class="col-3" v-if="c.type_sense_sortie == 'i_up'">
                                                                <img
                                                                    class="comapny_logo_invoice"
                                                                    src="/images/form2T.png"
                                                                    alt="Image D'itéquette"
                                                                    height="100px"
                                                                    width="100px"
                                                                />
                                                            </div>
                                                            <div class="col-3" v-if="c.type_sense_sortie == 'i_down'">
                                                                <img
                                                                    class="comapny_logo_invoice"
                                                                    src="/images/form2B.png"
                                                                    alt="Image D'itéquette"
                                                                    height="100px"
                                                                    width="100px"
                                                                />
                                                            </div>
                                                            <div class="col-3" v-if="c.type_sense_sortie == 'i_right'">
                                                                <img
                                                                    class="comapny_logo_invoice"
                                                                    src="/images/form2R.png"
                                                                    alt="Image D'itéquette"
                                                                    height="100px"
                                                                    width="100px"
                                                                />
                                                            </div>
                                                            <div class="col-3" v-if="c.type_sense_sortie == 'i_left'">
                                                                <img
                                                                    class="comapny_logo_invoice"
                                                                    src="/images/form2L.png"
                                                                    alt="Image D'itéquette"
                                                                    height="100px"
                                                                    width="100px"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- Extern -->
                                                    <div class="col-4" v-if="c.sens_sortie == 'externe'">
                                                        <div class="row">
                                                            <div class="col-6">
                                                                <strong> Sens De Sortie : </strong>{{c.sens_sortie}}
                                                            </div>
                                                            <div class="col-3" v-if="c.type_sense_sortie == 'e_up'">
                                                                <img
                                                                    class="comapny_logo_invoice"
                                                                    src="/images/form1T.png"
                                                                    alt="Image D'itéquette"
                                                                    height="100px"
                                                                    width="100px"
                                                                />
                                                            </div>
                                                            <div class="col-3" v-if="c.type_sense_sortie == 'e_down'">
                                                                <img
                                                                    class="comapny_logo_invoice"
                                                                    src="/images/form1B.png"
                                                                    alt="Image D'itéquette"
                                                                    height="100px"
                                                                    width="100px"
                                                                />
                                                            </div>
                                                            <div class="col-3" v-if="c.type_sense_sortie == 'e_right'">
                                                                <img
                                                                    class="comapny_logo_invoice"
                                                                    src="/images/form1R.png"
                                                                    alt="Image D'itéquette"
                                                                    height="100px"
                                                                    width="100px"
                                                                />
                                                            </div>
                                                            <div class="col-3" v-if="c.type_sense_sortie == 'e_left'">
                                                                <img
                                                                    class="comapny_logo_invoice"
                                                                    src="/images/form1.png"
                                                                    alt="Image D'itéquette"
                                                                    height="100px"
                                                                    width="100px"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-8">
                                                        <el-table
                                                            :data="Couleurs"
                                                            style="width: 100%">
                                                            <el-table-column
                                                                prop="couleur"
                                                                label="Couleur"
                                                                width="180">
                                                            </el-table-column>
                                                            <el-table-column
                                                                prop="ref"
                                                                label="Ref"
                                                                width="180">
                                                            </el-table-column>
                                                            <el-table-column
                                                                prop="formule"
                                                                label="formule">
                                                            </el-table-column>
                                                        </el-table>
                                                    </div>
                                            </div>

                                            <hr class="my-2" />
                                            <!-- footer -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button
                                class="btn btn-primary"
                                style="float: right;"
                                @click="printC()"
                            >
                                <span class="fa fa-print"></span>
                            </button>
                        </div>
                    </div>
                    <!-- end invoice  -->
                </div>
        </div>

            <!-- End Single Cost Invoice -->
        <!-- End Fiche Technique -->
        <!-- Modal Ordre de fabrication -->
        <!-- <div
                class="modal fade"
                id="OrdreFabrication"
                tabindex="-1"
                role="dialog"
                aria-labelledby="OrdreFabrication"
                aria-hidden="true"
            >
                <div class="modal-dialog modal-xl" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title text-right"></h5>
                            <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        
                        <div class="modal-body" id="printMeO">
                            <el-card class="box-card">
                    <div slot="header" class="clearfix text-center">
                        <h4><strong> Ordre De Fabrication </strong></h4>
                    </div>
                    <div class="row ml-3">
                        <div class="col-12 border  row">
                            <div class="col-4">
                                <strong>Projet N° : 00006  </strong>
                            </div>
                            <div class="col-8 border-left">
                                <strong> 27/11/2021 </strong>
                            </div>
                        </div>
                        <div class="col-12 border mb-3 row">
                                <strong>Fiche Technique N°  : 0006-096-003/C4-B0006 </strong>
                        </div>
                        
                        <div class="col-12 border  row">
                            <div class="col-4">
                                <strong>Client :  </strong>
                            </div>
                            <div class="col-8 border-left">
                                <strong> RISOUSS AGRICOULE </strong>
                            </div>
                        </div>
                        <div class="col-12 border row">
                            <div class="col-4">
                                <strong>Nom Etiquette :  </strong>
                            </div>
                            <div class="col-8 border-left">
                                <strong> Etiquette SPITZ-PAPRIKA ROT 500g </strong>
                            </div>
                        </div>
                        <div class="col-12 border row">
                            <div class="col-4">
                                <strong>Format en mm :  </strong>
                            </div>
                            <div class="col-8 border-left">
                                <strong class="mr-5"> 88 </strong> <strong class="mr-5"> x </strong> <strong class="mr-5"> 71 </strong> 
                            </div>
                        </div>
                        <div class="col-12 border row">
                            <div class="col-4">
                                <strong>Quantité :  </strong>
                            </div>
                            <div class="col-8 border-left">
                                <strong> 500000 </strong>
                            </div>
                        </div>
                        <div class="col-12 border row">
                            <div class="col-4">
                                <strong>Support d'impression :  </strong>
                            </div>
                            <div class="col-8 border-left">
                                <strong> THERMIQUE </strong>
                            </div>
                        </div>
                        <div class="col-6 border row">
                            <div class="col-8">
                                <strong>Nombre de couleur :  </strong>
                            </div>
                            <div class="col-4 border-left">
                                <strong> 4 </strong>
                            </div>
                        </div>
                        <div class="col-6 border row">
                            <div class="col-8">
                                <strong>Nombre de pause en laize :  </strong>
                            </div>
                            <div class="col-4 border-left">
                                <strong> 2 </strong>
                            </div>
                        </div>
                        <div class="col-6 border row">
                            <div class="col-8">
                                <strong>Repiquage :  </strong>
                            </div>
                            <div class="col-4 border-left">
                                <strong> oui </strong>
                            </div>
                        </div>
                        <div class="col-6 border row">
                            <div class="col-8">
                                <strong>Nombre de pause en DEV :  </strong>
                            </div>
                            <div class="col-4 border-left">
                                <strong> 4 </strong>
                            </div>
                        </div>
                        <div class="col-6 border row">
                            <div class="col-8">
                                <strong>Constante :  </strong>
                            </div>
                            <div class="col-4 border-left">
                                <strong> 3.175 </strong>
                            </div>
                        </div>
                        <div class="col-6 border row">
                            <div class="col-8">
                                <strong>Nombre total de pause :  </strong>
                            </div>
                            <div class="col-4 border-left">
                                <strong> 8 </strong>
                            </div>
                        </div>
                        <div class="col-6 border row">
                            <div class="col-8">
                                <strong>Nombre Etiquette / Bobines :  </strong>
                            </div>
                            <div class="col-4 border-left">
                                <strong> 2000 </strong>
                            </div>
                        </div>
                        <div class="col-6 border row">
                            <div class="col-8">
                                <strong>Nombre de front :  </strong>
                            </div>
                            <div class="col-4 border-left">
                                <strong> 1 </strong>
                            </div>
                        </div>
                        <div class="col-6 border row">
                            <div class="col-8">
                                <strong>Z Cylindre :  </strong>
                            </div>
                            <div class="col-4 border-left">
                                <strong> 94 </strong>
                            </div>
                        </div>
                        <div class="col-6 border row">
                            <div class="col-8">
                                <strong>Developpe :  </strong>
                            </div>
                            <div class="col-4 border-left">
                                <strong> 298.45 </strong>
                            </div>
                        </div>
                        <div class="col-6 border row">
                            <div class="col-8">
                                <strong>Reves en mm :  </strong>
                            </div>
                            <div class="col-4 border-left">
                                <strong> 10 </strong>
                            </div>
                        </div>
                        <div class="col-6 border row">
                            <div class="col-8">
                                <strong>Laize Support Calculee :  </strong>
                            </div>
                            <div class="col-4 border-left">
                                <strong> 189 </strong>
                            </div>
                        </div>
                        
                        <div class="col-12 mt-4 mb-4 border row">
                            <div class="col-8">
                                <strong>Machine d'impression Programmee :  </strong>
                            </div>
                            <div class="col-4 border-left">
                                <strong> GIDUE 370 </strong>
                            </div>
                        </div>
                        
                        <div class="col-12 mt-4 mb-4 border row">
                            <div class="col-8">
                                <strong>N° De La Forme De Decoupe :  </strong>
                            </div>
                            <div class="col-4 border-left">
                                <strong> 0005 88x71 REC </strong>
                            </div>
                        </div>
                        
                        <div class="col-12 mt-4 mb-4 border row">
                            <div class="col-8">
                                <strong>N° De Cliche :  </strong>
                            </div>
                            <div class="col-4 border-left">
                                <strong> 0008-096-003-4-O-N-N-N </strong>
                            </div>
                        </div>
                        
                        <div class="col-12 mt-4 mb-4 border text-center">
                                <h4><strong>CALCUL DE LA CONSOMMATION NECESSAIRE </strong></h4>
                        </div>
                        
                        <div class="col-12 mt-4 mb-4 border row">
                            <div class="col-8">
                                <strong>Laize Support Disponible en Stock en mm :  </strong>
                            </div>
                            <div class="col-4 border-left">
                                <strong> 190 </strong>
                            </div>
                        </div>
                        
                         <div class="col-12 ">
                            <el-table
                                    :data="tableData3"
                                    style="width: 100%">
                                    <el-table-column
                                        prop="QuantitML"
                                        label="Quantité En ML"
                                        width="180">
                                    </el-table-column>
                                    <el-table-column
                                        prop="QuantitM2"
                                        label="QUantite En M²"
                                        width="180">
                                    </el-table-column>
                                    <el-table-column
                                        prop="EstimationB"
                                        label="Estimation Bobine">
                                    </el-table-column>
                                        <el-table-column
                                        prop="FormatM"
                                        label="Format Mandrin">
                                    </el-table-column>
                                    <el-table-column
                                        prop="QuantitM"
                                        label="QUantite Mandrin">
                                    </el-table-column>
                            </el-table>
                         </div>
                    </div>
                </el-card>
                            
                        </div>
                        <div class="modal-footer">
                            <button
                                class="btn btn-primary"
                                style="float: right;"
                                @click="printO()"
                            >
                                <span class="fa fa-print"></span>
                            </button>
                        </div>
                    </div>
                </div>
        </div> -->
        <!-- end ordre de fabrication -->

<!--Dialoge Magasin -->
<el-dialog title="Ajouter Bobine" :visible.sync="dialogVisibleMagasin" width="50%" :before-close="handleClose">
            <el-form :model="formMagasin" >
                <!-- <div class="form-group col-12">
                    <label>Scanner le Code barre :</label>
                    <qrcode-stream :camera="camera" @decode="onDecode" @init="onInit">
                    <div v-show="showScanConfirmation" class="scan-confirmation">
                    </div>
                    </qrcode-stream>
                </div> -->
                <div class="row">
                    <el-form-item 
                        class="col-lg-9"
                        label="N° De Bobine"
                        prop="id_bobine"
                    >
                        <el-select v-model="formMagasin.id_bobine" style="width: 100%" filterable placeholder="Choisir une bobine">
                            <el-option
                                v-for="item in Bobines"
                                :key="item.id"
                                :label="item.code_bobine"
                                :value="item.code_bobine"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item 
                        class="col-lg-3"
                        label="Poids (KG)"
                        prop="poids"
                    >
                        <el-input v-model="formMagasin.poids" placeholder="Poids"></el-input>
                    </el-form-item>
                </div>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisibleMagasin = false">Annuler</el-button>
                <el-button  type="success" v-show="!editmode" @click="createMagasin()">Valider</el-button>
                <el-button  type="success" v-show="editmode" @click="SaveupdateMagasin()">Modifier</el-button>
            </span>
</el-dialog>
        
<!-- end Dialog magasin -->
        <!-- Modal Production 2 -->
        <div class="modal fade" id="addNewProduction2" tabindex="-1" role="dialog" aria-labelledby="addNewProduction2" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    
                    <h5 class="modal-title" v-show="!editmode">Ajouter</h5>
                    <h5 class="modal-title" v-show="editmode">Modifier</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form>
                    <div class="modal-body">
                        
                        <!--  -->
                        
                            <!-- <div class="row">
                                <fieldset class="border col-6  p-2">
                                    <legend class="w-auto badge"><h6><strong> Scanner le Code barre </strong></h6></legend>
                                    <div class=" row">
                                    
                                     <div class="form-group col-12">
                                        <qrcode-stream :camera="camera" @decode="onDecode" @init="onInit">
                                        <div v-show="showScanConfirmation" class="scan-confirmation">
                                        </div>
                                        </qrcode-stream>
                                    </div>
                                    <div class="form-group col-12">
                                        <label>Ou Saisie le N° de Bobine:</label>
                                        <input v-model="formMagasin.num_Bobine" type="text" name="num_Bobine"
                                            class="form-control text-center" :class="{ 'is-invalid': form.errors.has('num_Bobine') }">
                                        <has-error :form="form" field="num_Bobine"></has-error>
                                    </div>
                                    </div>
                                </fieldset>
                                
                                <fieldset class="border p-2 col-6 ">
                                    <legend class="w-auto badge"><h6><strong> Durée De Production </strong></h6></legend>
                                    <div class="row mt-5">
                                        <div class="form-group col-12">
                                            <h4 class="ml-5">{{ result1 | minutesAndSeconds }}</h4>
                                        </div>
                                        <div  class="form-group col-12">
                                            <el-button v-if="!started"  type="success" class="btn-block " size="small" icon="el-icon-timer" @click="countToTen()">Commancer la Production</el-button>
                                            <el-button  v-else type="danger" class="btn-block " size="small" icon="el-icon-timer" @click="stopCountToTen()">Terminer</el-button>
                                        </div>
                                    </div>
                                </fieldset>
                            </div> -->
                        
                    </div>
                    <div class="modal-footer">
                        <el-button type="danger" size="small" data-dismiss="modal" plain>Annuler</el-button>
                        <el-button type="success" v-show="editmode" size="small" @click="updateProduct()" plain>Valider</el-button>
                        <el-button type="success" v-show="!editmode" size="small" @click="createProduct()" plain>Valider</el-button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
        <!-- end Modal Production 2 -->
        <!-- Modal Production -->
        <div class="modal fade" id="addNewProduction" tabindex="-1" role="dialog" aria-labelledby="addNewProduction" aria-hidden="true">
            <div class="modal-dialog modal-xl" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Ajouter</h5>
                    <h5 class="modal-title" v-show="editmode">Modifier</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form>
                    <div class="modal-body">
                        
                        <!--  -->
                        <fieldset class="border p-2">
                            <div class="row">
                                    <div class="form-group col-4">
                                        <label>Poids Déchets:</label>
                                        <input v-model="formProduction.Poids_dechet" type="text" name="Poids_dechet"
                                            class="form-control text-center" :class="{ 'is-invalid': form.errors.has('Poids_dechet') }">
                                        <has-error :form="form" field="Poids_dechet"></has-error>
                                    </div>
                                    <div class="form-group col-4">
                                        <label>Poids Faut-Tirage:</label>
                                        <input v-model="formProduction.Poids_FTirage" type="number" name="Poids_FTirage"
                                            class="form-control" :class="{ 'is-invalid': form.errors.has('Poids_FTirage') }">
                                        <has-error :form="form" field="Poids_FTirage"></has-error>
                                    </div>
                                    <div class="form-group col-4">
                                        <label>Le reste (ML) :</label>
                                        <input v-model="formProduction.Reste" type="number" name="Reste"
                                            class="form-control" :class="{ 'is-invalid': form.errors.has('Reste') }">
                                        <has-error :form="form" field="Reste"></has-error>
                                    </div>
                            </div>
                        </fieldset>
                    </div>
                    <div class="modal-footer">
                        <el-button type="danger" size="small" data-dismiss="modal" plain>Annuler</el-button>
                        <el-button type="success" v-show="editmode" size="small" @click="updateProduct()" plain>Valider</el-button>
                        <el-button type="success" v-show="!editmode" size="small" @click="createProduct()" plain>Valider</el-button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
        <!-- end Modal Production -->
        <!-- Modal Conditionement QrCodes -->
        <div
                class="modal fade"
                id="ModalCondi"
                tabindex="-1"
                role="dialog"
                aria-labelledby="Condi"
                aria-hidden="true"
            >
                <div class="modal-dialog modal-xl" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title text-right"></h5>
                            <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <!-- invoice -->
                        <div class="modal-body" id="printMeCondi">
                                <el-card class="box-card col-3" v-for="item in QrCodes" :key="item.value">
                                    <VueQRCodeComponent 
                                    :size="100"
                                    :text="item.value">
                                    </VueQRCodeComponent>
                                    <strong class="mt-2">QrCode : {{item.value}}</strong>
                                </el-card>
                        </div>
                        <div class="modal-footer">
                            <button
                                class="btn btn-primary"
                                style="float: right;"
                                @click="printCondi()"
                            >
                                <span class="fa fa-print"></span>
                            </button>
                        </div>
                    </div>
                    <!-- end invoice  -->
                </div>
        </div>
        <!-- End Conditionement Modal -->
    </div>
  </section>
</template>

<script>
    import VueQRCodeComponent from 'vue-qrcode-component';
    import moment from 'moment';
    import Example from '../ExampleComponent.vue';
    import {Machine1 , Machine2 ,Machine3, Mandrin, Etiquettes , Clients} from './devis/Zcylindre';

    export default {
      components: {
            VueQRCodeComponent,
            Example
        },
        data () {
            return {
                DialogVisiblePlaques: false,
                DialogVisibleDevisPlaques: false,
                DialogVisibleValideDevisPlaques: false,
                Projet:[],
                devisPlaque:[],
                Couleurs:[],
                Remarques:[],
                file:[],
                BobineMagasin:[],
                Bobines:[],
                etape:'',
                idProjet : this.$route.params.idp,
                editmode: false,

                // dialogues
                dialogVisibleMagasin: false,

                Remarquesform: new Form({
                    id:'',
                //    id_user:'',
                   id_projet:this.$route.params.idp,
                   etape:'',
                   message:''
                }),

                ValidationForm: new Form({
                    etape:'',
                    validation_FD1:'',
                    validation_Concept1:'',
                    validation_BAT1:''
                }),

                // Form Devis Plaque
                formDevisPlaque: new Form({ 
                    id_projet:this.$route.params.idp,
                    id_fournisseur:'',
                    type_de_demande:'',
                    id_etc:this.$route.params.idEtc,
                    rayon_coin:'',
                    rayon_coin_valeur:'',
                    qtit:'',
                    prix_unit:'',
                    method_envoi:'',
                    date_livraison:'',
                    modalite_paiment:'',
                }),

                // Form Magasin
                formMagasin: new Form({ 
                    id_projet:this.$route.params.idp,
                    id_bobine:'',
                    quantite:'',
                    poids : '',
                    etats : '',
                }),


            // To delete after
                // 
                valueFD: '',
                valueCL: '',

                QrCodes: [{
                    value: '12547826654',
                    label: '12547826654'
                    }, {
                    value: '645318945454',
                    label: '645318945454'
                    }, {
                    value: '321578978454',
                    label: '321578978454'
                    },{
                    value: '231564654545',
                    label: '231564654545'
                    }, {
                    value: '321654822222',
                    label: '321654822222'
                    }, {
                    value: '215482214597',
                    label: '215482214597'
                    }],

                optionsFD: [{
                    value: '0005 88x71 REC',
                    label: '0005 88x71 REC'
                    }, {
                    value: '0004 64x48 REC-VER',
                    label: '0004 64x48 REC-VER'
                    }, {
                    value: '0006 90x71 REC',
                    label: '0006 90x71 REC'
                    }],

                optionsCL: [{
                    value: '0008-096-003-4-O-N-N-N',
                    label: '0008-096-003-4-O-N-N-N'
                    }, {
                    value: '0009-012-103-2-O-N-N-N',
                    label: '0009-012-103-2-O-N-N-N'
                    }, {
                    value: '0021-01-023-3-O-N-N-O',
                    label: '0021-01-023-3-O-N-N-O'
                    }],
                    
                soundBeep : 'https://soundbible.com/mp3/Beep-SoundBible.com-923660219.mp3',
                // QrCode generator
                QRcode: '5236541236',
                // QrCode
                // camera: 'auto',
                result: null,
                showScanConfirmation: false,

                // 

                // projet
                tableData: [{
                        dossiernumber: '1254123',
                        etiquet_name: 'etiquette-500g-rec-oval-caree',
                        client_name: 'Mohamed',
                        date: '12-10-2021',
                        client_email: 'mohamed@entreprise.com',
                        formatL:'88',
                        formatD:'71',
                        form_etiquette :'REC',
                        qtit: '500000',
                        support: 'Termique',
                        impression:'oui',
                        Nbr_couleur:'4',
                        recto:'non',
                        verso:'non',
                        conception:'Non',
                        repiquage:'oui',
                        vernis:'non',
                        plastification:'non',
                        dorure:'non',
                        nbrbobine:'2000',
                        nbrfront:'1',
                        mandrin : '76',
                        poseEtq: 'Manuelle',
                        formDecoup : 'oui',
                        filmCliches: 'non',
                        // 
                        Z_cylindre:'94',
                        nbrPosesL : '2',
                        nbrPosesD : '4',
                        espaceL : '3',
                        espaceD: '3.61',
                        machine:'GIDUE 370',
                        // BAT
                        

                }],
                // composition encre
                tableData2: [{
                        Couleur: 'JAUNE',
                        Ref: 'Principale',
                        poste: 'G1',
                        Anilox: '3.7/398',
                        formule: '-------',
                        observations: ''
                    }, {
                        Couleur: 'MAGENTA',
                        Ref: 'Principale',
                        poste: 'G2',
                        Anilox: '3.2/303',
                        formule: '-------',
                        observations: ''
                    }, {
                        Couleur: 'CYAN',
                        Ref: 'Principale',
                        poste: 'G3',
                        Anilox: '3.5/408',
                        formule: '-------',
                        observations: ''
                    }, {
                        Couleur: 'NOIR',
                        Ref: 'Principale',
                        poste: 'G4',
                        Anilox: '3.2/306',
                        formule: '-------',
                        observations: ''
                }],
                // 
                tableData3: [{
                        QuantitML: '18753.125',
                        QuantitM2: '3563.093',
                        EstimationB: '9.376',
                        FormatM: '78mm D76',
                        QuantitM: '250'
                    }],
                    // Magasin
                    tableDataMagasin:[{
                        num_Bobine: '123456',
                        quantite : '2000',
                        poids: '59'
                    },{
                        num_Bobine: '85264',
                        quantite : '2000',
                        poids: '60'
                    },{
                        num_Bobine: '9545',
                        quantite : '2000',
                        poids: '61'
                    },
                    {
                        num_Bobine: '98845',
                        quantite : '2000',
                        poids: '59'
                    },
                    {
                        num_Bobine: '897854',
                        quantite : '2000',
                        poids: '59'
                    },
                    {
                        num_Bobine: '8845',
                        quantite : '2000',
                        poids: '59'
                    },
                    {
                        num_Bobine: '74440',
                        quantite : '2000',
                        poids: '59'
                    },{
                        num_Bobine: '897854',
                        quantite : '2000',
                        poids: '59'
                    },
                    {
                        num_Bobine: '8845',
                        quantite : '2000',
                        poids: '59'
                    },
                    {
                        num_Bobine: '74440',
                        quantite : '2000',
                        poids: '59'
                    }],
                    // Magasin
                    tableDataProduction:[{
                        Poids_dechet: '1250',
                        Poids_FTirage : '500',
                        Reste: '150'
                    }],
                    // Production2
                    tableDataProduction2:[
                        {
                        num_Bobine: '86544654',
                        duree_Production : '500'
                        },
                        {
                        num_Bobine: '54654',
                        duree_Production : '254'
                        },
                        {
                        num_Bobine: '965412',
                        duree_Production : '500'
                        },
                        {
                        num_Bobine: '98563',
                        duree_Production : '254'
                        },
                    ],
                // plaques
                plaqueFormat:[
                    {l:'21', d:'71', num:'0007-xxx1'},
                    {l:'22', d:'72', num:'0010-xxx7'},
                    {l:'23', d:'73', num:'0008-xxx2'},
                    ],
                tableData1s: [{
                id:'1',
                dossiernumber: '1254123',
                client_name: 'Mohamed',
                Date: '12-10-2021',
                responsable_name: 'Mustapha',
                etiquet_name: 'etiquette-500g-rec-oval-caree',
                support: 'Couché',
                format:'21 x 17',
                conception:'Non',
                Impression:'Oui',
                Nbr_couleur:'2',
                recto:'oui',
                verso:"non",
                repiquage:"non",
                plastification:"oui",
                vernis:"Non",
                }],
                search: '',
                pagesize: 10,
                currpage: 1,
                
                products : [],
                // formPlaque
                Zcylindre:[],
                Machine1: Machine1,
                Machine2: Machine2,
                Machine3: Machine3,
                form2: new Form({
                    validation1:1,
                    validation2:'',
                }),
                form: new Form({
                    num_pfd:'',
                    num_reference:'',
                    date_production:'',
                    numSerie:'',
                    formatMM_L:'',
                    formatMM_D:'',
                    nbrPoseLaize:'',
                    nbrPoseDevlop:'',
                    nbrPoseSurPlaq:'',
                    machine:[],
                    z_cylindre:'',
                    devlop_mm:'',
                    laize_mm:'',
                    laize_papier_mm:'',
                    type_support:'',
                    forme_etq:'',
                }),
                categories: [],

                tag:  '',
                autocompleteItems: [],

                // Form Production
                formProduction: new Form({ 
                    Poids_dechet:'',
                    Poids_FTirage:'',
                    Reste : '',
                    preparationTime: '',
                    productionTime: ''
                }),
                // 
                result1: 0,
                started: false,
                timeout1: null,
                Onefournisseurs:[],
                fournisseurs:[],
                devispfd:[],
                fullscreenLoading: false,

                // Bon Livraison
                DialogVisibleBonLivraison : false,
                filename : '',
                file: "",
                // 
            }
        },
        methods: {

            // file Bon Livraison
            joindreBLPlaque(){
                this.filename ='';
                
                this.DialogVisibleBonLivraison = true;
            },
            
            onFileChange(e) {
                // console.log(e.target.files[0].name);
                this.filename = "FICHIER SÉLECTIONNÉ : " +e.target.files[0].name;
                this.file = e.target.files[0];
            },

            submitBonCommande(e) {
                this.$Progress.start();
                e.preventDefault();
                let currentObj = this;
                const config = {
                    headers: {
                        "content-type": "multipart/form-data",
                        "X-CSRF-TOKEN": document.querySelector(
                            'meta[name="csrf-token"]'
                        ).content
                    }
                };
                // form data
                let formData = new FormData();
                formData.append('file', this.file[0].raw);
                
                axios.post("/api/uploadBLP/"+this.$route.params.idp, formData, config)
                    
                    .then(response => {
                        // success
                        Toast.fire({
                            icon: "success",
                            title: response.data.message
                        });

                        this.$Progress.finish();
                        
                    }
                    )
                    .catch(function(error) {
                        currentObj.output = error;
                    });
                },
            // End File Bon Livraison

            async loadOneDevisPlaque(idDevis){
                this.$Progress.start();
                    await axios.get("/api/GetOneDevis/"+idDevis).then(({ data }) => (this.devisPlaque = data.data))
                this.$Progress.finish();
            },

            async printC(idDevis) {
                await this.loadOneDevisPlaque(idDevis);
                this.$htmlToPaper("printMe");
            },

            InvoiceModal() {
                this.editmode = false;
                this.form.reset();
                $("#Invoice").modal("show");
            },
            
            ValidationPFD(){
              this.$Progress.start();
              this.form2.put('/api/validationPFD/'+this.$route.params.idp)
              .then((response) => {
                  // success
                  
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });
                  this.$Progress.finish();
              })
              .catch(() => {
                  this.$Progress.fail();
              });

          },

        //   Demande Devis FD
            // async getFournisseur(fournisseurID){ 
            //     this.$Progress.start();
            //     this.fullscreenLoading = true;
            //      let response = await axios.get("/api/indexOne/"+fournisseurID)
            //     this.Onefournisseurs = response.data.data;
                
            //     this.fullscreenLoading = false;
            //     this.$Progress.finish();
            // },

            loadFournisseurs(){
                
                axios.get("/api/CompteFournisseurs").then(({ data }) => (this.fournisseurs = data.data));
            },

            loadDevisPFD(){
                
                axios.get('/api/DemandeDevisPlaque/'+this.$route.params.idEtc).then(({ data }) => (this.devispfd = data.data));
            },

            DemandeDevisFD(){
                this.loadFournisseurs();
                this.formDevisPlaque.reset();
                this.editmode = false;
                this.DialogVisibleDevisPlaques = true;
            },            

            ValiderDevisFD(){
                this.loadFournisseurs();
                this.formDevisPlaque.reset();
                this.editmode = false;
                this.DialogVisibleValideDevisPlaques = true;
            },

            // 
            validerDevisPlaque(){
              this.$Progress.start();
              this.formDevisPlaque.type_de_demande = 3

              this.formDevisPlaque.post('/api/DemandeDevisPlaque')
              .then((data)=>{
                if(data.data.success){
                 this.DialogVisibleValideDevisPlaques = false

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.$Progress.finish();
                this.loadDevisPFD();

                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })

          },

            deleteDevis(id){
                Swal.fire({
                  title: 'Attention !',
                  text: "vous voulez vraiment supprimer ce Devis ??",
                  showCancelButton: true,
                  confirmButtonColor: '#67c23a',
                  cancelButtonColor: '#f56c6c',
                  confirmButtonText: 'Oui, supprimez-le ! '
                  }).then((result) => {

                      // Send request to the server
                        if (result.value) {
                              this.form.delete('/api/DemandeDevisPlaque/'+id).then(()=>{
                                      Swal.fire(
                                      'Supprimé !',
                                      'Demande De Devis a été Bien supprimé.',
                                      'success'
                                      );
                                  this.loadDevisPFD();
                              }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                        }
                  })
            },

            createDevisPlaque(){
 
            this.$Progress.start();

              this.formDevisPlaque.post('/api/DemandeDevisPlaque')
              .then((data)=>{
                if(data.data.success){
                 this.DialogVisibleDevisPlaques = false

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.$Progress.finish();
                this.loadDevisPFD();

                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
                
            },

            

            // Plaque
            laizePapierCalculer(){
                this.form.laize_papier_mm = (this.form.laize_mm * this.form.nbrPoseLaize)+3*(this.form.nbrPoseLaize-1)+10
            },

            totalPosePlaqueCalculer(){
                this.form.nbrPoseSurPlaq = (this.form.nbrPoseDevlop * this.form.nbrPoseLaize)
            },

            newPlaque(){
                this.form.reset();
                this.editmode = false;
                this.DialogVisiblePlaques = true;
            },

            changeSelectMachine () {
                console.log(this.form.machine);
                 switch (this.form.machine) {
                     case 'GIDUE-370':
                         this.Zcylindre = this.Machine1;
                         
                         break;
                     case 'NILPITER-B200':
                         this.Zcylindre = this.Machine2;
                        
                         break;
                     case 'ROTOFLEX-400':
                         this.Zcylindre = this.Machine3;
                         
                         break;
                     }
             },

             createPlaque(){
                this.$Progress.start();

                this.form.post('/api/PlaqueDecoupe')
                .then((data)=>{
                    if(data.data.success){
                    this.DialogVisiblePlaques = false

                    Toast.fire({
                            icon: 'success',
                            title: data.data.message
                        });
                    this.$Progress.finish();
                    //   this.loadDevisGlobal();
                    //   this.loadDataPrepared();

                    } else {
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });

                    this.$Progress.failed();
                    }
                })
                .catch(()=>{

                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
                })
            },
            // End Plaque

            getTypeSense(){
                for (let i = 0; i < this.Projet.length; i++) {
                    this.form.type_sense_sortie = this.Projet[i].type_sense_sortie;
                }
            },

            handleClose(done) {
                this.$confirm('Voulez-vous vraiment fermer cette boîte de dialogue ?')
                .then(_ => {
                    done();
                })
                .catch(_ => {});
            },

    // Crud Profile
        async loadProjet(){
            this.$Progress.start();
            await axios.get("/api/GetOneProject/"+this.$route.params.idp).then(({ data }) => (this.Projet = data.data))
            for (let i = 0; i < this.Projet.length; i++) {
                
                this.form2.validation1 = this.Projet[i].validationPFD
            }
           
            this.$Progress.finish();
            
        },

        async getValidations(){

            await this.loadProjet();
            
        },


        loadCouleurs(){
              axios.get("/api/GetDevisCouleurs/"+this.$route.params.idp).then(({ data }) => (this.Couleurs = data.data));
        },

        // update Forme de decoupe
         onChangeFormDecoupe(file, fileList) {
                this.file = fileList.slice(-1);
                this.etape='FormDecoupe'
            },
         onChangeConception(file, fileList) {
                this.file = fileList.slice(-1);
                this.etape='Conception'
            },
         onChangeBAT(file, fileList) {
                this.file = fileList.slice(-1);
                this.etape='BAT'
            },

        submitForm(e) {
            this.$Progress.start();
            e.preventDefault();
            let currentObj = this;
            const config = {
                headers: {
                    "content-type": "multipart/form-data",
                    "X-CSRF-TOKEN": document.querySelector(
                        'meta[name="csrf-token"]'
                    ).content
                }
            };
            // form data
            let formData = new FormData();
            formData.append('file', this.file[0].raw);
            formData.append("etape", this.etape);
            // send upload request
            
            axios.post("/api/uploadFormeD/"+this.$route.params.idp, formData, config)
                
                .then(response => {
                    // success
                    Toast.fire({
                        icon: "success",
                        title: response.data.message
                    });
                    this.file = [];
                    this.$Progress.finish();
                    window.location.reload();
                    
                    // this.loadFiles();
                }
                )
                .catch(function(error) {
                    currentObj.output = error;
                });
        },

        ValidationEtapes(etape){
              this.$Progress.start();
              this.ValidationForm.etape= etape;

              this.ValidationForm.put('/api/ValidateEtaps/'+this.$route.params.idp)
              .then((response) => {
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });
                  this.$Progress.finish();
                  this.ValidationForm.etape= '';

              })
              .catch(() => {
                  this.$Progress.fail();
              });

          },

        
    // end CRUD

    // CRUD remarque
    loadRemarques(){
                this.$Progress.start();
                    axios.get("/api/ProjetRemarques/"+this.$route.params.idp).then(({ data }) => (this.Remarques = data.data));
                this.$Progress.finish();
            },

    SendRemarque(etape){
            this.$Progress.start();
            this.Remarquesform.etape = etape;

              this.Remarquesform.post('/api/ProjetRemarques')
              .then((data)=>{
                if(data.data.success){
                //  this.DialogVisible = false

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.$Progress.finish();
                  this.Remarquesform.etape = '';
                  this.Remarquesform.message = '';
                  this.loadRemarques();

                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          },

          deletRemarque(id){
              Swal.fire({
                  title: 'Attention !',
                  text: "vous voulez vraiment supprimer cette Remarque",
                  showCancelButton: true,
                  confirmButtonColor: '#67c23a',
                  cancelButtonColor: '#f56c6c',
                  confirmButtonText: 'Oui, supprimez-le ! '
                  }).then((result) => {

                      // Send request to the server
                        if (result.value) {
                              this.form.delete('/api/ProjetRemarques/'+id).then(()=>{
                                      Swal.fire(
                                      'Supprimé !',
                                      'Votre Remarque a été supprimé.',
                                      'success'
                                      );
                                  this.loadRemarques();
                              }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                        }
                  })
          },
    // End CRUD Remarque 
    // CRUD MAGASIN


                loadMagasin(){
                
                    axios.get("/api/MagasinProduction/"+this.$route.params.idp).then(({ data }) => (this.BobineMagasin = data.data));
                },

                loadStockBobines(){
                
                    axios.get("/api/Bobines").then(({ data }) => (this.Bobines = data.data));
                },

                newModalMagasin(){
                    this.editmode = false;
                    this.formMagasin.reset();
                    this.dialogVisibleMagasin = true;
                },

                createMagasin(){
                    this.$Progress.start();

                    this.formMagasin.post('/api/MagasinProduction')
                    .then((data)=>{
                        if(data.data.success){
                        this.dialogVisibleMagasin = false;
                        
                        Toast.fire({
                                icon: 'success',
                                title: data.data.message,
                            });
                            this.$Progress.finish();
                            this.loadMagasin();

                        } else {
                        Toast.fire({
                            icon: 'error',
                            title: 'Some error occured! Please try again',
                        });

                        this.$Progress.failed();
                        }
                    })
                    .catch(()=>{

                        Toast.fire({
                            icon: 'error',
                            title: 'Some error occured! Please try again',
                        });
                    })
                },

                deletMagasin(id){
                    Swal.fire({
                        title: 'Attention !',
                        text: "vous voulez vraiment supprimer cette Bobine ??",
                        showCancelButton: true,
                        confirmButtonColor: '#67c23a',
                        cancelButtonColor: '#f56c6c',
                        confirmButtonText: 'Oui, supprimez-le ! '
                        }).then((result) => {

                            // Send request to the server
                                if (result.value) {
                                    this.form.delete('/api/MagasinProduction/'+id).then(()=>{
                                            Swal.fire(
                                            'Supprimé !',
                                            'Votre Bobine a été bien supprimé.',
                                            'success'
                                            );
                                        // Fire.$emit('AfterCreate');
                                        this.loadMagasin();
                                    }).catch((data)=> {
                                        Swal.fire("Failed!", data.message, "warning");
                                    });
                                }
                        })
                },

                
// END CRUD MAGASIN

            // timer
            countToTen() {
            this.started = true
            // if (this.result < 10)
                this.timeout1 = setTimeout(() => {
                this.result1 += 1
                this.countToTen();
                }, 1000)
            },

            stopCountToTen() {
                this.formProduction.productionTime = this.result1
                clearTimeout(this.timeout1)
                this.started = false
                this.result1 = 0
            },

            // qrCode
            // async onInit (promise) {
            //     try {
            //         await promise
            //     } catch (e) {
            //         console.error(e)
            //     } finally {
            //         this.showScanConfirmation = this.camera === "off"
            //     }
            //     },

                playSound () {
                    var audio = new Audio(this.soundBeep);
                    audio.play();
                },

                async onDecode (content) {
                this.formMagasin.num_Bobine = content
                this.playSound()
                this.pause()
                await this.timeout(500)
                Toast.fire({
                        icon: 'success',
                        title: 'Bobine Code Bien Scanner'
                    });
                this.unpause()
                },

                // unpause () {
                // this.camera = 'auto'
                // },

                // pause () {
                // this.camera = 'off'
                // },

                timeout (ms) {
                return new Promise(resolve => {
                    window.setTimeout(resolve, ms)
                })
            },

            // 


          
          loadCategories(){
              axios.get("/api/category/list").then(({ data }) => (this.categories = data.data));
          },
          loadTags(){
              axios.get("/api/tag/list").then(response => {
                  this.autocompleteItems = response.data.data.map(a => {
                      return { text: a.name, id: a.id };
                  });
              }).catch(() => console.warn('Oh. Something went wrong'));
          },
          editModal(product){
              this.editmode = true;
              this.form.reset();
              $('#addNew').modal('show');
              this.form.fill(product);
          },
          newModal(){
              this.editmode = false;
              this.form.reset();
              $('#addNew').modal('show');
          },
           
          newModalProduction(){
              this.editmode = false;
              this.formProduction.reset();
              $('#addNewProduction').modal('show');
          },
          newModalProduction2(){
              this.editmode = false;
              this.formProduction.reset();
              $('#addNewProduction2').modal('show');
          },
          newModalP(){
              this.editmode = false;
              this.form.reset();
              $('#addNewP').modal('show');
          },
          createProduct(){
              this.$Progress.start();

              this.form.post('api/product')
              .then((data)=>{
                if(data.data.success){
                  $('#addNew').modal('hide');

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.$Progress.finish();
                  this.loadProjet();

                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          },
          updateProduct(){
              this.$Progress.start();
              this.form.put('api/product/'+this.form.id)
              .then((response) => {
                  // success
                  $('#addNew').modal('hide');
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });
                  this.$Progress.finish();
                      //  Fire.$emit('AfterCreate');

                  this.loadProjet();
              })
              .catch(() => {
                  this.$Progress.fail();
              });

          },
          ConfermReceptionModal(id){
              Swal.fire({
                  title: 'Confirmation !',
                  text: "Confirmer la réception de form de découpe et cliché",
                  showCancelButton: true,
                  confirmButtonColor: '#67c23a',
                  cancelButtonColor: '#f56c6c',
                  confirmButtonText: 'Oui, Confirmé ! '
                  }).then((result) => {

                      // Send request to the server
                        if (result.value) {
                              this.form.delete('api/product/'+id).then(()=>{
                                      Swal.fire(
                                      'Confirmé !',
                                      'Confirmation Valider',
                                      'success'
                                      );
                                  // Fire.$emit('AfterCreate');
                                  this.loadProjet();
                              }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                        }
                  })
          },
          deletModal(id){
              Swal.fire({
                  title: 'Attention !',
                  text: "vous voulez vraiment supprimer ce devis",
                  showCancelButton: true,
                  confirmButtonColor: '#67c23a',
                  cancelButtonColor: '#f56c6c',
                  confirmButtonText: 'Oui, supprimez-le ! '
                  }).then((result) => {

                      // Send request to the server
                        if (result.value) {
                              this.form.delete('api/product/'+id).then(()=>{
                                      Swal.fire(
                                      'Supprimé !',
                                      'Votre fichier a été supprimé.',
                                      'success'
                                      );
                                  // Fire.$emit('AfterCreate');
                                  this.loadProjet();
                              }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                        }
                  })
          },
        //   check existe
        checkexist() {
            let x = '';
            this.plaqueFormat.forEach((value, index) => {
                if(this.form.formL == value.l && this.form.formD == value.d){
                    x = value.num
                }
            });

            if(x != ''){
                this.form.formCode = x;
            }else{
                 this.form.formCode = 'cette plaque  n\'existe pas !!';
            }

        },

// FicheTechnique
            async printC1 () {
            await this.$htmlToPaper('printMe');
            },

        FicheTechniqueModal() {
            this.editmode = false;
            this.form.reset();
            $("#FicheTechnique").modal("show");
        },
// End FicheTechnique
// Ordre Fabric
        printO() {
                this.$htmlToPaper("printMeO");
            },

             OrdreFabricationModal() {
            this.editmode = false;
            this.form.reset();
            $("#OrdreFabrication").modal("show");
        },
// ENd Ordre Fabric
// qrcodes conditionement
        printCondi() {
            this.$htmlToPaper("printMeCondi");
        },

        CondiModal() {
            this.editmode = false;
            this.form.reset();
            $("#ModalCondi").modal("show");
        },

// end conditionement

// Upload Image
          submitUpload() {
            this.$refs.upload.submit();
        },

        handleCurrentChange(cpage) {
                this.currpage = cpage;
            },

        handleSizeChange(psize) {
                this.pagesize = psize;
            },


        },
        mounted() {

        },
        created() {
            this.$Progress.start();

            this.getValidations();
            this.loadDevisPFD();
            // this.loadMagasin();
            // this.loadStockBobines();
            this.loadCouleurs();
            this.loadRemarques();

            this.$Progress.finish();
        },
        filters: {
            truncate: function (text, length, suffix) {
                return text.substring(0, length) + suffix;
            },
            // 
            minutesAndSeconds (value) {
                var minutes = Math.floor(parseInt(value, 10) / 60)
                var seconds = parseInt(value, 10) - minutes * 60
                return `${minutes} minutes : ${seconds} seconds`
            },
            // 
            RemarqueDates(value){
                return moment(String(value)).format('MM/DD/YYYY HH:mm')
            }
        },
        computed: {
          
        },
    }
</script>
<style >
.scan-confirmation {
  position: absolute;
  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 255, .8);

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}
/* show files PDF Style */
.card-box {
    padding: 20px;
    border-radius: 3px;
    margin-bottom: 30px;
    background-color: #fff;
}

.file-man-box {
    /* padding: 20px; */
    border: 1px solid #e3eaef;
    border-radius: 5px;
    position: relative;
    margin-bottom: 20px
}

.file-man-box .file-close {
    color: #f1556c;
    position: absolute;
    line-height: 24px;
    font-size: 24px;
    right: 10px;
    top: 10px;
    visibility: hidden
}

.file-man-box .file-img-box {
    line-height: 120px;
    text-align: center
}

.file-man-box .file-img-box img {
    height: 64px
}

.file-man-box .file-download {
    font-size: 32px;
    color: #98a6ad;
    position: absolute;
    right: 10px
}

.file-man-box .file-download:hover {
    color: #313a46
}

.file-man-box .file-man-title {
    padding-right: 25px
}

.file-man-box:hover {
    -webkit-box-shadow: 0 0 24px 0 rgba(0, 0, 0, .06), 0 1px 0 0 rgba(0, 0, 0, .02);
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, .06), 0 1px 0 0 rgba(0, 0, 0, .02)
}

.file-man-box:hover .file-close {
    visibility: visible
}
.text-overflow {
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    width: 100%;
    overflow: hidden;
}
h5 {
    font-size: 15px;
}
</style>