<template>
    <section class="content">
      <div class="container-fluid">
<!-- {{ Reclamations.data }} -->
        <el-card shadow="always">
            <div class="row">
                <div class="col-3">
                    <el-button type="primary" @click="newData()" size="small" icon="el-icon-plus" >Add New Reclamation</el-button>
                </div>
                <!-- <div class="col-3">
                    <el-input v-model="searchHotels" @change="filterHotels" placeholder="Filter By Hotel's Name"> 
                        <el-button slot="append" icon="el-icon-search"></el-button>
                    </el-input>
                </div>
                <div class="col-3">
                    <el-select @change="filterType" v-model="searchType" filterable  style="width: 100%" placeholder="Filter By Accommodation">
                        <el-option label="Hotel" value="Hotel"></el-option>
                        <el-option label="Riad" value="Riad"></el-option>
                        <el-option label="Auberge" value="Auberge"></el-option>
                    </el-select>
                </div>
                <div class="col-3">
                    <el-select @change="filterCity" v-model="searchCity" filterable  style="width: 100%" placeholder="Filter By City">
                        <el-option label="Agadir" value="Agadir"></el-option>
                            <el-option label="Marrakech" value="Marrakech"></el-option>
                            <el-option label="Fes" value="Frensh"></el-option>
                            <el-option label="Meknes" value="Meknes"></el-option>
                            <el-option label="Tanger" value="Tanger"></el-option>
                            <el-option label="CasaBlanca" value="CasaBlanca"></el-option>
                            <el-option label="Rabat" value="Rabat"></el-option>
                            <el-option label="Ouarzazat" value="Ouarzazat"></el-option>
                            <el-option label="Tiznit" value="Tiznit"></el-option>
                            <el-option label="Essaouira" value="Essaouira"></el-option>
                    </el-select>
                </div> -->
            </div>
        </el-card>
        <!-- Start Table -->
        <el-card class="mb-3 mt-3">
          <div class="row">
          <div class="col-6">
              <h4 class="ml-3">Reclamations</h4>
          </div>
            <!-- <div class="col-6">
                <el-input v-model="search" @change="filterETC" placeholder="Filter : "> 
                    <el-button slot="append" icon="el-icon-search"></el-button>
                </el-input>
            </div> -->
            <div class="col-12 mt-3">
              <el-table
                    :data="Reclamations.data" border >
                    <el-table-column type="expand">
                        <template slot-scope="props">
                            <el-descriptions class="margin-top" :column="1" border>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <i class="el-icon-mobile-phone"></i>
                                        Note 
                                    </template>
                                    {{ props.row.note}}
                                </el-descriptions-item>
                            </el-descriptions>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="Ticket ID">
                        <template slot-scope="scope">
                            <strong>{{ scope.row.reclamation_title }}</strong>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="Date">
                        <template slot-scope="scope">
                            <strong>{{ scope.row.date }}</strong>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="Ref Booking">
                        <template slot-scope="scope">
                            <strong>{{ scope.row.ref_booking }}</strong>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="Product">
                        <template slot-scope="scope">
                            <strong>{{ scope.row.get_product.title }}</strong>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="Platform">
                        <template slot-scope="scope">
                            <strong>{{ scope.row.get_plateformes.title }}</strong>
                        </template>
                    </el-table-column>
                    <el-table-column align="center"  label="Etat">
                        <template slot-scope="scope">
                            <div v-if="scope.row.etat == 'Processing'">
                                <el-tag  type="" effect="dark" ><strong>{{ scope.row.etat }}</strong></el-tag>
                            </div>
                            <div v-if="scope.row.etat == 'Completed'">
                                <el-tag  type="success" effect="dark" ><strong>{{ scope.row.etat }}</strong></el-tag>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column width="100" align="center">
                        <template slot-scope="{row}">
                                <a href="#" @click="updateHotel(row)" data-toggle="tooltip" data-placement="top" title="Modifier">
                                        <i class="fa fa-edit blue mr-2"></i>
                                </a>
                                <el-divider direction="vertical"></el-divider>
                                
                                <a href="#" @click="deletData(row.id)" data-toggle="tooltip" data-placement="top" title="Supprimer">
                                    <i class="fa fa-trash red"></i>
                                </a>
                        </template>
                    </el-table-column>
              </el-table>
               
               <pagination class="mt-4" :data="Reclamations" @pagination-change-page="loadData"></pagination>
            </div>
            
          </div>
          </el-card>
        <!-- End Table -->
          
          <!-- dialog -->
          <el-dialog
            :title="editmode ? 'Update Reclamation':'New Reclamation'"
            :visible.sync="DialogVisible_global"
            width="70%"
            :before-close="handleClose">
            <!-- Body dialog -->
                <el-form :model="form_global" ref="form_global" class="demo-ruleForm">
                    <fieldset class="row col-12 border p-2 mt-2">
                        <legend  class="w-auto badge"><h5>Informations</h5></legend>
                            <el-form-item class="col-lg-4" label="Ticket ID" prop="reclamation_title" 
                            :rules="[
                                { required: true, message: 'Ticket ID is required' }
                            ]">
                                <el-input v-model="form_global.reclamation_title" autocomplete="off"></el-input>
                            </el-form-item>
                            <el-form-item class="col-lg-4" label="Booking Reference" prop="ref_booking" 
                            :rules="[
                                { required: true, message: 'Reference is required' }
                            ]">
                                <el-input v-model="form_global.ref_booking" autocomplete="off"></el-input>
                            </el-form-item>
                            <el-form-item class="col-lg-4" label="Plateforme" prop="id_plateform" 
                            :rules="[
                                { required: true, message: 'Plateform is required' }
                            ]">
                                <el-select v-model="form_global.id_plateform" filterable  style="width: 100%" placeholder="Select Plateforme">
                                    <el-option
                                        v-for="item in Plateformes"
                                        :key="item.id"
                                        :label="item.title"
                                        :value="item.id"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item class="col-lg-4" label="Product" prop="id_product" 
                            :rules="[
                                { required: true, message: 'Product is required' }
                            ]">
                                    <el-select v-model="form_global.id_product" filterable  style="width: 100%" placeholder="Select Product">
                                        <el-option
                                            v-for="item in products"
                                            :key="item.id"
                                            :label="item.title"
                                            :value="item.id"
                                        ></el-option>
                                    </el-select>
                            </el-form-item>
                            <el-form-item class="col-lg-4" label="Date Reclamed" prop="date" >
                                <el-date-picker placeholder="Pick a date" v-model="form_global.date" value-format="yyyy-MM-dd" style="width: 100%;"></el-date-picker>
                            </el-form-item>
                            <el-form-item class="col-lg-4" label="Etat" prop="etat"
                            :rules="[
                                { required: true, message: 'Hotel Name is required' }
                            ]">
                                <el-select v-model="form_global.etat" style="width: 100%" placeholder="Select Status">
                                        <el-option label="Processing" value="Processing"></el-option>
                                        <el-option label="Completed" value="Completed"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item class="col-lg-12" label="Note" prop="note" >
                                <el-input type="textarea" v-model="form_global.note" autocomplete="off"></el-input>
                            </el-form-item>
                    </fieldset>
                </el-form>
                <!-- ENd body Dialog -->
                <span slot="footer" class="dialog-footer">
                    <el-button @click="DialogVisible_global = false">Annuler</el-button>
                    <el-button type="primary" v-show="!editmode" @click="submitForm('form_global')">Valider</el-button>
                    <el-button type="primary" v-show="editmode" @click="updateData()">Valider</el-button>
                </span>
          </el-dialog>

          <!-- ENd dialog -->
          <!-- Modal files Bon Livraison -->
        <el-dialog
                title="Agency Price"
                :visible.sync="DialogVisiblePrices"
                center>

                <div style="max-width: 500px; margin: 0 auto;">
                            
                            <form
                                @submit="submitPrice"
                                class="mt-4"
                                enctype="multipart/form-data"
                            >
                                <div class="input-group">
                                    <div class="custom-file">
                                        <input
                                            type="file"
                                            name="filename"
                                            class="custom-file-input"
                                            id="inputFileUpload"
                                            v-on:change="onFileChange"
                                        />
                                        <label
                                            class="custom-file-label"
                                            for="inputFileUpload"
                                            >Select Agency Price For The Hotel</label>
                                    </div>
                                    
                                </div>
                                <br />
                                <p class="text-success font-weight-bold">
                                    {{ filename }}
                                </p>
                            
                        <span slot="footer" class="dialog-footer">
                            <el-button type="warning" @click="DialogVisibleBonLivraison = false">Annuler</el-button>
                            <input  type="submit" class="btn btn-success" value="Enregistrer"  />
                        </span>
                    </form>
                </div>
            </el-dialog>
            <!-- end dialog file Bon Livraison -->
  
      </div>
     
    </section>
    <!--  -->
  </template>
  
  
  <script>
      import VueTagsInput from '@johmun/vue-tags-input';
      import moment from 'moment'
  
      export default {
        components: {
            VueTagsInput,
          },
          data () {
              return {
                  DialogVisible_global: false,
                  active: 0,
                  Reclamations: [],
                  products : [],
                  Plateformes: [],
                  search: '',
                  searchType: '',
                  searchCity: '',
                  searchHotels: '',
                  pagesize: 10,
                  currpage: 1,
                  editmode: false,
                //   products : [],
                //   
                DialogVisiblePrices:false,
                filename:'',


                  numberValidateForm: {
                    age: ''
                },
                
                  form_global: new Form({
                    id:'',
                    reclamation_title:'',
                    id_plateform:'',
                    id_product:'',
                    date:'',
                    note:'',
                    etat:'',
                    ref_booking:'',
                  }),

                  autocompleteItems: [],
              }
          },
          methods: {            

                // 

            filterType(){
                if(this.searchType != ""){
                    this.$Progress.start();
                    axios.get('api/filterType/' + this.searchType).then(({ data }) => (this.Hotels = data.data));
                    this.$Progress.finish();
                }else{
                    this.loadData();
                }
            },

            filterHotels(){
                if(this.searchHotels != ""){
                    this.$Progress.start();
                    axios.get('api/filterHotels/' + this.searchHotels).then(({ data }) => (this.Hotels = data.data));
                    this.$Progress.finish();
                }else{
                    this.loadData();
                }
            },

            filterCity(){
                if(this.searchCity != ""){
                    this.$Progress.start();
                    axios.get('api/filterCity/' + this.searchCity).then(({ data }) => (this.Hotels = data.data));
                    this.$Progress.finish();
                }else{
                    this.loadData();
                }
            },

            filterETC(){
                if(this.search != ''){
                    this.$Progress.start();
                    axios.get('api/filterHotels/' + this.search).then(({ data }) => (this.Hotels = data.data));
                    this.$Progress.finish();
                }else{
                    this.loadData();
                }
            },
            // CRUD
            newData(){
                this.form_global.reset();
                this.editmode = false;
                // this.disableDevisPV = false;
                this.DialogVisible_global = true;
                this.active=0;
            },

            createData(){
            this.$Progress.start();

              this.form_global.post('api/reclamations')
              .then((data)=>{
                if(data.data.success){
                 this.DialogVisible_global = false

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.$Progress.finish();
                  this.loadData();
                //   this.loadDataPrepared();

                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          },

          submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.createData();
                } else {
                    console.log('error submit!!');
                    return false;
                }
                });
            },

            loadData(page = 1){
                this.$Progress.start();
                axios.get('api/reclamations?page=' + page).then(({ data }) => (this.Reclamations = data.data));
                this.$Progress.finish();
            },

            loadProducts(){
                this.$Progress.start();
                axios.get("api/indexTours").then(({ data }) => (this.products = data.data));
                this.$Progress.finish();
            },
            loadPlateformes(){
                this.$Progress.start();
                axios.get('api/indexPlateformes').then(({ data }) => (this.Plateformes = data.data));
                this.$Progress.finish();
            },

            updateHotel(reservation){
                this.editmode = true;
                this.form_global.reset();
                this.DialogVisible_global = true;
                this.form_global.fill(reservation);
            },

            updateData(){
              this.$Progress.start();
              this.form_global.put('api/reclamations/'+this.form_global.id)
              .then((response) => {
                  // success
                  this.DialogVisible_global = false
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });
                  this.$Progress.finish();

                  this.loadData();
              })
              .catch(() => {
                  this.$Progress.fail();
              });

          },

          deletData(id){
              Swal.fire({
                  title: 'Attention !',
                  text: "You really want to delete this Reclamation ??",
                  showCancelButton: true,
                  confirmButtonColor: '#67c23a',
                  cancelButtonColor: '#f56c6c',
                  confirmButtonText: 'Yes, Delete-it ! '
                  }).then((result) => {

                      // Send request to the server
                        if (result.value) {
                              this.form_global.delete('api/reclamations/'+id).then(()=>{
                                      Swal.fire(
                                      'Deleted !',
                                      'Reclamation Deleted Successfully',
                                      'success'
                                      );
                                  // Fire.$emit('AfterCreate');
                                  this.loadData();
                              }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                        }
                  })
          },
        //   End CRUD

            handleClose(done) {
                this.$confirm('Are you sure you want to close this dialog ?')
                .then(_ => {
                    done();
                })
                .catch(_ => {});
            },

            handleCurrentChange2(val) {
                this.currentRow = val;
            },

            // END
  
           
            
            loadCategories(){
                axios.get("/api/category/list").then(({ data }) => (this.categories = data.data));
            },
            loadTags(){
                axios.get("/api/tag/list").then(response => {
                    this.autocompleteItems = response.data.data.map(a => {
                        return { text: a.name, id: a.id };
                    });
                }).catch(() => console.warn('Oh. Something went wrong'));
            },
            editModal(product){
                this.editmode = true;
                this.form.reset();
                $('#addNew').modal('show');
                this.form.fill(product);
            },
            newModal(){
                this.editmode = false;
                this.form.reset();
                $('#addNew').modal('show');
            },
            
            deletModal(id){
                Swal.fire({
                    title: 'Attention !',
                    text: "you really want to delete this Hotel ??",
                    showCancelButton: true,
                    confirmButtonColor: '#67c23a',
                    cancelButtonColor: '#f56c6c',
                    confirmButtonText: 'Yes, Delete-it ! '
                    }).then((result) => {
  
                        // Send request to the server
                          if (result.value) {
                                this.form.delete('api/product/'+id).then(()=>{
                                        Swal.fire(
                                        'Deleted !',
                                        'Votre fichier a été supprimé.',
                                        'success'
                                        );
                                    // Fire.$emit('AfterCreate');
                                    this.loadProducts();
                                }).catch((data)=> {
                                    Swal.fire("Failed!", data.message, "warning");
                                });
                          }
                    })
            },
          
           handleCurrentChange(cpage) {
                  this.currpage = cpage;
              },
          handleSizeChange(psize) {
                  this.pagesize = psize;
              },
  
  
          },
          mounted() {
              
          },
          created() {
              this.$Progress.start();
  
              this.loadData();
              this.loadPlateformes();
              this.loadProducts();
  
              this.$Progress.finish();
          },
          filters: {
            OfferDates(value){
                return moment(String(value)).format('MM-DD-YY')
            },
              truncate: function (text, length, suffix) {
                  return text.substring(0, length) + suffix;
              },
          },
          computed: {
            filteredItems() {
              return this.autocompleteItems.filter(i => {
                return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
              });
            },
          },
      }
  </script>
  