<template>
  <section class="content">
    <div class="container-fluid">
        <el-tabs  tab-position="top" style="margin-bottom: 30px;">
                        <el-tab-pane label="Reclamation Projets">
                            <el-card class="mb-3 mt-3">
                                <div slot="header" class="clearfix text-center">
                                    <h3> Liste Des Réclamation Projets </h3>
                                </div>
                            <div class="row">
                            <div class="col-6">
                                <el-input v-model="search" size="mini" placeholder="Filter : "/>
                            </div>
                            <div class="col-12 mt-3">
                                <el-table
                                    :data="Remarques.filter(data => !search || data.id_projet.toLowerCase().includes(search.toLowerCase()) ).slice((currpage - 1) * pagesize, currpage * pagesize)" style="width: 100%"
                                    border >
                                
                                <el-table-column label="Reclamer par ">
                                    <template slot-scope="scope">
                                        <span>{{ scope.row.get_user.name }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Projet ">
                                    <template slot-scope="scope">
                                        <span>{{ scope.row.id_projet }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="étape">
                                    <template slot-scope="scope">
                                        <span>{{ scope.row.etape }}</span>
                                    </template>
                                </el-table-column>

                                <el-table-column label="Réclamation">
                                    <template slot-scope="scope">
                                        <span>{{ scope.row.message }}</span>
                                    </template>
                                </el-table-column>
                                
                                <el-table-column v-if="$gate.isAdmin()" width="150" align="center">
                                    <template slot-scope="{row}">
                                            <a href="#" @click="updateCompte(row)" data-toggle="tooltip" data-placement="top" title="Modifier">
                                                    <i class="fa fa-edit blue mr-2"></i>
                                            </a>
                                            <el-divider direction="vertical"></el-divider>
                                            <a href="#" @click="deletData(row.id)" data-toggle="tooltip" data-placement="top" title="Supprimer">
                                                <i class="fa fa-trash red"></i>
                                            </a>
                                            
                                    </template>
                                </el-table-column>
                                </el-table>
                                <!--  -->
                                <el-pagination background
                                        layout="prev, pager, next, sizes, total, jumper"
                                        :page-sizes = "[5, 10, 15, 20]"
                                        :page-size = "pagesize"
                                        :total="Remarques.length"
                                        @current-change = "handleCurrentChange"
                                        @size-change = "handleSizeChange"
                                        >
                                </el-pagination>
                            </div>
                            </div>
                            </el-card>
                        </el-tab-pane>
                        <el-tab-pane label="Reclamation Commandes">
                            <el-card class="mb-3 mt-3">
                                    <div slot="header" class="clearfix text-center">
                                        <h3> Liste Des Réclamation Commandes </h3>
                                    </div>
                                <div class="row">
                                <div class="col-6">
                                    <el-input v-model="search" size="mini" placeholder="Filter : "/>
                                </div>
                                <div class="col-12 mt-3">
                                    <el-table
                                        :data="RemarquesC.filter(data => !search || data.id_commande.toLowerCase().includes(search.toLowerCase()) ).slice((currpage - 1) * pagesize, currpage * pagesize)" style="width: 100%"
                                        border >
                                    
                                    <el-table-column label="Reclamer par ">
                                        <template slot-scope="scope">
                                            <span>{{ scope.row.get_user.name }}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="La commande ">
                                        <template slot-scope="scope">
                                            <span>{{ scope.row.id_commande }}</span>
                                        </template>
                                    </el-table-column>
                                    <!-- <el-table-column label="étape">
                                        <template slot-scope="scope">
                                            <span>{{ scope.row.etape }}</span>
                                        </template>
                                    </el-table-column> -->

                                    <el-table-column label="Réclamation">
                                        <template slot-scope="scope">
                                            <span>{{ scope.row.message }}</span>
                                        </template>
                                    </el-table-column>
                                    
                                    <el-table-column v-if="$gate.isAdmin()" width="150" align="center">
                                        <template slot-scope="{row}">
                                                <a href="#" @click="updateCompte(row)" data-toggle="tooltip" data-placement="top" title="Modifier">
                                                        <i class="fa fa-edit blue mr-2"></i>
                                                </a>
                                                <el-divider direction="vertical"></el-divider>
                                                <a href="#" @click="deletData(row.id)" data-toggle="tooltip" data-placement="top" title="Supprimer">
                                                    <i class="fa fa-trash red"></i>
                                                </a>
                                                
                                        </template>
                                    </el-table-column>
                                    </el-table>
                                    <!--  -->
                                    <el-pagination background
                                            layout="prev, pager, next, sizes, total, jumper"
                                            :page-sizes = "[5, 10, 15, 20]"
                                            :page-size = "pagesize"
                                            :total="Remarques.length"
                                            @current-change = "handleCurrentChange"
                                            @size-change = "handleSizeChange"
                                            >
                                    </el-pagination>
                                </div>
                                </div>
                            </el-card>

                    </el-tab-pane>
        </el-tabs>
        <!--  -->
        

        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog modal-xl" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Ajouter Nouvelle Réclamation</h5>
                    <h5 class="modal-title" v-show="editmode">Modifier la Réclamation</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form>
                    <div class="modal-body">
                        <fieldset class="border p-2">
                            <!-- <legend class="w-auto badge badge-success"><h6> Informations de Siège </h6></legend> -->
                            <div class="row">
                                <div class="form-group col-4">
                                    <label>N° Projet:</label>
                                    <input v-model="form.numSerie" type="text" name="numSerie"
                                        class="form-control" :class="{ 'is-invalid': form.errors.has('numSerie') }">
                                    <has-error :form="form" field="numSerie"></has-error>
                                </div>
                                <div class="form-group col-4">
                                    <label>Date:</label>
                                    <input v-model="form.datePlaque" type="date" name="datePlaque"
                                        class="form-control" :class="{ 'is-invalid': form.errors.has('datePlaque') }">
                                    <has-error :form="form" field="datePlaque"></has-error>
                                </div>
                                
                                <div class="form-group col-4">
                                    <label>Status :</label>
                                    <select v-model="form.machine" class="custom-select mr-sm-2" id="machine">
                                            <option disabled selected>...</option>
                                            <option value="1">En Cours</option>
                                            <option value="2">Valider</option>
                                            <option value="3">Refuser</option>
                                            <option value="4">à traister</option>
                                        </select>
                                </div>
                                <div class="form-group col-12">
                                    <label>Réclamation :</label>
                                    <textarea v-model="form.remarques" name="remarques" class="form-control" id="exampleFormControlTextarea1" rows="5"></textarea>
                                    <has-error :form="form" field="fournisseur"></has-error>
                                </div>
                                 
                                
                            </div>
                        </fieldset>
                        <!--  -->

                    </div>
                    <div class="modal-footer">
                        <el-button type="danger" size="small" data-dismiss="modal" plain>Annuler</el-button>
                        <el-button type="success" v-show="editmode" size="small" @click="updateProduct()" plain>Valider</el-button>
                        <el-button type="success" v-show="!editmode" size="small" @click="createProduct()" plain>Valider</el-button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
    import VueTagsInput from '@johmun/vue-tags-input';

    export default {
      components: {
          VueTagsInput,
        },
        data () {
            return {

                Remarques:[],
                RemarquesC:[],

                 tableData: [{
                    projet:'25632',
                    reclamation:'probléme sur le BAT (code) a modifier',
                    date: new Date().toISOString().slice(0,10),
                    auteur:'Mohamed Ben Ali',
                    statut:'En cours',
                }],

                search: '',
                pagesize: 10,
                currpage: 1,
                editmode: false,
                products : [],
                form: new Form({
                    id : '',
                    category : '',
                    name: '',
                    description: '',
                    tags:  [],
                    photo: '',
                    category_id: '',
                    price: '',
                    photoUrl: '',
                }),
                categories: [],
              
                tag:  '',
                autocompleteItems: [],
            }
        },
        methods: {

            loadRemarques(){
              axios.get("api/ProjetRemarques").then(({ data }) => (this.Remarques = data.data));
        },

            loadRemarquesC(){
              axios.get("api/CommandesRemarques").then(({ data }) => (this.RemarquesC = data.data));
        },

        // to delete after

         
          loadProducts(){

            // if(this.$gate.isAdmin()){
              axios.get("api/product").then(({ data }) => (this.products = data.data));
            // }
          },
          loadCategories(){
              axios.get("/api/category/list").then(({ data }) => (this.categories = data.data));
          },
          loadTags(){
              axios.get("/api/tag/list").then(response => {
                  this.autocompleteItems = response.data.data.map(a => {
                      return { text: a.name, id: a.id };
                  });
              }).catch(() => console.warn('Oh. Something went wrong'));
          },
          editModal(product){
              this.editmode = true;
              this.form.reset();
              $('#addNew').modal('show');
              this.form.fill(product);
          },
          newModal(){
              this.editmode = false;
              this.form.reset();
              $('#addNew').modal('show');
          },
          createProduct(){
              this.$Progress.start();

              this.form.post('api/product')
              .then((data)=>{
                if(data.data.success){
                  $('#addNew').modal('hide');

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.$Progress.finish();
                  this.loadProducts();

                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          },
          updateProduct(){
              this.$Progress.start();
              this.form.put('api/product/'+this.form.id)
              .then((response) => {
                  // success
                  $('#addNew').modal('hide');
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });
                  this.$Progress.finish();
                      //  Fire.$emit('AfterCreate');

                  this.loadProducts();
              })
              .catch(() => {
                  this.$Progress.fail();
              });

          },
          deletModal(id){
              Swal.fire({
                  title: 'Attention !',
                  text: "vous voulez vraiment supprimer ce devis",
                  showCancelButton: true,
                  confirmButtonColor: '#67c23a',
                  cancelButtonColor: '#f56c6c',
                  confirmButtonText: 'Oui, supprimez-le ! '
                  }).then((result) => {

                      // Send request to the server
                        if (result.value) {
                              this.form.delete('api/product/'+id).then(()=>{
                                      Swal.fire(
                                      'Supprimé !',
                                      'Votre fichier a été supprimé.',
                                      'success'
                                      );
                                  // Fire.$emit('AfterCreate');
                                  this.loadProducts();
                              }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                        }
                  })
          },
        
         handleCurrentChange(cpage) {
                this.currpage = cpage;
            },
        handleSizeChange(psize) {
                this.pagesize = psize;
            },


        },
        mounted() {
            
        },
        created() {
            this.$Progress.start();

            this.loadRemarques();
            this.loadRemarquesC();

            this.$Progress.finish();
        },
        filters: {
           
        },
        computed: {
         
        },
    }
</script>
