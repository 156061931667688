export const  Machine1 = [
    {
        value: '67',
        label: 'Z-67'
    }, 
    {
        value: '72',
        label: 'Z-72'
    }, 
    {
        value: '75',
        label: 'Z-75'
    }, 
    {
        value: '78',
        label: 'Z-78'
    }, 
    {
        value: '80',
        label: 'Z-80'
    }, 
    {
        value: '94',
        label: 'Z-94'
    }, 
    {
        value: '108',
        label: 'Z-108'
    }, 
    {
        value: '120',
        label: 'Z-120'
    }, 
    {
        value: '136',
        label: 'Z-136'
    }, 
    {
        value: '145',
        label: 'Z-145'
    },
    {
        value: '155',
        label: 'Z-155'
    }, 
    {
        value: '192',
        label: 'Z-192'
    }, 
 ];
//  
export const  Machine2 = [
    {
        value: '67',
        label: 'Z-67'
    }, 
    {
        value: '75',
        label: 'Z-75'
    }, 
    {
        value: '80',
        label: 'Z-80'
    }, 
    {
        value: '88',
        label: 'Z-88'
    }, 
    {
        value: '90',
        label: 'Z-90'
    }, 
    {
        value: '94',
        label: 'Z-94'
    }, 
    {
        value: '96',
        label: 'Z-96'
    }, 
    {
        value: '102',
        label: 'Z-102'
    }, 
];
// 
export const  Machine3 = [
    {
        value: '94',
        label: 'Z-94'
    }, 
];
// Mandrin Stock
export const Mandrin = [
    
    {
        value :'78',
        label : 'Mandrin 78'
    },
    {
        value :'80',
        label : 'Mandrin 80'
    },
    {
        value :'55',
        label : 'Mandrin 55'
    },
    {
        value :'48',
        label : 'Mandrin 48'
    },
    {
        value :'38',
        label : 'Mandrin 38'
    },
    
]
// Etiquettes
export const Etiquettes = [
    
    {
        value :'1',
        label : 'Spitzpaprik Natur lieblinge'
    },
    {
        value :'2',
        label : 'Poivron 500g REC TH REP'
    },
]
// CLients
export const Clients = [
    
    {
        value :'1',
        label : 'Spitzpaprik'
    },
    {
        value :'2',
        label : 'SUNFRUCT'
    },
]