<template>
  <section class="content">
    <div class="container-fluid">
        <el-card class="mb-3 mt-3">
            <div slot="header" class="clearfix text-center">
                <h3> Liste Des Comptes Clients </h3>
            </div>
        <div class="row">
        <div class="col-6">
            <el-button type="primary" @click="newCompte()" size="small" icon="el-icon-plus">Ouverture de Compte</el-button>
        </div>
          <div class="col-6">
              <el-input v-model="search" @change="filterClient" size="mini" placeholder="Filter : "/>
          </div>
          <div class="col-12 mt-3">
            <el-table
                  :data="clients.data"
                  border >
            <el-table-column type="expand">
                <template  slot-scope="props">
                    <div class="row">
                        <div class="col ml-5">
                            <h5>Informations de Siège :</h5>
                            <p class="ml-5">{{props.row.typeLancementCMD}}</p>
                            <div>
                                <p class="ml-5"><strong> Siége Social: </strong> {{ props.row.siegeSocial }}</p>
                                <p class="ml-5"><strong> Ville : </strong> {{ props.row.villeSocial }}</p>
                                <p class="ml-5"><strong> N° ICE : </strong> {{ props.row.ice }}</p>
                                <p class="ml-5"><strong> N° R.C : </strong> {{ props.row.NumRC }} - {{ props.row.villeRC }} - {{ props.row.CodeRC }}</p>
                            </div>
                            <h5>Façon de lancement :</h5>
                            <p class="ml-5">{{props.row.typeLancementCMD}}</p>
                            <div v-if="props.row.typeLancementCMD == 'Par telephone'">
                                <p class="ml-5"><strong> Nom Prénom: </strong> {{ props.row.RLancementCMDName }}</p>
                                <p class="ml-5"><strong> E-mail: </strong> {{ props.row.RLancementCMDemail }}</p>
                                <p class="ml-5"><strong> Télé: </strong> {{ props.row.RLancementCMDtele }}</p>
                            </div>
                            <h5>Responsable Comptabilité :</h5>
                            <p class="ml-5"><strong> Nom Prénom: </strong> {{ props.row.RComptableName }}</p>
                            <p class="ml-5"><strong> E-mail: </strong> {{ props.row.RComptableemail }}</p>
                            <p class="ml-5"><strong> Télé: </strong> {{ props.row.RComptabletele }}</p>
                            <h5>Gérant :</h5>
                            <p class="ml-5"><strong> Nom Prénom: </strong> {{ props.row.GerantName }}</p>
                            <p class="ml-5"><strong> E-mail: </strong> {{ props.row.Gerantemail }}</p>
                            <p class="ml-5"><strong> Télé: </strong> {{ props.row.Geranttele }}</p>
                        </div>
                        <h5>Régime Facturation :</h5>
                        <div v-if="props.row.RegimeFacturation == 'TVA'" class="col">
                            <p class="ml-5"><strong> Regime Facturation: </strong> {{ props.row.RegimeFacturation }}</p>
                        </div>
                        <div v-if="props.row.RegimeFacturation == 'AT'" class="col">
                            <p class="ml-5"><strong> Regime Facturation: </strong> {{ props.row.RegimeFacturation }}</p>
                            <p class="ml-5"><strong> Nom de Transitaire : </strong> {{ props.row.TransitaireName }}</p>
                            <p class="ml-5"><strong> Tele de Transitaire : </strong> {{ props.row.Transitairetele }}</p>
                            <h5>Responsable A.T :</h5>
                            <p class="ml-5"><strong> Nom Prénom: </strong> {{ props.row.ResponsablATName }}</p>
                            <p class="ml-5"><strong> E-mail: </strong> {{ props.row.ResponsablATemail }}</p>
                            <p class="ml-5"><strong> Télé: </strong> {{ props.row.ResponsablATtele }}</p>
                            <h5>Modalité de Paiement :</h5>
                            <p class="ml-5">{{ props.row.ModalitePaiment }}</p>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label=" Nom de societe ">
                <template slot-scope="scope">
                    <span>{{ scope.row.name }}</span>
                </template>
            </el-table-column>
            <el-table-column label="Domaine d'activité">
                <template slot-scope="scope">
                    <span>{{ scope.row.domaineActivite }}</span>
                </template>
            </el-table-column>
            <el-table-column label="Télé">
                <template slot-scope="scope">
                    <span>{{ scope.row.tele }}</span>
                </template>
            </el-table-column>
            <el-table-column label="E-mail">
                <template slot-scope="scope">
                    <span>{{ scope.row.email }}</span>
                </template>
            </el-table-column>
            <el-table-column v-if="$gate.isAdmin()" width="150" align="center">
                <template slot-scope="{row}">
                        <a href="#" @click="updateCompte(row)" data-toggle="tooltip" data-placement="top" title="Modifier">
                                <i class="fa fa-edit blue mr-2"></i>
                        </a>
                        <el-divider direction="vertical"></el-divider>
                        <a href="#" @click="deletData(row.id)" data-toggle="tooltip" data-placement="top" title="Supprimer">
                            <i class="fa fa-trash red"></i>
                        </a>
                </template>
            </el-table-column>
            </el-table>
             <!--  -->
             <pagination class="mt-4" :data="clients" @pagination-change-page="loadData"></pagination>
          </div>
        </div>
        </el-card>

        <!-- dialog Compte-->
        <el-dialog
            :title="editmode ? 'Modifier Le Compte':'Ouverture De Compte'"
            :visible.sync="DialogVisible"
            width="90%"
            :before-close="handleClose">
            <!-- Body dialog -->
                <el-form :model="form" >
                    <fieldset class="border p-2 mt-2">
                            <legend class="w-auto badge"><h5>Informations de Siège</h5></legend>
                            <div class="row">
                                <el-form-item 
                                    class="text-center col-lg-6"
                                    prop="name"
                                    label="Nom de la societe"
                                >
                                    <el-input type="text" v-model="form.name" placeholder="Nom de la societe" autocomplete="off"></el-input>
                                </el-form-item>
                                <el-form-item 
                                    class="text-center col-lg-6"
                                    prop="ice"
                                    label="ICE"
                                >
                                    <el-input type="text" v-model="form.ice" placeholder="ICE" autocomplete="off"></el-input>
                                </el-form-item>

                                <fieldset class="border mb-2 col-lg-6">
                                    <div class="row">
                                        <el-form-item 
                                            class="text-center col-lg-6"
                                            prop="siegeSocial"
                                            label="Siége Social (Adresse)"
                                        >
                                            <el-input type="text" v-model="form.siegeSocial" placeholder="Siége Social (Adresse)" autocomplete="off"></el-input>
                                        </el-form-item>
                                        <el-form-item 
                                            class="text-center col-lg-6"
                                            prop="villeSocial"
                                            label="Ville"
                                        >
                                            <el-input type="text" v-model="form.villeSocial" placeholder="Ville" autocomplete="off"></el-input>
                                        </el-form-item>
                                    </div>
                                </fieldset>

                                <fieldset class="border mb-2 col-lg-6">
                                    <div class="row">
                                        <el-form-item 
                                            class="text-center col-lg-4"
                                            prop="NumRC"
                                            label="N° R.C"
                                        >
                                            <el-input type="text" v-model="form.NumRC" placeholder="N° R.C" autocomplete="off"></el-input>
                                        </el-form-item>
                                        <el-form-item 
                                            class="text-center col-lg-4"
                                            prop="villeRC"
                                            label="Ville"
                                        >
                                            <el-input type="text" v-model="form.villeRC" placeholder="Ville" autocomplete="off"></el-input>
                                        </el-form-item>
                                        <el-form-item 
                                            class="text-center col-lg-4"
                                            prop="CodeRC"
                                            label="Code"
                                        >
                                            <el-input type="text" v-model="form.CodeRC" placeholder="Code" autocomplete="off"></el-input>
                                        </el-form-item>
                                    </div>
                                </fieldset>

                                <el-form-item 
                                    class="text-center col-lg-3"
                                    prop="domaineActivite"
                                    label="Domaine d'activité"
                                >
                                    <el-input type="text" v-model="form.domaineActivite" placeholder="Domaine d'activité" autocomplete="off"></el-input>
                                </el-form-item>
                                <el-form-item 
                                    class="text-center col-lg-3"
                                    prop="email"
                                    label="E-mail"
                                >
                                    <el-input type="email" v-model="form.email" placeholder="E-mail" autocomplete="off"></el-input>
                                </el-form-item>
                                <el-form-item 
                                    class="text-center col-lg-3"
                                    prop="tele"
                                    label="N° Téléphone(GSM)"
                                >
                                    <el-input type="text" v-model="form.tele" placeholder="N° Téléphone(GSM)" autocomplete="off"></el-input>
                                </el-form-item>
                                <el-form-item 
                                    class="text-center col-lg-3"
                                    prop="fix"
                                    label="N° Téléphone(FIX)"
                                >
                                    <el-input type="text" v-model="form.fix" placeholder="N° Téléphone(FIX)" autocomplete="off"></el-input>
                                </el-form-item>
                            </div>
                    </fieldset>
                    <fieldset class="border p-2 mt-2">
                            <legend class="w-auto badge"><h5>Façon de lancer la commande</h5></legend>
                            <el-form-item 
                                class="text-center"
                                prop="typeLancementCMD"
                            >
                                <el-radio-group v-model="form.typeLancementCMD">
                                    <el-radio label="Obligation Bon de commande" ></el-radio>
                                    <el-radio label="Par telephone" ></el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <fieldset v-if="form.typeLancementCMD == 'Par telephone'" class="border p-2 mt-2">
                                    <legend  class="w-auto badge"><h5>Résponsable a contacter pour le lancement de commande</h5></legend>
                                    <div class="row">
                                        <el-form-item 
                                            class="text-center col-lg-3"
                                            prop="RLancementCMDName"
                                            label="Nom Prénom"
                                        >
                                            <el-input type="text" v-model="form.RLancementCMDName" placeholder="Nom Prénom" autocomplete="off"></el-input>
                                        </el-form-item>
                                        <el-form-item 
                                            class="text-center col-lg-3"
                                            prop="RLancementCMDemail"
                                            label="E-mail"
                                        >
                                            <el-input type="email" v-model="form.RLancementCMDemail" placeholder="E-mail" autocomplete="off"></el-input>
                                        </el-form-item>
                                        <el-form-item 
                                            class="text-center col-lg-3"
                                            prop="RLancementCMDtele"
                                            label="N° Téléphone(GSM)"
                                        >
                                            <el-input type="text" v-model="form.RLancementCMDtele" placeholder="N° Téléphone(GSM)" autocomplete="off"></el-input>
                                        </el-form-item>
                                        <el-form-item 
                                            class="text-center col-lg-3"
                                            prop="RLancementCMDfix"
                                            label="N° Téléphone(FIX)"
                                        >
                                            <el-input type="text" v-model="form.RLancementCMDfix" placeholder="N° Téléphone(FIX)" autocomplete="off"></el-input>
                                        </el-form-item>
                                    </div>
                            </fieldset>
                    </fieldset>
                    
                    <fieldset class="border p-2 mt-2">
                            <legend class="w-auto badge"><h5>Résponsable Comptabilité</h5></legend>
                            <div class="row">
                                <el-form-item 
                                    class="text-center col-lg-3"
                                    prop="RComptableName"
                                    label="Nom Prénom"
                                >
                                    <el-input type="text" v-model="form.RComptableName" placeholder="Nom Prénom" autocomplete="off"></el-input>
                                </el-form-item>
                                <el-form-item 
                                    class="text-center col-lg-3"
                                    prop="RComptableemail"
                                    label="E-mail"
                                >
                                    <el-input type="email" v-model="form.RComptableemail" placeholder="E-mail" autocomplete="off"></el-input>
                                </el-form-item>
                                <el-form-item 
                                    class="text-center col-lg-3"
                                    prop="RComptabletele"
                                    label="N° Téléphone(GSM)"
                                >
                                    <el-input type="text" v-model="form.RComptabletele" placeholder="N° Téléphone(GSM)" autocomplete="off"></el-input>
                                </el-form-item>
                                <el-form-item 
                                    class="text-center col-lg-3"
                                    prop="RComptablefix"
                                    label="N° Téléphone(FIX)"
                                >
                                    <el-input type="text" v-model="form.RComptablefix" placeholder="N° Téléphone(FIX)" autocomplete="off"></el-input>
                                </el-form-item>
                            </div>
                    </fieldset>
                    <fieldset class="border p-2 mt-2">
                            <legend class="w-auto badge"><h5>Gérant</h5></legend>
                            <div class="row">
                                <el-form-item 
                                    class="text-center col-lg-3"
                                    prop="GerantName"
                                    label="Nom Prénom"
                                >
                                    <el-input type="text" v-model="form.GerantName" placeholder="Nom Prénom" autocomplete="off"></el-input>
                                </el-form-item>
                                <el-form-item 
                                    class="text-center col-lg-3"
                                    prop="Gerantemail"
                                    label="E-mail"
                                >
                                    <el-input type="email" v-model="form.Gerantemail" placeholder="E-mail" autocomplete="off"></el-input>
                                </el-form-item>
                                <el-form-item 
                                    class="text-center col-lg-3"
                                    prop="Geranttele"
                                    label="N° Téléphone(GSM)"
                                >
                                    <el-input type="text" v-model="form.Geranttele" placeholder="N° Téléphone(GSM)" autocomplete="off"></el-input>
                                </el-form-item>
                                <el-form-item 
                                    class="text-center col-lg-3"
                                    prop="Gerantfix"
                                    label="N° Téléphone(FIX)"
                                >
                                    <el-input type="text" v-model="form.Gerantfix" placeholder="N° Téléphone(FIX)" autocomplete="off"></el-input>
                                </el-form-item>
                            </div>
                    </fieldset>
                    <fieldset class="border p-2 mt-2">
                            <legend class="w-auto badge"><h5>Regime de Facturation</h5></legend>
                            <el-form-item 
                                class="text-center"
                                prop="RegimeFacturation"
                            >
                                <el-radio-group v-model="form.RegimeFacturation">
                                    <el-radio label="AT" ></el-radio>
                                    <el-radio label="TVA" ></el-radio>
                                </el-radio-group>
                            </el-form-item>

                            <fieldset v-if="form.RegimeFacturation == 'AT'" class="border p-2 mt-2">
                                <legend class="w-auto badge"><h5>Transitaire</h5></legend>
                                    <div class="row">
                                        <el-form-item 
                                            class="text-center col-lg-6"
                                            prop="TransitaireName"
                                            label="Nom Prénom"
                                        >
                                            <el-input type="text" v-model="form.TransitaireName" placeholder="Nom Prénom" autocomplete="off"></el-input>
                                        </el-form-item>
                                        
                                        <el-form-item 
                                            class="text-center col-lg-6"
                                            prop="Transitairetele"
                                            label="N° Téléphone(GSM)"
                                        >
                                            <el-input type="text" v-model="form.Transitairetele" placeholder="N° Téléphone(GSM)" autocomplete="off"></el-input>
                                        </el-form-item>
                                </div>
                            </fieldset>
                            <fieldset v-if="form.RegimeFacturation == 'AT'" class="border p-2 mt-2">
                                <legend class="w-auto badge"><h5>Responsable A.T Chez CLient</h5></legend>
                                    <div class="row">
                                        <el-form-item 
                                            class="text-center col-lg-3"
                                            prop="ResponsablATName"
                                            label="Nom Prénom"
                                        >
                                            <el-input type="text" v-model="form.ResponsablATName" placeholder="Nom Prénom" autocomplete="off"></el-input>
                                        </el-form-item>
                                        <el-form-item 
                                            class="text-center col-lg-3"
                                            prop="ResponsablATemail"
                                            label="E-mail"
                                        >
                                            <el-input type="email" v-model="form.ResponsablATemail" placeholder="E-mail" autocomplete="off"></el-input>
                                        </el-form-item>
                                        <el-form-item 
                                            class="text-center col-lg-3"
                                            prop="ResponsablATtele"
                                            label="N° Téléphone(GSM)"
                                        >
                                            <el-input type="text" v-model="form.ResponsablATtele" placeholder="N° Téléphone(GSM)" autocomplete="off"></el-input>
                                        </el-form-item>
                                        
                                        <el-form-item 
                                            class="text-center col-lg-3"
                                            prop="Transitairefix"
                                            label="N° Téléphone(FIX)"
                                        >
                                            <el-input type="text" v-model="form.Transitairefix" placeholder="N° Téléphone(FIX)" autocomplete="off"></el-input>
                                        </el-form-item>
                                </div>
                            </fieldset>
                            <fieldset v-if="form.RegimeFacturation == 'AT'" class="border p-2 mt-2">
                                <legend class="w-auto badge"><h5>Autre Responsable à contactez</h5></legend>
                                    <div class="row">
                                        <el-form-item 
                                            class="text-center col-lg-3"
                                            prop="ResponsablATName2"
                                            label="Nom Prénom"
                                        >
                                            <el-input type="text" v-model="form.ResponsablATName2" placeholder="Nom Prénom" autocomplete="off"></el-input>
                                        </el-form-item>
                                        <el-form-item 
                                            class="text-center col-lg-3"
                                            prop="ResponsablATemail2"
                                            label="E-mail"
                                        >
                                            <el-input type="email" v-model="form.ResponsablATemail2" placeholder="E-mail" autocomplete="off"></el-input>
                                        </el-form-item>
                                        <el-form-item 
                                            class="text-center col-lg-3"
                                            prop="ResponsablATtele2"
                                            label="N° Téléphone(GSM)"
                                        >
                                            <el-input type="text" v-model="form.ResponsablATtele2" placeholder="N° Téléphone(GSM)" autocomplete="off"></el-input>
                                        </el-form-item>
                                        
                                        <el-form-item 
                                            class="text-center col-lg-3"
                                            prop="Transitairefix2"
                                            label="N° Téléphone(FIX)"
                                        >
                                            <el-input type="text" v-model="form.Transitairefix2" placeholder="N° Téléphone(FIX)" autocomplete="off"></el-input>
                                        </el-form-item>
                                </div>
                            </fieldset>
                    </fieldset>
                    <fieldset class="border p-2 mt-2">
                            <legend class="w-auto badge"><h5>LIEU DE LIVRAISON</h5></legend>
                            <el-form-item 
                                class="text-center col-lg-12"
                                prop="lieu_livraison"
                            >
                                <el-input type="text" v-model="form.lieu_livraison" placeholder="Merci De Saisie Le Lieu De Livraison" autocomplete="off"></el-input>
                            </el-form-item>
                    </fieldset>
                    <fieldset class="border p-2 mt-2">
                            <legend class="w-auto badge"><h5>Modalité de Paiement</h5></legend>
                            <el-form-item 
                                class="text-center"
                                prop="ModalitePaiment"
                            >
                                <el-radio-group v-model="form.ModalitePaiment">
                                    <el-radio label="50% A la commande , 50% a la livraison" ></el-radio>
                                    <el-radio label="30 Jours" ></el-radio>
                                    <el-radio label="60 Jours" ></el-radio>
                                    <el-radio label="90 Jours" ></el-radio>
                                    <el-radio label="120 Jours" ></el-radio>
                                </el-radio-group>
                            </el-form-item>
                    </fieldset>
                    
                </el-form>
                <!-- ENd body Dialog -->
            <span slot="footer" class="dialog-footer">
                <el-button @click="DialogVisible = false">Cancel</el-button>
                <el-button type="primary" v-show="!editmode" @click="createData()">Ajouter</el-button>
                <el-button type="primary" v-show="editmode" @click="updateData()">Modifier</el-button>
            </span>
        </el-dialog>
    </div>
  </section>
</template>

<script>
    import VueTagsInput from '@johmun/vue-tags-input';

    export default {
      components: {
          VueTagsInput,
        },
        data () {
            return {
                DialogVisible: false,
                search: '',
                pagesize: 10,
                currpage: 1,
                editmode: false,
                clients : [],

                form: new Form({
                    id:'',
                    name:'',
                    ice:'',
                    siegeSocial:'',
                    villeSocial:'',
                    NumRC:'',
                    villeRC:'',
                    CodeRC:'',
                    domaineActivite:'',
                    email:'',
                    tele:'',
                    fix:'',
                    typeLancementCMD:'',
                    RLancementCMDName:'',
                    RLancementCMDemail:'',
                    RLancementCMDtele:'',
                    RLancementCMDfix:'',
                    RComptableName:'',
                    RComptableemail:'',
                    RComptabletele:'',
                    RComptablefix:'',

                    GerantName:'',
                    Gerantemail:'',
                    Geranttele:'',
                    Gerantfix:'',

                    RegimeFacturation:'',
                    TransitaireName:'',
                    Transitairetele:'',
                    ResponsablATName:'',
                    ResponsablATemail:'',
                    ResponsablATtele:'',
                    ResponsablATfix:'',

                    ResponsablATName2:'',
                    ResponsablATemail2:'',
                    ResponsablATtele2:'',
                    ResponsablATfix2:'',

                    ModalitePaiment:'',
                    lieu_livraison:''
                }),

                tableData: [{
                    societName:'MediaTeck',
                    siegeSocial:'El Houda, N°565 , Agadir ',
                    ice:'445622199265',
                    nrc:'....',
                    domainActivity:'Media & Developpement',
                    tele:'05 28 28 74 12',
                    email:'contact@mediateck.ma',
                    ra_name:'amzil youssef',
                    ra_email:'yousse.amzil@mediateck.ma',
                    ra_tele:'06 25 21 41 25',
                    rc_name:'aimad raghida',
                    rc_email:'aimad.raghida@mediateck.ma',
                    rc_tele:'06 61 25 45 25',
                    g_name:'Mustapha BAH',
                    g_email:'bah.mustapha@mediateck.ma',
                    g_tele:'06 24 98 12 23',
                    regime_facture:'AT',
                    nom_transitaire:'Mohamed Ben ALi',
                    responsable_at_name:'Oussama Karroum',
                    responsable_at_tele:'06 25 45 47 45',
                    responsable_at_email:'oussama_ka@gmail.com',
                    designation:'...',
                    quantite:'...',
                    lieu_livraison:'...'
                },{
                    societName:'Enteprise X',
                    siegeSocial:'City d\'inovation, Bureau 85  , Agadir ',
                    ice:'65321313',
                    nrc:'....',
                    domainActivity:'Media & Developpement',
                    tele:'05 28 28 74 12',
                    email:'contact@mediateck.ma',
                    ra_name:'amzil youssef',
                    ra_email:'yousse.amzil@mediateck.ma',
                    ra_tele:'06 25 21 41 25',
                    rc_name:'aimad raghida',
                    rc_email:'aimad.raghida@mediateck.ma',
                    rc_tele:'06 61 25 45 25',
                    g_name:'Mustapha BAH',
                    g_email:'bah.mustapha@mediateck.ma',
                    g_tele:'06 24 98 12 23',
                    regime_facture:'TVA',
                    nom_transitaire:'',
                    responsable_at_name:'',
                    responsable_at_tele:'',
                    responsable_at_email:'',
                    designation:'...',
                    quantite:'...',
                    lieu_livraison:'...'
                }],
                
                products : [],
                
                categories: [],

                tag:  '',
                autocompleteItems: [],
            }
        },
        methods: {

            newCompte(){
                this.form.reset();
                this.editmode = false;
                this.DialogVisible = true;
            },

            // Start CRUD Comptes
            loadData(page = 1){
                this.$Progress.start();
                axios.get('api/CompteClients?page=' + page).then(({ data }) => (this.clients = data.data));
                this.$Progress.finish();
            },

            filterClient(){
                if(this.search != ''){
                    this.$Progress.start();
                    axios.get('api/filterClient/' + this.search).then(({ data }) => (this.clients = data.data));
                    this.$Progress.finish();
                }else{
                    this.loadData();
                }
            },
            
            createData(){
            this.$Progress.start();

              this.form.post('api/CompteClients')
              .then((data)=>{
                if(data.data.success){
                 this.DialogVisible = false

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.$Progress.finish();
                  this.loadData();

                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          },

          updateCompte(compte){
                this.editmode = true;
                this.form.reset();
                this.DialogVisible = true;
                this.form.fill(compte);
            },

          updateData(){
              this.$Progress.start();
              this.form.put('api/CompteClients/'+this.form.id)
              .then((response) => {
                  // success
                  this.DialogVisible = false
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });
                  this.$Progress.finish();

                  this.loadData();
              })
              .catch(() => {
                  this.$Progress.fail();
              });

          },

          deletData(id){
              Swal.fire({
                  title: 'Attention !',
                  text: "vous voulez vraiment supprimer ce Client ??",
                  showCancelButton: true,
                  confirmButtonColor: '#67c23a',
                  cancelButtonColor: '#f56c6c',
                  confirmButtonText: 'Oui, supprimez-le ! '
                  }).then((result) => {

                      // Send request to the server
                        if (result.value) {
                              this.form.delete('api/CompteClients/'+id).then(()=>{
                                      Swal.fire(
                                      'Supprimé !',
                                      'le Compte Client a été supprimé.',
                                      'success'
                                      );
                                  // Fire.$emit('AfterCreate');
                                  this.loadData();
                              }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                        }
                  })
          },


            // End CRUD 

          handleClose(done) {
                this.$confirm('Voulez-vous vraiment fermer cette boîte de dialogue ?')
                .then(_ => {
                    done();
                })
                .catch(_ => {});
            },  

         handleCurrentChange(cpage) {
                this.currpage = cpage;
            },
        handleSizeChange(psize) {
                this.pagesize = psize;
            },


        },
        mounted() {

        },
        created() {
            this.$Progress.start();

            this.loadData();

            this.$Progress.finish();
        },
        filters: {
            
        },
        computed: {
          
        },
    }
</script>
