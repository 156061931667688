<template>
    <section class="content">
      <div class="container-fluid">
          <div class="row">
              
              <!-- Tabse -->
              <div class="col-12 mt-4 mb-4">
                  <el-tabs tab-position="top"  label="Gestion Des Fichiers" style="margin-bottom: 30px;">
                      <!-- Plaque -->
                      <el-tab-pane label="Demande De Devis Plaque">
                          <el-card class="box-card">
                              <div slot="header" class="clearfix text-center">
                                  
                              </div>
                              <div class="row">
                                  <!--  -->
                                  
                                <fieldset class=" col-lg-6 border p-2 text-center">
                                    <legend class="w-auto badge badge"><h6><strong> Demande de devis form decoupe </strong></h6></legend>
                                    
                                        <el-button type="primary" size="small" icon="el-icon-file" @click="DemandeDevisFD($route.params.idpfd)" plain>Demande de Reduction devis (FDP)</el-button>
                                        <el-button type="success" size="small" icon="el-icon-file" @click="ValiderDevisFD($route.params.idpfd)" plain>Validation de devis (FDP)</el-button>
                                        <!-- show file get_e_t_c get_fournisseur-->
                                        <div class="row" >
                                        
                                            <div class="col-12">
                                                <div class="card-box">
                                                    <div class="row" >
                                                        <div class="col-lg-12" v-for="c in devispfd" :key="c.id">
                                                            <div class="file-man-box"><a @click="deleteDevis(c.id)" class="file-close"><i class="fa fa-times-circle"></i></a>
                                                                <div class="file-img-box">
                                                                    <img v-if="c.type_de_demande != 3" src="/images/pdf_red.png" alt="icon">
                                                                    <img v-else src="/images/pdf_green.png" alt="icon">
                                                                </div><a @click="printC(c.id)" class="file-download"><i class="fa fa-download"></i></a>
                                                                <div v-if="c.type_de_demande == 1" class="file-man-title" >
                                                                    <h5 class="mb-0 text-overflow">Demande_de_prix.pdf<br>({{c.get_fournisseur.name}})</h5>
                                                                    <p></p>
                                                                </div>
                                                                <div v-else-if="c.type_de_demande == 2" class="file-man-title" >
                                                                    <h5 class="mb-0 text-overflow">Demande_de_reduction_prix.pdf<br>({{c.get_fournisseur.name}})</h5>
                                                                    <p></p>
                                                                </div>
                                                                <div v-else class="file-man-title" >
                                                                    <h5 class="mb-0 text-overflow">Bon_de_commande.pdf<br>({{c.get_fournisseur.name}})</h5>
                                                                    <p></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- Bon Commande -->
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- end col -->
                                        </div>
                                        <!-- End show file -->
                                </fieldset>
                                <fieldset class=" col-lg-6 border p-2 text-center">
                                    <legend class="w-auto badge badge"><h6><strong> FACTURE PRO FORMAT </strong></h6></legend>
                                    
                                        <el-button v-if="(ProFormatPFD.length == 0)" type="success" size="small" icon="el-icon-file" @click="DemandeFactureProFormat($route.params.idpfd)" plain>Demander De Facture Pro Format</el-button>
                                        <!-- show file get_e_t_c get_fournisseur-->
                                        <div class="row" >
                                        
                                            <div class="col-12">
                                                <div class="card-box">
                                                    <div class="row" >
                                                        <div class="col-lg-12" v-for="c in ProFormatPFD" :key="c.id">
                                                            <div class="file-man-box" v-if="c.montant_chiffre > 0"><a @click="deleteFPF(c.id)" class="file-close"><i class="fa fa-times-circle"></i></a>
                                                                <div class="file-img-box">
                                                                    <img  src="/images/pdf_red.png" alt="icon">
                                                                </div><a @click="printPFP_PFD(c.id)" class="file-download"><i class="fa fa-download"></i></a>
                                                                <div class="file-man-title" >
                                                                    <h5 class="mb-0 text-overflow">Demande_de_facture_pro_format.pdf<br>({{c.get_fournisseur.name}})</h5>
                                                                    <p></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- end col -->
                                        </div>
                                        <!-- End show file -->
                                </fieldset>
                                <fieldset class=" col-lg-12 border p-2 text-center">
                                    <legend class="w-auto badge badge"><h6><strong> Recevoir La Facture Proforma Ou Finale </strong></h6></legend>
                                        
                                        <el-button v-if="listeFilesFPF.length == 0"  type="primary" size="small" icon="el-icon-file" @click="joindreFPFPlaque" plain>Joindre La Facture Pro Forma</el-button>
                                        <el-button v-if="ProFormaPlaque.length == 0"  type="primary" size="small" icon="el-icon-file" @click="SaisieFPF" plain>Saisie La Facture Pro Forma</el-button>
                                        <div class="row" >
                                        
                                        <div class="col-lg-4">
                                            <div class="card-box">
                                                <div class="row" >
                                                    <div class="col-lg-12" v-for="f in listeFilesFPF" :key="f.id">
                                                        <div class="file-man-box"><a @click="deleteFPFPlaque(f.id)" class="file-close"><i class="fa fa-times-circle"></i></a>
                                                            <div class="file-img-box">
                                                                <img  src="/images/pdf_red.png" alt="icon">
                                                            </div><a @click="getFileFPF(f.file_name)" class="file-download"><i class="fa fa-download"></i></a>
                                                            <div class="file-man-title" >
                                                                <h5 class="mb-0 text-overflow">{{f.file_name}}</h5><br>
                                                                <p></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            
                                            <el-table
                                                    :data="ProFormaPlaque"
                                                    border >
                                                <el-table-column type="expand">
                                                    <template  slot-scope="scope" >
                                                        <el-table
                                                            :data="scope.row.get_articles"
                                                            >
                                                            <el-table-column label="Réf" width="50">
                                                                <template slot-scope="scope">
                                                                    <span>{{ scope.row.id }}</span>
                                                                </template>
                                                            </el-table-column>
                                                            <el-table-column label="Désignation Fournisseur" >
                                                                <template slot-scope="scope">
                                                                    <span>{{ scope.row.designation_fournisseur }}</span>
                                                                </template>
                                                            </el-table-column>
                                                            <el-table-column label="Rebrique">
                                                                <template slot-scope="scope">
                                                                    <span>{{ scope.row.rebrique_designation }}</span>
                                                                </template>
                                                            </el-table-column>
                                                            <el-table-column label="Code Designation">
                                                                <template slot-scope="scope">
                                                                    <span>{{ scope.row.code_designation }}</span>
                                                                </template>
                                                            </el-table-column>
                                                            <el-table-column label="Nomenclature">
                                                                <template slot-scope="scope">
                                                                    <span>{{ scope.row.nomenclature }}</span>
                                                                </template>
                                                            </el-table-column>
                                                            <el-table-column label="Poids Net">
                                                                <template slot-scope="scope">
                                                                    <span>{{ scope.row.poids_net }} KG</span>
                                                                </template>
                                                            </el-table-column>
                                                            <el-table-column label="Poids Brut">
                                                                <template slot-scope="scope">
                                                                    <span>{{ scope.row.poids_brut }} KG</span>
                                                                </template>
                                                            </el-table-column>
                                                            <el-table-column label="Pays Origine">
                                                                <template slot-scope="scope">
                                                                    <span>{{ scope.row.pays_origine }}</span>
                                                                </template>
                                                            </el-table-column>
                                                            <el-table-column label="Incoterm">
                                                                <template slot-scope="scope">
                                                                    <span>{{ scope.row.incoterm }}</span>
                                                                </template>
                                                            </el-table-column>
                                                            <el-table-column label="Quantite">
                                                                <template slot-scope="scope">
                                                                    <span>{{ scope.row.qtit }}</span>
                                                                </template>
                                                            </el-table-column>
                                                            <el-table-column label="Prix Unit">
                                                                <template slot-scope="scope">
                                                                    <span>{{ scope.row.prix_unit }}</span>
                                                                </template>
                                                            </el-table-column>
                                                            
                                                        </el-table>
                                                    </template>
                                                </el-table-column>

                                                <el-table-column label="N° Devis">
                                                    <template slot-scope="scope">
                                                        <span>{{ scope.row.id }}</span>
                                                    </template>
                                                </el-table-column>
                                                <el-table-column label="N° Facture" >
                                                    <template slot-scope="scope">
                                                        <span>{{ scope.row.num_facturePro }}</span>
                                                    </template>
                                                </el-table-column>
                                                <el-table-column label="Date">
                                                    <template slot-scope="scope">
                                                        <el-tag >
                                                            <span>{{ scope.row.date_facturePro }}</span>
                                                        </el-tag>
                                                    </template>
                                                </el-table-column>
                                                <el-table-column label="Condition Reglement">
                                                    <template slot-scope="scope">
                                                        <el-tag>
                                                            <span>{{ scope.row.condition_reglement }}</span>
                                                        </el-tag>
                                                    </template>
                                                </el-table-column>
                                                <el-table-column label="Montant Total">
                                                    <template slot-scope="scope">
                                                        <el-tag>
                                                            <span>{{ scope.row.montantPrixTotal }}</span>
                                                        </el-tag>
                                                    </template>
                                                </el-table-column>
                                                <el-table-column label="Cours Change">
                                                    <template slot-scope="scope">
                                                        <el-tag>
                                                            <span>{{ scope.row.cours_changeActuel }}</span>
                                                        </el-tag>
                                                    </template>
                                                </el-table-column>
                                                <el-table-column label="Contre Valeur Total (DH)">
                                                    <template slot-scope="scope">
                                                        <el-tag>
                                                            <span>{{ scope.row.valeur_totalCalculer }}</span>
                                                        </el-tag>
                                                    </template>
                                                </el-table-column>
                        
                                                <el-table-column v-if="$gate.isAdmin()" align="center">
                                                    <template slot-scope="scope">
                                                        <a href="#" @click="updateProFormaGlobal(scope.row)" data-toggle="tooltip" data-placement="top" title="Modifier">
                                                            <i class="fa fa-edit blue mr-2"></i>
                                                        </a>
                                                        <el-divider direction="vertical"></el-divider>
                                                        <a @click="deletProFormaGlobal(scope.row.id)" data-toggle="tooltip" data-placement="top" title="Supprimer">
                                                            <i class="fa fa-trash red"></i>
                                                        </a>
                                                    </template>
                                                </el-table-column>
                                    
                                                </el-table>
                                            <!-- <div class="card-box">
                                                <div class="row" >
                                                    <div class="col-lg-12" v-for="f in listeFilesFPF" :key="f.id">
                                                        <div class="file-man-box"><a @click="deleteFPFPlaque(f.id)" class="file-close"><i class="fa fa-times-circle"></i></a>
                                                            <div class="file-img-box">
                                                                <img  src="/images/pdf_red.png" alt="icon">
                                                            </div><a @click="getFileFPF(f.file_name)" class="file-download"><i class="fa fa-download"></i></a>
                                                            <div class="file-man-title" >
                                                                <h5 class="mb-0 text-overflow">{{f.file_name}}</h5><br>
                                                                <p></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> -->
                                        </div>
                                        <!-- end col -->
                                    </div>
                                        <!-- End show file -->
                                </fieldset>
                                  
                                  
                                  <fieldset class=" col-lg-6 border p-2 text-center">
                                      <legend class="w-auto badge badge"><h6><strong> Bon De Livraison (PFD) </strong></h6></legend>
                                      
                                          <el-button v-if="listeFiles.length == 0" type="primary" size="small" icon="el-icon-file" @click="joindreBLPlaque" plain>Joindre Bon De Livraison (BL)</el-button>
                                          <!-- show file get_e_t_c get_fournisseur-->
                                          <div class="row" >
                                            
                                            <div class="col-12">
                                                <div class="card-box">
                                                    <div class="row" >
                                                        <div class="col-lg-12" v-for="f in listeFiles" :key="f.id">
                                                            <div class="file-man-box"><a @click="deleteBLPlaque(f.id)" class="file-close"><i class="fa fa-times-circle"></i></a>
                                                                <div class="file-img-box">
                                                                    <img  src="/images/pdf_red.png" alt="icon">
                                                                </div><a @click="getFile(f.file_name)" class="file-download"><i class="fa fa-download"></i></a>
                                                                <div class="file-man-title" >
                                                                    <h5 class="mb-0 text-overflow">{{f.file_name}}</h5><br>
                                                                    <p></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- end col -->
                                        </div>
                                          <!-- End show file -->
                                  </fieldset>
                                  
                                  <!-- <fieldset class=" col-6 border p-2 text-center">
                                      <legend class="w-auto badge badge"><h6><strong> Plaque </strong></h6></legend>
                                      
                                          <el-button type="primary" size="small" icon="el-icon-plus" @click="newModalP" plain>Ajouter Nouveau Plaque old</el-button>
                                          <el-button type="primary" size="small" icon="el-icon-plus" @click="newPlaque" plain>Ajouter Nouveau Plaque</el-button>
                                      
                                  </fieldset> -->
                              </div>
                          </el-card>
                      </el-tab-pane>
                      <!-- Transport -->
                      <el-tab-pane label="Transport">
                          <el-card class="box-card">
                              <div slot="header" class="clearfix text-center">
                                  
                              </div>
                            <div class="row">
                                <fieldset class=" col-lg-6 border p-2 text-center">
                                    <legend class="w-auto badge badge"><h6><strong> DEMANDE DEVIS TRANSPORT </strong></h6></legend>
                                    
                                        <!-- <el-button v-if="(ProFormatPFD.length == 0)" type="success" size="small" icon="el-icon-file" @click="DemandeFactureProFormat($route.params.idpfd)" plain>Demander De Facture Pro Format</el-button> -->
                                        <el-button  type="success" size="small" icon="el-icon-file" @click="Demande_D_Transport($route.params.idpfd)" plain>Demander Devis De Transport</el-button>
                                        <!-- show file get_e_t_c get_fournisseur-->
                                        <div class="row" >
                                            <!-- <div class="col-12">
                                                <div class="card-box">
                                                    <div class="row">
                                                        <div class="col-lg-12" v-for="c in DemandeEngagePFD" :key="c.id">
                                                            <div class="file-man-box" ><a @click="deleteDDENG(c.id)" class="file-close"><i class="fa fa-times-circle"></i></a>
                                                                <div class="file-img-box">
                                                                    <img  src="/images/pdf_red.png" alt="icon">
                                                                </div><a @click="printDSENG_PFD(c.id)" class="file-download"><i class="fa fa-download"></i></a>
                                                                <div class="file-man-title" >
                                                                    <h5 class="mb-0 text-overflow">Demande_de_Saisir_Engagement.pdf<br></h5><br>
                                                                    <p></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> -->
                                        </div>
                                </fieldset>
                            </div>
                          </el-card>
                      </el-tab-pane>
                      <!-- ENGAGEMENT -->
                      <el-tab-pane label="Engagement (PFD)">
                          <el-card class="box-card">
                              <div slot="header" class="clearfix text-center">
                                  
                              </div>
                            <div class="row">
                                <fieldset class=" col-lg-6 border p-2 text-center">
                                    <legend class="w-auto badge badge"><h6><strong> DEMANDE D'ENGAGEMENT </strong></h6></legend>
                                    
                                        <!-- <el-button v-if="(ProFormatPFD.length == 0)" type="success" size="small" icon="el-icon-file" @click="DemandeFactureProFormat($route.params.idpfd)" plain>Demander De Facture Pro Format</el-button> -->
                                        <el-button v-if="(DemandeEngagePFD.length == 0)" type="success" size="small" icon="el-icon-file" @click="DemandeSEngagement($route.params.idpfd)" plain>Demander De Saisir L'engagement D'importation</el-button>
                                        <!-- show file get_e_t_c get_fournisseur-->
                                        <div class="row" >
                                            <div class="col-12">
                                                <div class="card-box">
                                                    <div class="row">
                                                        <div class="col-lg-12" v-for="c in DemandeEngagePFD" :key="c.id">
                                                            <div class="file-man-box" ><a @click="deleteDDENG(c.id)" class="file-close"><i class="fa fa-times-circle"></i></a>
                                                                <div class="file-img-box">
                                                                    <img  src="/images/pdf_red.png" alt="icon">
                                                                </div><a @click="printDSENG_PFD(c.id)" class="file-download"><i class="fa fa-download"></i></a>
                                                                <div class="file-man-title" >
                                                                    <h5 class="mb-0 text-overflow">Demande_de_Saisir_Engagement.pdf<br></h5><br>
                                                                    <p></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- end col -->
                                        </div>
                                        <!-- End show file -->
                                </fieldset>
                                <fieldset class=" col-lg-6 border p-2 text-center">
                                    <legend class="w-auto badge badge"><h6><strong> DEMANDE CORRECTION DES ERREUR D'ENGAGEMENT </strong></h6></legend>
                                    
                                        <el-button v-if="(DemandeCEngagePFD.length == 0)" type="success" size="small" icon="el-icon-file" @click="DemandeCEngagement($route.params.idpfd)" plain>Demander De Correction D'engagement D'importation</el-button>
                                        <!-- show file get_e_t_c get_fournisseur-->
                                        <div class="row" >
                                            <div class="col-12">
                                                <div class="card-box">
                                                    <div class="row" >
                                                        <div class="col-lg-12" v-for="c in DemandeCEngagePFD" :key="c.id">
                                                            <div class="file-man-box" >
                                                                <a @click="deleteDCENG(c.id)" class="file-close"><i class="fa fa-times-circle"></i></a>
                                                                <a @click="UpdateDCENG(DemandeCEngagePFD)" class="file-update"><i class="fa fa-edit"></i></a>
                                                                <div class="file-img-box">
                                                                    <img  src="/images/pdf_red.png" alt="icon">
                                                                </div><a @click="printDCENG_PFD(c.id)" class="file-download"><i class="fa fa-download"></i></a>
                                                                <div class="file-man-title" >
                                                                    <h5 class="mb-0 text-overflow">Demande_de_Correction_Engagement.pdf<br></h5><br>
                                                                    <p></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- end col -->
                                        </div>
                                        <!-- End show file -->
                                </fieldset>
                                <fieldset class=" col-lg-6 border p-2 text-center">
                                    <legend class="w-auto badge badge"><h6><strong> DEMANDE DE VALIDER ET DOMICILIER <br>L'ENGAGEMENT D'IMPORTATION. </strong></h6></legend>
                                    
                                        <el-button v-if="(DemandeVEngagePFD.length == 0)" type="success" size="small" icon="el-icon-file" @click="DemandeVEngagement($route.params.idpfd)" plain>Demander De Validation D'engagement D'importation</el-button>
                                        <!-- show file get_e_t_c get_fournisseur-->
                                        <div class="row" >
                                            <div class="col-12">
                                                <div class="card-box">
                                                    <div class="row" >
                                                        <div class="col-lg-12" v-for="c in DemandeVEngagePFD" :key="c.id">
                                                            <div class="file-man-box" >
                                                                <a @click="deleteDVENG(c.id)" class="file-close"><i class="fa fa-times-circle"></i></a>
                                                                <div class="file-img-box">
                                                                    <img  src="/images/pdf_red.png" alt="icon">
                                                                </div><a @click="printDVENG_PFD(c.id)" class="file-download"><i class="fa fa-download"></i></a>
                                                                <div class="file-man-title" >
                                                                    <h5 class="mb-0 text-overflow">Demande_De_Validation_Engagement.pdf<br></h5><br>
                                                                    <p></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- end col -->
                                        </div>
                                        <!-- End show file -->
                                </fieldset>
                                
                            </div>
                          </el-card>
                      </el-tab-pane>
                       <!-- Paiement -->
                       <el-tab-pane label="Paiement">
                          <el-card class="box-card">
                              <div slot="header" class="clearfix text-center row">
                                <fieldset v-for="c in devisPlaque" :key="c.id" class=" col-lg-6 border p-2 text-center">
                                      <legend class="w-auto badge badge"><h6><strong>Préparation de paiement </strong></h6></legend>
                                          <!-- <el-button type="success" size="small" icon="el-icon-file" @click="DemandeOrdreVirement($route.params.idpfd)" plain>Demander L'ordre De Virement</el-button> -->
                                          <!-- show file get_e_t_c get_fournisseur-->
                                          <p>{{c.get_fournisseur.ModalitePaiment}}</p>
                                          <!-- End show file -->
                                  </fieldset>
                                <fieldset class=" col-lg-6 border p-2 text-center">
                                      <legend class="w-auto badge badge"><h6><strong>Demande ORDER DE VIREMENT INTERNATIONAL </strong></h6></legend>
                                          <el-button type="success" size="small" icon="el-icon-file" @click="DemandeOrdreVirement($route.params.idpfd)" plain>Demander L'ordre De Virement</el-button>
                                          <!-- show file get_e_t_c get_fournisseur-->
                                          <div class="row" >
                                              <div class="col-12">
                                                  <div class="card-box">
                                                      <div class="row" >
                                                          <div class="col-lg-12" v-for="c in devispfd" :key="c.id">
                                                              <div class="file-man-box" v-if="c.montantChiffre > 0"><a @click="deleteDevis(c.id)" class="file-close"><i class="fa fa-times-circle"></i></a>
                                                                  <div class="file-img-box">
                                                                      <img  src="/images/pdf_red.png" alt="icon">
                                                                  </div><a @click="printOrdre(c.id)" class="file-download"><i class="fa fa-download"></i></a>
                                                                  <div class="file-man-title" >
                                                                      <h5 class="mb-0 text-overflow">Ordre_De_Virement_International.pdf<br>({{c.get_fournisseur.name}})</h5>
                                                                      <p></p>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                              <!-- end col -->
                                          </div>
                                          <!-- End show file -->
                                  </fieldset>
                                <fieldset class=" col-lg-12 border p-2 text-center">
                                      <legend class="w-auto badge badge"><h6><strong>Recevoir L'ordre De Virement Accusé </strong></h6></legend>
                                          <el-button type="success" size="small" icon="el-icon-file" @click="JoindreAccusReception($route.params.idpfd)" plain>Joindre L'accusé de reception</el-button>
                                          <el-button type="success" size="small" icon="el-icon-file" @click="JoindreSWIFT($route.params.idpfd)" plain>Joindre SWIFT</el-button>
                                          <!-- show file get_e_t_c get_fournisseur-->
                                          <div class="row" >
                                              <div class="col-lg-6">
                                                  <div class="card-box">
                                                      <div class="row" >
                                                          <div class="col-lg-12" v-for="c in listeFilesAccuseR" :key="c.id">
                                                              <div class="file-man-box"><a @click="deleteDevis(c.id)" class="file-close"><i class="fa fa-times-circle"></i></a>
                                                                  <div class="file-img-box">
                                                                      <img  src="/images/pdf_red.png" alt="icon">
                                                                  </div><a @click="getFileAccuseR(c.file_name)" class="file-download"><i class="fa fa-download"></i></a>
                                                                  <div class="file-man-title" >
                                                                      <h5 class="mb-0 text-overflow">L'accuser_De_Reception.pdf<br></h5><br>
                                                                      <p></p>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div class="col-lg-6">
                                                  <div class="card-box">
                                                      <div class="row" >
                                                          <div class="col-lg-12" v-for="c in listeFilesSWIFT" :key="c.id">
                                                              <div class="file-man-box"><a @click="deleteDevis(c.id)" class="file-close"><i class="fa fa-times-circle"></i></a>
                                                                  <div class="file-img-box">
                                                                      <img  src="/images/pdf_red.png" alt="icon">
                                                                  </div><a @click="getFileSWIFT(c.file_name)" class="file-download"><i class="fa fa-download"></i></a>
                                                                  <div class="file-man-title" >
                                                                      <h5 class="mb-0 text-overflow">SWIFT.pdf<br></h5><br>
                                                                      <p></p>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                              <!-- end col -->
                                          </div>
                                          <!-- End show file -->
                                  </fieldset>
                              </div>
                            </el-card>
                        </el-tab-pane>
                       <!-- Expidition -->
                       <el-tab-pane label="Expidition">
                          <el-card class="box-card">
                              <div slot="header" class="clearfix text-center">
                                  
                              </div>
                            <div class="row">
                                <fieldset class=" col-lg-12 border p-2 text-center">
                                    <legend class="w-auto badge badge"><h6><strong> Type D'envoi </strong></h6></legend>
                                        <el-form>  
                                            <div class="row">
                                                    <el-form-item 
                                                        class="text-center col"
                                                        prop="type_envoi"
                                                    >
                                                        <el-radio-group v-model="form_expidition.type_envoi">
                                                            <el-radio label="Envoi Rapide" ></el-radio>
                                                            <el-radio label="Envoi Normale" ></el-radio>
                                                        </el-radio-group>
                                                    </el-form-item>
                                            </div>
                                        </el-form>
                                        <!-- End show file -->
                                </fieldset>
                                <fieldset v-if="form_expidition.type_envoi == 'Envoi Normale'" class=" col-lg-12 mt-5 border p-2 text-center">
                                    <legend class="w-auto badge badge"><h6><strong> DECLARATION DOUANIERE </strong></h6></legend>
                                    <el-tabs type="border-card">
                                        <el-tab-pane label="Autorisation">
                                            <fieldset class=" col-lg-6 mt-5 border p-2 text-center">
                                                <legend class="w-auto badge badge"><h6><strong> Autorisation </strong></h6></legend>
                                                <el-button type="success" size="small" icon="el-icon-file" @click="JoindreCertificatOrigine($route.params.idpfd)" plain>Joindre Certificat D'origine Ou Saisie N° D'autorisation</el-button>
                                                <div class="col-12">
                                                  <div class="card-box">
                                                      <div class="row" >
                                                          <div class="col-lg-12" v-for="c in listeFilesCertificatOrigine" :key="c.id">
                                                              <div class="file-man-box"><a @click="deleteDevis(c.id)" class="file-close"><i class="fa fa-times-circle"></i></a>
                                                                  <div class="file-img-box">
                                                                      <img  src="/images/pdf_red.png" alt="icon">
                                                                  </div><a @click="getFileCertificatOrigine(c.file_name)" class="file-download"><i class="fa fa-download"></i></a>
                                                                  <div class="file-man-title" >
                                                                      <h5 class="mb-0 text-overflow">Certificat_Origine.pdf<br></h5><br>
                                                                      <p></p>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                            </fieldset>
                                        </el-tab-pane>
                                        <el-tab-pane label="Enregistrement DUM">
                                            <div class="row">
                                                <fieldset class=" col-lg-6 mt-5 border p-2 text-center">
                                                    <legend class="w-auto badge badge"><h6><strong> Saisie La DUM D'importation </strong></h6></legend>
                                                    <el-button v-if="DUM_Saised.length == 0" type="success" size="small" icon="el-icon-file" @click="SaisieDumImpo($route.params.idpfd)" plain>Saisie La DUM D'importation</el-button>
                                                    <div class="row" >
                                        
                                                        <div class="col-12">
                                                            <div class="card-box">
                                                                <div class="row" >
                                                                    <div class="col-lg-12" v-for="c in DUM_Saised" :key="c.id">
                                                                        <div class="file-man-box" ><a @click="deleteS_DUM(c.id)" class="file-close"><i class="fa fa-times-circle"></i></a>
                                                                            <div class="file-img-box">
                                                                                <img  src="/images/pdf_red.png" alt="icon">
                                                                            </div><a @click="printS_DUM()" class="file-download"><i class="fa fa-download"></i></a>
                                                                            <div class="file-man-title" >
                                                                                <h5 class="mb-0 text-overflow">Demande_Saisie_DUM.pdf</h5><br>
                                                                                <p></p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- end col -->
                                                    </div>
                                                </fieldset>
                                                <fieldset class=" col-lg-6 mt-5 border p-2 text-center">
                                                    <legend class="w-auto badge badge"><h6><strong> Demande De Correction De DUM </strong></h6></legend>
                                                    <el-button type="success" size="small" icon="el-icon-file" @click="DemandeCEngagement($route.params.idpfd)" plain>Demander Correction Les Erreurs De La DUM</el-button>
                                                </fieldset>
                                                <fieldset class=" col-lg-6 mt-5 border p-2 text-center">
                                                    <legend class="w-auto badge badge"><h6><strong> Demande D'enregistrement De DUM </strong></h6></legend>
                                                    <el-button type="success" size="small" icon="el-icon-file" @click="Demande_E_DUM($route.params.idpfd)" plain>Demander L'enregistrement De La DUM</el-button>
                                                    <div class="row" >
                                        
                                                        <div class="col-12">
                                                            <div class="card-box">
                                                                <div class="row" >
                                                                    <div class="col-lg-12" v-for="c in DUM_Saised" :key="c.id">
                                                                        <div v-if="c.num_enregistrement != null" class="file-man-box" ><a @click="deleteS_DUM(c.id)" class="file-close"><i class="fa fa-times-circle"></i></a>
                                                                            <div class="file-img-box">
                                                                                <img  src="/images/pdf_red.png" alt="icon">
                                                                            </div><a @click="printS_DUM()" class="file-download"><i class="fa fa-download"></i></a>
                                                                            <div class="file-man-title" >
                                                                                <h5 class="mb-0 text-overflow">Demande_Enregistrement_DUM.pdf</h5><br>
                                                                                <p></p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- end col -->
                                                    </div>
                                                </fieldset>
                                            </div>
                                        </el-tab-pane>
                                        <el-tab-pane label="Régime Douanier (10)">
                                            <div class="row">
                                                <fieldset class=" col-lg-6 mt-5 border p-2 text-center">
                                                    <legend class="w-auto badge badge"><h6><strong> Fiche De liquidation <br> douanier </strong></h6></legend>
                                                    <el-button type="success" size="small" icon="el-icon-file" @click="SaisieFL($route.params.idpfd)" plain>Saisie La fiche de Liquidation</el-button>
                                                    <el-button type="success" size="small" icon="el-icon-file" @click="JoindreFL($route.params.idpfd)" plain>Joindre La fiche de Liquidation</el-button>
                                                    <div class="col-12">
                                                        <div class="card-box">
                                                            <div class="row" >
                                                                <div class="col-lg-12" v-for="c in listeFilesFL" :key="c.id">
                                                                    <div class="file-man-box"><a @click="deleteDevis(c.id)" class="file-close"><i class="fa fa-times-circle"></i></a>
                                                                        <div class="file-img-box">
                                                                            <img  src="/images/pdf_red.png" alt="icon">
                                                                        </div><a @click="getFileFL(c.file_name)" class="file-download"><i class="fa fa-download"></i></a>
                                                                        <div class="file-man-title" >
                                                                            <h5 class="mb-0 text-overflow">Fiche_De_Liquidation.pdf<br></h5><br>
                                                                            <p></p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </fieldset>
                                                <fieldset class=" col-lg-6 mt-5 border p-2 text-center">
                                                    <legend class="w-auto badge badge"><h6><strong> Paiment des droits et taxes <br> d'importation </strong></h6></legend>
                                                    <el-button type="success" size="small" icon="el-icon-file" @click="JoindreEDP($route.params.idpfd)" plain>Joindre Etiquette de paiement</el-button>
                                                    <div class="col-12">
                                                        <div class="card-box">
                                                            <div class="row" >
                                                                <div class="col-lg-12" v-for="c in listeFilesEDP" :key="c.id">
                                                                    <div class="file-man-box"><a @click="deleteDevis(c.id)" class="file-close"><i class="fa fa-times-circle"></i></a>
                                                                        <div class="file-img-box">
                                                                            <img  src="/images/pdf_red.png" alt="icon">
                                                                        </div><a @click="getFileEDP(c.file_name)" class="file-download"><i class="fa fa-download"></i></a>
                                                                        <div class="file-man-title" >
                                                                            <h5 class="mb-0 text-overflow">L'etiquette_De_Paiement.pdf<br></h5><br>
                                                                            <p></p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </fieldset>
                                                <fieldset class=" col-lg-6 mt-5 border p-2 text-center">
                                                    <legend class="w-auto badge badge"><h6><strong> Reglenemt Des Droit et taxes <br> d'importation </strong></h6></legend>
                                                    <el-button type="success" size="small" icon="el-icon-file" @click="JoindreRecuReglement($route.params.idpfd)" plain>Joindre Reçu de Reglement</el-button>
                                                    <el-button type="success" size="small" icon="el-icon-file" @click="SaisieDroitTaxes($route.params.idpfd)" plain>Saisie Réglement des taxes d'importation</el-button>
                                                    <div class="col-12">
                                                        <div class="card-box">
                                                            <div class="row" >
                                                                <div class="col-lg-12" v-for="c in listeFilesRecuReglement" :key="c.id">
                                                                    <div class="file-man-box"><a @click="deleteDevis(c.id)" class="file-close"><i class="fa fa-times-circle"></i></a>
                                                                        <div class="file-img-box">
                                                                            <img  src="/images/pdf_red.png" alt="icon">
                                                                        </div><a @click="getFileRecuReglement(c.file_name)" class="file-download"><i class="fa fa-download"></i></a>
                                                                        <div class="file-man-title" >
                                                                            <h5 class="mb-0 text-overflow">Reçu_De_Reglement.pdf<br></h5><br>
                                                                            <p></p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </fieldset>
                                                <fieldset class=" col-lg-6 mt-5 border p-2 text-center">
                                                    <legend class="w-auto badge badge"><h6><strong> Validation De Reglement des Droit et Taxes <br> d'importation </strong></h6></legend>
                                                    <el-button type="success" size="small" icon="el-icon-file" @click="JoindreMainL($route.params.idpfd)" plain>Joindre La Main Levée Delivrée</el-button>
                                                    <div class="col-12">
                                                        <div class="card-box">
                                                            <div class="row" >
                                                                <div class="col-lg-12" v-for="c in listeFilesMainL" :key="c.id">
                                                                    <div class="file-man-box"><a @click="deleteDevis(c.id)" class="file-close"><i class="fa fa-times-circle"></i></a>
                                                                        <div class="file-img-box">
                                                                            <img  src="/images/pdf_red.png" alt="icon">
                                                                        </div><a @click="getFileMainL(c.file_name)" class="file-download"><i class="fa fa-download"></i></a>
                                                                        <div class="file-man-title" >
                                                                            <h5 class="mb-0 text-overflow">Main_Lever_delevrée.pdf<br></h5><br>
                                                                            <p></p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </fieldset>
                                            </div>
                                        </el-tab-pane>
                                        <el-tab-pane label="Régime Douanier (22 , 37)">
                                            <div class="row">
                                                
                                                <fieldset class=" col-12 border p-2 text-center">
                                                    <legend class="w-auto badge badge"><h6><strong> Coutionement Banquaire </strong></h6></legend>
                                                        <div class="row">
                                                            <fieldset class=" col-lg-6 border p-2 text-center">
                                                                <legend class="w-auto badge badge"><h6><strong> Accusé de reception </strong></h6></legend>
                                                                <el-button type="success" size="small" icon="el-icon-file" @click="JoindreADC($route.params.idpfd)" plain>Joindre L'accusé De Reception</el-button>
                                                                <div class="col-12">
                                                                    <div class="card-box">
                                                                        <div class="row" >
                                                                            <div class="col-lg-12" v-for="c in listeFilesADC" :key="c.id">
                                                                                <div class="file-man-box"><a @click="deleteDevis(c.id)" class="file-close"><i class="fa fa-times-circle"></i></a>
                                                                                    <div class="file-img-box">
                                                                                        <img  src="/images/pdf_red.png" alt="icon">
                                                                                    </div><a @click="getFileADC(c.file_name)" class="file-download"><i class="fa fa-download"></i></a>
                                                                                    <div class="file-man-title" >
                                                                                        <h5 class="mb-0 text-overflow">Accusé_De_Reception.pdf<br></h5><br>
                                                                                        <p></p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </fieldset>
                                                            <fieldset class=" col-lg-6  border p-2 text-center">
                                                                <legend class="w-auto badge badge"><h6><strong> Fiche de declaration on transitaire </strong></h6></legend>
                                                                <el-button type="success" size="small" icon="el-icon-file" @click="JoindreFDT($route.params.idpfd)" plain>Joindre La Fiche de declaration on transitaire</el-button>
                                                                <div class="col-12">
                                                                    <div class="card-box">
                                                                        <div class="row" >
                                                                            <div class="col-lg-12" v-for="c in listeFilesFDT" :key="c.id">
                                                                                <div class="file-man-box"><a @click="deleteDevis(c.id)" class="file-close"><i class="fa fa-times-circle"></i></a>
                                                                                    <div class="file-img-box">
                                                                                        <img  src="/images/pdf_red.png" alt="icon">
                                                                                    </div><a @click="getFileFDT(c.file_name)" class="file-download"><i class="fa fa-download"></i></a>
                                                                                    <div class="file-man-title" >
                                                                                        <h5 class="mb-0 text-overflow">Fiche_De_Declaration_Transitaire.pdf<br></h5><br>
                                                                                        <p></p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </fieldset>
                                                            <fieldset class=" col-lg-6  border p-2 text-center">
                                                                <legend class="w-auto badge badge"><h6><strong> Fiche de Declaration de Cautionnement </strong></h6></legend>
                                                                <el-button type="success" size="small" icon="el-icon-file" @click="JoindreFDC($route.params.idpfd)" plain>Joindre La Fiche de declaration on transitaire</el-button>
                                                                <div class="col-12">
                                                                    <div class="card-box">
                                                                        <div class="row" >
                                                                            <div class="col-lg-12" v-for="c in listeFilesFDC" :key="c.id">
                                                                                <div class="file-man-box"><a @click="deleteDevis(c.id)" class="file-close"><i class="fa fa-times-circle"></i></a>
                                                                                    <div class="file-img-box">
                                                                                        <img  src="/images/pdf_red.png" alt="icon">
                                                                                    </div><a @click="getFileFDC(c.file_name)" class="file-download"><i class="fa fa-download"></i></a>
                                                                                    <div class="file-man-title" >
                                                                                        <h5 class="mb-0 text-overflow">Fiche_De_Declaration_De_Cautionnement.pdf<br></h5><br>
                                                                                        <p></p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </fieldset>
                                                            <!-- <fieldset class=" col-lg-6 mt-5 border p-2 text-center">
                                                                <legend class="w-auto badge badge"><h6><strong> Joindre la DUM Enregistrer </strong></h6></legend>
                                                                <el-button type="success" size="small" icon="el-icon-file" plain>Joindre</el-button>
                                                            </fieldset>
                                                            <fieldset class=" col-lg-6 mt-5 border p-2 text-center">
                                                                <legend class="w-auto badge badge"><h6><strong> Joindre la facture fournisseur </strong></h6></legend>
                                                                <el-button type="success" size="small" icon="el-icon-file" plain>Joindre</el-button>
                                                            </fieldset>
                                                            <fieldset class=" col-lg-6 mt-5 border p-2 text-center">
                                                                <legend class="w-auto badge badge"><h6><strong> Joindre l'estimation des droits et <br> taxes exigibles </strong></h6></legend>
                                                                <el-button type="success" size="small" icon="el-icon-file" plain>Joindre</el-button>
                                                            </fieldset> -->
                                                        </div>
                                                    <!-- <el-button type="success" size="small" icon="el-icon-file" @click="DemandeCEngagement($route.params.idpfd)" plain>Coutionement Banquaire</el-button> -->
                                                </fieldset>
                                                <fieldset class=" col-12 border p-2 text-center">
                                                    <legend class="w-auto badge badge"><h6><strong>Enregistrement de caution banquaire</strong></h6></legend>
                                                    <el-button type="success" size="small" icon="el-icon-file" @click="SaisieCoutionBanque()" plain>Saisie Coutionement Banquaire</el-button>
                                                    
                                                    <div v-for="(c, k) in ECautionB" :key="k">
                                                        <el-descriptions class="mb-5 mt-5" size="medium" :column="3"  border>
                                                            
                                                            <el-descriptions-item>
                                                                <template slot="label"><strong>N° DUM :</strong></template>
                                                                <el-tag type="" effect="plain"> <strong>{{c.num_dum}}</strong></el-tag>
                                                            </el-descriptions-item>
                                                            <el-descriptions-item>
                                                                <template slot="label"><strong>Montant Déclarer :</strong></template>
                                                                <el-tag type="" effect="plain"> <strong>{{c.mantant_declare}}</strong></el-tag>
                                                            </el-descriptions-item>
                                                            <el-descriptions-item>
                                                                <template slot="label"><strong>Montant Cautionnement :</strong></template>
                                                                <el-tag type="" effect="plain"> <strong>{{c.mantant_cautionne}}</strong></el-tag>
                                                            </el-descriptions-item>
                                                            <el-descriptions-item>
                                                                <template slot="label"><strong>Taux Appliqué :</strong></template>
                                                                <el-tag type="" effect="plain"> <strong>{{c.taux_calculer}}</strong></el-tag>
                                                            </el-descriptions-item>
                                                            <el-descriptions-item>
                                                                <template slot="label"><strong>N° De Caution Banquaire :</strong></template>
                                                                <el-tag type="" effect="plain"> <strong>{{c.num_caution_banque}}</strong></el-tag>
                                                            </el-descriptions-item>
                                                            <el-descriptions-item>
                                                                <template slot="label"><strong>Date De DUM :</strong></template>
                                                                <el-tag type="" effect="plain"> <strong>{{c.date_dum}}</strong></el-tag>
                                                            </el-descriptions-item>
                                                        </el-descriptions>
                                                    </div>
                                                </fieldset>
                                                
                                            </div>
                                        </el-tab-pane>
                                    </el-tabs>
                                    
                                        
                                </fieldset>
                                
                                
                            </div>
                          </el-card>
                      </el-tab-pane>
                      <!-- Suivi Commande form -->
                      <el-tab-pane label="Suivi De La Commande (PFD)">
                          <el-card class="box-card">
                              <div slot="header" class="clearfix text-center">
                                  
                              </div>
                              <fieldset class=" col-lg-12 border p-2 ">
                                      <legend class="w-auto badge badge"><h6><strong> Suivi De Commande Form Decoupe </strong></h6></legend>
                                     
                                      <div class="row">
                                            <div class="col-6">
                                                <el-button type="primary" size="small" icon="el-icon-file" @click="trackingPlaque">Suivi De La Commande</el-button>
                                            </div>
                                                <div class="col-6">
                                                    <el-input v-model="search"  size="mini" placeholder="Filter : "> 
                                                        <el-button slot="append" icon="el-icon-search"></el-button>
                                                    </el-input>
                                                </div>
                                                <div class="col-12 mt-3">
                                                    <el-table border :data="TrackingPlaque.data"  style="width: 100%" >
                                                        
                                                        <el-table-column label="Réf" width="55">
                                                            <template slot-scope="scope">
                                                                <span>{{ scope.row.id }}</span>
                                                            </template>
                                                        </el-table-column>
                                                        
                                                        <el-table-column label="Lieu">
                                                            <template slot-scope="scope">
                                                                <el-tag effect="dark">
                                                                    {{ scope.row.lieu }}
                                                                </el-tag>
                                                            </template>
                                                        </el-table-column>
                                                        <el-table-column label="Date" >
                                                            <template slot-scope="scope">
                                                                    <el-tag effect="dark">
                                                                        {{ scope.row.created_at | RemarqueDates}}
                                                                    </el-tag>
                                                            </template>
                                                        </el-table-column>
                                                        <el-table-column width="50" align="center">
                                                            <template slot-scope="{row}">
                                                                <a href="#" @click="deleteTracking(row.id)" data-toggle="tooltip" data-placement="top" title="Supprimer">
                                                                    <i class="fa fa-trash red "></i>
                                                                </a>
                                                            </template>
                                                        </el-table-column>
                                                    </el-table>
                                                <!--  -->
                                                <pagination class="mt-4" :data="TrackingPlaque" @pagination-change-page="loadTrackingPlaque"></pagination>
                                                </div>
                                                <!-- table Global -->
                                            </div>
                                          <!-- show file get_e_t_c get_fournisseur-->
                                          
                                          <!-- End show file -->
                                  </fieldset>
                          </el-card>
                      </el-tab-pane>
                      <!-- BAT -->
                  </el-tabs>
              </div>
          </div>
  
          <!-- Modal Cliché -->
          <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
              <div class="modal-dialog modal-xl" role="document">
                  <div class="modal-content">
                  <div class="modal-header">
                      <h5 class="modal-title" v-show="!editmode">Ajouter Nouveau Cliché</h5>
                      <h5 class="modal-title" v-show="editmode">Modifier le Cliché</h5>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                      </button>
                  </div>
  
                  <form>
                      <div class="modal-body">
                          <fieldset class="border p-2">
                              <!-- <legend class="w-auto badge badge-success"><h6> Informations de Siège </h6></legend> -->
                              <div class="row">
                                  <div class="form-group col-6">
                                      <label>N° Serie:</label>
                                      <input v-model="form.numSerie" type="text" name="numSerie"
                                          class="form-control" :class="{ 'is-invalid': form.errors.has('numSerie') }">
                                      <has-error :form="form" field="numSerie"></has-error>
                                  </div>
                                  <div class="form-group col-6">
                                      <label>Date:</label>
                                      <input v-model="form.datePlaque" type="date" name="datePlaque"
                                          class="form-control" :class="{ 'is-invalid': form.errors.has('datePlaque') }">
                                      <has-error :form="form" field="datePlaque"></has-error>
                                  </div>
                                 
                                   <div class="form-group col-6">
                                      <label>Fournisseur :</label>
                                      <input v-model="form.fournisseur" placeholder="fournisseur" type="text" name="fournisseur"
                                          class="form-control" :class="{ 'is-invalid': form.errors.has('fournisseur') }">
                                      <has-error :form="form" field="fournisseur"></has-error>
                                  </div>
                                   <div class="form-group col-6">
                                      <label>BL / FA N° :</label>
                                      <input v-model="form.blfa" type="text" name="blfa"
                                          class="form-control" :class="{ 'is-invalid': form.errors.has('blfa') }">
                                      <has-error :form="form" field="blfa"></has-error>
                                  </div>
  
                                  
                              </div>
                          </fieldset>
                          <!--  -->
                          <fieldset class="border p-2">
                              <!-- <legend class="w-auto badge badge-success"><h6> Résponsable D'Achat </h6></legend> -->
                                  <div class="row">
                                      <div class="form-group col-4">
                                          <label>Client :</label>
                                          <input v-model="form.client" type="number" name="client"
                                              class="form-control" :class="{ 'is-invalid': form.errors.has('client') }">
                                          <has-error :form="form" field="client"></has-error>
                                      </div>
                                      <div class="form-group col-4">
                                          <label>Format Etq en mm:</label>
                                              <div class="row">
                                                  <div class="col-4">
                                                      <input v-model="form.format1" placeholder="L" type="number" name="format1"
                                                      class="form-control" :class="{ 'is-invalid': form.errors.has('price') }">
                                                  </div>
                                                  <div class="col-4">
                                                      <input v-model="form.format2" placeholder="D" type="number" name="format2"
                                                      class="form-control" :class="{ 'is-invalid': form.errors.has('price') }">
                                                  </div>
                                              </div>
                                          <has-error :form="form" field="nrc"></has-error>
                                      </div>
                                      <div class="col-4">
                                          <label>Nbr de cliché:</label>
                                          <input v-model="form.nbrCliche" type="number" name="nbrCliche"
                                              class="form-control" :class="{ 'is-invalid': form.errors.has('nbrCliche') }">
                                          <has-error :form="form" field="nbrCliche"></has-error>
                                      </div>
                                      <div class="col-12 mb-3">
                                          <fieldset class="border p-2 ">
                                              <legend class="w-auto badge badge"><h6><strong> Nombre de Pose </strong></h6></legend>
                                              <div class="row">
                                                  <div class="form-group col-4">
                                                      <div class="input-group mb-2">
                                                          <div class="input-group-prepend">
                                                              <div class="input-group-text">L</div>
                                                          </div>
                                                          <input type="text" class="form-control" name="l" id="inlineFormInputGroup" placeholder="">
                                                      </div>
                                                  </div>
                                                  <div class="form-group col-4">
                                                      <div class="input-group mb-2">
                                                          <div class="input-group-prepend">
                                                              <div class="input-group-text">D</div>
                                                          </div>
                                                          <input type="text" class="form-control" name="d" id="inlineFormInputGroup" placeholder="">
                                                      </div>
                                                  </div>
                                                  <div class="form-group col-4">
                                                      <div class="input-group mb-2">
                                                          <div class="input-group-prepend">
                                                              <div class="input-group-text">T</div>
                                                          </div>
                                                          <input type="text" class="form-control" name="t" id="inlineFormInputGroup" placeholder="">
                                                      </div>
                                                  </div>
                                              </div>
                                          </fieldset>
                                      </div>
                                      <!--  -->
                                      
                                          <div class="form-group col-3">
                                              <div class="input-group mb-2">
                                                  <div class="input-group-prepend">
                                                      <div class="input-group-text">C1</div>
                                                  </div>
                                                  <input type="text" class="form-control" name="c1" id="inlineFormInputGroup" placeholder="">
                                              </div>
                                          </div>
                                          <div class="form-group col-3">
                                              <div class="input-group mb-2">
                                                  <div class="input-group-prepend">
                                                      <div class="input-group-text">C2</div>
                                                  </div>
                                                  <input type="text" class="form-control" name="c2" id="inlineFormInputGroup" placeholder="">
                                              </div>
                                          </div>
                                          <div class="form-group col-3">
                                              <div class="input-group mb-2">
                                                  <div class="input-group-prepend">
                                                      <div class="input-group-text">C3</div>
                                                  </div>
                                                  <input type="text" class="form-control" name="c3" id="inlineFormInputGroup" placeholder="">
                                              </div>
                                          </div>
                                          <div class="form-group col-3">
                                              <div class="input-group mb-2">
                                                  <div class="input-group-prepend">
                                                      <div class="input-group-text">C4</div>
                                                  </div>
                                                  <input type="text" class="form-control" name="c4" id="inlineFormInputGroup" placeholder="">
                                              </div>
                                          </div>
                                          <div class="form-group col-3">
                                              <div class="input-group mb-2">
                                                  <div class="input-group-prepend">
                                                      <div class="input-group-text">C5</div>
                                                  </div>
                                                  <input type="text" class="form-control" name="c5" id="inlineFormInputGroup" placeholder="">
                                              </div>
                                          </div>
                                          <div class="form-group col-3">
                                              <div class="input-group mb-2">
                                                  <div class="input-group-prepend">
                                                      <div class="input-group-text">C6</div>
                                                  </div>
                                                  <input type="text" class="form-control" name="c6" id="inlineFormInputGroup" placeholder="">
                                              </div>
                                          </div>
                                          <div class="form-group col-3">
                                              <div class="input-group mb-2">
                                                  <div class="input-group-prepend">
                                                      <div class="input-group-text">C7</div>
                                                  </div>
                                                  <input type="text" class="form-control" name="c7" id="inlineFormInputGroup" placeholder="">
                                              </div>
                                          </div>
                                          <div class="form-group col-3">
                                              <div class="input-group mb-2">
                                                  <div class="input-group-prepend">
                                                      <div class="input-group-text">C8</div>
                                                  </div>
                                                  <input type="text" class="form-control" name="c8" id="inlineFormInputGroup" placeholder="">
                                              </div>
                                          </div>
                                      <!--  -->
                                      
                                      <div class="form-group col">
                                          <label>Z Cylindre:</label>
                                          <input v-model="form.ZCylindr" type="number" name="ZCylindr"
                                              class="form-control" :class="{ 'is-invalid': form.errors.has('ZCylindr') }">
                                          <has-error :form="form" field="ZCylindr"></has-error>
                                      </div>
                                      <div class="form-group col">
                                          <label>Espace developpe en mm:</label>
                                          <input v-model="form.espaceDevlop" type="number" name="espaceDevlop"
                                              class="form-control" :class="{ 'is-invalid': form.errors.has('espaceDevlop') }">
                                          <has-error :form="form" field="espaceDevlop"></has-error>
                                      </div>
                                      <div class="form-group col">
                                          <label>Espace Laize en mm :</label>
                                          <input v-model="form.espaceLaize" type="number" name="espaceLaize"
                                              class="form-control" :class="{ 'is-invalid': form.errors.has('espaceLaize') }">
                                          <has-error :form="form" field="espaceLaize"></has-error>
                                      </div>
                                      <div class="form-group col">
                                          <label>Laize papier calculée en mm :</label>
                                          <input v-model="form.laizePapier" type="number" name="laizePapier"
                                              class="form-control" :class="{ 'is-invalid': form.errors.has('laizePapier') }">
                                          <has-error :form="form" field="laizePapier"></has-error>
                                      </div>
                                      <div class="form-group col">
                                          <label>Machine :</label>
                                          <select v-model="form.machine" class="custom-select mr-sm-2" id="machine">
                                              <option disabled selected>choisir la machine</option>
                                              <option value="1">GIDUE 370</option>
                                              <option value="1">MACHINE 2</option>
                                              <option value="1">MACHINE 3</option>
                                          </select>
                                          <has-error :form="form" field="machine"></has-error>
                                      </div>
                                  </div>
                          </fieldset>
                      </div>
                      <div class="modal-footer">
                          <el-button type="danger" size="small" data-dismiss="modal" plain>Annuler</el-button>
                          <el-button type="success" v-show="editmode" size="small" @click="updateProduct()" plain>Valider</el-button>
                          <el-button type="success" v-show="!editmode" size="small" @click="createProduct()" plain>Valider</el-button>
                      </div>
                    </form>
                  </div>
              </div>
          </div>
          <!-- Modal Plaque  -->
          <el-dialog
              :title="'Ajouter Nouvelle Plaque'"
              :visible.sync="DialogVisiblePlaques"
              width="80%"
              :before-close="handleClose">
              <!-- Body dialog -->
              <el-form :model="form">
                  <fieldset class="border p-2 mt-2">
                      <div class="row">
                          <el-form-item 
                              class="text-center col-lg-4"
                              label="N° Référence De La Plaque"
                              prop="num_reference">
                              <el-input type="text" v-model="form.num_reference" autocomplete="off"></el-input>
                          </el-form-item>
                          <el-form-item 
                              class="text-center col-lg-4"
                              label="Date De Production"
                              prop="date_production">
                              <el-date-picker type="date" placeholder="Choisis une date" v-model="form.date_production" format="yyyy-MM-dd" value-format="yyyy-MM-dd" style="width: 100%;"></el-date-picker>
                          </el-form-item>
                          <el-form-item 
                              class="text-center col-lg-4"
                              label="N° Plan De Form de decoupe (PFD)"
                              prop="num_pfd">
                              <el-input type="text" v-model="form.num_pfd" autocomplete="off"></el-input>
                          </el-form-item>
                      </div>
                  </fieldset>
                  <fieldset class="border p-2 mt-2">
                      <div class="row">
                          <el-form-item class="col-lg-3" prop="formatMM_L" label="FORMAT EN (mm)">
                              <br>
                              <div class="row float-right">
                                  <el-input class="col-5" type="number" placeholder="L" v-model="form.formatMM_L" autocomplete="off"></el-input>
                                  <p class="col-1"> X </p>
                                  <el-input class="col-5" type="number" placeholder="D" v-model="form.formatMM_D" autocomplete="off"></el-input>
                              </div>
                          </el-form-item>
                          <el-form-item 
                              class="col-lg-3" 
                              prop="forme_etq" 
                              label="FORME D'ETIQUETTE"
                          >
                              <el-select v-model="form.forme_etq" style="width: 100%" placeholder="Please select Form d'étiquitte">
                                  <el-option label="REC" value="REC"></el-option>
                                  <el-option label="OVA" value="OVA"></el-option>
                                  <el-option label="CAR" value="CAR"></el-option>
                                  <el-option label="RONDER" value="RONDER"></el-option>
                                  <el-option label="TRIANGULAIRE" value="TRIANGULAIRE"></el-option>
                                  <el-option label="SPECIALE" value="SPECIALE"></el-option>
                              </el-select>
                          </el-form-item>
                          <el-form-item 
                              class="col-lg-3" 
                              prop="machine" 
                              label="MACHINE"
                          >
                              <el-select v-model="form.machine" multiple @change="changeSelectMachine" style="width: 100%" placeholder="Merci de Choisir une machine">
                                  <el-option label="GIDUE-370" value="GIDUE-370"></el-option>
                                  <el-option label="NILPITER-B200" value="NILPITER-B200"></el-option>
                                  <el-option label="ROTOFLEX-400" value="ROTOFLEX-400"></el-option>
                              </el-select>
                          </el-form-item>
                          <el-form-item 
                              class="col-lg-3" 
                              prop="z_cylindre" 
                              label="Z Cylindre"
                          >
                          <el-input type="number" v-model="form.z_cylindre" autocomplete="off"></el-input>
  
                              <!-- <el-select v-model="form.z_cylindre" style="width: 100%" placeholder="Merci de Choisir Z Cylindre">
                                  <el-option
                                      v-for="item in Zcylindre"
                                      :key="item.value"
                                      :label="item.label"
                                      :value="item.value"
                                  ></el-option>
                              </el-select> -->
                          </el-form-item>
                          <!-- -->
                          <el-form-item 
                              class="col-lg-4" 
                              prop="nbrPoseLaize" 
                              label="Nombre de Pose en laize"
                          >
                              <el-input type="number" v-model="form.nbrPoseLaize" @change="totalPosePlaqueCalculer" autocomplete="off"></el-input>
                          </el-form-item>
                          <el-form-item 
                              class="col-lg-4" 
                              prop="nbrPoseDevlop" 
                              label="Nombre de Pose en Developpe"
                          >
                              <el-input type="number" v-model="form.nbrPoseDevlop" @change="totalPosePlaqueCalculer" autocomplete="off"></el-input>
                          </el-form-item>
                          <el-form-item 
                              class="col-lg-4" 
                              prop="nbrPoseSurPlaq" 
                              label="Nombre de Pose sur la plaque"
                          >
                              <el-input type="number" v-model="form.nbrPoseSurPlaq" autocomplete="off"></el-input>
                          </el-form-item>
                          <!--  -->
                          <!-- <el-form-item 
                              class="col-lg-4" 
                              prop="devlop_mm" 
                              label="FORMAT D'ETIQUETTE EN LAIZE"
                          >
                              <el-input type="number" v-model="form.devlop_mm" autocomplete="off"></el-input>
                          </el-form-item> -->
                          <el-form-item 
                              class="col-lg-4" 
                              prop="laize_mm" 
                              label="FORMAT D'ETIQUETTE EN LAIZE"
                          >
                              <el-input type="number" v-model="form.laize_mm" @change="laizePapierCalculer" autocomplete="off"></el-input>
                          </el-form-item>
                          <el-form-item 
                              class="col-lg-4" 
                              prop="laize_papier_mm" 
                              label="Laize papier calculée en mm"
                          >
                              <el-input type="number" v-model="form.laize_papier_mm" autocomplete="off"></el-input>
                          </el-form-item>
                      </div>
                  </fieldset>
                  <fieldset class="border p-2 mt-2">
                      <legend class="w-auto badge"><h5> Support d'impression </h5></legend>
                      <el-form-item 
                          class="text-center col"
                          prop="type_support"
                      >
                          <el-radio-group v-model="form.type_support">
                              <el-radio label="Thermique" ></el-radio>
                              <el-radio label="Couché" ></el-radio>
                              <el-radio label="PPT" ></el-radio>
                              <el-radio label="PPB" ></el-radio>
                              <el-radio label="Autres" ></el-radio>
                          </el-radio-group>
                      </el-form-item>
                  </fieldset>
              </el-form>
              <!-- show on edit mode -->
              <span slot="footer"  class="dialog-footer">
                  <el-button @click="DialogVisiblePlaques = false">Annuler</el-button>
                  <el-button type="primary" @click="createPlaque()">Valider</el-button>
              </span>
          </el-dialog>
          <!-- Demande Devis Plaque -->
          <el-dialog
              :title="'Demande De Reduction Devis Pour Nouvelle Plaque'"
              :visible.sync="DialogVisibleDevisPlaques"
              width="70%"
              :before-close="handleClose">
              <!-- Body dialog -->
              <el-form :model="formDevisPlaque">
                  
                  <fieldset class="border p-2 mt-2">
                    <strong class="mt-4 mb-4"> FOURNISSEUR : <el-tag effect="dark"> {{form_Prices.fournisseur}} </el-tag> </strong>
                  </fieldset>
                  <fieldset class="border p-2 mt-2">
                    <el-form :model="form_Prices" ref="form_Prices" class="demo-ruleForm">
                        <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col">Réferance</th>
                                            <th scope="col">Quantité</th>
                                            <th scope="col">Désignation</th>
                                            <th scope="col">Prix Proposer Par Fournisseur</th>
                                        </tr>
                                    </thead>
                                    <tbody v-for="(c, k) in form_Prices.plaquePrices" :key="k">
                                        <tr >
                                            <th scope="row">{{c.id_plaque}}</th>
                                            <td>1</td>
                                            <td>{{c.nom_etc}}</td>
                                            <td><el-input  v-model="c.prixDevis" type="number" :min=0 ></el-input></td>
                                        </tr>
                                    </tbody>
                            </table>
                        <!-- next prev -->
                    </el-form>
                  </fieldset>
                  
              </el-form>
              <!-- show on edit mode -->
              <span slot="footer"  class="dialog-footer">
                  <el-button @click="DialogVisibleDevisPlaques = false">Annuler</el-button>
                  <el-button type="primary" @click="createReductionDevisPlaque()">Valider</el-button>
              </span>
          </el-dialog>
          <!-- End Modal -->
          <!-- Demande Devis Transport -->
          <el-dialog
              :title="'Demande Devis Du Transport A L\'etranger'"
              :visible.sync="DialogVisibleDevisTransport"
              width="90%"
              :before-close="handleClose">
              <!-- Body dialog -->
              <el-form :model="formDevisTransport">
                  <!-- {{ProFormaPlaque}} -->
                <fieldset class="border p-2 mt-2" >
                    <el-table border ref="multipleTable"  style="width: 100%" :data="ProFormaPlaque" @selection-change="handleSelectionChange">
                        <el-table-column type="selection" width="50"></el-table-column>
                        <el-table-column label="N° FP">
                            <template slot-scope="scope">
                                <span>{{ scope.row.num_facturePro }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="Fournisseur" >
                            <template slot-scope="scope">
                                <span>{{ scope.row.get_fournisseur.name }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="Type Transport">
                            <template slot-scope="scope">
                                <span>{{ scope.row.type_transport }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="Ville Chargement">
                            <template slot-scope="scope">
                                <span>{{ scope.row.get_fournisseur.villeSocial }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="Pays de Chargement">
                            <template slot-scope="scope">
                                <span>{{ scope.row.get_fournisseur.paysSocial }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </fieldset>
                <fieldset class="border p-2 mt-2" >
                        <div class="row">
                            <el-form-item 
                                class="col-lg-4"
                                label="Transporteur"
                                prop="id_transporteur">
                                <el-input v-model="formDevisTransport.id_transporteur" placeholder="Select Transporteur"></el-input>
                            </el-form-item>
                            <el-form-item 
                                class="text-center col-lg-4"
                                label="Bureau Douanier"
                                prop="bureau_douanier">
                                <el-input v-model="formDevisTransport.bureau_douanier" placeholder="Bureau Douanier"></el-input>
                            </el-form-item>
                            <el-form-item 
                                class="text-center col-lg-4"
                                label="Type De Chargement"
                                prop="type_chargement">
                                <el-input type="text" v-model="formDevisTransport.type_chargement"></el-input>
                            </el-form-item>
                            <el-form-item 
                                class="col-lg-4"
                                label="Date Enlevement"
                                prop="date_enlevement">
                                <el-input v-model="formDevisTransport.date_enlevement" placeholder="Date Enlevement"></el-input>
                            </el-form-item>
                            <el-form-item 
                                class="text-center col-lg-4"
                                label="Lieu Livraison"
                                prop="lieu_livraison">
                                <el-input v-model="formDevisTransport.lieu_livraison" placeholder="Lieu Livraison"></el-input>
                            </el-form-item>
                            <el-form-item 
                                class="text-center col-lg-4"
                                label="Poid Net Total"
                                prop="poids_net_total">
                                <el-input type="text" v-model="formDevisTransport.poids_net_total"></el-input>
                            </el-form-item>
                            <el-form-item 
                                class="text-center col-lg-4"
                                label="Poid Brut Total"
                                prop="poids_brut_total">
                                <el-input type="text" v-model="formDevisTransport.poids_brut_total"></el-input>
                            </el-form-item>
                        </div>
                        
                </fieldset>
                  
              </el-form>
              <!-- show on edit mode -->
              <span slot="footer"  class="dialog-footer">
                  <el-button @click="DialogVisibleDevisTransport = false">Annuler</el-button>
                  <el-button type="primary" @click="createDevisTransport()">Valider</el-button>
              </span>
          </el-dialog>
          <!-- End Modal -->
          <el-dialog
          
              :title="editmode ? 'Modifier La Facture Pro Forma':'Saisie La Facture Pro Forma'"
              :visible.sync="DialogVisibleSFPFPlaques"
              width="90%"
              :before-close="handleClose">
              <!-- Body dialog -->
              <el-form :model="formDevisPlaque">
                  
                  <!-- <fieldset class="border p-2 mt-2">
                    <strong class="mt-4 mb-4"> FOURNISSEUR : <el-tag effect="dark"> {{form_Prices.fournisseur}} </el-tag> </strong>
                  </fieldset> -->
                  <el-form :model="form_ProForma">
                    <fieldset class="border p-2 mt-2" v-if="active === 0">
                            <div class="row">
                                <el-form-item 
                                    class="col-lg-4"
                                    label="N° Facture Pro Forma"
                                    prop="num_facturePro">
                                    <el-input v-model="form_ProForma.num_facturePro" placeholder="Saisie N° Facture"></el-input>
                                </el-form-item>
                                <el-form-item 
                                    class="text-center col-lg-4"
                                    label="Date"
                                    prop="date_facturePro">
                                    <el-date-picker type="date" placeholder="Choisis Date Facture" v-model="form_ProForma.date_facturePro" format="yyyy-MM-dd" value-format="yyyy-MM-dd" style="width: 100%;"></el-date-picker>
                                </el-form-item>
                                <el-form-item 
                                    class="text-center col-lg-4"
                                    label="Conditions de réglement"
                                    prop="condition_reglement">
                                    <el-input type="text" v-model="form_ProForma.condition_reglement"></el-input>
                                </el-form-item>
                            </div>
                    </fieldset>
                    <fieldset class="border p-2 col-lg-12 mt-3" v-if="active === 1">
                        <legend class="w-auto badge"><h5> Saisie Les infos ... </h5></legend>
                            <!-- <div v-for="dev in devisPlaque" :key="dev.id">
                                <div v-for="(d, k) in dev.get_plaques" :key="k">
                                    <p class="mt-5"><strong>Plaque N° {{k+1}} : </strong>{{d.get_e_t_c.formatMM_L}}x{{d.get_e_t_c.formatMM_D}}-{{d.get_e_t_c.forme_etq}},
                                        Z-Cylindre : {{d.get_e_t_c.Z_cylindre}}, Nombre De Pose Laize : {{d.get_e_t_c.Nombre_PosesL}} , 
                                        Nombre De Pose Developpe : {{d.get_e_t_c.nombre_PosesD}}, Support (PAPIER) : {{d.get_e_t_c.support_papier}},
                                        Laize Support Plan Du Travail Calcule En mm {{d.get_e_t_c.support_papier}}, Machine : {{d.get_e_t_c.Machine}}
                                    </p>
                                </div>
                            </div> -->
                            <div>
                               
                                
                                <div v-for="(c, k) in form_ProForma.myarticles" :key="k">
                                    <strong>Plaque Réf : {{c.id_plaque}}</strong>
                                    <el-descriptions class="mb-5" direction="vertical" :column="3"  border>
                                        <el-descriptions-item><template slot="label"><strong>Désignation Fournisseur :</strong></template> 
                                            <el-input v-model="c.designation_fournisseur" placeholder="Saisie Désigniation Fournisseur"></el-input>
                                        </el-descriptions-item>
                                        <el-descriptions-item><template slot="label"><strong>Rubrique Désignation :</strong></template> 
                                            <el-input v-model="c.rebrique_designation" placeholder="Saisie La Rebrique Désignation"></el-input>
                                        </el-descriptions-item>
                                        <el-descriptions-item><template slot="label"><strong>Code Désignation :</strong></template> 
                                            <el-input v-model="c.code_designation" placeholder="Saisie Code Désignation"></el-input>
                                        </el-descriptions-item>
                                        <el-descriptions-item><template slot="label"><strong>Devis :</strong></template> 
                                            <el-input v-model="c.devis" placeholder="Saisie Devis"></el-input>
                                        </el-descriptions-item>
                                        <el-descriptions-item><template slot="label"><strong>Nomenclature (Code Marchandise) :</strong></template> 
                                            <el-input v-model="c.nomenclature" placeholder="Saisie N° Nomenclature"></el-input>
                                        </el-descriptions-item>
                                        <el-descriptions-item><template slot="label"><strong>Poids Net (KG):</strong></template> 
                                            <el-input v-model="c.poids_net" placeholder="Saisie Poids net"></el-input>
                                        </el-descriptions-item>
                                        <el-descriptions-item><template slot="label"><strong>Poids Brut (KG):</strong></template> 
                                            <el-input v-model="c.poids_brut" placeholder="Saisie Poids brut"></el-input>
                                        </el-descriptions-item>
                                        <el-descriptions-item><template slot="label"><strong>Pays D'origine :</strong></template> 
                                            <el-input v-model="c.pays_origine" placeholder="Saisie Pays D'origine"></el-input>
                                        </el-descriptions-item>
                                        <el-descriptions-item><template slot="label" ><strong>Pays Provenance :</strong></template> 
                                            <el-input v-model="c.pays_provenance" placeholder="Saisie Pays D'origine"></el-input>
                                        </el-descriptions-item>
                                        <el-descriptions-item><template slot="label"><strong>Unite De Mesure :</strong></template> 
                                            <el-input v-model="c.unite_mesure" placeholder="Saisie Unite"></el-input>
                                        </el-descriptions-item>
                                        <el-descriptions-item><template slot="label"><strong>Quantité :</strong></template> 
                                            <el-input v-model="c.qtit" type="number" placeholder="Saisie La Quantité"></el-input>
                                        </el-descriptions-item>
                                        <el-descriptions-item><template slot="label"><strong>Prix Unitaire :</strong></template> 
                                            <el-input v-model="c.prix_unit" placeholder="Saisie Prix Unitaire"></el-input>
                                        </el-descriptions-item>

                                        <el-descriptions-item><template slot="label"><strong>INCOTERM :</strong></template> 
                                            <fieldset class="border p-2 mt-2">
                                                <legend class="w-auto badge"><h5></h5></legend>
                                                        <el-form-item 
                                                            class=" col-lg-12 text-center"
                                                            prop="incoterm"
                                                        >
                                                            <el-radio-group v-model="c.incoterm">
                                                                <el-radio label="EX-WORKS (à l'usine)"></el-radio>
                                                                <el-radio label="FCA – Free Carrier (franco transporteur)"></el-radio>
                                                                <el-radio label="Les Incoterms® CPT et CIP"></el-radio>
                                                                <el-radio label="Les Incoterms® DAP, DPU et DDP"></el-radio>
                                                            </el-radio-group>
                                                        </el-form-item>
                                                </fieldset>
                                                <!-- Images -->
                                                <fieldset v-if="c.incoterm == 'EX-WORKS (à l\'usine)'" class="border p-2 mt-2">
                                                    <div class="demo-image__preview text-center">
                                                        <el-image
                                                            :src="url1"
                                                            :preview-src-list="srcList1">
                                                        </el-image>
                                                    </div>
                                                    <el-alert
                                                        class="mt-3"
                                                        title="Description : "
                                                        description="Règle qui impose le moins d'obligations au vendeur, dont l'unique responsabilité consiste à emballer les marchandises et à les mettre à disposition de l'acheteur dans ses propres locaux. En vertu de cette règle, l'acheteur supporte ainsi tous les frais et risques inhérents au chargement et au transport des marchandises jusqu'à leur arrivée à destination.
                                                        Les formalités douanières à l'export étant à la charge de l'acheteur, celui-ci peut rencontrer des difficultés dans le pays du vendeur pour obtenir les justificatifs de sortie des marchandises.
                                                        C'est pourquoi l'ICC recommande de réserver cette règle aux échanges nationaux ou régionaux n'impliquant pas l'exportation des marchandises et de privilégier la règle FCA en vertu de laquelle les formalités et coûts de dédouanement incombent au vendeur."
                                                        type="info"
                                                        effect="dark"
                                                        :closable="false"
                                                        show-icon>
                                                    </el-alert>
                                                    
                                                </fieldset>
                                                <fieldset v-if="c.incoterm == 'FCA – Free Carrier (franco transporteur)'" class="border p-2 mt-2">
                                                    <div class="demo-image__preview text-center">
                                                        <el-image
                                                            :src="url2"
                                                            :preview-src-list="srcList2">
                                                        </el-image>
                                                        <el-alert
                                                            class="mt-3"
                                                            title="Description : "
                                                            description="Deux options sont possibles pour cet Incoterms® en fonction du lieu de livraison :
                                                                        dans les locaux du vendeur, qui procède au chargement des marchandises sur le moyen de transport de l'acheteur (FCA « locaux du vendeur ») ;
                                                                        dans tout autre lieu : le vendeur organise l'acheminement des marchandises jusqu'au lieu d'embarquement où elles sont mises à disposition du transporteur prêtes à être déchargées (FCA « autre lieu convenu »).
                                                                        En vertu de cette règle, l'acheteur prend en charge la majeure partie du transport, mais lui permet d'être dispensé des formalités dans le pays d'exportation, qui incombent au vendeur."
                                                            type="info"
                                                            effect="dark"
                                                            :closable="false"
                                                            show-icon>
                                                        </el-alert>
                                                    </div>
                                                </fieldset>
                                                <fieldset v-if="c.incoterm == 'Les Incoterms® CPT et CIP'" class="border p-2 mt-2">
                                                    <div class="demo-image__preview text-center">
                                                        <el-image
                                                            :src="url3"
                                                            :preview-src-list="srcList3">
                                                        </el-image>
                                                        <el-alert
                                                            class="mt-3"
                                                            title="Description : "
                                                            description="CPT – Carriage Paid To (port payé jusqu'à)
                                                                        Le vendeur supporte le coût du transport jusqu'au lieu de destination mais n'est plus responsable des marchandises, qui voyagent aux risques de l'acheteur. En effet, le transfert des risques intervient au moment de la livraison, dès que les marchandises sont remises au transporteur, tandis que le transfert des coûts à l'acheteur a lieu lorsque les marchandises arrivent à destination.

                                                                        CIP – Carriage and Insurance Paid to (port payé assurance comprise jusqu’à)
                                                                        Le vendeur supporte les coûts de transport jusqu’au lieu de destination indiqué par l’Incoterms®.
                                                                        Règle fréquemment utilisée, notamment pour le transport conteneurisé, le CIP permet de maîtriser l’acheminement des marchandises jusqu’à un point donné.

                                                                        De même que pour le CPT, les frais de déchargement au lieu de destination convenu ne sont à la charge du vendeur que si le contrat de transport le prévoit.
                                                                        En revanche, à la différence du CPT, le vendeur a l’obligation de souscrire une assurance couvrant les risques liés au transport des marchandises jusqu’au lieu de destination."
                                                            type="info"
                                                            effect="dark"
                                                            :closable="false"
                                                            show-icon>
                                                        </el-alert>
                                                    </div>
                                                </fieldset>
                                                <fieldset v-if="c.incoterm == 'Les Incoterms® DAP, DPU et DDP'" class="border p-2 mt-2">
                                                    <div class="demo-image__preview text-center">
                                                        <el-image
                                                            :src="url4"
                                                            :preview-src-list="srcList4">
                                                        </el-image>
                                                        <el-alert
                                                            class="mt-3"
                                                            title="Description : "
                                                            description="S’agissant des règles Incoterms® D, la livraison étant effectuée dans le pays de destination, le transfert des risques intervient dans celui-ci.

                                                                        En vertu de ces Incoterms® dits de « vente à l’arrivée », la marchandise voyage aux risques du vendeur qui assume l’ensemble des risques et des coûts liés au transport des marchandises jusqu’au lieu de destination.

                                                                        DAP – Delivered At Place (rendu au lieu de destination)
                                                                        Cet Incoterms® signifie que les marchandises sont considérées comme livrées lorsqu’elles sont mises à disposition de l’acheteur à destination sur le moyen de transport arrivant, sans être déchargées. En vertu de cette règle, le vendeur prend en charge le transport des marchandises jusqu’au point de livraison convenu, dans le pays de destination.
                                                                        Ainsi, sauf si le contrat de transport en dispose autrement, c’est à l’acheteur qu’incombent les formalités douanières, le paiement des droits et taxes dus en raison de l’importation et le déchargement des marchandises à destination.

                                                                        DPU – Delivered at Place Unloaded (rendu au lieu de destination déchargé)
                                                                        Le DPU remplace le DAT 2010 et devient une nouvelle règle des Incoterms® 2020.
                                                                        Cette règle signifie que les marchandises sont considérées comme livrées, une fois déchargées du moyen de transport et mises à disposition de l’acheteur au lieu de destination convenu (terminal ou autre).
                                                                        Dans cette règle Incoterms®, la livraison et l’arrivée à destination interviennent au même point. Le vendeur assume donc tous les risques et coûts liés au transport des marchandises et à leur déchargement jusqu’au lieu désigné.
                                                                        Le DPU est la seule règle Incoterms® qui oblige le vendeur à décharger les marchandises à destination.

                                                                        DDP – Delivered Duty Paid (rendu droits acquittés)  Règle Incoterms® qui confère le niveau maximal d’obligations au vendeur, qui assume tous les risques et frais, y compris de dédouanement, jusqu’au lieu convenu.
                                                                        Ainsi, en vertu de cet Incoterms®, les marchandises sont livrées dédouanées, prêtes à être déchargées au lieu de destination.
                                                                        Seuls les frais d’assurance et de déchargement à destination sont à la charge de l’acheteur."
                                                            type="info"
                                                            effect="dark"
                                                            :closable="false"
                                                            show-icon>
                                                        </el-alert>
                                                    </div>
                                                </fieldset>
                                        </el-descriptions-item>
                                    </el-descriptions>
                                </div>
                            </div>
                    </fieldset>
                    <fieldset class="border p-2 mt-2" v-if="active === 2">
                            <div class="row">
                                <el-form-item 
                                    class="col-lg-4"
                                    label="Montant Total"
                                    prop="montantPrixTotal">
                                    <el-input v-model="form_ProForma.montantPrixTotal" placeholder="Motant Total">
                                        <template slot="append">EURO</template>
                                    </el-input>
                                </el-form-item>
                                <el-form-item 
                                    class="col-lg-4"
                                    label="Cours De Change Actuel"
                                    prop="cours_changeActuel">
                                    <el-input v-model="form_ProForma.cours_changeActuel" @change="calculerValeurTotal" placeholder="Saisie Cours de change actuel Total"></el-input>
                                </el-form-item>
                                <el-form-item 
                                    class="text-center col-lg-4"
                                    label="Contre Valeur Total en DH"
                                    prop="valeur_totalCalculer">
                                    <el-input type="text" v-model="form_ProForma.valeur_totalCalculer" placeholder="Contre Valeur Total Calculer en DH">
                                        <template slot="append">DH</template>
                                    </el-input>
                                </el-form-item>
                            </div>
                    </fieldset>
                    <fieldset class="border p-2 mt-2" v-if="active === 3">
                            <div class="row">
                                <el-form-item 
                                    class="col-lg-4"
                                    label="TYPE DE LA MODALITÉ DE PAIMENT"
                                    prop="type_davance"
                                >
                                    <el-select v-model="form_ProForma.type_davance" style="width: 100%" placeholder="SELECTIONNER TYPE DE LA MODALITÉ DE PAIMENT">
                                            <el-option label="Pourcentage %" value="Pourcentage %"></el-option>
                                            <el-option label="Nombre De Jours" value="Nombre De Jours"></el-option>
                                            <el-option label="Montant" value="Montant"></el-option>
                                    </el-select>
                                    
                                </el-form-item>
                                <el-form-item 
                                    class="col-lg-4"
                                    v-if="form_ProForma.type_davance == 'Pourcentage %'"
                                    label="MODALITÉ DE PAIMENT %"
                                    prop="modalite_paiment"
                                >
                                    <el-select v-model="form_ProForma.modalite_paiment" style="width: 100%" placeholder="SELECTIONNER LA MODALITÉ DE PAIMENT">
                                            <el-option label="10 %" value="10"></el-option>
                                            <el-option label="20 %" value="20"></el-option>
                                            <el-option label="30 %" value="30"></el-option>
                                            <div v-if="form_ProForma.valeur_totalCalculer < 200000">
                                                <el-option label="40 %" value="40"></el-option>
                                                <el-option label="50 %" value="50"></el-option>
                                                <el-option label="60 %" value="60"></el-option>
                                                <el-option label="70 %" value="70"></el-option>
                                                <el-option label="80 %" value="80"></el-option>
                                                <el-option label="90 %" value="90"></el-option>
                                                <el-option label="100 %" value="100"></el-option>
                                            </div>
                                    </el-select>
                                </el-form-item>
                                
                            </div>
                    </fieldset>
                    <fieldset class="border p-2 mt-2" v-if="active === 4">
                            <div class="row">
                                <el-form-item 
                                    class="col-lg-6"
                                    label="Type De Transport"
                                    prop="type_transport"
                                >
                                    <el-select v-model="form_ProForma.type_transport" style="width: 100%" placeholder="SELECTIONNER LE TYPE DE TRANSPORT">
                                            <el-option label="Routier" value="Routier"></el-option>
                                            <el-option label="Maritime" value="Maritime"></el-option>
                                            <el-option label="Aérien" value="Aérien"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item 
                                    class="col-lg-6"
                                    label="Les Charges de transport"
                                    prop="charge_transport"
                                >
                                    <el-select v-model="form_ProForma.charge_transport" style="width: 100%" placeholder="DETERMINER LES CHARGES DE TRANSPORT">
                                            <el-option label="A NOTRE CHARGE (PORT PAYÉ)" value="port_paye"></el-option>
                                            <el-option label="A LA CHARGE DE FOURNISSEUR (PORT DUÊ)" value="Maritime"></el-option>
                                    </el-select>
                                </el-form-item>
                                
                            </div>
                    </fieldset>
                </el-form>
                <fieldset class="border p-2 col-lg-12">
                    <el-form-item>
                        <el-button v-if="this.active > 0" type="primary" @click="prev" icon="el-icon-arrow-left">L'étape Précédente </el-button>
                        <el-button v-if="this.active < 4" type="primary" class="float-right"  @click="next">L'étape suivante <i class="el-icon-arrow-right"></i></el-button>
                        <!-- <el-button v-if="this.active < 2" type="primary" class="float-right"  @click=submitForm1(form_global)>L'étape suivante <i class="el-icon-arrow-right"></i></el-button> -->
                    </el-form-item>
                </fieldset>
                  
              </el-form>
              <!-- show on edit mode -->
              <span slot="footer"  class="dialog-footer">
                  <el-button @click="DialogVisibleSFPFPlaques = false">Annuler</el-button>
                  <el-button type="primary" v-show="!editmode" @click="CreateProForma()">Valider</el-button>
                  <el-button type="primary" v-show="editmode" @click="UpdateProForma()">Valider</el-button>
              </span>
          </el-dialog>
          <!-- End Modal -->
          <!-- Demande Virement Plaque -->
          <el-dialog
              :title="'ORDRE DE VIREMENT INTERNATIONAL'"
              :visible.sync="DialogVisibleValideDevisPlaques"
              width="70%"
              :before-close="handleClose">
              <!-- Body dialog -->
              <el-form :model="formDevisPlaque">
                    <fieldset class="border p-2 mt-2">
                        <strong class="mt-4 mb-4"> FOURNISSEUR : <el-tag effect="dark"> {{form_bon_cmd.fournisseur}} </el-tag> </strong>
                    </fieldset>
                  <fieldset class="border p-2 mt-2">
                        <div class="row">
                            <el-form-item 
                                class="col-lg-4"
                                label="Montant En Chiffres"
                                prop="montantChiffre"
                            >
                                <el-input v-model="form_bon_cmd.montantChiffre" placeholder="Saisie Le Montant En Chiffre"></el-input>
                            </el-form-item>
                            <el-form-item 
                                class="col-lg-8"
                                label="Montant En Lettre"
                                prop="montantLettre"
                            >
                                <el-input v-model="form_bon_cmd.montantLettre" placeholder="Saisie Le Montant En Lettre"></el-input>
                            </el-form-item>
                            <el-form-item 
                                class="col-lg-12"
                                label="SWIFT"
                                prop="montantLettre"
                            >
                                <el-input v-model="form_bon_cmd.swift" placeholder="Saisie Le SWIFT"></el-input>
                            </el-form-item>
                        </div>
                  </fieldset>
              </el-form>
              <!-- show on edit mode -->
              <span slot="footer"  class="dialog-footer">
                  <el-button @click="DialogVisibleValideDevisPlaques = false">Annuler</el-button>
                  <el-button type="primary" @click="validerDevisPlaque()">Valider</el-button>
              </span>
          </el-dialog>
          <!-- End Modal -->
          <!-- Demande Facture Pro format Plaque -->
          <el-dialog
              :title="'DEMANDE FACTURE PRO FORMAT'"
              :visible.sync="DialogVisibleDemandeFactureProFormat"
              width="70%"
              :before-close="handleClose">
              <!-- Body dialog -->
              <el-form :model="form_d_pro_format">
                
                    <fieldset class="border p-2 mt-2">
                        <strong class="mt-4 mb-4"> FOURNISSEUR : <el-tag effect="dark"> {{form_d_pro_format.fournisseur_name}} </el-tag> </strong>
                    </fieldset>
                  <fieldset class="border p-2 mt-2">
                        <div class="row">
                            <el-form-item 
                                class="col-lg-4"
                                label="Montant En Chiffres"
                                prop="montantChiffre"
                            >
                                <el-input v-model="form_d_pro_format.montantChiffre" placeholder="Saisie Le Montant En Chiffre"></el-input>
                            </el-form-item>
                            <el-form-item 
                                class="col-lg-8"
                                label="Montant En Lettre"
                                prop="montantLettre"
                            >
                                <el-input v-model="form_d_pro_format.montantLettre" placeholder="Saisie Le Montant En Lettre"></el-input>
                            </el-form-item>
                        </div>
                  </fieldset>
              </el-form>
              <!-- show on edit mode -->
              <span slot="footer"  class="dialog-footer">
                  <el-button @click="DialogVisibleDemandeFactureProFormat = false">Annuler</el-button>
                  <el-button type="primary" @click="CreateDemandeProFormat()">Valider</el-button>
              </span>
          </el-dialog>
          <!-- End Modal -->
          <!-- Demande Correction Engagement -->
          <el-dialog
              :title="'LES INFORMATION A CORRIGER SUR L\'ENGAGEMENT SAISI'"
              :visible.sync="DialogVisibleDemandeCENGAGE"
              width="90%"
              :before-close="handleClose">
              <!-- Body dialog -->
              <el-form :model="form_correction_engagement">
                
                    <fieldset class="border p-2 mt-2">
                        <strong class="mt-4 mb-4"> FOURNISSEUR : <el-tag effect="dark"> {{form_correction_engagement.fournisseur_name}} </el-tag> </strong>
                    </fieldset>
                  <fieldset class="border p-2 mt-2">
                    
                        <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col">Liste Des Infos</th>
                                            <th scope="col">Donnees Sur Facture Pro Forma</th>
                                            <th scope="col">Donnees Sur Engagement Saisi</th>
                                            <th scope="col">Etat Des Donnes Saisies</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row">NOM D'EXPEDITEUR<br>(FOURNISSEUR)</th>
                                            <td><el-input  v-model="form_correction_engagement.nom_F_Pro" type="text"></el-input></td>
                                            <td><el-input @change="nomfournisseur('nom_F')" v-model="form_correction_engagement.nom_F_Enga" type="text"></el-input></td>
                                            <td><el-input class="text-center"  v-model="form_correction_engagement.nom_F_Etat" type="text" disabled></el-input></td>
                                        </tr>
                                        <tr>
                                            <th scope="row">BUREAU DOUANIER</th>
                                            <td><el-input  v-model="form_correction_engagement.bureau_D_Pro" type="text"></el-input></td>
                                            <td><el-input  @change="nomfournisseur('bureau_D')" v-model="form_correction_engagement.bureau_D_Enga" type="text"></el-input></td>
                                            <td><el-input  class="text-center" style="color: black" v-model="form_correction_engagement.bureau_D_Etat" type="text" disabled></el-input></td>
                                        </tr>
                                        <tr>
                                            <th scope="row">REGIME DOUANIER</th>
                                            <td><el-input  v-model="form_correction_engagement.regime_D_Pro" type="text"></el-input></td>
                                            <td><el-input  @change="nomfournisseur('regime_D')" v-model="form_correction_engagement.regime_D_Enga" type="text"></el-input></td>
                                            <td><el-input  class="text-center" v-model="form_correction_engagement.regime_D_Etat" type="text" disabled></el-input></td>
                                        </tr>
                                        <tr>
                                            <th scope="row">TYPE TRANSPORT</th>
                                            <td><el-input  v-model="form_correction_engagement.transport_Pro" type="text"></el-input></td>
                                            <td><el-input  @change="nomfournisseur('transport')" v-model="form_correction_engagement.transport_Enga" type="text"></el-input></td>
                                            <td><el-input  class="text-center" v-model="form_correction_engagement.transport_Etat" type="text" disabled></el-input></td>
                                        </tr>
                                        <tr>
                                            <th scope="row">DESIGNATION</th>
                                            <td><el-input  v-model="form_correction_engagement.designation_Pro" type="text"></el-input></td>
                                            <td><el-input  @change="nomfournisseur('designation')" v-model="form_correction_engagement.designation_Enga" type="text"></el-input></td>
                                            <td><el-input  class="text-center" v-model="form_correction_engagement.designation_Etat" type="text" disabled></el-input></td>
                                        </tr>
                                        <tr>
                                            <th scope="row">QUANTITE</th>
                                            <td><el-input  v-model="form_correction_engagement.qtit_Pro" type="text"></el-input></td>
                                            <td><el-input  @change="nomfournisseur('qtit')" v-model="form_correction_engagement.qtit_Enga" type="text"></el-input></td>
                                            <td><el-input  class="text-center" v-model="form_correction_engagement.qtit_Etat" type="text" disabled></el-input></td>
                                        </tr>
                                        <tr>
                                            <th scope="row">NOMENCLATURE</th>
                                            <td><el-input  v-model="form_correction_engagement.nomenclature_Pro" type="text"></el-input></td>
                                            <td><el-input  @change="nomfournisseur('nomenclature')" v-model="form_correction_engagement.nomenclature_Enga" type="text"></el-input></td>
                                            <td><el-input  class="text-center" v-model="form_correction_engagement.nomenclature_Etat" type="text" disabled></el-input></td>
                                        </tr>
                                        <tr>
                                            <th scope="row">POIDS NET EN KG</th>
                                            <td><el-input  v-model="form_correction_engagement.P_NET_Pro" type="text"></el-input></td>
                                            <td><el-input  @change="nomfournisseur('P_NET')" v-model="form_correction_engagement.P_NET_Enga" type="text"></el-input></td>
                                            <td><el-input  class="text-center" v-model="form_correction_engagement.P_NET_Etat" type="text" disabled></el-input></td>
                                        </tr>
                                        <tr>
                                            <th scope="row">POIDS BRUT EN KG</th>
                                            <td><el-input  v-model="form_correction_engagement.P_BRUT_Pro" type="text"></el-input></td>
                                            <td><el-input  @change="nomfournisseur('P_BRUT')" v-model="form_correction_engagement.P_BRUT_Enga" type="text"></el-input></td>
                                            <td><el-input  class="text-center" v-model="form_correction_engagement.P_BRUT_Etat" type="text" disabled></el-input></td>
                                        </tr>
                                        <tr>
                                            <th scope="row">VALEUR EN DEVIS</th>
                                            <td><el-input  v-model="form_correction_engagement.devis_Pro" type="text"></el-input></td>
                                            <td><el-input  @change="nomfournisseur('devis')" v-model="form_correction_engagement.devis_Enga" type="text"></el-input></td>
                                            <td><el-input  class="text-center" v-model="form_correction_engagement.devis_Etat" type="text" disabled></el-input></td>
                                        </tr>
                                        <tr>
                                            <th scope="row">PAYS D'ORIGINE <br> PAYS DE PROVENANCE</th>
                                            <td><el-input  v-model="form_correction_engagement.pays_Pro" type="text"></el-input></td>
                                            <td><el-input  @change="nomfournisseur('pays')" v-model="form_correction_engagement.pays_Enga" type="text"></el-input></td>
                                            <td><el-input  class="text-center" v-model="form_correction_engagement.pays_Etat" type="text" disabled></el-input></td>
                                        </tr>
                                        <tr>
                                            <th scope="row">INCOTERM</th>
                                            <td><el-input  v-model="form_correction_engagement.incoterm_Pro" type="text"></el-input></td>
                                            <td><el-input  @change="nomfournisseur('incoterm')" v-model="form_correction_engagement.incoterm_Enga" type="text"></el-input></td>
                                            <td><el-input  class="text-center" v-model="form_correction_engagement.incoterm_Etat" type="text" disabled></el-input></td>
                                        </tr>
                                        <tr>
                                            <th scope="row">FRET</th>
                                            <td><el-input  v-model="form_correction_engagement.fret_Pro" type="text"></el-input></td>
                                            <td><el-input  @change="nomfournisseur('fret')" v-model="form_correction_engagement.fret_Enga" type="text"></el-input></td>
                                            <td><el-input  class="text-center" v-model="form_correction_engagement.fret_Etat" type="text" disabled></el-input></td>
                                        </tr>
                                    </tbody>
                            </table>
                        
                    
                  </fieldset>
              </el-form>
              <!-- show on edit mode -->
              <span slot="footer"  class="dialog-footer">
                  <el-button @click="DialogVisibleDemandeCENGAGE = false">Annuler</el-button>
                  <el-button type="primary" @click="CreateDemandeCEngagement()">Valider</el-button>
              </span>
          </el-dialog>
          <!-- End Modal -->
          <!-- Demande Correction Engagement -->
          <el-dialog
              :title="'Enregistrement De Caution Banquaire'"
              :visible.sync="DialogVisibleCoutionBanque"
              width="80%"
              :before-close="handleClose">
              <!-- Body dialog -->
              
              <el-form :model="form_caution_banque">
                  <fieldset class="border p-2 mt-2">
                    <div class="row">
                        <el-form-item 
                            class="col-lg-4"
                            label="N° De La DUM"
                            prop="num_dum"
                        >
                            <el-input v-model="form_caution_banque.num_dum" placeholder="Saisie N° De La DUM"></el-input>
                        </el-form-item>
                        <el-form-item 
                            class="col-lg-4"
                            label="Date DUM"
                            prop="date_dum"
                        >
                            <el-date-picker type="date" placeholder="Saisie Date De La DUM" v-model="form_caution_banque.date_dum" format="yyyy-MM-dd" value-format="yyyy-MM-dd" style="width: 100%;"></el-date-picker>
                        </el-form-item>
                        <el-form-item 
                            class="col-lg-4"
                            label="Mantant Declarée"
                            prop="mantant_declarée"
                        >
                            <el-input v-model="form_caution_banque.mantant_declarée" placeholder="Saisie Le Mantant Declarée"></el-input>
                        </el-form-item>
                        <el-form-item 
                            class="col-lg-4"
                            label="Mantant Cautionné"
                            prop="mantant_cautionée"
                        >
                            <el-input v-model="form_caution_banque.mantant_cautionée" placeholder="Saisie Le Mantant Cautionné"></el-input>
                        </el-form-item>
                        <el-form-item 
                            class="col-lg-4"
                            label="Taux Calaculer"
                            prop="taux_calculer"
                        >
                            <el-input v-model="form_caution_banque.taux_calculer" placeholder="Saisie Taux Calculer"></el-input>
                        </el-form-item>
                        <el-form-item 
                            class="col-lg-4"
                            label="N° Caution Banquaire"
                            prop="num_caution_banque"
                        >
                            <el-input v-model="form_caution_banque.num_caution_banque" placeholder="Saisie N° Caution Banquaire"></el-input>
                        </el-form-item>
                    </div>
                    
                  </fieldset>
              </el-form>
              <!-- show on edit mode -->
              <span slot="footer"  class="dialog-footer">
                  <el-button @click="DialogVisibleCoutionBanque = false">Annuler</el-button>
                  <el-button type="primary" @click="CreateEnregistreCautionB()">Valider</el-button>
              </span>
          </el-dialog>
          <!-- End Modal -->
          <!-- Saisie Fiche Liquidation -->
          <el-dialog
              :title="'Saisie Fiche De Liquidation'"
              :visible.sync="DialogVisibleFLiquidation"
              width="80%"
              :before-close="handleClose">
              <!-- Body dialog -->
              
              <el-form :model="form_F_Liquidation">
                  <fieldset class="border p-2 mt-2">
                    <div class="row">
                        <el-form-item 
                            class="col-lg-4"
                            label="N° Déclaration"
                            prop="num_declaration"
                        >
                            <el-input v-model="form_F_Liquidation.num_declaration" placeholder="Saisie N° Déclaration"></el-input>
                        </el-form-item>
                        <el-form-item 
                            class="col-lg-4"
                            label="Date Déclaration"
                            prop="date_declaration"
                        >
                            <el-date-picker type="date" placeholder="Saisie Date De La Déclaration" v-model="form_F_Liquidation.date_declaration" format="yyyy-MM-dd" value-format="yyyy-MM-dd" style="width: 100%;"></el-date-picker>
                        </el-form-item>
                        <el-form-item 
                            class="col-lg-4"
                            label="N° Liquidation"
                            prop="num_liquidation"
                        >
                            <el-input v-model="form_F_Liquidation.num_liquidation" placeholder="Saisie Le N° Liquidation"></el-input>
                        </el-form-item>
                        <el-form-item 
                            class="col-lg-4"
                            label="Date Liquidation"
                            prop="date_liquidation"
                        >
                            <el-date-picker type="date" placeholder="Saisie Date De La Liquidation" v-model="form_F_Liquidation.date_liquidation" format="yyyy-MM-dd" value-format="yyyy-MM-dd" style="width: 100%;"></el-date-picker>
                        </el-form-item>
                    </div>
                    
                  </fieldset>
              </el-form>
              <!-- show on edit mode -->
              <span slot="footer"  class="dialog-footer">
                  <el-button @click="DialogVisibleFLiquidation = false">Annuler</el-button>
                  <el-button type="primary" @click="CreateFicheLiquidation()">Valider</el-button>
              </span>
          </el-dialog>
          <!-- End Modal -->
          <!-- Saisie Fiche Liquidation -->
          <el-dialog
              :title="'Saisie Réglement des droit de taxes d\'importation'"
              :visible.sync="DialogVisibleRDroitTaxes"
              width="80%"
              :before-close="handleClose">
              <!-- Body dialog -->
              
              <el-form :model="form_R_Droit_Taxes">
                  <fieldset class="border p-2 mt-2">
                    <div class="row">
                        <el-form-item 
                            class="col-lg-4"
                            label="N° Transaction MTC"
                            prop="num_transaction_mtc"
                        >
                            <el-input v-model="form_R_Droit_Taxes.num_transaction_mtc" placeholder="Saisie N° Transaction MTC"></el-input>
                        </el-form-item>
                        <el-form-item 
                            class="col-lg-4"
                            label="N° Transaction Marchandise"
                            prop="num_transaction_marchand"
                        >
                            <el-input v-model="form_R_Droit_Taxes.num_transaction_marchand" placeholder="Saisie N° Transaction Marchandise"></el-input>
                        </el-form-item>
                        <el-form-item 
                            class="col-lg-4"
                            label="Date Réglement"
                            prop="date_reglement"
                        >
                            <el-date-picker type="date" placeholder="Saisie Date De La Réglement" v-model="form_R_Droit_Taxes.date_reglement" format="yyyy-MM-dd" value-format="yyyy-MM-dd" style="width: 100%;"></el-date-picker>
                        </el-form-item>
                        <el-form-item 
                            class="col-lg-4"
                            label="Mode de paiement"
                            prop="mode_paiement"
                        >
                            <el-input v-model="form_R_Droit_Taxes.mode_paiement" placeholder="Saisie Le Mode de paiement"></el-input>
                        </el-form-item>
                        <el-form-item 
                            class="col-lg-4"
                            label="Compte banquaire"
                            prop="compte_banque"
                        >
                            <el-input v-model="form_R_Droit_Taxes.compte_banque" placeholder="Saisie Le Compte banquaire"></el-input>
                        </el-form-item>
                        <el-form-item 
                            class="col-lg-4"
                            label="Paiement effectuer par"
                            prop="paiement_effectBy"
                        >
                            <el-input v-model="form_R_Droit_Taxes.paiement_effectBy" placeholder="Saisie Le Paiement effectuer par"></el-input>
                        </el-form-item>
                    </div>
                    
                  </fieldset>
              </el-form>
              <!-- show on edit mode -->
              <span slot="footer"  class="dialog-footer">
                  <el-button @click="DialogVisibleRDroitTaxes = false">Annuler</el-button>
                  <el-button type="primary" @click="CreateFicheLiquidation()">Valider</el-button>
              </span>
          </el-dialog>
          <!-- End Modal -->
          <el-dialog
              :title="'SUIVI DE COMMANDE (PFD)'"
              :visible.sync="DialogVisibleTrackingPlaques"
              width="40%"
              :before-close="handleClose">
              <!-- Body dialog -->
              <el-form :model="formTracking">
                    <!-- <fieldset class="border p-2 mt-2">
                        <strong class="mt-4 mb-4"> FOURNISSEUR : <el-tag effect="dark"> {{form_bon_cmd.fournisseur}} </el-tag> </strong>
                    </fieldset> -->
                  <fieldset class="border p-2 mt-2">
                        <div class="row">
                            <el-form-item 
                                class="col-lg-12"
                                label="Lieu"
                                prop="lieu"
                            >
                                <el-input v-model="formTracking.lieu" placeholder="Saisie Le Lieu"></el-input>
                            </el-form-item>
                        </div>
                  </fieldset>
              </el-form>
              <!-- show on edit mode -->
              <span slot="footer"  class="dialog-footer">
                  <el-button @click="DialogVisibleTrackingPlaques = false">Annuler</el-button>
                  <el-button type="primary" @click="createtrackingPlaque()">Valider</el-button>
              </span>
          </el-dialog>
          <!-- End Modal -->
          <!-- Invoice Demandes and Bon CMD -->
          <div
                          class="modal fade"
                          id="Invoice"
                          tabindex="-1"
                          role="dialog"
                          aria-labelledby="Invoice"
                          aria-hidden="true"
                      >
                          <div class="modal-dialog modal-xl" role="document">
                              <div class="modal-content">
                                  <div class="modal-header">
                                      <h5 class="modal-title text-right">Devis</h5>
                                      <button
                                          type="button"
                                          class="close"
                                          data-dismiss="modal"
                                          aria-label="Close"
                                      >
                                          <span aria-hidden="true">&times;</span>
                                      </button>
                                  </div>
                                  <!-- invoice -->
                                  <div class="modal-body" id="printMe">
                                      <div class="row" v-for="d in devisPlaque" :key="d.id">
                                          <div class="col-12">
                                              <div class="card">
                                                  <div class="card-body p-0">
                                                      <div class="row">
                                                          <div class="col-12 text-center">
                                                              <img
                                                                  class="comapny_logo_invoice"
                                                                  src="/images/logo.png"
                                                                  alt="brand-logo-for-invoice"
                                                                  style="height:90px"
                                                              />
                                                          </div>
                                                      </div>
  
                                                      <hr class="my-2" />
  
                                              <div class="page-content container" >
                                                  <div class="page-header text-blue-d2">
                                                      <h2 v-if='d.type_de_demande == 3' class="page-title text-center text-secondary-d1">
                                                          A NOTRE CHER FOURNISSEUR
                                                      </h2>
                                                      <h2 v-else-if='d.type_de_demande == 1' class="page-title text-center text-secondary-d1">
                                                          DEMANDE DE PRIX DES PLAQUES MAGNETIQUE
                                                      </h2>
                                                      <h2 v-else class="page-title text-center text-secondary-d1">
                                                          DEMANDE DE REDUCTION DE L'OFFRE DE PRIX DES PLAQUES MAGNETIQUE
                                                      </h2>
                                                  </div>
                                                  
                                                  <div class="row">
                                                      <div class="col-6"></div>
                                                      <div class="text-95 col-6 align-self-start d-sm-flex justify-content-end">
                                                          <p class="page-title text-secondary-d1">
                                                              <strong>Date  :</strong>  -- / -- / --
                                                          </p>
                                                      </div>
  
                                                  </div>
                              
                                                  <div class="container px-0" >
                                                      <div class="row mt-4">
                                                          <div class="col-12 col-lg-12">
                                                          
                                                              <hr/>
  
                                                              <div class="row">
                                                                  <div class="col-sm-6">
                                                                      <div>
                                                                          <div class="text-sm text-grey-m2 align-middle">N° DE LA DEMANDE : PL. MA 0001</div>
                                                                          <div class="text-sm text-grey-m2 align-middle">PLAN DE FORME DE DECOUPE N° : --- ,DATE : ---</div>
                                                                          <div class="text-sm text-grey-m2 align-middle ml-5">FOURNISSEUR : {{d.get_fournisseur.name}}</div>
                                                                          <div class="text-sm text-grey-m2 align-middle ml-5">ADRESSE : {{d.get_fournisseur.adressSocial}}</div>
                                                                          <div class="text-sm text-grey-m2 align-middle ml-5">PAYS :{{d.get_fournisseur.paysSocial}}</div>
                                                                          <div class="text-sm text-grey-m2 align-middle ml-5">VILLE DE CHARGEMENT : {{d.get_fournisseur.villeSocial}}</div>
                                                                          <div class="text-sm text-grey-m2 align-middle ml-5">TEL :{{d.get_fournisseur.Geranttele}}</div>
                                                                          <div class="text-sm text-grey-m2 align-middle ml-5">E-MAIL :{{d.get_fournisseur.Gerantemail}}</div>
                                                                          <div class="text-sm text-grey-m2 align-middle">CHARGE D'AFFAIRE</div>
                                                                          <div class="text-sm text-grey-m2 align-middle ml-5">NOM : --- </div>
                                                                          <div class="text-sm text-grey-m2 align-middle ml-5">E-MAIL : --- </div>
                                                                          <div class="text-sm text-grey-m2 align-middle ml-5">TEL : --- </div>
                                                                      </div>
                                                                      
                                                                  </div>
                                                                  <!-- /.col -->
  
                                                              </div>
                                                              <hr />
                                                              <div class="mt-4">
                                                                  <div class="row border-b-2 brc-default-l2"></div>
  
                                                                  <!-- or use a table instead -->
                                                                  <strong>CHER FOURNISSEUR </strong>
                                                                  <br>
                                                                  <p class="ml-3" v-if="d.type_de_demande == 1">
                                                                      MERCI DE NOUS FAIRE PARVENIR VOTRE MAILLEUR OFFRE DE PRIX CONCERNANT LES ARTICLES SUIVANTS SELON LES <br>
                                                                      SPECIFITES ET CARACTERISTIQUES TECHNIQUES DEMANDEES.
                                                                  </p>
  
                                                                  <p class="ml-3" v-else-if="d.type_de_demande == 2">
                                                                      NOUS AVONS BIEN EXAMINER VOTRE OFFRE DE PRIX N° DATE : -- / -- / --<br>
                                                                      NOUS AVONS CONSTATES QUE VOTRE PRIX PROPOSE EST EXCESSIF, ON VOUS DEMANDE CHER FOURNISSEUR
                                                                      DE FAIRE EFFORT POUR NOUS PROPOSER UNE REDUCTION AINSI QU'UN MEILLEUR DELAI PAIEMENT. <br>
                                                                  </p>
  
                                                                  <p class="ml-3" v-else>
                                                                      NOUS AVONS L'HONNEUR DE VOUS ADRESSER CE BON DE COMMANDE N° DATE : -- / -- / --<br>
                                                                      AFIN DE COMMENCER L'ORDRE DE SERVICE SELON LES NORMES ET CARACTERISTIQUES TECHNIQUES EXIGÉES.
                                                                      A CET EFFET, NOUS VOUS DEMANDONS DE PREPARER LA FACTURE PRO FORMA QUI VA PORTER TOUT LES INFORMATIONS SUIVANTES A SAVOIR : <br>
                                                                  </p>
  
                                                                  <div v-if="d.type_de_demande == 3" class="row mt-3">
                                                                    
                                                                      <div class="col-12 ml-3 text-grey-d2 text-95 mt-2 mt-lg-0">
                                                                          DESIGNATION EXACTE DE CHAQUE ARTICLE : ---
                                                                      </div>
                                                                      <div class="col-12 ml-3 text-grey-d2 text-95 mt-2 mt-lg-0">
                                                                          QUANTITE : ---
                                                                      </div>
                                                                      <div class="col-12 ml-3 text-grey-d2 text-95 mt-2 mt-lg-0">
                                                                          NOMENCLATURE DE CHAQUE ARTICLE SI IL Y A PLUSIEURS ARTICLES DIFFERENTS: ---
                                                                      </div>
                                                                      <div class="col-12 ml-3 text-grey-d2 text-95 mt-2 mt-lg-0">
                                                                          POIDS BRUT ET NET EN KG : ---
                                                                      </div>
                                                                      <div class="col-12 ml-3 text-grey-d2 text-95 mt-2 mt-lg-0">
                                                                          VALEUR EN DEVIS : ---
                                                                      </div>
                                                                      <div class="col-12 ml-3 text-grey-d2 text-95 mt-2 mt-lg-0">
                                                                          PAYS D'ORIGINE, PAYS DE PROVENANCE : ---
                                                                      </div>
                                                                      <div class="col-12 ml-3 text-grey-d2 text-95 mt-2 mt-lg-0">
                                                                          INCOTERM : ---
                                                                      </div>
                                                                      <div class="col-12 ml-3 text-grey-d2 text-95 mt-2 mt-lg-0">
                                                                          TRANSPORT : ---
                                                                      </div>
                                                                      <div class="col-12 ml-3 text-grey-d2 text-95 mt-2 mt-lg-0">
                                                                          COORDONEES BANCAIRES ET MODALITE DE PAIEMENT : ---
                                                                      </div>
  
                                                                  </div>
                                                                  <p class="ml-3" v-if="d.type_de_demande == 3">
                                                                      DANS L'ATTENTE DE VOTRE RETOUR, VEUILLEZ ACCEPTER CHER FOURNISSEUR, NOUS SINCERES SALUTATIONS.
                                                                  </p>
  
                                                                  <table class="table table-bordered" v-if="d.type_de_demande != 3">
                                                                          <thead>
                                                                              <tr>
                                                                                  <th scope="col">Réferance</th>
                                                                                  <th scope="col">Désignation</th>
                                                                                  <th scope="col">Quantité</th>
                                                                                  <th scope="col">P.U.(H.T)</th>
                                                                                  
                                                                              </tr>
                                                                          </thead>
                                                                          <tbody v-for="(c, k) in d.get_plaques" :key="k">
                                                                            <tr>
                                                                                <th scope="row">{{c.get_e_t_c.id}}</th>
                                                                                <td>Forme De Decoupe : {{c.get_e_t_c.formatMM_L}}x{{c.get_e_t_c.formatMM_D}}-{{c.get_e_t_c.forme_etq}},
                                                                                    Z-Cylindre : {{c.get_e_t_c.Z_cylindre}}, Nombre De Pose Laize : {{c.get_e_t_c.Nombre_PosesL}} , 
                                                                                    Nombre De Pose Developpe : {{c.get_e_t_c.nombre_PosesD}}, Support (PAPIER) : {{c.get_e_t_c.support_papier}},
                                                                                    Laize Support Plan Du Travail Calcule En mm {{c.get_e_t_c.support_papier}}, Machine : {{c.get_e_t_c.Machine}}
                                                                                </td>
                                                                                <td>1</td>
                                                                                <td>{{c.prix_devis}}</td>
                                                                                <td v-if="d.prix_reduction > 0">{{c.prix_reduction}}</td>
                                                                                
                                                                            </tr>
                                                                        </tbody>
                                                                  </table>
  
                                                                  <hr/>
  
                                                                  <div v-if="d.type_de_demande != 3" class="text-center">
                                                                      <strong class="text-secondary-d1 text-105">SIGNATURES :</strong>
                                                                      <p class="text-secondary-d1 text-105">CHARGE COMANDES</p>
                                                                  </div>
                                                                  <div v-else class="text-center">
                                                                      <strong class="text-secondary-d1 text-105">SIGNATURES :</strong>
                                                                      <div class="row">
                                                                        <div class="col-6">
                                                                            <p class="text-secondary-d1 text-105">Hassan EL AISSARI</p>
                                                                        </div>
                                                                        <div class="col-6">
                                                                            <p class="text-secondary-d1 text-105">Abdelaali EL AISSARI</p>
                                                                        </div>
                                                                        </div>
                                                                  </div>
                                                                  <hr />
  
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                              <hr class="my-2" />
                                              <!-- footer -->
                                              <div class=" flex-row-reverse text-center p-4">
                                                  <div class="py-3 px-5">
                                                      <div>
                                                          Lot Yamna Ismail,Avenue Med. VI Route de Biougra - Ait Meloul -B.P.N° 1810 Z.I.Ait Melloul - Tél /Fax: 05 28 24 51 90
                                                      </div>
                                                      <div>
                                                          SARL au Capital de 300 000.00 DH - R.C: 3545 Inzegan -lf : 06 95 04 67 - Patente : 49866952- CNSS : 7341845
                                                      </div>
                                                      <div>
                                                          N° Compte B.P .Ait Melloul : 101022212117001481001534 - Email: aakabar@gmail.com
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                                  </div>
                          <div class="modal-footer">
                              <button
                                  class="btn btn-primary"
                                  style="float: right;"
                                  @click="printC()"
                              >
                                  <span class="fa fa-print"></span>
                              </button>
                          </div>
                      </div>
                      <!-- end invoice  -->
                  </div>
          </div>
          <!-- End Invoice -->
          <!-- Invoice Demandes and Bon CMD -->
          <div
                          class="modal fade"
                          id="Invoice"
                          tabindex="-1"
                          role="dialog"
                          aria-labelledby="Invoice"
                          aria-hidden="true"
                      >
                          <div class="modal-dialog modal-xl" role="document">
                              <div class="modal-content">
                                  <div class="modal-header">
                                      <h5 class="modal-title text-right">Devis</h5>
                                      <button
                                          type="button"
                                          class="close"
                                          data-dismiss="modal"
                                          aria-label="Close"
                                      >
                                          <span aria-hidden="true">&times;</span>
                                      </button>
                                  </div>
                                  <!-- invoice -->
                                  <div class="modal-body" id="printMeOr">
                                      <div class="row" v-for="d in devisPlaque" :key="d.id">
                                          <div class="col-12">
                                              <div class="card">
                                                  <div class="card-body p-0">
                                                      <div class="row">
                                                          <div class="col-12 text-center">
                                                              <img
                                                                  class="comapny_logo_invoice"
                                                                  src="/images/logo.png"
                                                                  alt="brand-logo-for-invoice"
                                                                  style="height:90px"
                                                              />
                                                          </div>
                                                      </div>
  
                                                      <hr class="my-2" />
  
                                              <div class="page-content container" >
                                                <div class="row">
                                                    <div class="col-6"></div>
                                                    <div class="text-95 col-6 align-self-start d-sm-flex justify-content-end">
                                                        <p class="page-title text-secondary-d1">
                                                            <strong>Ait Melloul le  :</strong>  -- / -- / --
                                                        </p>
                                                    </div>

                                                </div>
                                                  <div class="page-header mt-5 text-blue-d2 text-center">
                                                      <h2 class="page-title text-center text-secondary-d1">
                                                          A MONSIEUR LE DIRECTEUR DE LA BANQUE POPULAIRE CENTRE SUD SERVICE COMEX - AGADIR
                                                      </h2>
                                                      
                                                  </div>
                                                  
                                                  <div class="container px-0" >
                                                      <div class="row mt-4">
                                                          <div class="col-12 col-lg-12">
                                                            <div class="page-header mt-5 text-blue-d2 text-center">
                                                                <strong class="page-title  text-secondary-d1">
                                                                    OBJET : ORDRE DE VIREMENT INTERNATIONAL PARTIEL (TOTAL) N° : VIR/INT 0001
                                                                </strong>
                                                                
                                                            </div>
                                                              <hr/>
                                                              <div class="mt-4">
                                                                  <div class="row border-b-2 brc-default-l2"></div>
  
                                                                  <!-- or use a table instead -->
                                                                  <!-- <strong>CHER FOURNISSEUR </strong> -->
                                                                  <br>
                                                                  <br class="ml-3">
                                                                  <p>
                                                                     Par la présente, merci d'établir un virement international d'un montant de : --- </p>
                                                                     <p> En chiffres : --- Euros. </p>
                                                                     <p> En lettre   : --- Euros. </p>
                                                                     <p> Par le débit de compte de la société AKABAR INO SARL N° 21 211 7001 4810 015 </p>
                                                                     <p> SWIFT : --- </p>
                                                                     <p> BANQUE POPULAIRE AGENCE ALFIRDAOUS - VILLE AIT MELLOUL - AGADIR - MAROC. </p>
                                                                     <p class="ml-5"> Au profil de la société : ---</p>
                                                                     <p class="ml-5"> Adresse : ---</p>
                                                                     <p class="ml-5"> PAYS : ---</p>
                                                                     <p class="ml-5"> TEL : ---</p>
                                                                     <p class="ml-5"> E-MAIL : ---</p>
                                                                  
  
                                                                  <div class="row mt-3">
                                                                    
                                                                      <div class="col-12 ml-3 text-grey-d2 text-95 mt-2 mt-lg-0">
                                                                          BANQUE : ---
                                                                      </div>
                                                                      <div class="col-12 ml-3 text-grey-d2 text-95 mt-2 mt-lg-0">
                                                                          N° COMPTE RIB (IBAN) : ---
                                                                      </div>
                                                                      <div class="col-12 ml-3 text-grey-d2 text-95 mt-2 mt-lg-0">
                                                                          SWIFT : ---
                                                                      </div>
                                                                      <div class="col-12 ml-3 text-grey-d2 text-95 mt-2 mt-lg-0">
                                                                          Qui concerne l'opération commerciale suivante : ---
                                                                      </div>
                                                                      <div class="col-12 ml-3 text-grey-d2 text-95 mt-2 mt-lg-0">
                                                                          FACTURE N° : --- , DATE : ---
                                                                      </div>
                                                                      <div class="col-12 ml-3 text-grey-d2 text-95 mt-2 mt-lg-0">
                                                                          ENGAGEMENT DOMICILIE N° : --- , DATE : ---
                                                                      </div>
                                                                      <div class="col-12 ml-3 text-grey-d2 text-95 mt-2 mt-lg-0">
                                                                          DATE D'IMPUTATION : --- , N° DUM : ---
                                                                      </div>
  
                                                                  </div>
  
                                                                  <hr/>
                                                                  <div class="text-center">
                                                                      <strong class="text-secondary-d1 text-105">SIGNATURES :</strong>
                                                                      <div class="row">
                                                                        <div class="col-6">
                                                                            <p class="text-secondary-d1 text-105">Hassan EL AISSARI</p>
                                                                        </div>
                                                                        <div class="col-6">
                                                                            <p class="text-secondary-d1 text-105">Abdelaali EL AISSARI</p>
                                                                        </div>
                                                                        </div>
                                                                  </div>
                                                                  <hr />
  
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                              <hr class="my-2" />
                                              <!-- footer -->
                                              <div class=" flex-row-reverse text-center p-4">
                                                  <div class="py-3 px-5">
                                                      <div>
                                                          Lot Yamna Ismail,Avenue Med. VI Route de Biougra - Ait Meloul -B.P.N° 1810 Z.I.Ait Melloul - Tél /Fax: 05 28 24 51 90
                                                      </div>
                                                      <div>
                                                          SARL au Capital de 300 000.00 DH - R.C: 3545 Inzegan -lf : 06 95 04 67 - Patente : 49866952- CNSS : 7341845
                                                      </div>
                                                      <div>
                                                          N° Compte B.P .Ait Melloul : 101022212117001481001534 - Email: aakabar@gmail.com
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                                  </div>
                          <div class="modal-footer">
                              <button
                                  class="btn btn-primary"
                                  style="float: right;"
                                  @click="printO()"
                              >
                                  <span class="fa fa-print"></span>
                              </button>
                          </div>
                      </div>
                      
                  </div>
          </div>
          <!-- End Demande Ordre virement -->
          <!-- Invoice Demandes Saisie DUM -->
          <div
                          class="modal fade"
                          id="Invoice"
                          tabindex="-1"
                          role="dialog"
                          aria-labelledby="Invoice"
                          aria-hidden="true"
                      >
                          <div class="modal-dialog modal-xl" role="document">
                              <div class="modal-content">
                                  <div class="modal-header">
                                      <h5 class="modal-title text-right">Demande Saisie D'engagement</h5>
                                      <button
                                          type="button"
                                          class="close"
                                          data-dismiss="modal"
                                          aria-label="Close"
                                      >
                                          <span aria-hidden="true">&times;</span>
                                      </button>
                                  </div>
                                  <!-- invoice -->
                                  <div class="modal-body" id="printMeSDUM">
                                    <div class="row" v-for="d in DUM_Saised" :key="d.id">
                                          <div class="col-12">
                                              <div class="card">
                                                  <div class="card-body p-0">
                                                      <div class="row">
                                                          <div class="col-12 text-center">
                                                              <img
                                                                  class="comapny_logo_invoice"
                                                                  src="/images/logo.png"
                                                                  alt="brand-logo-for-invoice"
                                                                  style="height:90px"
                                                              />
                                                          </div>
                                                      </div>
  
                                                      <hr class="my-2" />
  
                                              <div class="page-content container" >
                                                <div class="row">
                                                    <div class="col-6"></div>
                                                    <div class="text-95 col-6 align-self-start d-sm-flex justify-content-end">
                                                        <p class="page-title text-secondary-d1">
                                                            <strong>Ait Melloul le  :</strong>  -- / -- / --
                                                        </p>
                                                    </div>

                                                </div>
                                                  <div class="page-header mt-5 text-blue-d2 text-center">
                                                      <h2 class="page-title text-center text-secondary-d1">
                                                            A NOTRE CHER TRANSITAIRE
                                                      </h2>
                                                      
                                                  </div>
                                                  
                                                  <div class="container px-0" >
                                                      <div class="row mt-4">
                                                          <div class="col-12 col-lg-12">
                                                            <div class="page-header text-blue-d2 text-center">
                                                                <strong class="page-title  text-secondary-d1">
                                                                    OBJET : Demande de Saisie La Declaration Douaniére D'importation : DUM IMPORT .
                                                                </strong>
                                                            </div>
                                                              <hr/>
                                                              <div class="mt-4">
                                                                  <div class="row border-b-2 brc-default-l2"></div>
  
                                                                  <!-- or use a table instead -->
                                                                  <strong>Monsieur le directeur :</strong>
                                                                  <br>
                                                                  <br class="ml-3">
                                                                  <p class="ml-4">Merci de commencer la saisie de la declaration douaniére d'importation : DUM d'importation 
                                                                  </p>
                                                                     <p>Selon les information suivants
                                                                     </p>
                                                                     <p class="ml-5"> * Exportateur / Expediteur : {{d.exportateur}} </p>
                                                                     <p class="ml-5"> * Importateur : STE AKABAR INO SARL <br> <span class="ml-5">Adress : Ait Melloul <br>R.C : 3545 - Center.R.C: 013</span></p>
                                                                     <p class="ml-5"> * N° Declaration : 3213215 </p>
                                                                     <p class="ml-5"> * Régime douaniére : {{d.declaration}} </p>
                                                                     <p class="ml-5"> * Bureau douaniére : {{d.bureauD}} </p>
                                                                     <p class="ml-5"> * Declaration : {{d.declarant}}</p>
                                                                     <p class="ml-5"> * Taux de change : {{d.tauxChange}} </p>
                                                                     <p class="ml-5"> * Assurance : {{d.assurance}} </p>
                                                                     <p class="ml-5"> * Fret : {{d.fret}} </p>
                                                                     <p class="ml-5"> * Montant Total en Euro : {{d.totalMontant}} </p>
                                                                     <p class="ml-5"> * NOMENCLATURE : --- </p>
                                                                     <p class="ml-5"> * Poids total Net : --- </p>
                                                                     <p class="ml-5"> * Poids total Brut : --- </p>
                                                                    <br>
  
                                                                  <br class="ml-3">
                                                                  <p>Dans l'attente dune suite favorable accepter cher directeur, NOS SINCERES SALUTATIONS.
                                                                  </p>
  
                                                                  <hr/>
                                                                  <div class="text-center">
                                                                      <strong class="text-secondary-d1 text-105">SIGNATURES :</strong>
                                                                      <div class="row">
                                                                        <div class="col-6">
                                                                            <p class="text-secondary-d1 text-105">Hassan EL AISSARI</p>
                                                                        </div>
                                                                        <div class="col-6">
                                                                            <p class="text-secondary-d1 text-105">Abdelaali EL AISSARI</p>
                                                                        </div>
                                                                        </div>
                                                                  </div>
                                                                  <hr />
  
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                              <hr class="my-2" />
                                              <!-- footer -->
                                              <div class=" flex-row-reverse text-center p-4">
                                                  <div class="py-3 px-5">
                                                      <div>
                                                          Lot Yamna Ismail,Avenue Med. VI Route de Biougra - Ait Meloul -B.P.N° 1810 Z.I.Ait Melloul - Tél /Fax: 05 28 24 51 90
                                                      </div>
                                                      <div>
                                                          SARL au Capital de 300 000.00 DH - R.C: 3545 Inzegan -lf : 06 95 04 67 - Patente : 49866952- CNSS : 7341845
                                                      </div>
                                                      <div>
                                                          N° Compte B.P .Ait Melloul : 101022212117001481001534 - Email: aakabar@gmail.com
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                                  </div>
                          <div class="modal-footer">
                              <button
                                  class="btn btn-primary"
                                  style="float: right;"
                                  @click="printO()"
                              >
                                  <span class="fa fa-print"></span>
                              </button>
                          </div>
                      </div>
                      
                  </div>
          </div>
          <!-- Invoice Demandes Pro Format -->
          <div
                          class="modal fade"
                          id="Invoice"
                          tabindex="-1"
                          role="dialog"
                          aria-labelledby="Invoice"
                          aria-hidden="true"
                      >
                          <div class="modal-dialog modal-xl" role="document">
                              <div class="modal-content">
                                  <div class="modal-header">
                                      <h5 class="modal-title text-right">Devis</h5>
                                      <button
                                          type="button"
                                          class="close"
                                          data-dismiss="modal"
                                          aria-label="Close"
                                      >
                                          <span aria-hidden="true">&times;</span>
                                      </button>
                                  </div>
                                  <!-- invoice -->
                                  <div class="modal-body" id="printMePFP">
                                      <div class="row" v-for="d in ProFormatPFD" :key="d.id">
                                          <div class="col-12">
                                              <div class="card">
                                                  <div class="card-body p-0">
                                                      <div class="row">
                                                          <div class="col-12 text-center">
                                                              <img
                                                                  class="comapny_logo_invoice"
                                                                  src="/images/logo.png"
                                                                  alt="brand-logo-for-invoice"
                                                                  style="height:90px"
                                                              />
                                                          </div>
                                                      </div>
  
                                                      <hr class="my-2" />
  
                                              <div class="page-content container" >
                                                <div class="row">
                                                    <div class="col-6"></div>
                                                    <div class="text-95 col-6 align-self-start d-sm-flex justify-content-end">
                                                        <p class="page-title text-secondary-d1">
                                                            <strong>Ait Melloul le  :</strong>  -- / -- / --
                                                        </p>
                                                    </div>

                                                </div>
                                                  <div class="page-header mt-5 text-blue-d2 text-center">
                                                      <h2 class="page-title text-center text-secondary-d1">
                                                            Demande De Facture Proformat
                                                      </h2>
                                                      
                                                  </div>
                                                  
                                                  <div class="container px-0" >
                                                      <div class="row mt-4">
                                                          <div class="col-12 col-lg-12">
                                                            
                                                              <hr/>
                                                              <div class="mt-4">
                                                                  <div class="row border-b-2 brc-default-l2"></div>
  
                                                                  <!-- or use a table instead -->
                                                                  <!-- <strong>CHER FOURNISSEUR </strong> -->
                                                                  <br>
                                                                  <br class="ml-3">
                                                                  <p>
                                                                     Par la présente, Merci de faire la facture proforma des plaques d'un montant de : </p>
                                                                     <p> En chiffres : <strong>{{d.montant_chiffre}} </strong>Euros. </p>
                                                                     <p> En lettre   : <strong>{{d.montant_lettre}} </strong>Euros. </p>
  
                                                                     <p class="ml-3">
                                                                        NOUS VOUS DEMANDONS DE PREPARER LA FACTURE PRO FORMA QUI VA PORTER TOUT LES INFORMATIONS SUIVANTES A SAVOIR : <br>
                                                                    </p>
    
                                                                    <div class="row mt-3">
                                                                        
                                                                        <div class="col-12 ml-3 text-grey-d2 text-95 mt-2 mt-lg-0">
                                                                            DESIGNATION D'ARTICLE : ---
                                                                        </div>
                                                                        <div class="col-12 ml-3 text-grey-d2 text-95 mt-2 mt-lg-0">
                                                                            QUANTITE : ---
                                                                        </div>
                                                                        <div class="col-12 ml-3 text-grey-d2 text-95 mt-2 mt-lg-0">
                                                                            NOMENCLATURE : ---
                                                                        </div>
                                                                        <div class="col-12 ml-3 text-grey-d2 text-95 mt-2 mt-lg-0">
                                                                            POIDS BRUT ET NET EN KG : ---
                                                                        </div>
                                                                        <div class="col-12 ml-3 text-grey-d2 text-95 mt-2 mt-lg-0">
                                                                            VALEUR EN DEVIS : ---
                                                                        </div>
                                                                        <div class="col-12 ml-3 text-grey-d2 text-95 mt-2 mt-lg-0">
                                                                            PAYS D'ORIGINE, PAYS DE PROVENANCE : ---
                                                                        </div>
                                                                        <div class="col-12 ml-3 text-grey-d2 text-95 mt-2 mt-lg-0">
                                                                            INCOTERM : ---
                                                                        </div>
                                                                        <div class="col-12 ml-3 text-grey-d2 text-95 mt-2 mt-lg-0">
                                                                            TRANSPORT : ---
                                                                        </div>
                                                                        <div class="col-12 ml-3 text-grey-d2 text-95 mt-2 mt-lg-0">
                                                                            COORDONEES BANCAIRES ET MODALITE DE PAIEMENT : ---
                                                                        </div>
    
                                                                    </div>
                                                                    <p class="ml-3" v-if="d.type_de_demande == 3">
                                                                        DANS L'ATTENTE DE VOTRE RETOUR, VEUILLEZ ACCEPTER CHER FOURNISSEUR, NOUS SINCERES SALUTATIONS.
                                                                    </p>
  
                                                                  <hr/>
                                                                  <div class="text-center">
                                                                      <strong class="text-secondary-d1 text-105">SIGNATURES :</strong>
                                                                      <div class="row">
                                                                        <div class="col-6">
                                                                            <p class="text-secondary-d1 text-105">Hassan EL AISSARI</p>
                                                                        </div>
                                                                        <div class="col-6">
                                                                            <p class="text-secondary-d1 text-105">Abdelaali EL AISSARI</p>
                                                                        </div>
                                                                        </div>
                                                                  </div>
                                                                  <hr />
  
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                              <hr class="my-2" />
                                              <!-- footer -->
                                              <div class=" flex-row-reverse text-center p-4">
                                                  <div class="py-3 px-5">
                                                      <div>
                                                          Lot Yamna Ismail,Avenue Med. VI Route de Biougra - Ait Meloul -B.P.N° 1810 Z.I.Ait Melloul - Tél /Fax: 05 28 24 51 90
                                                      </div>
                                                      <div>
                                                          SARL au Capital de 300 000.00 DH - R.C: 3545 Inzegan -lf : 06 95 04 67 - Patente : 49866952- CNSS : 7341845
                                                      </div>
                                                      <div>
                                                          N° Compte B.P .Ait Melloul : 101022212117001481001534 - Email: aakabar@gmail.com
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                                  </div>
                          <div class="modal-footer">
                              <button
                                  class="btn btn-primary"
                                  style="float: right;"
                                  @click="printO()"
                              >
                                  <span class="fa fa-print"></span>
                              </button>
                          </div>
                      </div>
                      
                  </div>
          </div>
          <!-- Invoice Demandes Saisie Engagement-->
          <div
                          class="modal fade"
                          id="Invoice"
                          tabindex="-1"
                          role="dialog"
                          aria-labelledby="Invoice"
                          aria-hidden="true"
                      >
                          <div class="modal-dialog modal-xl" role="document">
                              <div class="modal-content">
                                  <div class="modal-header">
                                      <h5 class="modal-title text-right">Demande Saisie D'engagement</h5>
                                      <button
                                          type="button"
                                          class="close"
                                          data-dismiss="modal"
                                          aria-label="Close"
                                      >
                                          <span aria-hidden="true">&times;</span>
                                      </button>
                                  </div>
                                  <!-- invoice -->
                                  <div class="modal-body" id="printMeDSENG">
                                      <div class="row" v-for="d in DemandeEngagePFD" :key="d.id">
                                          <div class="col-12">
                                              <div class="card">
                                                  <div class="card-body p-0">
                                                      <div class="row">
                                                          <div class="col-12 text-center">
                                                              <img
                                                                  class="comapny_logo_invoice"
                                                                  src="/images/logo.png"
                                                                  alt="brand-logo-for-invoice"
                                                                  style="height:90px"
                                                              />
                                                          </div>
                                                      </div>
  
                                                      <hr class="my-2" />
  
                                              <div class="page-content container" >
                                                <div class="row">
                                                    <div class="col-6"></div>
                                                    <div class="text-95 col-6 align-self-start d-sm-flex justify-content-end">
                                                        <p class="page-title text-secondary-d1">
                                                            <strong>Ait Melloul le  :</strong>  -- / -- / --
                                                        </p>
                                                    </div>

                                                </div>
                                                  <div class="page-header mt-5 text-blue-d2 text-center">
                                                      <h2 class="page-title text-center text-secondary-d1">
                                                            A NOTRE CHER TRANSITAIRE
                                                      </h2>
                                                      
                                                  </div>
                                                  
                                                  <div class="container px-0" >
                                                      <div class="row mt-4">
                                                          <div class="col-12 col-lg-12">
                                                            <div class="page-header text-blue-d2 text-center">
                                                                <strong class="page-title  text-secondary-d1">
                                                                    OBJET : DEMANDE DE SAISIR L'ENGAGEMENT D'IMPORTATION POUR VERIFICATION .
                                                                </strong>
                                                                
                                                            </div>
                                                              <hr/>
                                                              <div class="mt-4">
                                                                  <div class="row border-b-2 brc-default-l2"></div>
  
                                                                  <!-- or use a table instead -->
                                                                  <strong>CHER FOURNISSEUR </strong>
                                                                  <br>
                                                                  <br class="ml-3">
                                                                  <p class="ml-4">NOUS AVONS L'HOUNNEUR DE VOUS ADRESSER CETTE DEMANDE POUR SAISIR L'ENGAGEMENT D'IMPORTATION
                                                                    QUI CONCERNE LA FACTURE PRO FORMA N°: --- A FIN DE COMMENCER UNE NOUVELLE IMPORTATION.
                                                                  </p>
                                                                     <p>A CET EFFET NOUS VOUS DEMANDONS DE PREPARER CET ENGAGEMENT D'IMPORTATION QUI VA PORTER TOUTES LES 
                                                                        INFORMATIONS SUIVANTES A SAVOIR :
                                                                     </p>
                                                                     <p class="ml-5"> * NOM D'EXPEDITEUR (FOURNISSUER) : --- </p>
                                                                     <p class="ml-5"> * BUREAU DOUANIER : --- </p>
                                                                     <p class="ml-5"> * REGIME DOUANIER : --- </p>
                                                                     <p class="ml-5"> * TYPE TRANSPORT : --- </p>
                                                                     <p class="ml-5"> * DESIGNATION D'ARTICLE : ---</p>
                                                                     <p class="ml-5"> * QUANTITE : ---</p>
                                                                     <p class="ml-5"> * NOMENCLATURE : ---</p>
                                                                     <p class="ml-5"> * POIDS NET, POIDS BRUT EN KG : ---</p>
                                                                     <p class="ml-5"> * VALEUR EN DEVIS : --- </p>
                                                                     <p class="ml-5"> * PAYS D'ORIGINE, PAYS DE PROVENANCE : --- </p>
                                                                     <p class="ml-5"> * INCOTERM : --- </p>
                                                                     <p class="ml-5"> * FRET : --- </p>
                                                                    <br>
  
                                                                    <strong>CHER TRANSITAIRE, </strong>
                                                                  <br>
                                                                  <br class="ml-3">
                                                                  <p>JE VOUS DEMANDE UNE FOIS VOUS AVEZ SAISIE CET NOUVEL ENGAGEMENT DE NOUS L'ENVOYER PAR E-MAIL SUIVANT : ...
                                                                  </p>
                                                                  <p>AFIN DE LE VERIFIER ET DE FAIRE DES CORRECTIONS ANTICPEES.<br>
                                                                  NB: IL INTERDIT DE VALIDER L'ENGAGEMENT AVANT DE RECEVOIR LA LETTRE DE VALIDATION DE CETTE ENGAGEMENT SAISI.</p>
                                                                  <p> DANS L'ATTENTE DE VOTRE RETOUR, VUILLEZ ACCEPTER, CHER FOURNISSEUR, NOS SINCERES SALUTATIONS.</p>
  
                                                                  <hr/>
                                                                  <div class="text-center">
                                                                      <strong class="text-secondary-d1 text-105">SIGNATURES :</strong>
                                                                      <div class="row">
                                                                        <div class="col-6">
                                                                            <p class="text-secondary-d1 text-105">Hassan EL AISSARI</p>
                                                                        </div>
                                                                        <div class="col-6">
                                                                            <p class="text-secondary-d1 text-105">Abdelaali EL AISSARI</p>
                                                                        </div>
                                                                        </div>
                                                                  </div>
                                                                  <hr />
  
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                              <hr class="my-2" />
                                              <!-- footer -->
                                              <div class=" flex-row-reverse text-center p-4">
                                                  <div class="py-3 px-5">
                                                      <div>
                                                          Lot Yamna Ismail,Avenue Med. VI Route de Biougra - Ait Meloul -B.P.N° 1810 Z.I.Ait Melloul - Tél /Fax: 05 28 24 51 90
                                                      </div>
                                                      <div>
                                                          SARL au Capital de 300 000.00 DH - R.C: 3545 Inzegan -lf : 06 95 04 67 - Patente : 49866952- CNSS : 7341845
                                                      </div>
                                                      <div>
                                                          N° Compte B.P .Ait Melloul : 101022212117001481001534 - Email: aakabar@gmail.com
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                                  </div>
                          <div class="modal-footer">
                              <button
                                  class="btn btn-primary"
                                  style="float: right;"
                                  @click="printO()"
                              >
                                  <span class="fa fa-print"></span>
                              </button>
                          </div>
                      </div>
                      
                  </div>
          </div>
          <!-- Invoice Demandes Saisie Engagement-->
          <div
                          class="modal fade"
                          id="Invoice"
                          tabindex="-1"
                          role="dialog"
                          aria-labelledby="Invoice"
                          aria-hidden="true"
                      >
                          <div class="modal-dialog modal-xl" role="document">
                              <div class="modal-content">
                                  <div class="modal-header">
                                      <h5 class="modal-title text-right">Demande De Correction D'engagement</h5>
                                      <button
                                          type="button"
                                          class="close"
                                          data-dismiss="modal"
                                          aria-label="Close"
                                      >
                                          <span aria-hidden="true">&times;</span>
                                      </button>
                                  </div>
                                  <!-- invoice -->
                                  <div class="modal-body" id="printMeDCENG">
                                      <div class="row" v-for="d in DemandeCEngagePFD" :key="d.id">
                                          <div class="col-12">
                                              <div class="card">
                                                  <div class="card-body p-0">
                                                      <div class="row">
                                                          <div class="col-12 text-center">
                                                              <img
                                                                  class="comapny_logo_invoice"
                                                                  src="/images/logo.png"
                                                                  alt="brand-logo-for-invoice"
                                                                  style="height:90px"
                                                              />
                                                          </div>
                                                      </div>
  
                                                      <hr class="my-2" />
  
                                              <div class="page-content container" >
                                                <div class="row">
                                                    <div class="col-6"></div>
                                                    <div class="text-95 col-6 align-self-start d-sm-flex justify-content-end">
                                                        <p class="page-title text-secondary-d1">
                                                            <strong>Ait Melloul le  :</strong>  -- / -- / --
                                                        </p>
                                                    </div>

                                                </div>
                                                  <div class="page-header mt-5 text-blue-d2 text-center">
                                                      <h2 class="page-title text-center text-secondary-d1">
                                                            A NOTRE CHER TRANSITAIRE
                                                      </h2>
                                                      
                                                  </div>
                                                  
                                                  <div class="container px-0" >
                                                      <div class="row mt-4">
                                                          <div class="col-12 col-lg-12">
                                                            <div class="page-header mt-5 text-blue-d2 text-center">
                                                                <strong class="page-title  text-secondary-d1">
                                                                    OBJET : DEMANDE CORRECTION DES ERREUR SUR L'ENGAGEMENT D'IMPORTATION SAISI POUR VERIFICATION.
                                                                </strong>
                                                                
                                                            </div>
                                                              <hr/>
                                                              <div class="mt-4">
                                                                  <div class="row border-b-2 brc-default-l2"></div>
  
                                                                  <!-- or use a table instead -->
                                                                  <strong>CHER FOURNISSEUR </strong>
                                                                  <br>
                                                                  <br class="ml-3">
                                                                  <p class="ml-4">NOUS AVONS L'HOUNNEUR DE VOUS ADRESSER CETTE DEMANDE POUR CORRIGER LES ERREURS SUR L'ENGAGEMENT
                                                                    D'IMPORTATION QUI CONCERNE LA FACTURE PRO FORMA N° DATE AFIN DE COMMENCER UNE NOUVELLE 
                                                                    IMPORTATION.
                                                                  </p>
                                                                  <div class="row mb-5">
                                                                    <div class="col-2"></div>
                                                                    <div class="col-8"><strong class="text-center ">LES INFORMATIONS A CORRIGER SUR L'ENREGISTREMENT SAISI</strong></div>
                                                                    <div class="col-2"></div>
                                                                  </div>
                                                                     
                                                                    <table class="table table-bordered table-sm">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th scope="col">Liste Des Informations</th>
                                                                                    <th scope="col">Donnees Sur Facture Pro Forma</th>
                                                                                    <th scope="col">Donnes Sur Engagement SAISI</th>
                                                                                    <th scope="col">Etat Des Donnes Saisie</th>
                                                                                    
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <th scope="row">Nom D'Expediteur</th>
                                                                                    <td>{{d.nom_F_Pro}}</td>
                                                                                    <td>{{d.nom_F_Enga}}</td>
                                                                                    <td>{{d.nom_F_Etat}}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <th scope="row">Bureau Douanier</th>
                                                                                    <td>{{d.bureau_D_Pro}}</td>
                                                                                    <td>{{d.bureau_D_Enga}}</td>
                                                                                    <td>{{d.bureau_D_Etat}}</td>
                                                                                    
                                                                                </tr>
                                                                                <tr>
                                                                                    <th scope="row">Regime Dounanier</th>
                                                                                    <td>{{d.regime_D_Pro}}</td>
                                                                                    <td>{{d.regime_D_Enga}}</td>
                                                                                    <td>{{d.regime_D_Etat}}</td>
                                                                                    
                                                                                </tr>
                                                                                <tr>
                                                                                    <th scope="row">Type Transport</th>
                                                                                    <td>{{d.transport_Pro}}</td>
                                                                                    <td>{{d.transport_Enga}}</td>
                                                                                    <td>{{d.transport_Etat}}</td>
                                                                                    
                                                                                </tr>
                                                                                <tr>
                                                                                    <th scope="row">Designation</th>
                                                                                    <td>{{d.designation_Pro}}</td>
                                                                                    <td>{{d.designation_Enga}}</td>
                                                                                    <td>{{d.designation_Etat}}</td>
                                                                                    
                                                                                </tr>
                                                                                <tr>
                                                                                    <th scope="row">Quantite</th>
                                                                                    <td>{{d.qtit_Pro}}</td>
                                                                                    <td>{{d.qtit_Enga}}</td>
                                                                                    <td>{{d.qtit_Etat}}</td>
                                                                                    
                                                                                </tr>
                                                                                <tr>
                                                                                    <th scope="row">Nomenclature</th>
                                                                                    <td>{{d.nomenclature_Pro}}</td>
                                                                                    <td>{{d.nomenclature_Enga}}</td>
                                                                                    <td>{{d.nomenclature_Etat}}</td>
                                                                                    
                                                                                </tr>
                                                                                <tr>
                                                                                    <th scope="row">Poids Net (KG)</th>
                                                                                    <td>{{d.P_NET_Pro}}</td>
                                                                                    <td>{{d.P_NET_Enga}}</td>
                                                                                    <td>{{d.P_NET_Etat}}</td>
                                                                                    
                                                                                </tr>
                                                                                <tr>
                                                                                    <th scope="row">Poids Brut (KG)</th>
                                                                                    <td>{{d.P_BRUT_Pro}}</td>
                                                                                    <td>{{d.P_BRUT_Enga}}</td>
                                                                                    <td>{{d.P_BRUT_Etat}}</td>
                                                                                    
                                                                                </tr>
                                                                                <tr>
                                                                                    <th scope="row">Valeur En Devis</th>
                                                                                    <td>{{d.devis_Pro}}</td>
                                                                                    <td>{{d.devis_Enga}}</td>
                                                                                    <td>{{d.devis_Etat}}</td>
                                                                                    
                                                                                </tr>
                                                                                <tr>
                                                                                    <th scope="row">Pays D'origine <br> Pays De Provenance</th>
                                                                                    <td>{{d.pays_Pro}}</td>
                                                                                    <td>{{d.pays_Enga}}</td>
                                                                                    <td>{{d.pays_Etat}}</td>
                                                                                    
                                                                                </tr>
                                                                                <tr>
                                                                                    <th scope="row">Incoterm</th>
                                                                                    <td>{{d.incoterm_Pro}}</td>
                                                                                    <td>{{d.incoterm_Enga}}</td>
                                                                                    <td>{{d.incoterm_Etat}}</td>
                                                                                    
                                                                                </tr>
                                                                                <tr>
                                                                                    <th scope="row">Fret</th>
                                                                                    <td>{{d.fret_Pro}}</td>
                                                                                    <td>{{d.fret_Enga}}</td>
                                                                                    <td>{{d.fret_Etat}}</td>
                                                                                    
                                                                                </tr>
                                                                            </tbody>
                                                                    </table>
                                                                    <br>
  
                                                                  <hr/>
                                                                  <div class="text-center">
                                                                      <strong class="text-secondary-d1 text-105">SIGNATURES :</strong>
                                                                      <div class="row">
                                                                        <div class="col-6">
                                                                            <p class="text-secondary-d1 text-105">Hassan EL AISSARI</p>
                                                                        </div>
                                                                        <div class="col-6">
                                                                            <p class="text-secondary-d1 text-105">Abdelaali EL AISSARI</p>
                                                                        </div>
                                                                        </div>
                                                                  </div>
                                                                  <hr />
  
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                              <hr class="my-2" />
                                              <!-- footer -->
                                              <div class=" flex-row-reverse text-center p-4">
                                                  <div class="py-3 px-5">
                                                      <div>
                                                          Lot Yamna Ismail,Avenue Med. VI Route de Biougra - Ait Meloul -B.P.N° 1810 Z.I.Ait Melloul - Tél /Fax: 05 28 24 51 90
                                                      </div>
                                                      <div>
                                                          SARL au Capital de 300 000.00 DH - R.C: 3545 Inzegan -lf : 06 95 04 67 - Patente : 49866952- CNSS : 7341845
                                                      </div>
                                                      <div>
                                                          N° Compte B.P .Ait Melloul : 101022212117001481001534 - Email: aakabar@gmail.com
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                                  </div>
                          <div class="modal-footer">
                              <button
                                  class="btn btn-primary"
                                  style="float: right;"
                                  @click="printO()"
                              >
                                  <span class="fa fa-print"></span>
                              </button>
                          </div>
                      </div>
                      
                  </div>
          </div>
          <!-- PDF Demande Valider L'engagement -->
          <div
                          class="modal fade"
                          id="Invoice"
                          tabindex="-1"
                          role="dialog"
                          aria-labelledby="Invoice"
                          aria-hidden="true"
                      >
                          <div class="modal-dialog modal-xl" role="document">
                              <div class="modal-content">
                                  <div class="modal-header">
                                      <h5 class="modal-title text-right">Demande De  VALIDER ET DOMICILIER L'ENGAGEMENT D'IMPORTATION.</h5>
                                      <button
                                          type="button"
                                          class="close"
                                          data-dismiss="modal"
                                          aria-label="Close"
                                      >
                                          <span aria-hidden="true">&times;</span>
                                      </button>
                                  </div>
                                  <!-- invoice -->
                                  <div class="modal-body" id="printMeDVENG">
                                      <div class="row" v-for="d in DemandeVEngagePFD" :key="d.id">
                                          <div class="col-12">
                                              <div class="card">
                                                  <div class="card-body p-0">
                                                      <div class="row">
                                                          <div class="col-12 text-center">
                                                              <img
                                                                  class="comapny_logo_invoice"
                                                                  src="/images/logo.png"
                                                                  alt="brand-logo-for-invoice"
                                                                  style="height:90px"
                                                              />
                                                          </div>
                                                      </div>
  
                                                      <hr class="my-2" />
  
                                              <div class="page-content container" >
                                                <div class="row">
                                                    <div class="col-6"></div>
                                                    <div class="text-95 col-6 align-self-start d-sm-flex justify-content-end">
                                                        <p class="page-title text-secondary-d1">
                                                            <strong>Ait Melloul le  :</strong>  -- / -- / --
                                                        </p>
                                                    </div>

                                                </div>
                                                  <div class="page-header mt-5 text-blue-d2 text-center">
                                                      <h2 class="page-title text-center text-secondary-d1">
                                                            A NOTRE CHER TRANSITAIRE
                                                      </h2>
                                                      
                                                  </div>
                                                  
                                                  <div class="container px-0" >
                                                      <div class="row mt-4">
                                                          <div class="col-12 col-lg-12">
                                                            <div class="page-header mt-5 text-blue-d2 text-center">
                                                                <strong class="page-title  text-secondary-d1">
                                                                    OBJET : DEMANDE DE VALIDER ET DOMICILIER L'ENGAGEMENT D'IMPORTATION.
                                                                </strong>
                                                                
                                                            </div>
                                                              <hr/>
                                                              <div class="mt-4">
                                                                  <div class="row border-b-2 brc-default-l2"></div>
  
                                                                  <!-- or use a table instead -->
                                                                  <strong>CHER FOURNISSEUR </strong>
                                                                  <br>
                                                                  <br class="ml-3">
                                                                  <p class="ml-4">NOUS AVONS L'HOUNNEUR DE VOUS ADRESSER CETTE DEMANDE A FIN DE VALIDER ET DOMICILIER L'ENGAGEMENT
                                                                    D'IMPORTATION QUI CONCERNE LA FACTURE PRO FORMA N° --- A FIN DE COMMENCER UNE NOUVELLE IMPORTATION.
                                                                  </p>
                                                                  <p class="ml-5"> <strong>CHER TRANSITAIRE</strong></p>
                                                                  <p>MERCI DE NOUS ENVOYER SOUS PDF CET ENGAGEMENT DOMICILIER DANS LES PLUS BREFS DELAIS.</p>
                                                                    
                                                                    <br>
                                                                    <p>DANS L'ATTENTE DE VOTRE RETOUR, VEUILLEZ ACCEPTER, CHER FOURNISSEUR, NOUS SINCERES SALUTATIONS.</p>
  
                                                                  <hr/>
                                                                  <div class="text-center">
                                                                      <strong class="text-secondary-d1 text-105">SIGNATURES :</strong>
                                                                      <div class="row">
                                                                        <div class="col-6">
                                                                            <p class="text-secondary-d1 text-105">Hassan EL AISSARI</p>
                                                                        </div>
                                                                        <div class="col-6">
                                                                            <p class="text-secondary-d1 text-105">Abdelaali EL AISSARI</p>
                                                                        </div>
                                                                        </div>
                                                                  </div>
                                                                  <hr />
  
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                              <hr class="my-2" />
                                              <!-- footer -->
                                              <div class=" flex-row-reverse text-center p-4">
                                                  <div class="py-3 px-5">
                                                      <div>
                                                          Lot Yamna Ismail,Avenue Med. VI Route de Biougra - Ait Meloul -B.P.N° 1810 Z.I.Ait Melloul - Tél /Fax: 05 28 24 51 90
                                                      </div>
                                                      <div>
                                                          SARL au Capital de 300 000.00 DH - R.C: 3545 Inzegan -lf : 06 95 04 67 - Patente : 49866952- CNSS : 7341845
                                                      </div>
                                                      <div>
                                                          N° Compte B.P .Ait Melloul : 101022212117001481001534 - Email: aakabar@gmail.com
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                                  </div>
                          <div class="modal-footer">
                              <button
                                  class="btn btn-primary"
                                  style="float: right;"
                                  @click="printO()"
                              >
                                  <span class="fa fa-print"></span>
                              </button>
                          </div>
                      </div>
                      
                  </div>
          </div>
        <!-- End Demande Ordre virement -->
        <!-- Modal files Bon Livraison -->
            <el-dialog
                  title="Ajouter Bon De Livraison De Plaque"
                  :visible.sync="DialogVisibleBonLivraison"
                  center>
  
                  <div style="max-width: 500px; margin: 0 auto;">
                              
                              <form
                                  @submit="submitBonLivraison"
                                  class="mt-4"
                                  enctype="multipart/form-data"
                              >
                                  <div class="input-group">
                                      <div class="custom-file">
                                          <input
                                              type="file"
                                              name="filename"
                                              class="custom-file-input"
                                              id="inputFileUpload"
                                              v-on:change="onFileChange"
                                          />
                                          <label
                                              class="custom-file-label"
                                              for="inputFileUpload"
                                              >Séléctionner Bon De Livraison</label
                                          >
                                      </div>
                                      
                                  </div>
                                  <br />
                                  <p class="text-success font-weight-bold">
                                      {{ filename }}
                                  </p>
                              
                          <span slot="footer" class="dialog-footer">
                              <el-button type="warning" @click="DialogVisibleBonLivraison = false">Annuler</el-button>
                              <input  type="submit" class="btn btn-success" value="Enregistrer"  />
                          </span>
                      </form>
                  </div>
            </el-dialog>
        <!-- end dialog file Bon Livraison -->
        <!-- Modal files Facture Pro forma -->
            <el-dialog
                  title="Joindre La Facture Pro Forma"
                  :visible.sync="DialogVisibleFPF"
                  center>
  
                  <div style="max-width: 500px; margin: 0 auto;">
                              
                              <form
                                  @submit="submitFPF"
                                  class="mt-4"
                                  enctype="multipart/form-data"
                              >
                                  <div class="input-group">
                                      <div class="custom-file">
                                          <input
                                              type="file"
                                              name="filename"
                                              class="custom-file-input"
                                              id="inputFileUpload"
                                              v-on:change="onFileChange"
                                          />
                                          <label
                                              class="custom-file-label"
                                              for="inputFileUpload"
                                              >Séléctionner Bon De Livraison</label
                                          >
                                      </div>
                                      
                                  </div>
                                  <br />
                                  <p class="text-success font-weight-bold">
                                      {{ filename }}
                                  </p>
                              
                          <span slot="footer" class="dialog-footer">
                              <el-button type="warning" @click="DialogVisibleFPF= false">Annuler</el-button>
                              <input  type="submit" class="btn btn-success" value="Enregistrer"  />
                          </span>
                      </form>
                  </div>
            </el-dialog>
        <!-- end dialog file Bon Livraison -->
        <!-- Modal files SWIFT -->
            <el-dialog
                  title="Joindre L'acussé de reception"
                  :visible.sync="DialogVisibleSWIFT"
                  center>
  
                  <div style="max-width: 500px; margin: 0 auto;">
                              
                              <form
                                  @submit="submitSWIFT"
                                  class="mt-4"
                                  enctype="multipart/form-data"
                              >
                                  <div class="input-group">
                                      <div class="custom-file">
                                          <input
                                              type="file"
                                              name="filename"
                                              class="custom-file-input"
                                              id="inputFileUpload"
                                              v-on:change="onFileChange"
                                          />
                                          <label
                                              class="custom-file-label"
                                              for="inputFileUpload"
                                              >Séléctionner SWIFT a joindre</label
                                          >
                                      </div>
                                      
                                  </div>
                                  <br />
                                  <p class="text-success font-weight-bold">
                                      {{ filename }}
                                  </p>
                              
                          <span slot="footer" class="dialog-footer">
                              <el-button type="warning" @click="DialogVisibleSWIFT= false">Annuler</el-button>
                              <input  type="submit" class="btn btn-success" value="Enregistrer"  />
                          </span>
                      </form>
                  </div>
            </el-dialog>
        <!-- end dialog -->
        <!-- Modal files Certificat Origine -->
            <el-dialog
                  title="Joindre L'acussé de reception"
                  :visible.sync="DialogVisibleCertificatOrigine"
                  center>
  
                  <div style="max-width: 500px; margin: 0 auto;">
                              
                              <form
                                  @submit="submitCertificatOrigine"
                                  class="mt-4"
                                  enctype="multipart/form-data"
                              >
                                  <div class="input-group">
                                      <div class="custom-file">
                                          <input
                                              type="file"
                                              name="filename"
                                              class="custom-file-input"
                                              id="inputFileUpload"
                                              v-on:change="onFileChange"
                                          />
                                          <label
                                              class="custom-file-label"
                                              for="inputFileUpload"
                                              >Séléctionner Certificat Origine a joindre</label
                                          >
                                      </div>
                                      
                                  </div>
                                  <br />
                                  <p class="text-success font-weight-bold">
                                      {{ filename }}
                                  </p>
                              
                          <span slot="footer" class="dialog-footer">
                              <el-button type="warning" @click="DialogVisibleCertificatOrigine= false">Annuler</el-button>
                              <input  type="submit" class="btn btn-success" value="Enregistrer"  />
                          </span>
                      </form>
                  </div>
            </el-dialog>
        <!-- end dialog -->
        <!-- Modal files Certificat Origine -->
            <el-dialog
                  title="Joindre Reçu de Reglement"
                  :visible.sync="DialogVisibleRecuReglement"
                  center>
  
                  <div style="max-width: 500px; margin: 0 auto;">
                              
                              <form
                                  @submit="submitRecuReglement"
                                  class="mt-4"
                                  enctype="multipart/form-data"
                              >
                                  <div class="input-group">
                                      <div class="custom-file">
                                          <input
                                              type="file"
                                              name="filename"
                                              class="custom-file-input"
                                              id="inputFileUpload"
                                              v-on:change="onFileChange"
                                          />
                                          <label
                                              class="custom-file-label"
                                              for="inputFileUpload"
                                              >Séléctionner Reçu de reglement a joindre</label
                                          >
                                      </div>
                                      
                                  </div>
                                  <br />
                                  <p class="text-success font-weight-bold">
                                      {{ filename }}
                                  </p>
                              
                          <span slot="footer" class="dialog-footer">
                              <el-button type="warning" @click="DialogVisibleRecuReglement= false">Annuler</el-button>
                              <input  type="submit" class="btn btn-success" value="Enregistrer"  />
                          </span>
                      </form>
                  </div>
            </el-dialog>
              <!-- end dialog -->
        <!-- Modal files Certificat Origine -->
            <el-dialog
                  title="Joindre Reçu de Reglement"
                  :visible.sync="DialogVisibleMainL"
                  center>
  
                  <div style="max-width: 500px; margin: 0 auto;">
                              
                              <form
                                  @submit="submitMainL"
                                  class="mt-4"
                                  enctype="multipart/form-data"
                              >
                                  <div class="input-group">
                                      <div class="custom-file">
                                          <input
                                              type="file"
                                              name="filename"
                                              class="custom-file-input"
                                              id="inputFileUpload"
                                              v-on:change="onFileChange"
                                          />
                                          <label
                                              class="custom-file-label"
                                              for="inputFileUpload"
                                              >Séléctionner La Main levée a joindre</label
                                          >
                                      </div>
                                      
                                  </div>
                                  <br />
                                  <p class="text-success font-weight-bold">
                                      {{ filename }}
                                  </p>
                              
                          <span slot="footer" class="dialog-footer">
                              <el-button type="warning" @click="DialogVisibleMainL= false">Annuler</el-button>
                              <input  type="submit" class="btn btn-success" value="Enregistrer"  />
                          </span>
                      </form>
                  </div>
            </el-dialog>
        <!-- end dialog -->
        <!-- Modal files Accusé de reception -->
            <el-dialog
                  title="Joindre L'accusé de reception"
                  :visible.sync="DialogVisibleADC"
                  center>
  
                  <div style="max-width: 500px; margin: 0 auto;">
                              
                              <form
                                  @submit="submitADC"
                                  class="mt-4"
                                  enctype="multipart/form-data"
                              >
                                  <div class="input-group">
                                      <div class="custom-file">
                                          <input
                                              type="file"
                                              name="filename"
                                              class="custom-file-input"
                                              id="inputFileUpload"
                                              v-on:change="onFileChange"
                                          />
                                          <label
                                              class="custom-file-label"
                                              for="inputFileUpload"
                                              >Séléctionner L'accusé de reception a joindre</label
                                          >
                                      </div>
                                      
                                  </div>
                                  <br />
                                  <p class="text-success font-weight-bold">
                                      {{ filename }}
                                  </p>
                              
                          <span slot="footer" class="dialog-footer">
                              <el-button type="warning" @click="DialogVisibleADC= false">Annuler</el-button>
                              <input  type="submit" class="btn btn-success" value="Enregistrer"  />
                          </span>
                      </form>
                  </div>
            </el-dialog>
        <!-- end dialog -->
          <!-- Modal Fiche de declaration on transitaire => FDT-->
            <el-dialog
                  title="Joindre La Fiche De Declaration On Transitaire"
                  :visible.sync="DialogVisibleFDT"
                  center>
  
                  <div style="max-width: 500px; margin: 0 auto;">
                              
                              <form
                                  @submit="submitFDT"
                                  class="mt-4"
                                  enctype="multipart/form-data"
                              >
                                  <div class="input-group">
                                      <div class="custom-file">
                                          <input
                                              type="file"
                                              name="filename"
                                              class="custom-file-input"
                                              id="inputFileUpload"
                                              v-on:change="onFileChange"
                                          />
                                          <label
                                              class="custom-file-label"
                                              for="inputFileUpload"
                                              >Séléctionner La Fiche De Declaration On Transitaire a joindre</label
                                          >
                                      </div>
                                      
                                  </div>
                                  <br />
                                  <p class="text-success font-weight-bold">
                                      {{ filename }}
                                  </p>
                              
                          <span slot="footer" class="dialog-footer">
                              <el-button type="warning" @click="DialogVisibleFDT= false">Annuler</el-button>
                              <input  type="submit" class="btn btn-success" value="Enregistrer"  />
                          </span>
                      </form>
                  </div>
            </el-dialog>
        <!-- end dialog -->
        <!-- Modal Fiche de declaration de cautionnement => FDC-->
            <el-dialog
                  title="Joindre La Fiche De Declaration De Cautionnement"
                  :visible.sync="DialogVisibleFDC"
                  center>
  
                  <div style="max-width: 500px; margin: 0 auto;">
                              
                              <form
                                  @submit="submitFDC"
                                  class="mt-4"
                                  enctype="multipart/form-data"
                              >
                                  <div class="input-group">
                                      <div class="custom-file">
                                          <input
                                              type="file"
                                              name="filename"
                                              class="custom-file-input"
                                              id="inputFileUpload"
                                              v-on:change="onFileChange"
                                          />
                                          <label
                                              class="custom-file-label"
                                              for="inputFileUpload"
                                              >Séléctionner La Fiche De Declaration De Cautionnement a joindre</label
                                          >
                                      </div>
                                      
                                  </div>
                                  <br />
                                  <p class="text-success font-weight-bold">
                                      {{ filename }}
                                  </p>
                              
                          <span slot="footer" class="dialog-footer">
                              <el-button type="warning" @click="DialogVisibleFDC= false">Annuler</el-button>
                              <input  type="submit" class="btn btn-success" value="Enregistrer"  />
                          </span>
                      </form>
                  </div>
            </el-dialog>
        <!-- end dialog -->
          <!-- Modal files Facture Pro forma -->
            <el-dialog
                  title="Joindre L'acussé de reception"
                  :visible.sync="DialogVisibleAccuseR"
                  center>
  
                  <div style="max-width: 500px; margin: 0 auto;">
                              
                              <form
                                  @submit="submitAcusseR"
                                  class="mt-4"
                                  enctype="multipart/form-data"
                              >
                                  <div class="input-group">
                                      <div class="custom-file">
                                          <input
                                              type="file"
                                              name="filename"
                                              class="custom-file-input"
                                              id="inputFileUpload"
                                              v-on:change="onFileChange"
                                          />
                                          <label
                                              class="custom-file-label"
                                              for="inputFileUpload"
                                              >Séléctionner L'accusé a joindre</label
                                          >
                                      </div>
                                      
                                  </div>
                                  <br />
                                  <p class="text-success font-weight-bold">
                                      {{ filename }}
                                  </p>
                              
                          <span slot="footer" class="dialog-footer">
                              <el-button type="warning" @click="DialogVisibleAccuseR= false">Annuler</el-button>
                              <input  type="submit" class="btn btn-success" value="Enregistrer"  />
                          </span>
                      </form>
                  </div>
            </el-dialog>
        <!-- end dialog file Bon Livraison -->
              <!-- Modal files Paiement des droit importation ( Etiquette de paiement )-->
            <el-dialog
                  title="Joindre L'acussé de reception"
                  :visible.sync="DialogVisibleEDP"
                  center>
  
                  <div style="max-width: 500px; margin: 0 auto;">
                              
                              <form
                                  @submit="submitEDP"
                                  class="mt-4"
                                  enctype="multipart/form-data"
                              >
                                  <div class="input-group">
                                      <div class="custom-file">
                                          <input
                                              type="file"
                                              name="filename"
                                              class="custom-file-input"
                                              id="inputFileUpload"
                                              v-on:change="onFileChange"
                                          />
                                          <label
                                              class="custom-file-label"
                                              for="inputFileUpload"
                                              >Séléctionner l'etiquette de paiement a joindre</label
                                          >
                                      </div>
                                      
                                  </div>
                                  <br />
                                  <p class="text-success font-weight-bold">
                                      {{ filename }}
                                  </p>
                              
                          <span slot="footer" class="dialog-footer">
                              <el-button type="warning" @click="DialogVisibleEDP= false">Annuler</el-button>
                              <input  type="submit" class="btn btn-success" value="Enregistrer"  />
                          </span>
                      </form>
                  </div>
            </el-dialog>
              <!-- end dialog -->
              <!-- Modal files Fiche Liquidation-->
            <el-dialog
                  title="Joindre La fiche De Liquidation Douanier"
                  :visible.sync="DialogVisibleFL"
                  center>
  
                  <div style="max-width: 500px; margin: 0 auto;">
                              
                              <form
                                  @submit="submitFL"
                                  class="mt-4"
                                  enctype="multipart/form-data"
                              >
                                  <div class="input-group">
                                      <div class="custom-file">
                                          <input
                                              type="file"
                                              name="filename"
                                              class="custom-file-input"
                                              id="inputFileUpload"
                                              v-on:change="onFileChange"
                                          />
                                          <label
                                              class="custom-file-label"
                                              for="inputFileUpload"
                                              >Séléctionner La fiche de liquidation a joindre</label
                                          >
                                      </div>
                                      
                                  </div>
                                  <br />
                                  <p class="text-success font-weight-bold">
                                      {{ filename }}
                                  </p>
                              
                          <span slot="footer" class="dialog-footer">
                              <el-button type="warning" @click="DialogVisibleFL= false">Annuler</el-button>
                              <input  type="submit" class="btn btn-success" value="Enregistrer"  />
                          </span>
                      </form>
                  </div>
            </el-dialog>
              <!-- end dialog -->
          <!-- Invoice Fiche Technique -->
          <div
              class="modal fade"
              id="FicheTechnique"
              tabindex="-1"
              role="dialog"
              aria-labelledby="Fiche Technique"
              aria-hidden="true"
              >
                  <div class="modal-dialog modal-xl" role="document">
                      <div class="modal-content">
                          <div class="modal-header">
                              <h5 class="modal-title text-right">Fiche Technique</h5>
                              <button
                                  type="button"
                                  class="close"
                                  data-dismiss="modal"
                                  aria-label="Close"
                              >
                                  <span aria-hidden="true">&times;</span>
                              </button>
                          </div>
                          <!-- invoice -->
                          <div class="modal-body" id="printMe" v-for="c in Projet" :key="c.id">
                              <div class="row">
                                  <div class="col-12">
                                      <div class="card">
                                          <div class="card-body p-0">
                                              <div class="row p-5">
                                                  <div class="col-md-4 text-left">
                                                      <img
                                                          class="comapny_logo_invoice"
                                                          :src="`/upload/Profile/Conception_${idProjet}.png`"
                                                          alt="Image D'itéquette"
                                                          height="200px"
                                                          width="200px"
                                                      />
                                                  </div>
  
                                                  <div class="col-md-4 text-center">
                                                      <h2
                                                          style="margin: 0px"
                                                          class="mb-3" >
                                                          FICHE TECHNIQUE
                                                      </h2>
                                                  </div>
  
                                                  <div
                                                      class="col-md-4 text-right"
                                                  >
                                                  <fieldset style="text-align:-webkit-center;">
                                                      <VueQRCodeComponent :size="200" :text="QRcode"></VueQRCodeComponent>
                                                  </fieldset>
                                                      
                                                  </div>
                                              </div>
  
                                              <hr class="my-2" />
  
                                              <hr class="my-2" />
  
                                              <div class="row flex-row-reverse  p-4 mb-5">
  
                                              <div class="col-12 py-3 px-5">
                                                  <div class="row">
                                                      <div class="col-4">
                                                          <strong>Client :</strong> {{c.get_client.name}}
                                                      </div>
                                                      <div class="col-4"> <strong>Nom D'étiquette :</strong> {{c.nom_etc}} </div>
                                                      <div class="col-4"> <strong>Format en mm :</strong> {{c.formatMM_L}} x {{c.formatMM_D}} </div>
                                                      <!--  -->
                                                      <div class="col-4"> <strong>Form d'étiquette :</strong> {{c.forme_etq}} </div>
                                                      <div class="col-4"> <strong>Support :</strong> {{c.support_papier}} </div>
                                                      <div class="col-4"> <strong>Impression :</strong> {{c.impression}} </div>
                                                      <!--  -->
                                                      <div class="col-4"> <strong>Client :</strong> {{c.get_client.name}} </div>
                                                      <div class="col-4"> <strong>Nombre Couleurs :</strong> {{c.nbr_couleur}} </div>
                                                      <div class="col-4"> <strong>Quantité :</strong> {{c.quantite_etq}} </div>
                                                      <!--  -->
                                                      <div class="col-4"> <strong>Recto :</strong> {{c.recto}} </div>
                                                      <div class="col-4"> <strong>Verso :</strong> {{c.verso}} </div>
                                                      <div class="col-4"> <strong>Repiquage :</strong> {{c.repiquage}} </div>
                                                      <!--  -->
                                                      <div class="col-4"> <strong>Vernis :</strong> {{c.Vernis}} </div>
                                                      <div class="col-4"> <strong>Plastification :</strong> {{c.plastification}} </div>
                                                      <div class="col-4"> <strong>Dorure :</strong> {{c.dorure}} </div>
                                                      <!--  -->
                                                      <div class="col-4"> <strong>Mandrin :</strong> {{c.mandrin}} </div>
                                                      <div class="col-4"> <strong>Nombre Etq/Front :</strong> {{c.nbr_etq_front}} </div>
                                                      <div class="col-4"> <strong>Nombre Etq/Bobine :</strong> {{c.nbr_etq_bobine}} </div>
                                                      <!--  -->
                                                      <div class="col-4"> <strong>Poses Etiquette :</strong> {{c.poses_etq}} </div>
                                                      <div class="col-4"> <strong>Form De Decoupe :</strong> {{c.form_decoupe}} </div>
                                                      <div class="col-4"> <strong>Films et Cliches :</strong> {{c.films_cliches}} </div>
                                                  </div>
                                              </div>
                                              </div>
                                              <hr class="my-2" />
  
                                              <hr class="my-2" />
  
                                              <div class="row flex-row-reverse  p-4 mb-5">
                                                      <div class="col-4 " v-if="c.sens_sortie == 'interne'">
                                                          <div class="row">
                                                              <div class="col-6">
                                                                  <strong> Sens De Sortie : </strong>{{c.sens_sortie}}
                                                              </div>
                                                              <div class="col-3" v-if="c.type_sense_sortie == 'i_up'">
                                                                  <img
                                                                      class="comapny_logo_invoice"
                                                                      src="/images/form2T.png"
                                                                      alt="Image D'itéquette"
                                                                      height="100px"
                                                                      width="100px"
                                                                  />
                                                              </div>
                                                              <div class="col-3" v-if="c.type_sense_sortie == 'i_down'">
                                                                  <img
                                                                      class="comapny_logo_invoice"
                                                                      src="/images/form2B.png"
                                                                      alt="Image D'itéquette"
                                                                      height="100px"
                                                                      width="100px"
                                                                  />
                                                              </div>
                                                              <div class="col-3" v-if="c.type_sense_sortie == 'i_right'">
                                                                  <img
                                                                      class="comapny_logo_invoice"
                                                                      src="/images/form2R.png"
                                                                      alt="Image D'itéquette"
                                                                      height="100px"
                                                                      width="100px"
                                                                  />
                                                              </div>
                                                              <div class="col-3" v-if="c.type_sense_sortie == 'i_left'">
                                                                  <img
                                                                      class="comapny_logo_invoice"
                                                                      src="/images/form2L.png"
                                                                      alt="Image D'itéquette"
                                                                      height="100px"
                                                                      width="100px"
                                                                  />
                                                              </div>
                                                          </div>
                                                      </div>
                                                      <!-- Extern -->
                                                      <div class="col-4" v-if="c.sens_sortie == 'externe'">
                                                          <div class="row">
                                                              <div class="col-6">
                                                                  <strong> Sens De Sortie : </strong>{{c.sens_sortie}}
                                                              </div>
                                                              <div class="col-3" v-if="c.type_sense_sortie == 'e_up'">
                                                                  <img
                                                                      class="comapny_logo_invoice"
                                                                      src="/images/form1T.png"
                                                                      alt="Image D'itéquette"
                                                                      height="100px"
                                                                      width="100px"
                                                                  />
                                                              </div>
                                                              <div class="col-3" v-if="c.type_sense_sortie == 'e_down'">
                                                                  <img
                                                                      class="comapny_logo_invoice"
                                                                      src="/images/form1B.png"
                                                                      alt="Image D'itéquette"
                                                                      height="100px"
                                                                      width="100px"
                                                                  />
                                                              </div>
                                                              <div class="col-3" v-if="c.type_sense_sortie == 'e_right'">
                                                                  <img
                                                                      class="comapny_logo_invoice"
                                                                      src="/images/form1R.png"
                                                                      alt="Image D'itéquette"
                                                                      height="100px"
                                                                      width="100px"
                                                                  />
                                                              </div>
                                                              <div class="col-3" v-if="c.type_sense_sortie == 'e_left'">
                                                                  <img
                                                                      class="comapny_logo_invoice"
                                                                      src="/images/form1.png"
                                                                      alt="Image D'itéquette"
                                                                      height="100px"
                                                                      width="100px"
                                                                  />
                                                              </div>
                                                          </div>
                                                      </div>
                                                      <div class="col-8">
                                                          <el-table
                                                              :data="Couleurs"
                                                              style="width: 100%">
                                                              <el-table-column
                                                                  prop="couleur"
                                                                  label="Couleur"
                                                                  width="180">
                                                              </el-table-column>
                                                              <el-table-column
                                                                  prop="ref"
                                                                  label="Ref"
                                                                  width="180">
                                                              </el-table-column>
                                                              <el-table-column
                                                                  prop="formule"
                                                                  label="formule">
                                                              </el-table-column>
                                                          </el-table>
                                                      </div>
                                              </div>
  
                                              <hr class="my-2" />
                                              <!-- footer -->
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="modal-footer">
                              <button
                                  class="btn btn-primary"
                                  style="float: right;"
                                  @click="printC()"
                              >
                                  <span class="fa fa-print"></span>
                              </button>
                          </div>
                      </div>
                      <!-- end invoice  -->
                  </div>
          </div>
  
              <!-- End Single Cost Invoice -->
          <!-- End Fiche Technique -->
          <!-- Demande Correction Engagement -->
          <el-dialog
              :title="'Saisie La DUM D\'importation'"
              :visible.sync="DialogVisibleSaisiDUM"
              width="80%"
              :before-close="handleClose">
              <!-- Body dialog -->
              <el-form :model="form_dum_importation">
                  <fieldset class="border p-2 mt-2">
                    <div class="row">
                        <el-form-item 
                            class="text-center col-lg-4"
                            prop="exportateur"
                            label="Exportarteur / Expéditeur"
                        >
                            <el-input v-model="form_dum_importation.exportateur"></el-input>
                        </el-form-item>
                        <el-form-item 
                            class="text-center col-lg-4"
                            prop="declaration"
                            label="Déclaration (Régime Douanier)"
                        >
                            <el-input v-model="form_dum_importation.declaration"></el-input>
                        </el-form-item>
                        <el-form-item 
                            class="text-center col-lg-4"
                            prop="bureauD"
                            label="Bureau Douanier"
                        >
                            <el-input v-model="form_dum_importation.bureauD"></el-input>
                        </el-form-item>
                        <el-form-item 
                            class="text-center col-lg-4"
                            prop="importateur"
                            label="Importateur / Distinataire"
                        >
                            <el-input v-model="form_dum_importation.importateur"></el-input>
                        </el-form-item>
                        <el-form-item 
                            class="text-center col-lg-4"
                            prop="declarant"
                            label="Déclarant (Transitaire) "
                        >
                            <el-input v-model="form_dum_importation.declarant"></el-input>
                        </el-form-item>
                        <el-form-item 
                            class="text-center col-lg-4"
                            prop="paysProv"
                            label="Pays Provenance"
                        >
                            <el-input v-model="form_dum_importation.paysProv"></el-input>
                        </el-form-item>
                        <el-form-item 
                            class="text-center col-lg-4"
                            prop="paysOrigine"
                            label="Pays D'origine"
                        >
                            <el-input v-model="form_dum_importation.paysOrigine"></el-input>
                        </el-form-item>
                        <el-form-item 
                            class="text-center col-lg-4"
                            prop="monnaie"
                            label="Monnaie (Devis)"
                        >
                            <el-input v-model="form_dum_importation.monnaie"></el-input>
                        </el-form-item>
                        <el-form-item 
                            class="text-center col-lg-4"
                            prop="totalMontant"
                            label="Montant Total"
                        >
                            <el-input v-model="form_dum_importation.totalMontant"></el-input>
                        </el-form-item>
                        <el-form-item 
                            class="text-center col-lg-4"
                            prop="tauxChange"
                            label="Taux de change"
                        >
                            <el-input v-model="form_dum_importation.tauxChange"></el-input>
                        </el-form-item>
                        <el-form-item 
                            class="text-center col-lg-4"
                            prop="fret"
                            label="Fret"
                        >
                            <el-input v-model="form_dum_importation.fret"></el-input>
                        </el-form-item>
                        <el-form-item 
                            class="text-center col-lg-4"
                            prop="assurance"
                            label="Assurance"
                        >
                            <el-input v-model="form_dum_importation.assurance"></el-input>
                        </el-form-item>
                    </div>
                  </fieldset>
              </el-form>
              <!-- show on edit mode -->
              <span slot="footer"  class="dialog-footer">
                  <el-button @click="DialogVisibleSaisiDUM = false">Annuler</el-button>
                  <el-button type="primary" @click="CreateDumImportation()">Valider</el-button>
              </span>
          </el-dialog>
          <!-- End Modal -->
          <!-- Demande Correction Engagement -->
          <el-dialog
              :title="'Demande D\'enregistrement De DUM D\'importation'"
              :visible.sync="DialogVisible_E_DUM"
              width="60%"
              :before-close="handleClose">
              <!-- Body dialog -->
              <el-form :model="form_dum_importation">
                  <fieldset class="border p-2 mt-2">
                    <div class="row">
                        <el-form-item 
                            class="text-center col-lg-6"
                            prop="num_enregistrement"
                            label="N° d'enregistrement"
                        >
                            <el-input v-model="form_dum_importation.num_enregistrement"></el-input>
                        </el-form-item>
                        <el-form-item 
                            class="text-center col-lg-6"
                            prop="date_enregistrement"
                            label="Date d'enregistrement"
                        >
                        <el-date-picker type="date" placeholder="Choisis une date" v-model="form_dum_importation.date_enregistrement" format="yyyy-MM-dd" value-format="yyyy-MM-dd" style="width: 100%;"></el-date-picker>
                        </el-form-item>
                    </div>
                  </fieldset>
              </el-form>
              <!-- show on edit mode -->
              <span slot="footer"  class="dialog-footer">
                  <el-button @click="DialogVisible_E_DUM = false">Annuler</el-button>
                  <el-button type="primary" @click="Creat_Demande_E_DUM()">Valider</el-button>
              </span>
          </el-dialog>
          <!-- End Modal -->
        
  
  <!--Dialoge Magasin -->
  <el-dialog title="Ajouter Bobine" :visible.sync="dialogVisibleMagasin" width="50%" :before-close="handleClose">
              <el-form :model="formMagasin" >
                  <!-- <div class="form-group col-12">
                      <label>Scanner le Code barre :</label>
                      <qrcode-stream :camera="camera" @decode="onDecode" @init="onInit">
                      <div v-show="showScanConfirmation" class="scan-confirmation">
                      </div>
                      </qrcode-stream>
                  </div> -->
                  <div class="row">
                      <el-form-item 
                          class="col-lg-9"
                          label="N° De Bobine"
                          prop="id_bobine"
                      >
                          <el-select v-model="formMagasin.id_bobine" style="width: 100%" filterable placeholder="Choisir une bobine">
                              <el-option
                                  v-for="item in Bobines"
                                  :key="item.id"
                                  :label="item.code_bobine"
                                  :value="item.code_bobine"
                              ></el-option>
                          </el-select>
                      </el-form-item>
                      <el-form-item 
                          class="col-lg-3"
                          label="Poids (KG)"
                          prop="poids"
                      >
                          <el-input v-model="formMagasin.poids" placeholder="Poids"></el-input>
                      </el-form-item>
                  </div>
              </el-form>
              <span slot="footer" class="dialog-footer">
                  <el-button @click="dialogVisibleMagasin = false">Annuler</el-button>
                  <el-button  type="success" v-show="!editmode" @click="createMagasin()">Valider</el-button>
                  <el-button  type="success" v-show="editmode" @click="SaveupdateMagasin()">Modifier</el-button>
              </span>
  </el-dialog>
          
  <!-- end Dialog magasin -->
          <!-- Modal Production 2 -->
          <div class="modal fade" id="addNewProduction2" tabindex="-1" role="dialog" aria-labelledby="addNewProduction2" aria-hidden="true">
              <div class="modal-dialog modal-lg" role="document">
                  <div class="modal-content">
                  <div class="modal-header">
                      
                      <h5 class="modal-title" v-show="!editmode">Ajouter</h5>
                      <h5 class="modal-title" v-show="editmode">Modifier</h5>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                      </button>
                  </div>
  
                  <form>
                      <div class="modal-body">
                          
                          <!--  -->
                          
                              <!-- <div class="row">
                                  <fieldset class="border col-6  p-2">
                                      <legend class="w-auto badge"><h6><strong> Scanner le Code barre </strong></h6></legend>
                                      <div class=" row">
                                      
                                       <div class="form-group col-12">
                                          <qrcode-stream :camera="camera" @decode="onDecode" @init="onInit">
                                          <div v-show="showScanConfirmation" class="scan-confirmation">
                                          </div>
                                          </qrcode-stream>
                                      </div>
                                      <div class="form-group col-12">
                                          <label>Ou Saisie le N° de Bobine:</label>
                                          <input v-model="formMagasin.num_Bobine" type="text" name="num_Bobine"
                                              class="form-control text-center" :class="{ 'is-invalid': form.errors.has('num_Bobine') }">
                                          <has-error :form="form" field="num_Bobine"></has-error>
                                      </div>
                                      </div>
                                  </fieldset>
                                  
                                  <fieldset class="border p-2 col-6 ">
                                      <legend class="w-auto badge"><h6><strong> Durée De Production </strong></h6></legend>
                                      <div class="row mt-5">
                                          <div class="form-group col-12">
                                              <h4 class="ml-5">{{ result1 | minutesAndSeconds }}</h4>
                                          </div>
                                          <div  class="form-group col-12">
                                              <el-button v-if="!started"  type="success" class="btn-block " size="small" icon="el-icon-timer" @click="countToTen()">Commancer la Production</el-button>
                                              <el-button  v-else type="danger" class="btn-block " size="small" icon="el-icon-timer" @click="stopCountToTen()">Terminer</el-button>
                                          </div>
                                      </div>
                                  </fieldset>
                              </div> -->
                          
                      </div>
                      <div class="modal-footer">
                          <el-button type="danger" size="small" data-dismiss="modal" plain>Annuler</el-button>
                          <el-button type="success" v-show="editmode" size="small" @click="updateProduct()" plain>Valider</el-button>
                          <el-button type="success" v-show="!editmode" size="small" @click="createProduct()" plain>Valider</el-button>
                      </div>
                    </form>
                  </div>
              </div>
          </div>
          <!-- end Modal Production 2 -->
          <!-- Modal Production -->
          <div class="modal fade" id="addNewProduction" tabindex="-1" role="dialog" aria-labelledby="addNewProduction" aria-hidden="true">
              <div class="modal-dialog modal-xl" role="document">
                  <div class="modal-content">
                  <div class="modal-header">
                      <h5 class="modal-title" v-show="!editmode">Ajouter</h5>
                      <h5 class="modal-title" v-show="editmode">Modifier</h5>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                      </button>
                  </div>
  
                  <form>
                      <div class="modal-body">
                          
                          <!--  -->
                          <fieldset class="border p-2">
                              <div class="row">
                                      <div class="form-group col-4">
                                          <label>Poids Déchets:</label>
                                          <input v-model="formProduction.Poids_dechet" type="text" name="Poids_dechet"
                                              class="form-control text-center" :class="{ 'is-invalid': form.errors.has('Poids_dechet') }">
                                          <has-error :form="form" field="Poids_dechet"></has-error>
                                      </div>
                                      <div class="form-group col-4">
                                          <label>Poids Faut-Tirage:</label>
                                          <input v-model="formProduction.Poids_FTirage" type="number" name="Poids_FTirage"
                                              class="form-control" :class="{ 'is-invalid': form.errors.has('Poids_FTirage') }">
                                          <has-error :form="form" field="Poids_FTirage"></has-error>
                                      </div>
                                      <div class="form-group col-4">
                                          <label>Le reste (ML) :</label>
                                          <input v-model="formProduction.Reste" type="number" name="Reste"
                                              class="form-control" :class="{ 'is-invalid': form.errors.has('Reste') }">
                                          <has-error :form="form" field="Reste"></has-error>
                                      </div>
                              </div>
                          </fieldset>
                      </div>
                      <div class="modal-footer">
                          <el-button type="danger" size="small" data-dismiss="modal" plain>Annuler</el-button>
                          <el-button type="success" v-show="editmode" size="small" @click="updateProduct()" plain>Valider</el-button>
                          <el-button type="success" v-show="!editmode" size="small" @click="createProduct()" plain>Valider</el-button>
                      </div>
                    </form>
                  </div>
              </div>
          </div>
          <!-- end Modal Production -->
          <!-- Modal Conditionement QrCodes -->
          <div
                  class="modal fade"
                  id="ModalCondi"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="Condi"
                  aria-hidden="true"
              >
                  <div class="modal-dialog modal-xl" role="document">
                      <div class="modal-content">
                          <div class="modal-header">
                              <h5 class="modal-title text-right"></h5>
                              <button
                                  type="button"
                                  class="close"
                                  data-dismiss="modal"
                                  aria-label="Close"
                              >
                                  <span aria-hidden="true">&times;</span>
                              </button>
                          </div>
                          <!-- invoice -->
                          <div class="modal-body" id="printMeCondi">
                                  <el-card class="box-card col-3" v-for="item in QrCodes" :key="item.value">
                                      <VueQRCodeComponent 
                                      :size="100"
                                      :text="item.value">
                                      </VueQRCodeComponent>
                                      <strong class="mt-2">QrCode : {{item.value}}</strong>
                                  </el-card>
                          </div>
                          <div class="modal-footer">
                              <button
                                  class="btn btn-primary"
                                  style="float: right;"
                                  @click="printCondi()"
                              >
                                  <span class="fa fa-print"></span>
                              </button>
                          </div>
                      </div>
                      <!-- end invoice  -->
                  </div>
          </div>
          <!-- End Conditionement Modal -->
      </div>
    </section>
  </template>
  
  <script>
      import VueQRCodeComponent from 'vue-qrcode-component';
      import moment from 'moment';
      import {Machine1 , Machine2 ,Machine3, Mandrin, Etiquettes , Clients} from '../devis/Zcylindre';
  
      export default {
        components: {
              VueQRCodeComponent,
          },
          data () {
              return {
                  DialogVisiblePlaques: false,
                  DialogVisibleDevisPlaques: false,
                  DialogVisibleDevisTransport: false,
                  DialogVisibleSFPFPlaques: false,
                  DialogVisibleValideDevisPlaques: false,
                  Projet:[],
                  devisPlaque:[],
                  ProFormaPlaque:[],
                  Couleurs:[],
                  Remarques:[],
                  file:[],
                  BobineMagasin:[],
                  Bobines:[],
                  etape:'',
                  idProjet : this.$route.params.idpfd,
                  editmode: false,
  
                  // dialogues
                  dialogVisibleMagasin: false,
  
                  Remarquesform: new Form({
                      id:'',
                  //    id_user:'',
                     id_projet:this.$route.params.idpfd,
                     etape:'',
                     message:''
                  }),
  
                  ValidationForm: new Form({
                      etape:'',
                      validation_FD1:'',
                      validation_Concept1:'',
                      validation_BAT1:''
                  }),
  
                  // Form Devis Plaque
                  formDevisPlaque: new Form({ 
                      id_projet:this.$route.params.idpfd,
                      id_fournisseur:'',
                      type_de_demande:'',
                      id_etc:this.$route.params.idpfd,
                      rayon_coin:'',
                      rayon_coin_valeur:'',
                      qtit:'',
                      prix_unit:'',
                      method_envoi:'',
                      date_livraison:'',
                      modalite_paiment:'',
                  }),

                  // Form Devis Transport
                  formDevisTransport: new Form({ 
                    marchandises:[],
                    id_devis_global:this.$route.params.idpfd,
                    id_transporteur:'',
                    bureau_douanier:'',
                    type_chargement:'',
                    date_enlevement:'',
                    lieu_livraison:'',
                    poids_net_total:'',
                    poids_brut_total:'',
                  }),

                  form_ProForma: new Form({ 

                    myarticles:[],
                    id:'',

                    // id_projet:this.$route.params.idpfd,
                    id_devis_global:this.$route.params.idpfd,
                    id_fournisseur:'',
                    num_facturePro:'',
                    date_facturePro:'',
                    condition_reglement:'',
                    poid_brut_total:'',
                    poid_Net_total:'',
                    // 
                    montantPrixTotal:'',
                    cours_changeActuel:'',
                    valeur_totalCalculer:'',
                    // 
                    type_davance:'',
                    modalite_paiment:'',
                    // 
                    type_transport:'',
                    charge_transport:'',
                  }),

                  form_dum_importation: new Form({ 
                    // myarticles:[],
                    id:'',
                    id_devis_global:this.$route.params.idpfd,
                    exportateur:'',
                    declaration:'',
                    bureauD:'',
                    importateur:'',
                    declarant:'',
                    paysProv:'',
                    paysOrigine:'',
                    monnaie:'',
                    totalMontant:'',
                    fret:'',
                    assurance:'',
                    // enregistrement
                    num_enregistrement:'',
                    date_enregistrement:''
                  }),

                  form_caution_banque: new Form({ 

                    // id_projet:this.$route.params.idpfd,
                    id_devis_global:this.$route.params.idpfd,
                    // id_fournisseur:'',

                    num_dum:'',
                    date_dum:'',
                    mantant_declarée:'',
                    mantant_cautionée:'',
                    taux_calculer:'',
                    num_caution_banque:'',
                    
                  }),

                  form_F_Liquidation: new Form({ 

                    // id_projet:this.$route.params.idpfd,
                    id_devis_global:this.$route.params.idpfd,
                    // id_fournisseur:'',

                    num_declaration:'',
                    date_declaration:'',
                    num_liquidation:'',
                    date_liquidation:'',
                    
                  }),

                  form_R_Droit_Taxes: new Form({ 

                    // id_projet:this.$route.params.idpfd,
                    id_devis_global:this.$route.params.idpfd,
                    // id_fournisseur:'',
                    paiement_effectBy:'',
                    compte_banque:'',
                    num_transaction_mtc:'', 
                    num_transaction_marchand:'', 
                    date_reglement:'',
                    mode_paiement:'',
                    
                  }),

                  form_Prices: new Form({ 
                    //   id_projet:this.$route.params.idpfd,
                    fournisseur:'',
                      
                    plaquePrices:[]
                  }),

                  form_bon_cmd: new Form({ 
                    //   id_projet:this.$route.params.idpfd,
                    montantChiffre:'',
                    montantLettre:'',
                    swift:'',
                      
                    plaqueValidation:[]
                  }),
  
                  // Form Magasin
                  formMagasin: new Form({ 
                      id_projet:this.$route.params.idpfd,
                      id_bobine:'',
                      quantite:'',
                      poids : '',
                      etats : '',
                  }),
  
  
              // To delete after
                  // 
                  valueFD: '',
                  valueCL: '',
  
                  QrCodes: [{
                      value: '12547826654',
                      label: '12547826654'
                      }, {
                      value: '645318945454',
                      label: '645318945454'
                      }, {
                      value: '321578978454',
                      label: '321578978454'
                      },{
                      value: '231564654545',
                      label: '231564654545'
                      }, {
                      value: '321654822222',
                      label: '321654822222'
                      }, {
                      value: '215482214597',
                      label: '215482214597'
                      }],
  
                  optionsFD: [{
                      value: '0005 88x71 REC',
                      label: '0005 88x71 REC'
                      }, {
                      value: '0004 64x48 REC-VER',
                      label: '0004 64x48 REC-VER'
                      }, {
                      value: '0006 90x71 REC',
                      label: '0006 90x71 REC'
                      }],
  
                  optionsCL: [{
                      value: '0008-096-003-4-O-N-N-N',
                      label: '0008-096-003-4-O-N-N-N'
                      }, {
                      value: '0009-012-103-2-O-N-N-N',
                      label: '0009-012-103-2-O-N-N-N'
                      }, {
                      value: '0021-01-023-3-O-N-N-O',
                      label: '0021-01-023-3-O-N-N-O'
                      }],
                      
                  soundBeep : 'https://soundbible.com/mp3/Beep-SoundBible.com-923660219.mp3',
                  // QrCode generator
                  QRcode: '5236541236',
                  // QrCode
                  // camera: 'auto',
                  result: null,
                  showScanConfirmation: false,
  
                  // 
  
                  // projet
                  tableData: [{
                          dossiernumber: '1254123',
                          etiquet_name: 'etiquette-500g-rec-oval-caree',
                          client_name: 'Mohamed',
                          date: '12-10-2021',
                          client_email: 'mohamed@entreprise.com',
                          formatL:'88',
                          formatD:'71',
                          form_etiquette :'REC',
                          qtit: '500000',
                          support: 'Termique',
                          impression:'oui',
                          Nbr_couleur:'4',
                          recto:'non',
                          verso:'non',
                          conception:'Non',
                          repiquage:'oui',
                          vernis:'non',
                          plastification:'non',
                          dorure:'non',
                          nbrbobine:'2000',
                          nbrfront:'1',
                          mandrin : '76',
                          poseEtq: 'Manuelle',
                          formDecoup : 'oui',
                          filmCliches: 'non',
                          // 
                          Z_cylindre:'94',
                          nbrPosesL : '2',
                          nbrPosesD : '4',
                          espaceL : '3',
                          espaceD: '3.61',
                          machine:'GIDUE 370',
                          // BAT
                          
  
                  }],
                  // composition encre
                  tableData2: [{
                          Couleur: 'JAUNE',
                          Ref: 'Principale',
                          poste: 'G1',
                          Anilox: '3.7/398',
                          formule: '-------',
                          observations: ''
                      }, {
                          Couleur: 'MAGENTA',
                          Ref: 'Principale',
                          poste: 'G2',
                          Anilox: '3.2/303',
                          formule: '-------',
                          observations: ''
                      }, {
                          Couleur: 'CYAN',
                          Ref: 'Principale',
                          poste: 'G3',
                          Anilox: '3.5/408',
                          formule: '-------',
                          observations: ''
                      }, {
                          Couleur: 'NOIR',
                          Ref: 'Principale',
                          poste: 'G4',
                          Anilox: '3.2/306',
                          formule: '-------',
                          observations: ''
                  }],
                  // 
                  tableData3: [{
                          QuantitML: '18753.125',
                          QuantitM2: '3563.093',
                          EstimationB: '9.376',
                          FormatM: '78mm D76',
                          QuantitM: '250'
                      }],
                      // Magasin
                      tableDataMagasin:[{
                          num_Bobine: '123456',
                          quantite : '2000',
                          poids: '59'
                      },{
                          num_Bobine: '85264',
                          quantite : '2000',
                          poids: '60'
                      },{
                          num_Bobine: '9545',
                          quantite : '2000',
                          poids: '61'
                      },
                      {
                          num_Bobine: '98845',
                          quantite : '2000',
                          poids: '59'
                      },
                      {
                          num_Bobine: '897854',
                          quantite : '2000',
                          poids: '59'
                      },
                      {
                          num_Bobine: '8845',
                          quantite : '2000',
                          poids: '59'
                      },
                      {
                          num_Bobine: '74440',
                          quantite : '2000',
                          poids: '59'
                      },{
                          num_Bobine: '897854',
                          quantite : '2000',
                          poids: '59'
                      },
                      {
                          num_Bobine: '8845',
                          quantite : '2000',
                          poids: '59'
                      },
                      {
                          num_Bobine: '74440',
                          quantite : '2000',
                          poids: '59'
                      }],
                      // Magasin
                      tableDataProduction:[{
                          Poids_dechet: '1250',
                          Poids_FTirage : '500',
                          Reste: '150'
                      }],
                      // Production2
                      tableDataProduction2:[
                          {
                          num_Bobine: '86544654',
                          duree_Production : '500'
                          },
                          {
                          num_Bobine: '54654',
                          duree_Production : '254'
                          },
                          {
                          num_Bobine: '965412',
                          duree_Production : '500'
                          },
                          {
                          num_Bobine: '98563',
                          duree_Production : '254'
                          },
                      ],
                  // plaques
                  plaqueFormat:[
                      {l:'21', d:'71', num:'0007-xxx1'},
                      {l:'22', d:'72', num:'0010-xxx7'},
                      {l:'23', d:'73', num:'0008-xxx2'},
                      ],
                  tableData1s: [{
                  id:'1',
                  dossiernumber: '1254123',
                  client_name: 'Mohamed',
                  Date: '12-10-2021',
                  responsable_name: 'Mustapha',
                  etiquet_name: 'etiquette-500g-rec-oval-caree',
                  support: 'Couché',
                  format:'21 x 17',
                  conception:'Non',
                  Impression:'Oui',
                  Nbr_couleur:'2',
                  recto:'oui',
                  verso:"non",
                  repiquage:"non",
                  plastification:"oui",
                  vernis:"Non",
                  }],
                  search: '',
                  pagesize: 10,
                  currpage: 1,
                  
                  products : [],
                  // formPlaque
                  Zcylindre:[],
                  Machine1: Machine1,
                  Machine2: Machine2,
                  Machine3: Machine3,
                  form2: new Form({
                      validation1:1,
                      validation2:'',
                  }),
                  form: new Form({
                      num_pfd:'',
                      num_reference:'',
                      date_production:'',
                      numSerie:'',
                      formatMM_L:'',
                      formatMM_D:'',
                      nbrPoseLaize:'',
                      nbrPoseDevlop:'',
                      nbrPoseSurPlaq:'',
                      machine:[],
                      z_cylindre:'',
                      devlop_mm:'',
                      laize_mm:'',
                      laize_papier_mm:'',
                      type_support:'',
                      forme_etq:'',
                  }),
                  categories: [],
  
                  tag:  '',
                  autocompleteItems: [],
  
                  // Form Production
                  formProduction: new Form({ 
                      Poids_dechet:'',
                      Poids_FTirage:'',
                      Reste : '',
                      preparationTime: '',
                      productionTime: ''
                  }),

                  formTracking: new Form({ 
                    id_devis_global:this.$route.params.idpfd,
                    lieu:'',
                    id_projet:'',
                    plaqueArray:[]
                  }),

                  form_SFPF: new Form({
                    designiation: '',
                  }),
                  form_d_pro_format: new Form({ 
                    //   id_projet:this.$route.params.idpfd,
                    montantChiffre:'',
                    montantLettre:'',
                    id_fournisseur:'',
                    fournisseur_name:'',
                    id_global:'',
                      
                    plaqueDemandeProF:[]
                  }),

                  form_correction_engagement: new Form({ 

                    plaqueDemandeCorrect:[],
                    id_fournisseur:'',
                    fournisseur_name:'',
                    id_global:'',
                    // 
                    nom_F_Pro:'',
                    nom_F_Enga:'',
                    nom_F_Etat:'',
                    // 
                    bureau_D_Pro:'',
                    bureau_D_Enga:'',
                    bureau_D_Etat:'',
                    // 
                    regime_D_Pro:'',
                    regime_D_Enga:'',
                    regime_D_Etat:'',
                    // 
                    transport_Pro:'',
                    transport_Enga:'',
                    transport_Etat:'',
                    // 
                    designation_Pro:'',
                    designation_Enga:'',
                    designation_Etat:'',
                    // 
                    qtit_Pro:'',
                    qtit_Enga:'',
                    qtit_Etat:'',
                    // 
                    nomenclature_Pro:'',
                    nomenclature_Enga:'',
                    nomenclature_Etat:'',
                    // 
                    P_NET_Pro:'',
                    P_NET_Enga:'',
                    P_NET_Etat:'',
                    // 
                    P_BRUT_Pro:'',
                    P_BRUT_Enga:'',
                    P_BRUT_Etat:'',
                    // 
                    devis_Pro:'',
                    devis_Enga:'',
                    devis_Etat:'',
                    // 
                    pays_Pro:'',
                    pays_Enga:'',
                    pays_Etat:'',
                    // 
                    incoterm_Pro:'',
                    incoterm_Enga:'',
                    incoterm_Etat:'',
                    // 
                    fret_Pro:'',
                    fret_Enga:'',
                    fret_Etat:'',
                  }),

                  form_saisie_engagement: new Form({ 
                    fournisseur: '',
                    id_global:'',
                    id_proformat:'',
                    engagementSaisi:[]
                  }),

                  form_v_enga: new Form({ 
                    id_fournisseur: '',
                    id_global:'',
                    id_enga:'',
                    plaqueValidation:[]
                  }),

                  form_expidition: new Form({ 
                    
                    type_envoi:''
                    
                    // plaqueValidation:[]
                  }),
                  // 
                  result1: 0,
                  started: false,
                  timeout1: null,
                  Onefournisseurs:[],
                  fournisseurs:[],
                  devispfd:[],
                  fullscreenLoading: false,
  
                  // Bon Livraison
                  DialogVisibleBonLivraison : false,
                  DialogVisibleFPF : false,
                  DialogVisibleAccuseR : false,
                  DialogVisibleSWIFT : false,
                  DialogVisibleEDP : false,
                  DialogVisibleFL : false,
                  DialogVisibleRecuReglement : false,
                  DialogVisibleMainL : false,
                  DialogVisibleADC : false,
                  DialogVisibleFDT : false,
                  DialogVisibleFDC : false,
                  DialogVisibleTrackingPlaques : false,
                  DialogVisibleCoutionBanque : false,
                  DialogVisibleFLiquidation: false,
                  DialogVisibleRDroitTaxes: false,
                  DialogVisibleDemandeFactureProFormat : false,
                  DialogVisibleDemandeCENGAGE : false,
                  DialogVisibleCertificatOrigine : false,
                  DialogVisibleSaisiDUM : false,
                  DialogVisible_E_DUM : false,
                //   
                  filename : '',
                  file: "",
                  listeFiles: [],
                  listeFilesFPF: [],
                  listeFilesAccuseR: [],
                  listeFilesSWIFT: [],
                  listeFilesEDP: [],
                  listeFilesFL: [],
                  listeFilesCertificatOrigine: [],
                  listeFilesRecuReglement: [],
                  listeFilesMainL: [],
                  listeFilesADC: [],
                  listeFilesFDT: [], 
                  listeFilesFDC: [], 

                  TrackingPlaque: [],
                  ProFormatPFD: [],
                  DemandeEngagePFD: [],
                  DemandeCEngagePFD: [],
                  DemandeVEngagePFD: [],
                  ECautionB: [],
                  DUM_Saised: [],
                  // 
                  multipleSelection: [],
                  active: 0,

                  // Images Incoterm
                url1: '/images/incoterm/1.png',
                srcList1: ['/images/incoterm/1.png', ],
                url2: '/images/incoterm/2.png',
                srcList2: ['/images/incoterm/2.png', ],
                url3: '/images/incoterm/3.png',
                srcList3: ['/images/incoterm/3.png', ],
                url4: '/images/incoterm/4.png',
                srcList4: ['/images/incoterm/4.png', ],
              }
          },
          methods: {

            nomfournisseur(myvalue){

                switch (myvalue) {
                       case 'nom_F':
                            if(this.form_correction_engagement.nom_F_Enga == this.form_correction_engagement.nom_F_Pro){
                                this.form_correction_engagement.nom_F_Etat = "VALIDE"
                            }else{
                                this.form_correction_engagement.nom_F_Etat = "A CORRIGER"
                            }
                           break;
                       case 'bureau_D':
                            if(this.form_correction_engagement.bureau_D_Enga == this.form_correction_engagement.bureau_D_Pro){
                                this.form_correction_engagement.bureau_D_Etat = "VALIDE"
                            }else{
                                this.form_correction_engagement.bureau_D_Etat = "A CORRIGER"
                            }
                           break;
                       case 'regime_D':
                            if(this.form_correction_engagement.regime_D_Enga == this.form_correction_engagement.regime_D_Pro){
                                this.form_correction_engagement.regime_D_Etat = "VALIDE"
                            }else{
                                this.form_correction_engagement.regime_D_Etat = "A CORRIGER"
                            }
                           break;
                       case 'transport':
                            if(this.form_correction_engagement.transport_Enga == this.form_correction_engagement.transport_Pro){
                                this.form_correction_engagement.transport_Etat = "VALIDE"
                            }else{
                                this.form_correction_engagement.transport_Etat = "A CORRIGER"
                            }
                           break;
                       case 'designation':
                            if(this.form_correction_engagement.designation_Enga == this.form_correction_engagement.designation_Pro){
                                this.form_correction_engagement.designation_Etat = "VALIDE"
                            }else{
                                this.form_correction_engagement.designation_Etat = "A CORRIGER"
                            }
                           break;
                       case 'qtit':
                            if(this.form_correction_engagement.qtit_Enga == this.form_correction_engagement.qtit_Pro){
                                this.form_correction_engagement.qtit_Etat = "VALIDE"
                            }else{
                                this.form_correction_engagement.qtit_Etat = "A CORRIGER"
                            }
                           break;
                       case 'nomenclature':
                            if(this.form_correction_engagement.nomenclature_Enga == this.form_correction_engagement.nomenclature_Pro){
                                this.form_correction_engagement.nomenclature_Etat = "VALIDE"
                            }else{
                                this.form_correction_engagement.nomenclature_Etat = "A CORRIGER"
                            }
                           break;
                       case 'P_NET':
                            if(this.form_correction_engagement.P_NET_Enga == this.form_correction_engagement.P_NET_Pro){
                                this.form_correction_engagement.P_NET_Etat = "VALIDE"
                            }else{
                                this.form_correction_engagement.P_NET_Etat = "A CORRIGER"
                            }
                           break;
                       case 'P_BRUT':
                            if(this.form_correction_engagement.P_BRUT_Enga == this.form_correction_engagement.P_BRUT_Pro){
                                this.form_correction_engagement.P_BRUT_Etat = "VALIDE"
                            }else{
                                this.form_correction_engagement.P_BRUT_Etat = "A CORRIGER"
                            }
                           break;
                       case 'devis':
                            if(this.form_correction_engagement.devis_Enga == this.form_correction_engagement.devis_Pro){
                                this.form_correction_engagement.devis_Etat = "VALIDE"
                            }else{
                                this.form_correction_engagement.devis_Etat = "A CORRIGER"
                            }
                           break;
                       case 'pays':
                            if(this.form_correction_engagement.pays_Enga == this.form_correction_engagement.pays_Pro){
                                this.form_correction_engagement.pays_Etat = "VALIDE"
                            }else{
                                this.form_correction_engagement.pays_Etat = "A CORRIGER"
                            }
                           break;
                       case 'incoterm':
                            if(this.form_correction_engagement.incoterm_Enga == this.form_correction_engagement.incoterm_Pro){
                                this.form_correction_engagement.incoterm_Etat = "VALIDE"
                            }else{
                                this.form_correction_engagement.incoterm_Etat = "A CORRIGER"
                            }
                           break;
                       case 'fret':
                            if(this.form_correction_engagement.fret_Enga == this.form_correction_engagement.fret_Pro){
                                this.form_correction_engagement.fret_Etat = "VALIDE"
                            }else{
                                this.form_correction_engagement.fret_Etat = "A CORRIGER"
                            }
                           break;
                       }
                
            },

            // tracking Plaque
            async trackingPlaque(){
                this.formTracking.reset();

                this.formTracking.plaqueArray = [];
                
                this.editmode = false;
                this.DialogVisibleTrackingPlaques = true;
                await this.loadOneDevisPlaque(this.$route.params.idpfd);
                    
                    for (let i = 0; i < this.devisPlaque.length; i++) {
                        for (let j = 0; j < this.devisPlaque[i].get_plaques.length; j++) {
                            this.formTracking.plaqueArray.push({
                                    id_projet: this.devisPlaque[i].get_plaques[j].id_projet,
                                })
                        }
                    }
            },

            createtrackingPlaque(){

                this.$Progress.start();
                    this.formTracking.post('/api/TrackingCmdPlaques')
                    .then((response) => {
                        
                        Toast.fire({
                            icon: 'success',
                            title: response.data.message
                        });
                        this.DialogVisibleTrackingPlaques = false
                        this.loadTrackingPlaque();

                        this.$Progress.finish();
                    })
                    .catch(() => {
                        this.$Progress.fail();
                });
            },
              // file Bon Livraison
              joindreBLPlaque(){
                  this.filename ='';
                  
                  this.DialogVisibleBonLivraison = true;
              },
            //   saisie Facture Pro Forma
            next() { this.active++; },
            prev() { this.active--; },

            async SaisieFPF() {
                await this.loadOneDevisPlaque(this.$route.params.idpfd);
                
                this.form_ProForma.myarticles = [];
                this.form_ProForma.montantPrixTotal = 0;

                    for (let i = 0; i < this.devisPlaque.length; i++) {
                        this.form_ProForma.id_fournisseur = this.devisPlaque[i].id_fournisseur
                        for (let j = 0; j < this.devisPlaque[i].get_plaques.length; j++) {
                            
                            this.form_ProForma.myarticles.push({
                                id_plaque : this.devisPlaque[i].get_plaques[j].id,
                                designation_fournisseur:'',
                                rebrique_designation:'',
                                code_designation:'',
                                devis:'',
                                nomenclature:'',
                                poids_net:'',
                                poids_brut:'',
                                pays_origine:'',
                                pays_provenance:'',
                                incoterm:'',
                                unite_mesure:'',
                                qtit:'',
                                prix_unit:this.devisPlaque[i].get_plaques[j].prix_unit,
                                prix_total:'',
                            })

                            this.form_ProForma.montantPrixTotal += Number(this.devisPlaque[i].get_plaques[j].prix_unit)
                        }
                    }
                    
                this.DialogVisibleSFPFPlaques = true;
            },

            calculerValeurTotal(){
                this.form_ProForma.valeur_totalCalculer = this.form_ProForma.montantPrixTotal*(Number(this.form_ProForma.cours_changeActuel)+0.3)
            },

            calculerPoidBrutTotal(){
                var totalBrut = 0
                var totalNet = 0
                for (let i = 0; i < this.form_ProForma.myarticles.length; i++) {
                    totalBrut += Number(this.form_ProForma.myarticles[i].poids_brut)
                    totalNet += Number(this.form_ProForma.myarticles[i].poids_net)
                }
                this.form_ProForma.poid_brut_total = totalBrut
                this.form_ProForma.poid_Net_total = totalNet
            },

            async CreateProForma(){
                await this.calculerPoidBrutTotal(); 
                    this.$Progress.start();

                    this.form_ProForma.post('/api/ProFormaPlaque')
                    .then((data)=>{
                        if(data.data.success){
                        this.DialogVisibleSFPFPlaques = false

                        Toast.fire({
                                icon: 'success',
                                title: data.data.message
                            });
                        this.$Progress.finish();
                        this.loadProFormaPlaque();

                        } else {
                        Toast.fire({
                            icon: 'error',
                            title: 'Some error occured! Please try again'
                        });

                        this.$Progress.failed();
                        }
                    })
                    .catch(()=>{

                        Toast.fire({
                            icon: 'error',
                            title: 'Some error occured! Please try again'
                        });
                    })
            },

            updateProFormaGlobal(facture){
                
                this.editmode = true;
                this.DialogVisibleSFPFPlaques = true;
                this.active=0;
                this.form_ProForma.reset();
                this.form_ProForma.fill(facture);
                this.form_ProForma.myarticles = [];
                for (let i = 0; i < facture.get_articles.length; i++) {
                    this.form_ProForma.myarticles.push({
                        id_plaque : facture.get_articles[i].id_plaque,
                        designation_fournisseur:facture.get_articles[i].designation_fournisseur,
                        rebrique_designation:facture.get_articles[i].rebrique_designation,
                        code_designation:facture.get_articles[i].code_designation,
                        devis:facture.get_articles[i].devis,
                        nomenclature:facture.get_articles[i].nomenclature,
                        poids_net:facture.get_articles[i].poids_net,
                        poids_brut:facture.get_articles[i].poids_brut,
                        pays_origine:facture.get_articles[i].pays_origine,
                        pays_provenance:facture.get_articles[i].pays_provenance,
                        incoterm:facture.get_articles[i].incoterm,
                        unite_mesure:facture.get_articles[i].unite_mesure,
                        qtit:facture.get_articles[i].qtit,
                        prix_unit:facture.get_articles[i].prix_unit,
                        prix_total:facture.get_articles[i].prix_total,
                       
                    })
                }
            },

            UpdateProForma(){
                this.$Progress.start();
                this.form_ProForma.put('/api/ProFormaPlaque/'+this.form_ProForma.id)
                .then((response) => {
                    
                    this.DialogVisibleSFPFPlaques = false
                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                    this.$Progress.finish();

                    this.loadProFormaPlaque();
                })
                .catch(() => {
                    this.$Progress.fail();
                });
            },

            deletProFormaGlobal(myid){
                Swal.fire({
                    title: 'Attention !',
                    text: "vous voulez vraiment supprimer Cette Facture ??",
                    showCancelButton: true,
                    confirmButtonColor: '#67c23a',
                    cancelButtonColor: '#f56c6c',
                    confirmButtonText: 'Oui, supprimez-le ! '
                    }).then((result) => {
  
                        // Send request to the server
                          if (result.value) {
                                this.form.delete('/api/ProFormaPlaque/'+myid).then(()=>{
                                        Swal.fire(
                                        'Supprimé !',
                                        'La Facture a été Bien supprimé.',
                                        'success'
                                        );
                                        this.loadProFormaPlaque();
                                }).catch((data)=> {
                                    Swal.fire("Failed!", data.message, "warning");
                                });
                          }
                    })
                // console.log('pro forma deleted with id = '+myid)
            },

            handleSelectionChange(val) {
                this.multipleSelection = val;
            },


            
            JoindreAccusReception(){
                this.filename ='';
                
                this.DialogVisibleAccuseR = true;
            },
            JoindreSWIFT(){
                this.filename ='';
                
                this.DialogVisibleSWIFT = true;
            },
            joindreFPFPlaque(){
                this.filename ='';
                
                this.DialogVisibleFPF = true;
            },

            JoindreCertificatOrigine(){
                this.filename ='';
                
                this.DialogVisibleCertificatOrigine = true;
            },

            getFile(file) {
                window.open("/upload/Plaques/BL/"+file, "_blank");
            },

            getFileFPF(file) {
                window.open("/upload/Plaques/FPF/"+file, "_blank");
            },
            getFileAccuseR(file) {
                window.open("/upload/Plaques/AccuseR/"+file, "_blank");
            },
            getFileSWIFT(file) {
                window.open("/upload/Plaques/SWIFT/"+file, "_blank");
            },
            getFileCertificatOrigine(file) {
                window.open("/upload/Plaques/CertifOrigine/"+file, "_blank");
            },

            loadFiles() {
                
                axios.get("/api/FilesBLPlaques/" + this.$route.params.idpfd).then(({ data }) => (this.listeFiles = data.data));
            },

            loadFilesFPF() {
                
                axios.get("/api/FilesFPFPlaques/" + this.$route.params.idpfd).then(({ data }) => (this.listeFilesFPF = data.data));
            },

            loadFilesAccuseR() {
                
                axios.get("/api/FilesAccuseR/" + this.$route.params.idpfd).then(({ data }) => (this.listeFilesAccuseR = data.data));
            },
            loadFilesSWIFT() {
                
                axios.get("/api/FilesSWIFT/" + this.$route.params.idpfd).then(({ data }) => (this.listeFilesSWIFT = data.data));
            },
            loadFilesCertificatOrigine() {
                
                axios.get("/api/FilesFilesCertificatOrigine/" + this.$route.params.idpfd).then(({ data }) => (this.listeFilesCertificatOrigine = data.data));
            },

            // Régime Douanier 22 37
            // Accusé de receptio => ADC
            loadFilesADC() {
                axios.get("/api/FilesFilesADC/" + this.$route.params.idpfd).then(({ data }) => (this.listeFilesADC = data.data));
            },

            getFileADC(file) {
                window.open("/upload/Plaques/ADC/"+file, "_blank");
            },

            JoindreADC(){
                this.filename ='';
                
                this.DialogVisibleADC = true;
            },

            submitADC(e) {
                  this.$Progress.start();
                  e.preventDefault();
                  let currentObj = this;
                  const config = {
                      headers: {
                          "content-type": "multipart/form-data",
                          "X-CSRF-TOKEN": document.querySelector(
                              'meta[name="csrf-token"]'
                          ).content
                      }
                  };
                  // form data
                  let formData = new FormData();
                  formData.append('file', this.file);
                  
                  axios.post("/api/uploadADC/"+this.$route.params.idpfd, formData, config)
                      
                      .then(response => {
                          // success
                          Toast.fire({
                              icon: "success",
                              title: response.data.message
                          });
                          this.DialogVisibleADC = false;
                          this.loadFilesADC();
                          this.$Progress.finish();
                      }
                      )
                      .catch(function(error) {
                          currentObj.output = error;
                      });
                  },
            
            // Fiche de declaration on transitaire => FDT
            loadFilesFDT() {
                axios.get("/api/FilesFilesFDT/" + this.$route.params.idpfd).then(({ data }) => (this.listeFilesFDT = data.data));
            },

            getFileFDT(file) {
                window.open("/upload/Plaques/FDT/"+file, "_blank");
            },

            JoindreFDT(){
                this.filename ='';
                
                this.DialogVisibleFDT = true;
            },

            submitFDT(e) {
                  this.$Progress.start();
                  e.preventDefault();
                  let currentObj = this;
                  const config = {
                      headers: {
                          "content-type": "multipart/form-data",
                          "X-CSRF-TOKEN": document.querySelector(
                              'meta[name="csrf-token"]'
                          ).content
                      }
                  };
                  // form data
                  let formData = new FormData();
                  formData.append('file', this.file);
                  
                  axios.post("/api/uploadFDT/"+this.$route.params.idpfd, formData, config)
                      
                      .then(response => {
                          // success
                          Toast.fire({
                              icon: "success",
                              title: response.data.message
                          });
                          this.DialogVisibleFDT = false;
                          this.loadFilesFDT();
                          this.$Progress.finish();
                      }
                      )
                      .catch(function(error) {
                          currentObj.output = error;
                      });
                  },
            // Fiche de declaration de cautionnement => FDC
            loadFilesFDC() {
                axios.get("/api/FilesFilesFDC/" + this.$route.params.idpfd).then(({ data }) => (this.listeFilesFDC = data.data));
            },

            getFileFDC(file) {
                window.open("/upload/Plaques/FDC/"+file, "_blank");
            },

            JoindreFDC(){
                this.filename ='';
                
                this.DialogVisibleFDC = true;
            },

            submitFDC(e) {
                  this.$Progress.start();
                  e.preventDefault();
                  let currentObj = this;
                  const config = {
                      headers: {
                          "content-type": "multipart/form-data",
                          "X-CSRF-TOKEN": document.querySelector(
                              'meta[name="csrf-token"]'
                          ).content
                      }
                  };
                  // form data
                  let formData = new FormData();
                  formData.append('file', this.file);
                  
                  axios.post("/api/uploadFDC/"+this.$route.params.idpfd, formData, config)
                      
                      .then(response => {
                          // success
                          Toast.fire({
                              icon: "success",
                              title: response.data.message
                          });
                          this.DialogVisibleFDC = false;
                          this.loadFilesFDC();
                          this.$Progress.finish();
                      }
                      )
                      .catch(function(error) {
                          currentObj.output = error;
                      });
                  },
            // End Régime Douanier 22 37

            // Régime Douanier 10
            loadFilesFL() {
                axios.get("/api/FilesFilesFL/" + this.$route.params.idpfd).then(({ data }) => (this.listeFilesFL = data.data));
            },

            getFileFL(file) {
                window.open("/upload/Plaques/FL/"+file, "_blank");
            },

            JoindreFL(){
                this.filename ='';
                
                this.DialogVisibleFL = true;
            },

            submitFL(e) {
                  this.$Progress.start();
                  e.preventDefault();
                  let currentObj = this;
                  const config = {
                      headers: {
                          "content-type": "multipart/form-data",
                          "X-CSRF-TOKEN": document.querySelector(
                              'meta[name="csrf-token"]'
                          ).content
                      }
                  };
                  // form data
                  let formData = new FormData();
                  formData.append('file', this.file);
                  
                  axios.post("/api/uploadFL/"+this.$route.params.idpfd, formData, config)
                      
                      .then(response => {
                          // success
                          Toast.fire({
                              icon: "success",
                              title: response.data.message
                          });
                          this.DialogVisibleFL = false;
                          this.loadFilesFL();
                          this.$Progress.finish();
                      }
                      )
                      .catch(function(error) {
                          currentObj.output = error;
                      });
                  },

            loadFilesEDP() {
                axios.get("/api/FilesFilesEDP/" + this.$route.params.idpfd).then(({ data }) => (this.listeFilesEDP = data.data));
            },

            getFileEDP(file) {
                window.open("/upload/Plaques/EDP/"+file, "_blank");
            },

            JoindreEDP(){
                this.filename ='';
                
                this.DialogVisibleEDP = true;
            },

            submitEDP(e) {
                  this.$Progress.start();
                  e.preventDefault();
                  let currentObj = this;
                  const config = {
                      headers: {
                          "content-type": "multipart/form-data",
                          "X-CSRF-TOKEN": document.querySelector(
                              'meta[name="csrf-token"]'
                          ).content
                      }
                  };
                  // form data
                  let formData = new FormData();
                  formData.append('file', this.file);
                  
                  axios.post("/api/uploadEDP/"+this.$route.params.idpfd, formData, config)
                      
                      .then(response => {
                          // success
                          Toast.fire({
                              icon: "success",
                              title: response.data.message
                          });
                          this.DialogVisibleEDP = false;
                          this.loadFilesEDP();
                          this.$Progress.finish();
                      }
                      )
                      .catch(function(error) {
                          currentObj.output = error;
                      });
                  },
                  
            loadFilesRecuReglement() {
                axios.get("/api/FilesFilesRecuReglement/" + this.$route.params.idpfd).then(({ data }) => (this.listeFilesRecuReglement = data.data));
            },

            getFileRecuReglement(file) {
                window.open("/upload/Plaques/RecuReglement/"+file, "_blank");
            },
// 
            SaisieDroitTaxes(){
                        
                this.form_F_Liquidation.reset();
                this.editmode = false;
                this.DialogVisibleRDroitTaxes = true;
            },

            JoindreRecuReglement(){
                this.filename ='';
                
                this.DialogVisibleRecuReglement = true;
            },

            submitRecuReglement(e) {
                  this.$Progress.start();
                  e.preventDefault();
                  let currentObj = this;
                  const config = {
                      headers: {
                          "content-type": "multipart/form-data",
                          "X-CSRF-TOKEN": document.querySelector(
                              'meta[name="csrf-token"]'
                          ).content
                      }
                  };
                  // form data
                  let formData = new FormData();
                  formData.append('file', this.file);
                  
                  axios.post("/api/uploadRecuReglement/"+this.$route.params.idpfd, formData, config)
                      
                      .then(response => {
                          // success
                          Toast.fire({
                              icon: "success",
                              title: response.data.message
                          });
                          this.DialogVisibleRecuReglement = false;
                          this.loadFilesRecuReglement();
                          this.$Progress.finish();
                      }
                      )
                      .catch(function(error) {
                          currentObj.output = error;
                      });
                  },

            loadFilesMainL() {
                axios.get("/api/FilesFilesMainL/" + this.$route.params.idpfd).then(({ data }) => (this.listeFilesMainL = data.data));
            },

            getFileMainL(file) {
                window.open("/upload/Plaques/MainL/"+file, "_blank");
            },

            JoindreMainL(){
                this.filename ='';
                
                this.DialogVisibleMainL = true;
            },

            submitMainL(e) {
                  this.$Progress.start();
                  e.preventDefault();
                  let currentObj = this;
                  const config = {
                      headers: {
                          "content-type": "multipart/form-data",
                          "X-CSRF-TOKEN": document.querySelector(
                              'meta[name="csrf-token"]'
                          ).content
                      }
                  };
                  // form data
                  let formData = new FormData();
                  formData.append('file', this.file);
                  
                  axios.post("/api/uploadMainL/"+this.$route.params.idpfd, formData, config)
                      
                      .then(response => {
                          // success
                          Toast.fire({
                              icon: "success",
                              title: response.data.message
                          });
                          this.DialogVisibleMainL = false;
                          this.loadFilesMainL();
                          this.$Progress.finish();
                      }
                      )
                      .catch(function(error) {
                          currentObj.output = error;
                      });
                  },

            // End Régime Douanier 10
              
            onFileChange(e) {
                // console.log(e.target.files[0].name);
                this.filename = "FICHIER SÉLECTIONNÉ : " +e.target.files[0].name;
                this.file = e.target.files[0];
            },
  
              submitFPF(e) {
                  this.$Progress.start();
                  e.preventDefault();
                  let currentObj = this;
                  const config = {
                      headers: {
                          "content-type": "multipart/form-data",
                          "X-CSRF-TOKEN": document.querySelector(
                              'meta[name="csrf-token"]'
                          ).content
                      }
                  };
                  // form data
                  let formData = new FormData();
                  formData.append('file', this.file);
                  
                  axios.post("/api/uploadFPF/"+this.$route.params.idpfd, formData, config)
                      
                      .then(response => {
                          // success
                          Toast.fire({
                              icon: "success",
                              title: response.data.message
                          });
                          this.DialogVisibleFPF = false;
                          this.loadFilesFPF();
                          this.$Progress.finish();
                      }
                      )
                      .catch(function(error) {
                          currentObj.output = error;
                      });
                  },

              submitCertificatOrigine(e) {
                  this.$Progress.start();
                  e.preventDefault();
                  let currentObj = this;
                  const config = {
                      headers: {
                          "content-type": "multipart/form-data",
                          "X-CSRF-TOKEN": document.querySelector(
                              'meta[name="csrf-token"]'
                          ).content
                      }
                  };
                  // form data
                  let formData = new FormData();
                  formData.append('file', this.file);
                  
                  axios.post("/api/uploadCertificatOrigine/"+this.$route.params.idpfd, formData, config)
                      
                      .then(response => {
                          // success
                          Toast.fire({
                              icon: "success",
                              title: response.data.message
                          });
                          this.DialogVisibleCertificatOrigine = false;
                          this.loadFilesCertificatOrigine();
                          this.$Progress.finish();
                      }
                      )
                      .catch(function(error) {
                          currentObj.output = error;
                      });
                  },

              submitAcusseR(e) {
                  this.$Progress.start();
                  e.preventDefault();
                  let currentObj = this;
                  const config = {
                      headers: {
                          "content-type": "multipart/form-data",
                          "X-CSRF-TOKEN": document.querySelector(
                              'meta[name="csrf-token"]'
                          ).content
                      }
                  };
                  // form data
                  let formData = new FormData();
                  formData.append('file', this.file);
                  
                  axios.post("/api/fileStoreAccuseR/"+this.$route.params.idpfd, formData, config)
                      
                      .then(response => {
                          // success
                          Toast.fire({
                              icon: "success",
                              title: response.data.message
                          });
                          this.DialogVisibleAccuseR = false;
                          this.loadFilesAccuseR();
                          this.$Progress.finish();
                      }
                      )
                      .catch(function(error) {
                          currentObj.output = error;
                      });
                  },

              submitSWIFT(e) {
                  this.$Progress.start();
                  e.preventDefault();
                  let currentObj = this;
                  const config = {
                      headers: {
                          "content-type": "multipart/form-data",
                          "X-CSRF-TOKEN": document.querySelector(
                              'meta[name="csrf-token"]'
                          ).content
                      }
                  };
                  // form data
                  let formData = new FormData();
                  formData.append('file', this.file);
                  
                  axios.post("/api/fileStoreSWIFT/"+this.$route.params.idpfd, formData, config)
                      
                      .then(response => {
                          // success
                          Toast.fire({
                              icon: "success",
                              title: response.data.message
                          });
                          this.DialogVisibleSWIFT = false;
                          this.loadFilesSWIFT();
                          this.$Progress.finish();
                      }
                      )
                      .catch(function(error) {
                          currentObj.output = error;
                      });
                  },

            submitBonLivraison(e) {
                  this.$Progress.start();
                  e.preventDefault();
                  let currentObj = this;
                  const config = {
                      headers: {
                          "content-type": "multipart/form-data",
                          "X-CSRF-TOKEN": document.querySelector(
                              'meta[name="csrf-token"]'
                          ).content
                      }
                  };
                  // form data
                  let formData = new FormData();
                  formData.append('file', this.file);
                  
                  axios.post("/api/uploadBLP/"+this.$route.params.idpfd, formData, config)
                      
                      .then(response => {
                          // success
                          Toast.fire({
                              icon: "success",
                              title: response.data.message
                          });
                          this.DialogVisibleBonLivraison = false;
                          this.loadFiles();
                          this.$Progress.finish();
                      }
                      )
                      .catch(function(error) {
                          currentObj.output = error;
                      });
                  },
              // End File Bon Livraison
  
               loadTrackingPlaque(page = 1){
                    this.$Progress.start();
                       axios.get('/api/TrackingCmdPlaques?page=' + page).then(({ data }) => (this.TrackingPlaque = data.data))
                    this.$Progress.finish();
              },
              async loadOneDevisPlaque(idDevis){
                    this.$Progress.start();
                      await axios.get("/api/GetOneGlobalDevis/"+idDevis).then(({ data }) => (this.devisPlaque = data.data))
                    this.$Progress.finish();
              },

              loadProFormaPlaque(){
                    this.$Progress.start();
                       axios.get("/api/ProFormaPlaque/"+this.$route.params.idpfd).then(({ data }) => (this.ProFormaPlaque = data.data))
                    this.$Progress.finish();
              },

              async printC(idDevis) {
                  await this.loadOneDevisPlaque(idDevis);
                  this.$htmlToPaper("printMe");
              },

              async printOrdre(idDevis) {
                  await this.loadOneDevisPlaque(idDevis);
                  this.$htmlToPaper("printMeOr");
              },

              printS_DUM() {
                //   await this.loadOneDevisPlaque(idDevis);
                  this.$htmlToPaper("printMeSDUM");
              },
              async printPFP_PFD(idDevis) {
                  await this.loadOneDevisPlaque(idDevis);
                  this.$htmlToPaper("printMePFP");
              },

              async printDSENG_PFD(idDevis) {
                  await this.loadOneDevisPlaque(idDevis);
                  this.$htmlToPaper("printMeDSENG");
              },

              async printDCENG_PFD(idDevis) {
                  await this.loadOneDevisPlaque(idDevis);
                  this.$htmlToPaper("printMeDCENG");
              },

              async printDVENG_PFD(idDevis) {
                  await this.loadOneDevisPlaque(idDevis);
                  this.$htmlToPaper("printMeDVENG");
              },
  
              InvoiceModal() {
                  this.editmode = false;
                  this.form.reset();
                  $("#Invoice").modal("show");
              },

            //   Transport
            Demande_D_Transport(){
                // this.loadProFormaPlaque()

                // this.formDevisTransport.marchandises = [];
                    
                //     for (let i = 0; i < this.ProFormaPlaque.length; i++) {
                        
                //             this.formDevisTransport.marchandises.push({
                //                 id_proForma: this.ProFormaPlaque.id,
                //             })
                //     }
                
                this.editmode = false;
                this.DialogVisibleDevisTransport = true;
            },

            lancerDemandeDTrans(){
                this.formDevisTransport.marchandises = [];
                for (let i = 0; i < this.multipleSelection.length; i++) {
                    this.formDevisTransport.marchandises.push({
                        id_proForma: this.multipleSelection[i].id ,
                    })
                }
            },

            async createDevisTransport (){
                await this.lancerDemandeDTrans()
                console.log("marchandises : "+this.formDevisTransport.marchandises)
                console.log(this.multipleSelection)
                console.log(this.formDevisTransport)
            },
            // End Transport
  
          //   Demande Devis FD
  
              loadFournisseurs(){
                  
                  axios.get("/api/CompteFournisseurs").then(({ data }) => (this.fournisseurs = data.data));
              },
  
              loadDevisPFD(){
                  
                  axios.get('/api/DevisPlaqueGlobal/'+this.$route.params.idpfd).then(({ data }) => (this.devispfd = data.data));
              },

              load_Pro_Format(){
                  axios.get('/api/demandeProFormat/'+this.$route.params.idpfd).then(({ data }) => (this.ProFormatPFD = data.data));
              },

              load_S_DUM(){
                  axios.get('/api/SaisiDUM/'+this.$route.params.idpfd).then(({ data }) => (this.DUM_Saised = data.data));
              },

              load_S_Engagement(){
                  axios.get('/api/demandeEngagement/'+this.$route.params.idpfd).then(({ data }) => (this.DemandeEngagePFD = data.data));
              },

              load_C_Engagement(){
                  axios.get('/api/demandeCEngagement/'+this.$route.params.idpfd).then(({ data }) => (this.DemandeCEngagePFD = data.data));
              },

              load_E_Caution_B(){
                  axios.get('/api/EnregistreCautionB/'+this.$route.params.idpfd).then(({ data }) => (this.ECautionB = data.data));
              },

              load_V_Engagement(){
                  axios.get('/api/demandeVEngagement/'+this.$route.params.idpfd).then(({ data }) => (this.DemandeVEngagePFD = data.data));
              },
  
               async DemandeDevisFD(idDevis){
                await this.loadOneDevisPlaque(idDevis);
                
                    this.form_Prices.plaquePrices = [];
                    
                    for (let i = 0; i < this.devisPlaque.length; i++) {
                        this.form_Prices.fournisseur = this.devisPlaque[i].get_fournisseur.name
                        this.form_Prices.id_global = this.devisPlaque[i].id
                        for (let j = 0; j < this.devisPlaque[i].get_plaques.length; j++) {
                            
                            this.form_Prices.plaquePrices.push({
                                id_plaque: this.devisPlaque[i].get_plaques[j].id,
                                id_projet: this.devisPlaque[i].get_plaques[j].id_projet,
                                nom_etc: this.devisPlaque[i].get_plaques[j].get_e_t_c.nom_etc,
                                prixDevis: this.devisPlaque[i].get_plaques[j].prix_devis,
                            })
                        }
                    }
                    
                  this.editmode = false;
                  this.DialogVisibleDevisPlaques = true;
              },
              
              async DemandeOrdreVirement(idDevis){
                await this.loadOneDevisPlaque(idDevis);
                
                    this.form_bon_cmd.plaqueValidation = [];
                    
                        
                        for (let i = 0; i < this.devisPlaque.length; i++) {
                            this.form_bon_cmd.fournisseur = this.devisPlaque[i].get_fournisseur.name
                            this.form_bon_cmd.id_global = this.devisPlaque[i].id

                            this.form_bon_cmd.montantChiffre = this.devisPlaque[i].montantChiffre
                            this.form_bon_cmd.montantLettre = this.devisPlaque[i].montantLettre
                            this.form_bon_cmd.swift = this.devisPlaque[i].swift
                            for (let j = 0; j < this.devisPlaque[i].get_plaques.length; j++) {
                                
                                this.form_bon_cmd.plaqueValidation.push({
                                    id_plaque: this.devisPlaque[i].get_plaques[j].id,
                                    id_projet: this.devisPlaque[i].get_plaques[j].id_projet,
                                })
                            }
                        }
                //   this.loadFournisseurs();
                //   this.formDevisPlaque.reset();
                  this.editmode = false;
                  this.DialogVisibleValideDevisPlaques = true;
              },

              async DemandeFactureProFormat(idDevis){
                await this.loadOneDevisPlaque(idDevis);
                
                    this.form_d_pro_format.plaqueDemandeProF = [];
                        
                        for (let i = 0; i < this.devisPlaque.length; i++) {
                            this.form_d_pro_format.id_fournisseur = this.devisPlaque[i].get_fournisseur.id
                            this.form_d_pro_format.fournisseur_name = this.devisPlaque[i].get_fournisseur.name
                            this.form_d_pro_format.id_global = this.devisPlaque[i].id

                            this.form_d_pro_format.montantChiffre = this.devisPlaque[i].montantChiffre
                            this.form_d_pro_format.montantLettre = this.devisPlaque[i].montantLettre
                            for (let j = 0; j < this.devisPlaque[i].get_plaques.length; j++) {
                                
                                this.form_d_pro_format.plaqueDemandeProF.push({
                                    id_plaque: this.devisPlaque[i].get_plaques[j].id,
                                    id_projet: this.devisPlaque[i].get_plaques[j].id_projet,
                                })
                            }
                        }
                        console.log(this.form_d_pro_format.id_fournisseur,' , ',this.form_d_pro_format.id_global
                        ,' , ',this.form_d_pro_format.montantChiffre,' , ',this.form_d_pro_format.montantLettre
                        ,' , ',this.form_d_pro_format.plaqueDemandeProF);
                //   this.form_d_pro_format.reset();
                  this.editmode = false;
                  this.DialogVisibleDemandeFactureProFormat = true;
              },

            async DemandeVEngagement(idDevis){
                await this.loadOneDevisPlaque(idDevis);

                this.form_v_enga.plaqueValidation = [];

                for (let i = 0; i < this.devisPlaque.length; i++) {
                    this.form_v_enga.id_fournisseur = this.devisPlaque[i].get_fournisseur.id
                    this.form_v_enga.id_global = this.devisPlaque[i].id
                    // 
                    for (let j = 0; j < this.devisPlaque[i].get_engagement.length; j++) {
                        this.form_v_enga.id_enga = this.devisPlaque[i].get_engagement[j].id
                    }
                    
                    for (let j = 0; j < this.devisPlaque[i].get_plaques.length; j++) {
                        
                        this.form_v_enga.plaqueValidation.push({
                            id_plaque: this.devisPlaque[i].get_plaques[j].id,
                            id_projet: this.devisPlaque[i].get_plaques[j].id_projet,
                        })
                    }
                    
                }
                
                Swal.fire({
                    title: 'Attention !',
                    text: "Vous Voulez Créer Une Demande De Valider Et Domicilier L'engagement D'importation ?",
                    showCancelButton: true,
                    confirmButtonColor: '#67c23a',
                    cancelButtonColor: '#f56c6c',
                    confirmButtonText: 'Oui, Créer-le ! '
                    }).then((result) => {
                       
                          if (result.value) {
                                this.form_v_enga.post('/api/demandeVEngagement').then(()=>{
                                        Swal.fire(
                                        'Valider !',
                                        'Demande De Valider Et Domicilier L\'engagement été Bien Créer.',
                                        'success'
                                        );
                                    this.load_V_Engagement();
                                }).catch((data)=> {
                                    Swal.fire("Failed!", data.message, "warning");
                                });
                          }
                    })
            },

              async DemandeCEngagement(idDevis){
                await this.loadOneDevisPlaque(idDevis);
                
                    this.form_correction_engagement.plaqueDemandeCorrect = [];
                        
                        for (let i = 0; i < this.devisPlaque.length; i++) {
                            this.form_correction_engagement.id_fournisseur = this.devisPlaque[i].get_fournisseur.id
                            this.form_correction_engagement.fournisseur_name = this.devisPlaque[i].get_fournisseur.name
                            this.form_correction_engagement.id_global = this.devisPlaque[i].id

                            
                            for (let j = 0; j < this.devisPlaque[i].get_plaques.length; j++) {
                                
                                this.form_correction_engagement.plaqueDemandeCorrect.push({
                                    id_plaque: this.devisPlaque[i].get_plaques[j].id,
                                    id_projet: this.devisPlaque[i].get_plaques[j].id_projet,
                                })
                            }
                        }
                        
                //   this.form_d_pro_format.reset();
                  this.editmode = false;
                  this.DialogVisibleDemandeCENGAGE = true;
              },

            //   DUM 
            SaisieDumImpo(){
                this.editmode = false;
                this.DialogVisibleSaisiDUM = true;
            },

            Demande_E_DUM(){
                this.editmode = false;
                this.DialogVisible_E_DUM = true;
            },

            Creat_Demande_E_DUM(){
                
                this.$Progress.start();
                    this.form_dum_importation.put('/api/SaisiDUM/'+this.$route.params.idpfd)
                    .then((response) => {
                        
                        this.DialogVisible_E_DUM = false
                        Toast.fire({
                            icon: 'success',
                            title: response.data.message
                        });
                        this.load_S_DUM();
                        
                        this.$Progress.finish();
                    })
                    .catch(() => {
                        this.$Progress.fail();
                });
              },

            CreateDumImportation(){
                this.$Progress.start();
  
                this.form_dum_importation.post('/api/SaisiDUM')
                .then((data)=>{
                  if(data.data.success){
                   this.DialogVisibleSaisiDUM = false
  
                    Toast.fire({
                          icon: 'success',
                          title: data.data.message
                      });
                    this.$Progress.finish();
                  this.load_S_DUM();
  
                  } else {
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
  
                    this.$Progress.failed();
                  }
                })
                .catch(()=>{
  
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
                })
            },


            // ENd DUM

            SaisieFL(){
                        
                  this.form_F_Liquidation.reset();
                  this.editmode = false;
                  this.DialogVisibleFLiquidation = true;
              },

              CreateFicheLiquidation(){
                
                this.$Progress.start();
  
                console.log("fiche liquidation ajouter")
                // this.form_caution_banque.post('/api/EnregistreCautionB')
                // .then((data)=>{
                //   if(data.data.success){
                //    this.DialogVisibleFLiquidation = false
  
                //     Toast.fire({
                //           icon: 'success',
                //           title: data.data.message
                //       });
                //     this.$Progress.finish();
                //   this.load_E_Caution_B();
  
                //   } else {
                //     Toast.fire({
                //         icon: 'error',
                //         title: 'Some error occured! Please try again'
                //     });
  
                //     this.$Progress.failed();
                //   }
                // })
                // .catch(()=>{
  
                //     Toast.fire({
                //         icon: 'error',
                //         title: 'Some error occured! Please try again'
                //     });
                // })
  
            },
            
            

               SaisieCoutionBanque(){
                        
                  this.form_caution_banque.reset();
                  this.editmode = false;
                  this.DialogVisibleCoutionBanque = true;
              },

              CreateEnregistreCautionB(){
                
                this.$Progress.start();
  
                this.form_caution_banque.post('/api/EnregistreCautionB')
                .then((data)=>{
                  if(data.data.success){
                   this.DialogVisibleCoutionBanque = false
  
                    Toast.fire({
                          icon: 'success',
                          title: data.data.message
                      });
                    this.$Progress.finish();
                  this.load_E_Caution_B();
  
                  } else {
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
  
                    this.$Progress.failed();
                  }
                })
                .catch(()=>{
  
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
                })
  
            },
              CreateDemandeCEngagement(){
                
                this.$Progress.start();
  
                this.form_correction_engagement.post('/api/demandeCEngagement')
                .then((data)=>{
                  if(data.data.success){
                   this.DialogVisibleDemandeCENGAGE = false
  
                    Toast.fire({
                          icon: 'success',
                          title: data.data.message
                      });
                    this.$Progress.finish();
                  this.load_C_Engagement();
  
                  } else {
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
  
                    this.$Progress.failed();
                  }
                })
                .catch(()=>{
  
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
                })
  
            },
              CreateDemandeProFormat(){
                
                this.$Progress.start();
  
                this.form_d_pro_format.post('/api/demandeProFormat')
                .then((data)=>{
                  if(data.data.success){
                   this.DialogVisibleDemandeFactureProFormat = false
  
                    Toast.fire({
                          icon: 'success',
                          title: data.data.message
                      });
                    this.$Progress.finish();
                  this.load_Pro_Format();
  
                  } else {
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
  
                    this.$Progress.failed();
                  }
                })
                .catch(()=>{
  
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
                })
  
            },
              async ValiderDevisFD(idDevis){

                await this.loadOneDevisPlaque(idDevis);
                this.form_bon_cmd.plaqueValidation = [];
                    
                for (let i = 0; i < this.devisPlaque.length; i++) {
                    this.form_bon_cmd.fournisseur = this.devisPlaque[i].get_fournisseur.name
                    this.form_bon_cmd.id_global = this.devisPlaque[i].id
                    for (let j = 0; j < this.devisPlaque[i].get_plaques.length; j++) {
                        
                        this.form_bon_cmd.plaqueValidation.push({
                            id_plaque: this.devisPlaque[i].get_plaques[j].id,
                            id_projet: this.devisPlaque[i].get_plaques[j].id_projet,
                        })
                    }
                }
                    
                Swal.fire({
                    title: 'Attention !',
                    text: "vous voulez Valider Ce Devis (PFD) ??",
                    showCancelButton: true,
                    confirmButtonColor: '#67c23a',
                    cancelButtonColor: '#f56c6c',
                    confirmButtonText: 'Oui, Validez-le ! '
                    }).then((result) => {
  
                        // Send request to the server
                          if (result.value) {
                                this.form_bon_cmd.put('/api/validatePlaque').then(()=>{
                                        Swal.fire(
                                        'Valider !',
                                        'Demande De Devis a été Bien Valider.',
                                        'success'
                                        );
                                    this.loadDevisPFD();
                                }).catch((data)=> {
                                    Swal.fire("Failed!", data.message, "warning");
                                });
                          }
                    })

              },

              async DemandeSEngagement(idDevis){

                await this.loadOneDevisPlaque(idDevis);
                this.form_saisie_engagement.engagementSaisi = [];
                    
                for (let i = 0; i < this.devisPlaque.length; i++) {
                    this.form_saisie_engagement.fournisseur = this.devisPlaque[i].get_fournisseur.id
                    this.form_saisie_engagement.id_global = this.devisPlaque[i].id
                    
                    for (let j = 0; j < this.devisPlaque[i].get_plaques.length; j++) {
                        
                        this.form_saisie_engagement.engagementSaisi.push({
                            id_plaque: this.devisPlaque[i].get_plaques[j].id,
                            id_projet: this.devisPlaque[i].get_plaques[j].id_projet,
                        })
                    }
                }
                    
                Swal.fire({
                    title: 'Attention !',
                    text: "Es Que Vous Voulez Génerer Une Demande De Saisir D'engagement D'importation ?",
                    showCancelButton: true,
                    confirmButtonColor: '#67c23a',
                    cancelButtonColor: '#f56c6c',
                    confirmButtonText: 'Oui, Créer-le ! '
                    }).then((result) => {

                        // Send request to the server
                        if (result.value) {
                                this.form_saisie_engagement.post('/api/demandeEngagement').then(()=>{
                                        Swal.fire(
                                        'Valider !',
                                        "Demande De Saisir D'engagement a été Bien Créer.",
                                        'success'
                                        );
                                    this.load_S_Engagement();
                                }).catch((data)=> {
                                    Swal.fire("Failed!", data.message, "warning");
                                });
                        }
                    })

                },
              
              //   update prices and create reduction devis
              createReductionDevisPlaque(){
                
                this.$Progress.start();
                    this.form_Prices.put('/api/updatePricesPlaque')
                    .then((response) => {
                        // this.editmode = false;
                        this.DialogVisibleDevisPlaques = false
                        Toast.fire({
                            icon: 'success',
                            title: response.data.message
                        });
                        this.loadDevisPFD();
                        //   this.loadDevisGlobalR();
                        this.$Progress.finish();
                    })
                    .catch(() => {
                        this.$Progress.fail();
                });
              },
  
              
  
              // 

              validerDevisPlaque(){
                
                this.$Progress.start();
  
                this.form_bon_cmd.put('/api/demandeOrderPlaque')
                .then((data)=>{
                  if(data.data.success){
                   this.DialogVisibleValideDevisPlaques = false
  
                    Toast.fire({
                          icon: 'success',
                          title: data.data.message
                      });
                    this.$Progress.finish();
                  this.loadDevisPFD();
  
                  } else {
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
  
                    this.$Progress.failed();
                  }
                })
                .catch(()=>{
  
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
                })
  
            },

            
  
            deleteTracking(id){
                  Swal.fire({
                    title: 'Attention !',
                    text: "vous voulez vraiment supprimer Ce Lieu ??",
                    showCancelButton: true,
                    confirmButtonColor: '#67c23a',
                    cancelButtonColor: '#f56c6c',
                    confirmButtonText: 'Oui, supprimez-le ! '
                    }).then((result) => {
  
                          if (result.value) {
                                this.form.delete('/api/TrackingCmdPlaques/'+id).then(()=>{
                                        Swal.fire(
                                        'Supprimé !',
                                        'Tracking a été Bien supprimé.',
                                        'success'
                                        );
                                    this.loadTrackingPlaque();
                                }).catch((data)=> {
                                    Swal.fire("Failed!", data.message, "warning");
                                });
                          }
                    })
              },

              deleteDevis(id){

                console.log("deleted")
                //   Swal.fire({
                //     title: 'Attention !',
                //     text: "vous voulez vraiment supprimer ce Devis ??",
                //     showCancelButton: true,
                //     confirmButtonColor: '#67c23a',
                //     cancelButtonColor: '#f56c6c',
                //     confirmButtonText: 'Oui, supprimez-le ! '
                //     }).then((result) => {
                //           if (result.value) {
                //                 this.form.delete('/api/DemandeDevisPlaque/'+id).then(()=>{
                //                         Swal.fire(
                //                         'Supprimé !',
                //                         'Demande De Devis a été Bien supprimé.',
                //                         'success'
                //                         );
                //                     this.loadDevisPFD();
                //                 }).catch((data)=> {
                //                     Swal.fire("Failed!", data.message, "warning");
                //                 });
                //           }
                //     })
              },

              deleteFPFPlaque(id){
                  Swal.fire({
                    title: 'Attention !',
                    text: "Vous Voulez Vraiment Supprimer Cette Facture Pro Format ??",
                    showCancelButton: true,
                    confirmButtonColor: '#67c23a',
                    cancelButtonColor: '#f56c6c',
                    confirmButtonText: 'Oui, supprimez-le ! '
                    }).then((result) => {
  
                        // Send request to the server
                          if (result.value) {
                                this.form.delete('/api/FilesBonLivraisonPlaques/'+id).then(()=>{
                                        Swal.fire(
                                        'Supprimé !',
                                        'PDF Facture Pro Format a été Bien supprimé.',
                                        'success'
                                        );
                                    this.loadFilesFPF();
                                }).catch((data)=> {
                                    Swal.fire("Failed!", data.message, "warning");
                                });
                          }
                    })
              },

              deleteS_DUM(id){
                  Swal.fire({
                    title: 'Attention !',
                    text: "Vous Voulez Vraiment Supprimer La DUM ??",
                    showCancelButton: true,
                    confirmButtonColor: '#67c23a',
                    cancelButtonColor: '#f56c6c',
                    confirmButtonText: 'Oui, supprimez-le ! '
                    }).then((result) => {
  
                        // Send request to the server
                          if (result.value) {
                                this.form.delete('/api/SaisiDUM/'+id).then(()=>{
                                        Swal.fire(
                                        'Supprimé !',
                                        'La Saisie De DUM a été Bien supprimé.',
                                        'success'
                                        );
                                    this.load_S_DUM();
                                }).catch((data)=> {
                                    Swal.fire("Failed!", data.message, "warning");
                                });
                          }
                    })
              },

              UpdateDCENG(product){
                
                    // this.editmode = true;
                    this.form_correction_engagement.reset();
                    // $('#addNew').modal('show');
                    for (let j = 0; j < this.DemandeCEngagePFD.length; j++) {
                        this.form_correction_engagement.nom_F_Pro = this.DemandeCEngagePFD[j].nom_F_Pro
                        this.form_correction_engagement.nom_F_Enga = this.DemandeCEngagePFD[j].nom_F_Enga
                        this.form_correction_engagement.nom_F_Etat = this.DemandeCEngagePFD[j].nom_F_Etat
                        
                        this.form_correction_engagement.bureau_D_Pro = this.DemandeCEngagePFD[j].bureau_D_Pro
                        this.form_correction_engagement.bureau_D_Enga = this.DemandeCEngagePFD[j].bureau_D_Enga
                        this.form_correction_engagement.bureau_D_Etat = this.DemandeCEngagePFD[j].bureau_D_Etat
                        
                        this.form_correction_engagement.regime_D_Pro = this.DemandeCEngagePFD[j].regime_D_Pro
                        this.form_correction_engagement.regime_D_Enga = this.DemandeCEngagePFD[j].regime_D_Enga
                        this.form_correction_engagement.regime_D_Etat = this.DemandeCEngagePFD[j].regime_D_Etat
                        
                        this.form_correction_engagement.transport_Pro = this.DemandeCEngagePFD[j].transport_Pro
                        this.form_correction_engagement.transport_Enga = this.DemandeCEngagePFD[j].transport_Enga
                        this.form_correction_engagement.transport_Etat = this.DemandeCEngagePFD[j].transport_Etat
                        
                        this.form_correction_engagement.designation_Pro = this.DemandeCEngagePFD[j].designation_Pro
                        this.form_correction_engagement.designation_Enga = this.DemandeCEngagePFD[j].designation_Enga
                        this.form_correction_engagement.designation_Etat = this.DemandeCEngagePFD[j].designation_Etat
                        
                        this.form_correction_engagement.qtit_Pro = this.DemandeCEngagePFD[j].qtit_Pro
                        this.form_correction_engagement.qtit_Enga = this.DemandeCEngagePFD[j].qtit_Enga
                        this.form_correction_engagement.qtit_Etat = this.DemandeCEngagePFD[j].qtit_Etat
                        
                        this.form_correction_engagement.nomenclature_Pro = this.DemandeCEngagePFD[j].nomenclature_Pro
                        this.form_correction_engagement.nomenclature_Enga = this.DemandeCEngagePFD[j].nomenclature_Enga
                        this.form_correction_engagement.nomenclature_Etat = this.DemandeCEngagePFD[j].nomenclature_Etat
                        
                        this.form_correction_engagement.P_NET_Pro = this.DemandeCEngagePFD[j].P_NET_Pro
                        this.form_correction_engagement.P_NET_Enga = this.DemandeCEngagePFD[j].P_NET_Enga
                        this.form_correction_engagement.P_NET_Etat = this.DemandeCEngagePFD[j].P_NET_Etat
                        
                        this.form_correction_engagement.P_BRUT_Pro = this.DemandeCEngagePFD[j].P_BRUT_Pro
                        this.form_correction_engagement.P_BRUT_Enga = this.DemandeCEngagePFD[j].P_BRUT_Enga
                        this.form_correction_engagement.P_BRUT_Etat = this.DemandeCEngagePFD[j].P_BRUT_Etat
                        
                        this.form_correction_engagement.devis_Pro = this.DemandeCEngagePFD[j].devis_Pro
                        this.form_correction_engagement.devis_Enga = this.DemandeCEngagePFD[j].devis_Enga
                        this.form_correction_engagement.devis_Etat = this.DemandeCEngagePFD[j].devis_Etat
                        
                        this.form_correction_engagement.pays_Pro = this.DemandeCEngagePFD[j].pays_Pro
                        this.form_correction_engagement.pays_Enga = this.DemandeCEngagePFD[j].pays_Enga
                        this.form_correction_engagement.pays_Etat = this.DemandeCEngagePFD[j].pays_Etat
                        
                        this.form_correction_engagement.incoterm_Pro = this.DemandeCEngagePFD[j].incoterm_Pro
                        this.form_correction_engagement.incoterm_Enga = this.DemandeCEngagePFD[j].incoterm_Enga
                        this.form_correction_engagement.incoterm_Etat = this.DemandeCEngagePFD[j].incoterm_Etat
                        
                        this.form_correction_engagement.fret_Pro = this.DemandeCEngagePFD[j].fret_Pro
                        this.form_correction_engagement.fret_Enga = this.DemandeCEngagePFD[j].fret_Enga
                        this.form_correction_engagement.fret_Etat = this.DemandeCEngagePFD[j].fret_Etat
                    }
                    // 
                    this.DialogVisibleDemandeCENGAGE = true;
                },

                

                deleteDDENG(id){
                  Swal.fire({
                    title: 'Attention !',
                    text: "vous voulez vraiment supprimer Cette Demande De Saisir D'engagement ?",
                    showCancelButton: true,
                    confirmButtonColor: '#67c23a',
                    cancelButtonColor: '#f56c6c',
                    confirmButtonText: 'Oui, supprimez-le ! '
                    }).then((result) => {
  
                        // Send request to the server
                          if (result.value) {
                                this.form.delete('/api/demandeEngagement/'+id).then(()=>{
                                        Swal.fire(
                                        'Supprimé !',
                                        'Demande De Saisir d\'engagement a été Bien supprimé.',
                                        'success'
                                        );
                                    this.load_S_Engagement();
                                }).catch((data)=> {
                                    Swal.fire("Failed!", data.message, "warning");
                                });
                          }
                    })
              },
              deleteDCENG(id){
                  Swal.fire({
                    title: 'Attention !',
                    text: "vous voulez vraiment supprimer Cette Demande De Correction ?",
                    showCancelButton: true,
                    confirmButtonColor: '#67c23a',
                    cancelButtonColor: '#f56c6c',
                    confirmButtonText: 'Oui, supprimez-le ! '
                    }).then((result) => {
  
                        // Send request to the server
                          if (result.value) {
                                this.form.delete('/api/demandeCEngagement/'+id).then(()=>{
                                        Swal.fire(
                                        'Supprimé !',
                                        'Demande De Correction a été Bien supprimé.',
                                        'success'
                                        );
                                    this.load_C_Engagement();
                                }).catch((data)=> {
                                    Swal.fire("Failed!", data.message, "warning");
                                });
                          }
                    })
              },

              deleteDVENG(id){
                
                  Swal.fire({
                    title: 'Attention !',
                    text: "vous voulez vraiment supprimer Cette Demande De Validation D'engagement D'importation ?",
                    showCancelButton: true,
                    confirmButtonColor: '#67c23a',
                    cancelButtonColor: '#f56c6c',
                    confirmButtonText: 'Oui, supprimez-le ! '
                    }).then((result) => {
  
                          if (result.value) {
                                this.form.delete('/api/demandeVEngagement/'+id).then(()=>{
                                        Swal.fire(
                                        'Supprimé !',
                                        'Demande De Validation a été Bien supprimé.',
                                        'success'
                                        );
                                    this.load_V_Engagement();
                                }).catch((data)=> {
                                    Swal.fire("Failed!", data.message, "warning");
                                });
                          }
                    })
              },

              deleteBLPlaque(id){
                  Swal.fire({
                    title: 'Attention !',
                    text: "vous voulez vraiment supprimer ce bon de livraison ??",
                    showCancelButton: true,
                    confirmButtonColor: '#67c23a',
                    cancelButtonColor: '#f56c6c',
                    confirmButtonText: 'Oui, supprimez-le ! '
                    }).then((result) => {
  
                        // Send request to the server
                          if (result.value) {
                                this.form.delete('/api/FilesBonLivraisonPlaques/'+id).then(()=>{
                                        Swal.fire(
                                        'Supprimé !',
                                        'Bon De Livraison a été Bien supprimé.',
                                        'success'
                                        );
                                        this.loadFiles();
                                }).catch((data)=> {
                                    Swal.fire("Failed!", data.message, "warning");
                                });
                          }
                    })
              },

            
  
              createDevisPlaque(){
   
              this.$Progress.start();
  
                this.formDevisPlaque.post('/api/DemandeDevisPlaque')
                .then((data)=>{
                  if(data.data.success){
                   this.DialogVisibleDevisPlaques = false
  
                    Toast.fire({
                          icon: 'success',
                          title: data.data.message
                      });
                    this.$Progress.finish();
                  this.loadDevisPFD();
  
                  } else {
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
  
                    this.$Progress.failed();
                  }
                })
                .catch(()=>{
  
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
                })
                  
              },
  
              // Plaque
              laizePapierCalculer(){
                  this.form.laize_papier_mm = (this.form.laize_mm * this.form.nbrPoseLaize)+3*(this.form.nbrPoseLaize-1)+10
              },
  
              totalPosePlaqueCalculer(){
                  this.form.nbrPoseSurPlaq = (this.form.nbrPoseDevlop * this.form.nbrPoseLaize)
              },
  
              newPlaque(){
                  this.form.reset();
                  this.editmode = false;
                  this.DialogVisiblePlaques = true;
              },
  
              changeSelectMachine () {
                 
                   switch (this.form.machine) {
                       case 'GIDUE-370':
                           this.Zcylindre = this.Machine1;
                           
                           break;
                       case 'NILPITER-B200':
                           this.Zcylindre = this.Machine2;
                          
                           break;
                       case 'ROTOFLEX-400':
                           this.Zcylindre = this.Machine3;
                           
                           break;
                       }
               },
  
               createPlaque(){
                  this.$Progress.start();
  
                  this.form.post('/api/PlaqueDecoupe')
                  .then((data)=>{
                      if(data.data.success){
                      this.DialogVisiblePlaques = false
  
                      Toast.fire({
                              icon: 'success',
                              title: data.data.message
                          });
                      this.$Progress.finish();
                      //   this.loadDevisGlobal();
                      //   this.loadDataPrepared();
  
                      } else {
                      Toast.fire({
                          icon: 'error',
                          title: 'Some error occured! Please try again'
                      });
  
                      this.$Progress.failed();
                      }
                  })
                  .catch(()=>{
  
                      Toast.fire({
                          icon: 'error',
                          title: 'Some error occured! Please try again'
                      });
                  })
              },
              // End Plaque
  
              getTypeSense(){
                  for (let i = 0; i < this.Projet.length; i++) {
                      this.form.type_sense_sortie = this.Projet[i].type_sense_sortie;
                  }
              },
  
              handleClose(done) {
                  this.$confirm('Voulez-vous vraiment fermer cette boîte de dialogue ?')
                  .then(_ => {
                      done();
                  })
                  .catch(_ => {});
              },
  
      // Crud Profile
          async loadProjet(){
              this.$Progress.start();
              await axios.get("/api/GetOneProject/"+this.$route.params.idpfd).then(({ data }) => (this.Projet = data.data))
              for (let i = 0; i < this.Projet.length; i++) {
                  
                  this.form2.validation1 = this.Projet[i].validationPFD
              }
             
              this.$Progress.finish();
              
          },
  
          async getValidations(){
  
              await this.loadProjet();
              
          },
  
  
          loadCouleurs(){
                axios.get("/api/GetDevisCouleurs/"+this.$route.params.idpfd).then(({ data }) => (this.Couleurs = data.data));
          },
  
          // update Forme de decoupe
           onChangeFormDecoupe(file, fileList) {
                  this.file = fileList.slice(-1);
                  this.etape='FormDecoupe'
              },
           onChangeConception(file, fileList) {
                  this.file = fileList.slice(-1);
                  this.etape='Conception'
              },
           onChangeBAT(file, fileList) {
                  this.file = fileList.slice(-1);
                  this.etape='BAT'
              },
  
          submitForm(e) {
              this.$Progress.start();
              e.preventDefault();
              let currentObj = this;
              const config = {
                  headers: {
                      "content-type": "multipart/form-data",
                      "X-CSRF-TOKEN": document.querySelector(
                          'meta[name="csrf-token"]'
                      ).content
                  }
              };
              // form data
              let formData = new FormData();
              formData.append('file', this.file[0].raw);
              formData.append("etape", this.etape);
              // send upload request
              
              axios.post("/api/uploadFormeD/"+this.$route.params.idpfd, formData, config)
                  
                  .then(response => {
                      // success
                      Toast.fire({
                          icon: "success",
                          title: response.data.message
                      });
                      this.file = [];
                      this.$Progress.finish();
                      
                      this.loadFiles();
                  }
                  )
                  .catch(function(error) {
                      currentObj.output = error;
                  });
          },
  
          ValidationEtapes(etape){
                this.$Progress.start();
                this.ValidationForm.etape= etape;
  
                this.ValidationForm.put('/api/ValidateEtaps/'+this.$route.params.idpfd)
                .then((response) => {
                    Toast.fire({
                      icon: 'success',
                      title: response.data.message
                    });
                    this.$Progress.finish();
                    this.ValidationForm.etape= '';
  
                })
                .catch(() => {
                    this.$Progress.fail();
                });
  
            },
  
          
      // end CRUD
  
      // CRUD remarque
      loadRemarques(){
                  this.$Progress.start();
                      axios.get("/api/ProjetRemarques/"+this.$route.params.idpfd).then(({ data }) => (this.Remarques = data.data));
                  this.$Progress.finish();
              },
  
      SendRemarque(etape){
              this.$Progress.start();
              this.Remarquesform.etape = etape;
  
                this.Remarquesform.post('/api/ProjetRemarques')
                .then((data)=>{
                  if(data.data.success){
                  //  this.DialogVisible = false
  
                    Toast.fire({
                          icon: 'success',
                          title: data.data.message
                      });
                    this.$Progress.finish();
                    this.Remarquesform.etape = '';
                    this.Remarquesform.message = '';
                    this.loadRemarques();
  
                  } else {
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
  
                    this.$Progress.failed();
                  }
                })
                .catch(()=>{
  
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
                })
            },
  
            deletRemarque(id){
                Swal.fire({
                    title: 'Attention !',
                    text: "vous voulez vraiment supprimer cette Remarque",
                    showCancelButton: true,
                    confirmButtonColor: '#67c23a',
                    cancelButtonColor: '#f56c6c',
                    confirmButtonText: 'Oui, supprimez-le ! '
                    }).then((result) => {
  
                        // Send request to the server
                          if (result.value) {
                                this.form.delete('/api/ProjetRemarques/'+id).then(()=>{
                                        Swal.fire(
                                        'Supprimé !',
                                        'Votre Remarque a été supprimé.',
                                        'success'
                                        );
                                    this.loadRemarques();
                                }).catch((data)=> {
                                    Swal.fire("Failed!", data.message, "warning");
                                });
                          }
                    })
            },
      // End CRUD Remarque 
      // CRUD MAGASIN
  
  
                  loadMagasin(){
                  
                      axios.get("/api/MagasinProduction/"+this.$route.params.idpfd).then(({ data }) => (this.BobineMagasin = data.data));
                  },
  
                  loadStockBobines(){
                  
                      axios.get("/api/Bobines").then(({ data }) => (this.Bobines = data.data));
                  },
  
                  newModalMagasin(){
                      this.editmode = false;
                      this.formMagasin.reset();
                      this.dialogVisibleMagasin = true;
                  },
  
                  createMagasin(){
                      this.$Progress.start();
  
                      this.formMagasin.post('/api/MagasinProduction')
                      .then((data)=>{
                          if(data.data.success){
                          this.dialogVisibleMagasin = false;
                          
                          Toast.fire({
                                  icon: 'success',
                                  title: data.data.message,
                              });
                              this.$Progress.finish();
                              this.loadMagasin();
  
                          } else {
                          Toast.fire({
                              icon: 'error',
                              title: 'Some error occured! Please try again',
                          });
  
                          this.$Progress.failed();
                          }
                      })
                      .catch(()=>{
  
                          Toast.fire({
                              icon: 'error',
                              title: 'Some error occured! Please try again',
                          });
                      })
                  },
  
                  deletMagasin(id){
                      Swal.fire({
                          title: 'Attention !',
                          text: "vous voulez vraiment supprimer cette Bobine ??",
                          showCancelButton: true,
                          confirmButtonColor: '#67c23a',
                          cancelButtonColor: '#f56c6c',
                          confirmButtonText: 'Oui, supprimez-le ! '
                          }).then((result) => {
  
                              // Send request to the server
                                  if (result.value) {
                                      this.form.delete('/api/MagasinProduction/'+id).then(()=>{
                                              Swal.fire(
                                              'Supprimé !',
                                              'Votre Bobine a été bien supprimé.',
                                              'success'
                                              );
                                          // Fire.$emit('AfterCreate');
                                          this.loadMagasin();
                                      }).catch((data)=> {
                                          Swal.fire("Failed!", data.message, "warning");
                                      });
                                  }
                          })
                  },
  
                  
  // END CRUD MAGASIN
  
              // timer
              countToTen() {
              this.started = true
              // if (this.result < 10)
                  this.timeout1 = setTimeout(() => {
                  this.result1 += 1
                  this.countToTen();
                  }, 1000)
              },
  
              stopCountToTen() {
                  this.formProduction.productionTime = this.result1
                  clearTimeout(this.timeout1)
                  this.started = false
                  this.result1 = 0
              },
  
              // qrCode
              // async onInit (promise) {
              //     try {
              //         await promise
              //     } catch (e) {
              //         console.error(e)
              //     } finally {
              //         this.showScanConfirmation = this.camera === "off"
              //     }
              //     },
  
                  playSound () {
                      var audio = new Audio(this.soundBeep);
                      audio.play();
                  },
  
                  async onDecode (content) {
                  this.formMagasin.num_Bobine = content
                  this.playSound()
                  this.pause()
                  await this.timeout(500)
                  Toast.fire({
                          icon: 'success',
                          title: 'Bobine Code Bien Scanner'
                      });
                  this.unpause()
                  },
  
                  // unpause () {
                  // this.camera = 'auto'
                  // },
  
                  // pause () {
                  // this.camera = 'off'
                  // },
  
                  timeout (ms) {
                  return new Promise(resolve => {
                      window.setTimeout(resolve, ms)
                  })
              },
  
              // 
  
  
            
            loadCategories(){
                axios.get("/api/category/list").then(({ data }) => (this.categories = data.data));
            },
            loadTags(){
                axios.get("/api/tag/list").then(response => {
                    this.autocompleteItems = response.data.data.map(a => {
                        return { text: a.name, id: a.id };
                    });
                }).catch(() => console.warn('Oh. Something went wrong'));
            },
            editModal(product){
                this.editmode = true;
                this.form.reset();
                $('#addNew').modal('show');
                this.form.fill(product);
            },
            newModal(){
                this.editmode = false;
                this.form.reset();
                $('#addNew').modal('show');
            },
             
            newModalProduction(){
                this.editmode = false;
                this.formProduction.reset();
                $('#addNewProduction').modal('show');
            },
            newModalProduction2(){
                this.editmode = false;
                this.formProduction.reset();
                $('#addNewProduction2').modal('show');
            },
            newModalP(){
                this.editmode = false;
                this.form.reset();
                $('#addNewP').modal('show');
            },
            createProduct(){
                this.$Progress.start();
  
                this.form.post('api/product')
                .then((data)=>{
                  if(data.data.success){
                    $('#addNew').modal('hide');
  
                    Toast.fire({
                          icon: 'success',
                          title: data.data.message
                      });
                    this.$Progress.finish();
                    this.loadProjet();
  
                  } else {
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
  
                    this.$Progress.failed();
                  }
                })
                .catch(()=>{
  
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
                })
            },
            updateProduct(){
                this.$Progress.start();
                this.form.put('api/product/'+this.form.id)
                .then((response) => {
                    // success
                    $('#addNew').modal('hide');
                    Toast.fire({
                      icon: 'success',
                      title: response.data.message
                    });
                    this.$Progress.finish();
                        //  Fire.$emit('AfterCreate');
  
                    this.loadProjet();
                })
                .catch(() => {
                    this.$Progress.fail();
                });
  
            },
            ConfermReceptionModal(id){
                Swal.fire({
                    title: 'Confirmation !',
                    text: "Confirmer la réception de form de découpe et cliché",
                    showCancelButton: true,
                    confirmButtonColor: '#67c23a',
                    cancelButtonColor: '#f56c6c',
                    confirmButtonText: 'Oui, Confirmé ! '
                    }).then((result) => {
  
                        // Send request to the server
                          if (result.value) {
                                this.form.delete('api/product/'+id).then(()=>{
                                        Swal.fire(
                                        'Confirmé !',
                                        'Confirmation Valider',
                                        'success'
                                        );
                                    // Fire.$emit('AfterCreate');
                                    this.loadProjet();
                                }).catch((data)=> {
                                    Swal.fire("Failed!", data.message, "warning");
                                });
                          }
                    })
            },
            deletModal(id){
                Swal.fire({
                    title: 'Attention !',
                    text: "vous voulez vraiment supprimer ce devis",
                    showCancelButton: true,
                    confirmButtonColor: '#67c23a',
                    cancelButtonColor: '#f56c6c',
                    confirmButtonText: 'Oui, supprimez-le ! '
                    }).then((result) => {
  
                        // Send request to the server
                          if (result.value) {
                                this.form.delete('api/product/'+id).then(()=>{
                                        Swal.fire(
                                        'Supprimé !',
                                        'Votre fichier a été supprimé.',
                                        'success'
                                        );
                                    // Fire.$emit('AfterCreate');
                                    this.loadProjet();
                                }).catch((data)=> {
                                    Swal.fire("Failed!", data.message, "warning");
                                });
                          }
                    })
            },
          //   check existe
          checkexist() {
              let x = '';
              this.plaqueFormat.forEach((value, index) => {
                  if(this.form.formL == value.l && this.form.formD == value.d){
                      x = value.num
                  }
              });
  
              if(x != ''){
                  this.form.formCode = x;
              }else{
                   this.form.formCode = 'cette plaque  n\'existe pas !!';
              }
  
          },
  
  // FicheTechnique
              async printC1 () {
              await this.$htmlToPaper('printMe');
              },
  
          FicheTechniqueModal() {
              this.editmode = false;
              this.form.reset();
              $("#FicheTechnique").modal("show");
          },
  // End FicheTechnique
  // Ordre Fabric
          printO() {
                  this.$htmlToPaper("printMeO");
              },
  
               OrdreFabricationModal() {
              this.editmode = false;
              this.form.reset();
              $("#OrdreFabrication").modal("show");
          },
  // ENd Ordre Fabric
  // qrcodes conditionement
          printCondi() {
              this.$htmlToPaper("printMeCondi");
          },
  
          CondiModal() {
              this.editmode = false;
              this.form.reset();
              $("#ModalCondi").modal("show");
          },
  
  // end conditionement
  
  // Upload Image
            submitUpload() {
              this.$refs.upload.submit();
          },
  
          handleCurrentChange(cpage) {
                  this.currpage = cpage;
              },
  
          handleSizeChange(psize) {
                  this.pagesize = psize;
              },
  
  
          },
          mounted() {
  
          },
          created() {
              this.$Progress.start();
  
              this.getValidations();
              this.loadOneDevisPlaque(this.$route.params.idpfd);
              this.loadDevisPFD();
              this.load_V_Engagement();
              this.loadFiles();
              this.loadProFormaPlaque();
              this.loadFilesFPF();
              this.loadFilesAccuseR();
              this.loadFilesSWIFT();
              this.loadFilesFDT();
              this.loadFilesADC();
              this.loadFilesFDC();
              this.loadFilesCertificatOrigine();
              this.loadFilesEDP();
              this.loadFilesFL();
              this.loadFilesRecuReglement();
              this.loadFilesMainL();
              this.loadTrackingPlaque();
              this.load_Pro_Format();
              this.load_S_Engagement();
              this.load_C_Engagement();
              this.load_E_Caution_B();
              this.load_S_DUM();
              this.loadCouleurs();
              this.loadRemarques();
  
              this.$Progress.finish();
          },
          filters: {
              truncate: function (text, length, suffix) {
                  return text.substring(0, length) + suffix;
              },
              // 
              minutesAndSeconds (value) {
                  var minutes = Math.floor(parseInt(value, 10) / 60)
                  var seconds = parseInt(value, 10) - minutes * 60
                  return `${minutes} minutes : ${seconds} seconds`
              },
              // 
              RemarqueDates(value){
                  return moment(String(value)).format('MM/DD/YYYY HH:mm')
              }
          },
          computed: {
            
          },
      }
  </script>
  <style>
  .el-input.is-disabled .el-input__inner{
    color: black !important;
    text-align:center;
  }
  .scan-confirmation {
    position: absolute;
    width: 100%;
    height: 100%;
  
    background-color: rgba(255, 255, 255, .8);
  
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  /* show files PDF Style */
  .card-box {
      padding: 20px;
      border-radius: 3px;
      margin-bottom: 30px;
      background-color: #fff;
  }
  
  .file-man-box {
      /* padding: 20px; */
      border: 1px solid #e3eaef;
      border-radius: 5px;
      position: relative;
      margin-bottom: 20px
  }
  
  .file-man-box .file-close {
      color: #f1556c;
      position: absolute;
      line-height: 24px;
      font-size: 24px;
      right: 10px;
      top: 10px;
      visibility: hidden
  }

  .file-man-box .file-update {
      color: #5593f1;
      position: absolute;
      line-height: 24px;
      font-size: 22px;
      right: 10px;
      top: 40px;
      visibility: hidden
  }
  
  .file-man-box .file-img-box {
      line-height: 120px;
      text-align: center
  }
  
  .file-man-box .file-img-box img {
      height: 64px
  }
  
  .file-man-box .file-download {
      font-size: 32px;
      color: #98a6ad;
      position: absolute;
      right: 10px
  }
  
  .file-man-box .file-download:hover {
      color: #313a46
  }
  
  .file-man-box .file-man-title {
      padding-right: 25px
  }
  
  .file-man-box:hover {
      -webkit-box-shadow: 0 0 24px 0 rgba(0, 0, 0, .06), 0 1px 0 0 rgba(0, 0, 0, .02);
      box-shadow: 0 0 24px 0 rgba(0, 0, 0, .06), 0 1px 0 0 rgba(0, 0, 0, .02)
  }
  
  .file-man-box:hover .file-close {
      visibility: visible
  }
  .file-man-box:hover .file-update {
      visibility: visible
  }
  .text-overflow {
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
      width: 100%;
      overflow: hidden;
  }
  h5 {
      font-size: 15px;
  }
  </style>