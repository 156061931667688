<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row mt-3">
            <div class="col-12 mt-4">
                <el-card  class="box-card" v-for="c in Commande" :key='c.id'>
                    <div slot="header" class="clearfix">
                        <span><strong>N° De Commande: </strong> {{c.id}}</span>
                    </div>
                    <el-descriptions border>
                        <el-descriptions-item><template slot="label"><strong>Fournisseur :</strong></template> {{c.get_fournisseur.name}}</el-descriptions-item>
                        <el-descriptions-item ><template slot="label"><strong>Pays de Provenance :</strong></template> {{c.pays_provonance}} </el-descriptions-item>
                        <el-descriptions-item ><template slot="label"><strong>Regime :</strong></template> {{c.regime}} </el-descriptions-item>
                        <el-descriptions-item ><template slot="label"><strong>Date de Chargement :</strong></template> {{c.date_chargement}} </el-descriptions-item>
                        
                        <el-descriptions-item ><template slot="label"><strong>Incoterm :</strong></template> {{c.incoterm}}</el-descriptions-item>
                        <el-descriptions-item ><template slot="label"><strong>Montant en devise :</strong></template>{{c.montant}} </el-descriptions-item>
                        <el-descriptions-item ><template slot="label"><strong>N° d'enregistrement :</strong></template> {{c.num_enregistrement}} </el-descriptions-item>
                        <el-descriptions-item ><template slot="label"><strong>Date d'enregistrement :</strong></template> {{c.date_enregistrement}} </el-descriptions-item>
                    </el-descriptions>
                </el-card>
            </div>
            <div class="col-12 mt-4 mb-4">
                <el-tabs  tab-position="top" style="margin-bottom: 30px;">
                        <el-tab-pane label="FACTURE PRO FORMA">
                            <!-- Facture PRO Forma  -->
                                    <el-card class="box-card">
                                        <div slot="header" class="clearfix text-center">
                                            <h4><strong>FACTURE PRO FORMA (s'il y'a l'avance) (OU DEFINITIVE)</strong></h4>
                                        </div>
                                        
                                        <div class="row">
                                            <fieldset class="border p-2 col-12">
                                                    <legend class="w-auto badge"><h6><strong> Facture </strong></h6></legend>
                                                    
                                                    
                                                    <el-descriptions class="margin-top mb-2" border :column="2">
                                                        <el-descriptions-item><template slot="label"><strong>N° Facture Proformat :</strong></template> -------------- </el-descriptions-item>
                                                        <el-descriptions-item><template slot="label"><strong>Nature de transport :</strong></template> -------------- </el-descriptions-item>
                                                        <el-descriptions-item><template slot="label"><strong>L'incoterm :</strong></template> -------------- </el-descriptions-item>
                                                        <el-descriptions-item><template slot="label"><strong>Certificat d'origine :</strong></template> -------------- </el-descriptions-item>
                                                        <el-descriptions-item><template slot="label"><strong>Autorisation de l'etat :</strong></template> -------------- </el-descriptions-item>
                                                    </el-descriptions>
                                                    <el-button type="primary" size="small" icon="el-icon-plus" @click="dialogVisible = true" plain>Saisie La facture Proformat</el-button>
                                                    
                                            </fieldset>
                                            <fieldset class="border p-2 col-12">
                                                    <legend class="w-auto badge"><h6><strong>Joindre la facture</strong></h6></legend>
                                                <fieldset class="border p-2 mt-2">
                                                    <legend class="w-auto badge"><h6><strong> </strong></h6></legend>
                                                        <el-upload
                                                            class="upload-demo"
                                                            ref="upload"
                                                            action="https://jsonplaceholder.typicode.com/posts/"
                                                            :auto-upload="false">
                                                            <el-button slot="trigger" size="small" type="primary">Select Fichier</el-button>
                                                            <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">upload to server</el-button>
                                                            <div class="el-upload__tip" slot="tip">pdf files</div>
                                                        </el-upload>
                                                </fieldset>
                                            </fieldset>
                                            <fieldset class="border p-2 col-lg-12 mt-3" v-for="c in Commande" :key="c.id">
                                                    <legend class="w-auto badge"><h5> Liste des articles </h5></legend>
                                                <el-table
                                                    :data="c.get_articles"
                                                    style="width: 100%">
                                                    <el-table-column
                                                    fixed
                                                    prop="code_article"
                                                    label="Code Article"
                                                    >
                                                    </el-table-column>
                                                    <el-table-column
                                                    prop="Nomenclature"
                                                    label="Nomenclature"
                                                    >
                                                    </el-table-column>
                                                    <el-table-column
                                                    prop="num_bobines"
                                                    label="Nombre Bobine"
                                                    >
                                                    </el-table-column>
                                                    <el-table-column
                                                    prop="laize"
                                                    label="Laize En(mm)"
                                                    >
                                                    </el-table-column>
                                                    <el-table-column
                                                    prop="quantite_ml"
                                                    label="Quantite en ML"
                                                    >
                                                    </el-table-column>
                                                    <el-table-column
                                                    prop="quantite_m2"
                                                    label="Quantite en M²"
                                                    >
                                                    </el-table-column>
                                                </el-table>
                                            </fieldset>
                                            
                                        </div>
                                    </el-card>
                        </el-tab-pane>

                        <el-tab-pane label="L'ENGAGEMENT">
                            <!-- L'ENGAGEMENT -->
                                <div class="col-12 mt-4">
                                    <el-card class="box-card">
                                        <div slot="header" class="clearfix text-center">
                                            <h4><strong>L'ENGAGEMENT</strong></h4>
                                        </div>
                                        <div class="row"> 
                                            <fieldset class="border p-2 col-12">
                                                    <legend class="w-auto badge"><h6><strong> ENGAGEMENT ENREGISTRE N° .... DE ... </strong></h6></legend>
                                                    
                                                    <fieldset class="border p-2">
                                                            <legend class="w-auto badge"><h6><strong> </strong></h6></legend>

                                                            <el-upload
                                                                class="upload-demo"
                                                                ref="upload"
                                                                action=""
                                                                :auto-upload="false">
                                                                <el-button slot="trigger" size="small" type="primary">Select Fichier</el-button>
                                                                <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">upload to server</el-button>
                                                                <div class="el-upload__tip" slot="tip">pdf files</div>
                                                            </el-upload>

                                                    </fieldset>
                                            </fieldset>        
                                            
                                            <fieldset class="border p-2 col-12">
                                                <legend class="w-auto badge"><h6><strong>Saisie l'engaement</strong></h6></legend>

                                                <el-form :model="form" ref="form">
                                                    <el-form-item 
                                                        label="Saisie le Numéro"
                                                        prop="numero"
                                                        :rules="{
                                                            required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                                        }"
                                                        >
                                                        <el-input v-model="form.numero"></el-input>
                                                </el-form-item>
                                                <el-form-item 
                                                        prop="Date"
                                                        label="Choisie la date"
                                                        :rules="{
                                                            required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                                        }"
                                                        >
                                                        <el-date-picker placeholder="Choisie la date" v-model="form.date2" style="width: 100%;"></el-date-picker>
                                                </el-form-item>
                                                <el-form-item 
                                                        prop="regimeEconomique"
                                                        label="Regime Economique"
                                                        :rules="{
                                                            required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                                        }"
                                                        >
                                                        <el-select v-model="form.regimeEconomique" @change="regimeChange()" placeholder="Choisir Le Regime" style="width: 100%;">
                                                            <el-option label="10" value="10"></el-option>
                                                            <el-option label="22" value="22"></el-option>
                                                            <el-option label="37" value="37"></el-option>
                                                            <el-option label="81" value="81"></el-option>
                                                        </el-select>
                                                    </el-form-item>
                                                    <el-form-item 
                                                        prop="regimeName"
                                                        label="Nom Régime"
                                                        :rules="{
                                                            required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                                        }"
                                                        >
                                                        <el-input placeholder="Nome Régime" v-model="form.regimeName" style="width: 100%;"></el-input>
                                                </el-form-item>
                                                </el-form>

                                            </fieldset>
                                             
                                        </div>
                                        
                                    </el-card>
                                </div>

                        </el-tab-pane>

                        <el-tab-pane label="RECEPTION DE L'ENGAGEMENT">
                            <!-- RECEPTION DE L'ENGAGEMENT DOMICILIE -->
                            <div class="col-12 mt-4">
                                <el-card class="box-card">
                                    <div slot="header" class="clearfix text-center">
                                        <h4><strong>RECEPTION DE L'ENGAGEMENT DOMICILIE</strong></h4>
                                    </div>
                                    <div class="row">         
                                        <fieldset class="border p-2 col-12">
                                                <legend class="w-auto badge"><h6><strong> Joindre l'engagment Domicile </strong></h6></legend>
                                                
                                                <fieldset class="border p-2">
                                                        <legend class="w-auto badge"><h6><strong> </strong></h6></legend>

                                                        <el-upload
                                                            class="upload-demo"
                                                            ref="upload"
                                                            action="https://jsonplaceholder.typicode.com/posts/"
                                                            :auto-upload="false">
                                                            <el-button slot="trigger" size="small" type="primary">Select Fichier</el-button>
                                                            <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">upload to server</el-button>
                                                            <div class="el-upload__tip" slot="tip">pdf files</div>
                                                        </el-upload>

                                                </fieldset>
                                        </fieldset> 
                                        <fieldset class="border p-2 col-12">
                                            <legend class="w-auto badge"><h6><strong>Saisie l'engaement</strong></h6></legend>

                                            <el-form :model="form" ref="form">
                                                <el-form-item 
                                                    label="Saisie Numero Domiciliation"
                                                    prop="numero"
                                                    :rules="{
                                                        required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                                    }"
                                                    >
                                                    <el-input v-model="form.numero"></el-input>
                                                </el-form-item>
                                                <el-form-item 
                                                    prop="Date"
                                                    label="Choisie la date"
                                                    :rules="{
                                                        required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                                    }"
                                                    >
                                                    <el-date-picker placeholder="Choisie la date" v-model="form.date2" style="width: 100%;"></el-date-picker>
                                                </el-form-item>
                                            </el-form>

                                        </fieldset>
                                    </div>
                                    
                                </el-card>
                            </div>

                        </el-tab-pane>

                        <el-tab-pane label="TRANSPORT">
                            <!-- transport -->
                            <div class="col-12 mt-4 mb-4">
                                <el-card class="box-card">
                                    <div slot="header" class="clearfix text-center">
                                        <h4><strong> Documents De Transport </strong></h4>
                                    </div>
                                        
                                        <el-button type="primary" size="small" icon="el-icon-plus" @click="newModalTransport" class="mb-4" plain>Saisie le transport</el-button>

                                    <!--  -->
                                        <el-descriptions border>
                                            <el-descriptions-item><template slot="label"><strong>Type de transport :</strong></template> Maritime </el-descriptions-item>
                                            <el-descriptions-item ><template slot="label"><strong>Nom de compagnie :</strong></template> compagnie X</el-descriptions-item>
                                            <el-descriptions-item ><template slot="label"><strong>N° voyage :</strong></template> 125425 </el-descriptions-item>
                                            <el-descriptions-item ><template slot="label"><strong>N° connaissement :</strong></template> 852654 </el-descriptions-item>
                                            <el-descriptions-item ><template slot="label"><strong>Date Connaissement :</strong></template> 2022-01-29 </el-descriptions-item>
                                            
                                            <el-descriptions-item ><template slot="label"><strong>Mantant de fret :</strong></template>  ... </el-descriptions-item>
                                            <el-descriptions-item ><template slot="label"><strong>N° de facture :</strong></template>50000 </el-descriptions-item>
                                            <el-descriptions-item ><template slot="label"><strong>Total de facture HT :</strong></template> ... </el-descriptions-item>
                                            <el-descriptions-item ><template slot="label"><strong>TVA % :</strong></template> 20 </el-descriptions-item>
                                            <el-descriptions-item ><template slot="label"><strong>Montant TTC :</strong></template> ... </el-descriptions-item>
                                        </el-descriptions>
                                    <!--  -->
                                </el-card>
                            </div>

                        </el-tab-pane>

                        <el-tab-pane label="CHARGEMENT">
                            <!-- Chargement -->
                            <div class="col-12 mb-4">
                                <!-- <el-card class="box-card"> -->
                                    <div slot="header" class="clearfix text-center">
                                        <h4><strong> CHARGEMENT </strong></h4>
                                    </div>
                                    <el-tabs type="border-card">
                                        <el-tab-pane>
                                            <span slot="label"><i class="el-icon-edit-outline"></i> La facture </span>
                                                            <el-button type="primary" size="small" icon="el-icon-plus" @click="newColise()" plain>Saisie La Liste De Colisage</el-button>
                                                            <fieldset class="border p-2 text-center">
                                                                    <legend class="w-auto badge"><h6><strong> Liste De Colisage </strong></h6></legend>
                                                                <el-table 
                                                                    :data="Palettes.filter(data => !search || data.codePalet.toLowerCase().includes(search.toLowerCase()) ).slice((currpage - 1) * pagesize, currpage * pagesize)" style="width: 100%"  
                                                                    border
                                                                    >
                                                                <el-table-column type="expand" fixed>
                                                                    <template  slot-scope="scope" >
                                                                    <el-table
                                                                        :data="scope.row.get_bobines"
                                                                        style="width: 100%">
                                                                            <el-table-column
                                                                                prop="code_bobine"
                                                                                label="Code Bobine"
                                                                            >
                                                                            </el-table-column>
                                                                            <el-table-column
                                                                                prop="Laize"
                                                                                label="Laize en (mm)"
                                                                            >
                                                                            </el-table-column>
                                                                            <el-table-column
                                                                                prop="mtl"
                                                                                label="ML/MTL"
                                                                            >
                                                                            </el-table-column>
                                                                            
                                                                            <el-table-column
                                                                                prop="sqm"
                                                                                label="MQ/SQM">
                                                                            </el-table-column>
                                                                            <el-table-column
                                                                                prop="ref_bobine"
                                                                                label="Ref.Bobine">
                                                                            </el-table-column>
                                                                        </el-table>
                                                                    </template>
                                                                </el-table-column>
                                                                <el-table-column label="Code Palette" fixed width="150">
                                                                    <template slot-scope="scope">
                                                                        <span>{{ scope.row.codePalet }}</span>
                                                                    </template>
                                                                </el-table-column>
                                                                <el-table-column label="Bobines">
                                                                    <template slot-scope="scope">
                                                                        <span>{{ scope.row.QtitBobine }}</span>
                                                                    </template>
                                                                </el-table-column>
                                                                <el-table-column label="Unite">
                                                                    <template slot-scope="scope">
                                                                        <span>{{ scope.row.TypeUnite }}</span>
                                                                    </template>
                                                                </el-table-column>
                                                                <el-table-column label="Laize">
                                                                    <template slot-scope="scope">
                                                                        <span>{{ scope.row.laize }}</span>
                                                                    </template>
                                                                </el-table-column>
                                                                <el-table-column label="ML/MTL">
                                                                    <template slot-scope="scope">
                                                                        <span>{{ scope.row.mtl }}</span>
                                                                    </template>
                                                                </el-table-column>
                                                                <el-table-column label="MQ/SQM">
                                                                    <template slot-scope="scope">
                                                                        <span>{{ scope.row.sqm }}</span>
                                                                    </template>
                                                                </el-table-column>
                                                                <el-table-column label="Poids Brut" width="120">
                                                                    <template slot-scope="scope">
                                                                        <span>{{ scope.row.PoidsBrutTotal }}</span>
                                                                    </template>
                                                                </el-table-column>
                                                                <el-table-column label="Poids Net" width="120">
                                                                    <template slot-scope="scope">
                                                                        <span>{{ scope.row.PoidsNetTotal }}</span>
                                                                    </template>
                                                                </el-table-column>
                                                                <el-table-column v-if="$gate.isAdmin()" width="150" align="center">
                                                                    <template slot-scope="{row}">
                                                                            <a href="#" @click="updatePalette(row)" data-toggle="tooltip" data-placement="top" title="Modifier">
                                                                                    <i class="fa fa-edit blue mr-2"></i>
                                                                            </a>
                                                                            <el-divider direction="vertical"></el-divider>
                                                                            <a href="#" @click="deletPalette(row.id)" data-toggle="tooltip" data-placement="top" title="Supprimer">
                                                                                <i class="fa fa-trash red"></i>
                                                                            </a>
                                                                    </template>
                                                                </el-table-column>
                                                                </el-table>
                                                                <!--  -->
                                                                <el-pagination background 
                                                                        class="mt-3"
                                                                        layout="prev, pager, next, sizes, total, jumper"
                                                                        :page-sizes = "[5, 10, 15, 20]"
                                                                        :page-size = "pagesize"
                                                                        :total="Palettes.length"
                                                                        @current-change = "handleCurrentChange" 
                                                                        @size-change = "handleSizeChange" 
                                                                        >
                                                                </el-pagination>
                                                            </fieldset>
                                                        
                                        </el-tab-pane>
                                        <el-tab-pane>
                                            <span slot="label"><i class="el-icon-document"></i> Bon de livraison </span>
                                            <el-form :model="form" ref="form">
                                                <fieldset class="border p-2">
                                                    <legend class="w-auto badge"><h6><strong>  </strong></h6></legend>
                                                        <fieldset class="border p-2">
                                                                <legend class="w-auto badge"><h6><strong> Bon de livraison </strong></h6></legend>
                                                                
                                                                <el-form-item 
                                                                    class="text-center"
                                                                    prop="bonL"
                                                                    :rules="[
                                                                            { required: true, message: 'Ce champ est obligatoire'}
                                                                        ]"
                                                                >
                                                                    <el-radio-group v-model="form.bonL">
                                                                        <el-radio label="OUI"></el-radio>
                                                                        <el-radio label="NON"></el-radio>
                                                                    </el-radio-group>
                                                                </el-form-item>
                                                                <!-- <el-button type="primary" size="small" icon="el-icon-plus" @click="newModalTransport" plain>Saisie le transport</el-button> -->
                                                        </fieldset>
                                                        <fieldset v-if="form.bonL == 'OUI'" class="border p-2">
                                                                <div class="row">
                                                                    <el-form-item 
                                                                        prop="Date"
                                                                        class="col-lg-4" 
                                                                        label="Choisie la date"
                                                                        :rules="{
                                                                            required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                                                        }"
                                                                        >
                                                                        <el-date-picker placeholder="Choisie la date" v-model="form.date2" style="width: 100%;"></el-date-picker>
                                                                    </el-form-item>
                                                                    <el-form-item 
                                                                        label="N° de bon"
                                                                        class="col-lg-4" 
                                                                        prop="numero"
                                                                        :rules="{
                                                                            required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                                                        }"
                                                                        >
                                                                        <el-input v-model="form.numero"></el-input>
                                                                    </el-form-item>
                                                                    <el-form-item 
                                                                        label="Joindre Bon livraison"
                                                                        class="col-lg-4" 
                                                                        prop="facturefret"
                                                                        >
                                                                        <el-upload
                                                                            style="width: fit-content;"
                                                                            class="upload-demo"
                                                                            ref="upload"
                                                                            action="https://jsonplaceholder.typicode.com/posts/"
                                                                            :auto-upload="false">
                                                                            <el-button slot="trigger" size="small" type="primary">Select Fichier</el-button>
                                                                            <!-- <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">upload to server</el-button> -->
                                                                        </el-upload>
                                                                    </el-form-item>
                                                                </div>
                                                        </fieldset>
                                                </fieldset>
                                                </el-form>
                                        </el-tab-pane>
                                    </el-tabs>
                                <!-- </el-card> -->
                            </div>

                        </el-tab-pane>

                        <el-tab-pane label="DECLARATION DOUANIERE">
                            <!-- Declaration douaniere -->
                            <div class="col-12 mt-4 mb-4">
                                <el-card class="box-card">
                                    <div slot="header" class="clearfix text-center">
                                        <h4><strong> DECLARATION DOUANIERE </strong></h4>
                                    </div>
                                    <div class="row">
                                        <fieldset class="border p-2 col-12">
                                            <legend class="w-auto badge"></legend>
                                            <el-alert
                                                class="mt-3"
                                                title="Alert"
                                                description="les informations de DUM sont pas exacte "
                                                type="warning"
                                                effect="dark"
                                                :closable="false"
                                                show-icon>
                                            </el-alert>
                                        </fieldset>
                                                                
                                                <fieldset class="border p-2 col-lg-6">
                                                        <legend class="w-auto badge"><h6><strong> Corréction DUM Provisoire </strong></h6></legend>
                                                        <!-- <el-button type="primary" size="small" icon="el-icon-plus" @click="newModal" plain>Saisie La facture difinitive</el-button> -->
                                                    <el-form :model="form" ref="form" class="row">
                                                        <el-form-item
                                                            class="col-lg-6" 
                                                            label="Exportateur / expiditeur"
                                                            prop="fournisseurNameDUM" aria-placeholder="fournisseurName"
                                                            :rules="{
                                                                required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                                            }"
                                                            >
                                                            <el-input v-model="form.fournisseurNameDUM" v-on:input="checkDUMFournisseur()"></el-input>
                                                        </el-form-item>
                                                        <el-form-item
                                                            class="col-lg-6" 
                                                            label="Bureau Douanière"
                                                            prop="burreauDUM" aria-placeholder="Burreau"
                                                            :rules="{
                                                                required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                                            }"
                                                            >
                                                            <el-input v-model="form.burreauDUM" @input="checkDUMText()"></el-input>
                                                        </el-form-item>
                                                        <el-form-item
                                                            class="col-lg-6" 
                                                            label="régime economique"
                                                            prop="regimeDUM" aria-placeholder="regime"
                                                            :rules="{
                                                                required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                                            }"
                                                            >
                                                            <el-input v-model="form.regimeDUM" v-on:input="checkDUMRegime()"></el-input>
                                                        </el-form-item>
                                                        <el-form-item
                                                            class="col-lg-6" 
                                                            label="Client"
                                                            prop="clientNameDUM" aria-placeholder="clientName"
                                                            :rules="{
                                                                required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                                            }"
                                                            >
                                                            <el-input v-model="form.clientNameDUM"></el-input>
                                                        </el-form-item>
                                                        <el-form-item
                                                            class="col-lg-6" 
                                                            label="Devise"
                                                            prop="devise" aria-placeholder="nomenclature"
                                                            :rules="{
                                                                required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                                            }"
                                                            >
                                                            <el-input v-model="form.devise"></el-input>
                                                        </el-form-item>
                                                        <el-form-item
                                                            class="col-lg-6" 
                                                            label="Nomenclature"
                                                            prop="nomenclature" aria-placeholder="NumRC"
                                                            :rules="{
                                                                required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                                            }"
                                                            >
                                                            <el-input v-model="form.nomenclature"></el-input>
                                                        </el-form-item>
                                                        <el-form-item
                                                            class="col-lg-6" 
                                                            label="Incoterm"
                                                            prop="incoterm" aria-placeholder="ClientAdress"
                                                            :rules="{
                                                                required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                                            }"
                                                            >
                                                            <el-input v-model="form.incoterm"></el-input>
                                                        </el-form-item>
                                                        <el-form-item
                                                            class="col-lg-6" 
                                                            label="designiationArticles"
                                                            prop="designiationArticlesDUM" aria-placeholder="designiationArticles"
                                                            :rules="{
                                                                required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                                            }"
                                                            >
                                                            <el-input v-model="form.designiationArticlesDUM"></el-input>
                                                        </el-form-item>
                                                        <el-form-item
                                                            class="col-lg-6" 
                                                            label="UnitMesure"
                                                            prop="UnitMesureDUM" aria-placeholder="UnitMesure"
                                                            :rules="{
                                                                required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                                            }"
                                                            >
                                                            <el-input v-model="form.UnitMesureDUM"></el-input>
                                                        </el-form-item>
                                                        <el-form-item
                                                            class="col-lg-6" 
                                                            label="Pays D'origine"
                                                            prop="PaysOrigineDUM" aria-placeholder="PaysOrigine"
                                                            :rules="{
                                                                required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                                            }"
                                                            >
                                                            <el-input v-model="form.PaysOrigineDUM"></el-input>
                                                        </el-form-item>
                                                        <el-form-item
                                                            class="col-lg-6" 
                                                            label="Pays Provenance"
                                                            prop="PaysProvenanceDUM" aria-placeholder="PaysProvenance"
                                                            :rules="{
                                                                required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                                            }"
                                                            >
                                                            <el-input v-model="form.PaysProvenanceDUM"></el-input>
                                                        </el-form-item>
                                                        
                                                        <el-form-item
                                                            class="col-lg-6" 
                                                            label="Taux de Change"
                                                            prop="tauxChange" 
                                                            :rules="{
                                                                required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                                            }"
                                                            >
                                                            <el-input v-model="form.tauxChange"></el-input>
                                                        </el-form-item>
                                                        <el-form-item
                                                            class="col-lg-6" 
                                                            label="Saisie l'assurance"
                                                            prop="assurance" 
                                                            :rules="{
                                                                required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                                            }"
                                                            >
                                                            <el-input v-model="form.assurance"></el-input>
                                                        </el-form-item>
                                                        <el-form-item
                                                            class="col-lg-6" 
                                                            label="Fret "
                                                            prop="Fret" 
                                                            :rules="{
                                                                required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                                            }"
                                                            >
                                                            <el-input v-model="form.Fret"></el-input>
                                                        </el-form-item>
                                                        <el-form-item
                                                            class="col-lg-6" 
                                                            label="Nombre général des pallets "
                                                            prop="nombrePallets" 
                                                            :rules="{
                                                                required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                                            }"
                                                            >
                                                            <el-input v-model="form.nombrePallets"></el-input>
                                                        </el-form-item>
                                                        <el-form-item
                                                            class="col-lg-6" 
                                                            label="Poids Net "
                                                            prop="poidsNet" 
                                                            :rules="{
                                                                required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                                            }"
                                                            >
                                                            <el-input v-model="form.poidsNet"></el-input>
                                                        </el-form-item>
                                                        <el-form-item
                                                            class="col-lg-6" 
                                                            label="Poids Brut "
                                                            prop="poidsBrut" 
                                                            :rules="{
                                                                required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                                            }"
                                                            >
                                                            <el-input v-model="form.poidsBrut"></el-input>
                                                        </el-form-item>
                                                    </el-form>
                                        </fieldset>
                                        <fieldset class="border p-2 col-lg-6">
                                                <legend class="w-auto badge"><h6><strong> --- </strong></h6></legend>
                                                <fieldset class="border p-2">
                                                    <legend class="w-auto badge"><h6><strong> Joindre la DUM </strong></h6></legend>
                                                        <el-upload
                                                            class="upload-demo"
                                                            ref="upload"
                                                            action="https://jsonplaceholder.typicode.com/posts/"
                                                            :auto-upload="false">
                                                            <el-button slot="trigger" size="small" type="primary">Select Fichier</el-button>
                                                            <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">upload to server</el-button>
                                                            <div class="el-upload__tip" slot="tip">pdf files</div>
                                                        </el-upload>
                                                </fieldset>
                                                <fieldset class="border p-2">
                                                    <legend class="w-auto badge"><h6><strong> DUM </strong></h6></legend>
                                                    <el-descriptions class="margin-top mb-2" border :column="1">
                                                        <el-descriptions-item><template slot="label"><strong>Exportateur / Expiditeur :</strong></template> {{formTeCheck.fournisseurNameDUM}}</el-descriptions-item>
                                                        <el-descriptions-item><template slot="label"><strong>Bureau Douaniere :</strong></template> {{formTeCheck.burreauDUM}}</el-descriptions-item>
                                                        <el-descriptions-item><template slot="label"><strong>Régime économique :</strong></template>  {{formTeCheck.regimeDUM}} </el-descriptions-item>
                                                        <el-descriptions-item><template slot="label"><strong>Nomenclature:</strong></template> {{formTeCheck.nomenclatureDUM}}</el-descriptions-item>
                                                    </el-descriptions>
                                                </fieldset>
                                        </fieldset>
                                    </div>
                                    
                                </el-card>
                            </div>

                        </el-tab-pane>

                        <el-tab-pane label="DEMANDE DE CAUTION">
                            <!-- Impression demande de caution -->
                            <div class="col-12 mt-4 mb-4">
                                <el-card class="box-card text-center">
                                    <div slot="header" class="clearfix text-center">
                                        <h4><strong> DUM Pour Cautionnement Banquaire </strong></h4>
                                    </div>
                                    <div class="row">
                                        <fieldset class="border p-2 col-12 text-center">
                                                <legend class="w-auto badge"><h6><strong> Joindre la facture fournisseur </strong></h6></legend>
                                                <el-upload
                                                    class="upload-demo"
                                                    ref="upload"
                                                    action="https://jsonplaceholder.typicode.com/posts/"
                                                    :auto-upload="false">
                                                    <el-button slot="trigger" size="small" type="primary">Select Fichier</el-button>
                                                    <!-- <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">upload to server</el-button> -->
                                                    <div class="el-upload__tip" slot="tip">pdf files</div>
                                                </el-upload>
                                        </fieldset>
                                        <fieldset class="border p-2 col-12 text-center">
                                                <legend class="w-auto badge"><h6><strong> Joindre l'estimation des droits et taxes exigibles </strong></h6></legend>
                                                <el-upload
                                                    class="upload-demo"
                                                    ref="upload"
                                                    action="https://jsonplaceholder.typicode.com/posts/"
                                                    :auto-upload="false">
                                                    <el-button slot="trigger" size="small" type="primary">Select Fichier</el-button>
                                                    <!-- <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">upload to server</el-button> -->
                                                    <div class="el-upload__tip" slot="tip">pdf files</div>
                                                </el-upload>
                                        </fieldset>
                                        <fieldset class="border p-2 col-12 text-center">
                                                <legend class="w-auto badge"><h6><strong> Joindre la DUM enregistrer </strong></h6></legend>
                                                <el-upload
                                                    class="upload-demo"
                                                    ref="upload"
                                                    action="https://jsonplaceholder.typicode.com/posts/"
                                                    :auto-upload="false">
                                                    <el-button slot="trigger" size="small" type="primary">Select Fichier</el-button>
                                                    <!-- <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">upload to server</el-button> -->
                                                    <div class="el-upload__tip" slot="tip">pdf files</div>
                                                </el-upload>
                                        </fieldset>
                                        <fieldset class="border p-2 col-lg-12 text-center">
                                                    <legend class="w-auto badge"><h6><strong> Imprimer </strong></h6></legend>
                                            <el-button type="primary" size="small" icon="el-icon-printer" @click="dialogVisibleDemandeCaution = true" plain>Imprimer la Demande de caution</el-button>
                                            <el-button type="primary" size="small" icon="el-icon-printer" @click="dialogVisibleDeclaration = true" plain>Imprimer la Declaration</el-button>
                                        </fieldset>
                                    </div>
                                
                                </el-card>
                            </div>
                        </el-tab-pane>

                        <el-tab-pane label="DECLARATION DOUANIERE">
                            <!-- validation de la declaration douanière par la banque -->
                            <div class="col-12 mt-4 mb-4">
                                <el-card class="box-card">
                                    <div slot="header" class="clearfix text-center">
                                        <h4><strong> Validation de la declaration douanière par la banque  </strong></h4>
                                    </div>
                                    <div class="row">
                                        <fieldset class="border p-2 col-12">
                                                <legend class="w-auto badge"><h6><strong></strong></h6></legend>
                                                
                                                <el-button type="primary" size="small" class="mb-3" icon="el-icon-plus" @click="dialogVisibleValidationDDB = true" plain>Saisie la Validation</el-button>
                                                
                                                <el-descriptions border  v-for="t in ValidationDDB" :key='t.id'>
                                                    <el-descriptions-item><template slot="label"><strong> N° DUM :</strong></template> {{t.numDUM}}</el-descriptions-item>
                                                    <el-descriptions-item ><template slot="label"><strong>Année :</strong></template> {{t.annee}}</el-descriptions-item>
                                                    <el-descriptions-item ><template slot="label"><strong>Date d'enregistrement :</strong></template> {{t.dateEnregistrement}} </el-descriptions-item>
                                                    <el-descriptions-item ><template slot="label"><strong>Bureau :</strong></template> {{t.bureau}} </el-descriptions-item>
                                                    <el-descriptions-item ><template slot="label"><strong>Ville :</strong></template> {{t.ville}} </el-descriptions-item>
                                                    
                                                    <el-descriptions-item ><template slot="label"><strong>Nomenclature :</strong></template> {{t.nomenclature}}</el-descriptions-item>
                                                    <el-descriptions-item ><template slot="label"><strong>Unite Mesure :</strong></template> {{t.uniteMesure}} </el-descriptions-item>
                                                    <el-descriptions-item ><template slot="label"><strong>Quantité :</strong></template> {{t.qtitUnit}} </el-descriptions-item>
                                                    <el-descriptions-item ><template slot="label"><strong>montant Declare :</strong></template> {{t.montantDeclare}} </el-descriptions-item>
                                                    <el-descriptions-item ><template slot="label"><strong>Poids Net :</strong></template> {{t.poidsNet}} </el-descriptions-item>
                                                    <el-descriptions-item ><template slot="label"><strong>Poids Brut :</strong></template> {{t.poidsBrut}} </el-descriptions-item>
                                                    
                                                    <el-descriptions-item ><template slot="label"><strong>Taux Douanière :</strong></template> {{t.tauxDouanier}} </el-descriptions-item>
                                                    <el-descriptions-item ><template slot="label"><strong>Montant Douanièr :</strong></template> {{t.montantDouanier}} </el-descriptions-item>
                                                    <el-descriptions-item ><template slot="label"><strong>N° Caution :</strong></template> {{t.numCaution}} </el-descriptions-item>
                                                </el-descriptions>
                                        </fieldset>
                                        <fieldset class="border p-2 col-12 ">
                                                <legend class="w-auto badge"><h6><strong> Traitement douanier </strong></h6></legend>
                                                <el-button type="primary" size="small" class="mb-3" icon="el-icon-plus" @click="dialogVisibleDataTraitement = true" plain>Ajouter</el-button>
                                                    <el-table
                                                        :data="tableDataTraitement"
                                                        style="width: 100%">
                                                        <el-table-column
                                                        prop="bureauDouanier"
                                                        label="BUREAU DOUANIERE"
                                                        >
                                                        </el-table-column>
                                                        <el-table-column
                                                        prop="ville"
                                                        label="Ville"
                                                        >
                                                        </el-table-column>
                                                        <el-table-column
                                                        prop="dumFile"
                                                        label="La Copie de DUM"
                                                        >
                                                        </el-table-column>
                                                        <el-table-column
                                                            fixed="right"
                                                            >
                                                            <template>
                                                                <a href="#" data-toggle="tooltip" data-placement="top" title="Modifier">
                                                                        <i class="fa fa-edit blue mr-2"></i>
                                                                </a>
                                                                <el-divider direction="vertical"></el-divider>
                                                                <a href="#"  data-toggle="tooltip" data-placement="top" title="Supprimer">
                                                                    <i class="fa fa-trash red"></i>
                                                                </a>
                                                            </template>
                                                            </el-table-column>
                                                    </el-table>
                                        </fieldset>
                                    </div>
                                </el-card>
                            </div>
                        </el-tab-pane>

                        <el-tab-pane label="Reception De Stock">
                            <!-- Reception stock -->
                            <div class="col-12 mt-4 mb-4">
                                <el-card class="box-card">
                                    <div slot="header" class="clearfix text-center">
                                        <h4><strong> Reception De Stock  </strong></h4>
                                    </div>
                                            <span slot="label"><i class="el-icon-edit-outline"></i> Reception De Stock </span>
                                                            <!-- <el-button type="primary" size="small" icon="el-icon-plus" @click="newColise()" plain>Saisie La Liste De Colisage</el-button> -->
                                                            <fieldset class="border p-2 text-center">
                                                                    <legend class="w-auto badge"><h6><strong> Liste De Colisage </strong></h6></legend>
                                                                <el-table 
                                                                    :data="Palettes.filter(data => !search || data.codePalet.toLowerCase().includes(search.toLowerCase()) ).slice((currpage - 1) * pagesize, currpage * pagesize)" style="width: 100%"  
                                                                    border
                                                                    >
                                                                <el-table-column type="expand" fixed>
                                                                    <template  slot-scope="scope" >
                                                                        <el-table
                                                                            :data="scope.row.get_bobines"
                                                                            style="width: 100%">
                                                                                <el-table-column
                                                                                    prop="code_bobine"
                                                                                    label="Code Bobine"
                                                                                >
                                                                                </el-table-column>
                                                                                <el-table-column
                                                                                    prop="Laize"
                                                                                    label="Laize en (mm)"
                                                                                >
                                                                                </el-table-column>
                                                                                <el-table-column
                                                                                    prop="mtl"
                                                                                    label="ML/MTL"
                                                                                >
                                                                                </el-table-column>
                                                                                
                                                                                <el-table-column
                                                                                    prop="sqm"
                                                                                    label="MQ/SQM">
                                                                                </el-table-column>
                                                                                <el-table-column
                                                                                    prop="ref_bobine"
                                                                                    label="Ref.Bobine">
                                                                                </el-table-column>
                                                                        </el-table>
                                                                    </template>
                                                                </el-table-column>
                                                                <el-table-column label="Code Palette" fixed width="160">
                                                                    <template slot-scope="scope">
                                                                        <span>{{ scope.row.codePalet }}</span>
                                                                    </template>
                                                                </el-table-column>
                                                                <el-table-column label="Bobines">
                                                                    <template slot-scope="scope">
                                                                        <span>{{ scope.row.QtitBobine }}</span>
                                                                    </template>
                                                                </el-table-column>
                                                                <el-table-column label="Unite">
                                                                    <template slot-scope="scope">
                                                                        <span>{{ scope.row.TypeUnite }}</span>
                                                                    </template>
                                                                </el-table-column>
                                                                <el-table-column label="Laize">
                                                                    <template slot-scope="scope">
                                                                        <span>{{ scope.row.laize }}</span>
                                                                    </template>
                                                                </el-table-column>
                                                                <el-table-column label="ML/MTL">
                                                                    <template slot-scope="scope">
                                                                        <span>{{ scope.row.mtl }}</span>
                                                                    </template>
                                                                </el-table-column>
                                                                <el-table-column label="MQ/SQM">
                                                                    <template slot-scope="scope">
                                                                        <span>{{ scope.row.sqm }}</span>
                                                                    </template>
                                                                </el-table-column>
                                                                <el-table-column label="Poids Brut"  width="100">
                                                                    <template slot-scope="scope">
                                                                        <span>{{ scope.row.PoidsBrutTotal }}</span>
                                                                    </template>
                                                                </el-table-column>
                                                                <el-table-column label="Poids Net">
                                                                    <template slot-scope="scope">
                                                                        <span>{{ scope.row.PoidsNetTotal }}</span>
                                                                    </template>
                                                                </el-table-column>
                                                                <el-table-column label="L'état" width="150" align="center">
                                                                    <template slot-scope="scope">
                                                                        <div v-if="scope.row.etat_palette == 'chargement'">
                                                                            <el-tag  type="warning" effect="dark" ><strong>{{ scope.row.etat_palette }}</strong></el-tag>
                                                                        </div>
                                                                        <div v-if="scope.row.etat_palette == 'valider'">
                                                                            <el-tag  type="success" effect="dark" ><strong>{{ scope.row.etat_palette }}</strong></el-tag>
                                                                        </div>
                                                                    </template>
                                                                </el-table-column>
                                                                <el-table-column v-if="$gate.isAdmin()" align="center">
                                                                    <template slot-scope="{row}">
                                                                            <a href="#" @click="ValiderPalette(row.id)" data-toggle="tooltip" data-placement="top" title="Valider Palette">
                                                                                <i class="fa fa-check green"></i>
                                                                            </a>
                                                                    </template>
                                                                </el-table-column>
                                                                </el-table>
                                                                <!--  -->
                                                                <el-pagination background 
                                                                        class="mt-3"
                                                                        layout="prev, pager, next, sizes, total, jumper"
                                                                        :page-sizes = "[5, 10, 15, 20]"
                                                                        :page-size = "pagesize"
                                                                        :total="Palettes.length"
                                                                        @current-change = "handleCurrentChange" 
                                                                        @size-change = "handleSizeChange" 
                                                                        >
                                                                </el-pagination>
                                                            </fieldset>
                                                            <div class="col-12">
                                                                <el-form :model="Remarquesform" >
                                                                <fieldset class="border p-2">

                                                                    <legend class="w-auto badge badge"><h6><strong> Remarques </strong> </h6></legend>
                                                                    <el-form-item >
                                                                        <el-input :autosize="{ minRows: 5}" placeholder="Votre Remarque ici" type="textarea" v-model="Remarquesform.message"></el-input>
                                                                    </el-form-item>
                                                                    <el-form-item>
                                                                        <el-button type="primary" style="float: right;" size="small" @click="SendRemarque('ReceptionStock')">Ajouter Remarque</el-button>
                                                                    </el-form-item>

                                                                </fieldset>
                                                                </el-form>
                                                                <fieldset class="border p-2">
                                                                    <legend class="w-auto badge badge"><h6><strong>  </strong> </h6></legend>
                                                                    <div class="block">
                                                                        <el-timeline v-for="r in Remarques.RemarquesReceptionStock" :key="r.id">

                                                                            <el-timeline-item>
                                                                                <el-card>
                                                                                    <div class="row">
                                                                                        <div class="col-12 text-right">
                                                                                            <el-button style="float: right; padding: 3px 0" @click="deletRemarque(r.id)" data-toggle="tooltip" data-placement="top" title="Supprimer" type="text"><i class="fa fa-trash red"></i></el-button>
                                                                                        </div>
                                                                                        <div class="col-1">
                                                                                            <el-avatar icon="el-icon-user-solid"></el-avatar>
                                                                                        </div>
                                                                                        <div class="col-11">
                                                                                            <h6><strong>{{r.get_user.name}}</strong></h6>
                                                                                            <p>{{r.message}}</p>
                                                                                        </div>
                                                                                        <div class="col-12 text-right">
                                                                                            {{r.created_at | RemarqueDates}}
                                                                                        </div>
                                                                                    </div>
                                                                                </el-card>
                                                                            </el-timeline-item>
                                                                            
                                                                        </el-timeline>
                                                                        </div>
                                                                </fieldset>
                                                            </div>
                                </el-card>
                            </div>

                        </el-tab-pane>
                        
                </el-tabs>
            </div>
 
            <!-- Modal Demande Caution -->
        <el-dialog title="Validation de la declaration douanière par la banque" :visible.sync="dialogVisibleValidationDDB" width="50%" :before-close="handleClose">
            <el-form :model="formDDB" ref="form" >
                    <el-form-item 
                        label="Taux Douanier"
                        prop="tauxDouanier"
                        :rules="{
                            required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                        }"
                        >
                        <el-input v-model="formDDB.tauxDouanier" placeholder="Taux Douanier"></el-input>
                    </el-form-item>
                    <el-form-item 
                        label="Montant Douanier"
                        prop="montantDouanier"
                        :rules="{
                            required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                        }"
                        >
                        <el-input v-model="formDDB.montantDouanier" placeholder="Montant Douanier"></el-input>
                    </el-form-item>
                    <el-form-item 
                        label="N° Caution"
                        prop="numCaution"
                        :rules="{
                            required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                        }"
                        >
                        <el-input v-model="formDDB.numCaution" placeholder="N° Caution"></el-input>
                    </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisibleValidationDDB = false">Annuler</el-button>
                    <el-button  type="success" @click="dialogVisibleValidationDDB = false">Confirmer</el-button>
            </span>
        </el-dialog>
                    
            <!-- Modal Demande Caution -->
            <!-- Modal Traitement douanier -->
        <el-dialog title="Validation de la declaration douanière par la banque" :visible.sync="dialogVisibleDataTraitement" width="50%" :before-close="handleClose">
            <el-form :model="formDataTraitement" ref="form" >
                    <el-form-item 
                        label="BUREAU DOUANIERE"
                        prop="bureauDouanier"
                        :rules="{
                            required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                        }"
                        >
                        <el-input v-model="formDataTraitement.bureauDouanier" placeholder="BUREAU DOUANIERE"></el-input>
                    </el-form-item>
                    <el-form-item 
                        label="Ville"
                        prop="ville"
                        :rules="{
                            required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                        }"
                        >
                        <el-input v-model="formDataTraitement.ville" placeholder="Ville"></el-input>
                    </el-form-item>
                    <el-form-item 
                        label="Joindr la copie de DUM"
                        prop="dumFile"
                        >
                        
                        <el-upload
                            style="width: fit-content;"
                            class="upload-demo"
                            ref="upload"
                            action="https://jsonplaceholder.typicode.com/posts/"
                            :auto-upload="false">
                            <el-button slot="trigger" size="small" type="primary">Select Fichier</el-button>
                        </el-upload>
                    </el-form-item> 
            </el-form>
            <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisibleDataTraitement = false">Annuler</el-button>
                    <el-button  type="success" @click="dialogVisibleDataTraitement = false">Confirmer</el-button>
            </span>
        </el-dialog>
                    
            <!-- Modal Traitement douanier -->
        <el-dialog title="Facture" :visible.sync="dialogVisibleDemandeCaution" width="90%" :before-close="handleClose">
                    <div id="printMe" class="modal-body row">
                        
                            <fieldset class="border p-3 col-lg-12">
                                <legend class="w-auto badge"><h5>ANNEXE 1 : LA DEMANDE DE CAUTION</h5></legend>
                                    <p><strong> NOM OU DENOMINATION SOCIALE :</strong> ................ </p><br>
                                    <p><strong> ADRESSE OU SIEGE SOCIAL : </strong> ................ </p><br>
                                    <p><strong> N° DE COMPTE : </strong> ................ </p><br>
                                    <p class="float-right"><strong> A : </strong> ................ <strong>,le :  ................</strong></p><br>
                                    <p class="text-center"><strong> Monsieur le Directeur de la Banque Populaire de : </strong> ................ </p><br>
                                    <p><strong> OBJET : Demande de caution en douane  </strong> ................ </p><br>
                                    <p><strong> Monsieur le Directeur,</strong></p><br>
                                    <p><strong> Nous vous serions obligés de bien vouloir nous délivrer pour notre compte et sous notre pleine et entière
                                                responsabilité à votre égard, une caution en douane </strong> ................ <strong> d’un montan de </strong> ................ </p><br>
                                    <p><strong> Douanes au titre de l’operation mentionnée ci-après :</strong>
                                        <ul>
                                            <li><strong> Nature de la Marchandise :</strong> ................ </li>
                                            <li><strong> Nomenclature de la Marchandise :</strong> ................ </li>
                                            <li><strong> Valeur de la Marchandise en devises :</strong> ................</li>
                                            <li><strong> Sa contre-valeur en Dirhams :</strong> ................ </li>
                                            <li><strong> Numéro de la Déclaration auprès de l’Administration des Douanes :</strong> ................</li>
                                        </ul>
                                    </p><br>
                                    <p class="mr-2"><strong> Il est bien entendu que votre caution sera rémunérée au taux en vigueur</strong></p><br>
                                    <p class="mr-2"><strong> Nous vous autorisons d’ores et déjà, à prélever le montant correspondant à la rémunération de la caution</strong>
                                    <span><strong> Nous vous autorisons d’ores et déjà, à prélever le montant correspondant à la rémunération de la caution</strong></span><br>
                                    <span><strong> délivrée par vos soins en faveur de l’Administration des Douanes et à constituer une provision au titre de la
                                                présente caution et ce, par prélévement sur notre compte n°</strong> ................ <strong>, , ouvert sur vos
                                                livres.</strong> </span></p><br>
                                    <p class="mr-2"><strong>Au cas où vous seriez amenés à effectuer des règlemants ou à conclure une transaction au titre de ladite</strong>
                                    <span><strong>causion, nous nous interdisons formellement de contester à votre égard le bien fondé de toute transaction ainsi
                                                que des versements en capital, outre les intérêts de regard et pénalités que vous pourriez être amenés à effectuer
                                                en vertu de cette caution et vous donnons d’ores et déjà, ordre de débiter notre compte susvisé ou tout autre
                                                compte ouvert sur vos livres de toutes sommes qui seraient réclamées dans le cadre de la mise en jeu par
                                                l’Administration des douanes.</strong></span></p><br>
                                    <p class="mr-2"><strong>Au cas où notre (nos) compte(s) ne permet(tent) pas le paiement, nous nous engageons à verser</strong>
                                    <span><strong>immédiatement toutes sommes réclamées par vos soins à ce titre sur votre simple demande sans qu’il soit besoin
                                                de sommation, procédures ou autres démarches et vous réglerons toutes sommes que vous aurez à débourser de
                                                ce chef.</strong></span></p><br>
                                    <p class="mr-2"><strong>Veuillez agréer, Monsieur le Directeur, l’expression de mes sentiments distingués</strong></p><br>
                                    <p class="float-right"><strong>SIGNATURE</strong> <img style="height: 130px;" src="/images/signature.png"></p><br>
                            </fieldset>
                    </div>
        
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisibleDemandeCaution = false">Annuler</el-button>
                    <el-button
                        class="btn btn-primary"
                        style="float: right;"
                        @click="printC()"
                    >
                        <span class="fa fa-print"></span>
                    </el-button>
                </span>
        </el-dialog> 
        <!-- End Modal Demande Caution -->
        <!-- Modal Declaration PDF -->
        <el-dialog title="Facture" :visible.sync="dialogVisibleDeclaration" width="90%" :before-close="handleClose">
                    <div id="printMe" class="modal-body row">
                        
                            <fieldset class="border p-3 col-lg-12">
                                <legend class="w-auto badge"><h5>D E C L A R A T I O N</h5></legend>
                                    <p><strong> Je soussigné :</strong> ................ </p><br>
                                    <p><strong> Demeurant à : </strong> ................ </p><br>
                                    <p><strong> Reconnais ainsi être mandaté par : </strong> ................ </p><br>

                                    <p><strong> l’effet de procéder aux formalités au dédouanement des marchandises importées par le
                                                mandant indiquées ci-après : :</strong>
                                        <ul>
                                            <li><strong> Nature de la Marchandise :</strong> ................ </li>
                                            <li><strong> Nomenclature de la Marchandise :</strong> ................ </li>
                                            <li><strong> Valeur de la Marchandise en devises :</strong> ................</li>
                                            <li><strong> Sa contre-valeur en Dirhams :</strong> ................ </li>
                                        </ul>
                                    </p><br>
                                    
                                    <p class="float-right"><strong>SIGNATURE</strong> <img style="height: 130px;" src="/images/signature.png"><img style="height: 130px;" src="/images/cachet.png"></p><br>
                            </fieldset>
                    </div>
        
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisibleDeclaration = false">Annuler</el-button>
                    <el-button
                        class="btn btn-primary"
                        style="float: right;"
                        @click="printC()"
                    >
                        <span class="fa fa-print"></span>
                    </el-button>
                </span>
        </el-dialog> 
        <!-- End Modal Declaration PDF-->
        </div>
        <!-- Modal Facture Pro -->
        <el-dialog title="Facture" :visible.sync="dialogVisible" width="90%" :before-close="handleClose">
            <el-form :model="form" ref="form" >
                    <div class="modal-body row">
                        <fieldset class="border p-2 col-lg-12">
                            <legend class="w-auto badge"><h5>Informations De la facture Pro</h5></legend>
                            
                            <div class="row">
                                    <el-form-item 
                                        label="N° Facture Proformat"
                                        class="col-lg-4" 
                                        prop="numero"
                                        :rules="{
                                            required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                        }"
                                        >
                                        <el-input v-model="form.numero"></el-input>
                                    </el-form-item>
                                    <el-form-item 
                                        prop="Date"
                                        class="col-lg-4" 
                                        label="Type de Transport"
                                        :rules="{
                                            required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                        }"
                                        >
                                        <el-select v-model="form.typeTransport" placeholder="Choisie nature de transport" style="width: 100%;">
                                            <el-option label="Routier International" value="Routier International"></el-option>
                                            <el-option label="Routier National" value="Routier National"></el-option>
                                            <el-option label="Maritime" value="Maritime"></el-option>
                                            <el-option label="Aérien" value="Aérien"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item 
                                        prop="incoterm"
                                        class="col-lg-4" 
                                        label="Choisie l'incoterm"
                                        :rules="{
                                            required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                        }"
                                        >
                                        <el-select v-model="form.incoterm" placeholder="Choisie nature de transport" style="width: 100%;">
                                            <el-option label="EX-WORKS (à l'usine)" value="EX-WORKS (à l'usine)"></el-option>
                                            <el-option label="FCA – Free Carrier (franco transporteur)" value="FCA – Free Carrier (franco transporteur)"></el-option>
                                            <el-option label="Les Incoterms® CPT et CIP" value="Les Incoterms® CPT et CIP"></el-option>
                                            <el-option label="Les Incoterms® DAP, DPU et DDP" value="Les Incoterms® DAP, DPU et DDP"></el-option>
                                        </el-select>
                                    </el-form-item>
                            </div>
                        </fieldset>
                        <!-- les Articles -->
                        <!--End Articles  -->
                       
                        <fieldset class="border p-2 col-lg-6">
                            <legend class="w-auto badge"><h5> Certificat d'origine </h5></legend>
                            <el-form-item 
                                class=" col-lg-12 text-center"
                                prop="certificat"
                                :rules="[
                                        { required: true, message: 'Ce champ est obligatoire'}
                                    ]"
                            >
                                <el-radio-group v-model="form.certificat">
                                    <el-radio label="OUI"></el-radio>
                                    <el-radio label="NON"></el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item
                                v-if="form.certificat == 'NON'"
                                label="Saisie la Remarque"
                                prop="remarque"
                                
                            >
                                <el-input v-model="form.remarque1" type="textarea" placeholder="Saisie votre remarque ici"></el-input>
                            </el-form-item>
                        </fieldset> 
                        <fieldset class="border p-2 col-lg-6">
                            <legend class="w-auto badge"><h5> Autorisation de l'etat </h5></legend>
                            <el-form-item 
                                class=" col-lg-12 text-center"
                                prop="autorisation"
                                :rules="[
                                        { required: true, message: 'Ce champ est obligatoire'}
                                    ]"
                            >
                                <el-radio-group v-model="form.autorisation">
                                    <el-radio label="OUI"></el-radio>
                                    <el-radio label="NON"></el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item
                                v-if="form.autorisation == 'NON'"
                                label="Saisie la Remarque"
                                prop="remarque"
                                
                            >
                                <el-input v-model="form.remarque2" type="textarea" placeholder="Saisie votre remarque ici"></el-input>
                            </el-form-item>
                        </fieldset> 
                        
                    </div>
                  </el-form>
        
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false">Annuler</el-button>
                    <el-button  type="success" @click="dialogVisible = false">Confirmer</el-button>
                </span>
        </el-dialog> 
        <!-- End Facture Pro -->
        <!-- Modal Facture difinitive -->
        <el-dialog title="Saisie La Liste De Colisage" :visible.sync="dialogVisiblePalette" width="90%" :before-close="handleCloseFD">
            <el-form :model="formColise" >
                        <!-- Packing Liste -->
                        <fieldset class="border p-2">
                            <legend class="w-auto badge"><h5> </h5></legend>
                            
                                 <fieldset class="border p-2 col-lg-12" >
                                    <legend class="w-auto badge"><h5> Information De Pallet </h5></legend>
                                    <div class="row">
                                        <el-form-item 
                                            class="col-lg-3"
                                            label="Code Palete"
                                            prop="codePalet"
                                        >
                                            <el-input v-model="formColise.codePalet" placeholder="Code Palete"></el-input>
                                        </el-form-item>
                                        <el-form-item 
                                            class="col-lg-3"
                                            label="Quantite"
                                            prop="QtitBobine"
                                        >
                                            <el-input v-model="formColise.QtitBobine" placeholder="Quantite"></el-input>
                                        </el-form-item>
                                        <el-form-item 
                                            class="col-lg-3"
                                            label="Unite"
                                            prop="TypeUnite"
                                        >
                                            <el-select v-model="formColise.TypeUnite" style="width: 100%" placeholder="Choisir Type">
                                                <el-option label="Carton" value="Carton"></el-option>
                                                <el-option label="Bobine" value="Bobine"></el-option>
                                            </el-select>
                                        </el-form-item>
                                        <el-form-item
                                            class="col-lg-3"
                                            label="Laize"
                                            prop="laize"
                                        >
                                            <el-input v-model="formColise.laize" placeholder="Laize En(mm)"></el-input>
                                        </el-form-item>
                                        <el-form-item
                                            class="col-lg-3"
                                            label="ML/MTL"
                                            prop="mtl"
                                        >
                                            <el-input v-model="formColise.mtl" placeholder="ML/MTL"></el-input>
                                        </el-form-item>
                                        <el-form-item
                                            class="col-lg-3"
                                            label="MQ/SQM"
                                            prop="sqm"
                                        >
                                            <el-input v-model="formColise.sqm" placeholder="MQ/SQM"></el-input>
                                        </el-form-item>
                                        <el-form-item
                                            class="col-lg-3"
                                            label="Poids Brut"
                                            prop="PoidsBrutTotal"
                                        >
                                            <el-input  v-model="formColise.PoidsBrutTotal" placeholder = "Poids Brut"></el-input>
                                        </el-form-item>
                                        <el-form-item
                                            class="col-lg-3"
                                            label="Poids Net"
                                            prop="PoidsNetTotal"
                                        >
                                            <el-input  v-model="formColise.PoidsNetTotal" placeholder = "Poids Net"></el-input>
                                        </el-form-item>
                                        
                                        </div>
                                </fieldset>
                                <fieldset class="border p-2 col-lg-12 mt-3">
                                    <legend class="w-auto badge"><h5> Ajouter Bobines </h5></legend>
                                    <!--  -->
                                    <tr class="form-group padding: 7px; col-lg-12" v-for="(c, k) in formColise.bobines" :key="k">

                                            <td style="padding: 7px;">
                                                <label>Code Bobine</label>
                                                <input class="form-control" placeholder="Code Bobine" type="number" v-model="c.code_bobine"
                                                />
                                            </td>
                                            <td style="padding: 7px;">
                                                <label>Laize bobine en (mm)</label>
                                                <input class="form-control" placeholder="Laize bobine en (mm)" type="number" v-model="c.Laize"
                                                />
                                            </td>
                                            <td style="padding: 7px;">
                                                <label>ML/MTL</label>
                                                <input class="form-control" placeholder="ML/MTL" type="number"  v-model="c.mtl"
                                                />
                                            </td>
                                            <td style="padding: 7px;">
                                                <label>MQ/SQM</label>
                                                <input class="form-control" placeholder="MQ/SQM" type="number"  v-model="c.sqm"
                                                />
                                            </td>
                                            <td style="padding: 7px;">
                                                <label>Ref</label>
                                                <input class="form-control" placeholder="Ref.Bobine" type="text"  v-model="c.ref_bobine"
                                                />
                                            </td>
                                            

                                            <td scope="row" class="trashIconContainer">
                                                <svg
                                                    v-show="formColise.bobines.length > 1"
                                                    @click="deleteRow(k, c)"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 24 24"
                                                    width="24"
                                                    height="24"
                                                    class="ml-2 cursor-pointer"
                                                >
                                                    <path fill="none" d="M0 0h24v24H0z" />
                                                    <path
                                                    fill="#EC4899"
                                                    d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
                                                    />
                                                </svg>
                                            </td>
                                        </tr>
                                        <div class="form-group ml-2 float-right" v-if="indexForm < formColise.QtitBobine">
                                            <svg  
                                                @click="addNewRow"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 24 24"
                                                    width="24"
                                                    height="24"
                                                    class="ml-2 cursor-pointer"
                                                    >
                                                    <path fill="none" d="M0 0h24v24H0z" />
                                                    <path
                                                    fill="green"
                                                    d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                                                    />
                                                </svg>
                                            </div>
                                    <!--  -->
                            <!-- End Bobines -->                       
                            
                                </fieldset> 
                                
                        </fieldset> 
                            <!-- End Packing liste -->
                        
                  </el-form>

                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisiblePalette = false">Annuler</el-button>
                    <el-button  type="success" v-show="!editmode" @click="createPalette()">Valider</el-button>
                    <el-button  type="success" v-show="editmode" @click="SaveupdatePalette()">Modifier</el-button>
                </span>

        </el-dialog>
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog modal-xl" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Saisie la facture definitive</h5>
                    <h5 class="modal-title" v-show="editmode">Modifier</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                
                </div>
            </div>
        </div>
        <!-- Modal Plaque  -->
        <!-- Modal Transport -->
        <div class="modal fade" id="addNewTransport" tabindex="-1" role="dialog" aria-labelledby="addNewTransport" aria-hidden="true">
            <div class="modal-dialog modal-xl" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Saisie Les documents de transport</h5>
                    <h5 class="modal-title" v-show="editmode">Modifier Les documents de transport</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <el-form :model="form" ref="form" >
                    <div class="modal-body">
                        <fieldset class="border p-2 col-lg-12">
                            <legend class="w-auto badge"><h5>Informations De Transport </h5></legend>
                            
                            <div class="row">
                                    <el-form-item 
                                        prop="Date"
                                        class="col-lg-4" 
                                        label="Type de Transport"
                                        :rules="{
                                            required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                        }"
                                        >
                                        <el-select v-model="form.typeTransport" placeholder="Choisir type de transport" style="width: 100%;">
                                            <el-option label="Routier International" value="Routier International"></el-option>
                                            <el-option label="Routier National" value="Routier National"></el-option>
                                            <el-option label="Maritime" value="Maritime"></el-option>
                                            <el-option label="Aérienne" value="Aérienne"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item 
                                        label="Date Chargement"
                                        class="col-lg-4" 
                                        prop="DateChargement"
                                        :rules="{
                                            required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                        }"
                                        >
                                        <el-date-picker placeholder="Choisie la date" v-model="form.Datechargement" style="width: 100%;"></el-date-picker>
                                    </el-form-item>
                                    <el-form-item 
                                        label="Nom de Compagnie"
                                        class="col-lg-4" 
                                        prop="societeTransport"
                                        :rules="{
                                            required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                        }"
                                        >
                                        <el-input placeholder="Nom de Compagnie" v-model="form.societeTransport" style="width: 100%;"></el-input>
                                    </el-form-item>
                                    <el-form-item 
                                        v-if="form.typeTransport == 'Routier National'"
                                        label="N° Bon de transport"
                                        class="col-lg-3" 
                                        prop="numBonTransport"
                                        :rules="{
                                            required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                        }"
                                        >
                                        <el-input placeholder="Societe Transport" v-model="form.numBonTransport" style="width: 100%;"></el-input>
                                    </el-form-item>
                                    <el-form-item 
                                        v-if="form.typeTransport == 'Routier National'"
                                        label="De"
                                        class="col-lg-3" 
                                        >
                                        <el-input placeholder="Ville 1" v-model="form.target1" style="width: 100%;"></el-input>
                                    </el-form-item>
                                    <el-form-item 
                                        v-if="form.typeTransport == 'Routier National'"
                                        label="à"
                                        class="col-lg-3" 
                                        >
                                        <el-input placeholder="Ville 2" v-model="form.target2" style="width: 100%;"></el-input>
                                    </el-form-item>
                                    <el-form-item 
                                        v-if="form.typeTransport == 'Routier National'"
                                        label="N° Facture"
                                        class="col-lg-3" 
                                        >
                                        <el-input placeholder="Ville 2" v-model="form.numfacture" style="width: 100%;"></el-input>
                                    </el-form-item>
                                    <el-form-item 
                                        v-if="form.typeTransport == 'Routier International'"
                                        label="N° CMR"
                                        class="col-lg-6" 
                                        prop="NumCMR"
                                        :rules="{
                                            required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                        }"
                                        >
                                        <el-input placeholder="N° CMR" v-model="form.NumCMR" style="width: 100%;"></el-input>
                                    </el-form-item>
                                    <el-form-item 
                                        v-if="form.typeTransport == 'Routier International'"
                                        label="Joindr CMR"
                                        class="col-lg-6" 
                                        prop="joindrCMR"
                                        >
                                        
                                        <el-upload
                                            style="width: fit-content;"
                                            class="upload-demo"
                                            ref="upload"
                                            action="https://jsonplaceholder.typicode.com/posts/"
                                            :auto-upload="false">
                                            <el-button slot="trigger" size="small" type="primary">Select Fichier</el-button>
                                            <!-- <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">upload to server</el-button> -->
                                        </el-upload>
                                    </el-form-item> 
                                </div>
                            </fieldset>
                            <!-- Aérienne -->
                            <fieldset class="border p-2 col-lg-12"  v-if="form.typeTransport == 'Aérienne'">
                                <legend class="w-auto badge"><h5>AÉRIENNE</h5></legend>
                                <div class="row">
                                    <el-form-item 
                                        label="N° Colis :"
                                        class="col-lg-3" 
                                        prop="numColis"
                                        :rules="{
                                            required: true, type: 'text', message: 'Ce champ est obligatoire', trigger: 'blur'
                                        }"
                                        >
                                        <el-input placeholder="N° Colis" v-model="form.numColis" style="width: 100%;"></el-input>
                                    </el-form-item>
                                    
                                    <el-form-item label="Saisie les 3 Barre Code :" class="col-lg-9">
                                       <div class="row" style="width: 100%;">
                                            <el-input placeholder="Barre Code 1" class="col-4" v-model="form.barreCode1"></el-input>
                                            <el-input placeholder="Barre Code 2" class="col-4" v-model="form.barreCode2"></el-input>
                                            <el-input placeholder="Barre Code 3" class="col-4" v-model="form.barreCode3"></el-input>
                                       </div>
                                    </el-form-item>
                                </div>
                            </fieldset>
                            <fieldset class="border p-2 col-lg-12"  v-if="form.typeTransport == 'Aérienne'">
                                <legend class="w-auto badge"><h5>Reçu D'expédition</h5></legend>
                                <div class="row">
                                    <el-form-item 
                                        label="N° Bordereau"
                                        class="col-lg-3" 
                                        prop="numBordereau"
                                        :rules="{
                                            required: true, type: 'text', message: 'Ce champ est obligatoire', trigger: 'blur'
                                        }"
                                        >
                                        <el-input placeholder="N° Bordereau" v-model="form.numBordereau" style="width: 100%;"></el-input>
                                    </el-form-item>
                                    <el-form-item 
                                        label="Date Bordereau"
                                        class="col-lg-3" 
                                        prop="dateBordereau"
                                        :rules="{
                                            required: true, type: 'text', message: 'Ce champ est obligatoire', trigger: 'blur'
                                        }"
                                        >
                                        <el-date-picker placeholder="Date Bordereau" v-model="form.dateBordereau" style="width: 100%;"></el-date-picker>
                                    </el-form-item>
                                    <el-form-item 
                                        label="La Valeur Declarée En EURO"
                                        class="col-lg-3" 
                                        prop="ValeurEURO"
                                        :rules="{
                                            required: true, type: 'text', message: 'Ce champ est obligatoire', trigger: 'blur'
                                        }"
                                        >
                                        <el-input placeholder="La Valeur Declarée En EURO" v-model="form.ValeurEURO" style="width: 100%;"></el-input>
                                    </el-form-item>
                                    <el-form-item 
                                        label="Poids Total"
                                        class="col-lg-3" 
                                        prop="poidsTotal"
                                        :rules="{
                                            required: true, type: 'text', message: 'Ce champ est obligatoire', trigger: 'blur'
                                        }"
                                        >
                                        <el-input placeholder="Poids Total" v-model="form.poidsTotal" style="width: 100%;"></el-input>
                                    </el-form-item>
                                    <el-form-item 
                                        label="Poids Volumétrique"
                                        class="col-lg-3" 
                                        prop="poidsVolumétrique"
                                        :rules="{
                                            required: true, type: 'text', message: 'Ce champ est obligatoire', trigger: 'blur'
                                        }"
                                        >
                                        <el-input placeholder="Poids Volumétrique" v-model="form.poidsVolumétrique" style="width: 100%;"></el-input>
                                    </el-form-item>
                                    <el-form-item 
                                        label="Poids Facturable"
                                        class="col-lg-3" 
                                        prop="poidsFacturable"
                                        :rules="{
                                            required: true, type: 'text', message: 'Ce champ est obligatoire', trigger: 'blur'
                                        }"
                                        >
                                        <el-input placeholder="Poids Facturable" v-model="form.poidsFacturable" style="width: 100%;"></el-input>
                                    </el-form-item>
                                    <el-form-item 
                                        prop="incoterm"
                                        label="Incoterm"
                                        :rules="{
                                            required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                        }"
                                        >
                                        <el-select v-model="form.incoterm" placeholder="Choisir L'Incoterm" style="width: 100%;">
                                            <el-option label="EX-WORKS (à l'usine)" value="EX-WORKS (à l'usine)"></el-option>
                                            <el-option label="FCA – Free Carrier (franco transporteur)" value="FCA – Free Carrier (franco transporteur)"></el-option>
                                            <el-option label="Les Incoterms® CPT et CIP" value="Les Incoterms® CPT et CIP"></el-option>
                                            <el-option label="Les Incoterms® DAP, DPU et DDP" value="Les Incoterms® DAP, DPU et DDP"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </fieldset>
                            <fieldset class="border p-2 col-lg-12"  v-if="form.typeTransport == 'Aérienne'">
                                <legend class="w-auto badge"><h5>Droit d'importation douane DHL</h5></legend>
                                <div class="row">
                                    <el-form-item 
                                        label="N° de Déclaration"
                                        class="col-lg-3" 
                                        prop="numDeclaration"
                                        :rules="{
                                            required: true, type: 'text', message: 'Ce champ est obligatoire', trigger: 'blur'
                                        }"
                                        >
                                        <el-input placeholder="N° de Déclaration" v-model="form.numDeclaration" style="width: 100%;"></el-input>
                                    </el-form-item>
                                    <el-form-item 
                                        label="Date Déclaration"
                                        class="col-lg-3" 
                                        prop="dateDeclaration"
                                        :rules="{
                                            required: true, type: 'text', message: 'Ce champ est obligatoire', trigger: 'blur'
                                        }"
                                        >
                                        <el-date-picker placeholder="Date Déclaration" v-model="form.dateDeclaration" style="width: 100%;"></el-date-picker>
                                    </el-form-item>
                                    <el-form-item 
                                        label="N° de liquidation"
                                        class="col-lg-3" 
                                        prop="numLiquidation"
                                        :rules="{
                                            required: true, type: 'text', message: 'Ce champ est obligatoire', trigger: 'blur'
                                        }"
                                        >
                                        <el-input placeholder="N° de liquidation" v-model="form.numLiquidation" style="width: 100%;"></el-input>
                                    </el-form-item>
                                    <el-form-item 
                                        label="Date liquidation"
                                        class="col-lg-3" 
                                        prop="dateliquidation"
                                        :rules="{
                                            required: true, type: 'text', message: 'Ce champ est obligatoire', trigger: 'blur'
                                        }"
                                        >
                                        <el-date-picker placeholder="Date liquidation" v-model="form.dateliquidation" style="width: 100%;"></el-date-picker>
                                    </el-form-item>
                                    <el-form-item 
                                        label="Total valeur en DH"
                                        class="col-lg-3" 
                                        prop="ValeurDH"
                                        :rules="{
                                            required: true, type: 'text', message: 'Ce champ est obligatoire', trigger: 'blur'
                                        }"
                                        >
                                        <el-input placeholder="Total valeur en DH" v-model="form.ValeurDH" style="width: 100%;"></el-input>
                                    </el-form-item>
                                    <el-form-item 
                                        label="Redevance informatique en DH"
                                        class="col-lg-3" 
                                        prop="redevanceInfo"
                                        :rules="{
                                            required: true, type: 'text', message: 'Ce champ est obligatoire', trigger: 'blur'
                                        }"
                                        >
                                        <el-input placeholder="Redevance informatique en DH" v-model="form.redevanceInfo" style="width: 100%;"></el-input>
                                    </el-form-item>
                                    <el-form-item 
                                        label="RI SEGMA en DH "
                                        class="col-lg-3" 
                                        prop="RiSegmaDH"
                                        :rules="{
                                            required: true, type: 'text', message: 'Ce champ est obligatoire', trigger: 'blur'
                                        }"
                                        >
                                        <el-input placeholder="RI SEGMA en DH " v-model="form.RiSegmaDH" style="width: 100%;"></el-input>
                                    </el-form-item>
                                    <el-form-item 
                                        label="TVA IMPORT AUTRE PDS"
                                        class="col-lg-3" 
                                        prop="tvaImportPDS"
                                        :rules="{
                                            required: true, type: 'text', message: 'Ce champ est obligatoire', trigger: 'blur'
                                        }"
                                        >
                                        <el-input placeholder="TVA IMPORT AUTRE PDS" v-model="form.tvaImportPDS" style="width: 100%;"></el-input>
                                    </el-form-item>
                                    <el-form-item 
                                        label="SOUS - TOTAL"
                                        class="col-lg-3" 
                                        prop="sousTotal"
                                        :rules="{
                                            required: true, type: 'text', message: 'Ce champ est obligatoire', trigger: 'blur'
                                        }"
                                        >
                                        <el-input placeholder="SOUS - TOTAL" v-model="form.sousTotal" style="width: 100%;"></el-input>
                                    </el-form-item>
                                    <el-form-item 
                                        label="REMISES CREDIT"
                                        class="col-lg-3" 
                                        prop="remisesCredit"
                                        :rules="{
                                            required: true, type: 'text', message: 'Ce champ est obligatoire', trigger: 'blur'
                                        }"
                                        >
                                        <el-input placeholder="REMISES CREDIT" v-model="form.remisesCredit" style="width: 100%;"></el-input>
                                    </el-form-item>
                                    <el-form-item 
                                        label="Total"
                                        class="col-lg-3" 
                                        prop="total"
                                        :rules="{
                                            required: true, type: 'text', message: 'Ce champ est obligatoire', trigger: 'blur'
                                        }"
                                        >
                                        <el-input placeholder="Total" v-model="form.total" style="width: 100%;"></el-input>
                                    </el-form-item>
                                    <el-form-item 
                                        label="Droit de Timbre"
                                        class="col-lg-3" 
                                        prop="droitTimbre"
                                        :rules="{
                                            required: true, type: 'text', message: 'Ce champ est obligatoire', trigger: 'blur'
                                        }"
                                        >
                                        <el-input placeholder="Droit de Timbre" v-model="form.droitTimbre" style="width: 100%;"></el-input>
                                    </el-form-item>
                                    <el-form-item 
                                        label="Quittance Douanière"
                                        class="col-lg-6" 
                                        prop="quittanceDouanière"
                                        >
                                        <el-upload
                                            style="width: fit-content;"
                                            class="upload-demo"
                                            ref="upload"
                                            action="https://jsonplaceholder.typicode.com/posts/"
                                            :auto-upload="false">
                                            <el-button slot="trigger" size="small" type="primary">Select Fichier</el-button>
                                        </el-upload>
                                    </el-form-item>
                                </div>
                            </fieldset>
                            <fieldset class="border p-2 col-lg-12"  v-if="form.typeTransport == 'Aérienne'">
                                <legend class="w-auto badge"><h5>Honoraire De Traitement Douanier "Compagnie Aérienne"</h5></legend>
                                <div class="row">
                                    <el-form-item 
                                        label="N° Facture"
                                        class="col-lg-3" 
                                        prop="numFacture"
                                        :rules="{
                                            required: true, type: 'text', message: 'Ce champ est obligatoire', trigger: 'blur'
                                        }"
                                        >
                                        <el-input placeholder="N° Facture" v-model="form.numFacture" style="width: 100%;"></el-input>
                                    </el-form-item>
                                    <el-form-item 
                                        label="N° de bordereau"
                                        class="col-lg-3" 
                                        prop="numBordereau"
                                        :rules="{
                                            required: true, type: 'text', message: 'Ce champ est obligatoire', trigger: 'blur'
                                        }"
                                        >
                                        <el-input placeholder="N° de bordereau" v-model="form.numBordereau" style="width: 100%;"></el-input>
                                    </el-form-item>
                                    <el-form-item 
                                        label="N° de DUM"
                                        class="col-lg-3" 
                                        prop="numDUM"
                                        :rules="{
                                            required: true, type: 'text', message: 'Ce champ est obligatoire', trigger: 'blur'
                                        }"
                                        >
                                        <el-input placeholder="N° de DUM" v-model="form.numDUM" style="width: 100%;"></el-input>
                                    </el-form-item>
                                    <el-form-item 
                                        label="Date de DUM"
                                        class="col-lg-3" 
                                        prop="dateDUM"
                                        :rules="{
                                            required: true, type: 'text', message: 'Ce champ est obligatoire', trigger: 'blur'
                                        }"
                                        >
                                        <el-date-picker placeholder="Date de DUM" v-model="form.dateDUM" style="width: 100%;"></el-date-picker>
                                    </el-form-item>
                                    <el-form-item 
                                        label="Timbre"
                                        class="col-lg-3" 
                                        prop="timbre"
                                        :rules="{
                                            required: true, type: 'text', message: 'Ce champ est obligatoire', trigger: 'blur'
                                        }"
                                        >
                                        <el-input placeholder="Timbre" v-model="form.timbre" style="width: 100%;"></el-input>
                                    </el-form-item>
                                    <el-form-item 
                                        label="Droit Douaniere"
                                        class="col-lg-3" 
                                        prop="droitDouaniere"
                                        :rules="{
                                            required: true, type: 'text', message: 'Ce champ est obligatoire', trigger: 'blur'
                                        }"
                                        >
                                        <el-input placeholder="Droit Douaniere" v-model="form.droitDouaniere" style="width: 100%;"></el-input>
                                    </el-form-item>
                                    <el-form-item 
                                        label="Frais De Traitment"
                                        class="col-lg-3" 
                                        prop="fraisDeTraitment"
                                        :rules="{
                                            required: true, type: 'text', message: 'Ce champ est obligatoire', trigger: 'blur'
                                        }"
                                        >
                                        <el-input placeholder="Frais De Traitment" v-model="form.fraisDeTraitment" style="width: 100%;"></el-input>
                                    </el-form-item>
                                    <el-form-item 
                                        label="Avance de fonds cash"
                                        class="col-lg-3" 
                                        prop="avanceCash"
                                        :rules="{
                                            required: true, type: 'text', message: 'Ce champ est obligatoire', trigger: 'blur'
                                        }"
                                        >
                                        <el-input placeholder="Avance de fonds cash" v-model="form.avanceCash" style="width: 100%;"></el-input>
                                    </el-form-item>
                                    <el-form-item 
                                        label="TVA Import"
                                        class="col-lg-3" 
                                        prop="tvaImport"
                                        :rules="{
                                            required: true, type: 'text', message: 'Ce champ est obligatoire', trigger: 'blur'
                                        }"
                                        >
                                        <el-input placeholder="TVA Import" v-model="form.tvaImport" style="width: 100%;"></el-input>
                                    </el-form-item>
                                    <el-form-item 
                                        label="Montant a payer DH"
                                        class="col-lg-3" 
                                        prop="montantPayer"
                                        :rules="{
                                            required: true, type: 'text', message: 'Ce champ est obligatoire', trigger: 'blur'
                                        }"
                                        >
                                        <el-input placeholder="Montant a payer DH" v-model="form.montantPayer" style="width: 100%;"></el-input>
                                    </el-form-item>
                                    <el-form-item 
                                        label="Joindre la facture"
                                        class="col-lg-6" 
                                        prop="facturefret"
                                        >
                                        
                                        <el-upload
                                            style="width: fit-content;"
                                            class="upload-demo"
                                            ref="upload"
                                            action="https://jsonplaceholder.typicode.com/posts/"
                                            :auto-upload="false">
                                            <el-button slot="trigger" size="small" type="primary">Select Fichier</el-button>
                                        </el-upload>
                                    </el-form-item>
                                </div>
                            </fieldset>
                            <!-- End Aérienne -->
                            <!-- Martime -->
                            <fieldset class="border p-2 col-lg-12"  v-if="form.typeTransport == 'Maritime'">
                                <legend class="w-auto badge"><h5>MARITIME</h5></legend>
                                <div class="row">
                                    <el-form-item 
                                        label="N° voyage"
                                        class="col-lg-4" 
                                        prop="numVoyage"
                                        :rules="{
                                            required: true, type: 'text', message: 'Ce champ est obligatoire', trigger: 'blur'
                                        }"
                                        >
                                        <el-input placeholder="N° voyage" v-model="form.numVoyage" style="width: 100%;"></el-input>
                                    </el-form-item>
                                    <el-form-item 
                                        label="N° Connaissement"
                                        class="col-lg-4" 
                                        prop="numConnaissement"
                                        :rules="{
                                            required: true, type: 'text', message: 'Ce champ est obligatoire', trigger: 'blur'
                                        }"
                                        >
                                        <el-input placeholder="N° Connaissement" v-model="form.numConnaissement" style="width: 100%;"></el-input>
                                    </el-form-item> 
                                    <el-form-item 
                                            prop="dateConnaissement"
                                            class="col-lg-4" 
                                            label="Date Connaissement"
                                            :rules="{
                                                required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                            }"
                                            >
                                            <el-date-picker placeholder="Date Connaissement" v-model="form.dateConnaissement" style="width: 100%;"></el-date-picker>
                                    </el-form-item>
                                </div>
                            </fieldset>  
                            <fieldset class="border p-2 col-lg-12" >
                                <legend class="w-auto badge"><h5></h5></legend>
                                <div class="row">
                                    <el-form-item 
                                        label="Montant de fret"
                                        class="col-lg-3" 
                                        prop="fretMontant"
                                        :rules="{
                                            required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                        }"
                                        >
                                        <el-input placeholder="Montant de fret" v-model="form.fretMontant" style="width: 100%;"></el-input>
                                    </el-form-item>
                                    
                                    <el-form-item 
                                        label="N° de facture"
                                        class="col-lg-3" 
                                        prop="numFacture"
                                        :rules="{
                                            required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                        }"
                                        >
                                        <el-input placeholder="N° Facture" v-model="form.numFacture" style="width: 100%;"></el-input>
                                    </el-form-item>  
                                    <el-form-item 
                                        label="Total Facture HT"
                                        class="col-lg-3" 
                                        prop="totalFacture"
                                        :rules="{
                                            required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                        }"
                                        >
                                        <el-input placeholder="Total Facture HT" v-model="form.totalFacture" style="width: 100%;"></el-input>
                                    </el-form-item> 

                                    <el-form-item 
                                        label="TVA %"
                                        class="col-lg-3" 
                                        prop="tva"
                                        :rules="{
                                            required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                        }"
                                        >
                                        <el-input placeholder="TVA %" v-model="form.tva" style="width: 100%;"></el-input>
                                    </el-form-item>  

                                    <el-form-item 
                                        label="Montant TTC"
                                        class="col-lg-3" 
                                        prop="ttc"
                                        :rules="{
                                            required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                        }"
                                        >
                                        <el-input placeholder="Montant TTC" v-model="form.ttc" style="width: 100%;"></el-input>
                                    </el-form-item> 
                                    <el-form-item 
                                        label="Facture de fret"
                                        class="col-lg-6" 
                                        prop="facturefret"
                                        >
                                        
                                        <el-upload
                                            style="width: fit-content;"
                                            class="upload-demo"
                                            ref="upload"
                                            action="https://jsonplaceholder.typicode.com/posts/"
                                            :auto-upload="false">
                                            <el-button slot="trigger" size="small" type="primary">Select Fichier</el-button>
                                            <!-- <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">upload to server</el-button> -->
                                        </el-upload>
                                    </el-form-item> 
                                </div>
                            </fieldset>
                                    <!-- Marsa Maroc -->
                            <fieldset class="border p-2 col-lg-12"  v-if="form.typeTransport == 'Maritime'">
                                <legend class="w-auto badge"><h5>Traitement MARSA MAROC</h5></legend>
                                <div class="row">
                                    
                                    <el-form-item 
                                        label="N° Facture"
                                        class="col-lg-3" 
                                        prop="NumFacture"
                                        :rules="{
                                            required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                        }"
                                        >
                                        <el-input placeholder="N° Facture" v-model="form.NumFacture" style="width: 100%;"></el-input>
                                    </el-form-item> 
                                    <el-form-item 
                                            prop="dateMarsa"
                                            class="col-lg-3" 
                                            label="Date "
                                            :rules="{
                                                required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                            }"
                                            >
                                            <el-date-picker placeholder="Date " v-model="form.dateMarsa" style="width: 100%;"></el-date-picker>
                                    </el-form-item>
                                    <el-form-item 
                                        label="Total HT"
                                        class="col-lg-3" 
                                        prop="totalHT"
                                        :rules="{
                                            required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                        }"
                                        >
                                        <el-input placeholder="Total HT" v-model="form.totalHT" style="width: 100%;"></el-input>
                                    </el-form-item> 
                                    <el-form-item 
                                        label="Taux de la Taxe Régionale (%)"
                                        class="col-lg-3" 
                                        prop="TauxTaxRegional"
                                        :rules="{
                                            required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                        }"
                                        >
                                        <el-input placeholder="Taux de la Taxe Régionale %" v-model="form.TauxTaxRegional" style="width: 100%;"></el-input>
                                    </el-form-item> 
                                    <el-form-item 
                                        label="total de taxe"
                                        class="col-lg-3" 
                                        prop="totalTax"
                                        :rules="{
                                            required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                        }"
                                        >
                                        <el-input placeholder="total de taxe" v-model="form.totalTax" style="width: 100%;"></el-input>
                                    </el-form-item> 
                                    <el-form-item 
                                        label="Taux TVA"
                                        class="col-lg-3" 
                                        prop="tauxTVA"
                                        :rules="{
                                            required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                        }"
                                        >
                                        <el-input placeholder="Taux TVA" v-model="form.tauxTVA" style="width: 100%;"></el-input>
                                    </el-form-item> 
                                    <el-form-item 
                                        label="Total TVA"
                                        class="col-lg-3" 
                                        prop="totalTVA"
                                        :rules="{
                                            required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                        }"
                                        >
                                        <el-input placeholder="Total TVA" v-model="form.totalTVA" style="width: 100%;"></el-input>
                                    </el-form-item> 
                                    <el-form-item 
                                        label="Montant TTC"
                                        class="col-lg-3" 
                                        prop="montantTTC"
                                        :rules="{
                                            required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                        }"
                                        >
                                        <el-input placeholder="Montant TTC" v-model="form.montantTTC" style="width: 100%;"></el-input>
                                    </el-form-item> 
                                    <el-form-item 
                                        label="joindre DUM Traitee Au Bureau Douaniere"
                                        class="col-lg-6" 
                                        prop="TmarsaMaroc"
                                        >
                                        <el-upload
                                            style="width: fit-content;"
                                            class="upload-demo"
                                            ref="upload"
                                            action="https://jsonplaceholder.typicode.com/posts/"
                                            :auto-upload="false">
                                            <el-button slot="trigger" size="small" type="primary">Select Fichier</el-button>
                                        </el-upload>
                                    </el-form-item>
                                </div>
                            </fieldset>
                            <fieldset class="border p-2 col-lg-12"  v-if="form.typeTransport == 'Maritime'">
                                <legend class="w-auto badge"><h5>L'autorisation de sortie dans le port</h5></legend>
                                <div class="row">
                                    <el-form-item 
                                            label="N° bon d'autorisation"
                                            class="col-lg-6" 
                                            prop="numBonSortie"
                                            :rules="{
                                                required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                            }"
                                            >
                                            <el-input placeholder="N° bon d'autorisation" v-model="form.numBonSortie" style="width: 100%;"></el-input>
                                    </el-form-item> 
                                    <el-form-item 
                                        label="joindre Bon d'autorisation de sortie dans le port"
                                        class="col-lg-6" 
                                        prop="bonAutorisation"
                                        >
                                        
                                        <el-upload
                                            style="width: fit-content;"
                                            class="upload-demo"
                                            ref="upload"
                                            action="https://jsonplaceholder.typicode.com/posts/"
                                            :auto-upload="false">
                                            <el-button slot="trigger" size="small" type="primary">Select Fichier</el-button>
                                        </el-upload>
                                    </el-form-item>
                                </div>
                            </fieldset>
                                    <!-- End Marsa Maroc -->
                                    <!-- Transporteur -->
                            <fieldset class="border p-2 col-lg-12">
                                <legend class="w-auto badge"><h5>Transporteur </h5></legend>
                                <div class="row">
                                    <el-form-item 
                                        label="Société de transport"
                                        class="col-lg-6" 
                                        prop="societeTransport"
                                        :rules="{
                                            required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                        }"
                                        >
                                        <el-input placeholder="Société de transport" v-model="form.societeTransport" style="width: 100%;"></el-input>
                                    </el-form-item>
                                    <el-form-item 
                                        label="Matricule Camion"
                                        class="col-lg-6" 
                                        prop="matriculeCamion"
                                        :rules="{
                                            required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                        }"
                                        >
                                        <el-input placeholder="Matricule Camion" v-model="form.matriculeCamion" style="width: 100%;"></el-input>
                                    </el-form-item>
                                    <el-form-item 
                                        label="Nom de chauffeur"
                                        class="col-lg-6" 
                                        prop="chauffeurName"
                                        :rules="{
                                            required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                        }"
                                        >
                                        <el-input placeholder="Nom de chauffeur" v-model="form.chauffeurName" style="width: 100%;"></el-input>
                                    </el-form-item>
                                    <el-form-item 
                                        label="Téle de chauffeur"
                                        class="col-lg-6" 
                                        prop="chauffeurTele"
                                        :rules="{
                                            required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                        }"
                                        >
                                        <el-input placeholder="Téle de chauffeur" v-model="form.chauffeurTele" style="width: 100%;"></el-input>
                                    </el-form-item>
                                    <el-form-item 
                                        label="Montant de Transport"
                                        class="col-lg-3" 
                                        prop="montantTransport"
                                        :rules="{
                                            required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                        }"
                                        >
                                        <el-input placeholder="Montant de Transport" v-model="form.montantTransport" style="width: 100%;"></el-input>
                                    </el-form-item>
                                    <el-form-item 
                                        label="N° Facture"
                                        class="col-lg-3" 
                                        prop="montantTransport"
                                        :rules="{
                                            required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                        }"
                                        >
                                        <el-input placeholder="N° Facture" v-model="form.montantTransport" style="width: 100%;"></el-input>
                                    </el-form-item>
                                    <el-form-item 
                                            prop="dateFactureTrans"
                                            class="col-lg-3" 
                                            label="Date Facture"
                                            :rules="{
                                                required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                            }"
                                            >
                                            <el-date-picker placeholder="Date Facture" v-model="form.dateFactureTrans" style="width: 100%;"></el-date-picker>
                                    </el-form-item>
                                    <el-form-item 
                                        label="Total HT"
                                        class="col-lg-3" 
                                        prop="totalHT"
                                        :rules="{
                                            required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                        }"
                                        >
                                        <el-input placeholder="Total HT" v-model="form.totalHT" style="width: 100%;"></el-input>
                                    </el-form-item>
                                    <el-form-item 
                                        label="Taux TVA"
                                        class="col-lg-3" 
                                        prop="tauxTVA"
                                        :rules="{
                                            required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                        }"
                                        >
                                        <el-input placeholder="Taux TVA" v-model="form.tauxTVA" style="width: 100%;"></el-input>
                                    </el-form-item>
                                    <el-form-item 
                                        label="Total TVA"
                                        class="col-lg-3" 
                                        prop="totalTVA"
                                        :rules="{
                                            required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                        }"
                                        >
                                        <el-input placeholder="Total TVA" v-model="form.totalTVA" style="width: 100%;"></el-input>
                                    </el-form-item>
                                    <el-form-item 
                                        label="Montant TTC"
                                        class="col-lg-6" 
                                        prop="montantTTC"
                                        :rules="{
                                            required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                        }"
                                        >
                                        <el-input placeholder="Montant TTC" v-model="form.montantTTC" style="width: 100%;"></el-input>
                                    </el-form-item>
                                    <el-form-item 
                                        label="Facture (joindre)"
                                        class="col-lg-6" 
                                        prop="factureTrans"
                                        >
                                        <el-upload
                                            style="width:fit-content"
                                            class="upload-demo"
                                            ref="upload"
                                            action="https://jsonplaceholder.typicode.com/posts/"
                                            :auto-upload="false">
                                            <el-button slot="trigger" size="small" type="primary">Select Fichier</el-button>
                                        </el-upload>
                                    </el-form-item>
                                </div>
                            </fieldset>
                                    <!-- End Transporteur -->
                            <fieldset class="border p-2 col-lg-12">
                                <legend class="w-auto badge"><h5> Certificat d'origine </h5></legend>
                                <div class="row">
                                    <el-form-item 
                                        label="N° Certificat d'origine"
                                        class="col-lg-6" 
                                        prop="numCertificat"
                                        :rules="{
                                            required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                        }"
                                        >
                                        <el-input placeholder="N° Certificat d'origine" v-model="form.numCertificat" style="width: 100%;"></el-input>
                                    </el-form-item>
                                    <el-form-item 
                                        label="Certificat d'origine (joindre)"
                                        class="col-lg-6" 
                                        prop="fileCertificat"
                                        >
                                        <el-upload
                                            style="width:fit-content"
                                            class="upload-demo"
                                            ref="upload"
                                            action="https://jsonplaceholder.typicode.com/posts/"
                                            :auto-upload="false">
                                            <el-button slot="trigger" size="small" type="primary">Select Fichier</el-button>
                                            <!-- <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">upload to server</el-button> -->
                                            <!-- <div class="el-upload__tip" slot="tip">pdf files</div> -->
                                        </el-upload>
                                    </el-form-item>
                                </div>
                            </fieldset>
                    </div>
                    <div class="modal-footer">
                        <el-button type="danger" size="small" data-dismiss="modal" plain>Annuler</el-button>
                        <el-button type="success" v-show="editmode" size="small" @click="updateProduct()" plain>Valider</el-button>
                        <el-button type="success" v-show="!editmode" size="small" @click="createProduct()" plain>Valider</el-button>
                    </div>
                  </el-form>
                </div>
            </div>
        </div>
        <!-- Modal Transport  -->
        
        <!-- Modal Ordre de fabrication -->
       
    </div>
  </section>
</template>

<script>
import VueQRCodeComponent from 'vue-qrcode-component'
import moment from 'moment'

    export default {
      components: {
            VueQRCodeComponent,
        },
        data () {
            return {
                active: 0,
                dialogVisible: false,
                dialogVisiblePalette: false,
                dialogVisibleDemandeCaution: false,
                dialogVisibleDeclaration: false,
                dialogVisibleValidationDDB: false,
                dialogVisibleDataTraitement: false,
                // 
                search: '',
                pagesize: 10,
                currpage: 1,
                editmode: false,
                Commande:[],
                Remarques:[],
                indexForm: 1,
                // successMSG:'',

                Remarquesform: new Form({
                    id:'',
                // id_user:'',
                   id_commande:this.$route.params.ids,
                   etape:'',
                   message:''
                }),

                Palettes:[],
                formColise: new Form ({
                        // key: 1,
                        id:'',
                        id_Commande: this.$route.params.ids,
                        codePalet:'',
                        QtitBobine:'',
                        TypeUnite:'',
                        laize:'',
                        mtl:'',
                        sqm:'',
                        etat_palette:'',
                        PoidsBrutTotal:'',
                        PoidsNetTotal:'',
                        bobines: [{  code_bobine: "",Laize:"",mtl:"",sqm:"",ref_bobine:"" }],

                    }),

                // End variables

                // to delete after

                consommables: [{ value: 'Ancres'},{ value: 'Assytat'},{ value: 'White spirit'},{ value: 'Huile 46'}],
                matier_premier: [{ value: 'Papier'},{ value: 'P.P'},{ value: 'Fruit tag'},{ value: 'Mandrin en carton'}],
                marchandise : [ { value: 'Cornière en carton'},{ value: 'Filets tubulaires'},{ value: 'Filets de palitisation'},
                                { value: 'Filets cellulosiques'},{ value: 'Feuillards en plastique'},{ value: 'Feuillards métaliques'},
                                { value: 'Barquettes'},{ value: 'Complexes'},{ value: 'Alvéoles'},{ value: 'Agraffes'},
                                { value: 'Papillotes'}],

                tableData: [{
                    codeArticle: 'Art-12205',
                    categorie: 'Matier Premier',
                    souscategorie: 'P.P',
                    designation: 'Designation 1',
                    nomenclature: 'Nomenclature 1',
                    }, {
                    codeArticle: 'Art-5485',
                    categorie: 'Marchandise',
                    souscategorie: 'Braquettes',
                    designation: 'Designation 2',
                    nomenclature: 'Nomenclature 2',
                    }, {
                    codeArticle: 'Art-7783',
                    categorie: 'Matier Premier',
                    souscategorie: 'Fruit tag',
                    designation: 'Designation 3',
                    nomenclature: 'Nomenclature 3',
                    }],

                tableDataTraitement: [{
                    bureauDouanier : 'bureau douanier Tanger N°548',
                    ville: 'Tanger',
                    dumFile : 'la copie de DUM'
                },{
                    bureauDouanier : 'bureau douanier Agadir N° 985',
                    ville: 'Agadir',
                    dumFile : 'la copie de DUM'
                }],

                ValidationDDB: [{
                        id:"1",
                        numDUM: 'DUM-12205',
                        annee: '2022',
                        dateEnregistrement: '01/01/2022',
                        bureau: 'bureau 1',
                        ville: 'tanger',
                        nomenclature: 'nomenclature 1',
                        uniteMesure: 'ML',
                        qtitUnit:'2000',
                        montantDeclare: '24545',
                        poidsNet: '111',
                        poidsBrut: '111',
                        tauxDouanier : '...',
                        montantDouanier : '...',
                        numCaution: '...'
                }],

                tableDataColisage1: [{
                    codeArticle: '00384279731044320814',
                    LaizeMM: '150',
                    QuantitML: '2000',
                    MQ: '340',
                    }, {
                    codeArticle: '00384279731044320838',
                    LaizeMM: '175',
                    QuantitML: '2000',
                    MQ: '350',
                    },{
                    codeArticle: '00384279731044320852',
                    LaizeMM: '150',
                    QuantitML: '2000',
                    MQ: '340',
                    },],

                tableDataColisage: [{
                    codePalete: '0002938744',
                    unite: 'Bobine',
                    nbrUnit : '60',
                    codeUnit: '5469dd',
                    designation: '-------',
                    typeUnite:'KG',
                    uniteIntern: '5000',
                    prixUnit:'--------',
                    prixTotal:'--------',
                    hasChildren: true,
                    bobines: [{
                                codeArticle: '00384279731044320838',
                                LaizeMM: '175',
                                QuantitML: '2000',
                                MQ: '350',
                            },{
                                codeArticle: '00384279731044320852',
                                LaizeMM: '150',
                                QuantitML: '2000',
                                MQ: '340',
                            }]
                },{
                    codePalete: 'PL-84545',
                    unite: 'Bobine',
                    nbrunit : '60',
                    codeUnit: '87777',
                    designation: '-------',
                    typeUnite:'ML',
                    uniteIntern: '2000',
                    prixUnit:'--------',
                    prixTotal:'--------',
                    bobines: [{
                                codeArticle: '00384279731044320814',
                                LaizeMM: '150',
                                QuantitML: '2000',
                                MQ: '340',
                            },]
                }],
                
                // commande
                tableDataCommand: [{
                    id:'1',
                    commandeNumber: 'C_545784654',
                    fournissuer:'Fournisseur 1',
                    societe:'Societe 1',
                    articles:[{
                        article: 'Article 1',
                        designation: 'Designation 1',
                        uniteMesure: 'Unite Mesure 1',
                        quantite: '15'
                    },{
                        article: 'Article 2',
                        designation: 'Designation 2',
                        uniteMesure: 'Unite Mesure 2',
                        quantite: '10'
                    }],
                    devis:'...',
                    pays:'Italy',
                    nomenclature:'...',
                    poidsNet:'150',
                    poidsBrut:'130',
                    dateChargement:'12-12-2021',
                    incoterm:'EX-WORKS (à l\'usine)',
                }],
                
                Souscategorie:[],
                sousCategorieSelected:'',
               
                
                products : [],

                formDDB: new Form({
                    tauxDouanier:'',
                    montantDouanier:'',
                    numCaution:''
                }),

                formDataTraitement: new Form({
                    bureauDouanier:'',
                    ville:'',
                    dumFile:''
                }),

                form: new Form({
                    id : '',
                    fournisseur:'',
                    nbrunit:'',
                     domains: [{
                        key: 1,
                        codeArticle: '',
                        designation:'',
                        souscategorie:'',
                        categorie:'',
                        uniteMesure:'',
                        quantite:''
                    }],
                    
                    // engagement
                    regimeEconomique:'',
                    regimeName: '',
                    // declaration douaniere
                    burreauDUM:'',
                    regimeDUM:'',
                   fournisseurNameDUM: ''
                }),

                

                 formArticle: new Form({

                    prixTotal:'',
                    codeArticle:'',
                    categorie:'',
                    souscategorie:'',
                    designation:'',
                    nomenclature:'',
                    nombreBobine:'',
                    QtitMlBobine:'',
                    qtitML:'',
                    LaizeMM:'',
                    qtitM2:'',
                    unitMesure:'',
                    prixUnit:'',
                    
                }),

                formTeCheck: {
                    id:'1',
                    burreauDUM : 'burreau 1',
                    regimeDUM: 'regime 1',
                    fournisseurNameDUM : 'fournisseur 1',
                    clientNameDUM: 'client 1',
                    NumRCDUM: '7777',
                    ClientAdressDUM: 'ait meloul',
                    designiationArticlesDUM:'agadir',
                    UnitMesureDUM: 'test',
                    PaysOrigineDUM: 'Italy',
                    PaysProvenanceDUM:'Maroc',
                    nomenclatureDUM:'kkk',
                    poidsNetDUM:'500',
                    poidsBrutDUM:'450'
                },

                // style inputs to check
                burreauInput :'',
                RegimeInput:'',
                FournisseurInput:'',
                PoidsBrutInput:'' , 
                PoidsNetInput:'' , 
                NomenclatureInput:'', 
                PaysProvenanceInput:'' , 
                PaysOrigineInput:'' , 
                UniteMesureInput:'', 
                DesignationInput:'',
                AdressClientInput:'',
                RCInput:'',
                ClientNameInput:'',
                //end style inputs to check

                categories: [],

                tag:  '',
                autocompleteItems: [],
                
            }
        },
        methods: {

// Start CRUD
            // Commande CRUD
            loadCommande(){
                
                axios.get("/api/GetOneCommande/"+this.$route.params.ids).then(({ data }) => (this.Commande = data.data));
            },

            // Palettes CRUD
            loadPalettes(){
                axios.get("/api/Getpalettes/"+this.$route.params.ids).then(({ data }) => (this.Palettes = data.data));
            },
            

            NewPaletteColise(){
                // id:'',
                this.formColise.reset();
                this.formColise.bobines= [{  code_bobine: "",Laize:"",mtl:"",sqm:"",ref_bobine:"" }]
            },

            newColise(){
                
                this.NewPaletteColise();
                this.editmode = false;
                this.dialogVisiblePalette = true;
            },



            createPalette(){
              this.$Progress.start();

              this.formColise.post('/api/Palettes')
              .then((data)=>{
                if(data.data.success){
                  this.NewPaletteColise();
                
                  Toast.fire({
                        icon: 'success',
                        title: data.data.message,
                        style:'z-index: 2000;'
                    });
                    this.$Progress.finish();
                    this.loadPalettes();

                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again',
                      style:'z-index: 2000;'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again',
                      style:'z-index: 2000;'
                  });
              })
          },

          updatePalette(palette){
                this.editmode = true;
                this.formColise.reset();
                this.dialogVisiblePalette = true;
                this.formColise.fill(palette);
                this.formColise.bobines = [];
                for (let i = 0; i < palette.get_bobines.length; i++) {
                    this.formColise.bobines.push({
                        code_bobine: palette.get_bobines[i].code_bobine,
                        Laize: palette.get_bobines[i].Laize,
                        mtl: palette.get_bobines[i].mtl,
                        sqm: palette.get_bobines[i].sqm,
                        ref_bobine: palette.get_bobines[i].ref_bobine,
                    })
                }
            },

          SaveupdatePalette(){
              this.$Progress.start();
              this.formColise.put('/api/Palettes/'+this.formColise.id)
              .then((response) => {
                  
                this.dialogVisiblePalette = false;

                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });
                  this.NewPaletteColise();
                  
                  this.$Progress.finish();

                  this.loadPalettes();
              })
              .catch(() => {
                  this.$Progress.fail();
              });

          },

            ValiderPalette(id){
                this.formColise.etat_palette = 'valider'
                this.formColise.id = id
              Swal.fire({
                  title: 'Attention !',
                  text: "vous voulez vraiment Valider cette palette ??",
                  showCancelButton: true,
                  confirmButtonColor: '#67c23a',
                  cancelButtonColor: '#f56c6c',
                  confirmButtonText: 'Oui, Valider ! '
                  }).then((result) => {

                      // Send request to the server
                        if (result.value) {
                            
                              this.formColise.put('/api/ValiderPalettes/'+id).then(()=>{
                                      Swal.fire(
                                      'Validation !',
                                      'Votre Palette a été bien Valider.',
                                      'success'
                                      );
                                   this.loadPalettes();
                              }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                        }
                  })
          },

          deletPalette(id){
              Swal.fire({
                  title: 'Attention !',
                  text: "vous voulez vraiment supprimer cette palette ??",
                  showCancelButton: true,
                  confirmButtonColor: '#67c23a',
                  cancelButtonColor: '#f56c6c',
                  confirmButtonText: 'Oui, supprimez-le ! '
                  }).then((result) => {

                      // Send request to the server
                        if (result.value) {
                              this.formColise.delete('/api/Palettes/'+id).then(()=>{
                                      Swal.fire(
                                      'Supprimé !',
                                      'Votre Palette a été bien supprimé.',
                                      'success'
                                      );
                                   this.loadPalettes();
                              }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                        }
                  })
          },

        deleteRow(index, bobines) {
            this.indexForm--;
            var idx = this.formColise.bobines.indexOf(bobines);

            if (idx > -1) {
                this.formColise.bobines.splice(idx, 1);
            }
        },
        addNewRow() {
            this.indexForm++;
            this.formColise.bobines.push({
                code_bobine: '',
                Laize: '',
                mtl: '',
                sqm: '',
                ref_bobine: '',
            })
        },


        //   End Palettes

        
        // CRUD remarque
    loadRemarques(){
              axios.get("/api/CommandesRemarques/"+this.$route.params.ids).then(({ data }) => (this.Remarques = data.data));
        },

    SendRemarque(etape){
            this.$Progress.start();
            this.Remarquesform.etape = etape;

              this.Remarquesform.post('/api/CommandesRemarques')
              .then((data)=>{
                if(data.data.success){
                //  this.DialogVisible = false

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.$Progress.finish();
                  this.Remarquesform.etape = '';
                  this.Remarquesform.message = '';
                  this.loadRemarques();

                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          },

          deletRemarque(id){
              Swal.fire({
                  title: 'Attention !',
                  text: "vous voulez vraiment supprimer cette Remarque",
                  showCancelButton: true,
                  confirmButtonColor: '#67c23a',
                  cancelButtonColor: '#f56c6c',
                  confirmButtonText: 'Oui, supprimez-le ! '
                  }).then((result) => {

                      // Send request to the server
                        if (result.value) {
                              this.form.delete('/api/CommandesRemarques/'+id).then(()=>{
                                      Swal.fire(
                                      'Supprimé !',
                                      'Votre Remarque a été supprimé.',
                                      'success'
                                      );
                                  this.loadRemarques();
                              }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                        }
                  })
          },
    // End CRUD Remarque 

// End CRUD

        printC() {
            this.$htmlToPaper("printMe");
        },

        next() {
            
            this.active++;
        },

        prev() {
            
            this.active--;
        },

        changeSelectCategorie () {

                    switch (this.formArticle.categorie) {
                        case 'Consommables':
                            this.Souscategorie = this.consommables;
                            
                            break;
                        case 'Matier Premier':
                            this.Souscategorie = this.matier_premier;
                           
                            break;
                        case 'Marchandise':
                            this.Souscategorie = this.marchandise;
                           
                            break;
                        }
                },
        changeSelectSousCategorie () {
                if(this.formArticle.souscategorie == 'Papier' || this.formArticle.souscategorie == 'P.P' || this.formArticle.souscategorie == 'Fruit tag')
                {
                    this.sousCategorieSelected = 'mp1'
                }
                else if(this.formArticle.souscategorie == 'Mandrin en carton')
                {
                    this.sousCategorieSelected = 'mp2'
                }
                else if(this.formArticle.souscategorie == 'Ancres' || this.formArticle.souscategorie == 'Assytat' || this.formArticle.souscategorie == 'White Spirit' || this.formArticle.souscategorie == 'Huile 46')
                {
                    this.sousCategorieSelected = 'consomables'
                }
                // else(this.formArticle.souscategorie == 'Cornière en Carton')
                else
                {
                    this.sousCategorieSelected = 'march1'
                }
                
            },

        

        //  confirmation closing dialog element
        handleClose(done) {
                this.$confirm('Voulez-vous vraiment fermer cette boîte de dialogue ?')
                .then(_ => {
                    done();
                })
                .catch(_ => {});
            },

        handleCloseFD(done) {
                this.$confirm('Voulez-vous vraiment fermer cette boîte de dialogue ?')
                .then(_ => {
                    done();
                })
                .catch(_ => {});
            },

            regimeChange(){
                if(this.form.regimeEconomique == '10'){
                    this.form.regimeName = 'Mise a la consomation'
                }else if(this.form.regimeEconomique == '22'){
                    this.form.regimeName = 'Transformation en AT'
                }else if(this.form.regimeEconomique == '37'){
                    this.form.regimeName = 'Achat international en AT depôt privé'
                }else if(this.form.regimeEconomique == '81'){
                    this.form.regimeName = 'local AT depôt privé'
                }
            },

            checkDUMText(e){
                
                
                if(this.formTeCheck.burreauDUM == this.form.burreauDUM){
                    this.burreauInput = 'success';
                   
                }else{
                    this.burreauInput = 'danger';
                }

            },
            checkDUMRegime(){
                    
                if(this.formTeCheck.regimeDUM == this.form.regimeDUM){
                    this.RegimeInput = 'success';
                   
                }else{
                    this.RegimeInput = 'danger';
                }
            },

            checkDUMFournisseur(){
                    
                if(this.formTeCheck.fournisseurNameDUM == this.form.fournisseurNameDUM){
                    this.FournisseurInput = 'success';
                   
                }else{
                    this.FournisseurInput = 'danger';
                }
            },
            // Facture Pro
            removeArticle(item) {
                var index = this.form.Articles.indexOf(item);
                if (index !== -1) {
                this.form.Articles.splice(index, 1);
                }
            },
            addArticle() {
                this.form.Articles.push({
                key: Date.now(),
                codeArticle: '',
                designation:'',
                uniteMesure:'',
                quantite:''
                });
            },
            newModalFacturePro(){
                this.editmode = false;
                this.form.reset();
                $('#addNewFacturePro').modal('show');
            },

            // End Facture Pro
            // add articles
            removeDomain(item) {
                this.indexForm--;
                var index = this.form.domains.indexOf(item);
                if (index !== -1) {
                this.form.domains.splice(index, 1);
                }
            },
            addDomain() {
                this.indexForm++;
                this.form.domains.push({
                key: Date.now(),
                codeArticle: '',
                designation:'',
                uniteMesure:'',
                quantite:''
                });
            },

            saveDomain() {
                this.indexForm = 1;
                this.form.domains = [{
                        key: 1,
                        codeArticle: '',
                        designation:'',
                        souscategorie:'',
                        categorie:'',
                        uniteMesure:'',
                        quantite:''
                    }],
                this.formColise.nbrunit = '',
                this.formColise.codePalet = ''
            },
            // end articles
            // add Packing liste
            removePacking(item) {
                var index = this.form.Packings.indexOf(item);
                if (index !== -1) {
                this.form.Packings.splice(index, 1);
                }
            },
            addPacking() {
                this.form.Packings.push({
                key: Date.now(),
                codeArticle: '',
                designation:'',
                uniteMesure:'',
                quantite:''
                });
            },
            // end Packing liste
            // timer
            countToTen() {
            this.started = true
            // if (this.result < 10)
                this.timeout1 = setTimeout(() => {
                this.result1 += 1
                this.countToTen();
                }, 1000)
            },

            stopCountToTen() {
                this.formProduction.productionTime = this.result1
                clearTimeout(this.timeout1)
                this.started = false
                this.result1 = 0
            },

            


          loadProducts(){

            // if(this.$gate.isAdmin()){
              axios.get("api/product").then(({ data }) => (this.products = data.data));
            // }
          },
          loadCategories(){
              axios.get("/api/category/list").then(({ data }) => (this.categories = data.data));
          },
          loadTags(){
              axios.get("/api/tag/list").then(response => {
                  this.autocompleteItems = response.data.data.map(a => {
                      return { text: a.name, id: a.id };
                  });
              }).catch(() => console.warn('Oh. Something went wrong'));
          },
          editModal(product){
              this.editmode = true;
              this.form.reset();
              $('#addNew').modal('show');
              this.form.fill(product);
          },
          newModal(){
              this.editmode = false;
              this.form.reset();
              $('#addNew').modal('show');
          },

          newModalTransport(){
              this.editmode = false;
              this.form.reset();
              $('#addNewTransport').modal('show');
          },

           newModalMagasin(){
              this.editmode = false;
              this.formMagasin.reset();
              $('#addNewMagasin').modal('show');
          },
          newModalProduction(){
              this.editmode = false;
              this.formProduction.reset();
              $('#addNewProduction').modal('show');
          },
          newModalProduction2(){
              this.editmode = false;
              this.formProduction.reset();
              $('#addNewProduction2').modal('show');
          },
          newModalP(){
              this.editmode = false;
              this.form.reset();
              $('#addNewP').modal('show');
          },
          createProduct(){
              this.$Progress.start();

              this.form.post('api/product')
              .then((data)=>{
                if(data.data.success){
                  $('#addNew').modal('hide');

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.$Progress.finish();
                  this.loadProducts();

                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          },
          updateProduct(){
              this.$Progress.start();
              this.form.put('api/product/'+this.form.id)
              .then((response) => {
                  // success
                  $('#addNew').modal('hide');
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });
                  this.$Progress.finish();
                      //  Fire.$emit('AfterCreate');

                  this.loadProducts();
              })
              .catch(() => {
                  this.$Progress.fail();
              });

          },
          ConfermReceptionModal(id){
              Swal.fire({
                  title: 'Confirmation !',
                  text: "Confirmer la réception de form de découpe et cliché",
                  showCancelButton: true,
                  confirmButtonColor: '#67c23a',
                  cancelButtonColor: '#f56c6c',
                  confirmButtonText: 'Oui, Confirmé ! '
                  }).then((result) => {

                      // Send request to the server
                        if (result.value) {
                              this.form.delete('api/product/'+id).then(()=>{
                                      Swal.fire(
                                      'Confirmé !',
                                      'Confirmation Valider',
                                      'success'
                                      );
                                  // Fire.$emit('AfterCreate');
                                  this.loadProducts();
                              }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                        }
                  })
          },
          deletModal(id){
              Swal.fire({
                  title: 'Attention !',
                  text: "vous voulez vraiment supprimer ce devis",
                  showCancelButton: true,
                  confirmButtonColor: '#67c23a',
                  cancelButtonColor: '#f56c6c',
                  confirmButtonText: 'Oui, supprimez-le ! '
                  }).then((result) => {

                      // Send request to the server
                        if (result.value) {
                              this.form.delete('api/product/'+id).then(()=>{
                                      Swal.fire(
                                      'Supprimé !',
                                      'Votre fichier a été supprimé.',
                                      'success'
                                      );
                                  // Fire.$emit('AfterCreate');
                                  this.loadProducts();
                              }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                        }
                  })
          },
        

// Upload Image
          submitUpload() {
        this.$refs.upload.submit();
      },

         handleCurrentChange(cpage) {
                this.currpage = cpage;
            },
        handleSizeChange(psize) {
                this.pagesize = psize;
            },


        },
        mounted() {

        },
        created() {
            this.$Progress.start();

            this.loadCommande();
            this.loadPalettes();
            this.loadRemarques();

            this.$Progress.finish();
        },
        filters: {
            // 
            minutesAndSeconds (value) {
                var minutes = Math.floor(parseInt(value, 10) / 60)
                var seconds = parseInt(value, 10) - minutes * 60
                return `${minutes} minutes : ${seconds} seconds`
            },
            // 
            RemarqueDates(value){
                return moment(String(value)).format('MM/DD/YYYY HH:mm')
            }
        },
        computed: {
          
        },
    }
</script>
<style >
.scan-confirmation {
  position: absolute;
  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 255, .8);

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}
</style>