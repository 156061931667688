<template>
    <section class="content">
<!-- {{ RapProductPerson }} -->
      <div class="container-fluid">

        <el-tabs>
            <el-tab-pane label="Products">
                <el-card class="mb-3 mt-3">
                <div class="row">
                <div class="col-6">
                    <!-- <h4 class="ml-3">Fournisseur</h4> -->
                    <el-button type="primary" @click="newData()" size="small" icon="el-icon-plus" >Add New Fournisseur</el-button>
                </div>
                    <div class="col-12 mt-3">
                        <el-table
                                :data="Fournisseurs.data" border >
                                <el-table-column align="center" label="Entreprise">
                                    <template slot-scope="scope">
                                        <strong>{{ scope.row.brandName }}</strong>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" label="Full Name">
                                    <template slot-scope="scope">
                                        <strong>{{ scope.row.name }}</strong>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" label="Adress">
                                    <template slot-scope="scope">
                                        <strong>{{ scope.row.adress }}</strong>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" label="Tele">
                                    <template slot-scope="scope">
                                        <strong>{{ scope.row.tele }}</strong>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" label="Email">
                                    <template slot-scope="scope">
                                        <strong>{{ scope.row.email }}</strong>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center">
                                    <template slot-scope="{row}">
                                        <el-divider direction="vertical"></el-divider>
                                            <a href="#" @click="updateFournisseur(row)" data-toggle="tooltip" data-placement="top" title="Modifier">
                                                    <i class="fa fa-edit blue mr-2"></i>
                                            </a>
                                            <el-divider direction="vertical"></el-divider>
                                            
                                            <a href="#" @click="deletData(row.id)" data-toggle="tooltip" data-placement="top" title="Supprimer">
                                                <i class="fa fa-trash red"></i>
                                            </a>
                                    </template>
                                </el-table-column>
                        </el-table>
                    <pagination class="mt-4" :data="Fournisseurs" @pagination-change-page="loadData"></pagination>
                    </div>
                </div>
          </el-card>
            </el-tab-pane>
            <el-tab-pane label="Rapport">
                <el-card class="mb-3 mt-3">
                <div class="row">
                    <div class="col-4">
                        <div class="row mb-3">
                            <div class="col-12">
                                <el-select @change="loadSUMByMonth" v-model="month" style="width: 100%" placeholder="Filter By Month">
                                    <el-option label="Filter By Month" value=""></el-option>
                                    <el-option label="January" value="01"></el-option>
                                    <el-option label="February" value="02"></el-option>
                                    <el-option label="March" value="03"></el-option>
                                    <el-option label="April" value="04"></el-option>
                                    <el-option label="May" value="05"></el-option>
                                    <el-option label="June" value="06"></el-option>
                                    <el-option label="July" value="07"></el-option>
                                    <el-option label="August" value="08"></el-option>
                                    <el-option label="September" value="09"></el-option>
                                    <el-option label="October" value="10"></el-option>
                                    <el-option label="November" value="11"></el-option>
                                    <el-option label="December" value="12"></el-option>
                                </el-select>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-3">
                    <el-table
                            :data="RapProductPerson" border >
                            <el-table-column align="center" label="Product" width="350px">
                                <template slot-scope="scope">
                                    <strong>{{ scope.row.get_product.title }} {{'(' +scope.row.TotalTravel+ ')'}}</strong>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="Total Adults">
                                <template slot-scope="scope">
                                    <strong>{{ scope.row.TotalAdults }}</strong>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="Total Children">
                                <template slot-scope="scope">
                                    <strong>{{ scope.row.TotalChildren }}</strong>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="Total Persons">
                                <template slot-scope="scope">
                                    <strong>{{ scope.row.TotalPersons }}</strong>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="Fournisseur Price">
                                <template slot-scope="scope">
                                    <strong>{{ scope.row.get_product.duration }} DH</strong>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="Total">
                                <template slot-scope="scope">
                                    <strong>{{scope.row.TotalPersons*scope.row.get_product.duration }} DH</strong>
                                </template>
                            </el-table-column>
                    </el-table>
                    
                    <pagination class="mt-4" :data="RapProductPerson" @pagination-change-page="loadTotalPerso"></pagination>
                    </div>
                    
                </div>
                </el-card>
            </el-tab-pane>
        </el-tabs>
        <!-- Start Table -->
        
        <!-- End Table -->
        
          
          <!-- dialog -->
          <el-dialog
            :title="editmode ? 'Update Fournisseur':'New Fournisseur'"
            :visible.sync="DialogVisible_global"
            width="70%"
            :before-close="handleClose">
            <!-- Body dialog -->
                <el-form :model="form_global" ref="form_global" class="demo-ruleForm">
                    <fieldset class="row col-12 border p-2 mt-2">
                        <legend  class="w-auto badge"><h5>Fournisseur Infos</h5></legend>
                            
                            <fieldset class="col-12 border p-2 mt-2">
                            <legend  class="w-auto badge"><h5>Entreprise</h5></legend>
                                <div class="row">
                                    <el-form-item class="col-lg-6" label="Entreprise Name" prop="brandName" :rules="[
                                        { required: true, message: ' Entreprise Name is required' }
                                    ]">
                                        <el-input type="text" v-model="form_global.brandName" autocomplete="off"></el-input>
                                    </el-form-item>
                                    <el-form-item class="col-lg-6" label="Adress" prop="adress" :rules="[
                                        { required: true, message: 'Adress is required' }
                                    ]">
                                        <el-input type="text" v-model="form_global.adress" autocomplete="off"></el-input>
                                    </el-form-item>
                                </div>
                            </fieldset>
                            
                            <fieldset class="col-12 border p-2 mt-2">
                            <legend  class="w-auto badge"><h5>Commercial</h5></legend>
                                <div class="row">
                                    <el-form-item class="col-lg-4" label="Name" prop="name" 
                                    :rules="[
                                        { required: true, message: ' Name is required' }
                                    ]">
                                        <el-input v-model="form_global.name" autocomplete="off"></el-input>
                                    </el-form-item>
                                    <el-form-item class="col-lg-4" label="Email" prop="email" :rules="[
                                        { required: true, message: ' Email is required' }
                                    ]">
                                        <el-input type="text" v-model="form_global.email" autocomplete="off"></el-input>
                                    </el-form-item>
                                    <el-form-item class="col-lg-4" label="Tele" prop="tele" :rules="[
                                        { required: true, message: ' Name is required' }
                                    ]">
                                        <el-input type="text" v-model="form_global.tele" autocomplete="off"></el-input>
                                    </el-form-item>
                                </div>
                            </fieldset>
                    </fieldset>
                </el-form>
                <!-- ENd body Dialog -->
                <span slot="footer" class="dialog-footer">
                    <el-button @click="DialogVisible_global = false">Annuler</el-button>
                    <el-button type="primary" v-show="!editmode" @click="submitForm('form_global')">Valider</el-button>
                    <el-button type="primary" v-show="editmode" @click="updateData()">Valider</el-button>
                </span>
          </el-dialog>

          <!-- ENd dialog -->
          <!-- Modal files Bon Livraison -->
        <el-dialog
                title="Agency Price"
                :visible.sync="DialogVisiblePrices"
                center>

                <div style="max-width: 500px; margin: 0 auto;">
                            
                            <form
                                @submit="submitPrice"
                                class="mt-4"
                                enctype="multipart/form-data"
                            >
                                <div class="input-group">
                                    <div class="custom-file">
                                        <input
                                            type="file"
                                            name="filename"
                                            class="custom-file-input"
                                            id="inputFileUpload"
                                            v-on:change="onFileChange"
                                        />
                                        <label
                                            class="custom-file-label"
                                            for="inputFileUpload"
                                            >Select Agency Price For The Hotel</label
                                        >
                                    </div>
                                    
                                </div>
                                <br />
                                <p class="text-success font-weight-bold">
                                    {{ filename }}
                                </p>
                            
                        <span slot="footer" class="dialog-footer">
                            <el-button type="warning" @click="DialogVisibleBonLivraison = false">Annuler</el-button>
                            <input  type="submit" class="btn btn-success" value="Enregistrer"  />
                        </span>
                    </form>
                </div>
            </el-dialog>
            <!-- end dialog file Bon Livraison -->
  
      </div>
     
    </section>
    <!--  -->
  </template>
  
  
  <script>
      import VueTagsInput from '@johmun/vue-tags-input';
      import moment from 'moment'
  
      export default {
        components: {
            VueTagsInput,
          },
          data () {
              return {
                  DialogVisible_global: false,
                  active: 0,
                  month: '',
                  Fournisseurs: [],
                  RapProductPerson: [],
                  ProdSum: [],
                  search: '',
                  searchType: '',
                  searchCity: '',
                  searchHotels: '',
                  pagesize: 10,
                  currpage: 1,
                  editmode: false,
                  products : [],
                //   
                DialogVisiblePrices:false,
                filename:'',


                  numberValidateForm: {
                    age: ''
                },
                
                  form_global: new Form({
                    id:'',
                    name:'',
                    email:'',
                    tele:'',
                    adress:'',
                    brandName:'',
                    
                  }),

                  autocompleteItems: [],
              }
          },
          methods: {            

                // 

            filterType(){
                if(this.searchType != ""){
                    this.$Progress.start();
                    axios.get('api/filterType/' + this.searchType).then(({ data }) => (this.Hotels = data.data));
                    this.$Progress.finish();
                }else{
                    this.loadData();
                }
            },

            filterHotels(){
                if(this.searchHotels != ""){
                    this.$Progress.start();
                    axios.get('api/filterHotels/' + this.searchHotels).then(({ data }) => (this.Hotels = data.data));
                    this.$Progress.finish();
                }else{
                    this.loadData();
                }
            },

            filterCity(){
                if(this.searchCity != ""){
                    this.$Progress.start();
                    axios.get('api/filterCity/' + this.searchCity).then(({ data }) => (this.Hotels = data.data));
                    this.$Progress.finish();
                }else{
                    this.loadData();
                }
            },

            filterETC(){
                if(this.search != ''){
                    this.$Progress.start();
                    axios.get('api/filterHotels/' + this.search).then(({ data }) => (this.Hotels = data.data));
                    this.$Progress.finish();
                }else{
                    this.loadData();
                }
            },
            // CRUD
            newData(){
                this.form_global.reset();
                this.editmode = false;
                // this.disableDevisPV = false;
                this.DialogVisible_global = true;
                this.active=0;
            },

            createData(){
            this.$Progress.start();

              this.form_global.post('api/fournisseur')
              .then((data)=>{
                if(data.data.success){
                 this.DialogVisible_global = false

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.$Progress.finish();
                  this.loadData();
                //   this.loadDataPrepared();

                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          },

          submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.createData();
                } else {
                    console.log('error submit!!');
                    return false;
                }
                });
            },

            loadData(page = 1){
                this.$Progress.start();
                axios.get('api/fournisseur?page=' + page).then(({ data }) => (this.Fournisseurs = data.data));
                this.$Progress.finish();
            },

            loadTotalPerso(){
                this.$Progress.start();
                axios.get('api/RapProductPerson').then(({ data }) => (this.RapProductPerson = data.data));
                this.$Progress.finish();
            },

            loadSUMByMonth(){
                
                if(this.month != ""){
                    this.$Progress.start();
                    axios.get('api/RapProdPersoByMonth/' + this.month).then(({ data }) => (this.RapProductPerson = data.data));
                    this.$Progress.finish();
                    
                }else{
                    this.loadTotalPerso();
                }
            },

            updateFournisseur(reservation){
                this.editmode = true;
                this.form_global.reset();
                this.DialogVisible_global = true;
                this.form_global.fill(reservation);
            },

            updateData(){
              this.$Progress.start();
              this.form_global.put('api/fournisseur/'+this.form_global.id)
              .then((response) => {
                  // success
                  this.DialogVisible_global = false
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });
                  this.$Progress.finish();

                  this.loadData();
              })
              .catch(() => {
                  this.$Progress.fail();
              });

          },

          deletData(id){
              Swal.fire({
                  title: 'Attention !',
                  text: "You really want to delete this Fournisseur ??",
                  showCancelButton: true,
                  confirmButtonColor: '#67c23a',
                  cancelButtonColor: '#f56c6c',
                  confirmButtonText: 'Yes, Delete-it ! '
                  }).then((result) => {

                      // Send request to the server
                        if (result.value) {
                              this.form_global.delete('api/fournisseur/'+id).then(()=>{
                                      Swal.fire(
                                      'Deleted !',
                                      'Fournisseur Deleted Successfully',
                                      'success'
                                      );
                                  // Fire.$emit('AfterCreate');
                                  this.loadData();
                              }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                        }
                  })
          },
        //   End CRUD

            handleClose(done) {
                this.$confirm('Are you sure you want to close this dialog ?')
                .then(_ => {
                    done();
                })
                .catch(_ => {});
            },

            handleCurrentChange2(val) {
                this.currentRow = val;
            },

            // END
  
           
            loadProducts(){
  
              // if(this.$gate.isAdmin()){
                axios.get("api/product").then(({ data }) => (this.products = data.data));
              // }
            },
            loadCategories(){
                axios.get("/api/category/list").then(({ data }) => (this.categories = data.data));
            },
            loadTags(){
                axios.get("/api/tag/list").then(response => {
                    this.autocompleteItems = response.data.data.map(a => {
                        return { text: a.name, id: a.id };
                    });
                }).catch(() => console.warn('Oh. Something went wrong'));
            },
            editModal(product){
                this.editmode = true;
                this.form.reset();
                $('#addNew').modal('show');
                this.form.fill(product);
            },
            newModal(){
                this.editmode = false;
                this.form.reset();
                $('#addNew').modal('show');
            },
            createProduct(){
                this.$Progress.start();
  
                this.form.post('api/product')
                .then((data)=>{
                  if(data.data.success){
                    $('#addNew').modal('hide');
  
                    Toast.fire({
                          icon: 'success',
                          title: data.data.message
                      });
                    this.$Progress.finish();
                    this.loadProducts();
  
                  } else {
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
  
                    this.$Progress.failed();
                  }
                })
                .catch(()=>{
  
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
                })
            },
            updateProduct(){
                this.$Progress.start();
                this.form.put('api/product/'+this.form.id)
                .then((response) => {
                    // success
                    $('#addNew').modal('hide');
                    Toast.fire({
                      icon: 'success',
                      title: response.data.message
                    });
                    this.$Progress.finish();
                        //  Fire.$emit('AfterCreate');
  
                    this.loadProducts();
                })
                .catch(() => {
                    this.$Progress.fail();
                });
  
            },
            deletModal(id){
                Swal.fire({
                    title: 'Attention !',
                    text: "you really want to delete this Hotel ??",
                    showCancelButton: true,
                    confirmButtonColor: '#67c23a',
                    cancelButtonColor: '#f56c6c',
                    confirmButtonText: 'Yes, Delete-it ! '
                    }).then((result) => {
  
                        // Send request to the server
                          if (result.value) {
                                this.form.delete('api/product/'+id).then(()=>{
                                        Swal.fire(
                                        'Deleted !',
                                        'Votre fichier a été supprimé.',
                                        'success'
                                        );
                                    // Fire.$emit('AfterCreate');
                                    this.loadProducts();
                                }).catch((data)=> {
                                    Swal.fire("Failed!", data.message, "warning");
                                });
                          }
                    })
            },
          
           handleCurrentChange(cpage) {
                  this.currpage = cpage;
              },
          handleSizeChange(psize) {
                  this.pagesize = psize;
              },
  
  
          },
          mounted() {
              
          },
          created() {
              this.$Progress.start();
  
              this.loadData();
              this.loadTotalPerso();
            //   this.loadTags();
  
              this.$Progress.finish();
          },
          filters: {
            OfferDates(value){
                return moment(String(value)).format('MM-DD-YY')
            },
              truncate: function (text, length, suffix) {
                  return text.substring(0, length) + suffix;
              },
          },
          computed: {
            filteredItems() {
              return this.autocompleteItems.filter(i => {
                return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
              });
            },
          },
      }
  </script>
  