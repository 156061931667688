<template>
  <section class="content">
    <!-- <el-tabs type="border-card">
        <el-tab-pane label="NOUVEAUX DEVIS"> -->
            <el-button v-if="$gate.isAdminOrCMRS()" type="primary" @click="newDevis()" size="small" icon="el-icon-plus" >AJOUTER NOUVEAUX DEVIS</el-button>
            <el-button v-if="$gate.isAdminOrCMRS()" type="primary" @click="RelancerDevis()" size="small" icon="el-icon-refresh" >RELANCER UNE COMMANDE</el-button>
            <!--  -->
            <el-tabs class="mt-3" type="border-card">
                <!--  -->
                <el-tab-pane>
                    <span slot="label">Liste des Nouveaux Devis</span>
                        <!-- <el-card class="mb-3 mt-3"> -->
                            <div class="row">
                            <div class="col-6">
                                <h4 class="ml-3">Liste des Nouveaux Devis</h4>
                            </div>
                            <div class="col-6">
                                <el-input v-model="search" @change="filterETC" size="mini" placeholder="Filter : "> 
                                    <el-button slot="append" icon="el-icon-search"></el-button>
                                </el-input>
                            </div>
                            <div class="col-12 mt-3">
                                <el-table
                                    :data="devisGlobal.data"
                                    border >
                                <el-table-column type="expand">
                                    <template  slot-scope="scope" >
                                        <el-table
                                            :data="scope.row.get_devis"
                                            
                                            :row-class-name="tableRowClassName">
                                            <el-table-column label="Réf" width="50">
                                                <template slot-scope="scope">
                                                    <span>{{ scope.row.id }}</span>
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="Nom Etiquette" >
                                                <template slot-scope="scope">
                                                    <span>{{ scope.row.nom_etc }}</span>
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="Catégorie" width="100">
                                                <template slot-scope="scope">
                                                    <span>{{ scope.row.typeEtiquette }}</span>
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="Quantité" width="100">
                                                <template slot-scope="scope">
                                                    <span>{{ scope.row.quantite_etq }}</span>
                                                </template>
                                            </el-table-column>
                                            <el-table-column v-if="$gate.isAdmin()" width="200" align="center">
                                                <template slot-scope="{row}">
                                                        <a href="#" @click="PreparationDevis(row)" data-toggle="tooltip" data-placement="top" title="Préparation De Devis">
                                                            <i class="fa fa-cogs green mr-2"></i>
                                                        </a>
                                                        <el-divider v-if="row.preparation == 1" direction="vertical"></el-divider>
                                                        <a href="#" v-if="row.preparation == 1" @click="updateDevis(row)" data-toggle="tooltip" data-placement="top" title="Valider Devis">
                                                            <i class="fa fa-check blue mr-2"></i>
                                                        </a>
                                                        <el-divider direction="vertical"></el-divider>
                                                        <a href="#" @click="updateDevis1(row)" data-toggle="tooltip" data-placement="top" title="Modifier">
                                                            <i class="fa fa-edit blue mr-2"></i>
                                                        </a>
                                                        <el-divider direction="vertical"></el-divider>
                                                        <a href="#" @click="deletData(row.id)" data-toggle="tooltip" data-placement="top" title="Supprimer">
                                                            <i class="fa fa-trash red"></i>
                                                        </a>
                                                </template>
                                            </el-table-column>
                                        </el-table>
                                    </template>
                                </el-table-column>

                                <el-table-column label="N° Devis">
                                    <template slot-scope="scope">
                                        <span>{{ scope.row.id }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="CLient">
                                    <template slot-scope="scope">
                                        <span>{{ scope.row.get_client.name }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Suivi Par" >
                                    <template slot-scope="scope">
                                        <span>{{ scope.row.get_user.name }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Date">
                                    <template slot-scope="scope">
                                        <el-tag >
                                            <span>{{ scope.row.dateDevis }}</span>
                                        </el-tag>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Régime">
                                    <template slot-scope="scope">
                                        <el-tag>
                                            <span>{{ scope.row.modalite_paiment }}</span>
                                        </el-tag>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Etat">
                                    <template slot-scope="scope">
                                        <div v-if="scope.row.Etat_devis == 'A préparer'">
                                            <el-tag  type="" effect="dark" ><strong>{{ scope.row.Etat_devis }}</strong></el-tag>
                                        </div>
                                        <div v-if="scope.row.Etat_devis == 'À Revoir'">
                                            <el-tag  type="danger" effect="dark" ><strong>{{ scope.row.Etat_devis }}</strong></el-tag>
                                        </div>
                                        <div v-if="scope.row.Etat_devis == 'A Valider'">
                                            <el-tag  type="warning" effect="dark" ><strong>{{ scope.row.Etat_devis }}</strong></el-tag>
                                        </div>
                                        <div v-if="scope.row.Etat_devis == 'Valider'">
                                            <el-tag  type="success" effect="dark" ><strong>{{ scope.row.Etat_devis }}</strong></el-tag>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column v-if="$gate.isAdmin()" align="center">
                                    <template slot-scope="{row}">
                                            <a href="#" v-if="row.Etat_devis == 'A Valider' || row.Etat_devis == 'À Revoir'" @click="newPrices(row)" data-toggle="tooltip" data-placement="top" title="Mise A jour Des Prix">
                                                <i class="fa fa-money-bill-alt green "></i>
                                            </a>
                                            <el-divider v-if="row.Etat_devis == 'A Valider' || row.Etat_devis == 'À Revoir'" direction="vertical"></el-divider>
                                            <a href="#" @click="updateDevisGlobal(row)" data-toggle="tooltip" data-placement="top" title="Modifier Devis">
                                                    <i class="fa fa-edit blue "></i>
                                            </a>
                                            <el-divider v-if="row.Etat_devis == 'A Valider'" direction="vertical"></el-divider>
                                            <a v-if="row.Etat_devis == 'A Valider'" href="#" data-toggle="tooltip" data-placement="top" @click="printGlobalDevis(row)" title="Télécharger l'offre de prix">
                                                <i class="fa fa-file-pdf orange "></i>
                                            </a>
                                            <el-divider direction="vertical"></el-divider>
                                            <a href="#" @click="deletDevisGlobal(row.id)" data-toggle="tooltip" data-placement="top" title="Supprimer">
                                                <i class="fa fa-trash red"></i>
                                            </a>
                                    </template>
                                </el-table-column>
                    
                                </el-table>
                                <!--  -->
                                <pagination class="mt-4" :data="devisGlobal" @pagination-change-page="loadDevisGlobal"></pagination>
                            </div>
                            
                            </div>
                        <!-- </el-card> -->
                </el-tab-pane>
                <el-tab-pane>
                    <span slot="label">Liste Des Commandes Relancer</span>
                 <!-- <el-card class="mb-3 mt-3"> -->
                    <div class="row">
                            <div class="col-6">
                                <h4 class="ml-3">Liste Des Commandes Relancer</h4>
                            </div>
                            <div class="col-6">
                                <el-input v-model="search" @change="filterETC" size="mini" placeholder="Filter : "> 
                                    <el-button slot="append" icon="el-icon-search"></el-button>
                                </el-input>
                            </div>
                            <div class="col-12 mt-3">
                                <el-table
                                    :data="devisGlobalR.data"
                                    border >
                                <el-table-column type="expand">
                                    <template  slot-scope="scope" >
                                        <el-table
                                            :data="scope.row.get_devis"
                                            
                                            :row-class-name="tableRowClassName">
                                            <el-table-column label="Réf" width="50">
                                                <template slot-scope="scope">
                                                    <span>{{ scope.row.id }}</span>
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="Nom Etiquette" >
                                                <template slot-scope="scope">
                                                    <span>{{ scope.row.nom_etc }}</span>
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="Catégorie" width="100">
                                                <template slot-scope="scope">
                                                    <span>{{ scope.row.typeEtiquette }}</span>
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="Quantité" width="100">
                                                <template slot-scope="scope">
                                                    <span>{{ scope.row.quantite_etq }}</span>
                                                </template>
                                            </el-table-column>
                                            <el-table-column v-if="$gate.isAdmin()" width="200" align="center">
                                                <template slot-scope="{row}">
                                                        <a href="#" @click="PreparationDevis(row)" data-toggle="tooltip" data-placement="top" title="Préparation De Devis">
                                                            <i class="fa fa-cogs green mr-2"></i>
                                                        </a>
                                                        <el-divider v-if="row.preparation == 1" direction="vertical"></el-divider>
                                                        <a href="#" v-if="row.preparation == 1" @click="updateDevis(row)" data-toggle="tooltip" data-placement="top" title="Valider Devis">
                                                            <i class="fa fa-check blue mr-2"></i>
                                                        </a>
                                                        <el-divider direction="vertical"></el-divider>
                                                        <a href="#" @click="updateDevis1(row)" data-toggle="tooltip" data-placement="top" title="Modifier">
                                                            <i class="fa fa-edit blue mr-2"></i>
                                                        </a>
                                                        <el-divider direction="vertical"></el-divider>
                                                        <a href="#" @click="deletData(row.id)" data-toggle="tooltip" data-placement="top" title="Supprimer">
                                                            <i class="fa fa-trash red"></i>
                                                        </a>
                                                </template>
                                            </el-table-column>
                                        </el-table>
                                    </template>
                                </el-table-column>

                                <el-table-column label="N° Devis">
                                    <template slot-scope="scope">
                                        <span>{{ scope.row.id }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="CLient">
                                    <template slot-scope="scope">
                                        <span>{{ scope.row.get_client.name }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Suivi Par" >
                                    <template slot-scope="scope">
                                        <span>{{ scope.row.get_user.name }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Date">
                                    <template slot-scope="scope">
                                        <el-tag >
                                            <span>{{ scope.row.dateDevis }}</span>
                                        </el-tag>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Régime">
                                    <template slot-scope="scope">
                                        <el-tag>
                                            <span>{{ scope.row.modalite_paiment }}</span>
                                        </el-tag>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Etat">
                                    <template slot-scope="scope">
                                        <div v-if="scope.row.Etat_devis == 'A préparer'">
                                            <el-tag  type="" effect="dark" ><strong>{{ scope.row.Etat_devis }}</strong></el-tag>
                                        </div>
                                        <div v-if="scope.row.Etat_devis == 'A Valider'">
                                            <el-tag  type="warning" effect="dark" ><strong>{{ scope.row.Etat_devis }}</strong></el-tag>
                                        </div>
                                        <div v-if="scope.row.Etat_devis == 'Valider'">
                                            <el-tag  type="success" effect="dark" ><strong>{{ scope.row.Etat_devis }}</strong></el-tag>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column v-if="$gate.isAdmin()" align="center">
                                    <template slot-scope="{row}">
                                            
                                            <a href="#" @click="updateDevisGlobal(row)" data-toggle="tooltip" data-placement="top" title="Modifier Devis">
                                                    <i class="fa fa-edit blue mr-2"></i>
                                            </a>
                                            <el-divider direction="vertical"></el-divider>
                                            <a href="#" data-toggle="tooltip" data-placement="top" @click="printGlobalDevis(row)" title="Télécharger l'offre de prix">
                                                <i class="fa fa-file-pdf green mr-2"></i>
                                            </a>
                                            <el-divider direction="vertical"></el-divider>
                                            <a href="#" @click="deletDevisGlobal(row.id)" data-toggle="tooltip" data-placement="top" title="Supprimer">
                                                <i class="fa fa-trash red"></i>
                                            </a>
                                    </template>
                                </el-table-column>
                    
                                </el-table>
                                <!--  -->
                                <pagination class="mt-4" :data="devisGlobalR" @pagination-change-page="loadDevisGlobalR"></pagination>
                            </div>
                            
                            </div>
                        <!-- </el-card> -->
                </el-tab-pane>
           
            </el-tabs>
        

        <!-- </el-tab-pane>
        <el-tab-pane label="COMMANDE A RELANCER">

        </el-tab-pane> -->
    <!-- </el-tabs> -->
    <div class="container-fluid">
        
        <!-- Liste deja preparer -->
        <!-- <el-card class="mb-3 mt-3">
        <div class="row">
        <div class="col-6">
            <h4 class="ml-3"> Liste des devis à valider </h4>
        </div>
          <div class="col-6">
              <el-input v-model="search1"  @change="filterETCPrepared" size="mini" placeholder="Filter : "> 
                  <el-button slot="append" icon="el-icon-search"></el-button>
              </el-input>
          </div>
          <div class="col-12 mt-3">
            <el-table
                  :data="devisETQPrepared.data"
                  border >
                <el-table-column type="expand">
                    <template  slot-scope="props">
                        <div class="row">
                            <div class="col">
                                <p class="ml-5"><strong> Date : </strong> {{ props.row.dateDevis }}</p>
                                <p class="ml-5"><strong> Format: </strong> {{ props.row.formatMM_L }} x {{ props.row.formatMM_D }}</p>
                                <p class="ml-5"><strong> Impression: </strong> {{ props.row.impression }}</p>
                                <p class="ml-5"><strong> Nbr Couleur: </strong> {{ props.row.nbr_clouleur }}</p>
                                <p class="ml-5"><strong> recto: </strong> {{ props.row.recto }}</p>
                                <p class="ml-5"><strong> verso: </strong> {{ props.row.verso }}</p>
                            </div>
                            <div class="col">
                                <p class="ml-5"><strong> Conception : </strong> {{ props.row.conception }}</p>
                                <p class="ml-5"><strong> Dorure: </strong> {{ props.row.dorure }}</p>
                                <p class="ml-5"><strong> repiquage: </strong> {{ props.row.repiquage }}</p>
                                <p class="ml-5"><strong> plastification: </strong> {{ props.row.plastification }}</p>
                                <p class="ml-5"><strong> vernis: </strong> {{ props.row.vernis }}</p>
                            </div>
                            <div class="col">
                                <p class="ml-5"><strong> Mandrin: </strong><span v-if="props.row.mandrin == 'Autre'">{{ props.row.mandrin_autre }}</span><span v-else>{{ props.row.mandrin }}</span></p>
                                <p class="ml-5"><strong> Poses d'étiquette: </strong> {{ props.row.poses_etq }}</p>
                                <p class="ml-5"><strong> forme Decoup : </strong> {{ props.row.form_decoupe  }}</p>
                                <p class="ml-5"><strong> Films/Cliches: </strong> {{ props.row.films_cliches }}</p>
                                <p class="ml-5"><strong> Form Etiquette: </strong> {{ props.row.forme_etq }}</p>
                            </div>
                            <div class="col">
                                <p class="ml-5"><strong> Support (Papier): </strong> {{ props.row.support_papier }}</p>
                                <p class="ml-5"><strong> Nombre Etq/Bobine: </strong> {{ props.row.nbr_etq_bobine }}</p>
                                <p class="ml-5"><strong> Nombre Etq/Front: </strong> {{ props.row.nbr_etq_front }}</p>
                                <p class="ml-5"><strong> Modalité Facturation: </strong> {{ props.row.modalite_paiment }}</p>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="N° Devis">
                    <template slot-scope="scope">
                        <span>{{ scope.row.id }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="Nom D'étiquette" width="200">
                    <template slot-scope="scope">
                        <span>{{ scope.row.nom_etc }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="CLient " width="200">
                    <template slot-scope="scope">
                        <span>{{ scope.row.get_client.name }}</span>
                    </template>
                </el-table-column>

                <el-table-column label="Quantité">
                    <template slot-scope="scope">
                        <span>{{ scope.row.quantite_etq }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="Prix HTT">
                    <template slot-scope="scope">
                        <span>{{ scope.row.prixHTT }}</span>
                    </template>
                </el-table-column>
                <el-table-column v-if="$gate.isAdmin()" width="200" align="center">
                    <template slot-scope="{row}">
                            <a href="#" @click="PreparationDevis(row)" data-toggle="tooltip" data-placement="top" title="Préparation De Devis">
                                <i class="fa fa-cogs green mr-2"></i>
                            </a>
                            <el-divider direction="vertical"></el-divider>
                            <a href="#" data-toggle="tooltip" data-placement="top" title="Télécharger le devis">
                                <i class="fa fa-file-pdf green mr-2"></i>
                            </a>
                            <el-divider direction="vertical"></el-divider>
                            <a href="#" @click="updateDevis(row)" data-toggle="tooltip" data-placement="top" title="Valider Devis">
                                    <i class="fa fa-check blue mr-2"></i>
                            </a>
                            <el-divider direction="vertical"></el-divider>
                            <a href="#" @click="deletData(row.id)" data-toggle="tooltip" data-placement="top" title="Supprimer">
                                <i class="fa fa-trash red"></i>
                            </a>
                    </template>
                </el-table-column>
            </el-table>
             
            <pagination class="mt-4" :data="devisETQPrepared" @pagination-change-page="loadDataPrepared"></pagination>
          </div>
        </div>
        </el-card> -->
        <!-- dialog Relancer Devis-->
        <el-dialog
            :title="Relancereditmode ? '':'RELANCER COMMANDE'"
            :visible.sync="DialogVisible_RelancerCMD"
            width="95%"
            :before-close="handleClose">
            <!-- Body dialog -->
                <el-form :model="Relancerform">
                    <fieldset class="border p-2">
                            <legend class="w-auto badge"><h5>  </h5></legend>
                            
                            <el-steps :active="active1" finish-status="success" align-center>
                                <el-step title="Selectionné Les Commandes A Relancer" icon="el-icon-edit-outline"></el-step>
                                <el-step title="Ajouter La Quantité" icon="el-icon-box"></el-step>
                                <!-- <el-step title="Modalité De Facturation" icon="el-icon-folder-checked"></el-step> -->
                            </el-steps>
                    </fieldset>
                    <fieldset v-if="active1 === 0" class="border p-2 mt-2 ">
                        <el-form-item 
                                class="col-lg-4" 
                                prop="id_client" 
                                label="CHOISIE LE CLIENT"
                            >
                            
                            <el-select v-model="Relancerform.id_client" @change="ClientChange()" filterable  style="width: 100%" placeholder="Veuillez sélectionner un client">
                                <el-option
                                    v-for="item in clients"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                        
                        <div v-if="Relancerform.id_client != ''" class="row">
                            <div class="col-6">
                                <h4 class="ml-3">LISTE DES COMMANDES A RELANCER</h4>
                            </div>
                                <!-- <div class="col-6">
                                    <el-input v-model="search" @change="filterETC" size="mini" placeholder="Filter : "> 
                                        <el-button slot="append" icon="el-icon-search"></el-button>
                                    </el-input>
                                </div> -->
                                <div class="col-12 mt-3">
                                    <el-table border ref="multipleTable"  style="width: 100%" :data="ExisteCMDClient.data" @selection-change="handleSelectionChange">
                                        <el-table-column type="selection" width="50"></el-table-column>
                                        <el-table-column type="expand">
                                            <template  slot-scope="props">
                                                <el-descriptions class="margin-top" :column="3" size="medium" border>
                                                    <el-descriptions-item>
                                                        <template slot="label"><strong>SUPPORT (PAPIER) :</strong></template>
                                                        <el-tag type="" effect="plain">
                                                            <strong>{{ props.row.support_papier}}</strong>
                                                        </el-tag>
                                                    </el-descriptions-item>
                                                    <el-descriptions-item>
                                                        <template slot="label"><strong>IMPRESSION :</strong></template>
                                                        <el-tag type="" effect="plain">
                                                            <strong>{{ props.row.impression}}</strong>
                                                        </el-tag>
                                                    </el-descriptions-item>
                                                    <el-descriptions-item v-if="props.row.impression == 'OUI'">
                                                        <template slot="label"><strong>RECTO :</strong></template>
                                                        <el-tag type="" effect="plain">
                                                            <strong>{{ props.row.recto}}</strong>
                                                        </el-tag>
                                                    </el-descriptions-item>
                                                    <el-descriptions-item v-if="props.row.impression == 'OUI'">
                                                        <template slot="label"><strong>NOMBRE COULEURS :</strong></template>
                                                        <el-tag type="" effect="plain">
                                                            <strong>{{ props.row.nbr_clouleur}}</strong>
                                                        </el-tag>
                                                    </el-descriptions-item>
                                                    <el-descriptions-item v-if="props.row.impression == 'OUI'">
                                                        <template slot="label"><strong>VERSO :</strong></template>
                                                        <el-tag type="" effect="plain">
                                                            <strong>{{ props.row.verso}}</strong>
                                                        </el-tag>
                                                    </el-descriptions-item>
                                                    <el-descriptions-item v-if="props.row.impression == 'OUI'">
                                                        <template slot="label"><strong>VERSO COULEUR :</strong></template>
                                                        <el-tag type="" effect="plain">
                                                            <strong>{{ props.row.verso_couleur}}</strong>
                                                        </el-tag>
                                                    </el-descriptions-item>
                                                    <el-descriptions-item v-if="props.row.impression == 'OUI'">
                                                        <template slot="label"><strong>SENS DE SORTIE :</strong></template>
                                                        <el-tag type="" effect="plain">
                                                            <strong>{{ props.row.sens_sortie}}</strong>
                                                        </el-tag>
                                                    </el-descriptions-item>
                                                    <el-descriptions-item >
                                                        <template slot="label"><strong>REPIQUAGE :</strong></template>
                                                        <el-tag type="" effect="plain">
                                                            <strong>{{ props.row.repiquage}}</strong>
                                                        </el-tag>
                                                    </el-descriptions-item>
                                                    <el-descriptions-item v-if="props.row.impression == 'OUI'">
                                                        <template slot="label"><strong>VERNIS :</strong></template>
                                                        <el-tag type="" effect="plain">
                                                            <strong>{{ props.row.vernis}}</strong>
                                                        </el-tag>
                                                    </el-descriptions-item>
                                                    <el-descriptions-item v-if="props.row.impression == 'OUI'">
                                                        <template slot="label"><strong>PLASTIFICATION :</strong></template>
                                                        <el-tag type="" effect="plain">
                                                            <strong>{{ props.row.plastification}}</strong>
                                                        </el-tag>
                                                    </el-descriptions-item>
                                                    <el-descriptions-item v-if="props.row.impression == 'OUI'">
                                                        <template slot="label"><strong>DORURE :</strong></template>
                                                        <el-tag type="" effect="plain">
                                                            <strong>{{ props.row.dorure}}</strong>
                                                        </el-tag>
                                                    </el-descriptions-item>
                                                    <el-descriptions-item >
                                                        <template slot="label"><strong>NBR ETQ/BOBINE :</strong></template>
                                                        <el-tag type="" effect="plain">
                                                            <strong>{{ props.row.nbr_etq_bobine}}</strong>
                                                        </el-tag>
                                                    </el-descriptions-item>
                                                    <el-descriptions-item >
                                                        <template slot="label"><strong>NBR ETQ/FRONT :</strong></template>
                                                        <el-tag type="" effect="plain">
                                                            <strong>{{ props.row.nbr_etq_front}}</strong>
                                                        </el-tag>
                                                    </el-descriptions-item>
                                                    <el-descriptions-item >
                                                        <template slot="label"><strong>MANDRIN :</strong></template>
                                                        <el-tag type="" effect="plain">
                                                            <strong>{{ props.row.mandrin}}</strong>
                                                        </el-tag>
                                                    </el-descriptions-item>
                                                    <el-descriptions-item >
                                                        <template slot="label"><strong>POSES D'ETIQUETTE :</strong></template>
                                                        <el-tag type="" effect="plain">
                                                            <strong>{{ props.row.poses_etq}}</strong>
                                                        </el-tag>
                                                    </el-descriptions-item>
                                                    <el-descriptions-item >
                                                        <template slot="label"><strong>FORM DE DECOUPE :</strong></template>
                                                        <el-tag type="" effect="plain">
                                                            <strong>{{ props.row.form_decoupe}}</strong>
                                                        </el-tag>
                                                    </el-descriptions-item>
                                                    <el-descriptions-item >
                                                        <template slot="label"><strong>FILMS ET CLICHES :</strong></template>
                                                        <el-tag type="" effect="plain">
                                                            <strong>{{ props.row.films_cliches}}</strong>
                                                        </el-tag>
                                                    </el-descriptions-item>
                                                </el-descriptions>
                                                
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="Nom Etiquette" width="321">
                                            <template slot-scope="scope">
                                                <span>{{ scope.row.nom_etc }}</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="Catégorie" width="150">
                                            <template slot-scope="scope">
                                                <span>{{ scope.row.typeEtiquette }}</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="Format (L x D)" width="123">
                                            <template slot-scope="scope">
                                                <span>{{ scope.row.formatMM_L}} x {{scope.row.formatMM_D}}</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="Forme Etiquette">
                                            <template slot-scope="scope">
                                                <span>{{ scope.row.forme_etq}}</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="Support">
                                            <template slot-scope="scope">
                                                <span>{{ scope.row.support_papier}}</span>
                                            </template>
                                        </el-table-column>
                                       
                                    </el-table>
                                <!--  -->
                                <pagination class="mt-4" :data="ExisteCMDClient" @pagination-change-page="ClientChange"></pagination>
                                </div>
                                <!-- table Global -->
                            </div>
                            
                    </fieldset>
                    <fieldset v-if="active1 === 1" class="border p-2 mt-2">
                            <legend class="w-auto badge"><h5>Liste des Articles</h5></legend>
                                
                                <div class="mt-5" v-for="(c, k) in multipleSelection" :key="k">
                                    
                                    <el-descriptions class="margin-top" :title="'Article N° : '+(k+1)" :column="3" size="medium" border>
                                                    <el-descriptions-item>
                                                        <template slot="label"><strong>SUPPORT (PAPIER) :</strong></template>
                                                        <el-tag type="" effect="plain">
                                                            <strong>{{ c.support_papier}}</strong>
                                                        </el-tag>
                                                    </el-descriptions-item>
                                                    <el-descriptions-item>
                                                        <template slot="label"><strong>IMPRESSION :</strong></template>
                                                        <el-tag type="" effect="plain">
                                                            <strong>{{ c.impression}}</strong>
                                                        </el-tag>
                                                    </el-descriptions-item>
                                                    <el-descriptions-item v-if="c.impression == 'OUI'">
                                                        <template slot="label"><strong>RECTO :</strong></template>
                                                        <el-tag type="" effect="plain">
                                                            <strong>{{ c.recto}}</strong>
                                                        </el-tag>
                                                    </el-descriptions-item>
                                                    <el-descriptions-item v-if="c.impression == 'OUI'">
                                                        <template slot="label"><strong>NOMBRE COULEURS :</strong></template>
                                                        <el-tag type="" effect="plain">
                                                            <strong>{{ c.nbr_clouleur}}</strong>
                                                        </el-tag>
                                                    </el-descriptions-item>
                                                    <el-descriptions-item v-if="c.impression == 'OUI'">
                                                        <template slot="label"><strong>VERSO :</strong></template>
                                                        <el-tag type="" effect="plain">
                                                            <strong>{{ c.verso}}</strong>
                                                        </el-tag>
                                                    </el-descriptions-item>
                                                    <el-descriptions-item v-if="c.impression == 'OUI'">
                                                        <template slot="label"><strong>VERSO COULEUR :</strong></template>
                                                        <el-tag type="" effect="plain">
                                                            <strong>{{ c.verso_couleur}}</strong>
                                                        </el-tag>
                                                    </el-descriptions-item>
                                                    <el-descriptions-item v-if="c.impression == 'OUI'">
                                                        <template slot="label"><strong>SENS DE SORTIE :</strong></template>
                                                        <el-tag type="" effect="plain">
                                                            <strong>{{ c.sens_sortie}}</strong>
                                                        </el-tag>
                                                    </el-descriptions-item>
                                                    <el-descriptions-item >
                                                        <template slot="label"><strong>REPIQUAGE :</strong></template>
                                                        <el-tag type="" effect="plain">
                                                            <strong>{{ c.repiquage}}</strong>
                                                        </el-tag>
                                                    </el-descriptions-item>
                                                    <el-descriptions-item v-if="c.impression == 'OUI'">
                                                        <template slot="label"><strong>VERNIS :</strong></template>
                                                        <el-tag type="" effect="plain">
                                                            <strong>{{ c.vernis}}</strong>
                                                        </el-tag>
                                                    </el-descriptions-item>
                                                    <el-descriptions-item v-if="c.impression == 'OUI'">
                                                        <template slot="label"><strong>PLASTIFICATION :</strong></template>
                                                        <el-tag type="" effect="plain">
                                                            <strong>{{ c.plastification}}</strong>
                                                        </el-tag>
                                                    </el-descriptions-item>
                                                    <el-descriptions-item v-if="c.impression == 'OUI'">
                                                        <template slot="label"><strong>DORURE :</strong></template>
                                                        <el-tag type="" effect="plain">
                                                            <strong>{{ c.dorure}}</strong>
                                                        </el-tag>
                                                    </el-descriptions-item>
                                                    <el-descriptions-item >
                                                        <template slot="label"><strong>NBR ETQ/BOBINE :</strong></template>
                                                        <el-tag type="" effect="plain">
                                                            <strong>{{ c.nbr_etq_bobine}}</strong>
                                                        </el-tag>
                                                    </el-descriptions-item>
                                                    <el-descriptions-item >
                                                        <template slot="label"><strong>NBR ETQ/FRONT :</strong></template>
                                                        <el-tag type="" effect="plain">
                                                            <strong>{{ c.nbr_etq_front}}</strong>
                                                        </el-tag>
                                                    </el-descriptions-item>
                                                    <el-descriptions-item >
                                                        <template slot="label"><strong>MANDRIN :</strong></template>
                                                        <el-tag type="" effect="plain">
                                                            <strong>{{ c.mandrin}}</strong>
                                                        </el-tag>
                                                    </el-descriptions-item>
                                                    <el-descriptions-item >
                                                        <template slot="label"><strong>POSES D'ETIQUETTE :</strong></template>
                                                        <el-tag type="" effect="plain">
                                                            <strong>{{ c.poses_etq}}</strong>
                                                        </el-tag>
                                                    </el-descriptions-item>
                                                    <el-descriptions-item >
                                                        <template slot="label"><strong>FORM DE DECOUPE :</strong></template>
                                                        <el-tag type="" effect="plain">
                                                            <strong>{{ c.form_decoupe}}</strong>
                                                        </el-tag>
                                                    </el-descriptions-item>
                                                    <el-descriptions-item >
                                                        <template slot="label"><strong>FILMS ET CLICHES :</strong></template>
                                                        <el-tag type="" effect="plain">
                                                            <strong>{{ c.films_cliches}}</strong>
                                                        </el-tag>
                                                    </el-descriptions-item>
                                                    <el-descriptions-item :span="3">
                                                        <template slot="label"><strong>ES QU'IL YA DES MODIFICATIONS SUR CETTE ÉTIQUETTE ?</strong></template>
                                                        <el-radio-group  v-model="c.changesExist">
                                                            <el-radio label="OUI" ></el-radio>
                                                            <el-radio label="NON" ></el-radio>
                                                        </el-radio-group>
                                                    </el-descriptions-item>
                                                    <el-descriptions-item v-if="c.changesExist == 'NON'">
                                                        <template slot="label"><strong>MERCI DE SAISIR LA QUANTITÉ COMMANDER :</strong></template>
                                                            <el-input  v-model="c.quantite_etq" type="number" :min=0 ></el-input>
                                                    </el-descriptions-item>
                                                    <el-descriptions-item v-if="c.changesExist == 'OUI'">
                                                        <!-- <template slot="label"><strong>MERCI DE SAISIR LA QUANTITÉ COMMANDER :</strong></template> -->
                                                            <el-alert
                                                                title="MERCI DE LANCER NOUVEUX DEVIS POUR CETTE ÉTIQUETTE"
                                                                type="error"
                                                                show-icon
                                                                >
                                                            </el-alert>
                                                    </el-descriptions-item>
                                                    
                                                </el-descriptions>
                                        <!--  -->
                                </div>
                        </fieldset>

                    <fieldset class="border p-2 col-lg-12">
                        <el-button v-if="this.active1 > 0" type="primary" @click="prev1" icon="el-icon-arrow-left">L'étape Précédente </el-button>
                        <el-button v-if="this.active1 < 1" type="primary" class="float-right"  @click="next1">L'étape suivante <i class="el-icon-arrow-right"></i></el-button>
                    </fieldset>
                </el-form>
            <!-- ENd body Dialog -->
            <span slot="footer" v-if="active1 === 1" class="dialog-footer">
                <el-button @click="DialogVisible_RelancerCMD = false">Annuler</el-button>
                <el-button type="primary"  @click="RelanceDevis()">Valider</el-button>
                <!-- <el-button type="primary" v-show="editmode" @click="updateData()">Valider</el-button> -->
            </span>
            
        </el-dialog>
        <!-- dialog devis_Global-->
        <el-dialog
            :title="editmode ? 'MODIFICATION DE DEVIS':'PREPARATION DE NOUVEAU DEVIS ETIQUETTES'"
            :visible.sync="DialogVisible_global"
            width="90%"
            :before-close="handleClose">
            <!-- Body dialog -->
                <el-form :model="form_global" ref="form_global" class="demo-ruleForm">
                    <fieldset v-show="!editmode" class="border p-2">
                            <legend class="w-auto badge"><h5>  </h5></legend>
                            
                            <el-steps :active="active" finish-status="success" align-center>
                                <el-step title="Informations De Client" icon="el-icon-edit-outline"></el-step>
                                <el-step title="Ajouter Les Articles" icon="el-icon-box"></el-step>
                                <el-step title="Modalité De Facturation" icon="el-icon-folder-checked"></el-step>
                            </el-steps>
                    </fieldset>
                    <div>
                        <fieldset class="border p-2 col-lg-12 mt-3" v-if="active === 0">
                            <legend class="w-auto badge"><h5> </h5></legend>

                            <div class="col-lg-8"></div>
                            <el-form-item
                                style="float: right"
                                class="col-lg-4"
                                label="DATE :"
                                prop="date"
                            >
                                <el-date-picker type="date" placeholder="Choisis une date" v-model="form_global.dateDevis" format="yyyy-MM-dd" value-format="yyyy-MM-dd" style="width: 100%;"></el-date-picker>
                            </el-form-item>
                    
                            <fieldset v-show="!editmode" class="col-12 border p-2 mt-2">
                                <legend  class="w-auto badge"><h5>NOUVEAU CLIENT ?</h5></legend>
                                <el-form-item
                                    v-show="!editmode"
                                    class="text-center"
                                    prop="existClient"
                                    :rules="[{ required: true, message: 'Please select Client', trigger: 'change' }]"
                                >
                                    <el-radio-group @change="ExistClientCHange" v-model="form_global.existClient">
                                        <el-radio label="OUI" ></el-radio>
                                        <el-radio label="Non" ></el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <!-- new client -->
                                <fieldset  v-if="form_global.existClient == 'Non'" class="border p-2 mt-2 ">
                                    <el-form-item 
                                            prop="id_client" 
                                            label="CHOISIE LE CLIENT"
                                        >
                                        <el-select v-model="form_global.id_client" filterable  style="width: 100%" placeholder="Veuillez sélectionner un client">
                                            <el-option
                                                v-for="item in clients"
                                                :key="item.id"
                                                :label="item.name"
                                                :value="item.id"
                                            ></el-option>
                                        </el-select>
                                    </el-form-item>
                                </fieldset>
                                <!-- old client -->
                                <fieldset  v-if="form_global.existClient == 'OUI'" class="border p-2 mt-2 ">
                                <div class="row" v-for="(c, k) in form_global.client" :key="k">
                                
                                    <el-form-item
                                        class="col-lg-4"
                                        label="NOM CLIENT"
                                        prop="name"
                                        
                                    >
                                        <el-input type="text" v-model="c.name" autocomplete="off"></el-input>
                                    </el-form-item>

                                    <el-form-item
                                    
                                        class="col-lg-4"
                                        label="NUM TELEPHONE"
                                        prop="tele_client"
                                    >
                                        <el-input type="text" v-model="c.tele" autocomplete="off"></el-input>
                                    </el-form-item>
                                    
                                    <el-form-item
                                    
                                        class="col-lg-4"
                                        label="EMAIL"
                                        prop="email_client"
                                    >
                                        <el-input type="email" v-model="c.email" autocomplete="off"></el-input>
                                    </el-form-item>
                                    <!-- Responsable infos -->
                                   
                                        <el-form-item 
                                            class="text-center col-lg-3"
                                            prop="RLancementCMDName"
                                            label="Nom Prénom Résponsable"
                                        >
                                            <el-input type="text" v-model="c.RLancementCMDName" placeholder="Nom Prénom Résponsable" autocomplete="off"></el-input>
                                        </el-form-item>
                                        <el-form-item 
                                            class="text-center col-lg-3"
                                            prop="RLancementCMDemail"
                                            label="E-mail Résponsable"
                                        >
                                            <el-input type="email" v-model="c.RLancementCMDemail" placeholder="E-mail Résponsable" autocomplete="off"></el-input>
                                        </el-form-item>
                                        <el-form-item 
                                            class="text-center col-lg-3"
                                            prop="RLancementCMDtele"
                                            label="N° Téléphone(GSM) Résponsable"
                                        >
                                            <el-input type="text" v-model="c.RLancementCMDtele" placeholder="N° Téléphone(GSM) Résponsable" autocomplete="off"></el-input>
                                        </el-form-item>
                                        <el-form-item 
                                            class="text-center col-lg-3"
                                            prop="RLancementCMDfix"
                                            label="N° Téléphone(FIX) Résponsable"
                                        >
                                            <el-input type="text" v-model="c.RLancementCMDfix" placeholder="N° Téléphone(FIX) Résponsable" autocomplete="off"></el-input>
                                        </el-form-item>
                                            
                                    </div>
                                </fieldset>
                            </fieldset>
                            <!-- show on edit mode -->
                            <fieldset v-show="editmode" class="border p-2 mt-2 ">
                                <el-form-item 
                                        class="col-lg-4" 
                                        prop="id_client" 
                                        label="CHOISIE LE CLIENT"
                                    >
                                    
                                    <el-select v-model="form_global.id_client" filterable  style="width: 100%" placeholder="Veuillez sélectionner un client">
                                        <el-option
                                            v-for="item in clients"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>
                            </fieldset>
                            <!-- show on edit mode -->
                            
                            <fieldset  v-show="editmode" class="border p-2 mt-2 col-lg-6">
                                    <legend class="w-auto badge"><h5>MODALITÉ DE FACTURATION</h5></legend>
                                    <el-form-item 
                                        class="text-center"
                                        prop="modalite_paiment"
                                    >
                                        <el-radio-group v-model="form_global.modalite_paiment">
                                            <el-radio label="AT" ></el-radio>
                                            <el-radio label="TVA" ></el-radio>
                                            <el-radio v-if="$gate.isAdmin()" label="COMPTANT" ></el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                            </fieldset>
                            <fieldset v-show="editmode" class="border p-2 mt-2 col-lg-6">
                                    <legend class="w-auto badge"><h5>MODALITÉ DE PAIMENT</h5></legend>
                                    <el-form-item 
                                        class="text-center"
                                        prop="modalite_paiment2"
                                    >
                                        <el-select v-model="form_global.modalite_paiment2" style="width: 100%" placeholder="SELECTIONNER LA MODALITÉ DE PAIMENT">
                                                <el-option label="50% à la commande 50% à la livraison" value="50% à la commande 50% à la livraison"></el-option>
                                                <el-option label="Contre chéque" value="Contre chéque"></el-option>
                                                <el-option label="Contre espéces" value="Contre espéces"></el-option>
                                                <el-option label="30jrs contre Chéque" value="30jrs contre Chéque"></el-option>
                                                <el-option label="30jrs contre Traite" value="30jrs contre Traite"></el-option>
                                                <el-option label="Autre" value="Autre"></el-option>
                                        </el-select>
                                        
                                    </el-form-item>
                            </fieldset>
                            
                            <fieldset v-show="editmode" class="border p-2 mt-2">
                                    <legend class="w-auto badge"><h5>Format de Prix sur l'offre de prix</h5></legend>
                                    <el-form-item 
                                        class="text-center"
                                        prop="type_ISE"
                                    >
                                        <el-radio-group v-model="form_global.type_ISE">
                                            <el-radio label="inclus" ></el-radio>
                                            <el-radio label="exclus" ></el-radio>
                                            <el-radio label="separee" ></el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                            </fieldset>
                            <fieldset v-show="editmode" class="border p-2 mt-2">
                                    <legend class="w-auto badge"><h5>L'état de devis</h5></legend>
                                    <el-form-item 
                                        class="text-center"
                                        prop="type_ISE"
                                    >
                                        <el-radio-group v-model="form_global.Etat_devis">
                                            <el-radio label="A préparer" ></el-radio>
                                            <el-radio label="A Valider" ></el-radio>
                                            <el-radio label="Valider" ></el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                            </fieldset>
                        </fieldset>

                        <fieldset class="border p-2 col-lg-12 mt-3" v-if="active === 1 && editmode == false">
                            <legend class="w-auto badge"><h5> </h5></legend>
                            <fieldset class="border p-2 mt-2">
                                    <legend class="w-auto badge"><h5>CATEGORIE D'ARTICLE</h5></legend>
                                    <div class="row">
                                        <!--  -->
                                        <el-form-item
                                            class="col-lg-12"
                                            label="CATEGORIE D'ARTICLE"
                                            prop="categoriArticle"
                                        >
                                            <el-select  style="width: 100%" v-model="ArticlesForm.typeEtiquette" placeholder="SELECTIONNER LE TYPE DE PROJET">
                                                <el-option label="ETIQUETTE" value="ETIQUETTE"></el-option>
                                                <el-option label="COMPLEXE" value="COMPLEXE"></el-option>
                                                <el-option label="INTERCALAIRE" value="INTERCALAIRE"></el-option>
                                                <!--  -->
                                                <el-option label="CONRNIERE EN CARTON" value="CONRNIERE EN CARTON"></el-option>
                                                <el-option label="CHAPPES" value="CHAPPES"></el-option>
                                                <el-option label="FILET TUBULAIRE A BASE DU PLASTIQUE" value="FILET TUBULAIRE A BASE DU PLASTIQUE"></el-option>
                                                <el-option label="FILET TUBULAIRE cellulosique" value="FILET TUBULAIRE cellulosique"></el-option>
                                                <el-option label="FILET PALITISATION A BASE DU PLASTIQUE" value="FILET TUBULAIRE PALITISATION A BASE DU PLASTIQUE"></el-option>
                                                <el-option label="FILET PALITISATION cellulosique" value="FILET TUBULAIRE PALITISATION cellulosique"></el-option>
                                                <el-option label="FEUILLARD EN PLASTIQUE" value="FEUILLARD EN PLASTIQUE"></el-option>
                                                <el-option label="FEUILLARD METALIQUE" value="FEUILLARD METALIQUE"></el-option>
                                                <el-option label="PAPILLOTES" value="PAPILLOTES"></el-option>
                                                <el-option label="ALVEOLES" value="ALVEOLES"></el-option>
                                                <el-option label="BARQUETTES EN PLASTIQUE" value="BARQUETTES EN PLASTIQUE"></el-option>
                                                <el-option label="BARQUETTES EN CARTON" value="BARQUETTES EN CARTON"></el-option>
                                                <el-option label="ETIQUETTE CRAVATTE BIODEGRADABLE NON COMPOSTABLE" value="ETIQUETTE CRAVATTE BIODEGRADABLE NON COMPOSTABLE"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </div>
                            </fieldset>

                            <fieldset class="border p-2 mt-2">
                                    <legend class="w-auto badge"><h5></h5></legend>
                                    <div class="row">
                                            
                                        <el-form-item class="col-lg-6" prop="formatMM_L" label="FORMAT EN (mm)">
                                            <div class="row">
                                                <el-input class="col-3" type="number" placeholder="L" v-model="ArticlesForm.formatMM_L" autocomplete="off"></el-input>
                                                <p class="col-1"> X </p>
                                                <el-input class="col-3" type="number" placeholder="D" v-model="ArticlesForm.formatMM_D" autocomplete="off"></el-input>
                                            </div>
                                        </el-form-item>
                                        <el-form-item class="col-lg-6">

                                        </el-form-item>
                                            
                                        <el-form-item 
                                            class="col-lg-4" 
                                            prop="forme_etq" 
                                            label="FORME D'ETIQUETTE"
                                        >
                                            <el-select v-model="ArticlesForm.forme_etq" style="width: 100%" placeholder="Please select Form d'étiquitte">
                                                <el-option label="REC" value="REC"></el-option>
                                                <el-option label="OVA" value="OVA"></el-option>
                                                <el-option label="CAR" value="CAR"></el-option>
                                                <el-option label="RONDER" value="RONDER"></el-option>
                                                <el-option label="TRIANGULAIRE" value="TRIANGULAIRE"></el-option>
                                                <el-option label="SPECIALE" value="SPECIALE"></el-option>
                                            </el-select>
                                        </el-form-item>

                                        <el-form-item 
                                            class="col-lg-4" 
                                            prop="support_papier" 
                                            label="SUPPORT (PAPIER)"
                                        >
                                            <el-select v-model="ArticlesForm.support_papier" style="width: 100%" placeholder="Please select Support Papier">
                                                <el-option label="ADHESIF THERMIQUE" value="ADHESIF THERMIQUE"></el-option>
                                                <el-option label="ADHESIF COUCHÉ" value="ADHESIF COUCHÉ"></el-option>
                                                <el-option label="ADHESIF PP (TRANSPARENT)" value="ADHESIF PP (TRANSPARENT)"></el-option>
                                                <el-option label="ADHESIF PP (BLANC PVC)" value="ADHESIF PP (BLANC PVC)"></el-option>
                                                
                                                <el-option label="ETIQUETTE CRAVATE" value="ETIQUETTE CRAVATE"></el-option>
                                                <el-option label="ETIQUETTE CRAVATE BIO BIODEGRADABLE" value="ETIQUETTE CRAVATE BIO BIODEGRADABLE"></el-option>
                                                <!--  -->
                                                <el-option label="PAPIER COUCHE BRILLANT" value="PAPIER COUCHE BRILLANT"></el-option>
                                                <el-option label="PAPIER COUCHE MATT" value="PAPIER COUCHE MATT"></el-option>
                                                <el-option label="BLANC SUPERIEUR" value="BLANC SUPERIEUR"></el-option>
                                                <el-option label="CARTE BLANCHE" value="CARTE BLANCHE"></el-option>
                                                <el-option label="DUPLEXE DOS GRIS" value="DUPLEXE DOS GRIS"></el-option>
                                                <el-option label="DUPLEXE DOS BLANC" value="DUPLEXE DOS BLANC"></el-option>
                                                <el-option label="DUPLEXE DOS CREME" value="DUPLEXE DOS CREME"></el-option>
                                            </el-select>
                                        </el-form-item>
                                        <!-- Intercalaire -->
                                        <!-- <el-form-item 
                                            class="col-lg-4" 
                                            prop="support_papier" 
                                            label="GRAMMAGE EN (g/m²)"
                                        >
                                            <el-select v-model="form.grammage" style="width: 100%" placeholder="Please select Support Papier">
                                                
                                                <el-option label="40" value="40"></el-option>
                                                <el-option label="50" value="50"></el-option>
                                                <el-option label="60" value="60"></el-option>
                                                <el-option label="70" value="70"></el-option>
                                                <el-option label="80" value="80"></el-option>
                                                <el-option label="90" value="90"></el-option>
                                                <el-option label="100" value="100"></el-option>
                                                <el-option label="115" value="115"></el-option>
                                                <el-option label="135" value="135"></el-option>
                                                <el-option label="170" value="170"></el-option>
                                                <el-option label="190" value="190"></el-option>
                                                <el-option label="240" value="240"></el-option>
                                                <el-option label="250" value="250"></el-option>
                                                <el-option label="300" value="300"></el-option>
                                                <el-option label="350" value="350"></el-option>
                                                <el-option label="400" value="400"></el-option>
                                                <el-option label="500" value="500"></el-option>
                                                <el-option label="Autre" value="Autre"></el-option>
                                            </el-select>
                                        </el-form-item> -->
                                        <!-- Intercalaire END -->
                                        <el-form-item 
                                            class="col-lg-4" 
                                            prop="quantite_etq" 
                                            label="QUANTITE UNITAIRE"
                                        >
                                            <el-input type="number" v-model="ArticlesForm.quantite_etq" autocomplete="off"></el-input>
                                        </el-form-item>
                                    </div>
                            </fieldset>
                            
                            <!-- Start Impression -->
                            <fieldset class="border p-2 mt-2">
                                    <legend class="w-auto badge"><h5>IMPRESSION</h5></legend>
                                    <div class="row">
                                        <el-form-item 
                                            class="text-center col"
                                            prop="impression"
                                        >
                                            <el-radio-group @change="ImpressionCHanger" v-model="ArticlesForm.impression">
                                                <el-radio label="OUI" ></el-radio>
                                                <el-radio label="Non" ></el-radio>
                                            </el-radio-group>
                                        </el-form-item>
                                        <el-form-item v-if="ArticlesForm.impression == 'Non'"
                                            class="col"
                                        >
                                        <el-alert
                                            title="ATTENTION CETTE ÉTIQUETTE EST VIERGE"
                                            
                                            type="error"
                                            show-icon
                                            >
                                        </el-alert>
                                            <!-- <h2 type="warning">Cette étiquettes est vierge</h2> -->
                                        </el-form-item>
                                    </div>
                                    <!-- Nam Etiquette -->
                                    <fieldset v-if="ArticlesForm.impression == 'OUI'" class="border p-2 mt-2">
                                        <legend class="w-auto badge"><h5></h5></legend>
                                        <div class="row">
                                            <el-form-item
                                                class="col-lg-12"
                                                label="SPECIFICATION NOM D'ETIQUETTE"
                                                prop="nom_etc"
                                            >
                                                <el-input type="text" v-model="ArticlesForm.nom_etc" autocomplete="off"></el-input>
                                            </el-form-item>
                                            <!--  -->
                                        </div>
                                    </fieldset>
                                    <!-- Recto / Verso -->
                                    <fieldset  v-if="ArticlesForm.impression == 'OUI'" class="border p-2 mt-2 ">
                                        <legend class="w-auto badge"><h5></h5></legend>
                                        <div class="row">
                                            <!-- Recto -->
                                            <fieldset class="border col-6 p-2 mt-2 ">
                                                <legend class="w-auto badge"><h5>RECTO</h5></legend>
                                                <el-form-item 
                                                    class="text-center"
                                                    prop="recto" 
                                                >
                                                    <el-radio-group label="top" @change="RectoCouleurCHange" style="width:100%" v-model="ArticlesForm.recto">
                                                        <el-radio label="OUI" ></el-radio>
                                                        <el-radio label="Non" ></el-radio>
                                                    </el-radio-group>
                                                </el-form-item>
                                                <!-- Couleurs Recto -->
                                                <div v-if="ArticlesForm.recto == 'OUI'" class="form-group">
                                                    <el-form-item 
                                                        class="col-12" 
                                                        prop="nbr_clouleur" 
                                                        label="NOMBRE DE COULEURS :"
                                                    >
                                                        <el-input type="number" v-model="ArticlesForm.nbr_clouleur" autocomplete="off"></el-input>

                                                    </el-form-item>
                                                    <fieldset class="border p-2">
                                                    <legend class="w-auto badge badge-warning"><h6>COMPOSANTS D'ENCRE EN RECTO  </h6></legend>
                                                    <tr class="form-group padding: 7px; col-lg-12" v-for="(c,k) in ArticlesForm.couleurs" :key="k">

                                                        <td style="width: 150px; padding: 7px;">
                                                            <input class="form-control" placeholder="Couleur" type="text" v-model="c.couleur"
                                                            />
                                                        </td>
                                                        <td style="width: 150px; padding: 7px;">
                                                            <input class="form-control" placeholder="Ref" type="text" v-model="c.ref"
                                                            />
                                                        </td>
                                                        <td style="width: 150px; padding: 7px;">
                                                            <input class="form-control" placeholder="Formule" type="text"  v-model="c.formule"
                                                            />
                                                        </td>

                                                        <td scope="row" class="trashIconContainer">
                                                            <svg
                                                                v-show="ArticlesForm.couleurs.length > 1"
                                                                @click="deleteRow(k, c)"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 24 24"
                                                                width="24"
                                                                height="24"
                                                                class="ml-2 cursor-pointer"
                                                            >
                                                                <path fill="none" d="M0 0h24v24H0z" />
                                                                <path
                                                                fill="#EC4899"
                                                                d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
                                                                />
                                                            </svg>
                                                        </td>
                                                    </tr>
                                                    <div class="form-group ml-2 float-right">
                                                        <svg  
                                                            @click="addNewRowGlobal"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 24 24"
                                                                width="24"
                                                                height="24"
                                                                class="ml-2 cursor-pointer"
                                                                >
                                                                <path fill="none" d="M0 0h24v24H0z" />
                                                                <path
                                                                fill="green"
                                                                d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                                                                />
                                                            </svg>
                                                        </div>
                                                    </fieldset>
                                                </div>
                                            </fieldset>
                                            <!-- Verso -->
                                            <fieldset class="border col-6 p-2 mt-2 ">
                                                <legend class="w-auto badge"><h5>VERSO</h5></legend>
                                                <el-form-item 
                                                    class="text-center" 
                                                    prop="verso" 
                                                >
                                                    <el-radio-group label="top" @change="VersoCouleurCHange" style="width:100%" v-model="ArticlesForm.verso">
                                                        <el-radio label="OUI" ></el-radio>
                                                        <el-radio label="Non" ></el-radio>
                                                    </el-radio-group>
                                                </el-form-item>
                                                <!-- Couleurs Verso -->
                                                <div v-if="ArticlesForm.verso == 'OUI'">
                                                    <fieldset class="border p-2">
                                                    <legend class="w-auto badge badge-warning"><h6> COMPOSANTS D'ENCRE EN VERSO </h6></legend>
                                                    <tr class="form-group padding: 7px; col-lg-12">

                                                        <td style="width: 200px; padding: 7px;">
                                                            <input class="form-control" placeholder="Couleur" type="text" v-model="ArticlesForm.verso_couleur"
                                                            />
                                                        </td>
                                                        <td style="width: 200px; padding: 7px;">
                                                            <input class="form-control" placeholder="Ref" type="text" v-model="ArticlesForm.verso_ref"
                                                            />
                                                        </td>
                                                        <td style="width: 200px; padding: 7px;">
                                                            <input class="form-control" placeholder="Formule" type="text"  v-model="ArticlesForm.verso_formule"
                                                            />
                                                        </td>

                                                    </tr>

                                                    </fieldset>
                                                </div>
                                            </fieldset>
                                            <!-- <div class="col-12"></div> -->

                                            
                                            <!--  -->
                                        </div>
                                    </fieldset>
                                        
                                    <!-- Sens De Sortie -->
                                    <fieldset v-if="ArticlesForm.impression == 'OUI'" class="border p-2">
                                            <legend class="w-auto badge badge-success"><h6> SENS DE SORTIE </h6></legend>
                                            <div class="row">
                                                <div class="form-group col">
                                                    <fieldset class="border p-2">
                                                        <legend class="w-auto badge badge-warning"><h6> SENS D'IMPRESSION </h6></legend>
                                                    <div class="row">
                                                            <div class="col">
                                                                <div class='text-center'>
                                                                    <input type="radio" v-model="ArticlesForm.sens_sortie" name="sansImpression" id="sansImpressionimg1" class="d-none imgbgchk" value="externe">
                                                                    <label for="sansImpressionimg1">
                                                                        <img src="/images/form1C.png" alt="Image 1">
                                                                        <div class="tick_container">
                                                                        <div class="tick"><i class="fa fa-check"></i></div>
                                                                        </div>
                                                                    </label>
                                                                    <p> EXTERNE </p>
                                                                </div>
                                                            </div>
                                                            <div class="col">
                                                            <div class='text-center'>
                                                                    <input type="radio" v-model="ArticlesForm.sens_sortie" name="sansImpression" id="sansImpressionimg2" class="d-none imgbgchk" value="interne">
                                                                    <label for="sansImpressionimg2">
                                                                        <img src="/images/form2C.png" alt="Image 1">
                                                                        <div class="tick_container">
                                                                        <div class="tick"><i class="fa fa-check"></i></div>
                                                                        </div>
                                                                    </label>
                                                                    <p> INTERNE </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                </div>
                                                <!-- externe -->
                                                <div v-if="ArticlesForm.sens_sortie == 'externe'" class="form-group col">
                                                    <fieldset class="border p-2">
                                                        <legend class="w-auto badge badge-warning"><h6> EXTERNE </h6></legend>
                                                            <div class="row">
                                                                <div class="col-sm-6">
                                                                <div class='text-center'>
                                                                        <input type="radio" v-model="ArticlesForm.type_sense_sortie" name="avecImpressionEX" id="avecImpressionEXimg1" class="d-none imgbgchk" value="e_up">
                                                                        <label for="avecImpressionEXimg1">
                                                                            <img src="/images/form1T.png" alt="Image 1">
                                                                            <div class="tick_container">
                                                                            <div class="tick"><i class="fa fa-check"></i></div>
                                                                            </div>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div class="col-sm-6">
                                                                <div class='text-center'>
                                                                        <input type="radio" name="avecImpressionEX" v-model="ArticlesForm.type_sense_sortie" id="avecImpressionEXimg2" class="d-none imgbgchk" value="e_down">
                                                                        <label for="avecImpressionEXimg2">
                                                                            <img src="/images/form1B.png" alt="Image 1">
                                                                            <div class="tick_container">
                                                                            <div class="tick"><i class="fa fa-check"></i></div>
                                                                            </div>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div class="col-sm-6">
                                                                <div class='text-center'>
                                                                        <input type="radio" v-model="ArticlesForm.type_sense_sortie" name="avecImpressionEX" id="avecImpressionEXimg3" class="d-none imgbgchk" value="e_left">
                                                                        <label for="avecImpressionEXimg3">
                                                                            <img src="/images/form1R.png" alt="Image 1">
                                                                            <div class="tick_container">
                                                                            <div class="tick"><i class="fa fa-check"></i></div>
                                                                            </div>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div class="col-sm-6">
                                                                <div class='text-center'>
                                                                        <input type="radio" v-model="ArticlesForm.type_sense_sortie" name="avecImpressionEX" id="avecImpressionEXimg4" class="d-none imgbgchk" value="e_right">
                                                                        <label for="avecImpressionEXimg4">
                                                                            <img src="/images/form1.png" alt="Image 1">
                                                                            <div class="tick_container">
                                                                            <div class="tick"><i class="fa fa-check"></i></div>
                                                                            </div>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    </fieldset>
                                                </div>
                                                <!-- Interne -->
                                                <div v-if="ArticlesForm.sens_sortie == 'interne'" class="form-group col">
                                                    <fieldset class="border p-2">
                                                        <legend class="w-auto badge badge-warning"><h6> INTERNE </h6></legend>
                                                            <div class="row">
                                                                <div class="col-sm-6">
                                                                    <div class='text-center'>
                                                                            <input type="radio" v-model="ArticlesForm.type_sense_sortie" name="avecImpressionIN" id="avecImpressionINimg1" class="d-none imgbgchk" value="i_up">
                                                                            <label for="avecImpressionINimg1">
                                                                                <img src="/images/form2T.png" alt="Image 1">
                                                                                <div class="tick_container">
                                                                                <div class="tick"><i class="fa fa-check"></i></div>
                                                                                </div>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-sm-6">
                                                                    <div class='text-center'>
                                                                            <input type="radio" v-model="ArticlesForm.type_sense_sortie" name="avecImpressionIN" id="avecImpressionINimg2" class="d-none imgbgchk" value="i_down">
                                                                            <label for="avecImpressionINimg2">
                                                                                <img src="/images/form2B.png" alt="Image 1">
                                                                                <div class="tick_container">
                                                                                <div class="tick"><i class="fa fa-check"></i></div>
                                                                                </div>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-sm-6">
                                                                    <div class='text-center'>
                                                                            <input type="radio" v-model="ArticlesForm.type_sense_sortie" name="avecImpressionIN" id="avecImpressionINimg3" class="d-none imgbgchk" value="i_right">
                                                                            <label for="avecImpressionINimg3">
                                                                                <img src="/images/form2R.png" alt="Image 1">
                                                                                <div class="tick_container">
                                                                                <div class="tick"><i class="fa fa-check"></i></div>
                                                                                </div>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-sm-6">
                                                                    <div class='text-center'>
                                                                            <input type="radio" v-model="ArticlesForm.type_sense_sortie" name="avecImpressionIN" id="avecImpressionINimg4" class="d-none imgbgchk" value="i_left">
                                                                            <label for="avecImpressionINimg4">
                                                                                <img src="/images/form2L.png" alt="Image 1">
                                                                                <div class="tick_container">
                                                                                <div class="tick"><i class="fa fa-check"></i></div>
                                                                                </div>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                            </div>
                                                    </fieldset>
                                                </div>
                                                <!--  -->
                                            </div>
                                    </fieldset>
                                    
                            </fieldset>
                            <!-- ENd -->
                            <fieldset class="border p-2 mt-2 ">
                                    <legend class="w-auto badge"><h5></h5></legend>
                                    <div class="row">
                                        <el-form-item 
                                            class="col-lg-3 col-sm-6 col-md-6" 
                                            prop="repiquage" 
                                            label="REPIQUAGE :"
                                        >
                                            <el-radio-group label="top" style="width:100%" v-model="ArticlesForm.repiquage">
                                                <el-radio label="OUI" ></el-radio>
                                                <el-radio label="Non" ></el-radio>
                                            </el-radio-group>
                                        </el-form-item>

                                        <el-form-item 
                                            v-if="ArticlesForm.impression == 'OUI'"
                                            class="col-lg-3 col-sm-6 col-md-6" 
                                            prop="vernis" 
                                            label="VERNIS :"
                                        >
                                            <el-radio-group label="top" style="width:100%" v-model="ArticlesForm.vernis">
                                                <el-radio label="OUI" ></el-radio>
                                                <el-radio label="Non" ></el-radio>
                                            </el-radio-group>
                                            <el-select v-if="ArticlesForm.vernis == 'OUI'"  v-model="ArticlesForm.vernis_type" style="width: 100%" placeholder="Sélectionner le type de Vernis">
                                                <el-option label="Matte" value="Matte"></el-option>
                                                <el-option label="Brillant" value="Brillant"></el-option>
                                            </el-select>
                                        </el-form-item>

                                        <el-form-item 
                                            v-if="ArticlesForm.impression == 'OUI'"
                                            class="col-lg-3 col-sm-6 col-md-6" 
                                            prop="plastification" 
                                            label="PLASTIFICATION :"
                                        >
                                            <el-radio-group label="top" style="width:100%" v-model="ArticlesForm.plastification">
                                                <el-radio label="OUI" ></el-radio>
                                                <el-radio label="Non" ></el-radio>
                                            </el-radio-group>
                                            <el-select v-if="ArticlesForm.plastification == 'OUI'"  v-model="ArticlesForm.plastification_type" style="width: 100%" placeholder="Sélectionner le type de Plastification">
                                                <el-option label="Matte" value="Matte"></el-option>
                                                <el-option label="Brillant" value="Brillant"></el-option>
                                            </el-select>
                                        </el-form-item>

                                        <el-form-item 
                                            v-if="ArticlesForm.impression == 'OUI'"
                                            class="col-lg-3 col-sm-6 col-md-6" 
                                            prop="dorure" 
                                            label="DORURE :"
                                        >
                                            <el-radio-group label="top" style="width:100%" v-model="ArticlesForm.dorure">
                                                <el-radio label="OUI" ></el-radio>
                                                <el-radio label="Non" ></el-radio>
                                            </el-radio-group>
                                            <el-select v-if="ArticlesForm.dorure == 'OUI'"  v-model="ArticlesForm.dorure_type" style="width: 100%" placeholder="Sélectionner le type de Dorure">
                                                <el-option label="Chaud" value="Chaud"></el-option>
                                                <el-option label="Froides" value="Froides"></el-option>
                                            </el-select>
                                            <el-select v-if="ArticlesForm.dorure_type == 'Chaud'"  v-model="ArticlesForm.couleur_dorure" style="width: 100%" placeholder="Sélectionner la couleur">
                                                <el-option label="Argenté" value="Argenté"></el-option>
                                                <el-option label="Doré" value="Doré"></el-option>
                                                <el-option label="autre" value="autre"></el-option>
                                            </el-select>
                                            <el-select v-if="ArticlesForm.dorure_type == 'Froides'"  v-model="ArticlesForm.couleur_dorure" style="width: 100%" placeholder="Sélectionner la couleur">
                                                <el-option label="Argenté" value="Argenté"></el-option>
                                                <el-option label="Doré" value="Doré"></el-option>
                                                <el-option label="autre" value="autre"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </div>
                            </fieldset>

                            <fieldset class="border p-2 mt-2">
                                    <legend class="w-auto badge"><h5>AVEC CONCEPTION</h5></legend>
                                    <el-form-item 
                                        class="text-center"
                                        prop="conception"
                                    >
                                        <el-radio-group v-model="ArticlesForm.conception">
                                            <el-radio label="OUI" ></el-radio>
                                            <el-radio label="Non" ></el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                            </fieldset>

                            <fieldset class="border p-2 mt-2 ">
                                    <legend class="w-auto badge"><h5></h5></legend>
                                    <div class="row">
                                        <el-form-item 
                                            class="col-lg-3" 
                                            prop="nbr_etq_bobine" 
                                            label="NBR ETQ / BOBINE :"
                                        >
                                        
                                        <el-input type="number" v-model="ArticlesForm.nbr_etq_bobine" autocomplete="off"></el-input>

                                        </el-form-item>
                                        <el-form-item 
                                            class="col-lg-3" 
                                            prop="nbr_etq_front" 
                                            label="NBR ETQ / DE FRONT :"
                                        >
                                        
                                        <el-input type="number" v-model="ArticlesForm.nbr_etq_front" autocomplete="off"></el-input>

                                        </el-form-item>
                                        <el-form-item 
                                            class="col-lg-3" 
                                            prop="mandrin" 
                                            label="MANDRIN :"
                                        >
                                            <el-radio-group label="top" v-model="ArticlesForm.mandrin" @change="MandrinChange" style="width:100%" >
                                                <el-radio label="40"></el-radio>
                                                <el-radio label="76"></el-radio>
                                                <el-radio label="Autre"></el-radio>
                                            </el-radio-group>
                                            
                                            <el-input v-if="ArticlesForm.mandrin == 'Autre'" type="number" placeholder="Saisie Mandrin ici" v-model="ArticlesForm.mandrin_autre" autocomplete="off"></el-input>
                                        </el-form-item>
                                        <el-form-item 
                                            class="col-lg-3" 
                                            prop="poses_etq" 
                                            label="POSES D'ETIQUETTE :"
                                        >
                                            <el-radio-group label="top" style="width:100%" v-model="ArticlesForm.poses_etq">
                                                <el-radio label="Manuelle"></el-radio>
                                                <el-radio label="Auto"></el-radio>
                                            </el-radio-group>
                                        </el-form-item>
                                    </div>
                            </fieldset>

                            <fieldset class="border p-2 mt-2">
                                    <legend class="w-auto badge"><h5>EXISTANCE FILMS , CLICHES ET FORM DE DECOUPE</h5></legend>
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <fieldset class="border p-2">
                                                <legend class="w-auto badge badge-inf">FORM DE DECOUPE</legend>
                                                <div class="row">
                                                        <el-form-item 
                                                            class="col text-center"
                                                            prop="form_decoupe"
                                                            >
                                                            <el-radio-group v-model="ArticlesForm.form_decoupe">
                                                                <el-radio label="OUI" ></el-radio>
                                                                <el-radio label="Non" ></el-radio>
                                                            </el-radio-group>
                                                            
                                                            <el-input v-if="ArticlesForm.form_decoupe == 'OUI'" type="text" placeholder="Saisie Form Decoupe" v-model="ArticlesForm.form_decoupe_id" autocomplete="off"></el-input>

                                                        </el-form-item>

                                                </div>
                                            </fieldset>
                                        </div>
                                        <div class="col-lg-6" v-if="ArticlesForm.impression == 'OUI'">
                                            <fieldset class="border p-2">
                                                <legend class="w-auto badge badge-inf">FILMS ET CLICHES</legend>
                                                <div class="row">
                                                        <el-form-item 
                                                            class="col text-center"
                                                            prop="films_cliches"
                                                            >
                                                            <el-radio-group v-model="ArticlesForm.films_cliches">
                                                                <el-radio label="OUI" ></el-radio>
                                                                <el-radio label="Non" ></el-radio>
                                                            </el-radio-group>
                                                            
                                                            <el-input v-if="ArticlesForm.films_cliches == 'OUI'" type="text" placeholder="Saisie Cliché" v-model="ArticlesForm.films_cliches_id" autocomplete="off"></el-input>

                                                        </el-form-item>
                                                </div>
                                            </fieldset>
                                        </div>
                                    </div>
                            </fieldset>
                            <fieldset class="border p-2 mt-2">
                                <el-button type="success" @click="addNewRowDevis" icon="el-icon-plus" class="float-right">Ajouter l'article</el-button>
                            </fieldset>
                        </fieldset>
                        <!-- show all data -->
                        <fieldset class="p-2 col-lg-12 mt-3" v-if="active === 1 && editmode == false">
                                            <legend class="w-auto badge"><h5> Liste des Articles </h5></legend>
                                                <div v-for="(c, k) in form_global.ArticlesCommander" :key="k">
                                                    <el-descriptions class="margin-top" border :title="'Article N° : '+k" v-if="c.typeEtiquette != ''">
                                                        <template slot="extra">
                                                            <!-- <el-button @click="updateArticle(k, c)" size="mini"  data-toggle="tooltip" data-placement="top" title="Modifier l'article" type="primary" icon="el-icon-edit" circle></el-button>
                                                            <el-divider direction="vertical"></el-divider> -->
                                                            <el-button type="danger" @click="deleteRowDevis(k, c)" size="mini" icon="el-icon-delete" data-toggle="tooltip" data-placement="top" title="Supprimer l'article" circle></el-button>
                                                        </template>
                                                        <!--  -->
                                                            <el-descriptions-item label="Categorie D'article">{{c.typeEtiquette}}</el-descriptions-item>
                                                            <el-descriptions-item label="Nom D'etiquette">{{c.nom_etc}}</el-descriptions-item>
                                                            <el-descriptions-item label="Format en (mm)">{{c.formatMM_L}}x{{c.formatMM_D}}</el-descriptions-item>
                                                            <el-descriptions-item label="Forme d'etiquette">{{c.forme_etq}}</el-descriptions-item>
                                                            <el-descriptions-item label="Support (PAPIER)">{{c.support_papier}}</el-descriptions-item>
                                                            <el-descriptions-item label="Quantite Unitaire">{{c.quantite_etq}}</el-descriptions-item>
                                                            <el-descriptions-item label="Impression">{{c.impression}}</el-descriptions-item>
                                                            <!-- start impression -->
                                                            <el-descriptions-item v-if="c.impression == 'OUI'" label="Recto">{{c.recto}}</el-descriptions-item>
                                                            <el-descriptions-item v-if="c.impression == 'OUI'" label="Sens d'impression">{{c.sens_sortie}}</el-descriptions-item>
                                                            <el-descriptions-item v-if="c.recto == 'OUI'" label="Nombre couleurs">{{c.nbr_clouleur}}</el-descriptions-item>
                                                            <!--  -->
                                                            <el-descriptions-item v-if="c.impression == 'OUI'" label="Verso">{{c.verso}}</el-descriptions-item>
                                                            <el-descriptions-item v-if="c.verso == 'OUI'" label="Couleur ">{{c.verso_couleur}}</el-descriptions-item>
                                                            <el-descriptions-item v-if="c.verso == 'OUI'" label="Formule ">{{c.verso_formule}}</el-descriptions-item>
                                                            <el-descriptions-item v-if="c.verso == 'OUI'" label="Ref ">{{c.verso_ref}}</el-descriptions-item>
                                                            <!-- rep plast dorure -->
                                                            <el-descriptions-item v-if="c.impression == 'OUI'" label="Vernis ">{{c.vernis}} - {{c.vernis_type}} </el-descriptions-item>
                                                            <el-descriptions-item v-if="c.impression == 'OUI'" label="Plastification ">{{c.plastification}} - {{c.plastification_type}} </el-descriptions-item>
                                                            <el-descriptions-item v-if="c.impression == 'OUI'" label="dorure ">{{c.dorure}} - {{c.dorure_type}} </el-descriptions-item>
                                                            <!-- detaills impression -->
                                                            <el-descriptions-item label="Repiquage">{{c.repiquage}}</el-descriptions-item>
                                                            <el-descriptions-item label="Conception">{{c.conception}}</el-descriptions-item>
                                                            <el-descriptions-item label="Nbr ETQ / Bobine ">{{c.nbr_etq_bobine}}</el-descriptions-item>
                                                            <el-descriptions-item label="Nbr ETQ / Front ">{{c.nbr_etq_front}}</el-descriptions-item>
                                                            <el-descriptions-item label="Mandrin ">{{c.mandrin}}</el-descriptions-item>
                                                            <el-descriptions-item label="Poses D'etiquette ">{{c.poses_etq}}</el-descriptions-item>
                                                            <el-descriptions-item label="Form de decoupe">{{c.form_decoupe}}</el-descriptions-item>
                                                            <el-descriptions-item label="Films , Cliches">{{c.films_cliches}}</el-descriptions-item>
                                                        <!--  -->
                                                        
                                                    </el-descriptions>
                                                </div>
                        </fieldset>

                        <!-- modalité de FACTURATION -->
                        <fieldset class="border p-2 col-lg-12 mt-3" v-if="active === 2 && editmode == false">
                            <legend class="w-auto badge"><h5> </h5></legend>
                            <fieldset class="border p-2 mt-2">
                                    <legend class="w-auto badge"><h5>MODALITÉ DE FACTURATION</h5></legend>
                                    <el-form-item 
                                        class="text-center"
                                        prop="modalite_paiment"
                                    >
                                        <el-radio-group v-model="form_global.modalite_paiment">
                                            <el-radio label="AT" ></el-radio>
                                            <el-radio label="TVA" ></el-radio>
                                            <el-radio v-if="$gate.isAdmin()" label="COMPTANT" ></el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                            </fieldset>
                        </fieldset>
                    </div>

                    <!-- validation de devis -->
                    <fieldset class="border p-2 mt-2" v-if="disableDevisPV == true">
                            <legend class="w-auto badge"><h5>Validation De Devis</h5></legend>
                            <div class="row d-flex justify-content-center">
                                <el-form-item 
                                    v-if="$gate.isCMRS()"
                                    class="text-center col-4"
                                    prop="validation1"
                                    label="Commercial :"
                                >
                                    <el-switch
                                        v-model="form.validation1"
                                        active-value="1"
                                        inactive-value="0"
                                        active-color="#13ce66"
                                        inactive-color="#ff4949"
                                        inactive-text="Invalide"
                                        active-text="Valide"
                                    >
                                    </el-switch>
                                </el-form-item>
                                <el-form-item 
                                    v-if="$gate.isAdmin()"
                                    class="text-center col-4"
                                    prop="validation3"
                                    label="Administration :"
                                >
                                    <el-switch
                                        v-model="form.validation3"
                                        active-value="1"
                                        inactive-value="0"
                                        active-color="#13ce66"
                                        inactive-color="#ff4949"
                                        inactive-text="Invalide"
                                        active-text="Valide"
                                    >
                                    </el-switch>
                                </el-form-item>
                                
                            </div>
                    </fieldset>

                    <!-- next prev -->
                    <fieldset v-if="editmode == false" class="border p-2 col-lg-12">
                        <el-form-item>
                            <el-button v-if="this.active > 0" type="primary" @click="prev" icon="el-icon-arrow-left">L'étape Précédente </el-button>
                            <el-button v-if="this.active < 2" type="primary" class="float-right"  @click="next">L'étape suivante <i class="el-icon-arrow-right"></i></el-button>
                            <!-- <el-button v-if="this.active < 2" type="primary" class="float-right"  @click=submitForm1(form_global)>L'étape suivante <i class="el-icon-arrow-right"></i></el-button> -->
                        </el-form-item>
                    </fieldset>
                    
                </el-form>
            <!-- ENd body Dialog -->
            <span slot="footer" v-if="this.active == 2 " class="dialog-footer">
                <el-button @click="DialogVisible_global = false">Annuler</el-button>
                <el-button type="primary" v-show="!editmode" @click="createData()">Valider</el-button>
                <el-button type="primary" v-show="editmode" @click="updateData()">Valider</el-button>
            </span>
            <!-- show on edit mode -->
            <span slot="footer" v-show="editmode"  class="dialog-footer">
                <el-button @click="DialogVisible_global = false">Annuler</el-button>
                <el-button type="primary" v-show="editmode" @click="updateGlobalData()">Valider</el-button>
            </span>
        </el-dialog>
        <!-- End dialog_Global -->
        <!-- Dialog Prices -->
        <el-dialog
            :title="'MISE A JOUR DES PRIX'"
            :visible.sync="DialogVisiblePrices"
            width="90%"
            :before-close="handleClose">
            <!-- Body dialog -->
                <el-form :model="form_Prices" ref="form_Prices" class="demo-ruleForm">
                    <table class="table table-bordered">
                                <thead>
                                    <tr>
                                    <th scope="col">Réferance</th>
                                    <th scope="col">Quantité</th>
                                    <th scope="col">Désignation</th>
                                    <!-- <th scope="col">Unité</th>
                                    <th scope="col">TAUX T.V.A</th> -->
                                    <th scope="col">Prix Calculer</th>
                                    <th scope="col">Prix Proposer Par Client</th>
                                    <th scope="col">Prix Final</th>
                                    </tr>
                                </thead>
                                <tbody v-for="(c, k) in form_Prices.devisPrices" :key="k">
                                    <tr >
                                        <th scope="row">{{c.id}}</th>
                                        <td>{{c.quantite_etq}}</td>
                                        <td>{{c.nom_etc}}</td>
                                        <!-- <td>Unité</td> -->
                                        <!-- <td>{{tvaCentage}}%</td> -->
                                        <td>{{c.prixHTTApliquer}}</td>
                                        <td><el-input  v-model="c.prixPClient" type="number" :min=0 ></el-input></td>
                                        <td><el-input  v-model="c.prixFinal" type="number" :min=0 ></el-input></td>
                                    </tr>
                                </tbody>
                        </table>
                    
                    <!-- next prev -->
                </el-form>
            <!-- show on edit mode -->
            <span slot="footer"  class="dialog-footer">
                <el-button @click="DialogVisiblePrices = false">Annuler</el-button>
                <el-button type="primary" @click="updatePrices()">Valider</el-button>
            </span>
        </el-dialog>
        <!-- dialog devis-->
        <el-dialog
            :title="editmode ? 'MODIFICATION DE DEVIS ETIQUETTES':'PREPARATION DE NOUVEAU DEVIS ETIQUETTES'"
            :visible.sync="DialogVisible"
            width="90%"
            :before-close="handleClose">
            <!-- Body dialog -->
            
                <el-form :model="form">
                    
                    <div v-if="disableDevisPV == false">
                        <div class="col-8"></div>
                           
                            <fieldset class="col-12 border p-2 mt-2">
                                <legend v-show="!editmode" class="w-auto badge"><h5>NOUVEAU CLIENT ?</h5></legend>
                                <el-form-item
                                    v-show="!editmode"
                                    class="text-center"
                                    prop="existClient"
                                >
                                    <el-radio-group @change="ExistClientCHange" v-model="form.existClient">
                                        <el-radio label="OUI" ></el-radio>
                                        <el-radio label="Non" ></el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <!-- new client -->
                                <fieldset  v-if="form.existClient == 'Non'" class="border p-2 mt-2 ">
                                    <el-form-item 
                                            class="col-lg-4" 
                                            prop="id_client" 
                                            label="CHOISIE LE CLIENT"
                                        >
                                        
                                        <el-select v-model="form.id_client" filterable  style="width: 100%" placeholder="Veuillez sélectionner un client">
                                            <el-option
                                                v-for="item in clients"
                                                :key="item.id"
                                                :label="item.name"
                                                :value="item.id"
                                            ></el-option>
                                        </el-select>
                                    </el-form-item>
                                </fieldset>
                                <!-- old client -->
                                <fieldset  v-if="form.existClient == 'OUI'" class="border p-2 mt-2 ">
                                <div class="row" v-for="(c, k) in form.client" :key="k">
                                
                                    <el-form-item
                                    
                                        class="col-lg-4"
                                        label="NOM CLIENT"
                                        prop="name"
                                    >
                                        <el-input type="text" v-model="c.name" autocomplete="off"></el-input>
                                    </el-form-item>
                                    <el-form-item
                                    
                                        class="col-lg-4"
                                        label="NUM TELEPHONE"
                                        prop="tele_client"
                                    >
                                        <el-input type="text" v-model="c.tele" autocomplete="off"></el-input>
                                    </el-form-item>
                                    <el-form-item
                                    
                                        class="col-lg-4"
                                        label="EMAIL"
                                        prop="email_client"
                                    >
                                        <el-input type="email" v-model="c.email" autocomplete="off"></el-input>
                                    </el-form-item>
                                
                                    </div>
                                </fieldset>
                            </fieldset>
                        <!-- </fieldset> -->

                        <fieldset class="border p-2 mt-2">
                                <legend class="w-auto badge"><h5>CATEGORIE D'ARTICLE</h5></legend>
                                <div class="row">
                                    <!--  -->
                                    <el-form-item
                                        class="col-lg-12"
                                        label="CATEGORIE D'ARTICLE"
                                        prop="categoriArticle"
                                    >
                                        <el-select  style="width: 100%" v-model="form.typeEtiquette" placeholder="SELECTIONNER LE TYPE DE PROJET">
                                            <el-option label="Etiquette" value="Etiquette"></el-option>
                                            <el-option label="Complexe" value="Complexe"></el-option>
                                            <el-option label="Intercalaire" value="Intercalaire"></el-option>
                                            <!--  -->
                                            <el-option label="CONRNIERE EN CARTON" value="CONRNIERE EN CARTON"></el-option>
                                            <el-option label="CHAPPES" value="CHAPPES"></el-option>
                                            <el-option label="FILET TUBULAIRE A BASE DU PLASTIQUE" value="FILET TUBULAIRE A BASE DU PLASTIQUE"></el-option>
                                            <el-option label="FILET TUBULAIRE cellulosique" value="FILET TUBULAIRE cellulosique"></el-option>
                                            <el-option label="FILET PALITISATION A BASE DU PLASTIQUE" value="FILET TUBULAIRE PALITISATION A BASE DU PLASTIQUE"></el-option>
                                            <el-option label="FILET PALITISATION cellulosique" value="FILET TUBULAIRE PALITISATION cellulosique"></el-option>
                                            <el-option label="FEUILLARD EN PLASTIQUE" value="FEUILLARD EN PLASTIQUE"></el-option>
                                            <el-option label="FEUILLARD METALIQUE" value="FEUILLARD METALIQUE"></el-option>
                                            <el-option label="PAPILLOTES" value="PAPILLOTES"></el-option>
                                            <el-option label="ALVEOLES" value="ALVEOLES"></el-option>
                                            <el-option label="BARQUETTES EN PLASTIQUE" value="BARQUETTES EN PLASTIQUE"></el-option>
                                            <el-option label="BARQUETTES EN CARTON" value="BARQUETTES EN CARTON"></el-option>
                                            <el-option label="ETIQUETTE CRAVATTE BIODEGRADABLE NON COMPOSTABLE" value="ETIQUETTE CRAVATTE BIODEGRADABLE NON COMPOSTABLE"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                        </fieldset>
                        <fieldset class="border p-2 mt-2">
                                <legend class="w-auto badge"><h5></h5></legend>
                                <div class="row">
                                    <el-form-item
                                        class="col-lg-12"
                                        label="NOM D'ETIQUETTE"
                                        prop="nom_etc"
                                    >
                                        <el-input type="text" v-model="form.nom_etc" autocomplete="off"></el-input>
                                    </el-form-item>
                                    <!--  -->
                                </div>
                        </fieldset>

                        <fieldset class="border p-2 mt-2">
                                <legend class="w-auto badge"><h5></h5></legend>
                                <div class="row">
                                        
                                    <el-form-item class="col-lg-6" prop="formatMM_L" label="FORMAT EN (mm)">
                                        <div class="row">
                                            <el-input class="col-3" type="number" placeholder="L" v-model="form.formatMM_L" autocomplete="off"></el-input>
                                            <p class="col-1"> X </p>
                                            <el-input class="col-3" type="number" placeholder="D" v-model="form.formatMM_D" autocomplete="off"></el-input>
                                        </div>
                                    </el-form-item>
                                    <el-form-item class="col-lg-6">

                                    </el-form-item>
                                           
                                    <el-form-item 
                                        class="col-lg-4" 
                                        prop="forme_etq" 
                                        label="FORME D'ETIQUETTE"
                                    >
                                        <el-select v-model="form.forme_etq" style="width: 100%" placeholder="Please select Form d'étiquitte">
                                            <el-option label="REC" value="REC"></el-option>
                                            <el-option label="OVA" value="OVA"></el-option>
                                            <el-option label="CAR" value="CAR"></el-option>
                                            <el-option label="RONDE" value="RONDE"></el-option>
                                            <el-option label="TRIANGULAIRE" value="TRIANGULAIRE"></el-option>
                                            <el-option label="SPECIALE" value="SPECIALE"></el-option>
                                        </el-select>
                                    </el-form-item>

                                    <el-form-item 
                                        class="col-lg-4" 
                                        prop="support_papier" 
                                        label="SUPPORT (PAPIER)"
                                    >
                                        <el-select v-model="form.support_papier" style="width: 100%" placeholder="Please select Support Papier">
                                            <el-option label="ADHESIF THERMIQUE" value="ADHESIF THERMIQUE"></el-option>
                                            <el-option label="ADHESIF COUCHÉ" value="ADHESIF COUCHÉ"></el-option>
                                            <el-option label="ADHESIF PP (TRANSPARENT)" value="ADHESIF PP (TRANSPARENT)"></el-option>
                                            <el-option label="ADHESIF PP (BLANC PVC)" value="ADHESIF PP (BLANC PVC)"></el-option>
                                            <el-option label="ETIQUETTE CRAVATE" value="ETIQUETTE CRAVATE"></el-option>
                                            <el-option label="ETIQUETTE CRAVATE BIODEGRADABLE" value="ETIQUETTE CRAVATE BIODEGRADABLE"></el-option>
                                            <!--  -->
                                            <el-option label="PAPIER COUCHE BRILLANT" value="PAPIER COUCHE BRILLANT"></el-option>
                                            <el-option label="PAPIER COUCHE MATT" value="PAPIER COUCHE MATT"></el-option>
                                            <el-option label="BLANC SUPERIEUR" value="BLANC SUPERIEUR"></el-option>
                                            <el-option label="CARTE BLANCHE" value="CARTE BLANCHE"></el-option>
                                            <el-option label="DUPLEXE DOS GRIS" value="DUPLEXE DOS GRIS"></el-option>
                                            <el-option label="DUPLEXE DOS BLANC" value="DUPLEXE DOS BLANC"></el-option>
                                            <el-option label="DUPLEXE DOS CREME" value="DUPLEXE DOS CREME"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <!-- Intercalaire -->
                                    <!-- <el-form-item 
                                        class="col-lg-4" 
                                        prop="support_papier" 
                                        label="GRAMMAGE EN (g/m²)"
                                    >
                                        <el-select v-model="form.grammage" style="width: 100%" placeholder="Please select Support Papier">
                                            
                                            <el-option label="40" value="40"></el-option>
                                            <el-option label="50" value="50"></el-option>
                                            <el-option label="60" value="60"></el-option>
                                            <el-option label="70" value="70"></el-option>
                                            <el-option label="80" value="80"></el-option>
                                            <el-option label="90" value="90"></el-option>
                                            <el-option label="100" value="100"></el-option>
                                            <el-option label="115" value="115"></el-option>
                                            <el-option label="135" value="135"></el-option>
                                            <el-option label="170" value="170"></el-option>
                                            <el-option label="190" value="190"></el-option>
                                            <el-option label="240" value="240"></el-option>
                                            <el-option label="250" value="250"></el-option>
                                            <el-option label="300" value="300"></el-option>
                                            <el-option label="350" value="350"></el-option>
                                            <el-option label="400" value="400"></el-option>
                                            <el-option label="500" value="500"></el-option>
                                            <el-option label="Autre" value="Autre"></el-option>
                                        </el-select>
                                    </el-form-item> -->
                                    <!-- Intercalaire END -->
                                    <el-form-item 
                                        class="col-lg-4" 
                                        prop="quantite_etq" 
                                        label="QUANTITE UNITAIRE"
                                    >
                                        <el-input type="number" v-model="form.quantite_etq" autocomplete="off"></el-input>
                                    </el-form-item>
                                </div>
                        </fieldset>
                        
                        <!-- Start Impression -->
                        <fieldset class="border p-2 mt-2">
                                <legend class="w-auto badge"><h5>IMPRESSION</h5></legend>
                                <div class="row">
                                    <el-form-item 
                                        class="text-center col"
                                        prop="impression"
                                    >
                                        <el-radio-group @change="ImpressionCHanger" v-model="form.impression">
                                            <el-radio label="OUI" ></el-radio>
                                            <el-radio label="Non" ></el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                    <el-form-item v-if="form.impression == 'Non'"
                                        class="col"
                                    >
                                    <el-alert
                                        title="ATTENTION CETTE ÉTIQUETTE EST VIERGE"
                                        
                                        type="error"
                                        show-icon
                                        >
                                    </el-alert>
                                        <!-- <h2 type="warning">Cette étiquettes est vierge</h2> -->
                                    </el-form-item>
                                </div>
                                <!-- Recto / Verso -->
                                <fieldset  v-if="form.impression == 'OUI'" class="border p-2 mt-2 ">
                                    <legend class="w-auto badge"><h5></h5></legend>
                                    <div class="row">
                                        <!-- Recto -->
                                        <fieldset class="border col-6 p-2 mt-2 ">
                                            <legend class="w-auto badge"><h5>RECTO</h5></legend>
                                            <el-form-item 
                                                class="text-center"
                                                prop="recto" 
                                            >
                                                <el-radio-group label="top" @change="RectoCouleurCHange" style="width:100%" v-model="form.recto">
                                                    <el-radio label="OUI" ></el-radio>
                                                    <el-radio label="Non" ></el-radio>
                                                </el-radio-group>
                                            </el-form-item>
                                            <!-- Couleurs Recto -->
                                            <div v-if="form.recto == 'OUI'" class="form-group">
                                                <el-form-item 
                                                    class="col-12" 
                                                    prop="nbr_clouleur" 
                                                    label="NOMBRE DE COULEURS :"
                                                >
                                                    <el-input type="number" v-model="form.nbr_clouleur" autocomplete="off"></el-input>

                                                </el-form-item>
                                                <fieldset class="border p-2">
                                                <legend class="w-auto badge badge-warning"><h6>COMPOSANTS D'ENCRE EN RECTO  </h6></legend>
                                                <tr class="form-group padding: 7px; col-lg-12" v-for="(c,k) in form.couleurs" :key="k">

                                                    <td style="width: 150px; padding: 7px;">
                                                        <input class="form-control" placeholder="Couleur" type="text" v-model="c.couleur"
                                                        />
                                                    </td>
                                                    <td style="width: 150px; padding: 7px;">
                                                        <input class="form-control" placeholder="Ref" type="text" v-model="c.ref"
                                                        />
                                                    </td>
                                                    <td style="width: 150px; padding: 7px;">
                                                        <input class="form-control" placeholder="Formule" type="text"  v-model="c.formule"
                                                        />
                                                    </td>

                                                    <td scope="row" class="trashIconContainer">
                                                        <svg
                                                            v-show="form.couleurs.length > 1"
                                                            @click="deleteRow(k, c)"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 24 24"
                                                            width="24"
                                                            height="24"
                                                            class="ml-2 cursor-pointer"
                                                        >
                                                            <path fill="none" d="M0 0h24v24H0z" />
                                                            <path
                                                            fill="#EC4899"
                                                            d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
                                                            />
                                                        </svg>
                                                    </td>
                                                </tr>
                                                <div class="form-group ml-2 float-right">
                                                    <svg  
                                                        @click="addNewRow"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 24 24"
                                                            width="24"
                                                            height="24"
                                                            class="ml-2 cursor-pointer"
                                                            >
                                                            <path fill="none" d="M0 0h24v24H0z" />
                                                            <path
                                                            fill="green"
                                                            d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                                                            />
                                                        </svg>
                                                    </div>
                                                </fieldset>
                                            </div>
                                        </fieldset>
                                        <!-- Verso -->
                                        <fieldset class="border col-6 p-2 mt-2 ">
                                            <legend class="w-auto badge"><h5>VERSO</h5></legend>
                                            <el-form-item 
                                                class="text-center" 
                                                prop="verso" 
                                            >
                                                <el-radio-group label="top" @change="VersoCouleurCHange" style="width:100%" v-model="form.verso">
                                                    <el-radio label="OUI" ></el-radio>
                                                    <el-radio label="Non" ></el-radio>
                                                </el-radio-group>
                                            </el-form-item>
                                            <!-- Couleurs Verso -->
                                            <div v-if="form.verso == 'OUI'">
                                                <fieldset class="border p-2">
                                                <legend class="w-auto badge badge-warning"><h6> COMPOSANTS D'ENCRE EN VERSO </h6></legend>
                                                <tr class="form-group padding: 7px; col-lg-12">

                                                    <td style="width: 200px; padding: 7px;">
                                                        <input class="form-control" placeholder="Couleur" type="text" v-model="form.verso_couleur"
                                                        />
                                                    </td>
                                                    <td style="width: 200px; padding: 7px;">
                                                        <input class="form-control" placeholder="Ref" type="text" v-model="form.verso_ref"
                                                        />
                                                    </td>
                                                    <td style="width: 200px; padding: 7px;">
                                                        <input class="form-control" placeholder="Formule" type="text"  v-model="form.verso_formule"
                                                        />
                                                    </td>

                                                </tr>

                                                </fieldset>
                                            </div>
                                        </fieldset>
                                        <!-- <div class="col-12"></div> -->

                                        
                                        <!--  -->
                                    </div>
                                </fieldset>
                                    
                                <!-- Sens De Sortie -->
                                <fieldset v-if="form.impression == 'OUI'" class="border p-2">
                                        <legend class="w-auto badge badge-success"><h6> SENS DE SORTIE </h6></legend>
                                        <div class="row">
                                            <div class="form-group col">
                                                <fieldset class="border p-2">
                                                    <legend class="w-auto badge badge-warning"><h6> SENS D'IMPRESSION </h6></legend>
                                                <div class="row">
                                                        <div class="col">
                                                            <div class='text-center'>
                                                                <input type="radio" v-model="form.sens_sortie" name="sansImpression" id="sansImpressionimg1" class="d-none imgbgchk" value="externe">
                                                                <label for="sansImpressionimg1">
                                                                    <img src="/images/form1C.png" alt="Image 1">
                                                                    <div class="tick_container">
                                                                    <div class="tick"><i class="fa fa-check"></i></div>
                                                                    </div>
                                                                </label>
                                                                <p> EXTERNE </p>
                                                            </div>
                                                        </div>
                                                        <div class="col">
                                                        <div class='text-center'>
                                                                <input type="radio" v-model="form.sens_sortie" name="sansImpression" id="sansImpressionimg2" class="d-none imgbgchk" value="interne">
                                                                <label for="sansImpressionimg2">
                                                                    <img src="/images/form2C.png" alt="Image 1">
                                                                    <div class="tick_container">
                                                                    <div class="tick"><i class="fa fa-check"></i></div>
                                                                    </div>
                                                                </label>
                                                                <p> INTERNE </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </fieldset>
                                            </div>
                                            <!-- externe -->
                                            <div v-if="form.sens_sortie == 'externe'" class="form-group col">
                                                <fieldset class="border p-2">
                                                    <legend class="w-auto badge badge-warning"><h6> EXTERNE </h6></legend>
                                                        <div class="row">
                                                            <div class="col-sm-6">
                                                            <div class='text-center'>
                                                                    <input type="radio" v-model="form.type_sense_sortie" name="avecImpressionEX" id="avecImpressionEXimg1" class="d-none imgbgchk" value="e_up">
                                                                    <label for="avecImpressionEXimg1">
                                                                        <img src="/images/form1T.png" alt="Image 1">
                                                                        <div class="tick_container">
                                                                        <div class="tick"><i class="fa fa-check"></i></div>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-6">
                                                            <div class='text-center'>
                                                                    <input type="radio" name="avecImpressionEX" v-model="form.type_sense_sortie" id="avecImpressionEXimg2" class="d-none imgbgchk" value="e_down">
                                                                    <label for="avecImpressionEXimg2">
                                                                        <img src="/images/form1B.png" alt="Image 1">
                                                                        <div class="tick_container">
                                                                        <div class="tick"><i class="fa fa-check"></i></div>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-6">
                                                            <div class='text-center'>
                                                                    <input type="radio" v-model="form.type_sense_sortie" name="avecImpressionEX" id="avecImpressionEXimg3" class="d-none imgbgchk" value="e_left">
                                                                    <label for="avecImpressionEXimg3">
                                                                        <img src="/images/form1R.png" alt="Image 1">
                                                                        <div class="tick_container">
                                                                        <div class="tick"><i class="fa fa-check"></i></div>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-6">
                                                            <div class='text-center'>
                                                                    <input type="radio" v-model="form.type_sense_sortie" name="avecImpressionEX" id="avecImpressionEXimg4" class="d-none imgbgchk" value="e_right">
                                                                    <label for="avecImpressionEXimg4">
                                                                        <img src="/images/form1.png" alt="Image 1">
                                                                        <div class="tick_container">
                                                                        <div class="tick"><i class="fa fa-check"></i></div>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                </fieldset>
                                            </div>
                                            <!-- Interne -->
                                            <div v-if="form.sens_sortie == 'interne'" class="form-group col">
                                                <fieldset class="border p-2">
                                                    <legend class="w-auto badge badge-warning"><h6> INTERNE </h6></legend>
                                                        <div class="row">
                                                            <div class="col-sm-6">
                                                                <div class='text-center'>
                                                                        <input type="radio" v-model="form.type_sense_sortie" name="avecImpressionIN" id="avecImpressionINimg1" class="d-none imgbgchk" value="i_up">
                                                                        <label for="avecImpressionINimg1">
                                                                            <img src="/images/form2T.png" alt="Image 1">
                                                                            <div class="tick_container">
                                                                            <div class="tick"><i class="fa fa-check"></i></div>
                                                                            </div>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div class="col-sm-6">
                                                                <div class='text-center'>
                                                                        <input type="radio" v-model="form.type_sense_sortie" name="avecImpressionIN" id="avecImpressionINimg2" class="d-none imgbgchk" value="i_down">
                                                                        <label for="avecImpressionINimg2">
                                                                            <img src="/images/form2B.png" alt="Image 1">
                                                                            <div class="tick_container">
                                                                            <div class="tick"><i class="fa fa-check"></i></div>
                                                                            </div>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div class="col-sm-6">
                                                                <div class='text-center'>
                                                                        <input type="radio" v-model="form.type_sense_sortie" name="avecImpressionIN" id="avecImpressionINimg3" class="d-none imgbgchk" value="i_right">
                                                                        <label for="avecImpressionINimg3">
                                                                            <img src="/images/form2R.png" alt="Image 1">
                                                                            <div class="tick_container">
                                                                            <div class="tick"><i class="fa fa-check"></i></div>
                                                                            </div>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div class="col-sm-6">
                                                                <div class='text-center'>
                                                                        <input type="radio" v-model="form.type_sense_sortie" name="avecImpressionIN" id="avecImpressionINimg4" class="d-none imgbgchk" value="i_left">
                                                                        <label for="avecImpressionINimg4">
                                                                            <img src="/images/form2L.png" alt="Image 1">
                                                                            <div class="tick_container">
                                                                            <div class="tick"><i class="fa fa-check"></i></div>
                                                                            </div>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                        </div>
                                                </fieldset>
                                            </div>
                                            <!--  -->
                                        </div>
                                </fieldset>
                                
                        </fieldset>
                        <!-- ENd -->
                        <fieldset class="border p-2 mt-2 ">
                                <legend class="w-auto badge"><h5></h5></legend>
                                <div class="row">
                                    <el-form-item 
                                        class="col-lg-3 col-sm-6 col-md-6" 
                                        prop="repiquage" 
                                        label="REPIQUAGE :"
                                    >
                                        <el-radio-group label="top" style="width:100%" v-model="form.repiquage">
                                            <el-radio label="OUI" ></el-radio>
                                            <el-radio label="Non" ></el-radio>
                                        </el-radio-group>
                                    </el-form-item>

                                    <el-form-item 
                                        v-if="form.impression == 'OUI'"
                                        class="col-lg-3 col-sm-6 col-md-6" 
                                        prop="vernis" 
                                        label="VERNIS :"
                                    >
                                        <el-radio-group label="top" style="width:100%" v-model="form.vernis">
                                            <el-radio label="OUI" ></el-radio>
                                            <el-radio label="Non" ></el-radio>
                                        </el-radio-group>
                                        <el-select v-if="form.vernis == 'OUI'"  v-model="form.vernis_type" style="width: 100%" placeholder="Sélectionner le type de Vernis">
                                            <el-option label="Matte" value="Matte"></el-option>
                                            <el-option label="Brillant" value="Brillant"></el-option>
                                        </el-select>
                                    </el-form-item>

                                    <el-form-item 
                                        v-if="form.impression == 'OUI'"
                                        class="col-lg-3 col-sm-6 col-md-6" 
                                        prop="plastification" 
                                        label="PLASTIFICATION :"
                                    >
                                        <el-radio-group label="top" style="width:100%" v-model="form.plastification">
                                            <el-radio label="OUI" ></el-radio>
                                            <el-radio label="Non" ></el-radio>
                                        </el-radio-group>
                                        <el-select v-if="form.plastification == 'OUI'"  v-model="form.plastification_type" style="width: 100%" placeholder="Sélectionner le type de Plastification">
                                            <el-option label="Matte" value="Matte"></el-option>
                                            <el-option label="Brillant" value="Brillant"></el-option>
                                        </el-select>
                                    </el-form-item>

                                    <el-form-item 
                                        v-if="form.impression == 'OUI'"
                                        class="col-lg-3 col-sm-6 col-md-6" 
                                        prop="dorure" 
                                        label="DORURE :"
                                    >
                                        <el-radio-group label="top" style="width:100%" v-model="form.dorure">
                                            <el-radio label="OUI" ></el-radio>
                                            <el-radio label="Non" ></el-radio>
                                        </el-radio-group>
                                        <el-select v-if="form.dorure == 'OUI'"  v-model="form.dorure_type" style="width: 100%" placeholder="Sélectionner le type de Dorure">
                                            <el-option label="Chaud" value="Chaud"></el-option>
                                            <el-option label="Froides" value="Froides"></el-option>
                                        </el-select>
                                        <el-select v-if="form.dorure_type == 'Chaud'"  v-model="form.couleur_dorure" style="width: 100%" placeholder="Sélectionner la couleur">
                                            <el-option label="Argenté" value="Argenté"></el-option>
                                            <el-option label="Doré" value="Doré"></el-option>
                                            <el-option label="autre" value="autre"></el-option>
                                        </el-select>
                                        <el-select v-if="form.dorure_type == 'Froides'"  v-model="form.couleur_dorure" style="width: 100%" placeholder="Sélectionner la couleur">
                                            <el-option label="Argenté" value="Argenté"></el-option>
                                            <el-option label="Doré" value="Doré"></el-option>
                                            <el-option label="autre" value="autre"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                        </fieldset>

                        <fieldset class="border p-2 mt-2">
                                <legend class="w-auto badge"><h5>AVEC CONCEPTION</h5></legend>
                                <el-form-item 
                                    class="text-center"
                                    prop="conception"
                                >
                                    <el-radio-group v-model="form.conception">
                                        <el-radio label="OUI" ></el-radio>
                                        <el-radio label="Non" ></el-radio>
                                    </el-radio-group>
                                </el-form-item>
                        </fieldset>

                        <fieldset class="border p-2 mt-2 ">
                                <legend class="w-auto badge"><h5></h5></legend>
                                <div class="row">
                                    <el-form-item 
                                        class="col-lg-3" 
                                        prop="nbr_etq_bobine" 
                                        label="NBR ETQ / BOBINE :"
                                    >
                                    
                                    <el-input type="number" v-model="form.nbr_etq_bobine" autocomplete="off"></el-input>

                                    </el-form-item>
                                    <el-form-item 
                                        class="col-lg-3" 
                                        prop="nbr_etq_front" 
                                        label="NBR ETQ / DE FRONT :"
                                    >
                                    
                                    <el-input type="number" v-model="form.nbr_etq_front" autocomplete="off"></el-input>

                                    </el-form-item>
                                    <el-form-item 
                                        class="col-lg-3" 
                                        prop="mandrin" 
                                        label="MANDRIN :"
                                    >
                                        <el-radio-group label="top" v-model="form.mandrin" @change="MandrinChange" style="width:100%" >
                                            <el-radio label="40"></el-radio>
                                            <el-radio label="76"></el-radio>
                                            <el-radio label="Autre"></el-radio>
                                        </el-radio-group>
                                        
                                        <el-input v-if="form.mandrin == 'Autre'" type="number" placeholder="Saisie Mandrin ici" v-model="form.mandrin_autre" autocomplete="off"></el-input>
                                    </el-form-item>
                                    <el-form-item 
                                        class="col-lg-3" 
                                        prop="poses_etq" 
                                        label="POSES D'ETIQUETTE :"
                                    >
                                        <el-radio-group label="top" style="width:100%" v-model="form.poses_etq">
                                            <el-radio label="Manuelle"></el-radio>
                                            <el-radio label="Auto"></el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                </div>
                        </fieldset>

                        <fieldset class="border p-2 mt-2">
                                <legend class="w-auto badge"><h5>EXISTANCE FILMS , CLICHES ET FORM DE DECOUPE</h5></legend>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <fieldset class="border p-2">
                                            <legend class="w-auto badge badge-inf">FORM DE DECOUPE</legend>
                                            <div class="row">
                                                    <el-form-item 
                                                        class="col text-center"
                                                        prop="form_decoupe"
                                                        >
                                                        <el-radio-group v-model="form.form_decoupe">
                                                            <el-radio label="OUI" ></el-radio>
                                                            <el-radio label="Non" ></el-radio>
                                                        </el-radio-group>
                                                        
                                                        <el-input v-if="form.form_decoupe == 'OUI'" type="text" placeholder="Saisie Form Decoupe" v-model="form.form_decoupe_id" autocomplete="off"></el-input>

                                                    </el-form-item>

                                            </div>
                                        </fieldset>
                                    </div>
                                    <div class="col-lg-6" v-if="form.impression == 'OUI'">
                                        <fieldset class="border p-2">
                                            <legend class="w-auto badge badge-inf">FILMS ET CLICHES</legend>
                                            <div class="row">
                                                    <el-form-item 
                                                        class="col text-center"
                                                        prop="films_cliches"
                                                        >
                                                        <el-radio-group v-model="form.films_cliches">
                                                            <el-radio label="OUI" ></el-radio>
                                                            <el-radio label="Non" ></el-radio>
                                                        </el-radio-group>
                                                        
                                                        <el-input v-if="form.films_cliches == 'OUI'" type="text" placeholder="Saisie Cliché" v-model="form.films_cliches_id" autocomplete="off"></el-input>

                                                    </el-form-item>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                        </fieldset>

                    </div>
                    <fieldset class="border p-2 mt-2" v-if="disableDevisPV == true">
                            <legend class="w-auto badge"><h5>Validation De Devis</h5></legend>
                            <div class="row d-flex justify-content-center">
                                <el-form-item 
                                    v-if="$gate.isCMRS()"
                                    class="text-center col-4"
                                    prop="validation1"
                                    label="Commercial :"
                                >
                                    <el-switch
                                        v-model="form.validation1"
                                        active-value="1"
                                        inactive-value="0"
                                        active-color="#13ce66"
                                        inactive-color="#ff4949"
                                        inactive-text="Invalide"
                                        active-text="Valide"
                                    >
                                    </el-switch>
                                </el-form-item>
                                <el-form-item 
                                    v-if="$gate.isAdmin()"
                                    class="text-center col-4"
                                    prop="validation3"
                                    label="Administration :"
                                >
                                    <el-switch
                                        v-model="form.validation3"
                                        active-value="1"
                                        inactive-value="0"
                                        active-color="#13ce66"
                                        inactive-color="#ff4949"
                                        inactive-text="Invalide"
                                        active-text="Valide"
                                    >
                                    </el-switch>
                                </el-form-item>
                                
                            </div>
                    </fieldset>
                    
                </el-form>
            <!-- ENd body Dialog -->
            <span slot="footer" class="dialog-footer">
                <el-button @click="DialogVisible = false">Cancel</el-button>
                <el-button type="primary" v-show="!editmode" @click="createData()">Ajouter</el-button>
                <el-button type="primary" v-show="editmode" @click="updateData()">Valider</el-button>
            </span>
        </el-dialog>
        <!-- End dialog -->
        <!-- dialog Preparation -->
        <el-dialog
            title="Préparation Devis"
            :visible.sync="DialogVisibleP"
            width="90%"
            :before-close="handleClose">
            <!-- Body dialog -->
                    <fieldset class="border p-2 mt-2">
                            <legend class="w-auto badge"><h5>INFORMATIONS DE DEVIS</h5></legend>
                             <el-descriptions  border>
                                <el-descriptions-item><template slot="label"><strong>Nom D'étiquette :</strong></template> {{form.nom_etc}}</el-descriptions-item>
                                <!-- <el-descriptions-item ><template slot="label"><strong>Client :</strong></template> {{form.id_client}}</el-descriptions-item> -->
                                <el-descriptions-item><template slot="label"><strong>Format en mm :</strong></template> {{form.formatMM_L}}x{{form.formatMM_D}} </el-descriptions-item>
                                <el-descriptions-item><template slot="label"><strong>Form d'étiquette :</strong></template> {{form.forme_etq}} </el-descriptions-item>
                                <el-descriptions-item><template slot="label"><strong>Support :</strong></template> {{form.support_papier}} </el-descriptions-item>
                                
                                <el-descriptions-item><template slot="label"><strong>Impression :</strong></template> {{form.impression}}</el-descriptions-item>
                                <el-descriptions-item><template slot="label"><strong>Nombre Couleurs :</strong></template>{{form.nbr_clouleur}} </el-descriptions-item>
                                <el-descriptions-item><template slot="label"><strong>Quantité :</strong></template> {{form.quantite_etq}} </el-descriptions-item>
                                <el-descriptions-item><template slot="label"><strong>Recto :</strong></template> {{form.recto}} </el-descriptions-item>
                                <el-descriptions-item><template slot="label"><strong>Verso :</strong></template> {{form.verso}} </el-descriptions-item>

                                <el-descriptions-item><template slot="label"><strong>Repiquage :</strong></template> {{form.repiquage}} </el-descriptions-item>
                                <el-descriptions-item><template slot="label"><strong>Vernis :</strong></template> {{form.vernis}} </el-descriptions-item>
                                <el-descriptions-item><template slot="label"><strong>Plastification :</strong></template> {{form.plastification}} </el-descriptions-item>
                                <el-descriptions-item><template slot="label"><strong>Dorure :</strong></template> {{form.dorure}} </el-descriptions-item>
                                <el-descriptions-item><template slot="label"><strong>Mandrin :</strong></template> {{form.mandrin}} </el-descriptions-item>

                                <el-descriptions-item><template slot="label"><strong>Nombre Etq/Front :</strong></template> {{form.nbr_etq_front}} </el-descriptions-item>
                                <el-descriptions-item><template slot="label"><strong>Nombre Etq/Bobine :</strong></template> {{form.nbr_etq_bobine}} </el-descriptions-item>
                                <el-descriptions-item><template slot="label"><strong>Poses Etiquette :</strong></template> {{form.poses_etq}} </el-descriptions-item>
                                <el-descriptions-item><template slot="label"><strong>Form De Decoupe :</strong></template> {{form.form_decoupe}} </el-descriptions-item>
                                <el-descriptions-item><template slot="label"><strong>Films et Cliches :</strong></template> {{form.films_cliches}} </el-descriptions-item>
                            </el-descriptions>
                    </fieldset>
                    <el-form :model="form" >
                        <fieldset class="border p-2 mt-2">
                            <legend class="w-auto badge"><h5>PREPARATION</h5></legend>
                            <div class="row">
                                <el-form-item 
                                    class="col-lg-3" 
                                    prop="Machine" 
                                    label="MACHINE"
                                >
                                    <el-select v-model="form.Machine" @change="changeSelectMachine" style="width: 100%" placeholder="Merci de Choisir une machine">
                                        <el-option label="GIDUE-370" value="GIDUE-370"></el-option>
                                        <el-option label="NILPITER-B200" value="NILPITER-B200"></el-option>
                                        <el-option label="ROTOFLEX-400" value="ROTOFLEX-400"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item 
                                    class="col-lg-3" 
                                    prop="Z_cylindre" 
                                    label="Z CYLINDRE"
                                >
                                    <el-select v-model="form.Z_cylindre" style="width: 100%" @change="calculeAnamorph()" placeholder="Merci de Choisir Z Cylindre">
                                        <el-option
                                            v-for="item in Zcylindre"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item 
                                    class="col-lg-3" 
                                    prop="nombre_PosesD" 
                                    label="NBR POSES DEVELLOPE"
                                >
                                    <el-input-number v-model="form.nombre_PosesD" @change="calculePosesT" :min="0" :max="40"></el-input-number>
                                </el-form-item>
                                <el-form-item 
                                    class="col-lg-3" 
                                    prop="Nombre_PosesL" 
                                    label="NBR POSES LAIZE"
                                >
                                    <el-input-number v-model="form.Nombre_PosesL" @change="calculePosesT" :min="0" :max="40"></el-input-number>
                                </el-form-item>
                                <el-form-item 
                                    class="col-lg-3" 
                                    prop="anamorphose" 
                                    label="ANAMORPHOSE"
                                >
                                    <el-input v-model="form.anamorphose" :disabled="true" type="number"></el-input>
                                </el-form-item>
                                <el-form-item 
                                    class="col-lg-3" 
                                    prop="formatMM_L" 
                                    label="FORMAT LAIZE (L)"
                                >
                                    <el-input v-model="form.formatMM_L" :disabled="true" type="number"></el-input>
                                </el-form-item>
                                <el-form-item 
                                    class="col-lg-3" 
                                    prop="formatMM_D" 
                                    label="FORMAT DEVLOPPE (D)"
                                >
                                    <el-input v-model="form.formatMM_D" :disabled="true" type="number"></el-input>
                                </el-form-item>
                                <el-form-item 
                                    class="col-lg-3" 
                                    prop="developer_Calculer" 
                                    label="DEVELOPE CALCULER"
                                >
                                    <el-input v-model="form.developer_Calculer" :disabled="true" type="number"></el-input>
                                </el-form-item>
                                <el-form-item 
                                    class="col-lg-3" 
                                    prop="espace_D" 
                                    label="ESPACE DEVELOPPE"
                                >
                                    <el-input v-model="form.espace_D" :disabled="true" type="number"></el-input>
                                </el-form-item>
                                <el-form-item 
                                    class="col-lg-3" 
                                    prop="espace_L" 
                                    label="ESPACE LAIZE"
                                >
                                    <el-input v-model="form.espace_L" :disabled="true" type="number"></el-input>
                                </el-form-item>
                                <el-form-item 
                                    class="col-lg-3" 
                                    prop="nombre_poseT" 
                                    label="NOMBRE POSE TOTAL"
                                >
                                    <el-input v-model="form.nombre_poseT" :disabled="true" type="number"></el-input>
                                </el-form-item>
                                <el-form-item 
                                    class="col-lg-3" 
                                    prop="laize_tehorique" 
                                    label="LAIZE THEORIQUE"
                                >
                                    <el-input v-model="form.laize_tehorique" :disabled="true" type="number"></el-input>
                                </el-form-item>
                                <el-form-item 
                                    class="col-lg-3" 
                                    prop="laize_Stock" 
                                    label="LAIZE EN STOCK"
                                >
                                    <el-input v-model="form.laize_Stock" type="number"></el-input>
                                </el-form-item>
                            </div>
                        </fieldset>
                        <!-- Papier Consommé -->
                        <fieldset class="border p-2 mt-2">
                            <legend class="w-auto badge"><h5>QUANTITÉ DE PAPIER CONSOMMÉ</h5></legend>
                            <div class="row">
                                <el-form-item 
                                    class="col-lg-3" 
                                    prop="qtit_PapierML" 
                                    label="QUANTITE PAPIER (ML)"
                                >
                                    <el-input v-model="form.qtit_PapierML" :disabled="true" type="text"></el-input>
                                </el-form-item>
                                <el-form-item 
                                    class="col-lg-3" 
                                    prop="qtit_PapierM2" 
                                    label="QUANTITE PAPIER (M²)"
                                >
                                    <el-input v-model="form.qtit_PapierM2" :disabled="true" type="text"></el-input>
                                </el-form-item>
                                <el-form-item 
                                    class="col-lg-3" 
                                    prop="nbr_bobine_consomme" 
                                    label="NOMBRE BOBINE CONSOMMÉ"
                                >
                                    <el-input v-model="form.nbr_bobine_consomme" :disabled="true" type="text"></el-input>
                                </el-form-item>
                                <el-form-item 
                                    class="col-lg-3" 
                                >
                                    <br>
                                    <el-button type="primary" class="mt-2" size="small" icon="el-icon-s-operation" @click="calculeConsomationPP()" >Calculer Consomation Papier</el-button>
                                </el-form-item>
                                <div class="col-12">
                                    <strong> Calculer Consomation Papier = </strong><strong> Quantité : </strong> {{form.quantite_etq}} / 1000 / <strong> Nombre de Pose total : </strong> {{form.nombre_poseT}} x
                                    <strong> Anamorphose : </strong> {{form.anamorphose}} + {{form.vr}}
                                </div>
                                <div class="col-12">
                                    <strong> Convert en M² = </strong><strong> Quantité Papier en ml : </strong> {{form.qtit_PapierML}}  x <strong> Laize En Stock : </strong> {{form.laize_Stock}} / 1000
                                </div>
                            </div>
                        </fieldset>
                        <!-- Cout de Papier Consommé -->
                        <fieldset class="border p-2 mt-2">
                            <legend class="w-auto badge"><h5>COUT DE PAPIER CONSOMMÉ</h5></legend>
                            <div class="row">
                                <el-form-item 
                                    class="col-lg-3" 
                                    prop="prixEURO" 
                                    label="PRIX PAPIER EURO"
                                >
                                    <el-input v-model="form.prixEURO" type="text"></el-input>
                                </el-form-item>
                                <el-form-item 
                                    class="col-lg-3" 
                                    prop="coursChange" 
                                    label="Cours De Change"
                                >
                                    <el-input v-model="form.coursChange" type="text"></el-input>
                                </el-form-item>
                                <el-form-item 
                                    class="col-lg-3" 
                                    prop="coutransport" 
                                    label="Transport"
                                >
                                    <el-input v-model="form.coutransport" type="text"></el-input>
                                </el-form-item>
                                
                                <el-form-item 
                                    class="col-lg-3" 
                                    prop="nbr_bobine_consomme" 
                                    label="COUT DE PAPIER"
                                >
                                    <el-input v-model="form.cout_Papier" :disabled="true" type="text"></el-input>
                                </el-form-item>
                                <el-form-item 
                                    class="col-lg-3" 
                                >
                                    <br>
                                    <el-button type="primary" class="mt-2" size="small" icon="el-icon-s-operation" @click="calculeCoutPP()" >Calculer le cout de Papier</el-button>
                                </el-form-item>
                                <div class="col-12">
                                    <strong> Calculer Cout Papier Consommé = </strong><strong> Quantité M² x ((prix EURO x cout de change) + cout de transport) = </strong> {{form.qtit_PapierM2}} x (({{form.prixEURO}} x form.coursChange) +  form.coutransport) 
                                </div>
                            </div>
                        </fieldset>
                        <!-- Tirage -->
                        <fieldset class="border p-2 mt-2">
                            <legend class="w-auto badge"><h5>COUT DE TIRAGE ET IMPRESSION</h5></legend>
                            <div class="row">
                                <el-form-item 
                                    class="col-lg-3" 
                                    prop="vrTirage" 
                                    label="TIRAGE (Support papier)"
                                >
                                    <el-input-number v-model="form.vrTirage" :min="250" :max="2000"></el-input-number>

                                </el-form-item>
                                
                                <el-form-item 
                                    class="col-lg-3" 
                                    prop="tirage" 
                                    label="TIRAGE"
                                >
                                    <el-input v-model="form.tirage" :disabled="true" type="text"></el-input>
                                </el-form-item>
                                <el-form-item 
                                    class="col-lg-12" 
                                >
                                    <br>
                                    <strong> Calculer Tirage = </strong><strong> Quantité Papier en ml : </strong> {{form.qtit_PapierML}}  / 2000 x {{form.vrTirage}} Support Papier : {{form.support_papier}}
                                </el-form-item>
                                <el-form-item 
                                    class="col-lg-3" 
                                >
                                    <br>
                                    <el-button type="primary" class="mt-2" size="small" icon="el-icon-s-operation" @click="CalculeTirage()" >Calculer Tirage</el-button>
                                </el-form-item>
                                
                            </div>
                        </fieldset>
                        <!-- Mandrin -->
                        <fieldset class="border p-2 mt-2">
                            <legend class="w-auto badge"><h5>FRAIS DE MANDRIN</h5></legend>
                            <div class="row">
                                <el-form-item 
                                    class="col-lg-2" 
                                    prop="mandrin" 
                                    label="MANDRIN"
                                >
                                    <el-input v-model="form.mandrin" :disabled="true" type="text"></el-input>
                                    <el-input v-if="form.mandrin =='Autre'" v-model="form.mandrin_autre" :disabled="true" type="text"></el-input>
                                </el-form-item>
                                <el-form-item 
                                    class="col-lg-3" 
                                    prop="nbr_mandrin_consomme" 
                                    label="NOMBRE DE MANDRIN CONSOMÉ"
                                >
                                    <el-input v-model="form.nbr_mandrin_consomme" :disabled="true" type="text"></el-input>
                                </el-form-item>
                                <el-form-item 
                                    class="col-lg-3" 
                                    prop="format_mandrin" 
                                    label="FORMAT DE MANDRIN"
                                >
                                    <el-input v-model="form.format_mandrin" :disabled="true" type="text"></el-input>
                                </el-form-item>
                                <el-form-item 
                                    class="col-lg-3" 
                                    prop="cout_revien_mandrin" 
                                    label="COUT DE REVIENT MANDRIN"
                                >
                                    <el-input v-model="form.cout_revien_mandrin" type="text"></el-input>
                                </el-form-item>
                                <el-form-item 
                                    class="col-lg-3" 
                                >
                                    <el-button type="primary" size="small" class="mt-2" icon="el-icon-s-operation" @click="CalculeMandrin()" >Calcules de Mandrin</el-button>
                                </el-form-item>
                                <el-form-item class="col-lg-6" >
                                    <br>
                                    <strong> Nombre de Mandrin Consommé = </strong><strong> Quantité / Nombre Etq/Bobine : </strong> {{form.quantite_etq}}  / {{form.nbr_etq_bobine}}
                                    <br>
                                    <strong> Format de Mandrin = </strong><strong> Format en mm (L)  x Nombre Etq/Front : </strong> {{form.formatMM_L}}  x {{form.nbr_etq_front}}
                                    <br>
                                    <strong> Cout de revient de Mandrin = </strong><strong> Nombre de Mandrin Consommé x 0.5 : </strong> {{form.nbr_mandrin_consomme}} x 0.2       
                                </el-form-item>
                            </div>
                        </fieldset>
                        <!-- cliché form decoup -->
                        <fieldset class="border p-2 mt-2">
                            <legend class="w-auto badge"><h5>FRAIS DE CLICHÉS</h5></legend>
                            <div class="row">
                                <el-form-item 
                                    class="col-lg-3" 
                                    prop="frais_recto" 
                                    label="RECTO"
                                >
                                    <el-input v-model="form.frais_recto"  type="text"></el-input>
                                </el-form-item>
                                <el-form-item 
                                    class="col-lg-3" 
                                    prop="frais_verso" 
                                    label="VERSO"
                                >
                                    <el-input v-model="form.frais_verso"  type="text"></el-input>
                                </el-form-item>
                                <el-form-item 
                                    class="col-lg-3" 
                                    prop="FTotal_Cliches" 
                                    label="TOTAL"
                                >
                                    <el-input v-model="form.FTotal_Cliches"  type="text"></el-input>
                                </el-form-item>
                                <el-form-item 
                                    class="col-lg-3" 
                                >
                                    <br>
                                    <el-button type="primary" size="small" class="mt-2" icon="el-icon-s-operation" @click="CalculeFraisCliches()" >Calculer Frais Clichés</el-button>
                                </el-form-item>
                                <el-form-item 
                                    class="col-lg-12" 
                                >
                                    <strong> Calculer Frais des Clichés Recto = </strong>(<strong> Anamorphose : </strong> {{form.anamorphose}}  x <strong> Laize En Stock : </strong>{{form.laize_Stock}} / 100 ) x <strong> Nobre de Couleurs :  </strong> {{form.nbr_clouleur}} x 0.42 x 1.2
                                </el-form-item>
                                <el-form-item 
                                    class="col-lg-12" 
                                >
                                    <strong> Calculer Frais des Clichés Verso = </strong>(<strong> Anamorphose : </strong> {{form.anamorphose}}  x <strong> Laize En Stock : </strong>{{form.laize_Stock}} / 100 ) x 1
                                </el-form-item>
                            </div>
                        </fieldset>
                        <fieldset class="border p-2 mt-2">
                            <legend class="w-auto badge"><h5>FRAIS DE FORME DE DECOUPE</h5></legend>
                            <div class="row">
                                <el-form-item 
                                    class="col-lg-4" 
                                    prop="frais_formDecoup" 
                                    label="SAISIE LES FRAIS DE FORME DE DECOUPE"
                                >
                                    <el-input v-model="form.frais_formDecoup" type="text"></el-input>
                                </el-form-item>
                                <el-form-item 
                                    class="col-lg-4" 
                                    prop="qtit_formDecoup" 
                                    label="SAISIE LES qtit DE FORME DE DECOUPE"
                                >
                                    <el-input v-model="form.qtit_formDecoup" type="text"></el-input>
                                </el-form-item>
                                <el-form-item 
                                    class="col-lg-3" 
                                >
                                    <br>
                                    <el-button type="primary" size="small" class="mt-2" icon="el-icon-s-operation" @click="CalculeFraisFormDecoup()" >Calculer Frais Forme de decoupe</el-button>
                                </el-form-item>
                            </div>
                        </fieldset>
                        <!-- cliché form decoup -->
                        <fieldset class="border p-2 mt-2">
                            <legend class="w-auto badge"><h5>COUT DE REVIENT</h5></legend>
                            <div class="row">
                                <el-form-item 
                                    class="col-lg-3" 
                                    prop="total_frais_formDecoup" 
                                    label="LES FRAIS DE FORME DE DECOUPE"
                                >
                                    <el-input v-model="form.total_frais_formDecoup"  type="text"></el-input>
                                </el-form-item>
                                <br/>
                                <el-form-item 
                                    class="col-lg-3" 
                                    prop="FTotal_Cliches" 
                                    label="LES FRAIS DE CLICHÉ"
                                >
                                    <el-input v-model="form.FTotal_Cliches"  type="text"></el-input>
                                </el-form-item>
                                <el-form-item class="col-12"></el-form-item>
                                <el-form-item 
                                    class="col-lg-3" 
                                    prop="cout_hors_FDC" 
                                    label="COUT DE REVIENT"
                                >
                                    <el-input v-model="form.cout_hors_FDC" :disabled="true" type="number"></el-input>
                                </el-form-item>
                                <el-form-item 
                                    class="col-lg-2" 
                                    prop="marge_benif" 
                                    label="MARGE BENIFICIARE"
                                >
                                    <el-input v-model="form.marge_benif"  type="number"></el-input>
                                </el-form-item>
                                <el-form-item 
                                    class="col-lg-3" 
                                    prop="prixHTT" 
                                    label="PRIX UNITAIRE Calculer (HT)"
                                >
                                    <el-input v-model="form.prixHTT"  type="number"></el-input>
                                </el-form-item>
                                 <!-- calculer -->
                                 <el-form-item 
                                    class="col-lg-4" 
                                >
                                    <br>
                                    <el-button type="primary" size="small" class="mt-2" icon="el-icon-s-operation" @click="CalculeMargeBenific()" >Calculer les Couts Final</el-button>
                                </el-form-item>
                                <el-form-item 
                                    class="col-lg-4" 
                                    prop="prixHTT" 
                                    label="PRIX UNITAIRE A Apliquer (HT)"
                                >
                                    <el-input v-model="form.prixHTTApliquer" @change="ChangerPrixAplique"  type="number"></el-input>
                                </el-form-item>
                                <el-form-item 
                                    class="col-lg-4" 
                                    prop="prixHTT" 
                                    label="PRIX PAR 1000"
                                >
                                    <el-input v-model="form.prixParMill"  type="number"></el-input>
                                </el-form-item>
                                <el-form-item 
                                    class="col-lg-4" 
                                    prop="prixHTT" 
                                    label="PRIX PAR BOBINE"
                                >
                                    <el-input v-model="form.prixParBobine"  type="number"></el-input>
                                </el-form-item>
                                
                                <el-form-item 
                                    class="col-lg-12" 
                                >
                                    <strong> Cout de revient =  Cout Papier + Tirage + mandrin + recto + verso + Form de decoup / Quantité </strong> 
                                </el-form-item>
                                 <!-- Hors Form decoup -->
                                 <div class="col-lg-6">COUT DE REVIENT SANS FRAIS DE FORME DE DECOUPE :</div>
                                 <el-form-item 
                                    class="col-lg-3" 
                                    prop="totalSansFD" 
                                >
                                    <el-input v-model="form.totalSansFD" type="text"></el-input>
                                </el-form-item>
                                <!-- Hors Cliché -->
                                <div class="col-lg-6">COUT DE REVIENT SANS FRAIS DE CLICHE :</div>
                                <el-form-item 
                                    class="col-lg-3"
                                    prop="totalSansC" 
                                >
                                    <el-input v-model="form.totalSansC" type="text"></el-input>
                                </el-form-item>
                                 <!-- Hors Form decoup et Cliché -->
                                 <div class="col-lg-6">COUT DE REVIENT SANS FRAIS DE FORME DE DECOUPE ET CLICHE :</div>
                                 <el-form-item 
                                    class="col-lg-3" 
                                    prop="totalSansFDC" 
                                >
                                    <el-input v-model="form.totalSansFDC" type="text"></el-input>
                                </el-form-item>
                               
                            </div>
                        </fieldset>
                        <!-- cliché form decoup -->
                        <!-- <fieldset class="border p-2 mt-2">
                            <legend class="w-auto badge"><h5>Estimation Durée</h5></legend>
                            <div class="row">
                                <el-form-item 
                                    class="col-lg-3" 
                                    prop="duree_production" 
                                    label="Estimation Durée de Production "
                                >
                                    <el-input v-model="form.duree_production" :disabled="true" type="text"> <template slot="append">H</template></el-input>
                                </el-form-item>
                                <el-form-item 
                                    class="col-lg-3" 
                                    prop="duree_conditionement" 
                                    label="Estimation Durée de Conditionement"
                                >
                                    <el-input v-model="form.duree_conditionement" :disabled="true" type="text"> <template slot="append">H</template></el-input>
                                </el-form-item>
                                <el-form-item 
                                    class="col-lg-3" 
                                    prop="duree_conditionement" 
                                    label="Estimation Durée de Conditionement"
                                >
                                    <el-date-picker v-model="form.estimation_livraison" type="date"></el-date-picker>
                                </el-form-item>
                                <el-form-item 
                                    class="col-lg-3" 
                                >
                                    <br>
                                    <el-button type="primary" size="small" class="mt-2" icon="el-icon-s-operation" @click="CalculeDuree()" >Calculer Durées Production</el-button>
                                </el-form-item>
                            </div>
                        </fieldset>  -->
                    </el-form>
                <!-- ENd body Dialog -->
                <span slot="footer" class="dialog-footer">
                    <el-button @click="DialogVisibleP = false">Cancel</el-button>
                    <!-- <el-button type="primary" v-show="!editmode" @click="createData()">Ajouter</el-button> -->
                    <el-button type="primary" v-show="editmode" @click="PreparationData()">Valider</el-button>
                </span>
        </el-dialog>
        <!-- end Dialog Preparation -->
        <!-- Invoice -->
        <div
                class="modal fade"
                id="Invoice"
                tabindex="-1"
                role="dialog"
                aria-labelledby="Invoice"
                aria-hidden="true"
            >
                <div class="modal-dialog modal-xl" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title text-right">Devis</h5>
                            <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <!-- invoice -->
                        <div class="modal-body" id="printMe">
                            <div class="row">
                                <div class="col-12">
                                    <div class="card">
                                        <div class="card-body p-0">
                                            <div class="row p-5">
                                                <div class="col-12 text-center">
                                                    <img
                                                        class="comapny_logo_invoice"
                                                        src="/images/logo.png"
                                                        alt="brand-logo-for-invoice"
                                                        style="height:90px"
                                                    />
                                                </div>
                                            </div>

                                            <hr class="my-2" />

                    <div class="page-content container" >
                        <div class="row">
                            <div class="page-header col-6 text-blue-d2">
                                <h2 class="page-title text-secondary-d1">
                                    OFFRE DE PRIX 
                                    <small class="page-info">
                                        <i class="fa fa-angle-double-right text-80"></i>
                                        N° : #{{id_offer}}-{{dateOffre | OfferDates}}
                                    </small>
                                </h2>
                            </div>
                            <div class="text-95 col-6 align-self-start d-sm-flex justify-content-end">
                                <p class="page-title text-secondary-d1">
                                    Date  :  {{dateOffre}}
                                </p>
                            </div>

                        </div>
    
                        <div class="container px-0">
                            <div class="row mt-4">
                                <div class="col-12 col-lg-12">
                                
                                    <hr />

                                    <div class="row">
                                        <div class="col-sm-6">
                                            <div>
                                                <strong class="text-sm text-grey-m2 align-middle">CLIENT :</strong>
                                                <span class="text-600 text-110 text-blue align-middle">{{nameClient}}</span>
                                            </div>
                                            <div class="text-grey-m2">
                                                <div class="my-1">
                                                    <strong>ADRESSE :</strong> {{AdressClient}}
                                                </div>
                                                <div class="my-1">
                                                    <strong>TEL : </strong> {{teleClient}} 
                                                </div>
                                                <!-- <div class="my-1"><i class="fa fa-phone fa-flip-horizontal text-secondary"></i> <b class="text-600">111-111-111</b></div> -->
                                            </div>
                                        </div>
                                        <!-- /.col -->

                                        <div class="text-95 col-sm-6 align-self-start d-sm-flex justify-content-end">
                                            <hr class="d-sm-none" />
                                            <div class="text-grey-m2">

                                                <!-- <div class="my-2"><i class="fa fa-circle text-blue-m2 text-xs mr-1"></i> <span class="text-600 text-90">Consultation N° :</span> #111-222</div> -->

                                                <div class="my-2"><i class="fa fa-circle text-blue-m2 text-xs mr-1"></i> <strong class="text-600 text-90">Affaire Suivi Par:</strong> {{userName}}</div>

                                            </div>
                                        </div>
                                        <!-- /.col -->
                                    </div>
                                    <hr />
                                    <div class="mt-4">
                                        <div class="row border-b-2 brc-default-l2"></div>

                                        <!-- or use a table instead -->
                                        <table class="table table-bordered">
                                                <thead>
                                                    <tr>
                                                    <th scope="col">Réferance</th>
                                                    <th scope="col">Quantité</th>
                                                    <th scope="col">Désignation</th>
                                                    <th scope="col">Unité</th>
                                                    <th scope="col">TAUX T.V.A</th>
                                                    <th scope="col">P.U.(H.T)</th>
                                                    <th scope="col">TOTAL.(H.T)</th>
                                                    <th scope="col">TOTAL T.V.A</th>
                                                    </tr>
                                                </thead>
                                                <tbody v-for="(c, k) in Offer_devis" :key="k">
                                                    <tr v-if="type_ISE == 'inclus'">
                                                        <th scope="row">{{c.id}}</th>
                                                        <td>{{c.quantite_etq}}</td>
                                                        <td>{{c.nom_etc}} <br> Soit {{c.quantite_etq / c.nbr_etq_bobine }} BOBINES</td>
                                                        <td>Unité</td>
                                                        <td>{{tvaCentage}}%</td>
                                                        <td>{{c.prixFinal | FormNumbers}}</td>
                                                        <td>{{c.prixFinal * c.quantite_etq | FormNumbers}}</td>
                                                        <td>{{((c.prixFinal * c.quantite_etq)*tvaCentage2) | FormNumbers}}</td>
                                                    </tr>
                                                    <tr v-if="type_ISE == 'exclus' || type_ISE == 'separee'">
                                                        <th scope="row">{{c.id}}</th>
                                                        <td>{{c.quantite_etq}}</td>
                                                        <td>{{c.nom_etc}}</td>
                                                        <td>Unité</td>
                                                        <td>{{tvaCentage}} %</td>
                                                        <td>{{c.totalSansFDC | FormNumbers}}</td>
                                                        <td>{{c.totalSansFDC * c.quantite_etq | FormNumbers}}</td>
                                                        <td>{{((c.totalSansFDC * c.quantite_etq)*tvaCentage2) | FormNumbers}}</td>
                                                    </tr>
                                                    <tr v-if="type_ISE == 'separee' && c.impression == 'OUI'" >
                                                        <th>--</th>
                                                        <td>{{c.qtit_formDecoup}}</td>
                                                        <td>Forme de decoupe</td>
                                                        <td>Unité</td>
                                                        <td>20 %</td>
                                                        <td>{{c.frais_formDecoup | FormNumbers}}</td>
                                                        <td>{{c.total_frais_formDecoup | FormNumbers}}</td>
                                                        <td>{{c.total_frais_formDecoup*0.2 | FormNumbers}}</td>
                                                    </tr>
                                                    <tr v-if="type_ISE == 'separee' && c.impression == 'OUI'">
                                                        <th>--</th>
                                                        <td>{{c.nbr_clouleur}}</td>
                                                        <td>Cliché</td>
                                                        <td>Unité</td>
                                                        <td>20 %</td>
                                                        <td>{{(c.FTotal_Cliches / c.nbr_clouleur) | FormNumbers}}</td>
                                                        <td>{{c.FTotal_Cliches | FormNumbers}}</td>
                                                        <td>{{c.FTotal_Cliches*0.2 | FormNumbers}}</td>
                                                    </tr>
                                                </tbody>
                                        </table>

                                        <div class="row mt-3">
                                            <div class="col-12 col-sm-7 text-grey-d2 text-95 mt-2 mt-lg-0">
                                                ARRETER LE PRESENT OFFRE DE PRIX A LA SOMME DE : <br/>
                                                <strong>{{this.GlobalTotalTTC | NumberToLetterM}} DIRHAMS</strong>
                                            </div>

                                            <div class="col-12 col-sm-5 text-grey text-90 order-first order-sm-last">
                                                <div class="row my-2">
                                                    <div class="col-7 text-right">
                                                        MONTANT (H.T)
                                                    </div>
                                                    <div class="col-5">
                                                        <span class="text-120 text-secondary-d1">{{GlobalTotalHT | FormNumbers}}</span>
                                                    </div>
                                                </div>

                                                <div class="row my-2">
                                                    <div class="col-7 text-right">
                                                        T.V.A ({{tvaCentage}}%)
                                                    </div>
                                                    <div class="col-5">
                                                        <span class="text-110 text-secondary-d1">{{tva | FormNumbers}}</span>
                                                    </div>
                                                </div>

                                                <div class="row my-2 align-items-center bgc-primary-l3 p-2">
                                                    <div class="col-7 text-right">
                                                        TOTAL (T.T.C)
                                                    </div>
                                                    <div class="col-5">
                                                        <span class="text-150 text-success-d3 opacity-2">{{GlobalTotalTTC | FormNumbers}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <hr />

                                        <div class="mt-5 mb-5">
                                            <strong class="text-secondary-d1 text-105 mt-5 mb-5">SIGNATURES :</strong>
                                            <!-- <VueQRCodeComponent :size="200" class="float-right px-4 " text="dazdaz"></VueQRCodeComponent> -->
                                        </div>
                                        <hr />

                                        <div>
                                            <strong class="text-secondary-d1 text-105">REGIME DE FACTURATION :</strong> {{GlobalModalitePaiment}}
                                        </div>
                                        <hr />
                                        <div v-if="impression == 'OUI'">
                                            <p class="text-secondary-d1 text-105"><strong>Formes de découpes films et clichés :</strong>
                                                <span v-if="form_decoupe == 'Non'">les frais de la nouvelle forme de découpe seront facturées en (TTC) sur le client.</span>
                                                <span v-if="films_cliches == 'Non'">les frais des films et clichés seront facturées en (TTC) sur le client. </span></p>
                                        </div>
                                        <hr />
                                        <div v-if="GlobalModalitePaiment == 'AT'">
                                            <span class="text-secondary-d1 text-105"><strong>Condition d'admission temporaire (A.T) :</strong><br/>
                                                1 - Le respect de la réglementation relative aux régimes economiques de douane. <br/>
                                                2 - Taxe sur la valeur ajoutée : vente en suspentions de la taxe sur la valeur ajoutée en vertu de l'article 92 du code géneral des impots. <br/>
                                                3 - cette marchandise est strictement distinée à l'export. <br/>
                                                4 - le client exportateur d'engage a liquider la totalité de la marchandise avant 90 jours de sa date de livraison : l'enregistrement de la cession ne doit pas depasser 90 jours de la date de la premiére livraison conformément à la réglementation douaniére. <br/>
                                                le client s'engage à assumer toute la responsabilité vis-à-vis de la douane en ce qui concerne une eventuelle irrégularité et erreur dans la declaration (cession AT). à cette effet le client devra payer toute sorte de pénalité eventuelle générée par la douane.<br/>
                                                5 - le client doit assurer le suivi pour validation de la DUM de cession en mettant à la disponibilité de l'ADII les documents demandé. <br/>
                                                6 - les honoraires et tout les frais de la prestation de chaque cession douaniére sera à la charge de client.<br/>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                                    <hr class="my-2 mt-5" />
                                    <!-- footer -->
                                    <div class=" flex-row-reverse text-center p-4">
                                        <div class="py-3 px-5">
                                            <div>
                                                Lot Yamna Ismail,Avenue Med. VI Route de Biougra - Ait Meloul -B.P.N° 1810 Z.I.Ait Melloul - Tél /Fax: 05 28 24 51 90
                                            </div>
                                            <div>
                                            SARL au Capital de 300 000.00 DH - R.C: 3545 Inzegan -lf : 06 95 04 67 - Patente : 49866952- CNSS : 7341845
                                            </div>
                                            <div>
                                            N° Compte B.P .Ait Melloul : 101022212117001481001534 - Email: aakabar@gmail.com
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                        </div>
                <div class="modal-footer">
                    <button
                        class="btn btn-primary"
                        style="float: right;"
                        @click="printC()"
                    >
                        <span class="fa fa-print"></span>
                    </button>
                </div>
            </div>
            <!-- end invoice  -->
        </div>
</div>
        <!-- End Invoice -->
    </div>
    
  </section>
  
</template>

<style>
    .my-label{
        background: #ec4d4d;
    }
    .el-table .info-row {
        background: #A0CFFF
    }

    .el-table .warning-row {
        background: oldlace
    }

    .el-table .success-row {
        background: #f0f9eb;
    }
</style>

<script>
    import VueQRCodeComponent from 'vue-qrcode-component'
    import VueTagsInput from '@johmun/vue-tags-input';
    import moment from 'moment'
    import numeral from 'numeral'
    import { NumberToLetter } from 'convertir-nombre-lettre';
    import {Machine1 , Machine2 ,Machine3, Mandrin, Etiquettes , Clients} from './Zcylindre';

    export default {
      components: {
        VueQRCodeComponent,
          VueTagsInput,
        },
        data () {
            return {
                numberValidateForm: new Form({
                    age: '',
                    existClient:''
                }),
                active: 0,
                active1: 0,
                disableDevisPV: false,
                DialogVisible: false,
                DialogVisiblePrices: false,
                DialogVisible_global: false,
                DialogVisible_RelancerCMD: false,
                DialogVisibleP: false,
                devisETQ: [],
                ExisteCMDClient: [],
                devisGlobal: [],
                devisGlobalR: [],
                devisETQPrepared: [],
                clients: [],
                dateOffre : new Date().toISOString().slice(0,10),
                id_offer: '',
                validationBTN: '',
                // Print Offer de prix
                // Offer_Client: [],
                // start offer devis
                Offer_devis: [],
                nameClient : '',
                teleClient : '',
                emailClient : '',
                AdressClient : '',
                type_ISE:'',
                userName : '',
                GlobalModalitePaiment : '',
                GlobalTotalHT : '',
                tva : '',
                tvaCentage : '',
                tvaCentage2 : '',
                GlobalTotalTTC : '',
                form_decoupe : '',
                films_cliches : '',
                
                // End Print Offer de prix
                // prixEURO : '',
                // rules validation formulair
                // rules: {
                //     typeEtiquette: [
                //         { required: true, message: 'Please etiquette type', trigger: 'blur' },
                //     ],
                // },
                ArticlesForm : new Form({
                        typeEtiquette: '',
                        nom_etc:'',
                        conception:'',
                        formatMM_L:'',
                        formatMM_D : '',
                        forme_etq:'',
                        support_papier : '', 
                        quantite_etq:'',
                        impression:'',
                        nbr_clouleur:'',
                        couleurs: [{  couleur: "",ref:"",formule:"" }],
                        recto:'Non',
                        verso:'Non',
                        verso_couleur:'',
                        verso_ref:'',
                        verso_formule:'',
                        sens_sortie:'',
                        type_sense_sortie:'',
                        repiquage:'',
                        vernis:'',
                        vernis_type:'',
                        plastification:'',
                        plastification_type:'',
                        dorure:'',
                        dorure_type:'',
                        couleur_dorure:'',
                        nbr_etq_bobine : '',
                        nbr_etq_front: '',
                        mandrin: '',
                        mandrin_autre:'',
                        poses_etq: '',
                        form_decoupe: '',
                        form_decoupe_id: '',
                        films_cliches: '',
                        films_cliches_id: '',
                }),
                    
                // },
                // end rules
                form_global: new Form({
                    id:'',
                    
                    id_client:'',
                    existClient:'',
                    dateDevis : new Date().toISOString().slice(0,10),
                    type_ISE:'',
                    Etat_devis:'',
                    client: [{  name: "",tele:"",email:"",RLancementCMDfix:"",RLancementCMDtele:"",RLancementCMDemail:"",RLancementCMDName:""}],
                    // NumberToLetterValue:19987,
                    
                    ArticlesCommander : [{
                        typeEtiquette: '',
                        nom_etc:'',
                        conception:'',
                        formatMM_L:'',
                        formatMM_D : '',
                        forme_etq:'',
                        support_papier : '', 
                        quantite_etq:'',
                        impression:'',
                        nbr_clouleur:'',
                        couleurs: [{  couleur: "",ref:"",formule:"" }],
                        recto:'Non',
                        verso:'Non',
                        verso_couleur:'',
                        verso_ref:'',
                        verso_formule:'',
                        sens_sortie:'',
                        type_sense_sortie:'',
                        repiquage:'',
                        vernis:'',
                        vernis_type:'',
                        plastification:'',
                        plastification_type:'',
                        dorure:'',
                        dorure_type:'',
                        couleur_dorure:'',
                        nbr_etq_bobine : '',
                        nbr_etq_front: '',
                        mandrin: '',
                        mandrin_autre:'',
                        poses_etq: '',
                        form_decoupe: '',
                        form_decoupe_id: '',
                        films_cliches: '',
                        films_cliches_id: '',
                    }],
                    modalite_paiment: '',
                    modalite_paiment2: '',
                    // Preparation
                    Machine:'',
                    Z_cylindre : '',
                    nombre_PosesD : '',
                    Nombre_PosesL : '',
                    anamorphose : '',
                    developer_Calculer: '',
                    espace_D: '',
                    espace_L: '',
                    nombre_poseT : '',
                    laize_tehorique: '',
                    laize_Stock: '',
                    qtit_PapierML : '',
                    qtit_PapierM2 : '' ,
                    cout_Papier : '' ,
                    prixEURO : '',
                    nbr_bobine_consomme : '' ,
                    vrTirage: '',
                    vr:'',
                    tirage : '' ,
                    nbr_mandrin_consomme : '' ,
                    format_mandrin : '' ,
                    cout_revien_mandrin : '' ,
                    frais_recto : '' ,
                    frais_verso : '' ,
                    FTotal_Cliches : '' ,
                    frais_formDecoup : '' ,
                    cout_hors_FDC : '' ,
                    marge_benif : 0 ,
                    prixHTT : '' ,
                    prixHTTApliquer : '' ,
                    prixFinal : '' ,
                    prixParMill : '' ,
                    prixParBobine : '' ,
                    duree_production : '' ,
                    duree_conditionement : '' ,
                    estimation_livraison : '' ,
                    preparation:'',
                    // Validation
                    validation1: '',
                    validation2: '',
                    validation3: '',
                }),

                Relancerform: new Form({
                    id: '',
                    id_client:'',
                    dateDevis : new Date().toISOString().slice(0,10),
                    type_ISE:'',
                    Etat_devis:'',
                    modalite_paiment: 'TVA',
                    ArticlesCommander : [{
                        typeEtiquette: '',
                        nom_etc:'',
                        conception:'',
                        formatMM_L:'',
                        formatMM_D : '',
                        forme_etq:'',
                        support_papier : '', 
                        quantite_etq:'',
                        impression:'',
                        nbr_clouleur:'',
                        couleurs: [{  couleur: "",ref:"",formule:"" }],
                        recto:'',
                        verso:'',
                        verso_couleur:'',
                        verso_ref:'',
                        verso_formule:'',
                        sens_sortie:'',
                        type_sense_sortie:'',
                        repiquage:'',
                        vernis:'',
                        vernis_type:'',
                        plastification:'',
                        plastification_type:'',
                        dorure:'',
                        dorure_type:'',
                        couleur_dorure:'',
                        nbr_etq_bobine : '',
                        nbr_etq_front: '',
                        mandrin: '',
                        mandrin_autre:'',
                        poses_etq: '',
                        form_decoupe: '',
                        form_decoupe_id: '',
                        films_cliches: '',
                        films_cliches_id: '',
                    }],
                }),

                multipleSelection: [{  id_etc: "",qtit_lancer:0,changesExist:""}],

                form_Prices: new Form({
                    id_global:'',
                    devisPrices : [],
                }),
                
                form: new Form({
                    id:'',                    
                    id_client:'',
                    existClient:'',
                    dateDevis : new Date().toISOString().slice(0,10),
                    client: [{  name: "",tele:"",email:"" }],

                        typeEtiquette: '',
                        nom_etc:'',
                        conception:'',
                        formatMM_L:'',
                        formatMM_D : '',
                        forme_etq:'',
                        support_papier : '', 
                        quantite_etq:'',
                        impression:'',
                        nbr_clouleur:'',
                        couleurs: [{  couleur: "",ref:"",formule:"" }],
                        recto:'Non',
                        verso:'Non',
                        verso_couleur:'',
                        verso_ref:'',
                        verso_formule:'',
                        sens_sortie:'',
                        type_sense_sortie:'',
                        repiquage:'',
                        vernis:'',
                        vernis_type:'',
                        plastification:'',
                        plastification_type:'',
                        dorure:'',
                        dorure_type:'',
                        couleur_dorure:'',
                        nbr_etq_bobine : '',
                        nbr_etq_front: '',
                        mandrin: '',
                        mandrin_autre:'',
                        poses_etq: '',
                        form_decoupe: '',
                        form_decoupe_id: '',
                        films_cliches: '',
                        films_cliches_id: '',
                    
                    modalite_paiment: '',
                    // Preparation
                    Machine:'',
                    Z_cylindre : '',
                    nombre_PosesD : '',
                    Nombre_PosesL : '',
                    anamorphose : '',
                    developer_Calculer: '',
                    espace_D: '',
                    espace_L: '',
                    nombre_poseT : '',
                    laize_tehorique: '',
                    laize_Stock: '',
                    qtit_PapierML : '',
                    qtit_PapierM2 : '' ,
                    cout_Papier : '' ,
                    prixEURO : '',
                    coursChange:'',
                    coutransport:'',
                    nbr_bobine_consomme : '' ,
                    vrTirage: '',
                    vr:'',
                    tirage : '' ,
                    nbr_mandrin_consomme : '' ,
                    format_mandrin : '' ,
                    cout_revien_mandrin : '' ,
                    frais_recto : '' ,
                    frais_verso : '' ,
                    FTotal_Cliches : '' ,
                    frais_formDecoup : '' ,
                    total_frais_formDecoup : '' ,
                    qtit_formDecoup : '' ,
                    totalSansFDC : '' ,
                    totalSansFD : '' ,
                    totalSansC : '' ,
                    cout_hors_FDC : '' ,
                    marge_benif : 0 ,
                    prixHTT : '' ,
                    prixHTTApliquer : '' ,
                    prixFinal : '' ,
                    prixParMill : '' ,
                    prixParBobine : '' ,
                    duree_production : '' ,
                    duree_conditionement : '' ,
                    estimation_livraison : '' ,
                    preparation:'',
                    // Validation
                    validation1: '',
                    validation2: '',
                    validation3: '',
                }),

            //Static Data  
            Etiquettes : Etiquettes,
            // Clients : Clients,
            Machine1: Machine1,
            Machine2: Machine2,
            Machine3: Machine3,
            Mandrin_Stock: Mandrin,
            Mandrin_Stock2:[],
            Zcylindre:[],
            tableZ:[],
            // 
            search: '',
            pagesize: 10,
            currpage: 1,
            
            search1: '',
            pagesize1: 10,
            currpage1: 1,

            editmode: false,
            Relancereditmode: false,

            changesExist:0

            // autocompleteItems: [],
            }
        },
        methods: {
            // change inputes

            // Validation inputs

            submitForm1(formName) {
                this.$refs[formName].validate((valid) => {
                if (valid) {
                    alert('submit!');
                } else {
                    alert('error submit!!');
                    return false;
                }
                });
            },

            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                if (valid) {
                    alert('submit!');
                } else {
                    alert('error submit!!');
                    return false;
                }
                });
            },
            
            // End Validation inputs

            next() { this.active++; },
            prev() { this.active--; },

            next1() { this.active1++; },
            prev1() { this.multipleSelection = []; this.active1--; },

            ClientChange(page = 1){
                this.$Progress.start();
                console.log("client changes");
                console.log("id client :" + this.Relancerform.id_client);
                // existCmd                
                axios.get('api/existCmd/'+this.Relancerform.id_client+'?page=' + page).then(({ data }) => (this.ExisteCMDClient = data.data));
                this.$Progress.finish();
            },

            ImpressionCHanger(){
                if(this.form.impression == 'Non'){
                    
                    this.form.nbr_clouleur='0'
                    this.form.couleurs=[],
                    this.form.recto='Non'
                    this.form.verso='Non'
                    this.form.verso_couleur=''
                    this.form.verso_ref=''
                    this.form.verso_formule=''
                    this.form.sens_sortie=''
                    this.form.type_sense_sortie=''
                    this.form.vernis='',
                    this.form.vernis_type='',
                    this.form.plastification='',
                    this.form.plastification_type='',
                    this.form.dorure='',
                    this.form.dorure_type='',
                    this.form.couleur_dorure=''
                }
                if(this.form.impression == 'OUI'){
                     this.form.couleurs=[{  couleur: "",ref:"",formule:"" }]
                }
            },
            

            MandrinChange(){
                if(this.form.mandrin != 'Autre'){
                    
                    this.form.mandrin_autre=''
                }
            },

            // end condition of change inputs

            loadData(page = 1){
                this.$Progress.start();
                axios.get('api/devisETQ?page=' + page).then(({ data }) => (this.devisETQ = data.data.DevisETCToPrepared));
                this.$Progress.finish();
            },

            loadDevisGlobal(page = 1){
                this.$Progress.start();
                axios.get('api/devisETQ?page=' + page).then(({ data }) => (this.devisGlobal = data.data.DevisGlobalToPrepared));
                this.$Progress.finish();
            },

            loadDevisGlobalR(page = 1){
                this.$Progress.start();
                axios.get('api/devisETQ?page=' + page).then(({ data }) => (this.devisGlobalR = data.data.DevisGlobalToPreparedR));
                this.$Progress.finish();
            },

            loadDataPrepared(page = 1){
                this.$Progress.start();
                axios.get('api/devisETQ?page=' + page).then(({ data }) => (this.devisETQPrepared = data.data.DevisETCPrepared));
                this.$Progress.finish();
            },
           

            loadClients(){
                axios.get("api/indexClients").then(({ data }) => (this.clients = data.data));
                // console.log("load CLient ok");
            },

            ExistClientCHange(){
                if(this.form.existClient == 'Non'){
                    this.form.client = [{  name:"",tele:"",email:"" }];
                    // this.loadClients();
                    // console.log(this.form.existClient);
                    // console.log(this.clients);
                }else{
                    this.form.id_client = '';
                }
            },

            RectoCouleurCHange(){
                if(this.form.recto == 'Non'){
                    this.form.couleurs=[{  couleur: "",ref:"",formule:"" }]
                    this.fomr.nbr_clouleur = ''
                }
            },

            VersoCouleurCHange(){
                if(this.form.verso == 'Non'){
                    this.form.verso_couleur=''
                    this.form.verso_ref=''
                    this.form.verso_formule=''
                }
            },


            filterETC(){
                if(this.search != ''){
                    this.$Progress.start();
                    axios.get('api/filterETC/' + this.search).then(({ data }) => (this.devisETQ = data.data));
                    this.$Progress.finish();
                }else{
                    this.loadData();
                }
            },

            filterETCPrepared(){
                if(this.search1 != ''){
                    this.$Progress.start();
                    axios.get('api/filterETCPrepared/' + this.search1).then(({ data }) => (this.devisETQPrepared = data.data));
                    this.$Progress.finish();
                }else{
                    this.loadDataPrepared();
                }
            },

            newDevis(){
                this.form.reset();
                this.editmode = false;
                this.disableDevisPV = false;
                this.DialogVisible_global = true;
                this.active=0;
            },
            
            newPrices(row){
                // this.get_clientDevis(row)
                this.form_Prices.devisPrices = [];
                
                for (let i = 0; i < row.get_devis.length; i++) {
                    this.form_Prices.devisPrices.push({
                        id: row.get_devis[i].id,
                        quantite_etq: row.get_devis[i].quantite_etq,
                        nom_etc: row.get_devis[i].nom_etc,
                        prixHTTApliquer: row.get_devis[i].prixHTTApliquer,
                        prixPClient: row.get_devis[i].prixPClient,
                        prixFinal: row.get_devis[i].prixFinal,
                    })
                }
                this.form_Prices.id_global = row.id;
                
                this.DialogVisiblePrices = true;
            },

            updatePrices(){
                this.$Progress.start();
              this.form_Prices.put('api/updatePrices')
              .then((response) => {
                // this.editmode = false;
                this.DialogVisiblePrices = false
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });
                  this.loadDevisGlobal();
                //   this.loadDevisGlobalR();
                  this.$Progress.finish();
              })
              .catch(() => {
                  this.$Progress.fail();
              });
            },

            RelancerDevis(){
                this.Relancerform.reset();
                this.Relancereditmode = false;
                // this.disableDevisPV = false;
                this.DialogVisible_RelancerCMD = true;
                // this.active=0;
            },

            async updateDevisGlobal(devisGlobal){
                
                await this.get_clientDevis(devisGlobal);

                this.form_global.reset();
                this.editmode = true;
                this.disableDevisPV = false;
                this.DialogVisible_global = true;
                this.active=0;
               
                this.form_global.id = devisGlobal.id,
                this.form_global.id_client = devisGlobal.id_client,
                this.form_global.existClient = devisGlobal.existClient,
                this.form_global.dateDevis = devisGlobal.dateDevis,
                this.form_global.modalite_paiment = devisGlobal.modalite_paiment,
                this.form_global.type_ISE = devisGlobal.type_ISE
                this.form_global.Etat_devis = devisGlobal.Etat_devis
            },

            updateDevis1(devis){
                this.editmode = true;
                this.disableDevisPV = false;
                this.active=0;
                this.form.reset();
                this.DialogVisible = true;
                this.form.fill(devis);
                this.form.existClient = 'Non';
                this.form.couleurs = [];
                for (let i = 0; i < devis.get_couleurs.length; i++) {
                    this.form.couleurs.push({
                        id_couleur: devis.get_couleurs[i].id,
                        couleur: devis.get_couleurs[i].couleur,
                        ref: devis.get_couleurs[i].ref,
                        formule: devis.get_couleurs[i].formule,
                    })
                }
            },
            updateDevis(devis){
                this.editmode = true;
                this.disableDevisPV = true;
                this.active=0;
                this.form.reset();
                this.DialogVisible = true;
                this.form.fill(devis);
                this.form.existClient = 'Non';
                this.form.couleurs = [];
                for (let i = 0; i < devis.get_couleurs.length; i++) {
                    this.form.couleurs.push({
                        id_couleur: devis.get_couleurs[i].id,
                        couleur: devis.get_couleurs[i].couleur,
                        ref: devis.get_couleurs[i].ref,
                        formule: devis.get_couleurs[i].formule,
                    })
                }
            },
            

            PreparationDevis(devis){
                this.editmode = true;
                this.form.reset();
                this.DialogVisibleP = true;
                this.form.fill(devis);
            },

            tableRowClassName({row, rowIndex}) {
                
                if (row.preparation == 0) {                 
                    return 'info-row';
                } else if (row.preparation == 1 && row.validation3 == 0) {
                    return 'warning-row';
                }else if (row.preparation == 1 && row.validation3 == 1) {
                    return 'success-row';
                }
                return '';
            },

            createData(){
            this.$Progress.start();

              this.form_global.post('api/devisETQ')
              .then((data)=>{
                if(data.data.success){
                 this.DialogVisible_global = false

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.$Progress.finish();
                  this.loadDevisGlobal();
                  this.loadDataPrepared();

                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          },

          updateGlobalData(){
            this.$Progress.start();
              this.form_global.put('api/UpdateDevisGlobal/'+this.form_global.id)
              .then((response) => {
                // this.editmode = false;
                this.DialogVisible_global = false
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });
                  this.loadDevisGlobal();
                  this.loadDevisGlobalR();
                  this.$Progress.finish();
              })
              .catch(() => {
                  this.$Progress.fail();
              });
              
            // console.log(this.form_global.id);
          },

          updateData(){
              this.$Progress.start();
              this.form.put('api/devisETQ/'+this.form.id)
              .then((response) => {
                  // success
                //   $('#addNew').modal('hide');
                  this.DialogVisible = false
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });
                  this.$Progress.finish();
                      //  Fire.$emit('AfterCreate');

                  this.loadDevisGlobal();
                  this.loadDevisGlobalR();
                //   this.loadDataPrepared();
              })
              .catch(() => {
                  this.$Progress.fail();
              });

          },

          PreparationData(){
              this.$Progress.start();
              this.form.preparation = 1;
              this.form.prixFinal = this.form.prixHTTApliquer;
              this.form.put('api/preparationETQ/'+this.form.id)
              .then((response) => {
                  // success
                  this.DialogVisibleP = false
                  this.form.preparation = 0;
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });
                  this.$Progress.finish();

                  this.loadDevisGlobal();
                  this.loadDevisGlobalR();
                //   this.loadDataPrepared();
              })
              .catch(() => {
                  this.$Progress.fail();
              });

          },

          deletData(id){
              Swal.fire({
                  title: 'Attention !',
                  text: "vous voulez vraiment supprimer ce devis",
                  showCancelButton: true,
                  confirmButtonColor: '#67c23a',
                  cancelButtonColor: '#f56c6c',
                  confirmButtonText: 'Oui, supprimez-le ! '
                  }).then((result) => {

                      // Send request to the server
                        if (result.value) {
                              this.form.delete('api/devisETQ/'+id).then(()=>{
                                      Swal.fire(
                                      'Supprimé !',
                                      'Votre Devis a été Bien supprimé.',
                                      'success'
                                      );
                                  // Fire.$emit('AfterCreate');
                                  this.loadData();
                                  this.loadDataPrepared();
                              }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                        }
                  })
          },

          deletDevisGlobal(id){
              Swal.fire({
                  title: 'Attention !',
                  text: "vous voulez vraiment supprimer ce devis",
                  showCancelButton: true,
                  confirmButtonColor: '#67c23a',
                  cancelButtonColor: '#f56c6c',
                  confirmButtonText: 'Oui, supprimez-le ! '
                  }).then((result) => {

                      // Send request to the server
                        if (result.value) {
                              this.form.delete('api/destroyDevisGlobal/'+id).then(()=>{
                                      Swal.fire(
                                      'Supprimé !',
                                      'Votre Devis a été Bien supprimé.',
                                      'success'
                                      );
                                  // Fire.$emit('AfterCreate');
                                  this.loadDevisGlobal();
                                  this.loadDevisGlobalR();
                                //   this.loadDataPrepared();
                              }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                        }
                  })
          },


// ENd CRUD Devis

        //   Multiple Couleurs
            deleteRow(index, couleurs) {
                var idx = this.form.couleurs.indexOf(couleurs);
                console.log(idx, index);
                if (idx > -1) {
                    this.form.couleurs.splice(idx, 1);
                }
            },
            addNewRowGlobal() {
                this.ArticlesForm.couleurs.push({
                    couleur: '',
                    ref: '',
                    formule: '',
                })
            },

            addNewRow() {
                this.form.couleurs.push({
                    couleur: '',
                    ref: '',
                    formule: '',
                })
            },

            // End Multiple Couleurs

            // multiple devis
            addNewRowDevis() {
                var imprission=''
                var front=''

                if(this.ArticlesForm.nbr_etq_front >= 2){
                    front = this.ArticlesForm.nbr_etq_front+'P'
                }

                if(this.ArticlesForm.impression == 'OUI'){
                    imprission = 'IMPRIMÉ'
                }

                this.form_global.ArticlesCommander.push(
                {
                    typeEtiquette:this.ArticlesForm.typeEtiquette,
                    nom_etc:this.ArticlesForm.typeEtiquette +' SUR '+this.ArticlesForm.support_papier+' '+this.ArticlesForm.formatMM_L+'x'+this.ArticlesForm.formatMM_D
                            +' '+front+' '+imprission +' '+this.ArticlesForm.nom_etc.toUpperCase(),
                    // 
                    conception:this.ArticlesForm.conception,
                    formatMM_L:this.ArticlesForm.formatMM_L,
                    formatMM_D:this.ArticlesForm.formatMM_D,
                    forme_etq:this.ArticlesForm.forme_etq,
                    support_papier:this.ArticlesForm.support_papier,
                    quantite_etq:this.ArticlesForm.quantite_etq,
                    impression:this.ArticlesForm.impression,
                    nbr_clouleur:this.ArticlesForm.nbr_clouleur,
                    couleurs: this.ArticlesForm.couleurs,
                    recto:this.ArticlesForm.recto,
                    verso:this.ArticlesForm.verso,
                    verso_couleur:this.ArticlesForm.verso_couleur,
                    verso_ref:this.ArticlesForm.verso_ref,
                    verso_formule:this.ArticlesForm.verso_formule,
                    sens_sortie:this.ArticlesForm.sens_sortie,
                    type_sense_sortie:this.ArticlesForm.type_sense_sortie,
                    repiquage:this.ArticlesForm.repiquage,
                    vernis:this.ArticlesForm.vernis,
                    vernis_type:this.ArticlesForm.vernis_type,
                    plastification:this.ArticlesForm.plastification,
                    plastification_type:this.ArticlesForm.plastification_type,
                    dorure:this.ArticlesForm.dorure,
                    dorure_type:this.ArticlesForm.dorure_type,
                    couleur_dorure:this.ArticlesForm.couleur_dorure,
                    nbr_etq_bobine:this.ArticlesForm.nbr_etq_bobine,
                    nbr_etq_front:this.ArticlesForm.nbr_etq_front,
                    mandrin:this.ArticlesForm.mandrin,
                    mandrin_autre:this.ArticlesForm.mandrin_autre,
                    poses_etq:this.ArticlesForm.poses_etq,
                    form_decoupe:this.ArticlesForm.form_decoupe,
                    form_decoupe_id:this.ArticlesForm.form_decoupe_id,
                    films_cliches:this.ArticlesForm.films_cliches,
                    films_cliches_id:this.ArticlesForm.films_cliches_id,
                    }
                )

                // console.log(this.form.ArticlesCommander);
                this.ArticlesForm.reset();
                
            },

            deleteRowDevis(index, ArticlesCommander) {
                var idx = this.form_global.ArticlesCommander.indexOf(ArticlesCommander);
                // console.log(idx, index);
                if (idx > -1) {
                    this.form_global.ArticlesCommander.splice(idx, 1);
                }
            },
            // End Multiple devis

            // select multiple etc a relancer
            CloseDataLancer(){
                this.DialogVisible_RelancerCMD = false;
                this.multipleSelection = []
            },

            lancerCommande(){
                this.changesExist = 0;
                this.Relancerform.ArticlesCommander = [];
                for (let i = 0; i < this.multipleSelection.length; i++) {
                    this.Relancerform.ArticlesCommander.push({
                        typeEtiquette: this.multipleSelection[i].typeEtiquette ,
                        nom_etc: this.multipleSelection[i].nom_etc,
                        conception: this.multipleSelection[i].conception,
                        formatMM_L: this.multipleSelection[i].formatMM_L,
                        formatMM_D: this.multipleSelection[i].formatMM_D,
                        forme_etq: this.multipleSelection[i].forme_etq,
                        support_papier: this.multipleSelection[i].support_papier,
                        quantite_etq: this.multipleSelection[i].quantite_etq,
                        impression: this.multipleSelection[i].impression,
                        nbr_clouleur: this.multipleSelection[i].nbr_clouleur,
                        recto: this.multipleSelection[i].recto,
                        verso: this.multipleSelection[i].verso,
                        verso_couleur: this.multipleSelection[i].verso_couleur,
                        verso_ref: this.multipleSelection[i].verso_ref,
                        verso_formule: this.multipleSelection[i].verso_formule,
                        sens_sortie: this.multipleSelection[i].sens_sortie,
                        type_sense_sortie: this.multipleSelection[i].type_sense_sortie,
                        repiquage: this.multipleSelection[i].repiquage,
                        vernis: this.multipleSelection[i].vernis,
                        vernis_type: this.multipleSelection[i].vernis_type,
                        plastification: this.multipleSelection[i].plastification,
                        plastification_type: this.multipleSelection[i].plastification_type,
                        dorure: this.multipleSelection[i].dorure,
                        dorure_type: this.multipleSelection[i].dorure_type,
                        couleur_dorure: this.multipleSelection[i].couleur_dorure,
                        nbr_etq_bobine: this.multipleSelection[i].nbr_etq_bobine,
                        nbr_etq_front: this.multipleSelection[i].nbr_etq_front,
                        mandrin: this.multipleSelection[i].mandrin,
                        mandrin_autre: this.multipleSelection[i].mandrin_autre,
                        poses_etq: this.multipleSelection[i].poses_etq,
                        form_decoupe: this.multipleSelection[i].form_decoupe,
                        form_decoupe_id: this.multipleSelection[i].form_decoupe_id,
                        films_cliches: this.multipleSelection[i].films_cliches,
                        films_cliches_id: this.multipleSelection[i].films_cliches_id,
                        //get prices
                        prixHTTApliquer: this.multipleSelection[i].prixHTTApliquer,
                        prixParMill: this.multipleSelection[i].prixParMill,
                        totalSansFDC: this.multipleSelection[i].totalSansFDC,
                        totalSansC: this.multipleSelection[i].totalSansC,
                        totalSansFD: this.multipleSelection[i].totalSansFD,
                    })
                    // 
                    if(this.multipleSelection[i].changesExist == 'OUI'){
                        this.changesExist++
                    }
                    // console.log(this.multipleSelection[i].changesExist);
                }
                
            },

            async RelanceDevis(){
                await this.lancerCommande()

                if(this.changesExist == 0){

                    // console.log(this.multipleSelection);
                    // console.log("br");
                    // console.log(this.Relancerform.ArticlesCommander);

                // alert("ok" + this.changesExist)
                this.$Progress.start();
           
                this.Relancerform.post('api/RelancerDevis')
                    .then((data)=>{
                    if(data.data.success){
                    this.DialogVisible_RelancerCMD = false
    
                        Toast.fire({
                            icon: 'success',
                            title: data.data.message
                        });
                        this.$Progress.finish();
                        this.loadDevisGlobalR();
                        this.multipleSelection = []
                        this.active1 = 0
    
                    } else {
                        Toast.fire({
                            icon: 'error',
                            title: 'Some error occured! Please try again'
                        });
    
                        this.$Progress.failed();
                    }
                    })
                    .catch(()=>{
    
                        Toast.fire({
                            icon: 'error',
                            title: 'Some error occured! Please try again'
                        });
                    })
                }else{
                    this.$notify.error({
                        title: 'ATTENTION',
                        message: 'MERCI DE LANCER NOUVEUX DEVIS POUR LES ÉTIQUETTES AVEC MODIFICATIONS !!!',
                        offset: 100,
                    });
                }
            },

            handleSelectionChange(val) {
                this.multipleSelection = val;
            },

            // end select pultiple etc a lancer

            handleClose(done) {
                this.$confirm('Voulez-vous vraiment fermer cette boîte de dialogue ?')
                .then(_ => {
                    done();
                })
                .catch(_ => {});
            },

            handleCurrentChange2(val) {
                this.currentRow = val;
            },

            
// Start Calculs

            calculePosesT(){
                var total;
                var espaceD;
                var calculeD;

                 total = (this.form.Nombre_PosesL * this.form.nombre_PosesD);
                 calculeD = this.form.anamorphose / this.form.nombre_PosesD
                 espaceD = calculeD - this.form.formatMM_D;

                 if (!isNaN(total)) {
                    this.form.nombre_poseT = total;
                    this.form.espace_D = espaceD.toFixed(2);
                    this.form.developer_Calculer = calculeD.toFixed(2);
                } else {
                    this.form.nombre_poseT = '0';
                    this.form.espace_D = '0';
                    this.form.developer_Calculer = '0';
                }

                this.calculLaizeTH();
                
            },

             changeSelectMachine () {
                 
                    switch (this.form.Machine) {
                        case 'GIDUE-370':
                            this.Zcylindre = this.Machine1;
                            
                            break;
                        case 'NILPITER-B200':
                            this.Zcylindre = this.Machine2;
                           
                            break;
                        case 'ROTOFLEX-400':
                            this.Zcylindre = this.Machine3;
                            
                            break;
                        }


                },
            // calcul anamorphose
            calculeAnamorph(){
                var  total;

                total = (this.form.Z_cylindre * 3.175);
                this.form.espace_L = 3;
                 if (!isNaN(total)) {
                    this.form.anamorphose = total.toFixed(2);
                } else {
                    this.form.anamorphose = '0.00'
                }

                this.calculePosesT();
            },

            calculLaizeTH(){
                var totalTH;

                totalTH = (this.form.formatMM_L * this.form.Nombre_PosesL  + (Number(this.form.espace_L)  * (this.form.Nombre_PosesL - 1))) + 10 ;
                // totalTH = this.form.formatL * this.form.nbrPosesL

                 if (!isNaN(totalTH)) {
                    this.form.laize_tehorique = totalTH;
                } else {
                    this.form.laize_tehorique = '0'
                }
            },

            // 
            
            // quantité papier ..
            calculeConsomationPP(){
                var vr = 0;
                var vrTirage = 0;
                var qpc;
                var bobine;

                // for papier calcul

                if(this.form.recto == 'oui' && this.form.verso == 'oui'){
                    vr = 600;
                }else if(this.form.recto == 'oui' && this.form.verso == 'non'){
                    vr = 200;
                }else {
                    vr = 150;
                }

                // for tirage calcu
                if(this.form.recto == 'OUI' && this.form.verso == 'OUI' && this.form.support_papier == '-'){
                    
                    vrTirage = 600;
                }else if(this.form.recto == 'OUI' && this.form.verso == 'OUI' && this.form.support_papier != '-'){
                    
                    vrTirage = 800;
                }else if(this.form.recto == 'OUI' && this.form.verso == 'Non' && this.form.support_papier == '-'){
                    
                    vrTirage = 500;
                }else if(this.form.recto == 'OUI' && this.form.verso == 'Non' && this.form.support_papier != '-'){
                    
                    vrTirage = 700;
                }
                // else if(this.form.recto == 'Non' && this.form.verso == 'Non' && this.form.support_papier == '-'){
                    
                //     vrTirage = 280;
                // }
                else {
                    
                    vrTirage = 300;
                }

                this.form.vr = vr;
                this.form.vrTirage = vrTirage;

                qpc = (((this.form.quantite_etq/1000)/this.form.nombre_poseT)*this.form.anamorphose)+vr;
                bobine = qpc / 2000;

                if (!isNaN(qpc)) {
                    this.form.qtit_PapierML = qpc.toFixed(3);
                    this.form.nbr_bobine_consomme = bobine.toFixed(3);
                } else {
                    this.form.qtit_PapierML = '0';
                    this.form.nbr_bobine_consomme = '0'
                }
                
                this.ConvertMLtoM2();
            },

            

            ConvertMLtoM2(){
                var total;
                 total = (this.form.qtit_PapierML * this.form.laize_Stock)/1000;

                 if (!isNaN(total)) {
                    this.form.qtit_PapierM2 = total.toFixed(2);
                } else {
                    this.form.qtit_PapierM2 = '0'
                }

                this.calculLaizeTH();
            },

            calculeCoutPP(){
                var cout;
               
                cout = Number(this.form.qtit_PapierM2)*((Number(this.form.prixEURO) * Number(this.form.coursChange)) + Number(this.form.coutransport))

                if (!isNaN(cout)) {
                    this.form.cout_Papier = cout.toFixed(2);
                } else {
                    this.form.cout_Papier = '0'
                }
                
            },

            // 
            CalculeTirage(){
                var total;
                 total = (this.form.qtit_PapierML / 2000) * this.form.vrTirage;

                 if (!isNaN(total)) {
                    this.form.tirage = total.toFixed(3);
                } else {
                    this.form.tirage = '0'
                }

                // this.calculLaizeTH();
            },

            CalculeDuree(){
                var total1;
                var total2;

                 total1 = ((this.form.qtit_PapierML * 35)/2000)/60;
                 total2 = ((this.form.qtit_PapierML * 40)/2000)/60;

                console.log(this.form.qtit_PapierML , total1 , )
                 if (!isNaN(total1)) {
                    this.form.duree_production = total1.toFixed(0);
                    this.form.duree_conditionement = total2.toFixed(0);
                } else {
                    this.form.duree_conditionement = '0';
                    this.form.duree_production = '0'
                }

                // this.calculLaizeTH();
            },

            // 
            CalculeMandrin(){
                var total;
                var format;
                var cout;
                var array = [];

                 total = this.form.quantite_etq / this.form.nbr_etq_bobine;
                 format = this.form.formatMM_L * this.form.nbr_etq_front;
                 cout = total * 0.5;

                 if (!isNaN(total)) {
                    this.form.nbr_mandrin_consomme = total.toFixed(0);
                    this.form.format_mandrin = format;
                    this.form.cout_revien_mandrin = cout.toFixed(2);
                } else {
                    this.form.nbr_mandrin_consomme = '0';
                    this.form.format_mandrin = '0';
                    this.form.cout_revien_mandrin = '0'
                }
                
                // for (let index = 0; index < this.Mandrin_Stock.length; index++) {
                    
                //     if(this.form.format_mandrin >= this.Mandrin_Stock[index].value && this.form.format_mandrin - 10 <= this.Mandrin_Stock[index].value){
                //         console.log(this.Mandrin_Stock[index].label)
                //     }                    
                // }
            },

            CalculeFraisFormDecoup(){
                var total =0;

                total = this.form.frais_formDecoup*this.form.qtit_formDecoup;
            
                this.form.total_frais_formDecoup = total;
            },

            CalculeFraisCliches(){
                var totalRecto;
                var totalVerso;
                var total;
                var verso;
                if(this.form.verso == "Non"){
                    verso = 0;
                }else{
                    verso = 1
                }

                 totalRecto = ((this.form.anamorphose * this.form.laize_Stock) / 100 ) * this.form.nbr_clouleur * 0.42 * 1.2;
                 totalVerso = ((this.form.anamorphose * this.form.laize_Stock) / 100 )*verso;
                 total = totalRecto + totalVerso;

                 if (!isNaN(totalRecto) || totalRecto > 0) {
                    this.form.frais_recto = totalRecto.toFixed(2);
                    this.form.frais_verso = totalVerso.toFixed(2);
                    this.form.FTotal_Cliches = total.toFixed(2);
                } else {
                    this.form.frais_recto = '0';
                    this.form.frais_verso = '0';
                    this.form.FTotal_Cliches = '0';
                }

                // this.calculLaizeTH();
            },

            ChangerPrixAplique(){

                console.log(this.form.prixHTTApliquer)
                var total3 = 0;
                var total4 = 0;
                
                total3 = this.form.prixHTTApliquer*1000;
                total4 = this.form.prixHTTApliquer*this.form.nbr_etq_bobine;

                var totalSansFDC,totalSansFD,totalSansC = 0;

                totalSansFDC = this.form.prixHTTApliquer - ((Number(this.form.frais_formDecoup) + Number(this.form.FTotal_Cliches)) / this.form.quantite_etq);
                totalSansFD = this.form.prixHTTApliquer - (Number(this.form.frais_formDecoup) / this.form.quantite_etq);
                totalSansC = this.form.prixHTTApliquer - (Number(this.form.FTotal_Cliches) / this.form.quantite_etq);

                // totalSansFDC = totalSansFDC + (totalSansFDC * (this.form.marge_benif / 100));
                // totalSansFD = totalSansFD + (totalSansFD * (this.form.marge_benif / 100));
                // totalSansC = totalSansC + (totalSansC * (this.form.marge_benif / 100));

                // var totalSansFDC,totalSansFD,totalSansC = 0;

                // var totalSansFDC,totalSansFD,totalSansC = 0;
                // var totalSansFDC = 0;
                if (!isNaN(totalSansFDC)) { this.form.totalSansFDC  = totalSansFDC.toFixed(3)} else {this.form.totalSansFDC  = '0' }

                if (!isNaN(totalSansFD)) { this.form.totalSansFD  = totalSansFD.toFixed(3)} else {this.form.totalSansFD  = '0' }

                if (!isNaN(totalSansC)) { this.form.totalSansC  = totalSansC.toFixed(3)} else {this.form.totalSansC  = '0' }


                if (!isNaN(total3)) { this.form.prixParMill = total3.toFixed(3); } else { this.form.prixParMill = '0' }
                
                if (!isNaN(total4)) { this.form.prixParBobine = total4.toFixed(3); } else { this.form.prixParBobine = '0' }

            },

            // 
            CalculeMargeBenific(){
                var total = 0;
                var total2 = 0;

                total = (Number(this.form.cout_Papier) + Number(this.form.cout_revien_mandrin) + Number(this.form.tirage) + Number(this.form.FTotal_Cliches) + Number(this.form.frais_formDecoup)) / this.form.quantite_etq;
                total2 = (this.form.cout_hors_FDC * (this.form.marge_benif / 100)) + total;

                if (!isNaN(total2)) { this.form.prixHTT = total2.toFixed(3); } else { this.form.prixHTT = '0' }


                if (!isNaN(total)) { this.form.cout_hors_FDC = total.toFixed(3); } else { this.form.cout_hors_FDC = '0' }

                // this.calculLaizeTH();
            },

// End Calcules
// Invoice
            printC() {
                this.$htmlToPaper("printMe");
            },

            get_clientDevis(row){
                var total=0;
                var total1 =0;
                var total2= 0;
                var total3 = 0;
                // this.Offer_Client = row.get_client;
                this.Offer_devis = row.get_devis;
                this.id_offer = row.id
                this.form_decoupe = row.form_decoupe;
                this.films_cliches = row.films_cliches;
                
                this.userName = row.get_user.name;
                this.nameClient = row.get_client.name;
                this.teleClient = row.get_client.tele;
                this.emailClient = row.get_client.email;
                this.AdressClient = row.get_client.siegeSocial;
                this.GlobalModalitePaiment = row.modalite_paiment;
                this.type_ISE = row.type_ISE;

                // this.prixHTT = row.prixHTT

                console.log(this.type_ISE);

                if(this.GlobalModalitePaiment == "TVA"){
                    this.tvaCentage = 20;
                    this.tvaCentage2 = 0.2;
                } else {
                    this.tvaCentage = 0;
                    this.tvaCentage2 = 0;
                }

                for (let i = 0; i < row.get_devis.length; i++) {
                    if(this.type_ISE == 'inclus'){
                        total += (this.Offer_devis[i].quantite_etq * this.Offer_devis[i].prixFinal)

                        if(this.tvaCentage == 20){
                            this.tva = Number(total*0.2);
                        }else{
                            this.tva = Number(total*0);
                        }
                    }

                    if(this.type_ISE == 'exclus'){
                        total += (this.Offer_devis[i].quantite_etq * this.Offer_devis[i].totalSansFDC)
                        if(this.tvaCentage == 20){
                            this.tva = Number(total*0.2);
                        }else{
                            this.tva = Number(total*0);
                        }
                    }

                    if(this.type_ISE == 'separee'){

                        total1 = Number(this.Offer_devis[i].quantite_etq * this.Offer_devis[i].totalSansFDC),
                        total2 = Number(this.Offer_devis[i].FTotal_Cliches),
                        total3 = Number(this.Offer_devis[i].total_frais_formDecoup),
                        total +=  (Number(this.Offer_devis[i].quantite_etq * this.Offer_devis[i].totalSansFDC) + Number(this.Offer_devis[i].FTotal_Cliches) + Number(this.Offer_devis[i].total_frais_formDecoup))

                        if(this.tvaCentage == 0){
                            this.tva = Number(total2*0.2)+Number(total3*0.2);
                        }
                    }
                }

                this.GlobalTotalHT = total;
                if(this.tvaCentage == 20){
                    this.tva = total*this.tvaCentage2;
                }

                var x = total + this.tva;
               
                this.GlobalTotalTTC = x.toFixed(0);

            },

            async printGlobalDevis(row){
                
                await this.get_clientDevis(row),

                // console.log(NumberToLetter(this.GlobalTotalTTC)+' : '+this.GlobalTotalTTC)

                this.$htmlToPaper("printMe");
            },

            InvoiceModal() {
                this.editmode = false;
                this.form.reset();
                $("#Invoice").modal("show");
            },

// End Invoice

        handleCurrentChange(cpage) {
                this.currpage = cpage;
            },
        handleSizeChange(psize) {
                this.pagesize = psize;
            },


        },
        mounted() {

        },
        created() {
            this.$Progress.start();

            this.loadData();
            this.loadDevisGlobal();
            this.loadDevisGlobalR();
            this.loadDataPrepared();
            this.loadClients();

            this.$Progress.finish();
        },
        filters: {
            OfferDates(value){
                return moment(String(value)).format('MM-DD-YY')
            },
            FormNumbers(value){
                return numeral(value).format("0,0.000");
            },
            FormNumbers2(value){
                  return numeral(value).format("0,0");
              },
            NumberToLetterM(value){
                // console.log(value);
                var x = NumberToLetter(value);
                return x.toUpperCase();
            }
        },
        computed: {
        
        },
    }
</script>
<style>
  @import './checkstyle.css';
  /*  */
  .el-row {
    margin-bottom: 20px;
  }

  last-child {
      margin-bottom: 0;
    }
  .el-col {
    border-radius: 4px;
  }
  .bg-purple-dark {
    background: #99a9bf;
  }
  .bg-purple {
    background: #d3dce6;
  }
  .bg-purple-light {
    background: #e5e9f2;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }
  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }
</style>