<template>
    <section class="content">
<!-- {{ Reservations }} -->
      <div class="container-fluid">

        <el-card shadow="always">
            <div class="row">
                <div class="col-3">
                    <el-button type="primary" @click="newData()" size="small" icon="el-icon-plus" >Add New Hotel</el-button>
                </div>
                <div class="col-3">
                    <el-input v-model="searchHotels" @change="filterHotels" placeholder="Filter By Hotel's Name"> 
                        <el-button slot="append" icon="el-icon-search"></el-button>
                    </el-input>
                </div>
                <div class="col-3">
                    <el-select @change="filterType" v-model="searchType" filterable  style="width: 100%" placeholder="Filter By Accommodation">
                        <el-option label="Hotel" value="Hotel"></el-option>
                        <el-option label="Riad" value="Riad"></el-option>
                        <el-option label="Auberge" value="Auberge"></el-option>
                    </el-select>
                </div>
                <div class="col-3">
                    <el-select @change="filterCity" v-model="searchCity" filterable  style="width: 100%" placeholder="Filter By City">
                        <el-option label="Agadir" value="Agadir"></el-option>
                            <el-option label="Marrakech" value="Marrakech"></el-option>
                            <el-option label="Fes" value="Frensh"></el-option>
                            <el-option label="Meknes" value="Meknes"></el-option>
                            <el-option label="Tanger" value="Tanger"></el-option>
                            <el-option label="CasaBlanca" value="CasaBlanca"></el-option>
                            <el-option label="Rabat" value="Rabat"></el-option>
                            <el-option label="Ouarzazat" value="Ouarzazat"></el-option>
                            <el-option label="Tiznit" value="Tiznit"></el-option>
                            <el-option label="Essaouira" value="Essaouira"></el-option>
                    </el-select>
                </div>
            </div>
        </el-card>
        <!-- Start Table -->
        <el-card class="mb-3 mt-3">
          <div class="row">
          <div class="col-6">
              <h4 class="ml-3">Hotels</h4>
          </div>
            <!-- <div class="col-6">
                <el-input v-model="search" @change="filterETC" placeholder="Filter : "> 
                    <el-button slot="append" icon="el-icon-search"></el-button>
                </el-input>
            </div> -->
            <div class="col-12 mt-3">
              <el-table
                    :data="Hotels.data" border >
                    <el-table-column type="expand">
                        <template slot-scope="props">
                            <el-descriptions class="margin-top" :column="4" border>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <i class="el-icon-user"></i>
                                        Commercial 
                                    </template>
                                    <el-tag effect="dark">{{ props.row.commercial_name}}</el-tag>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <i class="el-icon-message"></i>
                                        Email 
                                    </template>
                                    <el-tag effect="dark">{{ props.row.commercial_email}}</el-tag>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <i class="el-icon-mobile-phone"></i>
                                        Tele 
                                    </template>
                                    <el-tag effect="dark">{{ props.row.commercial_tele}}</el-tag>
                                </el-descriptions-item>
                            </el-descriptions>
                        </template>
                    </el-table-column>
                    <el-table-column label="Name">
                        <template slot-scope="scope">
                            <strong>{{ scope.row.name }}</strong>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="Email">
                        <template slot-scope="scope">
                            <strong>{{ scope.row.email }}</strong>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="Stars">
                        <template slot-scope="scope">
                            <el-rate
                                v-model="scope.row.stars"
                                disabled
                                
                                text-color="#ff9900"
                                >
                            </el-rate>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="Tele">
                        <template slot-scope="scope">
                            <strong>{{ scope.row.tele }}</strong>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="City">
                        <template slot-scope="scope">
                            <strong>{{ scope.row.ville }}</strong>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="Accommodation">
                        <template slot-scope="scope">
                            <el-tag effect="dark"><strong>{{ scope.row.type }}</strong></el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column align="center">
                        <template slot-scope="{row}">
                            <el-divider direction="vertical"></el-divider>
                                <a href="#" @click="updateHotel(row)" data-toggle="tooltip" data-placement="top" title="Modifier">
                                        <i class="fa fa-edit blue mr-2"></i>
                                </a>
                                <el-divider direction="vertical"></el-divider>
                                
                                <a href="#" @click="deletData(row.id)" data-toggle="tooltip" data-placement="top" title="Supprimer">
                                    <i class="fa fa-trash red"></i>
                                </a>
                        </template>
                    </el-table-column>
              </el-table>
               
               <pagination class="mt-4" :data="Hotels" @pagination-change-page="loadData"></pagination>
            </div>
            
          </div>
          </el-card>
        <!-- End Table -->
          
          <!-- dialog -->
          <el-dialog
            :title="editmode ? 'Update Hotel':'New Hotel'"
            :visible.sync="DialogVisible_global"
            width="70%"
            :before-close="handleClose">
            <!-- Body dialog -->
                <el-form :model="form_global" ref="form_global" class="demo-ruleForm">
                    <fieldset class="row col-12 border p-2 mt-2">
                        <legend  class="w-auto badge"><h5>Hotel Infos</h5></legend>
                            <el-form-item class="col-lg-6" label="Name" prop="name" 
                            :rules="[
                                { required: true, message: 'Hotel Name is required' }
                            ]">
                                <el-input v-model="form_global.name" autocomplete="off"></el-input>
                            </el-form-item>
                            <el-form-item class="col-lg-3" label="Stars" prop="stars" 
                            :rules="[
                                { required: true, message: 'Stars is required' }
                            ]">
                                <el-input v-model="form_global.stars" autocomplete="off"></el-input>
                            </el-form-item>
                            <el-form-item class="col-lg-3" label="Type" prop="type"
                            :rules="[
                                { required: true, message: 'Hotel Name is required' }
                            ]">
                                <el-select v-model="form_global.type" style="width: 100%" placeholder="Type Of Accommodation">
                                        <el-option label="Hotel" value="Hotel"></el-option>
                                        <el-option label="Riad" value="Riad"></el-option>
                                        <el-option label="Auberge" value="Auberge"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item class="col-lg-4" label="Email" prop="email"
                            :rules="[
                                { required: true, message: 'Hotel Email is required' }
                            ]">
                                <el-input type="text" v-model="form_global.email" autocomplete="off"></el-input>
                            </el-form-item>
                            <el-form-item class="col-lg-4" label="Tele" prop="tele"
                            :rules="[
                                { required: true, message: 'Tele is required' }
                            ]">
                                <el-input type="text" v-model="form_global.tele" autocomplete="off"></el-input>
                            </el-form-item>
                            <el-form-item class="col-lg-4" label="Ville" prop="ville"
                            :rules="[
                                { required: true, message: 'Hotel Name is required' }
                            ]">
                                <el-input type="text" v-model="form_global.ville" autocomplete="off"></el-input>
                            </el-form-item>
                            <fieldset class="col-12 border p-2 mt-2">
                            <legend  class="w-auto badge"><h5>Commercial</h5></legend>
                                <div class="row">
                                    <el-form-item class="col-lg-4" label="Commercial Full Name" prop="commercial_name"
                                    :rules="[
                                        { required: true, message: 'Commercial Name is required' }
                                    ]">
                                        <el-input type="text" v-model="form_global.commercial_name" autocomplete="off"></el-input>
                                    </el-form-item>
                                    <el-form-item class="col-lg-4" label="Commercial Email" prop="commercial_email"
                                    :rules="[
                                        { required: true, message: 'Commercial Email is required' }
                                    ]">
                                        <el-input type="text" v-model="form_global.commercial_email" autocomplete="off"></el-input>
                                    </el-form-item>
                                    <el-form-item class="col-lg-4" label="Commercial Tele" prop="commercial_tele"
                                    :rules="[
                                        { required: true, message: 'Commercial Tele is required' }
                                    ]">
                                        <el-input type="text" v-model="form_global.commercial_tele" autocomplete="off"></el-input>
                                    </el-form-item>
                                </div>
                            </fieldset>
                    </fieldset>
                </el-form>
                <!-- ENd body Dialog -->
                <span slot="footer" class="dialog-footer">
                    <el-button @click="DialogVisible_global = false">Annuler</el-button>
                    <el-button type="primary" v-show="!editmode" @click="submitForm('form_global')">Valider</el-button>
                    <el-button type="primary" v-show="editmode" @click="updateData()">Valider</el-button>
                </span>
          </el-dialog>

          <!-- ENd dialog -->
          <!-- Modal files Bon Livraison -->
        <el-dialog
                title="Agency Price"
                :visible.sync="DialogVisiblePrices"
                center>

                <div style="max-width: 500px; margin: 0 auto;">
                            
                            <form
                                @submit="submitPrice"
                                class="mt-4"
                                enctype="multipart/form-data"
                            >
                                <div class="input-group">
                                    <div class="custom-file">
                                        <input
                                            type="file"
                                            name="filename"
                                            class="custom-file-input"
                                            id="inputFileUpload"
                                            v-on:change="onFileChange"
                                        />
                                        <label
                                            class="custom-file-label"
                                            for="inputFileUpload"
                                            >Select Agency Price For The Hotel</label
                                        >
                                    </div>
                                    
                                </div>
                                <br />
                                <p class="text-success font-weight-bold">
                                    {{ filename }}
                                </p>
                            
                        <span slot="footer" class="dialog-footer">
                            <el-button type="warning" @click="DialogVisibleBonLivraison = false">Annuler</el-button>
                            <input  type="submit" class="btn btn-success" value="Enregistrer"  />
                        </span>
                    </form>
                </div>
            </el-dialog>
            <!-- end dialog file Bon Livraison -->
  
      </div>
     
    </section>
    <!--  -->
  </template>
  
  
  <script>
      import VueTagsInput from '@johmun/vue-tags-input';
      import moment from 'moment'
  
      export default {
        components: {
            VueTagsInput,
          },
          data () {
              return {
                  DialogVisible_global: false,
                  active: 0,
                  Hotels: [],
                  search: '',
                  searchType: '',
                  searchCity: '',
                  searchHotels: '',
                  pagesize: 10,
                  currpage: 1,
                  editmode: false,
                  products : [],
                //   
                DialogVisiblePrices:false,
                filename:'',


                  numberValidateForm: {
                    age: ''
                },
                
                  form_global: new Form({
                    id:'',
                    name:'',
                    email:'',
                    tele:'',
                    ville:'',
                    stars:'',
                    commercial_name:'',
                    commercial_email:'',
                    commercial_tele:'',
                    type:'',
                  }),

                  autocompleteItems: [],
              }
          },
          methods: {            

                // 

            filterType(){
                if(this.searchType != ""){
                    this.$Progress.start();
                    axios.get('api/filterType/' + this.searchType).then(({ data }) => (this.Hotels = data.data));
                    this.$Progress.finish();
                }else{
                    this.loadData();
                }
            },

            filterHotels(){
                if(this.searchHotels != ""){
                    this.$Progress.start();
                    axios.get('api/filterHotels/' + this.searchHotels).then(({ data }) => (this.Hotels = data.data));
                    this.$Progress.finish();
                }else{
                    this.loadData();
                }
            },

            filterCity(){
                if(this.searchCity != ""){
                    this.$Progress.start();
                    axios.get('api/filterCity/' + this.searchCity).then(({ data }) => (this.Hotels = data.data));
                    this.$Progress.finish();
                }else{
                    this.loadData();
                }
            },

            filterETC(){
                if(this.search != ''){
                    this.$Progress.start();
                    axios.get('api/filterHotels/' + this.search).then(({ data }) => (this.Hotels = data.data));
                    this.$Progress.finish();
                }else{
                    this.loadData();
                }
            },
            // CRUD
            newData(){
                this.form_global.reset();
                this.editmode = false;
                // this.disableDevisPV = false;
                this.DialogVisible_global = true;
                this.active=0;
            },

            createData(){
            this.$Progress.start();

              this.form_global.post('api/hotels')
              .then((data)=>{
                if(data.data.success){
                 this.DialogVisible_global = false

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.$Progress.finish();
                  this.loadData();
                //   this.loadDataPrepared();

                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          },

          submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.createData();
                } else {
                    console.log('error submit!!');
                    return false;
                }
                });
            },

            loadData(page = 1){
                this.$Progress.start();
                axios.get('api/hotels?page=' + page).then(({ data }) => (this.Hotels = data.data));
                this.$Progress.finish();
            },

            updateHotel(reservation){
                this.editmode = true;
                this.form_global.reset();
                this.DialogVisible_global = true;
                this.form_global.fill(reservation);
            },

            updateData(){
              this.$Progress.start();
              this.form_global.put('api/hotels/'+this.form_global.id)
              .then((response) => {
                  // success
                  this.DialogVisible_global = false
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });
                  this.$Progress.finish();

                  this.loadData();
              })
              .catch(() => {
                  this.$Progress.fail();
              });

          },

          deletData(id){
              Swal.fire({
                  title: 'Attention !',
                  text: "You really want to delete this Hotel ??",
                  showCancelButton: true,
                  confirmButtonColor: '#67c23a',
                  cancelButtonColor: '#f56c6c',
                  confirmButtonText: 'Yes, Delete-it ! '
                  }).then((result) => {

                      // Send request to the server
                        if (result.value) {
                              this.form_global.delete('api/hotels/'+id).then(()=>{
                                      Swal.fire(
                                      'Deleted !',
                                      'Hotel Deleted Successfully',
                                      'success'
                                      );
                                  // Fire.$emit('AfterCreate');
                                  this.loadData();
                              }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                        }
                  })
          },
        //   End CRUD

            handleClose(done) {
                this.$confirm('Are you sure you want to close this dialog ?')
                .then(_ => {
                    done();
                })
                .catch(_ => {});
            },

            handleCurrentChange2(val) {
                this.currentRow = val;
            },

            // END
  
           
            loadProducts(){
  
              // if(this.$gate.isAdmin()){
                axios.get("api/product").then(({ data }) => (this.products = data.data));
              // }
            },
            loadCategories(){
                axios.get("/api/category/list").then(({ data }) => (this.categories = data.data));
            },
            loadTags(){
                axios.get("/api/tag/list").then(response => {
                    this.autocompleteItems = response.data.data.map(a => {
                        return { text: a.name, id: a.id };
                    });
                }).catch(() => console.warn('Oh. Something went wrong'));
            },
            editModal(product){
                this.editmode = true;
                this.form.reset();
                $('#addNew').modal('show');
                this.form.fill(product);
            },
            newModal(){
                this.editmode = false;
                this.form.reset();
                $('#addNew').modal('show');
            },
            createProduct(){
                this.$Progress.start();
  
                this.form.post('api/product')
                .then((data)=>{
                  if(data.data.success){
                    $('#addNew').modal('hide');
  
                    Toast.fire({
                          icon: 'success',
                          title: data.data.message
                      });
                    this.$Progress.finish();
                    this.loadProducts();
  
                  } else {
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
  
                    this.$Progress.failed();
                  }
                })
                .catch(()=>{
  
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
                })
            },
            updateProduct(){
                this.$Progress.start();
                this.form.put('api/product/'+this.form.id)
                .then((response) => {
                    // success
                    $('#addNew').modal('hide');
                    Toast.fire({
                      icon: 'success',
                      title: response.data.message
                    });
                    this.$Progress.finish();
                        //  Fire.$emit('AfterCreate');
  
                    this.loadProducts();
                })
                .catch(() => {
                    this.$Progress.fail();
                });
  
            },
            deletModal(id){
                Swal.fire({
                    title: 'Attention !',
                    text: "you really want to delete this Hotel ??",
                    showCancelButton: true,
                    confirmButtonColor: '#67c23a',
                    cancelButtonColor: '#f56c6c',
                    confirmButtonText: 'Yes, Delete-it ! '
                    }).then((result) => {
  
                        // Send request to the server
                          if (result.value) {
                                this.form.delete('api/product/'+id).then(()=>{
                                        Swal.fire(
                                        'Deleted !',
                                        'Votre fichier a été supprimé.',
                                        'success'
                                        );
                                    // Fire.$emit('AfterCreate');
                                    this.loadProducts();
                                }).catch((data)=> {
                                    Swal.fire("Failed!", data.message, "warning");
                                });
                          }
                    })
            },
          
           handleCurrentChange(cpage) {
                  this.currpage = cpage;
              },
          handleSizeChange(psize) {
                  this.pagesize = psize;
              },
  
  
          },
          mounted() {
              
          },
          created() {
              this.$Progress.start();
  
              this.loadData();
            //   this.loadCategories();
            //   this.loadTags();
  
              this.$Progress.finish();
          },
          filters: {
            OfferDates(value){
                return moment(String(value)).format('MM-DD-YY')
            },
              truncate: function (text, length, suffix) {
                  return text.substring(0, length) + suffix;
              },
          },
          computed: {
            filteredItems() {
              return this.autocompleteItems.filter(i => {
                return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
              });
            },
          },
      }
  </script>
  