<template>
    <div class="demo-image__preview text-center">
        <el-image
            :src=Urlsrc
            >
        </el-image>
    </div>
</template>

<script>
    export default {
        // idProjet:8,
        props: ['Urlsrc'],

        methods: {
           
        },
        mounted() {

        }
    }
</script>
