export default class Gate{

    constructor(user){
        this.user = user;
    }

    isAdmin(){
        return this.user.type === 'admin';
    }

    isUser(){
        return this.user.type === 'user';
    }

    isCMRS(){
        return this.user.type === 'commercial';
    }

    isConception(){
        return this.user.type === 'conception';
    }

    isMagasin(){
        return this.user.type === 'magasinier';
    }

    isProduction(){
        return this.user.type === 'production';
    }

    isAdminOrUser(){
        if(this.user.type === 'user' || this.user.type === 'admin'){
            return true;
        }
    }

    isAdminOrConception(){
        if(this.user.type === 'conception' || this.user.type === 'admin'){
            return true;
        }
    }

    isAdminOrCMRS(){
        if(this.user.type === 'commercial' || this.user.type === 'admin'){
            return true;
        }
    }

    isAdminOrProduct(){
        if(this.user.type === 'production' || this.user.type === 'admin'){
            return true;
        }
    }

    isGetProjects(){
        if(this.user.type === 'admin' || this.user.type === 'conception' || this.user.type === 'magasinier' || this.user.type === 'production'){
            return true;
        }
    }
}

