export default [
    { path: '/devis', component: require('./components/views/devis/devis.vue').default },
    { path: '/traitementCMDClient', component: require('./components/views/TraitementCMDClients.vue').default },
    { path: '/FactureDevis', component: require('./components/views/factureDevis/FactureDevis.vue').default },
    { path: '/ProfileFactureDevis/:idpfd', component: require('./components/views/factureDevis/profileFactureDevis.vue').default },
    { path: '/Projects', component: require('./components/views/Projects.vue').default },
    { path: '/profileProjet/:idp/:idEtc', component: require('./components/views/ProfileProjet.vue').default },
    // Traveling
    { path: '/reservations', component: require('./components/Travel/index.vue').default },
    { path: '/reminding', component: require('./components/Travel/reminding.vue').default },
    { path: '/products', component: require('./components/Travel/products.vue').default },
    { path: '/hotels', component: require('./components/Travel/hotels.vue').default },
    { path: '/plateformes', component: require('./components/Travel/plateforme.vue').default },
    { path: '/fournisseur', component: require('./components/Travel/fournisseur.vue').default },
    { path: '/spends', component: require('./components/Travel/spends.vue').default },
    { path: '/reclamations', component: require('./components/Travel/reclamations.vue').default },
    { path: '/esims', component: require('./components/Travel/esims.vue').default },
    // Comptes
    { path: '/ComptesClient', component: require('./components/views/ComptesClient.vue').default },
    { path: '/ComptesFourniss', component: require('./components/views/ComptesFourniss.vue').default },
    { path: '/DemandeDevisIntern', component: require('./components/views/DemandeDevisIntern.vue').default },
    // Commandes Fournisseur
    { path: '/CommandesFournisseurs', component: require('./components/views/CommandesFournisseurs.vue').default },
    { path: '/profileCommande/:ids', component: require('./components/views/ProfileCommande.vue').default },

    // End Comptes
    { path: '/reclamations', component: require('./components/views/reclamations.vue').default },
    
    // based
    { path: '/cliche', component: require('./components/views/based/cliche.vue').default },
    { path: '/plaque', component: require('./components/views/based/plaque.vue').default },
    { path: '/receptionStock', component: require('./components/views/based/receptionStock.vue').default },

    // 
    { path: '/dashboard', component: require('./components/Dashboard.vue').default },
    { path: '/profile', component: require('./components/Profile.vue').default },
    { path: '/developer', component: require('./components/Developer.vue').default },
    { path: '/users', component: require('./components/Users.vue').default },
    { path: '/products', component: require('./components/product/Products.vue').default },
    { path: '/product/tag', component: require('./components/product/Tag.vue').default },
    { path: '/product/category', component: require('./components/product/Category.vue').default },
    { path: '*', component: require('./components/NotFound.vue').default }
];
