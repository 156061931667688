<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">
        <div class="col-6">
            <el-button type="primary" size="small" icon="el-icon-plus" @click="newModal" plain>Ajouter Nouveau Cliché</el-button>
        </div> 
          <div class="col-6">
              <el-input v-model="search" size="mini" placeholder="Filter : "/>
          </div>
          <div class="col-12 mt-3">
            <el-table 
                  :data="tableData.filter(data => !search || data.PlaqueNum.toLowerCase().includes(search.toLowerCase()) ).slice((currpage - 1) * pagesize, currpage * pagesize)" style="width: 100%"  
                  border >
            <el-table-column type="expand">
                <template  slot-scope="props">
                    <div class="row">
                        <div class="col ml-5">
                            <p class="ml-5"><strong> Format Etq en mm: </strong> {{ props.row.form1 }} X {{ props.row.form2 }}</p>
                            <p class="ml-5"><strong> Nombre de pose en laize: </strong> {{ props.row.nombreL }}</p>
                            <p class="ml-5"><strong> Nombre de pose en developpe: </strong> {{ props.row.nombreD }}</p>
                            <p class="ml-5"><strong> Nombre de pose sur la plaque : </strong> {{ props.row.nombreL * props.row.nombreD }}</p>
                            <p class="ml-5"><strong> Z Cylindre: </strong> {{ props.row.ZCylindre }}</p>
                        </div>
                        
                        <div class="col">
                            <p class="ml-5"><strong> Espace developpe en mm: </strong> {{ props.row.EspaceDev }}</p>
                            <p class="ml-5"><strong> Espace Laize en mm: </strong> {{ props.row.EspaceLaize }}</p>
                            <p class="ml-5"><strong> Espace papier calculée en mm: </strong> {{ props.row.LaizePapier }}</p>
                            <p class="ml-5"><strong> Machines: </strong> {{ props.row.machine }}</p>
                            <p class="ml-5"><strong> C1 : </strong> {{ props.row.c1 }}</p>
                            <p class="ml-5"><strong> C2 : </strong> {{ props.row.c2 }}</p>
                            <p class="ml-5"><strong> C3 : </strong> {{ props.row.c3 }}</p>
                            <p class="ml-5"><strong> C4 : </strong> {{ props.row.c4 }}</p>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label=" Cliché N° ">
                <template slot-scope="scope">
                    <span>{{ scope.row.PlaqueNum }}</span>
                </template>
            </el-table-column>
            <el-table-column label=" N° Serie ">
                <template slot-scope="scope">
                    <span>{{ scope.row.num_serie }}</span>
                </template>
            </el-table-column>
            <el-table-column label="Date">
                <template slot-scope="scope">
                    <span>{{ scope.row.date }}</span>
                </template>
            </el-table-column>
            
            <el-table-column label="Fournisseur">
                <template slot-scope="scope">
                    <span>{{ scope.row.fournisseur }}</span>
                </template>
            </el-table-column>
            <el-table-column v-if="$gate.isAdmin()" width="150" align="center">
                <template slot-scope="{row}">
                        <a href="#" @click="editModal(row)">
                                <i class="fa fa-edit blue"></i>
                            </a>
                            <a href="#" @click="deletModal(row.id)">
                            <i class="fa fa-trash red"></i>
                        </a>
                </template>
            </el-table-column>
            </el-table>
             <!--  -->
            <el-pagination background 
                    layout="prev, pager, next, sizes, total, jumper"
                    :page-sizes = "[5, 10, 15, 20]"
                    :page-size = "pagesize"
                    :total="tableData.length"
                    @current-change = "handleCurrentChange" 
                    @size-change = "handleSizeChange" 
                    >
            </el-pagination>
          </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog modal-xl" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Ajouter Nouveau Cliché</h5>
                    <h5 class="modal-title" v-show="editmode">Modifier le Cliché</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form>
                    <div class="modal-body">
                        <fieldset class="border p-2">
                            <!-- <legend class="w-auto badge badge-success"><h6> Informations de Siège </h6></legend> -->
                            <div class="row">
                                <div class="form-group col-6">
                                    <label>N° Serie:</label>
                                    <input v-model="form.numSerie" type="text" name="numSerie"
                                        class="form-control" :class="{ 'is-invalid': form.errors.has('numSerie') }">
                                    <has-error :form="form" field="numSerie"></has-error>
                                </div>
                                <div class="form-group col-6">
                                    <label>Date:</label>
                                    <input v-model="form.datePlaque" type="date" name="datePlaque"
                                        class="form-control" :class="{ 'is-invalid': form.errors.has('datePlaque') }">
                                    <has-error :form="form" field="datePlaque"></has-error>
                                </div>
                               
                                 <div class="form-group col-6">
                                    <label>Fournisseur :</label>
                                    <input v-model="form.fournisseur" placeholder="fournisseur" type="text" name="fournisseur"
                                        class="form-control" :class="{ 'is-invalid': form.errors.has('fournisseur') }">
                                    <has-error :form="form" field="fournisseur"></has-error>
                                </div>
                                 <div class="form-group col-6">
                                    <label>BL / FA N° :</label>
                                    <input v-model="form.blfa" type="text" name="blfa"
                                        class="form-control" :class="{ 'is-invalid': form.errors.has('blfa') }">
                                    <has-error :form="form" field="blfa"></has-error>
                                </div>

                                
                            </div>
                        </fieldset>
                        <!--  -->
                        <fieldset class="border p-2">
                            <!-- <legend class="w-auto badge badge-success"><h6> Résponsable D'Achat </h6></legend> -->
                                <div class="row">
                                    <div class="form-group col-4">
                                        <label>Client :</label>
                                        <input v-model="form.client" type="number" name="client"
                                            class="form-control" :class="{ 'is-invalid': form.errors.has('client') }">
                                        <has-error :form="form" field="client"></has-error>
                                    </div>
                                    <div class="form-group col-4">
                                        <label>Format Etq en mm:</label>
                                            <div class="row">
                                                <div class="col-4">
                                                    <input v-model="form.format1" placeholder="L" type="number" name="format1"
                                                    class="form-control" :class="{ 'is-invalid': form.errors.has('price') }">
                                                </div>
                                                <div class="col-4">
                                                    <input v-model="form.format2" placeholder="D" type="number" name="format2"
                                                    class="form-control" :class="{ 'is-invalid': form.errors.has('price') }">
                                                </div>
                                            </div>
                                        <has-error :form="form" field="nrc"></has-error>
                                    </div>
                                    <div class="col-4">
                                        <label>Nbr de cliché:</label>
                                        <input v-model="form.nbrCliche" type="number" name="nbrCliche"
                                            class="form-control" :class="{ 'is-invalid': form.errors.has('nbrCliche') }">
                                        <has-error :form="form" field="nbrCliche"></has-error>
                                    </div>
                                    <div class="col-12 mb-3">
                                        <fieldset class="border p-2 ">
                                            <legend class="w-auto badge badge"><h6><strong> Nombre de Pose </strong></h6></legend>
                                            <div class="row">
                                                <div class="form-group col-4">
                                                    <div class="input-group mb-2">
                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text">L</div>
                                                        </div>
                                                        <input type="text" class="form-control" name="l" id="inlineFormInputGroup" placeholder="">
                                                    </div>
                                                </div>
                                                <div class="form-group col-4">
                                                    <div class="input-group mb-2">
                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text">D</div>
                                                        </div>
                                                        <input type="text" class="form-control" name="d" id="inlineFormInputGroup" placeholder="">
                                                    </div>
                                                </div>
                                                <div class="form-group col-4">
                                                    <div class="input-group mb-2">
                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text">T</div>
                                                        </div>
                                                        <input type="text" class="form-control" name="t" id="inlineFormInputGroup" placeholder="">
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                    <!--  -->
                                    
                                        <div class="form-group col-3">
                                            <div class="input-group mb-2">
                                                <div class="input-group-prepend">
                                                    <div class="input-group-text">C1</div>
                                                </div>
                                                <input type="text" class="form-control" name="c1" id="inlineFormInputGroup" placeholder="">
                                            </div>
                                        </div>
                                        <div class="form-group col-3">
                                            <div class="input-group mb-2">
                                                <div class="input-group-prepend">
                                                    <div class="input-group-text">C2</div>
                                                </div>
                                                <input type="text" class="form-control" name="c2" id="inlineFormInputGroup" placeholder="">
                                            </div>
                                        </div>
                                        <div class="form-group col-3">
                                            <div class="input-group mb-2">
                                                <div class="input-group-prepend">
                                                    <div class="input-group-text">C3</div>
                                                </div>
                                                <input type="text" class="form-control" name="c3" id="inlineFormInputGroup" placeholder="">
                                            </div>
                                        </div>
                                        <div class="form-group col-3">
                                            <div class="input-group mb-2">
                                                <div class="input-group-prepend">
                                                    <div class="input-group-text">C4</div>
                                                </div>
                                                <input type="text" class="form-control" name="c4" id="inlineFormInputGroup" placeholder="">
                                            </div>
                                        </div>
                                        <div class="form-group col-3">
                                            <div class="input-group mb-2">
                                                <div class="input-group-prepend">
                                                    <div class="input-group-text">C5</div>
                                                </div>
                                                <input type="text" class="form-control" name="c5" id="inlineFormInputGroup" placeholder="">
                                            </div>
                                        </div>
                                        <div class="form-group col-3">
                                            <div class="input-group mb-2">
                                                <div class="input-group-prepend">
                                                    <div class="input-group-text">C6</div>
                                                </div>
                                                <input type="text" class="form-control" name="c6" id="inlineFormInputGroup" placeholder="">
                                            </div>
                                        </div>
                                        <div class="form-group col-3">
                                            <div class="input-group mb-2">
                                                <div class="input-group-prepend">
                                                    <div class="input-group-text">C7</div>
                                                </div>
                                                <input type="text" class="form-control" name="c7" id="inlineFormInputGroup" placeholder="">
                                            </div>
                                        </div>
                                        <div class="form-group col-3">
                                            <div class="input-group mb-2">
                                                <div class="input-group-prepend">
                                                    <div class="input-group-text">C8</div>
                                                </div>
                                                <input type="text" class="form-control" name="c8" id="inlineFormInputGroup" placeholder="">
                                            </div>
                                        </div>
                                    <!--  -->
                                    
                                    <div class="form-group col">
                                        <label>Z Cylindre:</label>
                                        <input v-model="form.ZCylindr" type="number" name="ZCylindr"
                                            class="form-control" :class="{ 'is-invalid': form.errors.has('ZCylindr') }">
                                        <has-error :form="form" field="ZCylindr"></has-error>
                                    </div>
                                    <div class="form-group col">
                                        <label>Espace developpe en mm:</label>
                                        <input v-model="form.espaceDevlop" type="number" name="espaceDevlop"
                                            class="form-control" :class="{ 'is-invalid': form.errors.has('espaceDevlop') }">
                                        <has-error :form="form" field="espaceDevlop"></has-error>
                                    </div>
                                    <div class="form-group col">
                                        <label>Espace Laize en mm :</label>
                                        <input v-model="form.espaceLaize" type="number" name="espaceLaize"
                                            class="form-control" :class="{ 'is-invalid': form.errors.has('espaceLaize') }">
                                        <has-error :form="form" field="espaceLaize"></has-error>
                                    </div>
                                    <div class="form-group col">
                                        <label>Laize papier calculée en mm :</label>
                                        <input v-model="form.laizePapier" type="number" name="laizePapier"
                                            class="form-control" :class="{ 'is-invalid': form.errors.has('laizePapier') }">
                                        <has-error :form="form" field="laizePapier"></has-error>
                                    </div>
                                    <div class="form-group col">
                                        <label>Machine :</label>
                                        <select v-model="form.machine" class="custom-select mr-sm-2" id="machine">
                                            <option disabled selected>choisir la machine</option>
                                            <option value="1">GIDUE 370</option>
                                            <option value="1">MACHINE 2</option>
                                            <option value="1">MACHINE 3</option>
                                        </select>
                                        <has-error :form="form" field="machine"></has-error>
                                    </div>
                                </div>
                        </fieldset>
                    </div>
                    <div class="modal-footer">
                        <el-button type="danger" size="small" data-dismiss="modal" plain>Annuler</el-button>
                        <el-button type="success" v-show="editmode" size="small" @click="updateProduct()" plain>Valider</el-button>
                        <el-button type="success" v-show="!editmode" size="small" @click="createProduct()" plain>Valider</el-button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
    import VueTagsInput from '@johmun/vue-tags-input';

    export default {
      components: {
          VueTagsInput,
        },
        data () {
            return {
                 tableData: [{
                    PlaqueNum:'0007-88x71-REC',
                    num_serie:'El Houda, N°565 , Agadir ',
                    date: new Date().toISOString().slice(0,10),
                    fournisseur:'Miller Graphics',
                    format1:'88',
                    format2:'71',
                    nombreL:'2',
                    nombreD:'4',
                    ZCylindre:'94',
                    EspaceDev:'3.6125',
                    EspaceLaize:'3',
                    LaizePapier:'189',
                    Machine:'GIDUE 370',
                    c1:'JAUNE Pr',
                    c2:'MAGENTA Pr',
                    c3:'CYAN Pr',
                    c4:'NOIR Pr',
                }],

                search: '',
                pagesize: 10,
                currpage: 1,
                editmode: false,
                products : [],
                form: new Form({
                    id : '',
                    category : '',
                    name: '',
                    description: '',
                    tags:  [],
                    photo: '',
                    category_id: '',
                    price: '',
                    photoUrl: '',
                }),
                categories: [],
              
                tag:  '',
                autocompleteItems: [],
            }
        },
        methods: {

         
          loadProducts(){

            // if(this.$gate.isAdmin()){
              axios.get("api/product").then(({ data }) => (this.products = data.data));
            // }
          },
          loadCategories(){
              axios.get("/api/category/list").then(({ data }) => (this.categories = data.data));
          },
          loadTags(){
              axios.get("/api/tag/list").then(response => {
                  this.autocompleteItems = response.data.data.map(a => {
                      return { text: a.name, id: a.id };
                  });
              }).catch(() => console.warn('Oh. Something went wrong'));
          },
          editModal(product){
              this.editmode = true;
              this.form.reset();
              $('#addNew').modal('show');
              this.form.fill(product);
          },
          newModal(){
              this.editmode = false;
              this.form.reset();
              $('#addNew').modal('show');
          },
          createProduct(){
              this.$Progress.start();

              this.form.post('api/product')
              .then((data)=>{
                if(data.data.success){
                  $('#addNew').modal('hide');

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.$Progress.finish();
                  this.loadProducts();

                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          },
          updateProduct(){
              this.$Progress.start();
              this.form.put('api/product/'+this.form.id)
              .then((response) => {
                  // success
                  $('#addNew').modal('hide');
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });
                  this.$Progress.finish();
                      //  Fire.$emit('AfterCreate');

                  this.loadProducts();
              })
              .catch(() => {
                  this.$Progress.fail();
              });

          },
          deletModal(id){
              Swal.fire({
                  title: 'Attention !',
                  text: "vous voulez vraiment supprimer ce devis",
                  showCancelButton: true,
                  confirmButtonColor: '#67c23a',
                  cancelButtonColor: '#f56c6c',
                  confirmButtonText: 'Oui, supprimez-le ! '
                  }).then((result) => {

                      // Send request to the server
                        if (result.value) {
                              this.form.delete('api/product/'+id).then(()=>{
                                      Swal.fire(
                                      'Supprimé !',
                                      'Votre fichier a été supprimé.',
                                      'success'
                                      );
                                  // Fire.$emit('AfterCreate');
                                  this.loadProducts();
                              }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                        }
                  })
          },
        
         handleCurrentChange(cpage) {
                this.currpage = cpage;
            },
        handleSizeChange(psize) {
                this.pagesize = psize;
            },


        },
        mounted() {
            
        },
        created() {
            this.$Progress.start();

            this.loadProducts();
            this.loadCategories();
            this.loadTags();

            this.$Progress.finish();
        },
        filters: {
            truncate: function (text, length, suffix) {
                return text.substring(0, length) + suffix;
            },
        },
        computed: {
          filteredItems() {
            return this.autocompleteItems.filter(i => {
              return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
            });
          },
        },
    }
</script>
