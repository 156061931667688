<template>
    <section class="content">
        <!-- {{ Reservations }} -->
      <div class="container-fluid">
        <el-card shadow="always">
            <div class="row">
                <!-- <div class="col-lg-3 col-sm-12 mt-3">
                    <el-select @change="filterPlateform" v-model="searchPlateform" filterable  style="width: 100%" placeholder="Select Plateforme">
                        <el-option
                            v-for="item in Plateformes"
                            :key="item.id"
                            :label="item.title"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </div> -->
                
                
                <!-- <div class="col-lg-3 col-sm-12 mt-3">
                    <el-date-picker placeholder="Filter By Date" @change="filterDate" v-model="searchDate" value-format="yyyy-MM-dd" style="width: 100%;"></el-date-picker>
                </div> -->
                <div class="col-lg-3 col-sm-12 mt-3">
                    <el-input v-model="search" @change="filterClient" placeholder="Filter By Client's Name"> 
                        <el-button slot="append" icon="el-icon-search"></el-button>
                    </el-input>
                </div>
                <div class="col-lg-6 col-sm-12 mt-3">
                    <el-date-picker
                        style="width: 100%;"
                        v-model="searchDateRange"
                        @change="filterDateRenge"
                        type="daterange"
                        value-format="yyyy-MM-dd"
                        range-separator="To"
                        start-placeholder="Start date"
                        end-placeholder="End date">
                    </el-date-picker>
                </div> 
                <div class="col-lg-3 col-sm-12 mt-3">
                    <el-select @change="filterProduct" v-model="searchProduct" filterable  style="width: 100%" placeholder="Select Product">
                        <el-option
                            v-for="item in Tours"
                            :key="item.id"
                            :label="item.title"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </div>
                
            </div>
        </el-card>

        <!-- Start Table -->
        <el-card class="mb-3 mt-3">
          <div class="row">
          <div class="col-6">
            <el-button type="primary" @click="newDevis()" size="small" icon="el-icon-plus" >Add New Reservation</el-button>
          </div>
            <!-- <div class="col-6">
                
            </div> -->
            <div class="col-12 mt-3">
              <el-table
                    :data="Reservations.data" border >
                    <el-table-column type="expand">
                    <template slot-scope="props">
                        <el-descriptions class="margin-top" :column="4" border>
                            <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-link"></i>
                                Reference
                            </template>
                                <el-tag effect="dark">{{ props.row.reference}}</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-mobile-phone"></i>
                                Telephone
                            </template>
                                <el-tag effect="dark">{{ props.row.phone}}</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-chat-dot-round"></i>
                                Langue
                            </template>
                                <el-tag effect="dark">{{ props.row.langue}}</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-user-solid"></i>
                                Adults
                            </template>
                                <el-tag effect="dark">{{ props.row.nombreAdulte}}</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <i class="el-icon-user-solid"></i>
                                    Children
                                </template>
                                <el-tag effect="dark">{{ props.row.nombreChildren}}</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <i class="el-icon-office-building"></i>
                                    Plateforme
                                </template>
                                <el-tag effect="dark">{{ props.row.get_plateformes.title}}</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <i class="el-icon-money"></i>
                                    Price
                                </template>
                                <el-tag effect="dark">{{ props.row.price}} {{ props.row.currency }}</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <i class="el-icon-money"></i>
                                    Net Price
                                </template>
                                <el-tag effect="dark">{{ props.row.priceNet}} {{ props.row.currency }}</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item v-if="props.row.machineExiste == 'OUI'">
                                    <template slot="label">
                                        <i class="el-icon-money"></i>
                                        Duration
                                    </template>
                                    <el-tag  type="" effect="dark" >{{ props.row.duration }} Heur(s)</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item v-if="props.row.machineExiste == 'OUI'">
                                    <template slot="label">
                                        <i class="el-icon-money"></i>
                                        Number Of Machines
                                    </template>
                                    <el-tag  type="" effect="dark" >{{ props.row.nbr_bikes }}</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item v-if="props.row.machineExiste == 'OUI'">
                                    <template slot="label">
                                        <i class="el-icon-money"></i>
                                        Fournisseur
                                    </template>
                                    <el-tag  type="" effect="dark" >{{ props.row.get_fournisseur.name }} {{ props.row.get_fournisseur.adress }}</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-tickets"></i>
                                Note
                            </template>
                            <strong>{{ props.row.note }}</strong>
                            </el-descriptions-item>
                        </el-descriptions>
                    </template>
                    </el-table-column>
                    
                    <el-table-column label="Nom Client">
                        <template slot-scope="scope">
                            <strong>{{ scope.row.NomClient }} ({{ scope.row.nombreTotalPer }})</strong>
                        </template>
                    </el-table-column>
                    <el-table-column label="Pick Up">
                        <template slot-scope="scope">
                            <strong>{{ scope.row.id_pickup }}</strong>
                        </template>
                    </el-table-column>
                    <el-table-column label="Product">
                        <template slot-scope="scope">
                            <strong>{{ scope.row.get_product.title }}</strong>
                        </template>
                    </el-table-column>
                    <el-table-column label="Date" width="150" align="center">
                        <template slot-scope="scope">
                            <strong>{{ scope.row.dateJ | OfferDates }} <br> {{ scope.row.timeJ }} H</strong>
                        </template>
                    </el-table-column>
                    <el-table-column label="Status" width="100" align="center">
                        <template slot-scope="scope">
                            <div v-if="scope.row.etat == 'Processing'">
                                <el-tag  type="" effect="dark" ><strong>{{ scope.row.etat }}</strong></el-tag>
                            </div>
                            <div v-if="scope.row.etat == 'Refunded'">
                                <el-tag  type="danger" effect="dark" ><strong>{{ scope.row.etat }}</strong></el-tag>
                            </div>
                            <div v-if="scope.row.etat == 'Cancelled'">
                                <el-tag  type="warning" effect="dark" ><strong>{{ scope.row.etat }}</strong></el-tag>
                            </div>
                            <div v-if="scope.row.etat == 'Completed'">
                                <el-tag  type="success" effect="dark" ><strong>{{ scope.row.etat }}</strong></el-tag>
                            </div>
                        </template>
                    </el-table-column>
                    
                    <el-table-column width="100" align="center">
                        <template slot-scope="{row}">
                                <a href="#" @click="updateReservation(row)" data-toggle="tooltip" data-placement="top" title="Modifier">
                                        <i class="fa fa-edit blue mr-2"></i>
                                </a>
                                <el-divider direction="vertical"></el-divider>
                                <a href="#" @click="deletData(row.id)" data-toggle="tooltip" data-placement="top" title="Supprimer">
                                    <i class="fa fa-trash red"></i>
                                </a>
                        </template>
                    </el-table-column>
              </el-table>
                
               <pagination class="mt-4 center" :limit="10" :keep-length="10" :data="Reservations" @pagination-change-page="loadData"></pagination>
            </div>
            
          </div>
          </el-card>
        <!-- End Table -->
          
          <!-- dialog -->
          <el-dialog
            :title="editmode ? 'Update Reservation':'New Reservation'"
            :visible.sync="DialogVisible_global"
            width="90%"
            :before-close="handleClose">
            <!-- Body dialog -->
                <el-form :model="form_global" ref="form_global" class="demo-ruleForm">
                    <fieldset class="row col-12 border p-2 mt-2">
                        <legend  class="w-auto badge"><h5>Booking Infos</h5></legend>
                            <el-form-item class="col-lg-4" label="Booking Reference" prop="reference" 
                            :rules="[
                                { required: true, message: 'Booking Reference is required' }
                            ]">
                                <el-input v-model="form_global.reference" autocomplete="off"></el-input>
                            </el-form-item>
                            <el-form-item class="col-lg-4" label="NOM CLIENT" prop="NomClient" 
                            :rules="[
                                { required: true, message: 'Nom Client is required' }
                            ]">
                                <el-input v-model="form_global.NomClient" autocomplete="off"></el-input>
                            </el-form-item>
                            <el-form-item class="col-lg-4" label="Platform" prop="id_platform" 
                            :rules="[
                                { required: true, message: 'Platform is required' }
                            ]">
                                <el-select v-model="form_global.id_platform" @change="PlateformChange()"  filterable  style="width: 100%" placeholder="Select Plateforme">
                                    <el-option
                                        v-for="item in Plateformes"
                                        :key="item.id"
                                        :label="item.title"
                                        :value="item.id"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                            <!-- Price -->
                            <el-form-item class="col-lg-4" label="Price" prop="price" 
                                :rules="[{ required: true, message: 'Price is required' }]">
                                <el-input type="number"  @change ="PriceChange" v-model="form_global.price" autocomplete="off">
                                    <template slot="append"><strong>{{ form_global.currency }}</strong></template>
                                </el-input>
                            </el-form-item>
                            <el-form-item  class="col-lg-4" label="Commission" prop="commission" 
                                :rules="[{ required: true, message: 'Commission is required' }]">
                                <el-input :disabled="true" v-model="form_global.commission" autocomplete="off">
                                    <template slot="append"><strong>%</strong></template>
                                </el-input>
                            </el-form-item>
                            <el-form-item  class="col-lg-4" label="Net Price" prop="priceNet" 
                                :rules="[{ required: true, message: 'Price Net is required' }]">
                                <el-input :disabled="true" v-model="form_global.priceNet" autocomplete="off">
                                    <template slot="append"><strong>{{ form_global.currency }}</strong></template>
                                </el-input>
                            </el-form-item>
                            
                            <!-- End Price -->
                            <el-form-item class="col-lg-6" label="Hotel / Pickup" prop="id_pickup" 
                                :rules="[
                                    { required: true, message: 'Pick Up Place is required' }
                                ]">
                                <el-input v-model="form_global.id_pickup" autocomplete="off"></el-input>
                            </el-form-item>
                            <el-form-item class="col-lg-6" label="Excurtion" prop="id_product" 
                                :rules="[
                                    { required: true, message: 'Titre is required' }
                                ]">
                                <el-select v-model="form_global.id_product" filterable  style="width: 100%" placeholder="Select Product">
                                    <el-option
                                        v-for="item in Tours"
                                        :key="item.id"
                                        :label="item.title"
                                        :value="item.id"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                            <!--  -->
                            <el-form-item class="col-lg-3" label="Nombre Adulte" prop="nombreAdulte" 
                            :rules="[
                                { required: true, message: 'Nombre of adults is required' },
                            ]">
                                <el-input type="number" v-model="form_global.nombreAdulte" autocomplete="off"></el-input>
                            </el-form-item>
                            <el-form-item class="col-lg-3" label="Nombre Children" prop="nombreChildren" 
                            :rules="[
                                { required: true, message: 'Nombre of Children is required' },
                            ]">
                                <el-input type="number" v-model="form_global.nombreChildren" autocomplete="off"></el-input>
                            </el-form-item>
                            <el-form-item class="col-lg-3" label="Date Sortie" prop="dateJ" >
                                <el-date-picker placeholder="Pick a date" v-model="form_global.dateJ" value-format="yyyy-MM-dd" style="width: 100%;"></el-date-picker>
                            </el-form-item>
                            
                            <el-form-item class="col-lg-3" label="Time Sortie" prop="timeJ"
                                :rules="[
                                    { required: true, message: 'Please pick a Time', trigger: 'change' }
                                ]">
                                <el-time-select v-model="form_global.timeJ" :picker-options="{ start: '07:00', step: '00:10', end: '23:30' }"
                                    placeholder="Select time" style="width: 100%;">
                                </el-time-select>
                            </el-form-item>

                            <!-- <el-form-item 
                                class="col-lg-3"
                                prop="machineExiste"
                                label="Machines Exist ?"
                                :rules="[
                                    { required: true, message: 'This Option is required' }
                                ]"
                            >
                                <el-radio-group @change="MachineCHanger" v-model="form_global.machineExiste">
                                    <el-radio label="OUI" ></el-radio>
                                    <el-radio label="NON" ></el-radio>
                                </el-radio-group>
                            </el-form-item> -->
                            <el-form-item v-if="form_global.machineExiste == 'OUI'" class="col-lg-3" label="Number Of Machines" prop="nbr_bikes"
                                :rules="[
                                    { required: true, message: 'Number Of Machines is required' }
                                ]">
                                <el-input type="text" v-model="form_global.nbr_bikes" autocomplete="off"></el-input>
                            </el-form-item>
                            <el-form-item v-if="form_global.machineExiste == 'OUI'" class="col-lg-3" label="Fournisseur" prop="id_fournisseur"
                                :rules="[
                                    { required: true, message: 'Fournisseur is required' }
                                ]">
                                <el-select v-model="form_global.id_fournisseur" filterable  style="width: 100%" placeholder="Select Fournisseur">
                                    <el-option
                                        v-for="item in Fournisseurs"
                                        :key="item.id"
                                        :label="item.brandName+' - '+item.name+' - '+item.adress"
                                        :value="item.id"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item v-if="form_global.machineExiste == 'OUI'" class="col-lg-3" label="Duration of Activity" prop="timeJ"
                                :rules="[
                                    { required: true, message: 'Please pick a Duration of Activity', trigger: 'change' }
                                ]">
                                <el-select v-model="form_global.duration" style="width: 100%" placeholder="Select Duration">
                                        <el-option label="1 Hour" value="1"></el-option>
                                        <el-option label="2 Hour(s)" value="2"></el-option>
                                        <el-option label="3 Hour(s)" value="3"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item class="col-lg-3" label="Num Téléphone" prop="phone" 
                            :rules="[
                                { required: true, message: 'Phone number is required' }
                            ]">
                                <el-input type="text" v-model="form_global.phone" autocomplete="off"></el-input>
                            </el-form-item>
                            <el-form-item class="col-lg-3" label="Tour Language" prop="langue" 
                            :rules="[
                                { required: true, message: 'Langue is required' }
                            ]">
                                <el-select v-model="form_global.langue" style="width: 100%" placeholder="Select Langue">
                                        <el-option label="English" value="English"></el-option>
                                        <el-option label="Spanish" value="Spanish"></el-option>
                                        <el-option label="French" value="French"></el-option>
                                        <el-option label="Germany" value="Germany"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item class="col-lg-3" label="Status" prop="etat" 
                            :rules="[
                                { required: true, message: 'Etat is required' }
                            ]">
                                <el-select v-model="form_global.etat" style="width: 100%" placeholder="Select Status">
                                        <el-option label="Processing" value="Processing"></el-option>
                                        <el-option label="Completed" value="Completed"></el-option>
                                        <el-option label="Cancelled" value="Cancelled"></el-option>
                                        <el-option label="Refunded" value="Refunded"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item class="col-lg-12" label="Note" prop="note" >
                                <el-input type="textarea" v-model="form_global.note" autocomplete="off"></el-input>
                            </el-form-item>
                    </fieldset>
                </el-form>
                <!-- ENd body Dialog -->
                <span slot="footer" class="dialog-footer">
                    <el-button @click="DialogVisible_global = false">Annuler</el-button>
                    <el-button type="primary" v-show="!editmode" @click="submitForm('form_global')">Valider</el-button>
                    <el-button type="primary" v-show="editmode" @click="updateData()">Valider</el-button>
                </span>
          </el-dialog>

          <!-- ENd dialog -->
  
      </div>
     
    </section>
    <!--  -->
  </template>
  
  
  <script>
      import VueTagsInput from '@johmun/vue-tags-input';
      import moment from 'moment'
  
      export default {
        components: {
            VueTagsInput,
          },
          data () {
              return {
                  DialogVisible_global: false,
                  active: 0,
                  Priceactive: false,
                  Reservations: [],
                  Tours: [],
                  Hotels: [],
                  Fournisseurs: [],
                  Plateformes: [],
                  search: '',
                  searchDateRange: null,
                  DateRange: '',
                  searchProduct: '',
                  searchPlateform: '',
                  pagesize: 10,
                  currpage: 1,
                  editmode: false,
                  products : [],

                  numberValidateForm: {
                    age: ''
                },
                
                  form_global: new Form({
                    duration:'',
                    machineExiste:'',
                    id:'',
                    NomClient:'',
                    id_platform:'',
                    id_fournisseur:'',
                    etat:'',
                    id_pickup:'',
                    id_product:'',
                    nbr_bikes:'',
                    nombreAdulte:'',
                    nombreChildren:'',
                    nombreTotalPer:'',
                    dateJ:'',
                    timeJ:'',
                    dateReserve:'',
                    phone:'',
                    note:'',
                    langue:'',
                    price:'',
                    priceNet:'',
                    currency:'',
                    commission:'',
                    reference:''
                  }),

                  form: new Form({
                      id : '',
                      category : '',
                      name: '',
                      description: '',
                      tags:  [],
                      photo: '',
                      category_id: '',
                      price: '',
                      photoUrl: '',
                  }),
                  categories: [],
                
                  tag:  '',
                  autocompleteItems: [],
              }
          },
          methods: {    
            loadData(page = 1){
                this.$Progress.start();
                axios.get('api/reservation?page=' + page).then(({ data }) => (this.Reservations = data.data));
                this.$Progress.finish();
            },        

            filterDate(){
                if(this.searchDate == null){
                    this.loadData();
                }else{
                    this.$Progress.start();
                    axios.get('api/filterDate/' + this.searchDate).then(({ data }) => (this.Reservations = data.data));
                    this.$Progress.finish();
                }
            },

            filterDateRenge(){
                if(this.searchDateRange == null){
                    this.loadData();
                }else{
                    
                    this.Date1 = this.searchDateRange[0]
                    this.Date2 = this.searchDateRange[1]

                    this.$Progress.start();
                    axios.get('api/filterDateRange/'+this.Date1+'/'+this.Date2).then(({ data }) => (this.Reservations = data.data));
                    // axios.get('api/RapProdPersoByWhats/'+this.Date1+'/'+this.Date2).then(({ data }) => (this.RapProductPersonWhats = data.data));
                    this.$Progress.finish();
                }
            },

            filterClient(){
                if(this.search != ""){
                    this.$Progress.start();
                    axios.get('api/filterClient/' + this.search).then(({ data }) => (this.Reservations = data.data));
                    this.$Progress.finish();
                    
                }else{
                    this.loadData();
                }
            },

            filterProduct(){
                if(this.searchProduct != null){
                    console.log("product selected :" + this.searchProduct)
                    if(this.searchDateRange != null){
                        console.log("Date range selected : "+ this.searchDateRange)
                        this.Date1 = this.searchDateRange[0]
                        this.Date2 = this.searchDateRange[1]
                        this.$Progress.start();
                            axios.get('api/filterProductByRangeDate/'+this.Date1+'/'+this.Date2+'/'+ this.searchProduct).then(({ data }) => (this.Reservations = data.data));
                        this.$Progress.finish();
                    }
                    else if(this.searchDateRange == null){
                        console.log("Date range not selected")
                        this.$Progress.start();
                            axios.get('api/filterProduct/' + this.searchProduct).then(({ data }) => (this.Reservations = data.data));
                        this.$Progress.finish();
                    }
                }else{
                    this.loadData();
                }
            },

            filterPlateform(){
                if(this.searchPlateform != ""){
                    this.$Progress.start();
                    axios.get('api/filterPlateform/' + this.searchPlateform).then(({ data }) => (this.Reservations = data.data));
                    this.$Progress.finish();
                }else{
                    this.loadData();
                }
            },
            // filterProduct(){
            //     if(this.searchProduct != ""){
            //         this.$Progress.start();
            //         axios.get('api/filterProduct/' + this.searchProduct).then(({ data }) => (this.Reservations = data.data));
            //         this.$Progress.finish();
            //     }else{
            //         this.loadData();
            //     }
            // },

            PriceChange(){
                this.form_global.priceNet = this.form_global.price-(this.form_global.commission*this.form_global.price/100)
            },

            MachineCHanger(){
                if(this.form_global.machineExiste == 'NON')
                this.form_global.duration = 0;
                this.form_global.nbr_bikes = '';
                this.form_global.id_fournisseur = '';
            },

            PlateformChange(){

                this.Plateformes.currency=''
                this.Plateformes.commission=''
               
                for (let i = 0; i < this.Plateformes.length; i++) {
                    
                    if(this.Plateformes[i].id == this.form_global.id_platform)
                        {
                            this.form_global.currency = this.Plateformes[i].currency
                            this.form_global.commission = this.Plateformes[i].commission
                        }
                    }

                this.PriceChange();

            },
            // CRUD
            newDevis(){
                this.form_global.reset();
                this.editmode = false;
                // this.disableDevisPV = false;
                this.DialogVisible_global = true;
                this.active=0;
            },

            createData(){
            this.$Progress.start();

              this.form_global.post('api/reservation')
              .then((data)=>{
                if(data.data.success){
                 this.DialogVisible_global = false

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.$Progress.finish();
                  this.loadData();
                //   this.loadDataPrepared();

                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          },

          submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.createData();
                } else {
                    console.log('error submit!!');
                    return false;
                }
                });
            },

            loadTours(){
                this.$Progress.start();
                axios.get('api/indexTours').then(({ data }) => (this.Tours = data.data));
                this.$Progress.finish();
            },

            loadHotels(){
                this.$Progress.start();
                axios.get('api/indexHotels').then(({ data }) => (this.Hotels = data.data));
                this.$Progress.finish();
            },
            
            loadFournisseurs(){
                this.$Progress.start();
                axios.get('api/indexFournisseur').then(({ data }) => (this.Fournisseurs = data.data));
                this.$Progress.finish();
            },
            loadPlateformes(){
                this.$Progress.start();
                axios.get('api/indexPlateformes').then(({ data }) => (this.Plateformes = data.data));
                this.$Progress.finish();
            },

            updateReservation(reservation){
                // console.log(reservation);
                this.editmode = true;
                this.form_global.reset();
                this.form_global.fill(reservation);
                this.DialogVisible_global = true;
                // console.log(this.form_global);
            },

            updateData(){
              this.$Progress.start();
              var total = (this.form_global.nombreChildren + this.form_global.nombreAdulte)
              this.form_global.nombreTotalPer = total
              this.form_global.put('api/reservation/'+this.form_global.id)
              .then((response) => {
                  // success
                  this.DialogVisible_global = false
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });
                  this.$Progress.finish();

                  this.loadData();
              })
              .catch(() => {
                  this.$Progress.fail();
              });

          },

          deletData(id){
              Swal.fire({
                  title: 'Attention !',
                  text: "You really want to delete this Reservation ??",
                  showCancelButton: true,
                  confirmButtonColor: '#67c23a',
                  cancelButtonColor: '#f56c6c',
                  confirmButtonText: 'Yes, Delete-it ! '
                  }).then((result) => {

                      // Send request to the server
                        if (result.value) {
                              this.form_global.delete('api/reservation/'+id).then(()=>{
                                      Swal.fire(
                                      'Deleted !',
                                      'Reservation Delete Successfully',
                                      'success'
                                      );
                                  // Fire.$emit('AfterCreate');
                                  this.loadData();
                              }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                        }
                  })
          },
        //   End CRUD

            handleClose(done) {
                this.$confirm('Voulez-vous vraiment fermer cette boîte de dialogue ?')
                .then(_ => {
                    done();
                })
                .catch(_ => {});
            },

            handleCurrentChange2(val) {
                this.currentRow = val;
            },

            // END
  
           
            loadProducts(){
  
              // if(this.$gate.isAdmin()){
                axios.get("api/product").then(({ data }) => (this.products = data.data));
              // }
            },
            loadCategories(){
                axios.get("/api/category/list").then(({ data }) => (this.categories = data.data));
            },
            loadTags(){
                axios.get("/api/tag/list").then(response => {
                    this.autocompleteItems = response.data.data.map(a => {
                        return { text: a.name, id: a.id };
                    });
                }).catch(() => console.warn('Oh. Something went wrong'));
            },
            editModal(product){
                this.editmode = true;
                this.form.reset();
                $('#addNew').modal('show');
                this.form.fill(product);
            },
            newModal(){
                this.editmode = false;
                this.form.reset();
                $('#addNew').modal('show');
            },
            createProduct(){
                this.$Progress.start();
  
                this.form.post('api/product')
                .then((data)=>{
                  if(data.data.success){
                    $('#addNew').modal('hide');
  
                    Toast.fire({
                          icon: 'success',
                          title: data.data.message
                      });
                    this.$Progress.finish();
                    this.loadProducts();
  
                  } else {
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
  
                    this.$Progress.failed();
                  }
                })
                .catch(()=>{
  
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
                })
            },
            updateProduct(){
                this.$Progress.start();
                this.form.put('api/product/'+this.form.id)
                .then((response) => {
                    // success
                    $('#addNew').modal('hide');
                    Toast.fire({
                      icon: 'success',
                      title: response.data.message
                    });
                    this.$Progress.finish();
                        //  Fire.$emit('AfterCreate');
  
                    this.loadProducts();
                })
                .catch(() => {
                    this.$Progress.fail();
                });
  
            },
            deletModal(id){
                Swal.fire({
                    title: 'Attention !',
                    text: "vous voulez vraiment supprimer ce devis",
                    showCancelButton: true,
                    confirmButtonColor: '#67c23a',
                    cancelButtonColor: '#f56c6c',
                    confirmButtonText: 'Oui, supprimez-le ! '
                    }).then((result) => {
  
                        // Send request to the server
                          if (result.value) {
                                this.form.delete('api/product/'+id).then(()=>{
                                        Swal.fire(
                                        'Supprimé !',
                                        'Votre fichier a été supprimé.',
                                        'success'
                                        );
                                    // Fire.$emit('AfterCreate');
                                    this.loadProducts();
                                }).catch((data)=> {
                                    Swal.fire("Failed!", data.message, "warning");
                                });
                          }
                    })
            },
          
           handleCurrentChange(cpage) {
                  this.currpage = cpage;
              },
          handleSizeChange(psize) {
                  this.pagesize = psize;
              },
  
  
          },
          mounted() {
              
          },
          created() {
              this.$Progress.start();
  
            this.loadData();
            this.loadTours();
            //   this.loadHotels();
            this.loadFournisseurs();
            this.loadPlateformes();
  
              this.$Progress.finish();
          },
          filters: {
            OfferDates(value){
                return moment(String(value)).format('DD-MM-YYYY')
            },
              truncate: function (text, length, suffix) {
                  return text.substring(0, length) + suffix;
              },
          },
          computed: {
            filteredItems() {
              return this.autocompleteItems.filter(i => {
                return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
              });
            },
          },
      }
  </script>
  