<template>
    <section class="content">
        <!-- {{ ReservationsToday }} -->
        <div class="row row-cols-1 row-cols-md-2 row-cols-xl-4">
       <div class="col">
		 <div class="card radius-10 border-start border-0 border-3 border-info">
			<div class="card-body">
				<div class="d-flex align-items-center">
					<div>
						<p class="mb-0 text-secondary">Total Booking</p>
						<h4 class="my-1 text-info">{{ CountBooking.countTotal }}</h4>
                        <p class="mb-0 font-13 text-info">100 % </p>
					</div>
					<div class="widgets-icons-2 rounded-circle bg-gradient-scooter text-white ms-auto"><i class="fa fa-calendar-check"></i>
					</div>
				</div>
			</div>
		 </div>
	   </div>
	   <div class="col">
		<div class="card radius-10 border-start border-0 border-3 border-warning">
		   <div class="card-body">
			   <div class="d-flex align-items-center">
				   <div>
					   <p class="mb-0 text-secondary">Total Processing</p>
					   <h4 class="my-1 text-orange">{{ CountBooking.countProcessing }}</h4>
					   <p class="mb-0 font-13 text-orange">{{(CountBooking.countProcessing*100 /CountBooking.countTotal) | FormNumbers2}} %</p>
				   </div>
				   <div class="widgets-icons-2 rounded-circle bg-gradient-blooker text-white ms-auto"><i class="fa fa-cogs"></i>
				   </div>
			   </div>
		   </div>
		</div>
	  </div>
	  <div class="col">
		<div class="card radius-10 border-start border-0 border-3 border-success">
		   <div class="card-body">
			   <div class="d-flex align-items-center">
				   <div>
					   <p class="mb-0 text-secondary">Total Completed</p>
					   <h4 class="my-1 text-success">{{ CountBooking.countCompleted }}</h4>
                       <p class="mb-0 font-13 text-success">{{(CountBooking.countCompleted*100 /CountBooking.countTotal) | FormNumbers2}} %</p>
				   </div>
				   <div class="widgets-icons-2 rounded-circle bg-gradient-ohhappiness text-white ms-auto"><i class="fa fa-check-square"></i>
				   </div>
			   </div>
		   </div>
		</div>
	  </div>
	  <div class="col">
		<div class="card radius-10 border-start border-0 border-3 border-danger">
		   <div class="card-body">
			   <div class="d-flex align-items-center">
				   <div>
					   <p class="mb-0 text-secondary">Total Cancelled</p>
					   <h4 class="my-1 text-danger">{{ CountBooking.countCancelled }}</h4>
					   <p class="mb-0 font-13 text-danger">{{(CountBooking.countCancelled*100 /CountBooking.countTotal) | FormNumbers2}} %</p>
				   </div>
				   <div class="widgets-icons-2 rounded-circle bg-gradient-bloody text-white ms-auto"><i class="fa fa-ban"></i>
				   </div>
			   </div>
		   </div>
		</div>
	  </div> 
	    </div>
      <div class="container-fluid">
        <!-- <el-card shadow="always">
            <div class="row">
                <div class="col-lg-3 col-sm-12 mt-3">
                    <el-select @change="filterPlateform" v-model="searchPlateform" filterable  style="width: 100%" placeholder="Select Plateforme">
                        <el-option
                            v-for="item in Plateformes"
                            :key="item.id"
                            :label="item.title"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </div>
                <div class="col-lg-3 col-sm-12 mt-3">
                    <el-select @change="filterProduct" v-model="searchProduct" filterable  style="width: 100%" placeholder="Select Product">
                        <el-option
                            v-for="item in Tours"
                            :key="item.id"
                            :label="item.title"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </div>
                <div class="col-lg-3 col-sm-12 mt-3">
                    <el-input v-model="search" @change="filterClient" placeholder="Filter By Client's Name"> 
                        <el-button slot="append" icon="el-icon-search"></el-button>
                    </el-input>
                </div>
                <div class="col-lg-3 col-sm-12 mt-3">
                    <el-date-picker placeholder="Filter By Date" @change="filterDate" v-model="searchDate" value-format="yyyy-MM-dd" style="width: 100%;"></el-date-picker>
                </div>
            </div>
        </el-card> -->
        <el-tabs>
        <el-tab-pane label="eSIM Dash">
        <!-- Start Table -->
            <el-card class="mb-3 mt-3">
          <div class="row">
            <div class="col-lg-6">
              <h4 class="ml-3">Today's eSIMs</h4>
            </div>
            <div class="col-12 mt-3">
              <el-table
                    :data="ReservationsToday" border >
                    <el-table-column type="expand">
                    <template slot-scope="props">
                        <el-descriptions class="margin-top" :column="4" border>
                            <el-descriptions-item>
                                <template slot="label">
                                    <i class="el-icon-mobile-phone"></i>
                                    Reference
                                </template>
                                <el-tag effect="dark">{{ props.row.reference}}</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <i class="el-icon-mobile-phone"></i>
                                    Activation Code
                                </template>
                                <el-tag effect="dark">{{ props.row.activation_code}}</el-tag>
                            </el-descriptions-item>
                           
                            <el-descriptions-item>
                                <template slot="label">
                                    <i class="el-icon-user-solid"></i>
                                    Qtit eSIM
                                </template>
                                <el-tag effect="dark">{{ props.row.nombreAdulte}}</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <i class="el-icon-office-building"></i>
                                    Plateforme
                                </template>
                                <el-tag effect="dark">{{ props.row.get_plateformes.title}}</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <i class="el-icon-money"></i>
                                    Price
                                </template>
                                <el-tag type="warning" effect="dark">{{ props.row.price}} {{ props.row.currency }}</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <i class="el-icon-money"></i>
                                    Net Price
                                </template>
                                <el-tag type="success" effect="dark">{{ props.row.priceNet}} {{ props.row.currency }}</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <i class="el-icon-money"></i>
                                    Prix Achat
                                </template>
                                <el-tag type="danger" effect="dark">{{ props.row.priceAchat}} {{ props.row.currencyAchat }}</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <i class="el-icon-tickets"></i>
                                    Note
                                </template>
                                <strong>{{ props.row.note }}</strong>
                            </el-descriptions-item>
                        </el-descriptions>
                    </template>
                    </el-table-column>
                    <el-table-column label="Nom Client">
                        <template slot-scope="scope">
                            <strong>{{ scope.row.NomClient }} ({{  scope.row.nombreAdulte  }})</strong>
                        </template>
                    </el-table-column>
                    <el-table-column label="Phone Number">
                        <template slot-scope="scope">
                            <el-tag effect="dark">{{ scope.row.phone}}</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="Product">
                        <template slot-scope="scope">
                            <strong>{{ scope.row.get_esim.title }} : {{ scope.row.optionGD }}</strong>
                        </template>
                    </el-table-column>
                    <el-table-column label="Date" width="150" align="center">
                        <template slot-scope="scope">
                            <strong>{{ scope.row.dateJ | OfferDates }}</strong>
                        </template>
                    </el-table-column>
                    <el-table-column label="Status" width="100" align="center">
                        <template slot-scope="scope">
                            <div v-if="scope.row.etat == 'Processing'">
                                <el-tag  type="" effect="dark" ><strong>{{ scope.row.etat }}</strong></el-tag>
                            </div>
                            <div v-if="scope.row.etat == 'Completed'">
                                <el-tag  type="success" effect="dark" ><strong>{{ scope.row.etat }}</strong></el-tag>
                            </div>
                        </template>
                    </el-table-column>
                    
                    <el-table-column width="100" align="center">
                        <template slot-scope="{row}">
                                <a href="#" @click="updateReservation(row)" data-toggle="tooltip" data-placement="top" title="Modifier">
                                        <i class="fa fa-edit blue mr-2"></i>
                                </a>
                                <!-- <el-divider direction="vertical"></el-divider>
                                <a href="#" @click="deletData(row.id)" data-toggle="tooltip" data-placement="top" title="Supprimer">
                                    <i class="fa fa-trash red"></i>
                                </a> -->
                        </template>
                    </el-table-column>
              </el-table>
                
               <!-- <pagination class="mt-4 center" :limit="10" :keep-length="10" :data="ReservationsToday" @pagination-change-page="loadData"></pagination> -->
            </div>
            
          </div>
          </el-card>
        <!-- End Table -->
        </el-tab-pane>
        <el-tab-pane label="eSIM Reservations">
        <!-- Start Table -->
            <el-card class="mb-3 mt-3">
          <div class="row">
          <div class="col-6">
            <el-button type="primary" @click="newDevis()" size="small" icon="el-icon-plus" >Add New Esim</el-button>
          </div>
            <!-- <div class="col-6">
                
            </div> -->
            <div class="col-12 mt-3">
              <el-table
                    :data="Reservations.data" border >
                    <el-table-column type="expand">
                    <template slot-scope="props">
                        <el-descriptions class="margin-top" :column="4" border>
                            <el-descriptions-item>
                                <template slot="label">
                                    <i class="el-icon-mobile-phone"></i>
                                    Reference
                                </template>
                                <el-tag effect="dark">{{ props.row.reference}}</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <i class="el-icon-mobile-phone"></i>
                                    Activation Code
                                </template>
                                <el-tag effect="dark">{{ props.row.activation_code}}</el-tag>
                            </el-descriptions-item>
                           
                            <el-descriptions-item>
                                <template slot="label">
                                    <i class="el-icon-user-solid"></i>
                                    Qtit eSIM
                                </template>
                                <el-tag effect="dark">{{ props.row.nombreAdulte}}</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <i class="el-icon-office-building"></i>
                                    Plateforme
                                </template>
                                <el-tag effect="dark">{{ props.row.get_plateformes.title}}</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <i class="el-icon-money"></i>
                                    Price
                                </template>
                                <el-tag type="warning" effect="dark">{{ props.row.price}} {{ props.row.currency }}</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <i class="el-icon-money"></i>
                                    Net Price
                                </template>
                                <el-tag type="success" effect="dark">{{ props.row.priceNet}} {{ props.row.currency }}</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <i class="el-icon-money"></i>
                                    Prix Achat
                                </template>
                                <el-tag type="danger" effect="dark">{{ props.row.priceAchat}} {{ props.row.currencyAchat }}</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <i class="el-icon-tickets"></i>
                                    Note
                                </template>
                                <strong>{{ props.row.note }}</strong>
                            </el-descriptions-item>
                        </el-descriptions>
                    </template>
                    </el-table-column>
                    <el-table-column label="Nom Client">
                        <template slot-scope="scope">
                            <strong>{{ scope.row.NomClient }} ({{  scope.row.nombreAdulte }})</strong>
                        </template>
                    </el-table-column>
                    <el-table-column label="Phone Number">
                        <template slot-scope="scope">
                            <el-tag effect="dark">{{ scope.row.phone}}</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="Product">
                        <template slot-scope="scope">
                            <strong>{{ scope.row.get_esim.title }} : {{ scope.row.optionGD }}</strong>
                        </template>
                    </el-table-column>
                    <el-table-column label="Date" width="150" align="center">
                        <template slot-scope="scope">
                            <strong>{{ scope.row.dateJ | OfferDates }}</strong>
                        </template>
                    </el-table-column>
                    <el-table-column label="Status" width="100" align="center">
                        <template slot-scope="scope">
                            <div v-if="scope.row.etat == 'Processing'">
                                <el-tag  type="" effect="dark" ><strong>{{ scope.row.etat }}</strong></el-tag>
                            </div>
                            <div v-if="scope.row.etat == 'Refunded'">
                                <el-tag  type="danger" effect="dark" ><strong>{{ scope.row.etat }}</strong></el-tag>
                            </div>
                            <div v-if="scope.row.etat == 'Cancelled'">
                                <el-tag  type="warning" effect="dark" ><strong>{{ scope.row.etat }}</strong></el-tag>
                            </div>
                            <div v-if="scope.row.etat == 'Completed'">
                                <el-tag  type="success" effect="dark" ><strong>{{ scope.row.etat }}</strong></el-tag>
                            </div>
                        </template>
                    </el-table-column>
                    
                    <el-table-column width="100" align="center">
                        <template slot-scope="{row}">
                                <a href="#" @click="updateReservation(row)" data-toggle="tooltip" data-placement="top" title="Modifier">
                                        <i class="fa fa-edit blue mr-2"></i>
                                </a>
                                <el-divider direction="vertical"></el-divider>
                                <a href="#" @click="deletData(row.id)" data-toggle="tooltip" data-placement="top" title="Supprimer">
                                    <i class="fa fa-trash red"></i>
                                </a>
                        </template>
                    </el-table-column>
              </el-table>
                
               <pagination class="mt-4 center" :limit="10" :keep-length="10" :data="Reservations" @pagination-change-page="loadData"></pagination>
            </div>
            
          </div>
          </el-card>
        <!-- End Table -->
        </el-tab-pane>
        <el-tab-pane label="eSIM Products">
            <el-card class="mb-3 mt-3">
            <div class="row">
                <div class="col-6">
                    <el-button type="primary" @click="newProduct()" size="small" icon="el-icon-plus" >Add New Product</el-button>
                </div>
                <div class="col-12 mt-3">
                    <div class="col-12 mt-3">
                        <el-table
                                :data="eSIMProduct.data" border >
                                <el-table-column label="eSIM Title">
                                    <template slot-scope="scope">
                                        <strong>{{ scope.row.title }}</strong>
                                    </template>
                                </el-table-column>
                               
                                <el-table-column width="250" label="Fournisseur">
                                    <template slot-scope="scope">
                                        <strong>{{ scope.row.Fournisseur }}</strong>
                                    </template>
                                </el-table-column>
                                <el-table-column width="250" label="Fournisseur Price">
                                    <template slot-scope="scope">
                                        <strong>{{ scope.row.buy_price }} {{ scope.row.currency }}</strong>
                                    </template>
                                </el-table-column>
                                
                                <el-table-column width="100" align="center">
                                    <template slot-scope="{row}">
                                            <a href="#" @click="updateProduct(row)" data-toggle="tooltip" data-placement="top" title="Modifier">
                                                    <i class="fa fa-edit blue mr-2"></i>
                                            </a>
                                            <el-divider direction="vertical"></el-divider>
                                            <a href="#" @click="deletDataProduct(row.id)" data-toggle="tooltip" data-placement="top" title="Supprimer">
                                                <i class="fa fa-trash red"></i>
                                            </a>
                                    </template>
                                </el-table-column>
                        </el-table>
                            
                        <pagination class="mt-4 center" :limit="10" :keep-length="10" :data="eSIMProduct" @pagination-change-page="loadDataProduct"></pagination>
                        </div>
                </div>
            </div>
            </el-card>
        </el-tab-pane>
        </el-tabs>
          <!-- dialog Product -->
          <el-dialog
            :title="editmode ? 'Update Product':'New Product'"
            :visible.sync="DialogVisible_esim_Product"
            width="70%"
            :before-close="handleClose">
            <!-- Body dialog -->
                <el-form :model="form_global_Product" ref="form_global_Product" class="demo-ruleForm">
                    <fieldset class="row col-12 border p-2 mt-2">
                        <legend  class="w-auto badge"><h5>Product Infos</h5></legend>
                            <el-form-item class="col-lg-12" label="Title" prop="title" 
                            :rules="[
                                { required: true, message: 'Product Title is required' }
                            ]">
                                <el-input v-model="form_global_Product.title" autocomplete="off"></el-input>
                            </el-form-item>
                            <el-form-item class="col-lg-4" label="Fournisseur" prop="Fournisseur" 
                                :rules="[{ required: true, message: 'Fournisseur is required' }]">
                                <el-select v-model="form_global_Product.Fournisseur" style="width: 100%" placeholder="Select a Currency">
                                        <el-option label="Numero eSIM" value="Numero eSIM"></el-option>
                                        <el-option label="eSIM Access" value="eSIM Access"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item class="col-lg-4" label="Price Fournisseur" prop="buy_price" 
                                :rules="[{ required: true, message: 'Price Fournisseur is required' }]">
                                <el-input type="number" v-model="form_global_Product.buy_price" autocomplete="off"></el-input>
                            </el-form-item>
                            <el-form-item class="col-lg-4" label="Currency" prop="currency" 
                                :rules="[
                                    { required: true, message: 'Currency is required' }
                                ]">
                                <el-select v-model="form_global_Product.currency" style="width: 100%" placeholder="Select a Currency">
                                        <el-option label="MAD" value="MAD"></el-option>
                                        <el-option label="$" value="$"></el-option>
                                        <el-option label="€" value="€"></el-option>
                                        <el-option label="£" value="£"></el-option>
                                </el-select>
                                <!-- <el-input type="text" v-model="form_global.currency" autocomplete="off"></el-input> -->
                            </el-form-item>
                    </fieldset>
                </el-form>
                <!-- ENd body Dialog -->
                <span slot="footer" class="dialog-footer">
                    <el-button @click="DialogVisible_esim_Product = false">Annuler</el-button>
                    <el-button type="primary" v-show="!editmode" @click="submitFormProduct('form_global_Product')">Valider</el-button>
                    <el-button type="primary" v-show="editmode" @click="updateDataProduct()">Valider</el-button>
                </span>
          </el-dialog>

          <!-- ENd dialog Product-->
          <!-- dialog Booking -->
          <el-dialog
            :title="editmode ? 'Update Reservation':'New Reservation'"
            :visible.sync="DialogVisible_global"
            width="90%"
            :before-close="handleClose">
            <!-- Body dialog -->
                <el-form :model="form_global" ref="form_global" class="demo-ruleForm">
                    <fieldset class="row col-12 border p-2 mt-2">
                        <legend  class="w-auto badge"><h5>Booking Infos</h5></legend>
                            <el-form-item class="col-lg-3" label="Booking Reference" prop="reference" 
                            :rules="[
                                { required: true, message: 'Booking Reference is required' }
                            ]">
                                <el-input v-model="form_global.reference" autocomplete="off"></el-input>
                            </el-form-item>
                            <el-form-item class="col-lg-3" label="NOM CLIENT" prop="NomClient" 
                            :rules="[
                                { required: true, message: 'Nom Client is required' }
                            ]">
                                <el-input v-model="form_global.NomClient" autocomplete="off"></el-input>
                            </el-form-item>
                            <el-form-item class="col-lg-3" label="Num Téléphone" prop="phone" 
                            :rules="[
                                { required: true, message: 'Phone number is required' }
                            ]">
                                <el-input type="text" v-model="form_global.phone" autocomplete="off"></el-input>
                            </el-form-item>
                            <el-form-item class="col-lg-3" label="Platform" prop="id_platform" 
                            :rules="[
                                { required: true, message: 'Platform is required' }
                            ]">
                                <el-select v-model="form_global.id_platform" @change="PlateformChange()"  filterable  style="width: 100%" placeholder="Select Plateforme">
                                    <el-option
                                        v-for="item in Plateformes"
                                        :key="item.id"
                                        :label="item.title"
                                        :value="item.id"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item class="col-lg-2" label="Price" prop="price" 
                                :rules="[{ required: true, message: 'Price is required' }]">
                                <el-input type="number"  @change ="PriceChange" v-model="form_global.price" autocomplete="off">
                                    <template slot="append"><strong>{{ form_global.currency }}</strong></template>
                                </el-input>
                            </el-form-item>
                            
                            <el-form-item  class="col-lg-2" label="Commission" prop="commission" 
                                :rules="[{ required: true, message: 'Commission is required' }]">
                                <el-input :disabled="true" v-model="form_global.commission" autocomplete="off">
                                    <template slot="append"><strong>%</strong></template>
                                </el-input>
                            </el-form-item>
                            <el-form-item  class="col-lg-2" label="Net Price" prop="priceNet" 
                                :rules="[{ required: true, message: 'Price Net is required' }]">
                                <el-input :disabled="true" v-model="form_global.priceNet" autocomplete="off">
                                    <template slot="append"><strong>{{ form_global.currency }}</strong></template>
                                </el-input>
                            </el-form-item>
                            <el-form-item class="col-lg-6" label="Product Name" prop="id_product" 
                                :rules="[
                                    { required: true, message: 'Product Name is Required' }
                                ]">
                                <el-select v-model="form_global.id_product" filterable @change="ProductChange()" style="width: 100%" placeholder="Select Product">
                                    <el-option
                                        v-for="item in Tours"
                                        :key="item.id"
                                        :label="item.title"
                                        :value="item.id"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item class="col-lg-3" label="Option" prop="optionGD" 
                            :rules="[
                                { required: true, message: 'Option is required' }
                            ]">
                                <el-select v-model="form_global.optionGD" style="width: 100%" placeholder="Select Option">
                                        <el-option label="1G - 7 Days" value="1G - 7 Days"></el-option>
                                        <el-option label="3G - 15 Days" value="3G - 15 Days"></el-option>
                                        <el-option label="5G - 30 Days" value="5G - 30 Days"></el-option>
                                        <el-option label="5G - 30 Days" value="5G - 30 Days"></el-option>
                                        <el-option label="10G - 30 Days" value="10G - 30 Days"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item class="col-lg-3" label="Price Achat" prop="priceAchat" 
                                :rules="[{ required: true, message: 'Price Achat is required' }]">
                                <el-input type="number" :disabled="true" v-model="form_global.priceAchat" autocomplete="off">
                                    <template slot="append"><strong>{{ form_global.currencyAchat }}</strong></template>
                                </el-input>
                            </el-form-item>
                            
                            <el-form-item class="col-lg-3" label="Qtit eSIM" prop="nombreAdulte" 
                                :rules="[
                                    { required: true, message: 'Nombre of esims is required' },
                                ]">
                                <el-input type="number" v-model="form_global.nombreAdulte" @change ="QtitChange" autocomplete="off"></el-input>
                            </el-form-item>
                            <el-form-item class="col-lg-3" label="Date" prop="dateJ" >
                                <el-date-picker placeholder="Pick a date" v-model="form_global.dateJ" value-format="yyyy-MM-dd" style="width: 100%;"></el-date-picker>
                            </el-form-item>
                            
                            <el-form-item class="col-lg-3" label="Status" prop="etat" 
                            :rules="[
                                { required: true, message: 'Etat is required' }
                            ]">
                                <el-select v-model="form_global.etat" style="width: 100%" placeholder="Select Status">
                                        <el-option label="Processing" value="Processing"></el-option>
                                        <el-option label="Completed" value="Completed"></el-option>
                                        <el-option label="Cancelled" value="Cancelled"></el-option>
                                        <el-option label="Refunded" value="Refunded"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item class="col-lg-3" label="Activation Code" prop="phone" 
                                :rules="[
                                    { required: true, message: 'Activation Code is required' }
                                ]">
                                <el-input type="text" v-model="form_global.activation_code" autocomplete="off"></el-input>
                            </el-form-item>
                            <el-form-item class="col-lg-12" label="Note" prop="note" >
                                <el-input type="textarea" v-model="form_global.note" autocomplete="off"></el-input>
                            </el-form-item>
                    </fieldset>
                </el-form>
                <!-- ENd body Dialog -->
                <span slot="footer" class="dialog-footer">
                    <el-button @click="DialogVisible_global = false">Annuler</el-button>
                    <el-button type="primary" v-show="!editmode" @click="submitForm('form_global')">Valider</el-button>
                    <el-button type="primary" v-show="editmode" @click="updateData()">Valider</el-button>
                </span>
          </el-dialog>

          <!-- ENd dialog -->
  
      </div>
     
    </section>
    <!--  -->
  </template>
  
  
  <script>
      import VueTagsInput from '@johmun/vue-tags-input';
      import moment from 'moment'
      import numeral from 'numeral'
  
      export default {
        components: {
            VueTagsInput,
          },
          data () {
              return {
                  DialogVisible_global: false,
                  DialogVisible_esim_Product: false,
                  active: 0,
                  Priceactive: false,
                  Reservations: [],
                  ReservationsToday: [],
                  eSIMProduct: [],
                  CountBooking: [],
                  Tours: [],
                  Hotels: [],
                  Fournisseurs: [],
                  Plateformes: [],
                  search: '',
                  searchDate: '',
                  searchProduct: '',
                  searchPlateform: '',
                  pagesize: 10,
                  currpage: 1,
                  editmode: false,
                  products : [],

                  numberValidateForm: {
                    age: ''
                },

                form_global_Product: new Form({

                    id:'',
                    title:'',
                    buy_price:'',
                    Fournisseur:'',
                    currency:''
                }),
                
                  form_global: new Form({
                    
                    id:'',
                    NomClient:'',
                    id_platform:'',
                    etat:'',
                    id_pickup:'',
                    id_product:'',
                    optionGD:'',
                    nombreAdulte:'',
                    dateJ:'',
                    activation_code:'',
                    priceAchat:'',
                    priceAchat1:'',
                    currencyAchat:'',
                    phone:'',
                    note:'',
                    langue:'',
                    price:'',
                    priceNet:'',
                    currency:'',
                    commission:'',
                    reference:''
                  }),

                  form: new Form({
                      id : '',
                      category : '',
                      name: '',
                      description: '',
                      tags:  [],
                      photo: '',
                      category_id: '',
                      price: '',
                      photoUrl: '',
                  }),
                  categories: [],
                
                  tag:  '',
                  autocompleteItems: [],
              }
          },
          methods: {    

            loadCalculs(){
                
                this.$Progress.start();
                    axios.get('api/DashCountEsim').then(({ data }) => (this.CountBooking = data.data));
                this.$Progress.finish();
            },

            loadData(page = 1){
                this.$Progress.start();
                axios.get('api/esim?page=' + page).then(({ data }) => (this.Reservations = data.data));
                this.$Progress.finish();
            }, 

            loadDataToday(){
                this.$Progress.start();
                axios.get('api/esimToday').then(({ data }) => (this.ReservationsToday = data.data));
                this.$Progress.finish();
            },  

            loadDataProduct(page = 1){
                this.$Progress.start();
                axios.get('api/esim_products?page=' + page).then(({ data }) => (this.eSIMProduct = data.data));
                this.$Progress.finish();
            },        

            filterDate(){
                if(this.searchDate == null){
                    this.loadData();
                }else{
                    this.$Progress.start();
                    axios.get('api/filterDate/' + this.searchDate).then(({ data }) => (this.Reservations = data.data));
                    this.$Progress.finish();
                }
            },

            filterClient(){
                if(this.search != ""){
                    this.$Progress.start();
                    axios.get('api/filterClient/' + this.search).then(({ data }) => (this.Reservations = data.data));
                    this.$Progress.finish();
                    
                }else{
                    this.loadData();
                }
            },

            filterProduct(){
                if(this.searchProduct != ""){
                    this.$Progress.start();
                    axios.get('api/filterProduct/' + this.searchProduct).then(({ data }) => (this.Reservations = data.data));
                    this.$Progress.finish();
                }else{
                    this.loadData();
                }
            },

            filterPlateform(){
                if(this.searchPlateform != ""){
                    this.$Progress.start();
                    axios.get('api/filterPlateform/' + this.searchPlateform).then(({ data }) => (this.Reservations = data.data));
                    this.$Progress.finish();
                }else{
                    this.loadData();
                }
            },
            // filterProduct(){
            //     if(this.searchProduct != ""){
            //         this.$Progress.start();
            //         axios.get('api/filterProduct/' + this.searchProduct).then(({ data }) => (this.Reservations = data.data));
            //         this.$Progress.finish();
            //     }else{
            //         this.loadData();
            //     }
            // },

            PriceChange(){
                this.form_global.priceNet = this.form_global.price-(this.form_global.commission*this.form_global.price/100)
            },
            
            QtitChange(){
                this.form_global.priceAchat = this.form_global.priceAchat1*this.form_global.nombreAdulte
            },

            MachineCHanger(){
                if(this.form_global.machineExiste == 'NON')
                this.form_global.duration = 0;
                this.form_global.nbr_bikes = '';
                this.form_global.id_fournisseur = '';
            },

            PlateformChange(){

                this.Plateformes.currency=''
                this.Plateformes.commission=''
               
                for (let i = 0; i < this.Plateformes.length; i++) {
                    
                    if(this.Plateformes[i].id == this.form_global.id_platform)
                        {
                            this.form_global.currency = this.Plateformes[i].currency
                            this.form_global.commission = this.Plateformes[i].commission
                        }
                    }

                this.PriceChange();

            },

            ProductChange(){

                // this.Plateformes.currency=''
                // this.Plateformes.commission=''
                for (let i = 0; i < this.Tours.length; i++) {
                    
                    if(this.Tours[i].id == this.form_global.id_product)
                        {
                            this.form_global.currencyAchat = this.Tours[i].currency
                            this.form_global.priceAchat1 = this.Tours[i].buy_price
                        }
                    }

                // this.PriceChange();

            },
            // CRUD
            newProduct(){
                this.form_global_Product.reset();
                this.editmode = false;
                // this.disableDevisPV = false;
                this.DialogVisible_esim_Product = true;
                this.active=0;
            },
            createDataProduct(){
            this.$Progress.start();

              this.form_global_Product.post('api/esim_products')
              .then((data)=>{
                if(data.data.success){
                 this.DialogVisible_esim_Product = false

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.$Progress.finish();
                  this.loadDataProduct();
                  this.loadCalculs();

                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          },
            submitFormProduct(formName) {
                this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.createDataProduct();
                    this.loadTours();
                } else {
                    console.log('error submit!!');
                    return false;
                }
                });
            },
            updateProduct(reservation){
                this.editmode = true;
                this.form_global_Product.reset();
                this.DialogVisible_esim_Product = true;
                this.form_global_Product.fill(reservation);
            },
            updateDataProduct(){
              this.$Progress.start();
              this.form_global_Product.put('api/esim_products/'+this.form_global_Product.id)
              .then((response) => {
                  // success
                  this.DialogVisible_esim_Product = false
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });
                  this.$Progress.finish();

                  this.loadDataProduct();
                  this.loadCalculs();
              })
              .catch(() => {
                  this.$Progress.fail();
              });

          },

          deletDataProduct(id){
              Swal.fire({
                  title: 'Attention !',
                  text: "You really want to delete this Product ??",
                  showCancelButton: true,
                  confirmButtonColor: '#67c23a',
                  cancelButtonColor: '#f56c6c',
                  confirmButtonText: 'Yes, Delete-it ! '
                  }).then((result) => {

                      // Send request to the server
                        if (result.value) {
                              this.form_global_Product.delete('api/esim_products/'+id).then(()=>{
                                      Swal.fire(
                                      'Deleted !',
                                      'Product Delete Successfully',
                                      'success'
                                      );
                                  // Fire.$emit('AfterCreate');
                                  this.loadDataProduct();
                              }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                        }
                  })
          },

            // 
            newDevis(){
                this.form_global.reset();
                this.editmode = false;
                // this.disableDevisPV = false;
                this.DialogVisible_global = true;
                this.active=0;
            },
            createData(){
            this.$Progress.start();

              this.form_global.post('api/esim')
              .then((data)=>{
                if(data.data.success){
                 this.DialogVisible_global = false

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.$Progress.finish();
                  this.loadData();
                //   this.loadDataPrepared();

                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          },
          submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.createData();
                } else {
                    console.log('error submit!!');
                    return false;
                }
                });
            },
            loadTours(){
                this.$Progress.start();
                axios.get('api/indexEsim').then(({ data }) => (this.Tours = data.data));
                this.$Progress.finish();
            },
            loadHotels(){
                this.$Progress.start();
                axios.get('api/indexHotels').then(({ data }) => (this.Hotels = data.data));
                this.$Progress.finish();
            },
            loadFournisseurs(){
                this.$Progress.start();
                axios.get('api/indexFournisseur').then(({ data }) => (this.Fournisseurs = data.data));
                this.$Progress.finish();
            },
            loadPlateformes(){
                this.$Progress.start();
                axios.get('api/indexPlateformesESIM').then(({ data }) => (this.Plateformes = data.data));
                this.$Progress.finish();
            },

            updateReservation(reservation){
                // console.log(reservation);
                this.editmode = true;
                this.form_global.reset();
                this.form_global.fill(reservation);
                this.DialogVisible_global = true;
                // console.log(this.form_global);
            },

            updateData(){
              this.$Progress.start();
              this.form_global.put('api/esim/'+this.form_global.id)
              .then((response) => {
                  // success
                  this.DialogVisible_global = false
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });
                  this.$Progress.finish();

                  this.loadData();
                  this.loadDataToday();
              })
              .catch(() => {
                  this.$Progress.fail();
              });

          },

          deletData(id){
              Swal.fire({
                  title: 'Attention !',
                  text: "You really want to delete this eSIM ??",
                  showCancelButton: true,
                  confirmButtonColor: '#67c23a',
                  cancelButtonColor: '#f56c6c',
                  confirmButtonText: 'Yes, Delete-it ! '
                  }).then((result) => {

                      // Send request to the server
                        if (result.value) {
                              this.form_global.delete('api/esim/'+id).then(()=>{
                                      Swal.fire(
                                      'Deleted !',
                                      'eSIM Delete Successfully',
                                      'success'
                                      );
                                  // Fire.$emit('AfterCreate');
                                  this.loadData();
                              }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                        }
                  })
          },
        //   End CRUD

            handleClose(done) {
                this.$confirm('Voulez-vous vraiment fermer cette boîte de dialogue ?')
                .then(_ => {
                    done();
                })
                .catch(_ => {});
            },

            handleCurrentChange2(val) {
                this.currentRow = val;
            },

            // END
  
           
            loadProducts(){
  
              // if(this.$gate.isAdmin()){
                axios.get("api/product").then(({ data }) => (this.products = data.data));
              // }
            },
            loadCategories(){
                axios.get("/api/category/list").then(({ data }) => (this.categories = data.data));
            },
            loadTags(){
                axios.get("/api/tag/list").then(response => {
                    this.autocompleteItems = response.data.data.map(a => {
                        return { text: a.name, id: a.id };
                    });
                }).catch(() => console.warn('Oh. Something went wrong'));
            },
            editModal(product){
                this.editmode = true;
                this.form.reset();
                $('#addNew').modal('show');
                this.form.fill(product);
            },
            newModal(){
                this.editmode = false;
                this.form.reset();
                $('#addNew').modal('show');
            },
            createProduct(){
                this.$Progress.start();
  
                this.form.post('api/product')
                .then((data)=>{
                  if(data.data.success){
                    $('#addNew').modal('hide');
  
                    Toast.fire({
                          icon: 'success',
                          title: data.data.message
                      });
                    this.$Progress.finish();
                    this.loadProducts();
  
                  } else {
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
  
                    this.$Progress.failed();
                  }
                })
                .catch(()=>{
  
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
                })
            },
            
            deletModal(id){
                Swal.fire({
                    title: 'Attention !',
                    text: "vous voulez vraiment supprimer ce devis",
                    showCancelButton: true,
                    confirmButtonColor: '#67c23a',
                    cancelButtonColor: '#f56c6c',
                    confirmButtonText: 'Oui, supprimez-le ! '
                    }).then((result) => {
  
                        // Send request to the server
                          if (result.value) {
                                this.form.delete('api/product/'+id).then(()=>{
                                        Swal.fire(
                                        'Supprimé !',
                                        'Votre fichier a été supprimé.',
                                        'success'
                                        );
                                    // Fire.$emit('AfterCreate');
                                    this.loadProducts();
                                }).catch((data)=> {
                                    Swal.fire("Failed!", data.message, "warning");
                                });
                          }
                    })
            },
          
           handleCurrentChange(cpage) {
                  this.currpage = cpage;
              },
          handleSizeChange(psize) {
                  this.pagesize = psize;
              },
  
  
          },
          mounted() {
              
          },
          created() {
              this.$Progress.start();
  
            this.loadCalculs();
            this.loadData();
            this.loadDataToday();
            this.loadTours();
            this.loadDataProduct();
            //   this.loadHotels();
            // this.loadFournisseurs();
            this.loadPlateformes();
  
              this.$Progress.finish();
          },
          filters: {
            OfferDates(value){
                return moment(String(value)).format('DD-MM-YYYY')
            },
            FormNumbers2(value){
                return numeral(value).format("0.00");
            },
            truncate: function (text, length, suffix) {
                return text.substring(0, length) + suffix;
            },
          },
          computed: {
            filteredItems() {
              return this.autocompleteItems.filter(i => {
                return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
              });
            },
          },
      }
  </script>
  