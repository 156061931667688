<template>
    <section class="content">
        <!-- {{ pdfData }} -->
        <div class="container-fluid" v-if="$gate.isAdmin()">
            <div class="row row-cols-1 row-cols-md-2 row-cols-xl-4">
       <div class="col">
		 <div class="card radius-10 border-start border-0 border-3 border-info">
			<div class="card-body">
				<div class="d-flex align-items-center">
					<div>
						<p class="mb-0 text-secondary">Total Booking</p>
						<h4 class="my-1 text-info">{{ CountBooking.countTotal }}</h4>
                        <p class="mb-0 font-13 text-info">100 % </p>
					</div>
					<div class="widgets-icons-2 rounded-circle bg-gradient-scooter text-white ms-auto"><i class="fa fa-calendar-check"></i>
					</div>
				</div>
			</div>
		 </div>
	   </div>
	   <div class="col">
		<div class="card radius-10 border-start border-0 border-3 border-warning">
		   <div class="card-body">
			   <div class="d-flex align-items-center">
				   <div>
					   <p class="mb-0 text-secondary">Total Processing</p>
					   <h4 class="my-1 text-orange">{{ CountBooking.countProcessing }}</h4>
					   <p class="mb-0 font-13 text-orange">{{(CountBooking.countProcessing*100 /CountBooking.countTotal) | FormNumbers2}} %</p>
				   </div>
				   <div class="widgets-icons-2 rounded-circle bg-gradient-blooker text-white ms-auto"><i class="fa fa-cogs"></i>
				   </div>
			   </div>
		   </div>
		</div>
	  </div>
	  <div class="col">
		<div class="card radius-10 border-start border-0 border-3 border-success">
		   <div class="card-body">
			   <div class="d-flex align-items-center">
				   <div>
					   <p class="mb-0 text-secondary">Total Completed</p>
					   <h4 class="my-1 text-success">{{ CountBooking.countCompleted }}</h4>
                       <p class="mb-0 font-13 text-success">{{(CountBooking.countCompleted*100 /CountBooking.countTotal) | FormNumbers2}} %</p>
				   </div>
				   <div class="widgets-icons-2 rounded-circle bg-gradient-ohhappiness text-white ms-auto"><i class="fa fa-check-square"></i>
				   </div>
			   </div>
		   </div>
		</div>
	  </div>
	  <div class="col">
		<div class="card radius-10 border-start border-0 border-3 border-danger">
		   <div class="card-body">
			   <div class="d-flex align-items-center">
				   <div>
					   <p class="mb-0 text-secondary">Total Cancelled</p>
					   <h4 class="my-1 text-danger">{{ CountBooking.countCancelled }}</h4>
					   <p class="mb-0 font-13 text-danger">{{(CountBooking.countCancelled*100 /CountBooking.countTotal) | FormNumbers2}} %</p>
				   </div>
				   <div class="widgets-icons-2 rounded-circle bg-gradient-bloody text-white ms-auto"><i class="fa fa-ban"></i>
				   </div>
			   </div>
		   </div>
		</div>
	  </div> 
	  <!-- <div class="col">
		<div class="card radius-10 border-start border-0 border-3 border-danger">
		   <div class="card-body">
			   <div class="d-flex align-items-center">
				   <div>
					   <p class="mb-0 text-secondary">Total Refunded</p>
					   <h4 class="my-1 text-danger">{{ CountBooking.countRefunded }}</h4>
				   </div>
				   <div class="widgets-icons-2 rounded-circle bg-gradient-bloody text-white ms-auto"><i class="fa fa-undo"></i>
				   </div>
			   </div>
		   </div>
		</div>
	  </div>  -->
	</div>
            
            <!-- /.row -->
            <div class="mb-3 mt-3">
                <el-card class="mb-3 mt-3">
          <div class="row">
          <div class="col-6">
              <h4 class="ml-3">Tomorrow's Program</h4>
          </div>
          <div class="col-lg-6">
            <el-button class="float-right" type="primary" @click="showPDF()" size="small" icon="el-icon-document" >PDF</el-button>
          </div>
            <div class="col-12 mt-3">
              <el-table
                    :data="BeforD" border >
                    <el-table-column type="expand">
                    <template slot-scope="props">
                        <el-descriptions class="margin-top" :column="4" border>
                            <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-link"></i>
                                Reference
                            </template>
                                <el-tag effect="dark">{{ props.row.reference}}</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-mobile-phone"></i>
                                Telephone
                            </template>
                                <el-tag effect="dark">{{ props.row.phone}}</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-user-solid"></i>
                                Adults
                            </template>
                                <el-tag type="danger" effect="dark" >{{ props.row.nombreAdulte}}</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <i class="el-icon-user-solid"></i>
                                    Children
                                </template>
                                <el-tag type="danger" effect="dark">{{ props.row.nombreChildren}}</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-chat-dot-round"></i>
                                Langue
                            </template>
                                <el-tag effect="dark">{{ props.row.langue}}</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <i class="el-icon-office-building"></i>
                                    Plateforme
                                </template>
                                <el-tag effect="dark">{{ props.row.get_plateformes.title}}</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <i class="el-icon-money"></i>
                                    Price
                                </template>
                                <el-tag effect="dark">{{ props.row.price}} {{ props.row.currency }}</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <i class="el-icon-money"></i>
                                    Net Price
                                </template>
                                <el-tag effect="dark">{{ props.row.priceNet}} {{ props.row.currency }}</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item v-if="props.row.machineExiste == 'OUI'">
                                    <template slot="label">
                                        <i class="el-icon-money"></i>
                                        Duration
                                    </template>
                                    <el-tag  type="" effect="dark" >{{ props.row.duration }} Heur(s)</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item v-if="props.row.machineExiste == 'OUI'">
                                    <template slot="label">
                                        <i class="el-icon-money"></i>
                                        Number Of Machines
                                    </template>
                                    <el-tag  type="" effect="dark" >{{ props.row.nbr_bikes }}</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item v-if="props.row.machineExiste == 'OUI'">
                                    <template slot="label">
                                        <i class="el-icon-money"></i>
                                        Fournisseur
                                    </template>
                                    <el-tag  type="" effect="dark" >{{ props.row.get_fournisseur.name }} {{ props.row.get_fournisseur.adress }}</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-tickets"></i>
                                Note
                            </template>
                            <strong>{{ props.row.note }}</strong>
                            </el-descriptions-item>
                        </el-descriptions>
                    </template>
                    </el-table-column>
                    
                    <el-table-column label="Nom Client">
                        <template slot-scope="scope">
                            <strong>{{ scope.row.NomClient }}  ({{ scope.row.nombreTotalPer }})</strong>
                        </template>
                    </el-table-column>
                    <el-table-column label="Pick Up">
                        <template slot-scope="scope">
                            <strong>{{ scope.row.id_pickup }}</strong>
                        </template>
                    </el-table-column>
                    <el-table-column label="Product">
                        <template slot-scope="scope">
                            <strong>{{ scope.row.get_product.title }}</strong>
                        </template>
                    </el-table-column>
                    <el-table-column label="Date" align="center">
                        <template slot-scope="scope">
                            <strong>{{ scope.row.dateJ | OfferDates }} / {{ scope.row.timeJ }} H</strong>
                        </template>
                    </el-table-column>
                    <el-table-column label="Status" width="100px" align="center">
                        <template slot-scope="scope">
                            <div v-if="scope.row.etat == 'Processing'">
                                <el-tag  type="" effect="dark" ><strong>{{ scope.row.etat }}</strong></el-tag>
                            </div>
                            <div v-if="scope.row.etat == 'Refunded'">
                                <el-tag  type="danger" effect="dark" ><strong>{{ scope.row.etat }}</strong></el-tag>
                            </div>
                            <div v-if="scope.row.etat == 'Cancelled'">
                                <el-tag  type="warning" effect="dark" ><strong>{{ scope.row.etat }}</strong></el-tag>
                            </div>
                            <div v-if="scope.row.etat == 'Completed'">
                                <el-tag  type="success" effect="dark" ><strong>{{ scope.row.etat }}</strong></el-tag>
                            </div>
                        </template>
                    </el-table-column>
              </el-table>
               
               <pagination class="mt-4" :data="BeforD" @pagination-change-page="loadBookings"></pagination>
            </div>
            
          </div>
          </el-card>
            </div>
             <!-- Reminding -->
             <!-- <div class="mb-3 mt-3">
                <el-card class="mb-3 mt-3">
          <div class="row">
          <div class="col-6">
              <h4 class="ml-3">Tomorrow's Reminding</h4>
          </div>
            <div class="col-12 mt-3">
              <el-table
                    :data="Remind_BeforD" border >
                    <el-table-column type="expand">
                    <template slot-scope="props">
                        <el-descriptions class="margin-top" :column="3" border>
                            <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-user-solid"></i>
                                Adults
                            </template>
                                <el-tag effect="dark">{{ props.row.nombreAdulte}}</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <i class="el-icon-user-solid"></i>
                                    Children
                                </template>
                                <el-tag effect="dark">{{ props.row.nombreChildren}}</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <i class="el-icon-office-building"></i>
                                    Plateforme
                                </template>
                                <el-tag effect="dark">{{ props.row.get_plateformes.title}}</el-tag>
                            </el-descriptions-item>
                            
                            <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-tickets"></i>
                                Note
                            </template>
                            <strong>{{ props.row.note }}</strong>
                            </el-descriptions-item>
                        </el-descriptions>
                    </template>
                    </el-table-column>
                    
                    <el-table-column label="Nom Client">
                        <template slot-scope="scope">
                            <strong>{{ scope.row.NomClient }}</strong>
                        </template>
                    </el-table-column>
                    <el-table-column label="Pick Up">
                        <template slot-scope="scope">
                            <strong>{{ scope.row.pickup }}</strong>
                        </template>
                    </el-table-column>
                    <el-table-column label="Product">
                        <template slot-scope="scope">
                            <strong>{{ scope.row.get_product.title }}</strong>
                        </template>
                    </el-table-column>
                    <el-table-column label="Date" align="center">
                        <template slot-scope="scope">
                            <strong>{{ scope.row.dateJ | OfferDates }} / {{ scope.row.timeJ }} H</strong>
                        </template>
                    </el-table-column>
                    
              </el-table>
               
               <pagination class="mt-4" :data="Remind_BeforD" @pagination-change-page="loadReminders"></pagination>
            </div>
            
          </div>
          </el-card>
            </div> -->
            <!-- End Reminding -->
            <!-- Booking For Today -->
            <div class="mb-3 mt-3">
                <el-card class="mb-3 mt-3">
          <div class="row">
            
          <div class="col-lg-6">
              <h4 class="ml-3">Today's Program</h4>
          </div>
          <div class="col-lg-6">
            <el-button class="float-right" type="primary" @click="updatedEtat()" size="small" icon="el-icon-plus" >Completed</el-button>
          </div>
            <div class="col-12 mt-3">
              <el-table
                    :data="BookingD" border 
                    @selection-change="handleSelectionChange">
                    <el-table-column
                        type="selection"
                        width="55">
                    </el-table-column>
                    <el-table-column type="expand">
                    <template slot-scope="props">
                        <el-descriptions class="margin-top" :column="4" border>
                            <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-user-solid"></i>
                                Adults
                            </template>
                                <el-tag type="danger" effect="dark" >{{ props.row.nombreAdulte}}</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <i class="el-icon-user-solid"></i>
                                    Children
                                </template>
                                <el-tag type="danger" effect="dark">{{ props.row.nombreChildren}}</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-link"></i>
                                Reference
                            </template>
                                <el-tag effect="dark">{{ props.row.reference}}</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-mobile-phone"></i>
                                Telephone
                            </template>
                                <el-tag effect="dark">{{ props.row.phone}}</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-chat-dot-round"></i>
                                Langue
                            </template>
                                <el-tag effect="dark">{{ props.row.langue}}</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <i class="el-icon-office-building"></i>
                                    Plateforme
                                </template>
                                <el-tag effect="dark">{{ props.row.get_plateformes.title}}</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <i class="el-icon-money"></i>
                                    Price
                                </template>
                                <el-tag effect="dark">{{ props.row.price}} {{ props.row.currency }}</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <i class="el-icon-money"></i>
                                    Net Price
                                </template>
                                <el-tag effect="dark">{{ props.row.priceNet}} {{ props.row.currency }}</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item v-if="props.row.machineExiste == 'OUI'">
                                    <template slot="label">
                                        <i class="el-icon-money"></i>
                                        Duration
                                    </template>
                                    <el-tag  type="" effect="dark" >{{ props.row.duration }} Heur(s)</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item v-if="props.row.machineExiste == 'OUI'">
                                    <template slot="label">
                                        <i class="el-icon-money"></i>
                                        Number Of Machines
                                    </template>
                                    <el-tag  type="" effect="dark" >{{ props.row.nbr_bikes }}</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item v-if="props.row.machineExiste == 'OUI'">
                                    <template slot="label">
                                        <i class="el-icon-money"></i>
                                        Fournisseur
                                    </template>
                                    <el-tag  type="" effect="dark" >{{ props.row.get_fournisseur.name }} {{ props.row.get_fournisseur.adress }}</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-tickets"></i>
                                Note
                            </template>
                            <strong>{{ props.row.note }}</strong>
                            </el-descriptions-item>
                        </el-descriptions>
                    </template>
                    </el-table-column>
                    
                    <el-table-column label="Nom Client">
                        <template slot-scope="scope">
                            <strong>{{ scope.row.NomClient }}  ({{ scope.row.nombreTotalPer }})</strong>
                        </template>
                    </el-table-column>
                    <el-table-column label="Pick Up">
                        <template slot-scope="scope">
                            <strong>{{ scope.row.id_pickup }}</strong>
                        </template>
                    </el-table-column>
                    <el-table-column label="Product">
                        <template slot-scope="scope">
                            <strong>{{ scope.row.get_product.title }}</strong>
                        </template>
                    </el-table-column>
                    <el-table-column label="Date" align="center">
                        <template slot-scope="scope">
                            <strong>{{ scope.row.dateJ | OfferDates }} / {{ scope.row.timeJ }} H</strong>
                        </template>
                    </el-table-column>
                    <el-table-column label="Status" width="100px" align="center">
                        <template slot-scope="scope">
                            <div v-if="scope.row.etat == 'Processing'">
                                <el-tag  type="" effect="dark" ><strong>{{ scope.row.etat }}</strong></el-tag>
                            </div>
                            <div v-if="scope.row.etat == 'Refunded'">
                                <el-tag  type="danger" effect="dark" ><strong>{{ scope.row.etat }}</strong></el-tag>
                            </div>
                            <div v-if="scope.row.etat == 'Cancelled'">
                                <el-tag  type="warning" effect="dark" ><strong>{{ scope.row.etat }}</strong></el-tag>
                            </div>
                            <div v-if="scope.row.etat == 'Completed'">
                                <el-tag  type="success" effect="dark" ><strong>{{ scope.row.etat }}</strong></el-tag>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column width="100" align="center">
                        <template slot-scope="{row}">
                                <a href="#" @click="updateReservation(row)" data-toggle="tooltip" data-placement="top" title="Modifier">
                                        <i class="fa fa-edit blue mr-2"></i>
                                </a>
                                <!-- <el-divider direction="vertical"></el-divider>
                                <a href="#" @click="deletData(row.id)" data-toggle="tooltip" data-placement="top" title="Supprimer">
                                    <i class="fa fa-trash red"></i>
                                </a> -->
                        </template>
                    </el-table-column>
              </el-table>
               
               <pagination class="mt-4" :data="BookingD" @pagination-change-page="loadBookingsToday"></pagination>
            </div>
            
          </div>
          </el-card>
            </div>
            <!-- SUM Prices By Plateform -->
           

            <!-- <div class="row mb-3">
                <div class="col-12 col-sm-6 col-md-3">
                    <el-select @change="loadSUMByMonth" v-model="month" style="width: 100%" placeholder="Filter By Month">
                        <el-option label="Filter By Month" value=""></el-option>
                        <el-option label="January" value="01"></el-option>
                        <el-option label="February" value="02"></el-option>
                        <el-option label="March" value="03"></el-option>
                        <el-option label="April" value="04"></el-option>
                        <el-option label="May" value="05"></el-option>
                        <el-option label="June" value="06"></el-option>
                        <el-option label="July" value="07"></el-option>
                        <el-option label="August" value="08"></el-option>
                        <el-option label="September" value="09"></el-option>
                        <el-option label="October" value="10"></el-option>
                        <el-option label="November" value="11"></el-option>
                        <el-option label="December" value="12"></el-option>
                    </el-select>
                </div>
            </div>
            <div class="row " v-if="$gate.isAdmin()">
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box">
                        <span class="info-box-icon elevation-1">
                            <img src="https://cache.vtrcdn.com/orion/images/logos/logo-viator-facebook.png" alt="" class="avatar-sm rounded-circle me-2" />
                        </span>
                        <div class="info-box-content">
                            <strong class="info-box-text"> IMOHATOURS </strong>
                            <span class="info-box-number">
                                {{ DashSum.SumViatorImohaS | FormNumbers2 }} € 
                            </span>
                            <small><strong>Reservations : </strong> {{ DashSum.SumViatorImohaC }}</small>
                        </div>
                        
                    </div>
                    
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box">
                        <span class="info-box-icon elevation-1">
                            <img src="https://cache.vtrcdn.com/orion/images/logos/logo-viator-facebook.png" alt="" class="avatar-sm rounded-circle me-2" />
                        </span>
                        <div class="info-box-content">
                            <strong class="info-box-text">El Fakir Tours </strong>
                            <span class="info-box-number">
                                {{ DashSum.SumViatorFakirS | FormNumbers2 }} € 
                            </span>
                            <small><strong>Reservations : </strong> {{ DashSum.SumViatorFakirC }}</small>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box">
                        <span class="info-box-icon elevation-1">
                            <img src="https://play-lh.googleusercontent.com/Nrx5AlZiDCS__s51uPms4q6FiwPYX0ky4-Pf2KnsNZv6QlIKiov3n9N1K15ZNWLHc7k" alt="" class="avatar-sm rounded-circle me-2" />
                        </span>
                        <div class="info-box-content">
                            <strong class="info-box-text"> ImohaTours </strong>
                            <span class="info-box-number">
                                {{ DashSum.CiviTatisImohaS | FormNumbers2}} €
                            </span>
                            <small><strong>Reservations : </strong> {{ DashSum.CiviTatisImohaC }}</small>
                        </div>
                    </div>
                </div>
           
            
            
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box">
                        <span class="info-box-icon elevation-1">
                            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREZLNYoQwNfDBtqGdYy9DF9Er8wTyM_0MWHD3_ldgVPQ&s" alt="" class="avatar-sm rounded-circle me-2" />
                        </span>
                        <div class="info-box-content">
                            <strong class="info-box-text">Agadir Adventure </strong>
                            <span class="info-box-number">
                                {{ DashSum.GygAgadirADS | FormNumbers2}} £
                            </span>
                            <small><strong>Reservations : </strong> {{ DashSum.GygAgadirADC }}</small>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box">
                        <span class="info-box-icon elevation-1">
                            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREZLNYoQwNfDBtqGdYy9DF9Er8wTyM_0MWHD3_ldgVPQ&s" alt="" class="avatar-sm rounded-circle me-2" />
                        </span>
                        <div class="info-box-content">
                            <strong class="info-box-text">Agadir Horse Riding </strong>
                            <span class="info-box-number">
                                {{ DashSum.GygHRS | FormNumbers2}} €
                            </span>
                            <small><strong>Reservations : </strong> {{ DashSum.GygHRC }}</small>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box">
                        <span class="info-box-icon elevation-1">
                            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREZLNYoQwNfDBtqGdYy9DF9Er8wTyM_0MWHD3_ldgVPQ&s" alt="" class="avatar-sm rounded-circle me-2" />
                        </span>
                        <div class="info-box-content">
                            <strong class="info-box-text">Agadir JetSKi </strong>
                            <span class="info-box-number">
                                {{ DashSum.GyJTSKIS | FormNumbers2}} €
                            </span>
                            <small><strong>Reservations : </strong> {{ DashSum.GyJTSKIC }}</small>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box">
                        <span class="info-box-icon elevation-1">
                            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREZLNYoQwNfDBtqGdYy9DF9Er8wTyM_0MWHD3_ldgVPQ&s" alt="" class="avatar-sm rounded-circle me-2" />
                        </span>
                        <div class="info-box-content">
                            <strong class="info-box-text"> Sand Boarding </strong>
                            <span class="info-box-number">
                                {{ DashSum.GySandBS | FormNumbers2}} €
                            </span>
                            <small><strong>Reservations : </strong> {{ DashSum.GySandBC }}</small>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box">
                        <span class="info-box-icon elevation-1">
                            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREZLNYoQwNfDBtqGdYy9DF9Er8wTyM_0MWHD3_ldgVPQ&s" alt="" class="avatar-sm rounded-circle me-2" />
                        </span>
                        <div class="info-box-content">
                            <strong class="info-box-text">Aminia Travel </strong>
                            <span class="info-box-number">
                                {{ DashSum.GygAminiaS | FormNumbers2}} €
                            </span>
                            <small><strong>Reservations : </strong> {{ DashSum.GygAminiaC }}</small>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box">
                        <span class="info-box-icon elevation-1">
                            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREZLNYoQwNfDBtqGdYy9DF9Er8wTyM_0MWHD3_ldgVPQ&s" alt="" class="avatar-sm rounded-circle me-2" />
                        </span>
                        <div class="info-box-content">
                            <strong class="info-box-text">Marrakech Day Trip </strong>
                            <span class="info-box-number">
                                {{ DashSum.GygKechDTS | FormNumbers2}} €
                            </span>
                            <small><strong>Reservations : </strong> {{ DashSum.GygKechDTC }}</small>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box">
                        <span class="info-box-icon elevation-1">
                            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREZLNYoQwNfDBtqGdYy9DF9Er8wTyM_0MWHD3_ldgVPQ&s" alt="" class="avatar-sm rounded-circle me-2" />
                        </span>
                        <div class="info-box-content">
                            <strong class="info-box-text">Sahara Piscine</strong>
                            <span class="info-box-number">
                                {{ DashSum.GygSaharaPS | FormNumbers2}} MAD
                            </span>
                            <small><strong>Reservations : </strong> {{ DashSum.GygSaharaPC }}</small>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box">
                        <span class="info-box-icon elevation-1">
                            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREZLNYoQwNfDBtqGdYy9DF9Er8wTyM_0MWHD3_ldgVPQ&s" alt="" class="avatar-sm rounded-circle me-2" />
                        </span>
                        <div class="info-box-content">
                            <strong class="info-box-text">Agadir City Tour</strong>
                            <span class="info-box-number">
                                {{ DashSum.GygAgadirCTS | FormNumbers2}} EURO
                            </span>
                            <small><strong>Reservations : </strong> {{ DashSum.GygAgadirCTC }}</small>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box">
                        <span class="info-box-icon elevation-1">
                            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREZLNYoQwNfDBtqGdYy9DF9Er8wTyM_0MWHD3_ldgVPQ&s" alt="" class="avatar-sm rounded-circle me-2" />
                        </span>
                        <div class="info-box-content">
                            <strong class="info-box-text">IMOHATOURS </strong>
                            <span class="info-box-number">
                                {{ DashSum.GygImohaS | FormNumbers2}} £
                            </span>
                            <small><strong>Reservations : </strong> {{ DashSum.GygImohaC }}</small>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box">
                        <span class="info-box-icon elevation-1">
                            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREZLNYoQwNfDBtqGdYy9DF9Er8wTyM_0MWHD3_ldgVPQ&s" alt="" class="avatar-sm rounded-circle me-2" />
                        </span>
                        <div class="info-box-content">
                            <strong class="info-box-text"> El Fakir Tours </strong>
                            <span class="info-box-number">
                                {{ DashSum.GygFakirS | FormNumbers2}} DH
                            </span>
                            <small><strong>Reservations : </strong> {{ DashSum.GygFakirC }}</small>
                        </div>
                    </div>
                </div>
                
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box">
                        <span class="info-box-icon elevation-1">
                            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREZLNYoQwNfDBtqGdYy9DF9Er8wTyM_0MWHD3_ldgVPQ&s" alt="" class="avatar-sm rounded-circle me-2" />
                        </span>
                        <div class="info-box-content">
                            <strong class="info-box-text"> Free Stress</strong>
                            <span class="info-box-number">
                                {{ DashSum.GygStressS | FormNumbers2}} £
                            </span>
                            <small><strong>Reservations : </strong> {{ DashSum.GygStressC }}</small>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box">
                        <span class="info-box-icon elevation-1">
                            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREZLNYoQwNfDBtqGdYy9DF9Er8wTyM_0MWHD3_ldgVPQ&s" alt="" class="avatar-sm rounded-circle me-2" />
                        </span>
                        <div class="info-box-content">
                            <strong class="info-box-text"> Your Intrepid</strong>
                            <span class="info-box-number">
                                {{ DashSum.GygIntrepidS | FormNumbers2}} DH
                            </span>
                            <small><strong>Reservations : </strong> {{ DashSum.GygIntrepidC }}</small>
                        </div>
                    </div>
                </div>
                
            
           
            
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box">
                        <span class="info-box-icon elevation-1">
                            <img src="https://imohatours.com/wp-content/uploads/2018/12/old.webp" alt="" class="avatar-sm me-2" />
                        </span>
                        <div class="info-box-content">
                            <strong class="info-box-text">Imohatours.com </strong>
                            <span class="info-box-number">
                                {{ DashSum.ImohatoursS | FormNumbers2}} € 
                            </span>
                            <small><strong>Reservations : </strong> {{ DashSum.ImohatoursC }}</small>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box">
                        <span class="info-box-icon elevation-1">
                            <img src="https://elfakirtours.com/wp-content/uploads/2023/02/cropped-image_2023-02-11_001208787-removebg-preview-300x216.png" alt="" class="avatar-sm me-2" />
                        </span>
                        <div class="info-box-content">
                            <strong class="info-box-text">Elfakirtours.com </strong>
                            <span class="info-box-number">
                                {{ DashSum.FakirtoursS | FormNumbers2}} € 
                            </span>
                            <small><strong>Reservations : </strong> {{ DashSum.FakirtoursC }}</small>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box">
                        <span class="info-box-icon elevation-1">
                            <img src="https://png.pngtree.com/png-vector/20221018/ourmid/pngtree-whatsapp-icon-png-image_6315990.png" alt="" class="avatar-sm me-2" />
                        </span>
                        <div class="info-box-content">
                            <strong class="info-box-text">Whatssap</strong>
                            <span class="info-box-number">
                                {{ DashSum.WhatssapS | FormNumbers2}} DH 
                            </span>
                            <small><strong>Reservations : </strong> {{ DashSum.WhatssapC }}</small>
                        </div>
                    </div>
                </div>  
            </div> -->

            <!-- dialog -->
          <el-dialog
            :title="editmode ? 'Update Reservation':'New Reservation'"
            :visible.sync="DialogVisible_global"
            width="90%"
            :before-close="handleClose">
            <!-- Body dialog -->
                <el-form :model="form_global" ref="form_global" class="demo-ruleForm">
                    <fieldset class="row col-12 border p-2 mt-2">
                        <legend  class="w-auto badge"><h5>Booking Infos</h5></legend>
                            <el-form-item class="col-lg-4" label="Booking Reference" prop="reference" 
                            :rules="[
                                { required: true, message: 'Booking Reference is required' }
                            ]">
                                <el-input v-model="form_global.reference" autocomplete="off"></el-input>
                            </el-form-item>
                            <el-form-item class="col-lg-4" label="NOM CLIENT" prop="NomClient" 
                            :rules="[
                                { required: true, message: 'Nom Client is required' }
                            ]">
                                <el-input v-model="form_global.NomClient" autocomplete="off"></el-input>
                            </el-form-item>
                            <el-form-item class="col-lg-4" label="Platform" prop="id_platform" 
                            :rules="[
                                { required: true, message: 'Platform is required' }
                            ]">
                                <el-select v-model="form_global.id_platform" :disabled="true" @change="PlateformChange()"  filterable  style="width: 100%" placeholder="Select Plateforme">
                                    <el-option
                                        v-for="item in Plateformes"
                                        :key="item.id"
                                        :label="item.title"
                                        :value="item.id"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                            <!-- Price -->
                            <el-form-item class="col-lg-4" label="Price" prop="price" 
                                :rules="[{ required: true, message: 'Price is required' }]">
                                <el-input type="number" :disabled="true" v-model="form_global.price" autocomplete="off">
                                    <template slot="append"><strong>{{ form_global.currency }}</strong></template>
                                </el-input>
                            </el-form-item>
                            <el-form-item  class="col-lg-4" label="Commission" prop="commission" 
                                :rules="[{ required: true, message: 'Commission is required' }]">
                                <el-input :disabled="true" v-model="form_global.commission" autocomplete="off">
                                    <template slot="append"><strong>%</strong></template>
                                </el-input>
                            </el-form-item>
                            <el-form-item  class="col-lg-4" label="Net Price" prop="priceNet" 
                                :rules="[{ required: true, message: 'Price Net is required' }]">
                                <el-input :disabled="true" v-model="form_global.priceNet" autocomplete="off">
                                    <template slot="append"><strong>{{ form_global.currency }}</strong></template>
                                </el-input>
                            </el-form-item>
                            
                            <!-- End Price -->
                            <el-form-item class="col-lg-6" label="Hotel / Pickup" prop="id_pickup" 
                            :rules="[
                                { required: true, message: 'Pick Up Place is required' }
                            ]">
                            <el-input v-model="form_global.id_pickup" autocomplete="off"></el-input>
                           
                            </el-form-item>
                            <el-form-item class="col-lg-6" label="Excurtion" prop="id_product" 
                            :rules="[
                                { required: true, message: 'Titre is required' }
                            ]">
                            <el-select v-model="form_global.id_product" filterable  style="width: 100%" placeholder="Select Product">
                                <el-option
                                    v-for="item in Tours"
                                    :key="item.id"
                                    :label="item.title"
                                    :value="item.id"
                                ></el-option>
                            </el-select>
                                <!-- <el-input type="text" v-model="form_global.id_product" autocomplete="off"></el-input> -->
                            </el-form-item>
                            <!--  -->
                            <el-form-item class="col-lg-3" label="Nombre Adulte" prop="nombreAdulte" 
                            :rules="[
                                { required: true, message: 'Nombre of adults is required' },
                            ]">
                                <el-input type="number" v-model="form_global.nombreAdulte" autocomplete="off"></el-input>
                            </el-form-item>
                            <el-form-item class="col-lg-3" label="Nombre Children" prop="nombreChildren" 
                            :rules="[
                                { required: true, message: 'Nombre of Children is required' },
                            ]">
                                <el-input type="number" v-model="form_global.nombreChildren" autocomplete="off"></el-input>
                            </el-form-item>
                            <el-form-item class="col-lg-3" label="Date Sortie" prop="dateJ" >
                                <el-date-picker placeholder="Pick a date" v-model="form_global.dateJ" value-format="yyyy-MM-dd" style="width: 100%;"></el-date-picker>
                            </el-form-item>
                            
                            <el-form-item class="col-lg-3" label="Time Sortie" prop="timeJ"
                                :rules="[
                                    { required: true, message: 'Please pick a Time', trigger: 'change' }
                                ]">
                                <el-time-select v-model="form_global.timeJ" :picker-options="{ start: '07:00', step: '00:30', end: '23:30' }"
                                placeholder="Select time" style="width: 100%;">
                            </el-time-select>
                            </el-form-item>
                            <el-form-item 
                                class="col-lg-3"
                                prop="machineExiste"
                                label="Machines Exist ?"
                                :rules="[
                                    { required: true, message: 'This Option is required' }
                                ]"
                            >
                                <el-radio-group @change="MachineCHanger" v-model="form_global.machineExiste">
                                    <el-radio label="OUI" ></el-radio>
                                    <el-radio label="NON" ></el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item v-if="form_global.machineExiste == 'OUI'" class="col-lg-3" label="Number Of Machines" prop="nbr_bikes"
                                :rules="[
                                    { required: true, message: 'Number Of Machines is required' }
                                ]">
                                <el-input type="text" v-model="form_global.nbr_bikes" autocomplete="off"></el-input>
                            </el-form-item>
                            <el-form-item v-if="form_global.machineExiste == 'OUI'" class="col-lg-3" label="Fournisseur" prop="id_fournisseur"
                                :rules="[
                                    { required: true, message: 'Fournisseur is required' }
                                ]">
                                <el-select v-model="form_global.id_fournisseur" filterable  style="width: 100%" placeholder="Select Fournisseur">
                                    <el-option
                                        v-for="item in Fournisseurs"
                                        :key="item.id"
                                        :label="item.brandName+' - '+item.name+' - '+item.adress"
                                        :value="item.id"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item v-if="form_global.machineExiste == 'OUI'" class="col-lg-3" label="Duration of Activity" prop="timeJ"
                                :rules="[
                                    { required: true, message: 'Please pick a Duration of Activity', trigger: 'change' }
                                ]">
                                <el-select v-model="form_global.duration" style="width: 100%" placeholder="Select Duration">
                                        <el-option label="1 Hour" value="1"></el-option>
                                        <el-option label="2 Hour(s)" value="2"></el-option>
                                        <el-option label="3 Hour(s)" value="3"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item class="col-lg-3" label="Num Téléphone" prop="phone" 
                            :rules="[
                                { required: true, message: 'Phone number is required' }
                            ]">
                                <el-input type="text" v-model="form_global.phone" autocomplete="off"></el-input>
                            </el-form-item>
                            <el-form-item class="col-lg-3" label="Tour Language" prop="langue" 
                            :rules="[
                                { required: true, message: 'Langue is required' }
                            ]">
                                <el-select v-model="form_global.langue" style="width: 100%" placeholder="Select Langue">
                                        <el-option label="English" value="English"></el-option>
                                        <el-option label="Spanish" value="Spanish"></el-option>
                                        <el-option label="French" value="French"></el-option>
                                        <el-option label="Germany" value="Germany"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item class="col-lg-3" label="Status" prop="etat" 
                            :rules="[
                                { required: true, message: 'Etat is required' }
                            ]">
                                <el-select v-model="form_global.etat" style="width: 100%" placeholder="Select Status">
                                        <el-option label="Processing" value="Processing"></el-option>
                                        <el-option label="Completed" value="Completed"></el-option>
                                        <el-option label="Cancelled" value="Cancelled"></el-option>
                                        <el-option label="Refunded" value="Refunded"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item class="col-lg-12" label="Note" prop="note" >
                                <el-input type="textarea" v-model="form_global.note" autocomplete="off"></el-input>
                            </el-form-item>
                    </fieldset>
                </el-form>
                <!-- ENd body Dialog -->
                <span slot="footer" class="dialog-footer">
                    <el-button @click="DialogVisible_global = false">Annuler</el-button>
                    <el-button type="primary" v-show="!editmode" @click="submitForm('form_global')">Valider</el-button>
                    <el-button type="primary" v-show="editmode" @click="updateData()">Valider</el-button>
                </span>
          </el-dialog>

          <!-- ENd dialog -->
          <!-- dialog -->
          <el-dialog
            :title="editmode ? 'Tomorrow\'s Program':''"
            :visible.sync="DialogVisible_PDF"
            width="80%"
            :before-close="handleClose">
            <!-- Body dialog -->
            <div id="printMe">
                <!-- {{pdfData}} -->
                <h2 class="text-center mt-3 mb-3"> {{ tomorrow | OfferDates }}</h2>
                <table class="table table-bordered text-center " style="font-size: 15px; font-weight: bold;">
                    <thead>
                        <tr>
                            <th scope="col">Activity</th>
                            <th scope="col">Client</th>
                            <th scope="col">Adress</th>
                            <th scope="col">Langue</th>
                            <th scope="col">Reste</th>
                        </tr>
                    </thead>
                    <tbody v-for="c in pdfData" :key="c.id">
                        <tr class="tablePDF">
                            <td>{{ c.get_product.title }} <br>({{ c.timeJ }}) </td>
                            <td>{{ c.NomClient }} <br> {{(c.nombreAdulte + c.nombreChildren)}}</td>
                            <td>{{ c.id_pickup }}</td>
                            <td>{{ c.langue }}</td>
                            <td v-if="c.note == null">---</td>
                            <td v-else>{{ c.note }}</td>
                        </tr>
                    </tbody>
                </table>
                <div v-for="c in pdfData" :key="c.id" class="mt-3">
                    <p><strong>{{ c.NomClient }} : {{ c.phone }}</strong></p>
                </div>
            </div>
                <!-- ENd body Dialog -->
                <span slot="footer" class="dialog-footer">
                    <el-button @click="print()">Print</el-button>
                    <el-button @click="DialogVisible_PDF = false">Annuler</el-button>
                    <!-- <el-button type="primary" v-show="!editmode" @click="submitFormSpendValide('form_spending')">Valider</el-button>
                    <el-button type="primary" v-show="editmode" @click="updateDataSpend()">Valider</el-button> -->
                </span>
          </el-dialog>

          <!-- ENd dialog -->
         
            <!-- END SUM Prices By Plateform -->
            <!-- Chart -->
            <div v-show="!dashData" class="col-lg-4 mt-3 mb-3">
                <el-button type="primary" @click="getChartPie()" size="small" icon="el-icon-plus" >Show Data</el-button>
            </div>
            
            <div v-show="dashData" class="row mb-5"  v-if="$gate.isAdmin()">
                <div class="col-lg-6 mt-3">
                    <el-card>
                        <div slot="header" class="clearfix">
                            <strong> Total Sales By Month </strong>
                        </div>
                        <apexchart  type="bar" :options="optionsX" :series="seriesX"></apexchart>
                    </el-card>
                </div>
                <div class="col-lg-6 mt-3">
                    <el-card>
                        <div slot="header" class="clearfix">
                            <strong> Total Sales By Platforms (TOP 10) </strong>
                        </div>
                        <apexchart  type="bar" :options="optionsY" :series="seriesY"></apexchart>
                    </el-card>
                </div>
                <div class="col-lg-6 mt-3">
                    <el-card>
                        <div slot="header" class="clearfix">
                            <strong> Total Sales By Product (TOP 10)</strong>
                        </div>
                        <apexchart  type="bar" :options="optionsZ" :series="seriesZ"></apexchart>
                    </el-card>
                </div>
                <!-- <div class="col-lg-6 mt-3">
                    <el-card>
                        <div slot="header" class="clearfix">
                            <strong> Total Sales By Langue</strong>
                        </div>
                        <apexchart  type="bar" :options="optionsL" :series="seriesL"></apexchart>
                    </el-card>
                </div> -->
                <!-- <div v-show="dashData" class="col-lg-6 col-sm-12 mt-3 mb-3">
                    <el-card>
                        <div slot="header">
                            <span> Total By Langue </span>
                        </div>
                        <div>
                            
                                <apexchart type="pie" :options="chartOptions33" :series="series33"></apexchart>
                            
                        </div>
                    </el-card>
                </div> -->
                <!-- <div v-show="dashData" class="col-lg-6">
                    <el-card>
                        <div slot="header" class="clearfix">
                            <span> By Month </span>
                        </div>
                        <apexchart width="380" type="donut" :options="optionsC" :series="seriesC"></apexchart>
                    </el-card>
                </div> -->
                <!-- <div class="col-lg-6">
                    <el-card>
                        <div slot="header" class="clearfix">
                            <span> --- </span>
                        </div>
                         <apexchart  type="area" :options="options" :series="series"></apexchart>
                    </el-card>
                </div>                 -->
                <!-- <div class="col-lg-4 mt-3 mb-3">
                    <el-button type="primary" @click="getChartMonth()" size="small" icon="el-icon-plus" >Show Data2</el-button>
                </div> -->

                <!-- <div v-show="dashData" class="col-lg-6 col-sm-12 mt-3 mb-3">
                    <el-card>
                        <div slot="header" class="clearfix">
                            <span> Bookings By Plateforme </span>
                        </div>
                        <div>
                            <apexchart width="500" type="donut" :options="PieOptions" :series="Pieseries2"></apexchart>
                        </div>
                    </el-card>
                </div>
                <div v-show="dashData" class="col-lg-6 col-sm-12 mt-3 mb-3">
                    <el-card>
                        <div slot="header" class="clearfix">
                            <span> Bookings By Month </span>
                        </div>
                        <div>
                            <apexchart  type="donut" :options="chartOptions1" :series="series1"></apexchart>
                        </div>
                    </el-card>
                </div> -->
                
                <!-- <div v-show="dashData" class="col-lg-6 mt-3 mb-3">
                    <el-card>
                        <div slot="header" class="clearfix">
                            <span> Bookings By Month </span>
                        </div>
                        <div id="chart1">
                            <apexchart type="bar" height="350" :options="chartOptions3" :series="series3"></apexchart>
                        </div>
                    </el-card>
                </div> -->
                    
            </div>

            <!-- End CHarts -->
            <!-- {{ DashSum.GygImoha }} -->
    <!-- <div class="row align-items-center">
        <div class="col-md-6">
            <div class="mb-3">
                <h5 class="card-title">Plateform List</h5>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <div class="">
                <div class="table-responsive">
                    <table class="table project-list-table table-nowrap align-middle table-borderless">
                        <thead>
                            <tr>
                                <th scope="col">Logo</th>
                                <th scope="col">Booked From</th>
                                <th scope="col">Total $</th>
                                <th scope="col">Total Booking</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <img src="https://cache.vtrcdn.com/orion/images/logos/logo-viator-facebook.png" alt="" class="avatar-sm rounded-circle me-2" />
                                </td>
                                <td><strong>Viator - ImohaTours</strong></td>
                                <td>{{DashSum.SumViatorImoha.sum}} EURO</td>
                                <td>{{DashSum.SumViatorImoha.count}}</td>
                                
                            </tr>
                            <tr>
                                <td>
                                    <img src="https://cache.vtrcdn.com/orion/images/logos/logo-viator-facebook.png" alt="" class="avatar-sm rounded-circle me-2" />
                                </td>
                                <td><strong>Viator - El Fakir Tours</strong></td>
                                <td>{{ DashSum.SumViatorFakirS }} EURO</td>
                                <td>{{ DashSum.SumViatorFakirC }}</td>
                            </tr>
                            <tr>
                                <td>
                                    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREZLNYoQwNfDBtqGdYy9DF9Er8wTyM_0MWHD3_ldgVPQ&s" alt="" class="avatar-sm rounded-circle me-2" />
                                </td>
                                <td><strong>Imoha Tours</strong></td>
                                <td>{{ DashSum.GygImohaS }} EURO</td>
                                <td>{{ DashSum.GygImohaC }}</td>
                                
                            </tr>
                            <tr>
                                <td>
                                    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREZLNYoQwNfDBtqGdYy9DF9Er8wTyM_0MWHD3_ldgVPQ&s" alt="" class="avatar-sm rounded-circle me-2" />
                                </td>
                                <td><strong>El Fakir Tours</strong></td>
                                <td>{{ DashSum.GygFakirS}} EURO</td>
                                <td>{{ DashSum.GygFakirC }}</td>
                                
                            </tr>
                            <tr>
                                <td>
                                    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREZLNYoQwNfDBtqGdYy9DF9Er8wTyM_0MWHD3_ldgVPQ&s" alt="" class="avatar-sm rounded-circle me-2" />
                                </td>
                                <td><strong>Your Intrepid</strong></td>
                                <td>{{ DashSum.GygIntrepidS}} EURO</td>
                                <td>{{ DashSum.GygIntrepidC}}</td>
                                
                            </tr>
                            <tr>
                                <td>
                                    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREZLNYoQwNfDBtqGdYy9DF9Er8wTyM_0MWHD3_ldgVPQ&s" alt="" class="avatar-sm rounded-circle me-2" />
                                </td>
                                <td><strong>Free Stress</strong></td>
                                <td>{{ DashSum.GygStressS}} EURO</td>
                                <td>{{ DashSum.GygStressC}}</td>
                                
                            </tr>
                            <tr>
                                <td>
                                    <img src="https://imohatours.com/wp-content/uploads/2018/12/old.webp" alt="" class="avatar-sm rounded-circle me-2" />
                                </td>
                                <td><strong>ImohaTours.com</strong></td>
                                <td>{{ DashSum.ImohatoursS}} EURO</td>
                                <td>{{ DashSum.ImohatoursC }}</td>
                                
                            </tr>
                            <tr>
                                <td>
                                    <img src="https://elfakirtours.com/wp-content/uploads/2023/02/cropped-image_2023-02-11_001208787-removebg-preview-300x216.png" alt="" class="avatar-sm rounded-circle me-2" />
                                </td>
                                <td><strong>ElfakirTours.com</strong></td>
                                <td>{{ DashSum.FakirtoursS}} EURO</td>
                                <td>{{ DashSum.FakirtoursC }}</td>
                                
                            </tr>
                            <tr>
                                <td>
                                    <img src="https://cdn-iofhd.nitrocdn.com/bvKLsIyciUaJRKhCZEwrNHknaTFENIeE/assets/images/optimized/rev-1405a25/wp-content/uploads/2023/03/cropped-CaptureLogo1-removebg-preview-1.png" alt="" class="avatar-sm rounded-circle me-2" />
                                </td>
                                <td><strong>MorocoGuide.com</strong></td>
                                <td>{{ DashSum.MorocoguideS}} EURO</td>
                                <td>{{ DashSum.MorocoguideC }}</td>
                                
                            </tr>
                            <tr>
                                <td>
                                    <img src="https://png.pngtree.com/png-vector/20221018/ourmid/pngtree-whatsapp-icon-png-image_6315990.png" alt="" class="avatar-sm rounded-circle me-2" />
                                </td>
                                <td><strong>Whatssap</strong></td>
                                <td>{{ DashSum.WhatssapS}} EURO</td>
                                <td>{{ DashSum.WhatssapC }}</td>
                                
                            </tr>
                            
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div> -->           
            <!-- /.row -->
        </div><!--/. container-fluid -->
        <div class="container-fluid" v-if="$gate.isUser()">
            <div class="row row-cols-1 row-cols-md-2 row-cols-xl-4">
                <!-- <div class="col-lg-4">
                    <div class="card radius-10 border-start border-0 border-3 border-danger">
                        <div class="card-body">
                            <div class="d-flex align-items-center">
                                <div>
                                    <strong class="mb-0 text-secondary">Total Spends</strong>
                                    <h4 class="my-1 text-danger">{{CountSpend.countUnpaid}} DH</h4>
                                </div>
                                <div class="widgets-icons-2 rounded-circle bg-gradient-bloody text-white ms-auto"><i class="fa fa-ban"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
            <div class="col-lg-4">
                    <div class="card radius-10 border-start border-0 border-3 border-success">
                        <div class="card-body">
                            <div class="d-flex align-items-center">
                                <div>
                                    <strong class="mb-0 text-secondary">Total Income</strong>
                                    <h4 class="my-1 text-success">{{CountSpend.countPaid}} DH</h4>
                                </div>
                                <div class="widgets-icons-2 rounded-circle bg-gradient-ohhappiness text-white ms-auto"><i class="fa fa-check-square"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="col-lg-4">
                    <div class="card radius-10 border-start border-0 border-3 border-warning">
                        <div class="card-body">
                            <div class="d-flex align-items-center">
                                <div>
                                    <strong class="mb-0 text-secondary">The Rest</strong>
                                    <h4 class="my-1 text-orange">{{CountSpend.countPaid - CountSpend.countUnpaid}} DH</h4>
                                    <!-- <p class="mb-0 font-13 text-danger">{{(CountBooking.countCancelled*100 /CountBooking.countTotal) | FormNumbers2}} %</p> -->
                                </div>
                                <div class="widgets-icons-2 rounded-circle bg-gradient-blooker text-white ms-auto"><i class="fa fa-cogs"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="mb-3 mt-3">
                <el-card class="mb-3 mt-3">
                <div class="row">
                    <div class="col-6 mt-3">
                        <el-button type="primary" @click="newSpend()" size="small" icon="el-icon-plus" >Add New Spend</el-button>
                    </div>
                    <div class="col-6 mt-3">
                        <el-select @change="loadSpendsByMonth" v-model="month" style="width: 100%" placeholder="Filter By Month">
                            <el-option label="Filter By Month" value=""></el-option>
                            <el-option label="January" value="01"></el-option>
                            <el-option label="February" value="02"></el-option>
                            <el-option label="March" value="03"></el-option>
                            <el-option label="April" value="04"></el-option>
                            <el-option label="May" value="05"></el-option>
                            <el-option label="June" value="06"></el-option>
                            <el-option label="July" value="07"></el-option>
                            <el-option label="August" value="08"></el-option>
                            <el-option label="September" value="09"></el-option>
                            <el-option label="October" value="10"></el-option>
                            <el-option label="November" value="11"></el-option>
                            <el-option label="December" value="12"></el-option>
                        </el-select>
                    </div>

                        <div class="col-12 mt-3">
                        <el-table
                                :data="Spending.data" border >
                                <el-table-column type="expand">
                                    <template slot-scope="props">
                                        <el-descriptions class="margin-top" :column="1" border>
                                            <el-descriptions-item>
                                                <template slot="label">
                                                    <i class="el-icon-link"></i>
                                                    Note
                                                </template>
                                                {{ props.row.note}}
                                            </el-descriptions-item>
                                        </el-descriptions>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Type of Spend" align="center">
                                    <template slot-scope="scope">
                                        <strong>{{ scope.row.type }}</strong>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Amount" align="center">
                                    <template slot-scope="scope">
                                        <strong>{{ scope.row.price }} DH</strong>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Date" align="center">
                                    <template slot-scope="scope">
                                        <strong>{{ scope.row.Date | OfferDates }}</strong>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Etat" width="100px" align="center">
                                    <template slot-scope="scope">
                                        <div v-if="scope.row.etat == 'Paid'">
                                            <el-tag  type="success" effect="dark" ><strong>{{ scope.row.etat }}</strong></el-tag>
                                        </div>
                                        <div v-if="scope.row.etat == 'Unpaid'">
                                            <el-tag  type="danger" effect="dark" ><strong>{{ scope.row.etat }}</strong></el-tag>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column width="100" align="center">
                                    <template slot-scope="{row}">
                                            <a href="#" @click="updateSpend(row)" data-toggle="tooltip" data-placement="top" title="Update">
                                                    <i class="fa fa-edit blue mr-2"></i>
                                            </a>
                                            <el-divider direction="vertical"></el-divider>
                                            <a href="#" @click="deletDataSpend(row.id)" data-toggle="tooltip" data-placement="top" title="Delete">
                                                <i class="fa fa-trash red"></i>
                                            </a>
                                    </template>
                                </el-table-column>
                        </el-table>
                        
                        <pagination class="mt-4" :data="Spending" @pagination-change-page="loadspending"></pagination>
                        </div>
                        
                    </div>
                </el-card>
            </div>

             <!-- dialog -->
          <el-dialog
            :title="editmode ? 'Update Spend':'New Spend'"
            :visible.sync="DialogVisible_spend"
            width="90%"
            :before-close="handleClose">
            <!-- Body dialog -->
                <el-form :model="form_spending" ref="form_spending" class="demo-ruleForm">
                    <fieldset class="row  border p-2 mt-2">
                        <legend  class="w-auto badge"><h5>Infos</h5></legend>
                            <el-form-item class="col-lg-4" label="Type" prop="type"
                                :rules="[
                                    { required: true, message: 'Type of Spend is required' }
                                ]">
                                <el-select @change="typechanged" v-model="form_spending.type"  style="width: 100%" placeholder="Type Of Spend">
                                        <el-option label="INCOME" value="Income"></el-option>
                                        <el-option label="Gas-oil" value="Gas-oil"></el-option>
                                        <el-option label="Food" value="Food"></el-option>
                                        <el-option label="Maintenance" value="Maintenance"></el-option>
                                        <el-option label="Tickets" value="Tickets"></el-option>
                                        <el-option label="Autre" value="Autre"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item class="col-lg-4" label="Amount" prop="price" 
                            :rules="[
                                { required: true, message: 'Amount is required' }
                            ]">
                                <el-input v-model="form_spending.price" type="number" autocomplete="off">
                                    <template slot="append"><strong>DH</strong></template>
                                </el-input>
                            </el-form-item>
                            <!-- <el-form-item class="col-lg-3" display label="Etat" prop="etat"
                                :rules="[
                                    { required: true, message: 'Etat of Spend is required' }
                                ]">
                                <el-select v-model="form_spending.etat" style="width: 100%" placeholder="Etat Of Spend">
                                    <el-option label="Unpaid" value="Unpaid"></el-option>    
                                    <el-option label="Paid" value="Paid"></el-option>
                                </el-select>
                            </el-form-item> -->
                            <el-form-item class="col-lg-4" label="Date" prop="Date" >
                                <el-date-picker placeholder="Pick a date" v-model="form_spending.Date" value-format="yyyy-MM-dd" style="width: 100%;"></el-date-picker>
                            </el-form-item>
                            <el-form-item class="col-lg-12" label="Note (Optional)" prop="note">
                                <el-input type="textarea" v-model="form_spending.note" autocomplete="off"></el-input>
                            </el-form-item>
                    </fieldset>
                </el-form>
                <!-- ENd body Dialog -->
                <span slot="footer" class="dialog-footer">
                    <el-button @click="DialogVisible_spend = false">Annuler</el-button>
                    <el-button type="primary" v-show="!editmode" @click="submitFormSpendValide('form_spending')">Valider</el-button>
                    <el-button type="primary" v-show="editmode" @click="updateDataSpend()">Valider</el-button>
                </span>
          </el-dialog>

          <!-- ENd dialog -->
             

          
        </div>
    </section>
</template>
<style>
    .el-table .warning-row {
        background: rgb(235, 195, 121);
    }

    .el-table .success-row {
        background: #97f166;
    }
</style>

<script>

import moment from 'moment'
import numeral from 'numeral'
    export default {

        data () {
            return {
                dashData: false,
                DialogVisible_global: false,
                DialogVisible_spend: false,
                DialogVisible_PDF: false,
                Plateformes: [],
                yearSelected: 2024,
                Tours: [],
                search: '',
                month: '',
                devisETQ: [],
                Fournisseurs: [],
                CountBooking: [],
                BeforD: [],
                Remind_BeforD: [],
                BookingD: [],
                DashSum: [],
                SumByMonth: [],
                countByMonth: [],
                countByProduct: [],
                countByLangue: [],
                Spending: [],
                pdfData: [],
                AllSpends: [],
                CountSpend: [],
                pagesize: 10,
                currpage: 1,
                yearV:'2024',
                tomorrow: '',
               
                // 
                

                form_global: new Form({
                    multipleSelection:[],
                    id:'',
                    NomClient:'',
                    id_platform:'',
                    etat:'',
                    id_pickup:'',
                    id_product:'',
                    nombreAdulte:'',
                    nombreChildren:'',
                    dateJ:'',
                    timeJ:'',
                    dateReserve:'',
                    phone:'',
                    note:'',
                    langue:'',
                    price:'',
                    priceNet:'',
                    currency:'',
                    commission:'',
                    reference:'',
                    duration:'',
                    machineExiste:'',
                    nbr_bikes:'',
                  }),
                // spending
                form_spending : new Form({
                    id:'',
                    id_driver: '',
                    type: '',
                    price: '',
                    etat: '',
                    Date: '',
                    note: '',
                }),

                optionsC: {},
                seriesC: [],

                // Bar1
                optionsX: {
                    chart: {
                    id: 'vuechart-exampleX'
                    },
                    xaxis: {
                    categories: []
                    }
                },
                seriesX: [{
                    name: 'Completed',
                    data: []
                }],
                // Bar2
                optionsY: {
                    chart: {
                    id: 'vuechart-exampleY'
                    },
                    plotOptions: {
                    bar: {
                            // borderRadius: 4,
                            horizontal: true,
                        }
                    },
                    xaxis: {
                    categories: []
                    }
                },
                seriesY: [{
                    name: 'Total',
                    data: []
                }],
                // Bar3
                optionsZ: {
                    chart: {
                    id: 'vuechart-exampleZ'
                    },
                    plotOptions: {
                    bar: {
                            // borderRadius: 4,
                            horizontal: true,
                        }
                    },
                    xaxis: {
                    categories: []
                    }
                },
                seriesZ: [{
                    name: 'Total',
                    data: []
                }],
                // optionsL: {
                //     chart: {
                //     id: 'vuechart-exampleZ'
                //     },
                //     plotOptions: {
                //     bar: {
                //             ontal: true,
                //         }
                //     },
                //     xaxis: {
                //     categories: []
                //     }
                // },
                // seriesL: [{
                //     name: 'Total',
                //     data: []
                // }],
                // 
          series33: [],
          chartOptions33: {
            chart: {
              type: 'donut',
            },
            labels: [],
            plotOptions: {
              pie: {
                startAngle: -90,
                endAngle: 90,
                offsetY: 10
              }
            },
            // grid: {
            //   padding: {
            //     bottom: -80
            //   }
            // },
            // responsive: [{
            //   breakpoint: 480,
            //   options: {
            //     chart: {
            //       width: 200
            //     },
            //     legend: {
            //       position: 'bottom'
            //     }
            //   }
            // }]
          },
                chartOptions3: {
                    chart: {
                    width: 380,
                    type: 'pie',
                    },
                    labels: ['dazdz','dazda','édazda','dazd','dazd'],
                    responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                        width: "200",
                        },
                        legend: {
                        position: 'bottom'
                        }
                    }
                    }]
                },
                series3: [{
                    name:'Total Booking',
                    data: [12,33,21,9,41]
                }],
                options2 : {},
                // Pie 
                chartOptions1: {
                chart: {
                
                type: 'donut',
                },
                labels: [],
                responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                    width: "100%",
                    },
                    legend: {
                    position: 'top'
                    }
                }
                }]
            },
                options1 : {},
                // Pie 
                PieOptions: {
                    
                 },
                series2: [],
                series1: [],
                // labels: [],
                 // chart
                totalBooking:[],
            }

        },

        methods:{

            handleSelectionChange(val){
                this.form_global.multipleSelection = []
                for (let i = 0; i < val.length; i++) {
                    this.form_global.multipleSelection.push(val[i]['id'])
                }
            },

            updatedEtat(){
                
                    
                    this.$Progress.start();

              this.form_global.post('api/UpdateEtatC')
              .then((data)=>{
                if(data.data.success){
                 this.DialogVisible_spend = false

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.$Progress.finish();
                  this.loadBookingsToday();

                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
            },

            typechanged(){

                if(this.form_spending.type == 'Income'){
                    this.form_spending.etat = 'Paid'
                }else{
                    this.form_spending.etat = 'Unpaid'
                }
            },

            updateReservation(reservation){
                this.editmode = true;
                this.form_global.reset();
                this.DialogVisible_global = true;
                this.form_global.fill(reservation);
            },

            updateData(){
              this.$Progress.start();
              this.form_global.put('api/reservation/'+this.form_global.id)
              .then((response) => {
                  // success
                  this.DialogVisible_global = false
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });
                  this.$Progress.finish();

                  this.loadBookingsToday();
              })
              .catch(() => {
                  this.$Progress.fail();
              });

          },

        //   Drivers

            print() {
                this.$htmlToPaper("printMe");
                console.log('print ok')
            },

            getPDF(){
                
                this.$Progress.start();
                axios.get('api/viewPDF').then(({ data }) => (this.pdfData = data.data));
                this.$Progress.finish();
            },  

            async showPDF(){
                await this.getPDF();
                // this.form_spending.reset();
                // this.editmode = false;
                // this.disableDevisPV = false;
                this.DialogVisible_PDF = true;
                // this.active=0;
            },


            loadspending(page = 1){
                
                this.$Progress.start();
                axios.get('api/spending?page=' + page).then(({ data }) => (this.Spending = data.data));
                this.$Progress.finish();
            }, 

            loadSpendsByMonth(){
                
                if(this.month != ""){
                    this.$Progress.start();
                    axios.get('api/filterSpendsDate/' + this.month).then(({ data }) => (this.Spending = data.data));
                    this.$Progress.finish();
                    
                }else{
                    this.loadspending();
                }
            },

            loadCalculsSpend(){
                
                this.$Progress.start();
                    axios.get('api/DashCountSpendByUser').then(({ data }) => (this.CountSpend = data.data));
                this.$Progress.finish();
            },

            newSpend(){
                this.form_spending.reset();
                this.editmode = false;
                // this.disableDevisPV = false;
                this.DialogVisible_spend = true;
                this.active=0;
            },

            submitFormSpendValide(formName) {
                this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.submitFormSpend();
                } else {
                    console.log('error submit!!');
                    return false;
                }
                });
            },
            
            submitFormSpend(){
            this.$Progress.start();

              this.form_spending.post('api/spending')
              .then((data)=>{
                if(data.data.success){
                 this.DialogVisible_spend = false

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.$Progress.finish();
                  this.loadspending();
                  this.loadCalculsSpend();

                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          },

          updateSpend(spending){
                
                this.editmode = true;
                this.form_spending.reset();
                this.form_spending.fill(spending);
                this.DialogVisible_spend = true;
            },

            updateDataSpend(){
              this.$Progress.start();
              this.form_spending.put('api/spending/'+this.form_spending.id)
              .then((response) => {
                  // success
                  this.DialogVisible_spend = false
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });
                  this.$Progress.finish();

                  this.loadspending();
                  this.loadCalculsSpend();
              })
              .catch(() => {
                  this.$Progress.fail();
              });

          },

          deletDataSpend(id){
              Swal.fire({
                  title: 'Attention !',
                  text: "You really want to delete this Spend ??",
                  showCancelButton: true,
                  confirmButtonColor: '#67c23a',
                  cancelButtonColor: '#f56c6c',
                  confirmButtonText: 'Yes, Delete-it ! '
                  }).then((result) => {

                      // Send request to the server
                        if (result.value) {
                              this.form_spending.delete('api/spending/'+id).then(()=>{
                                      Swal.fire(
                                      'Deleted !',
                                      'Spend Deleted Successfully',
                                      'success'
                                      );
                                  // Fire.$emit('AfterCreate');
                                  this.loadspending();
                                  this.loadCalculsSpend();
                              }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                        }
                  })
          },

            // End Drivers
            loadPlateformes(){
                this.$Progress.start();
                axios.get('api/indexPlateformes').then(({ data }) => (this.Plateformes = data.data));
                this.$Progress.finish();
            },

            loadFournisseurs(){
                this.$Progress.start();
                axios.get('api/indexFournisseur').then(({ data }) => (this.Fournisseurs = data.data));
                this.$Progress.finish();
            },

            loadTotalBookings(){
                this.$Progress.start();
                axios.get('api/DashtotalBooking').then(({ data }) => (this.totalBooking = data.data));
                this.$Progress.finish();
            },
            
            loadBookingsByMonth(){
                this.$Progress.start();
                console.log("Get All")
                 axios.get('api/DashtotalByMonth/'+this.yearV).then(({ data }) => (this.countByMonth = data.data));
                 this.$Progress.finish();  
            },

            loadBookingsByProduct(){
                this.$Progress.start();
                axios.get('api/DashtotalByProducts').then(({ data }) => (this.countByProduct = data.data));
                this.$Progress.finish();
            },

            // loadBookingsByLangue(){
            //     this.$Progress.start();
            //     axios.get('api/DashtotalByLangue').then(({ data }) => (this.countByLangue = data.data));
            //     this.$Progress.finish();
            // },

             getChartPie(){

                // console.log("get chartPie")
                // await this.loadBookingsByMonth();
                var mydataX = [];
                var mydataY = [];
                var mydataZ = [];
                var mydataL = [];
                for (let i = 0; i < 10; i++) {
                    mydataY.push(this.totalBooking[i].total)
                    this.optionsY.xaxis.categories.push(this.totalBooking[i].get_plateformes.title)
                }

                for (let i = 0; i < 10; i++) {
                    
                    mydataZ.push(this.countByProduct[i].total)
                    
                    this.optionsZ.xaxis.categories.push(this.countByProduct[i].get_product.title)
                }

                for (let i = 0; i < this.countByMonth.length; i++) {
                    
                    mydataX.push(this.countByMonth[i].total)
                    this.optionsX.xaxis.categories.push(this.countByMonth[i].month)
                    console.log(this.countByMonth[i].total+' -- '+this.countByMonth[i].month)
                }
                this.seriesX = [{
                    data: mydataX
                }]
                this.seriesY = [{
                    data: mydataY
                }]
                this.seriesZ = [{
                    data: mydataZ
                }]

                this.dashData = true;
            },

            loadTours(){
                this.$Progress.start();
                axios.get('api/indexTours').then(({ data }) => (this.Tours = data.data));
                this.$Progress.finish();
            },

            loadCalculs(){
                
                this.$Progress.start();
                    axios.get('api/DashCount').then(({ data }) => (this.CountBooking = data.data));
                this.$Progress.finish();
            },
            loadSUMByMonth(){
                
                if(this.month != ""){
                    this.$Progress.start();
                    axios.get('api/DashSumByMonth/' + this.month).then(({ data }) => (this.DashSum = data.data));
                    this.$Progress.finish();
                    
                }else{
                    this.loadSUMPrices();
                }
            },

           

            loadBookings(){
                
                this.$Progress.start();
                    axios.get('api/BeforD').then(({ data }) => (this.BeforD = data.data));
                this.$Progress.finish();
            },
            loadReminders(){
                
                this.$Progress.start();
                    axios.get('api/Remind_BeforD').then(({ data }) => (this.Remind_BeforD = data.data));
                this.$Progress.finish();
            },
            loadAllSpends(page = 1){
                
                this.$Progress.start();
                    axios.get('api/indexspending?page=' + page).then(({ data }) => (this.AllSpends = data.data));
                this.$Progress.finish();
            },
            loadBookingsToday(){
                this.$Progress.start();
                    axios.get('api/BookingD').then(({ data }) => (this.BookingD = data.data));
                this.$Progress.finish();
            },
            loadSUMPrices(){
                this.$Progress.start();
                    axios.get('api/DashSum').then(({ data }) => (this.DashSum = data.data));
                this.$Progress.finish();
            },
            loadData(page = 1){
                // this.$Progress.start();
                // axios.get('api/devisETQ?page=' + page).then(({ data }) => (this.devisETQ = data.data.DevisETC));
                // this.$Progress.finish();
            },

            filterETC(){
                if(this.search != ''){
                    this.$Progress.start();
                    axios.get('api/filterAll/' + this.search).then(({ data }) => (this.devisETQ = data.data));
                    this.$Progress.finish();
                }else{
                    this.loadData();
                }
            },

            tableRowClassName({row, rowIndex}) {
                if (row.validation3 == 0) {
                    return 'warning-row';
                } else if (row.validation3 == 1) {
                    return 'success-row';
                }
                return '';
            },

             handleCurrentChange(cpage) {
                this.currpage = cpage;
            },
            handleSizeChange(psize) {
                    this.pagesize = psize;
                },

        },

        mounted() {
            console.log('Component mounted.')
            const tomorrow = new Date()
            tomorrow.setDate(new Date().getDate() + 1)
            this.tomorrow = tomorrow.toLocaleDateString()
        },
        created() {
            this.$Progress.start();
                
                // Spending
                this.loadAllSpends();
                this.loadspending();
                this.loadCalculsSpend();
                // 
                this.loadData();
                this.loadCalculs();
                this.loadReminders();
                this.loadBookings();
                this.loadBookingsToday();
                // this.loadSUMPrices();
                this.loadPlateformes();
                this.loadTours();
                this.loadTotalBookings();
                this.loadFournisseurs();
                this.loadBookingsByMonth();
                this.loadBookingsByProduct();
                // this.loadBookingsByLangue();

            this.$Progress.finish();
        },
        filters: {
            OfferDates(value){
                return moment(String(value)).format('DD-MM-YYYY')
            },
            FormNumbers2(value){
                  return numeral(value).format("0.00");
              },
              truncate: function (text, length, suffix) {
                  return text.substring(0, length) + suffix;
              },
          },
          
    }
</script>
<style>
@import '../components/Travel/cardsStyle.css';
@import '../components/Travel/tableStyle.css';
</style>