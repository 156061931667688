<template>
  <section class="content">
    <div class="container-fluid">
        <!-- Liste Projets -->
       
        <div class="mb-3 mt-3">
                <el-card>
                    <div class="row">
                        <div class="col-6">
                            <h4>Liste des Projets Urgent</h4>
                        </div>
                        <div class="col-6">
                            <el-input v-model="search1" @change="filterProjet" size="mini" placeholder="Filter : "> 
                                <el-button slot="append" icon="el-icon-search"></el-button>
                            </el-input>
                        </div>
                        <div class="col-12 mt-3">
                            <el-table
                                :data="projectsUrgent.data"
                                border >
                            <el-table-column label="Client">
                                <template slot-scope="scope">
                                    <span>{{ scope.row.get_client.name }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="N° Projet" width="100">
                                <template slot-scope="scope">
                                    <span>{{ scope.row.id }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="Réf" width="55">
                                <template slot-scope="scope">
                                    <span>{{ scope.row.id_etc }}</span>
                                </template>
                            </el-table-column>
                            
                            <el-table-column label="Nom D'étiquette">
                                <template slot-scope="scope">
                                    <span>{{ scope.row.get_devis_e_t_c.nom_etc }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column align="right" label="Quantité a lancer" width="150">
                                <template slot-scope="scope">
                                    <strong>{{ scope.row.qtit_lancer | FormNumbers2 }}</strong>
                                </template>
                            </el-table-column>
                            <el-table-column align="right" label="Quantité Urgent" width="150">
                                <template slot-scope="scope" >
                                    <el-tag
                                        type="danger"
                                        effect="plain">
                                        <strong>{{ scope.row.qtit_urgence | FormNumbers2 }}</strong>
                                    </el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column label="Etat" >
                                <template slot-scope="scope">
                                        <el-tag effect="dark">
                                            {{ scope.row.etat_projet }}
                                        </el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column width="50" align="center">
                                <template slot-scope="{row}">
                                    <router-link  :to="`/profileProjet/${row.id}/${row.id_etc}`" data-toggle="tooltip" data-placement="top" title="Profile">
                                            <i class="fa fa-eye green"></i>
                                    </router-link>
                                    <!-- :<el-divider v-if="$gate.isAdmin()" direction="vertical"></el-divider> -->
                                    <!-- <a href="#" @click="updateProjet(row)" data-toggle="tooltip" data-placement="top" title="Modifier">
                                        <i class="fa fa-edit blue"></i>
                                    </a>
                                    <el-divider direction="vertical"></el-divider> -->
                                    <!-- <a href="#" v-if="$gate.isAdmin()" @click="deletData(row.id)" data-toggle="tooltip" data-placement="top" title="Supprimer">
                                        <i class="fa fa-trash red"></i>
                                    </a> -->
                                </template>
                            </el-table-column>
                            </el-table>
                            <!--  -->
                            <pagination class="mt-4" :data="projectsUrgent" @pagination-change-page="loadProjectsUrgent"></pagination>
                        </div>
                    </div>
                </el-card>
                <!-- Normal -->
                <el-card class="mt-5">
                    <div class="row">
                        <div class="col-6">
                            <h4>Liste Des Projets</h4>
                        </div>
                        <div class="col-6">
                            <el-input v-model="search1" @change="filterProjet" size="mini" placeholder="Filter : "> 
                                <el-button slot="append" icon="el-icon-search"></el-button>
                            </el-input>
                        </div>
                        <div class="col-12 mt-3">
                            <el-table
                                :data="projects.data"
                                border >
                            <el-table-column label="N° Projet" width="100">
                                <template slot-scope="scope">
                                    <span>{{ scope.row.id }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="Réf" width="55">
                                <template slot-scope="scope">
                                    <span>{{ scope.row.id_etc }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="Nom D'étiquette">
                                <template slot-scope="scope">
                                    <span>{{ scope.row.get_devis_e_t_c.nom_etc }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="Date Livraison">
                                <template slot-scope="scope">
                                    <span>{{ scope.row.date_livraison }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column align="right" label="Quantité a lancer" width="150">
                                <template slot-scope="scope">
                                    <strong>{{ scope.row.qtit_lancer | FormNumbers2 }}</strong>
                                </template>
                            </el-table-column>
                            <el-table-column label="Etat">
                                <template slot-scope="scope">
                                        <div v-if="scope.row.etat_projet == 'PFD à valider'">
                                            <el-tag  type="warning" effect="dark" ><strong>{{ scope.row.etat_projet }}</strong></el-tag>
                                        </div>
                                        <div v-else-if="scope.row.etat_projet == 'Plaque à commander'">
                                            <el-tag  type="success" effect="dark" ><strong>{{ scope.row.etat_projet }}</strong></el-tag>
                                        </div>
                                        <div v-else>
                                            <el-tag  type="" effect="dark" ><strong>{{ scope.row.etat_projet }}</strong></el-tag>
                                        </div>
                                       
                                </template>
                            </el-table-column>
                            <el-table-column v-if="$gate.isGetProjects()" width="50" align="center">
                                <template slot-scope="{row}">
                                    <router-link  :to="`/profileProjet/${row.id}/${row.id_etc}`" data-toggle="tooltip" data-placement="top" title="Profile">
                                            <i class="fa fa-eye green"></i>
                                    </router-link>
                                    <!-- :<el-divider v-if="$gate.isAdmin()" direction="vertical"></el-divider> -->
                                    <!-- <a href="#" @click="updateProjet(row)" data-toggle="tooltip" data-placement="top" title="Modifier">
                                        <i class="fa fa-edit blue"></i>
                                    </a>
                                    <el-divider direction="vertical"></el-divider> -->
                                    <!-- <a href="#" v-if="$gate.isAdmin()" @click="deletData(row.id)" data-toggle="tooltip" data-placement="top" title="Supprimer">
                                        <i class="fa fa-trash red"></i>
                                    </a> -->
                                </template>
                            </el-table-column>
                            </el-table>
                            <!--  -->
                            <pagination class="mt-4" :data="projects" @pagination-change-page="loadProjects"></pagination>
                        </div>
                    </div>
                </el-card>
            </div>
        <!-- End -->
        <!-- dialog Projet-->
        <el-dialog
            :title="editmode ? 'Modifier Projet':''"
            :visible.sync="DialogVisible"
            width="90%"
            :before-close="handleClose">
            <!-- Body dialog -->
                <el-form :model="form" :rules="rules">
                    <fieldset class="border p-2 mt-2">
                            <legend class="w-auto badge"><h5></h5></legend>
                            <div class="row">
                                <el-form-item
                                    class="col-lg-6"
                                    label="Nom d'étiquette"
                                    prop="nom_etc"
                                >
                                    <el-input type="text" v-model="form.nom_etc" autocomplete="off"></el-input>
                                </el-form-item>
                                <el-form-item
                                    class="col-lg-6"
                                    label="Date"
                                    prop="date"
                                >
                                    <el-date-picker type="date" placeholder="Pick a date" v-model="form.date" style="width: 100%;"></el-date-picker>
                                </el-form-item>
                            </div>
                    </fieldset>
                    <fieldset class="border p-2 mt-2">
                            <legend class="w-auto badge"><h5>Client</h5></legend>
                            <div class="row">
                                <el-form-item 
                                    class="col-lg-4" 
                                    prop="id_client" 
                                    label="Client"
                                >
                                    <el-select v-model="form.id_client" style="width: 100%" placeholder="Please select a client">
                                        <el-option
                                            v-for="item in clients"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item
                                    class="col-lg-4"
                                    label="N° Télephone"
                                    prop="tele_client"
                                >
                                    <el-input type="text" v-model="form.tele_client" autocomplete="off"></el-input>
                                </el-form-item>
                                <el-form-item
                                    class="col-lg-4"
                                    label="Email"
                                    prop="email_client"
                                >
                                    <el-input type="email" v-model="form.email_client" autocomplete="off"></el-input>
                                </el-form-item>
                                <el-form-item
                                    class="col-lg-4"
                                    label="Adress"
                                    prop="adress_client"
                                >
                                    <el-input type="text" v-model="form.adress_client" autocomplete="off"></el-input>
                                </el-form-item>
                            </div>
                    </fieldset>
                    <fieldset class="border p-2 mt-2">
                            <legend class="w-auto badge"><h5></h5></legend>
                            <div class="row">
                                <div class="col-lg-3">
                                    <div class="row">
                                        <el-form-item 
                                            class="col-6" 
                                            prop="formatMM_L" 
                                            label="Format en (mm)"
                                             >
                                            <el-input type="number" placeholder="L" v-model="form.formatMM_L" autocomplete="off"></el-input>

                                        </el-form-item>
                                         <el-form-item 
                                            class="col-6" 
                                            prop="formatMM_D"
                                            label=":" 
                                            >
                                            <el-input type="number" placeholder="D" v-model="form.formatMM_D" autocomplete="off"></el-input>

                                        </el-form-item>
                                    </div>
                                </div>
                                
                                <el-form-item 
                                    class="col-lg-3" 
                                    prop="forme_etq" 
                                    label="Form d'étiquette"
                                >
                                    <el-select v-model="form.forme_etq" style="width: 100%" placeholder="Please select Form d'étiquitte">
                                        <el-option label="REC" value="REC"></el-option>
                                        <el-option label="OVA" value="OVA"></el-option>
                                        <el-option label="CAR" value="CAR"></el-option>
                                        <el-option label="RONDER" value="RONDER"></el-option>
                                        <el-option label="TRIANGULAIRE" value="TRIANGULAIRE"></el-option>
                                        <el-option label="SPECIALE" value="SPECIALE"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item 
                                    class="col-lg-3" 
                                    prop="support_papier" 
                                    label="Support Papier"
                                >
                                    <el-select v-model="form.support_papier" style="width: 100%" placeholder="Please select Support Papier">
                                        <el-option label="Thermique" value="Thermique"></el-option>
                                        <el-option label="Couché" value="Couché"></el-option>
                                        <el-option label="PP" value="PP"></el-option>
                                        <el-option label="PVC" value="PVC"></el-option>
                                        <el-option label="Fruit TAG" value="Fruit TAG"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item 
                                    class="col-lg-3" 
                                    prop="quantite_etq" 
                                    label="Quantité"
                                >
                                    <el-input type="number" v-model="form.quantite_etq" autocomplete="off"></el-input>
                                </el-form-item>
                            </div>
                    </fieldset>
                    <fieldset class="border p-2 mt-2">
                            <legend class="w-auto badge"><h5>Impression</h5></legend>
                            <el-form-item 
                                class="text-center"
                                prop="impression"
                            >
                                <el-radio-group v-model="form.impression">
                                    <el-radio label="OUI" ></el-radio>
                                    <el-radio label="Non" ></el-radio>
                                </el-radio-group>
                            </el-form-item>
                    </fieldset>
                    <fieldset  v-if="form.impression == 'OUI'" class="border p-2 mt-2 ">
                            <legend class="w-auto badge"><h5></h5></legend>
                            <div class="row">
                                <el-form-item 
                                    class="col-lg-4" 
                                    prop="nbr_clouleur" 
                                    label="Nombre Couleurs :"
                                >
                                    <el-input type="number" v-model="form.nbr_clouleur" autocomplete="off"></el-input>

                                </el-form-item>
                                
                                <el-form-item 
                                    class="col-lg-4" 
                                    prop="recto" 
                                    label="Recto :"
                                >
                                    <el-radio-group label="top" style="width:100%" v-model="form.recto">
                                        <el-radio label="OUI" ></el-radio>
                                        <el-radio label="Non" ></el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <el-form-item 
                                    class="col-lg-4" 
                                    prop="verso" 
                                    label="Verso :"
                                >
                                    <el-radio-group label="top" style="width:100%" v-model="form.verso">
                                        <el-radio label="OUI" ></el-radio>
                                        <el-radio label="Non" ></el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <div class="col-12"></div>
                                <div v-if="form.recto == 'OUI'" class="form-group col-6">
                                        <fieldset class="border p-2">
                                        <legend class="w-auto badge badge-warning"><h6> Composants d'encre en recto </h6></legend>
                                        <tr class="form-group padding: 7px; col-lg-12" v-for="(c, k) in form.couleurs" :key="k">

                                            <td style="width: 150px; padding: 7px;">
                                                <input class="form-control" placeholder="Couleur" type="text" v-model="c.couleur"
                                                />
                                            </td>
                                            <td style="width: 150px; padding: 7px;">
                                                <input class="form-control" placeholder="Ref" type="text" v-model="c.ref"
                                                />
                                            </td>
                                            <td style="width: 150px; padding: 7px;">
                                                <input class="form-control" placeholder="Formule" type="text"  v-model="c.formule"
                                                />
                                            </td>

                                            <td scope="row" class="trashIconContainer">
                                                <svg
                                                    v-show="form.couleurs.length > 1"
                                                    @click="deleteRow(k, c)"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 24 24"
                                                    width="24"
                                                    height="24"
                                                    class="ml-2 cursor-pointer"
                                                >
                                                    <path fill="none" d="M0 0h24v24H0z" />
                                                    <path
                                                    fill="#EC4899"
                                                    d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
                                                    />
                                                </svg>
                                            </td>
                                        </tr>
                                        <div class="form-group ml-2 float-right">
                                            <svg  
                                                @click="addNewRow"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 24 24"
                                                    width="24"
                                                    height="24"
                                                    class="ml-2 cursor-pointer"
                                                    >
                                                    <path fill="none" d="M0 0h24v24H0z" />
                                                    <path
                                                    fill="green"
                                                    d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                                                    />
                                                </svg>
                                            </div>
                                        </fieldset>
                                    </div>
                                    <div v-if="form.verso == 'OUI'" class="col-6">
                                        <fieldset class="border p-2">
                                        <legend class="w-auto badge badge-warning"><h6> Composants d'encre en verso </h6></legend>
                                        <tr class="form-group padding: 7px; col-lg-12">

                                            <td style="width: 200px; padding: 7px;">
                                                <input class="form-control" placeholder="Couleur" type="text" v-model="form.verso_couleur"
                                                />
                                            </td>
                                            <td style="width: 200px; padding: 7px;">
                                                <input class="form-control" placeholder="Ref" type="text" v-model="form.verso_ref"
                                                />
                                            </td>
                                            <td style="width: 200px; padding: 7px;">
                                                <input class="form-control" placeholder="Formule" type="text"  v-model="form.verso_formule"
                                                />
                                            </td>

                                        </tr>

                                        </fieldset>
                                    </div>
                            </div>
                    </fieldset>
                    <fieldset v-if="form.impression == 'OUI'" class="border p-2">
                            <legend class="w-auto badge badge-success"><h6> Sens De Sortie </h6></legend>
                            <div class="row">
                                <div class="form-group col">
                                    <fieldset class="border p-2">
                                        <legend class="w-auto badge badge-warning"><h6> Sens de sortie : Sans impression </h6></legend>
                                     <div class="row">
                                            <div class="col">
                                                <div class='text-center'>
                                                    <input type="radio" v-model="form.sens_sortie" name="sansImpression" id="sansImpressionimg1" class="d-none imgbgchk" value="externe">
                                                    <label for="sansImpressionimg1">
                                                        <img src="/images/form1C.png" alt="Image 1">
                                                        <div class="tick_container">
                                                        <div class="tick"><i class="fa fa-check"></i></div>
                                                        </div>
                                                    </label>
                                                    <p> EXTERNE </p>
                                                </div>
                                            </div>
                                            <div class="col">
                                               <div class='text-center'>
                                                    <input type="radio" v-model="form.sens_sortie" name="sansImpression" id="sansImpressionimg2" class="d-none imgbgchk" value="interne">
                                                    <label for="sansImpressionimg2">
                                                        <img src="/images/form2C.png" alt="Image 1">
                                                        <div class="tick_container">
                                                        <div class="tick"><i class="fa fa-check"></i></div>
                                                        </div>
                                                    </label>
                                                    <p> INTERNE </p>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                                <!-- externe -->
                                <div v-if="form.sens_sortie == 'externe'" class="form-group col">
                                    <fieldset class="border p-2">
                                        <legend class="w-auto badge badge-warning"><h6> EXTERNE </h6></legend>
                                            <div class="row">
                                                <div class="col">
                                                <div class='text-center'>
                                                        <input type="radio" v-model="form.type_sense_sortie" name="avecImpressionEX" id="avecImpressionEXimg1" class="d-none imgbgchk" value="e_up">
                                                        <label for="avecImpressionEXimg1">
                                                            <img src="/images/form1T.png" alt="Image 1">
                                                            <div class="tick_container">
                                                            <div class="tick"><i class="fa fa-check"></i></div>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="col">
                                                <div class='text-center'>
                                                        <input type="radio" name="avecImpressionEX" v-model="form.type_sense_sortie" id="avecImpressionEXimg2" class="d-none imgbgchk" value="e_down">
                                                        <label for="avecImpressionEXimg2">
                                                            <img src="/images/form1B.png" alt="Image 1">
                                                            <div class="tick_container">
                                                            <div class="tick"><i class="fa fa-check"></i></div>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="col">
                                                <div class='text-center'>
                                                        <input type="radio" v-model="form.type_sense_sortie" name="avecImpressionEX" id="avecImpressionEXimg3" class="d-none imgbgchk" value="e_left">
                                                        <label for="avecImpressionEXimg3">
                                                            <img src="/images/form1R.png" alt="Image 1">
                                                            <div class="tick_container">
                                                            <div class="tick"><i class="fa fa-check"></i></div>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="col">
                                                <div class='text-center'>
                                                        <input type="radio" v-model="form.type_sense_sortie" name="avecImpressionEX" id="avecImpressionEXimg4" class="d-none imgbgchk" value="e_right">
                                                        <label for="avecImpressionEXimg4">
                                                            <img src="/images/form1.png" alt="Image 1">
                                                            <div class="tick_container">
                                                            <div class="tick"><i class="fa fa-check"></i></div>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                    </fieldset>
                                </div>
                                <!-- Interne -->
                                <div v-if="form.sens_sortie == 'interne'" class="form-group col">
                                    <fieldset class="border p-2">
                                        <legend class="w-auto badge badge-warning"><h6> INTERNE </h6></legend>
                                            <div class="row">
                                                 <div class="col">
                                                    <div class='text-center'>
                                                            <input type="radio" v-model="form.type_sense_sortie" name="avecImpressionIN" id="avecImpressionINimg1" class="d-none imgbgchk" value="i_up">
                                                            <label for="avecImpressionINimg1">
                                                                <img src="/images/form2T.png" alt="Image 1">
                                                                <div class="tick_container">
                                                                <div class="tick"><i class="fa fa-check"></i></div>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                    <div class='text-center'>
                                                            <input type="radio" v-model="form.type_sense_sortie" name="avecImpressionIN" id="avecImpressionINimg2" class="d-none imgbgchk" value="i_down">
                                                            <label for="avecImpressionINimg2">
                                                                <img src="/images/form2B.png" alt="Image 1">
                                                                <div class="tick_container">
                                                                <div class="tick"><i class="fa fa-check"></i></div>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                    <div class='text-center'>
                                                            <input type="radio" v-model="form.type_sense_sortie" name="avecImpressionIN" id="avecImpressionINimg3" class="d-none imgbgchk" value="i_right">
                                                            <label for="avecImpressionINimg3">
                                                                <img src="/images/form2R.png" alt="Image 1">
                                                                <div class="tick_container">
                                                                <div class="tick"><i class="fa fa-check"></i></div>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                    <div class='text-center'>
                                                            <input type="radio" v-model="form.type_sense_sortie" name="avecImpressionIN" id="avecImpressionINimg4" class="d-none imgbgchk" value="i_left">
                                                            <label for="avecImpressionINimg4">
                                                                <img src="/images/form2L.png" alt="Image 1">
                                                                <div class="tick_container">
                                                                <div class="tick"><i class="fa fa-check"></i></div>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>
                                             </div>
                                    </fieldset>
                                </div>
                                <!--  -->
                            </div>
                        </fieldset>
                        <fieldset class="border p-2 mt-2 ">
                            <legend class="w-auto badge"><h5></h5></legend>
                            <div class="row">
                                <el-form-item 
                                    class="col-lg-3" 
                                    prop="repiquage" 
                                    label="Repiquage :"
                                >
                                    <el-radio-group label="top" style="width:100%" v-model="form.repiquage">
                                        <el-radio label="OUI" ></el-radio>
                                        <el-radio label="Non" ></el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <el-form-item 
                                    class="col-lg-3" 
                                    prop="vernis" 
                                    label="Vernis :"
                                >
                                    <el-radio-group label="top" style="width:100%" v-model="form.vernis">
                                        <el-radio label="OUI" ></el-radio>
                                        <el-radio label="Non" ></el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <el-form-item 
                                    class="col-lg-3" 
                                    prop="plastification" 
                                    label="Plastification :"
                                >
                                    <el-radio-group label="top" style="width:100%" v-model="form.plastification">
                                        <el-radio label="OUI" ></el-radio>
                                        <el-radio label="Non" ></el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <el-form-item 
                                    class="col-lg-3" 
                                    prop="dorure" 
                                    label="Dorure :"
                                >
                                    <el-radio-group label="top" style="width:100%" v-model="form.dorure">
                                        <el-radio label="OUI" ></el-radio>
                                        <el-radio label="Non" ></el-radio>
                                    </el-radio-group>
                                </el-form-item>
                            </div>
                    </fieldset>
                    <fieldset class="border p-2 mt-2">
                            <legend class="w-auto badge"><h5>Conception</h5></legend>
                            <el-form-item 
                                class="text-center"
                                prop="conception"
                            >
                                <el-radio-group v-model="form.conception">
                                    <el-radio label="OUI" ></el-radio>
                                    <el-radio label="Non" ></el-radio>
                                </el-radio-group>
                            </el-form-item>
                    </fieldset>
                    <fieldset class="border p-2 mt-2 ">
                            <legend class="w-auto badge"><h5></h5></legend>
                            <div class="row">
                                <el-form-item 
                                    class="col-lg-3" 
                                    prop="nbr_etq_bobine" 
                                    label="Nbr Etq/Bobine :"
                                >
                                
                                <el-input type="number" v-model="form.nbr_etq_bobine" autocomplete="off"></el-input>

                                </el-form-item>
                                <el-form-item 
                                    class="col-lg-3" 
                                    prop="nbr_etq_front" 
                                    label="Nbr Etq/Front :"
                                >
                                
                                <el-input type="number" v-model="form.nbr_etq_front" autocomplete="off"></el-input>

                                </el-form-item>
                                <el-form-item 
                                    class="col-lg-3" 
                                    prop="mandrin" 
                                    label="Mandrin :"
                                >
                                    <el-radio-group label="top" style="width:100%" v-model="form.mandrin">
                                        <el-radio label="40"></el-radio>
                                        <el-radio label="76"></el-radio>
                                        <el-radio label="Autre"></el-radio>
                                    </el-radio-group>
                                    <el-input v-if="form.mandrin == 'Autre'" type="number" placeholder="Saisie Mandrin ici" v-model="form.mandrin_autre" autocomplete="off"></el-input>
                                </el-form-item>
                                <el-form-item 
                                    class="col-lg-3" 
                                    prop="poses_etq" 
                                    label="Poses d'étiquette:"
                                >
                                    <el-radio-group label="top" style="width:100%" v-model="form.poses_etq">
                                        <el-radio label="Manuelle"></el-radio>
                                        <el-radio label="Auto"></el-radio>
                                    </el-radio-group>
                                </el-form-item>
                            </div>
                    </fieldset>
                    <fieldset class="border p-2 mt-2">
                            <legend class="w-auto badge"><h5>Existance Films, Cliches Et Forme De Decoupe</h5></legend>
                            <div class="row">
                                <div class="col-lg-6">
                                    <fieldset class="border p-2">
                                        <legend class="w-auto badge badge-inf">Form De Decoupe</legend>
                                        <div class="row">
                                                <el-form-item 
                                                    class="col text-center"
                                                    prop="form_decoupe"
                                                    >
                                                    <el-radio-group v-model="form.form_decoupe">
                                                        <el-radio label="OUI" ></el-radio>
                                                        <el-radio label="Non" ></el-radio>
                                                    </el-radio-group>
                                                    
                                                    <el-input v-if="form.form_decoupe == 'OUI'" type="text" placeholder="Saisie Form Decoupe" v-model="form.form_decoupe_id" autocomplete="off"></el-input>

                                                </el-form-item>

                                        </div>
                                    </fieldset>
                                </div>
                                <div class="col-lg-6">
                                    <fieldset class="border p-2">
                                        <legend class="w-auto badge badge-inf">FILMS ET CLICHES</legend>
                                        <div class="row">
                                                <el-form-item 
                                                    class="col text-center"
                                                    prop="films_cliches"
                                                    >
                                                    <el-radio-group v-model="form.films_cliches">
                                                        <el-radio label="OUI" ></el-radio>
                                                        <el-radio label="Non" ></el-radio>
                                                    </el-radio-group>
                                                    
                                                    <el-input v-if="form.films_cliches == 'OUI'" type="text" placeholder="Saisie Cliché" v-model="form.films_cliches_id" autocomplete="off"></el-input>

                                                </el-form-item>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                    </fieldset>
                    <fieldset class="border p-2 mt-2">
                            <legend class="w-auto badge"><h5>Modalité de Facturation</h5></legend>
                            <el-form-item 
                                class="text-center"
                                prop="modalite_paiment"
                            >
                                <el-radio-group v-model="form.modalite_paiment">
                                    <el-radio label="AT" ></el-radio>
                                    <el-radio label="TVA" ></el-radio>
                                    <el-radio label="AT + TVA" ></el-radio>
                                    <el-radio label="Comptant" ></el-radio>
                                </el-radio-group>
                            </el-form-item>
                    </fieldset>
                    <fieldset class="border p-2 mt-2">
                            <legend class="w-auto badge"><h5>Validation De La Commande</h5></legend>
                            <div class="row">
                                <!-- <el-form-item 
                                    class="text-center col-4"
                                    prop="validation1"
                                >
                                    <el-checkbox v-model="form.validation1" label="Client / Commercial"></el-checkbox>
                                </el-form-item> -->
                                <el-form-item 
                                    class="text-center col-4"
                                    prop="validation1"
                                >
                                    <el-switch
                                        v-model="form.validation1"
                                        active-value="1"
                                        inactive-value="0"
                                        active-color="#13ce66"
                                        inactive-color="#ff4949"
                                        inactive-text="Client / Commercial"
                                    >
                                    </el-switch>
                                </el-form-item>
                                <el-form-item 
                                    class="text-center col-4"
                                    prop="validation2"
                                >
                                    <el-switch
                                        v-model="form.validation2"
                                        active-value="1"
                                        inactive-value="0"
                                        active-color="#13ce66"
                                        inactive-color="#ff4949"
                                        inactive-text="Facturation"
                                    >
                                    </el-switch>
                                </el-form-item>
                                <el-form-item 
                                    class="text-center col-4"
                                    prop="validation3"
                                >
                                    <el-switch
                                        v-model="form.validation3"
                                        active-value="1"
                                        inactive-value="0"
                                        active-color="#13ce66"
                                        inactive-color="#ff4949"
                                        inactive-text="Administration"
                                        
                                    >
                                    </el-switch>
                                </el-form-item>
                            </div>
                    </fieldset>
                    
                </el-form>
            <!-- ENd body Dialog -->
            <span slot="footer" class="dialog-footer">
                <el-button @click="DialogVisible = false">Cancel</el-button>
                <el-button type="primary" v-show="!editmode" @click="createData()">Ajouter</el-button>
                <el-button type="primary" v-show="editmode" @click="updateData()">Modifier</el-button>
            </span>
        </el-dialog>
        <!-- End dialog -->

        <!-- Urgence Model -->
        <div class="modal fade" id="addNewUrgence" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Ajouter</h5>
                    <h5 class="modal-title" v-show="editmode">Modifier</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form>
                    <div class="modal-body">
                        <div class="form-group">
                            <label>Nombre Bobine</label>
                            <input v-model="form.name" type="number" name="name"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('name') }">
                            <has-error :form="form" field="name"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Date / Heure</label>
                            <input v-model="form.description" type="datetime-local" name="description"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('description') }">
                            <has-error :form="form" field="description"></has-error>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <el-button type="danger" size="small" data-dismiss="modal" plain>Annuler</el-button>
                        <!-- <el-button type="success" v-show="editmode" size="small" @click="updateProduct()" plain>Valider</el-button>
                        <el-button type="success" v-show="!editmode" size="small" @click="createProduct()" plain>Valider</el-button> -->
                    </div>
                  </form>
                </div>
            </div>
        </div>

        <!-- ENd Urgence Model -->
    </div>
  </section>
</template>

<script>
    import VueTagsInput from '@johmun/vue-tags-input';
    import numeral from 'numeral'
    export default {
      components: {
          VueTagsInput,
        },
        data () {
            return {
                DialogVisible: false,
                projects: [],
                projectsUrgent: [],
                clients: [],

                search1: '',
                pagesize1: 10,
                currpage1: 1,
                editmode: false,

                // rules validation formulair
                rules: {
                    typeEtiquette: [
                        { required: true, message: 'Please etiquette type', trigger: 'blur' },
                    ],
                    etiquet_name: [
                        { required: true, message: 'Please input étiquette name', trigger: 'change' }
                    ],
                    date: [
                        { type: 'date', required: true, message: 'Please pick a date', trigger: 'change' }
                    ],
                    id_client: [
                        { required: true, message: 'Please select a client', trigger: 'change' }
                    ],
                },
                // end rules
                
                form: new Form({
                    id:'',
                    typeEtiquette: '',
                    nom_etc:'',
                    conception:'',
                    id_client:'',
                    formatMM_L:'',
                    date : new Date().toISOString().slice(0,10),
                    formatMM_D : '',
                    forme_etq:'',
                    support_papier : '', 
                    quantite_etq:'',
                    impression:'',
                    nbr_clouleur:'',
                    couleurs: [{  couleur: "",ref:"",formule:"" }],
                    recto:'',
                    verso:'',
                    verso_couleur:'',
                    verso_ref:'',
                    verso_formule:'',
                    sens_sortie:'',
                    type_sense_sortie:'',
                    repiquage:'',
                    vernis:'',
                    plastification:'',
                    dorure:'',
                    nbr_etq_bobine : '',
                    nbr_etq_front: '',
                    mandrin: '',
                    mandrin_autre:'',
                    poses_etq: '',
                    form_decoupe: '',
                    form_decoupe_id: '',
                    films_cliches: '',
                    films_cliches_id: '',
                    modalite_paiment: '',
                    // Preparation
                    Machine:'',
                    Z_cylindre : '',
                    nombre_PosesD : '',
                    Nombre_PosesL : '',
                    anamorphose : '',
                    developer_Calculer: '',
                    espace_D: '',
                    espace_L: '',
                    nombre_poseT : '',
                    laize_tehorique: '',
                    laize_Stock: '',
                    qtit_PapierML : '',
                    qtit_PapierM2 : '' ,
                    nbr_bobine_consomme : '' ,
                    vrTirage: '',
                    vr:'',
                    tirage : '' ,
                    nbr_mandrin_consomme : '' ,
                    format_mandrin : '' ,
                    cout_revien_mandrin : '' ,
                    frais_recto : '' ,
                    frais_verso : '' ,
                    FTotal_Cliches : '' ,
                    frais_formDecoup : '' ,
                    cout_hors_FDC : '' ,
                    marge_benif : '' ,
                    prixHTT : '' ,
                    duree_production : '' ,
                    duree_conditionement : '' ,
                    estimation_livraison : '' ,
                    preparation:'',
                    // Validation
                    validation1: '',
                    validation2: '',
                    validation3: '',
                }),
            }
        },
        methods: {

            objectSpanMethod({ row, column, rowIndex, columnIndex }) {
                if (columnIndex === 0) {
                    if (rowIndex % 2 === 0) {
                        return {
                        rowspan: 2,
                        colspan: 1
                        };
                    } else {
                        return {
                        rowspan: 0,
                        colspan: 0
                        };
                    }
                }
            },

            // crud Project
           
            loadProjects(page = 1){
                this.$Progress.start();
                    axios.get('api/ProjetLancer?page=' + page).then(({ data }) => (this.projects = data.data.ProjetNormal));
                this.$Progress.finish();
            },

            loadProjectsUrgent(page = 1){
                this.$Progress.start();
                    axios.get('api/ProjetLancer?page=' + page).then(({ data }) => (this.projectsUrgent = data.data.ProjetUrgent));
                this.$Progress.finish();
            },

            loadClients(){
                this.$Progress.start();
                    axios.get("api/indexClients").then(({ data }) => (this.clients = data.data));
                this.$Progress.finish();
            },

            filterProjet(){
                if(this.search1 != ''){
                    this.$Progress.start();
                    axios.get('api/filterProjet/' + this.search1).then(({ data }) => (this.projects.data = data.data));
                    this.$Progress.finish();
                }else{
                    this.loadProjects();
                }
            },

            updateProjet(devis){
                this.editmode = true;
                this.form.reset();
                this.DialogVisible = true;
                this.form.fill(devis);
                this.form.couleurs = [];
                for (let i = 0; i < devis.get_couleurs.length; i++) {
                    this.form.couleurs.push({
                        id_couleur: devis.get_couleurs[i].id,
                        couleur: devis.get_couleurs[i].couleur,
                        ref: devis.get_couleurs[i].ref,
                        formule: devis.get_couleurs[i].formule,
                    })
                }
            },

            updateData(){
              this.$Progress.start();
              this.form.put('api/devisETQ/'+this.form.id)
              .then((response) => {
                  // success
                //   $('#addNew').modal('hide');
                  this.DialogVisible = false
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });
                  this.$Progress.finish();
                      //  Fire.$emit('AfterCreate');

                  this.loadProjects();
              })
              .catch(() => {
                  this.$Progress.fail();
              });

          },

          deletData(id){
              Swal.fire({
                  title: 'Attention !',
                  text: "vous voulez vraiment supprimer ce Projet ??",
                  showCancelButton: true,
                  confirmButtonColor: '#67c23a',
                  cancelButtonColor: '#f56c6c',
                  confirmButtonText: 'Oui, supprimez-le ! '
                  }).then((result) => {

                      // Send request to the server
                        if (result.value) {
                              this.form.delete('api/devisETQ/'+id).then(()=>{
                                      Swal.fire(
                                      'Supprimé !',
                                      'Votre Projet a été supprimé.',
                                      'success'
                                      );
                                  // Fire.$emit('AfterCreate');
                                  this.loadProjects();
                              }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                        }
                  })
          },

        // end crud

         
          editModal(product){
              this.editmode = true;
              this.form.reset();
              $('#addNew').modal('show');
              this.form.fill(product);
          },
          newModal(){
              this.editmode = false;
              this.form.reset();
              $('#addNew').modal('show');
          },
        //   
        urgence(){
              this.editmode = false;
              this.form.reset();
              $('#addNewUrgence').modal('show');
          },
        //   

        deleteRow(index, couleurs) {
                var idx = this.form.couleurs.indexOf(couleurs);
                console.log(idx, index);
                if (idx > -1) {
                    this.form.couleurs.splice(idx, 1);
                }
        },

        addNewRow() {
            this.form.couleurs.push({
                couleur: '',
                ref: '',
                formule: '',
            })
        },

        handleClose(done) {
            this.$confirm('Voulez-vous vraiment fermer cette boîte de dialogue ?')
            .then(_ => {
                done();
            })
            .catch(_ => {});
        },
         
        
         handleCurrentChange(cpage) {
                this.currpage = cpage;
            },
        handleSizeChange(psize) {
                this.pagesize = psize;
            },


        },
        mounted() {
            
        },
        created() {
            this.$Progress.start();

            this.loadProjects();
            this.loadProjectsUrgent();
            this.loadClients();

            this.$Progress.finish();
        },
        filters: {
            FormNumbers2(value){
                  return numeral(value).format("0,0");
              },
            
        },
        computed: {
          
        },
    }
</script>
