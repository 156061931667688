<template>
    <section class="content">
        
      <div class="container-fluid">
        <el-card shadow="always">
            <div class="row">
                
                <div class="col-3">
                    <el-select @change="filterPlateform" v-model="searchPlateform" filterable  style="width: 100%" placeholder="Select Plateforme">
                        <el-option
                            v-for="item in Plateformes"
                            :key="item.id"
                            :label="item.title"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </div>
                <div class="col-3">
                    <el-select @change="filterProduct" v-model="searchProduct" filterable  style="width: 100%" placeholder="Select Product">
                        <el-option
                            v-for="item in Tours"
                            :key="item.id"
                            :label="item.title"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </div>
                <div class="col-3">
                    <el-input v-model="search" @change="filterClient" placeholder="Filter By Client's Name"> 
                        <el-button slot="append" icon="el-icon-search"></el-button>
                    </el-input>
                </div>
                <div class="col-3">
                    <el-date-picker placeholder="Filter By Date" @change="filterDate" v-model="searchDate" value-format="yyyy-MM-dd" style="width: 100%;"></el-date-picker>
                </div>
            </div>
        </el-card>

        <!-- Start Table -->
        <el-card class="mb-3 mt-3">
          <div class="row">
          <div class="col-6">
            <el-button type="primary" @click="newDevis()" size="small" icon="el-icon-plus" >Add New Reminder</el-button>
          </div>
            <!-- <div class="col-6">
                
            </div> -->
            <div class="col-12 mt-3">
              <el-table
                    :data="Reminding.data" border >
                    <el-table-column type="expand">
                    <template slot-scope="props">
                        <el-descriptions class="margin-top" :column="3" border>
                            <!-- <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-link"></i>
                                Reference
                            </template>
                                <el-tag effect="dark">{{ props.row.reference}}</el-tag>
                            </el-descriptions-item> -->
                            <!-- <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-mobile-phone"></i>
                                Telephone
                            </template>
                                <el-tag effect="dark">{{ props.row.phone}}</el-tag>
                            </el-descriptions-item> -->
                            <!-- <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-chat-dot-round"></i>
                                Langue
                            </template>
                                <el-tag effect="dark">{{ props.row.langue}}</el-tag>
                            </el-descriptions-item> -->
                            <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-user-solid"></i>
                                Adults
                            </template>
                                <el-tag effect="dark">{{ props.row.nombreAdulte}}</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <i class="el-icon-user-solid"></i>
                                    Children
                                </template>
                                <el-tag effect="dark">{{ props.row.nombreChildren}}</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <i class="el-icon-office-building"></i>
                                    Plateforme
                                </template>
                                <el-tag effect="dark">{{ props.row.get_plateformes.title}}</el-tag>
                            </el-descriptions-item>
                            <!-- <el-descriptions-item>
                                <template slot="label">
                                    <i class="el-icon-money"></i>
                                    Price
                                </template>
                                <el-tag effect="dark">{{ props.row.price}} {{ props.row.currency }}</el-tag>
                            </el-descriptions-item> -->
                            <!-- <el-descriptions-item>
                                <template slot="label">
                                    <i class="el-icon-money"></i>
                                    Net Price
                                </template>
                                <el-tag effect="dark">{{ props.row.priceNet}} {{ props.row.currency }}</el-tag>
                            </el-descriptions-item> -->
                            <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-tickets"></i>
                                Note
                            </template>
                            <strong>{{ props.row.note }}</strong>
                            </el-descriptions-item>
                        </el-descriptions>
                    </template>
                    </el-table-column>
                    
                    <el-table-column label="Nom">
                        <template slot-scope="scope">
                            <strong>{{ scope.row.NomClient }}</strong>
                        </template>
                    </el-table-column>
                    <el-table-column label="Pick Up">
                        <template slot-scope="scope">
                            <strong>{{ scope.row.pickup }}</strong>
                        </template>
                    </el-table-column>
                    <el-table-column label="Product">
                        <template slot-scope="scope">
                            <strong>{{ scope.row.get_product.title }}</strong>
                        </template>
                    </el-table-column>
                    <el-table-column label="Date" width="150" align="center">
                        <template slot-scope="scope">
                            <strong>{{ scope.row.dateJ | OfferDates }} <br> {{ scope.row.timeJ }} H</strong>
                        </template>
                    </el-table-column>
                    <el-table-column label="Status" width="100" align="center">
                        <template slot-scope="scope">
                            <div v-if="scope.row.etat == 'Processing'">
                                <el-tag  type="" effect="dark" ><strong>{{ scope.row.etat }}</strong></el-tag>
                            </div>
                            <div v-if="scope.row.etat == 'Refunded'">
                                <el-tag  type="danger" effect="dark" ><strong>{{ scope.row.etat }}</strong></el-tag>
                            </div>
                            <div v-if="scope.row.etat == 'Cancelled'">
                                <el-tag  type="warning" effect="dark" ><strong>{{ scope.row.etat }}</strong></el-tag>
                            </div>
                            <div v-if="scope.row.etat == 'Completed'">
                                <el-tag  type="success" effect="dark" ><strong>{{ scope.row.etat }}</strong></el-tag>
                            </div>
                        </template>
                    </el-table-column>
                    
                    <el-table-column width="100" align="center">
                        <template slot-scope="{row}">
                                <a href="#" @click="updateReservation(row)" data-toggle="tooltip" data-placement="top" title="Modifier">
                                        <i class="fa fa-edit blue mr-2"></i>
                                </a>
                                <el-divider direction="vertical"></el-divider>
                                <a href="#" @click="deletData(row.id)" data-toggle="tooltip" data-placement="top" title="Supprimer">
                                    <i class="fa fa-trash red"></i>
                                </a>
                        </template>
                    </el-table-column>
              </el-table>
               
               <pagination class="mt-4" :data="Reminding" @pagination-change-page="loadData"></pagination>
            </div>
            
          </div>
          </el-card>
        <!-- End Table -->
          
          <!-- dialog -->
          <el-dialog
            :title="editmode ? 'Update Reservation':'New Reservation'"
            :visible.sync="DialogVisible_global"
            width="90%"
            :before-close="handleClose">
            <!-- Body dialog -->
                <el-form :model="form_global" ref="form_global" class="demo-ruleForm">
                    <fieldset class="row col-12 border p-2 mt-2">
                        <legend  class="w-auto badge"><h5>Booking Infos</h5></legend>
                            <!-- <el-form-item class="col-lg-4" label="Booking Reference" prop="reference" 
                            :rules="[
                                { required: true, message: 'Booking Reference is required' }
                            ]">
                                <el-input v-model="form_global.reference" autocomplete="off"></el-input>
                            </el-form-item> -->
                            <el-form-item class="col-lg-4" label="Nom" prop="NomClient" 
                            :rules="[
                                { required: true, message: 'Nom is required' }
                            ]">
                                <el-input v-model="form_global.NomClient" autocomplete="off"></el-input>
                            </el-form-item>
                            <el-form-item class="col-lg-4" label="Platform" prop="id_platform" 
                            :rules="[
                                { required: true, message: 'Platform is required' }
                            ]">
                                <el-select v-model="form_global.id_platform" @change="PlateformChange()"  filterable  style="width: 100%" placeholder="Select Plateforme">
                                    <el-option
                                        v-for="item in Plateformes"
                                        :key="item.id"
                                        :label="item.title"
                                        :value="item.id"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                            <!-- Price -->
                            <!-- <el-form-item class="col-lg-4" label="Price" prop="price" 
                                :rules="[{ required: true, message: 'Price is required' }]">
                                <el-input type="number"  @change ="PriceChange" v-model="form_global.price" autocomplete="off">
                                    <template slot="append"><strong>{{ form_global.currency }}</strong></template>
                                </el-input>
                            </el-form-item>
                            <el-form-item  class="col-lg-4" label="Commission" prop="commission" 
                                :rules="[{ required: true, message: 'Commission is required' }]">
                                <el-input :disabled="true" v-model="form_global.commission" autocomplete="off">
                                    <template slot="append"><strong>%</strong></template>
                                </el-input>
                            </el-form-item>
                            <el-form-item  class="col-lg-4" label="Net Price" prop="priceNet" 
                                :rules="[{ required: true, message: 'Price Net is required' }]">
                                <el-input :disabled="true" v-model="form_global.priceNet" autocomplete="off">
                                    <template slot="append"><strong>{{ form_global.currency }}</strong></template>
                                </el-input>
                            </el-form-item> -->
                            
                            <!-- End Price -->
                            <el-form-item class="col-lg-6" label="Hotel / Pickup" prop="pickup" 
                            :rules="[
                                { required: true, message: 'Pick Up Place is required' }
                            ]">
                            <el-input v-model="form_global.pickup" autocomplete="off"></el-input>
                           
                            </el-form-item>
                            <el-form-item class="col-lg-6" label="Excurtion" prop="id_product" 
                            :rules="[
                                { required: true, message: 'Titre is required' }
                            ]">
                            <el-select v-model="form_global.id_product" filterable  style="width: 100%" placeholder="Select Product">
                                <el-option
                                    v-for="item in Tours"
                                    :key="item.id"
                                    :label="item.title"
                                    :value="item.id"
                                ></el-option>
                            </el-select>
                                <!-- <el-input type="text" v-model="form_global.id_product" autocomplete="off"></el-input> -->
                            </el-form-item>
                            <!--  -->
                            <el-form-item class="col-lg-3" label="Nombre Adulte" prop="nombreAdulte" 
                            :rules="[
                                { required: true, message: 'Nombre of adults is required' },
                            ]">
                                <el-input type="number" v-model="form_global.nombreAdulte" autocomplete="off"></el-input>
                            </el-form-item>
                            <el-form-item class="col-lg-3" label="Nombre Children" prop="nombreChildren" 
                            :rules="[
                                { required: true, message: 'Nombre of Children is required' },
                            ]">
                                <el-input type="number" v-model="form_global.nombreChildren" autocomplete="off"></el-input>
                            </el-form-item>
                            <el-form-item class="col-lg-3" label="Date Sortie" prop="dateJ" >
                                <el-date-picker placeholder="Pick a date" v-model="form_global.dateJ" value-format="yyyy-MM-dd" style="width: 100%;"></el-date-picker>
                            </el-form-item>
                            
                            <el-form-item class="col-lg-3" label="Time Sortie" prop="timeJ"
                                :rules="[
                                    { required: true, message: 'Please pick a Time', trigger: 'change' }
                                ]">
                                <el-time-select v-model="form_global.timeJ" :picker-options="{ start: '07:00', step: '00:30', end: '23:30' }"
                                placeholder="Select time" style="width: 100%;">
                            </el-time-select>
                            </el-form-item>

                            <!-- <el-form-item v-if="form_global.id_product == 20 || form_global.id_product == 2" class="col-lg-3" label="Number Of Machines" prop="nbr_bikes" 
                            :rules="[
                                { required: true, message: 'Number Of Machines is required' }
                            ]">
                                <el-input type="text" v-model="form_global.nbr_bikes" autocomplete="off"></el-input>
                            </el-form-item>
                            <el-form-item class="col-lg-3" label="Num Téléphone" prop="phone" 
                            :rules="[
                                { required: true, message: 'Phone number is required' }
                            ]">
                                <el-input type="text" v-model="form_global.phone" autocomplete="off"></el-input>
                            </el-form-item>
                            <el-form-item class="col-lg-3" label="Tour Language" prop="langue" 
                            :rules="[
                                { required: true, message: 'Langue is required' }
                            ]">
                                <el-select v-model="form_global.langue" style="width: 100%" placeholder="Select Langue">
                                        <el-option label="English" value="English"></el-option>
                                        <el-option label="Spanish" value="Spanish"></el-option>
                                        <el-option label="Frensh" value="Frensh"></el-option>
                                        <el-option label="Germany" value="Germany"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item class="col-lg-3" label="Status" prop="etat" 
                            :rules="[
                                { required: true, message: 'Etat is required' }
                            ]">
                                <el-select v-model="form_global.etat" style="width: 100%" placeholder="Select Status">
                                        <el-option label="Processing" value="Processing"></el-option>
                                        <el-option label="Completed" value="Completed"></el-option>
                                        <el-option label="Cancelled" value="Cancelled"></el-option>
                                        <el-option label="Refunded" value="Refunded"></el-option>
                                </el-select>
                            </el-form-item> -->
                            <el-form-item class="col-lg-12" label="Note" prop="note" >
                                <el-input type="textarea" v-model="form_global.note" autocomplete="off"></el-input>
                            </el-form-item>
                    </fieldset>
                </el-form>
                <!-- ENd body Dialog -->
                <span slot="footer" class="dialog-footer">
                    <el-button @click="DialogVisible_global = false">Annuler</el-button>
                    <el-button type="primary" v-show="!editmode" @click="submitForm('form_global')">Valider</el-button>
                    <el-button type="primary" v-show="editmode" @click="updateData()">Valider</el-button>
                </span>
          </el-dialog>

          <!-- ENd dialog -->
  
      </div>
     
    </section>
    <!--  -->
  </template>
  
  
  <script>
      import VueTagsInput from '@johmun/vue-tags-input';
      import moment from 'moment'
  
      export default {
        components: {
            VueTagsInput,
          },
          data () {
              return {
                  DialogVisible_global: false,
                  active: 0,
                  Priceactive: false,
                  Reminding: [],
                  Tours: [],
                  Hotels: [],
                  Plateformes: [],
                  search: '',
                  searchDate: '',
                  searchProduct: '',
                  searchPlateform: '',
                  pagesize: 10,
                  currpage: 1,
                  editmode: false,
                  products : [],

                  numberValidateForm: {
                    age: ''
                },
                
                  form_global: new Form({
                    id:'',
                    NomClient:'',
                    id_platform:'',
                    // etat:'',
                    pickup:'',
                    id_product:'',
                    // nbr_bikes:'',
                    nombreAdulte:'',
                    nombreChildren:'',
                    dateJ:'',
                    timeJ:'',
                    dateReserve:'',
                    // phone:'',
                    note:'',
                    // langue:'',
                    // price:'',
                    // priceNet:'',
                    // currency:'',
                    // commission:'',
                    // reference:''
                  }),

                  form: new Form({
                      id : '',
                      category : '',
                      name: '',
                      description: '',
                      tags:  [],
                      photo: '',
                      category_id: '',
                      price: '',
                      photoUrl: '',
                  }),
                  categories: [],
                
                  tag:  '',
                  autocompleteItems: [],
              }
          },
          methods: {    
            loadData(page = 1){
                this.$Progress.start();
                axios.get('api/reminding?page=' + page).then(({ data }) => (this.Reminding = data.data));
                this.$Progress.finish();
            },        

            filterDate(){
                if(this.searchDate == null){
                    this.loadData();
                }else{
                    this.$Progress.start();
                    axios.get('api/filterDate/' + this.searchDate).then(({ data }) => (this.Reservations = data.data));
                    this.$Progress.finish();
                }
            },

            filterClient(){
                if(this.search != ""){
                    this.$Progress.start();
                    axios.get('api/filterClient/' + this.search).then(({ data }) => (this.Reservations = data.data));
                    this.$Progress.finish();
                    
                }else{
                    this.loadData();
                }
            },

            filterProduct(){
                if(this.searchProduct != ""){
                    this.$Progress.start();
                    axios.get('api/filterProduct/' + this.searchProduct).then(({ data }) => (this.Reservations = data.data));
                    this.$Progress.finish();
                }else{
                    this.loadData();
                }
            },

            filterPlateform(){
                if(this.searchPlateform != ""){
                    this.$Progress.start();
                    axios.get('api/filterPlateform/' + this.searchPlateform).then(({ data }) => (this.Reservations = data.data));
                    this.$Progress.finish();
                }else{
                    this.loadData();
                }
            },
            filterProduct(){
                if(this.searchProduct != ""){
                    this.$Progress.start();
                    axios.get('api/filterProduct/' + this.searchProduct).then(({ data }) => (this.Reservations = data.data));
                    this.$Progress.finish();
                }else{
                    this.loadData();
                }
            },

            PriceChange(){

                // console.log(this.form_global.price);
                this.form_global.priceNet = this.form_global.price-(this.form_global.commission*this.form_global.price/100)
            },
            PlateformChange(){

                if([1,8].includes(this.form_global.id_platform)){
                    this.form_global.currency = "POUND"
                    this.Priceactive = true
                }
                else if([6,7,11,14].includes(this.form_global.id_platform))
                {
                    this.form_global.currency = "MAD"
                    this.Priceactive = true
                }else{
                    this.form_global.currency = "EURO"
                    this.Priceactive = false
                }

                if([6,14].includes(this.form_global.id_platform)){
                    this.form_global.commission = 35
                }
                else if([1,7,8].includes(this.form_global.id_platform))
                {
                    this.form_global.commission = 30
                }else if([13,15].includes(this.form_global.id_platform))
                {
                    this.form_global.commission = 25
                }else{
                    this.form_global.commission = 0
                }

                this.PriceChange();

            },
            // CRUD
            newDevis(){
                this.form_global.reset();
                this.editmode = false;
                // this.disableDevisPV = false;
                this.DialogVisible_global = true;
                this.active=0;
            },

            createData(){
            this.$Progress.start();

              this.form_global.post('api/reminding')
              .then((data)=>{
                if(data.data.success){
                 this.DialogVisible_global = false

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.$Progress.finish();
                  this.loadData();
                //   this.loadDataPrepared();

                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          },

          submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.createData();
                } else {
                    console.log('error submit!!');
                    return false;
                }
                });
            },

            loadTours(){
                this.$Progress.start();
                axios.get('api/indexTours').then(({ data }) => (this.Tours = data.data));
                this.$Progress.finish();
            },

            loadHotels(){
                this.$Progress.start();
                axios.get('api/indexHotels').then(({ data }) => (this.Hotels = data.data));
                this.$Progress.finish();
            },
            loadPlateformes(){
                this.$Progress.start();
                axios.get('api/indexPlateformes').then(({ data }) => (this.Plateformes = data.data));
                this.$Progress.finish();
            },

            updateReservation(reservation){
                this.editmode = true;
                this.form_global.reset();
                this.DialogVisible_global = true;
                this.form_global.fill(reservation);
            },

            updateData(){
              this.$Progress.start();
              this.form_global.put('api/reminding/'+this.form_global.id)
              .then((response) => {
                  // success
                  this.DialogVisible_global = false
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });
                  this.$Progress.finish();

                  this.loadData();
              })
              .catch(() => {
                  this.$Progress.fail();
              });

          },

          deletData(id){
              Swal.fire({
                  title: 'Attention !',
                  text: "You really want to delete this Reminding ??",
                  showCancelButton: true,
                  confirmButtonColor: '#67c23a',
                  cancelButtonColor: '#f56c6c',
                  confirmButtonText: 'Yes, Delete-it ! '
                  }).then((result) => {

                      // Send request to the server
                        if (result.value) {
                              this.form_global.delete('api/reminding/'+id).then(()=>{
                                      Swal.fire(
                                      'Deleted !',
                                      'Reminder Delete Successfully',
                                      'success'
                                      );
                                  // Fire.$emit('AfterCreate');
                                  this.loadData();
                              }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                        }
                  })
          },
        //   End CRUD

            handleClose(done) {
                this.$confirm('Voulez-vous vraiment fermer cette boîte de dialogue ?')
                .then(_ => {
                    done();
                })
                .catch(_ => {});
            },

            handleCurrentChange2(val) {
                this.currentRow = val;
            },

            // END
  
           
            loadProducts(){
  
              // if(this.$gate.isAdmin()){
                axios.get("api/product").then(({ data }) => (this.products = data.data));
              // }
            },
            loadCategories(){
                axios.get("/api/category/list").then(({ data }) => (this.categories = data.data));
            },
            loadTags(){
                axios.get("/api/tag/list").then(response => {
                    this.autocompleteItems = response.data.data.map(a => {
                        return { text: a.name, id: a.id };
                    });
                }).catch(() => console.warn('Oh. Something went wrong'));
            },
            editModal(product){
                this.editmode = true;
                this.form.reset();
                $('#addNew').modal('show');
                this.form.fill(product);
            },
            newModal(){
                this.editmode = false;
                this.form.reset();
                $('#addNew').modal('show');
            },
            createProduct(){
                this.$Progress.start();
  
                this.form.post('api/product')
                .then((data)=>{
                  if(data.data.success){
                    $('#addNew').modal('hide');
  
                    Toast.fire({
                          icon: 'success',
                          title: data.data.message
                      });
                    this.$Progress.finish();
                    this.loadProducts();
  
                  } else {
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
  
                    this.$Progress.failed();
                  }
                })
                .catch(()=>{
  
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
                })
            },
            updateProduct(){
                this.$Progress.start();
                this.form.put('api/product/'+this.form.id)
                .then((response) => {
                    // success
                    $('#addNew').modal('hide');
                    Toast.fire({
                      icon: 'success',
                      title: response.data.message
                    });
                    this.$Progress.finish();
                        //  Fire.$emit('AfterCreate');
  
                    this.loadProducts();
                })
                .catch(() => {
                    this.$Progress.fail();
                });
  
            },
            deletModal(id){
                Swal.fire({
                    title: 'Attention !',
                    text: "vous voulez vraiment supprimer ce devis",
                    showCancelButton: true,
                    confirmButtonColor: '#67c23a',
                    cancelButtonColor: '#f56c6c',
                    confirmButtonText: 'Oui, supprimez-le ! '
                    }).then((result) => {
  
                        // Send request to the server
                          if (result.value) {
                                this.form.delete('api/product/'+id).then(()=>{
                                        Swal.fire(
                                        'Supprimé !',
                                        'Votre fichier a été supprimé.',
                                        'success'
                                        );
                                    // Fire.$emit('AfterCreate');
                                    this.loadProducts();
                                }).catch((data)=> {
                                    Swal.fire("Failed!", data.message, "warning");
                                });
                          }
                    })
            },
          
           handleCurrentChange(cpage) {
                  this.currpage = cpage;
              },
          handleSizeChange(psize) {
                  this.pagesize = psize;
              },
  
  
          },
          mounted() {
              
          },
          created() {
              this.$Progress.start();
  
              this.loadData();
              this.loadTours();
            //   this.loadHotels();
              this.loadPlateformes();
  
              this.$Progress.finish();
          },
          filters: {
            OfferDates(value){
                return moment(String(value)).format('DD-MM-YYYY')
            },
              truncate: function (text, length, suffix) {
                  return text.substring(0, length) + suffix;
              },
          },
          computed: {
            filteredItems() {
              return this.autocompleteItems.filter(i => {
                return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
              });
            },
          },
      }
  </script>
  