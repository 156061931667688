<template>
  <section class="content">
    <div class="container-fluid">
        <el-card class="mb-3 mt-3">
            <div slot="header" class="clearfix text-center">
                <h3><div class="spinner-grow" style="color: white"></div> Reception De Stock </h3>
            </div>
        <div class="row">
        <div class="col-6">
            <!-- <el-button type="primary" size="small" icon="el-icon-plus" @click="newModal" plain>Ajouter Devis International</el-button> -->
        </div> 
          <div class="col-6">
              <el-input v-model="search" size="mini" placeholder="Filter : "> 
                  <el-button slot="append" icon="el-icon-search"></el-button>
              </el-input>
          </div>
          <div class="col-12 mt-3">
            <el-table
                :data="tableCommandes.filter(data => !search || data.numBonLivraison.toLowerCase().includes(search.toLowerCase()) ).slice((currpage - 1) * pagesize, currpage * pagesize)"
                style="width: 100%">
                <el-table-column
                    prop="numBonLivraison"
                    label="N° Bon Livraison"
                >
                </el-table-column>
                <el-table-column
                    prop="matriculeCamion"
                    label="Matricule Camion"
                >
                </el-table-column>
                <el-table-column
                    prop="nomChouffeur"
                    label="Nom Chouffeur"
                >
                </el-table-column>
                <el-table-column
                    prop="numCMR"
                    label="N° CMR"
                >
                </el-table-column>
                <el-table-column
                    prop="numConnaissement"
                    label="N° Connaissement"
                >
                </el-table-column>
                
                <el-table-column
                fixed="right"
                label="Operations"
                >
                <template>
                    <!-- <a href="#" data-toggle="tooltip" data-placement="top" title="Modifier">
                            <i class="fa fa-edit blue mr-2"></i>
                    </a>
                    <el-divider direction="vertical"></el-divider>-->
                    <a href="#"  data-toggle="tooltip" data-placement="top" title="Détailles de la commande">
                        <i class="fa fa-eye green"></i>
                    </a> 
                </template>
                </el-table-column>
            </el-table>
             <!--  -->
            <el-pagination background 
                    class="mt-4"
                    layout="prev, pager, next, sizes, total, jumper"
                    :page-sizes = "[5, 10, 15, 20]"
                    :page-size = "pagesize"
                    :total="tableCommandes.length"
                    @current-change = "handleCurrentChange" 
                    @size-change = "handleSizeChange" 
                    >
            </el-pagination>
          </div>
        </div>
        </el-card>

    </div>
  </section>
</template>

<script>
    import VueTagsInput from '@johmun/vue-tags-input';

    export default {
      components: {
          VueTagsInput,
        },
        data () {
            return {

                tableCommandes : [{
                    matriculeCamion: '21545236',
                    nomChouffeur: 'mohamed ..',
                    numCMR: '6548786',
                    numConnaissement : '87987897',
                    numBonLivraison : 'Bn-l-21-012478'
                },{
                    matriculeCamion: '758452',
                    nomChouffeur: 'Aziz Kamali',
                    numCMR: '8529',
                    numConnaissement : '8744521',
                    numBonLivraison : 'Bn-l-22-7/99478'
                },{
                    matriculeCamion: '8945',
                    nomChouffeur: 'Ibrahim Yahya',
                    numCMR: '21546532',
                    numConnaissement : '225447785',
                    numBonLivraison : 'Bn-l-22-991C78'
                }],
                  tableDataColisage1: [{
                    codeArticle: '00384279731044320814',
                    LaizeMM: '150',
                    QuantitML: '2000',
                    MQ: '340',
                    }, {
                    codeArticle: '00384279731044320838',
                    LaizeMM: '175',
                    QuantitML: '2000',
                    MQ: '350',
                    },{
                    codeArticle: '00384279731044320852',
                    LaizeMM: '150',
                    QuantitML: '2000',
                    MQ: '340',
                    },],

                tableDataColisage: [{
                    codePalete: '0002938744',
                    unite: 'Bobine',
                    nbrUnit : '60',
                    codeUnit: '5469dd',
                    designation: '-------',
                    typeUnite:'KG',
                    uniteIntern: '5000',
                    prixUnit:'--------',
                    prixTotal:'--------',
                    hasChildren: true,
                    bobines: [{
                                codeArticle: '00384279731044320838',
                                LaizeMM: '175',
                                QuantitML: '2000',
                                MQ: '350',
                            },{
                                codeArticle: '00384279731044320852',
                                LaizeMM: '150',
                                QuantitML: '2000',
                                MQ: '340',
                            }]
                },{
                    codePalete: 'PL-84545',
                    unite: 'Bobine',
                    nbrunit : '60',
                    codeUnit: '87777',
                    designation: '-------',
                    typeUnite:'ML',
                    uniteIntern: '2000',
                    prixUnit:'--------',
                    prixTotal:'--------',
                    bobines: [{
                                codeArticle: '00384279731044320814',
                                LaizeMM: '150',
                                QuantitML: '2000',
                                MQ: '340',
                            },]
                }],

                search: '',
                pagesize: 10,
                currpage: 1,
                editmode: false,
                products : [],
                form: new Form({
                    id : '',
                    category : '',
                    name: '',
                    description: '',
                    tags:  [],
                    photo: '',
                    category_id: '',
                    price: '',
                    photoUrl: '',
                }),
                categories: [],
              
                tag:  '',
                autocompleteItems: [],
            }
        },
        methods: {
            

         
          loadProducts(){

            // if(this.$gate.isAdmin()){
              axios.get("api/product").then(({ data }) => (this.products = data.data));
            // }
          },
          loadCategories(){
              axios.get("/api/category/list").then(({ data }) => (this.categories = data.data));
          },
          loadTags(){
              axios.get("/api/tag/list").then(response => {
                  this.autocompleteItems = response.data.data.map(a => {
                      return { text: a.name, id: a.id };
                  });
              }).catch(() => console.warn('Oh. Something went wrong'));
          },
          editModal(product){
              this.editmode = true;
              this.form.reset();
              $('#addNew').modal('show');
              this.form.fill(product);
          },
          newModal(){
              this.editmode = false;
              this.form.reset();
              $('#addNew').modal('show');
          },
          createProduct(){
              this.$Progress.start();

              this.form.post('api/product')
              .then((data)=>{
                if(data.data.success){
                  $('#addNew').modal('hide');

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.$Progress.finish();
                  this.loadProducts();

                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          },
          updateProduct(){
              this.$Progress.start();
              this.form.put('api/product/'+this.form.id)
              .then((response) => {
                  // success
                  $('#addNew').modal('hide');
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });
                  this.$Progress.finish();
                      //  Fire.$emit('AfterCreate');

                  this.loadProducts();
              })
              .catch(() => {
                  this.$Progress.fail();
              });

          },
          deletModal(id){
              Swal.fire({
                  title: 'Attention !',
                  text: "vous voulez vraiment supprimer ce devis",
                  showCancelButton: true,
                  confirmButtonColor: '#67c23a',
                  cancelButtonColor: '#f56c6c',
                  confirmButtonText: 'Oui, supprimez-le ! '
                  }).then((result) => {

                      // Send request to the server
                        if (result.value) {
                              this.form.delete('api/product/'+id).then(()=>{
                                      Swal.fire(
                                      'Supprimé !',
                                      'Votre fichier a été supprimé.',
                                      'success'
                                      );
                                  // Fire.$emit('AfterCreate');
                                  this.loadProducts();
                              }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                        }
                  })
          },
        
         handleCurrentChange(cpage) {
                this.currpage = cpage;
            },
        handleSizeChange(psize) {
                this.pagesize = psize;
            },


        },
        mounted() {
            
        },
        created() {
            this.$Progress.start();

            this.loadProducts();
            this.loadCategories();
            this.loadTags();

            this.$Progress.finish();
        },
        filters: {
            truncate: function (text, length, suffix) {
                return text.substring(0, length) + suffix;
            },
        },
        computed: {
          filteredItems() {
            return this.autocompleteItems.filter(i => {
              return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
            });
          },
        },
    }
</script>
