<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">
        <div class="col-6">
            <el-button type="primary" size="small" icon="el-icon-plus" @click="newPlaque" plain>Ajouter Nouveau Plaque</el-button>
        </div> 
          <div class="col-12 mt-3">
            <el-card>
                    <div class="row">
                        <div class="col-6">
                            <h4>LISTE DES PLAQUES</h4>
                        </div>
                        <div class="col-6">
                            <el-input v-model="search1" @change="filterProjet" size="mini" placeholder="Filter : "> 
                                <el-button slot="append" icon="el-icon-search"></el-button>
                            </el-input>
                        </div>
                        <div class="col-12 mt-3">
                            <el-table
                                :data="plaques.data"
                                border >
                            <el-table-column align="center" label="N° Plaque">
                                <template slot-scope="scope">
                                    <span>{{ scope.row.num_plaque }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="N° PFD">
                                <template slot-scope="scope">
                                    <span>{{ scope.row.num_pfd }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="Reference Plaque">
                                <template slot-scope="scope">
                                    <span>{{ scope.row.num_reference }}</span>
                                </template>
                            </el-table-column>
                           
                            <el-table-column align="center" label="Machine">
                                <template  slot-scope="scope">
                                    <strong>{{ scope.row.machine}}</strong>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="Nbr pose sur Plaque">
                                <template slot-scope="scope" >
                                    <el-tag
                                        type="danger"
                                        effect="plain">
                                        <strong>{{ scope.row.nbrPoseSurPlaq }}</strong>
                                    </el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="Laize De Papier">
                                <template slot-scope="scope">
                                        <el-tag effect="dark">
                                            {{ scope.row.laize_papier_mm }}
                                        </el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column align="center">
                                <template slot-scope="{row}">
                                    
                                     <a href="#" @click="updatePlaque(row)" data-toggle="tooltip" data-placement="top" title="Modifier">
                                        <i class="fa fa-edit blue"></i>
                                    </a>
                                    <el-divider direction="vertical"></el-divider>
                                    <a href="#" v-if="$gate.isAdmin()" @click="deletData(row.id)" data-toggle="tooltip" data-placement="top" title="Supprimer">
                                        <i class="fa fa-trash red"></i>
                                    </a>
                                </template>
                            </el-table-column>
                            </el-table>
                            <!--  -->
                            <pagination class="mt-4" :data="plaques" @pagination-change-page="loadPlaques"></pagination>
                        </div>
                    </div>
                </el-card>
          </div>
        </div>

        <!-- Modal Plaque  -->
        <el-dialog
            :title="editmode ? 'AJOUTER PLAQUE':'MODIFIER PLAQUE'"
            :visible.sync="DialogVisiblePlaques"
            width="80%"
            :before-close="handleClose">
            <!-- Body dialog -->
            <el-form :model="form">
                <fieldset class="border p-2 mt-2">
                    <div class="row">
                        <el-form-item 
                            class="text-center col-lg-4"
                            label="N° Référence De La Plaque"
                            prop="num_reference">
                            <el-input type="text" v-model="form.num_reference" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item 
                            class="text-center col-lg-4"
                            label="Date De Production"
                            prop="date_production">
                            <el-date-picker type="date" placeholder="Choisis une date" v-model="form.date_production" format="yyyy-MM-dd" value-format="yyyy-MM-dd" style="width: 100%;"></el-date-picker>
                        </el-form-item>
                        <el-form-item 
                            class="text-center col-lg-4"
                            label="N° Plan De Form de decoupe (PFD)"
                            prop="num_pfd">
                            <el-input type="text" v-model="form.num_pfd" autocomplete="off"></el-input>
                        </el-form-item>
                    </div>
                </fieldset>
                <fieldset class="border p-2 mt-2">
                    <div class="row">
                        <el-form-item class="col-lg-3" prop="formatMM_L" label="FORMAT EN (mm)">
                            <br>
                            <div class="row float-right">
                                <el-input class="col-5" type="number" placeholder="L" v-model="form.formatMM_L" autocomplete="off"></el-input>
                                <p class="col-1"> X </p>
                                <el-input class="col-5" type="number" placeholder="D" v-model="form.formatMM_D" autocomplete="off"></el-input>
                            </div>
                        </el-form-item>
                        <el-form-item 
                            class="col-lg-3" 
                            prop="forme_etq" 
                            label="FORME D'ETIQUETTE"
                        >
                            <el-select v-model="form.forme_etq" style="width: 100%" placeholder="Please select Form d'étiquitte">
                                <el-option label="REC" value="REC"></el-option>
                                <el-option label="OVA" value="OVA"></el-option>
                                <el-option label="CAR" value="CAR"></el-option>
                                <el-option label="RONDER" value="RONDER"></el-option>
                                <el-option label="TRIANGULAIRE" value="TRIANGULAIRE"></el-option>
                                <el-option label="SPECIALE" value="SPECIALE"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item 
                            class="col-lg-3" 
                            prop="machine" 
                            label="MACHINE"
                        >
                            <el-select v-model="form.machine" multiple style="width: 100%" placeholder="Merci de Choisir une machine">
                                <el-option label="GIDUE-370" value="GIDUE-370"></el-option>
                                <el-option label="NILPITER-B200" value="NILPITER-B200"></el-option>
                                <el-option label="ROTOFLEX-400" value="ROTOFLEX-400"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item 
                            class="col-lg-3" 
                            prop="z_cylindre" 
                            label="Z Cylindre"
                        >
                        <el-input type="number" v-model="form.z_cylindre" autocomplete="off"></el-input>
                            <!-- <el-select v-model="form.z_cylindre" style="width: 100%" placeholder="Merci de Choisir Z Cylindre">
                                <el-option
                                    v-for="item in Zcylindre"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                ></el-option>
                            </el-select> -->
                        </el-form-item>
                        <!-- -->
                        <el-form-item 
                            class="col-lg-4" 
                            prop="nbrPoseLaize" 
                            label="Nombre de Pose en laize"
                        >
                            <el-input type="number" v-model="form.nbrPoseLaize" @change="totalPosePlaqueCalculer" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item 
                            class="col-lg-4" 
                            prop="nbrPoseDevlop" 
                            label="Nombre de Pose en Developpe"
                        >
                            <el-input type="number" v-model="form.nbrPoseDevlop" @change="totalPosePlaqueCalculer" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item 
                            class="col-lg-4" 
                            prop="nbrPoseSurPlaq" 
                            label="Nombre de Pose sur la plaque"
                        >
                            <el-input type="number" v-model="form.nbrPoseSurPlaq" autocomplete="off"></el-input>
                        </el-form-item>
                        <!--  -->
                        <!-- <el-form-item 
                            class="col-lg-4" 
                            prop="devlop_mm" 
                            label="FORMAT D'ETIQUETTE EN LAIZE"
                        >
                            <el-input type="number" v-model="form.devlop_mm" autocomplete="off"></el-input>
                        </el-form-item> -->
                        <el-form-item 
                            class="col-lg-4" 
                            prop="laize_mm" 
                            label="FORMAT D'ETIQUETTE EN LAIZE"
                        >
                            <el-input type="number" v-model="form.laize_mm" @change="laizePapierCalculer" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item 
                            class="col-lg-4" 
                            prop="laize_papier_mm" 
                            label="Laize papier calculée en mm"
                        >
                            <el-input type="number" v-model="form.laize_papier_mm" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item 
                        class="text-center col-lg-4"
                        prop="type_support"
                        label="Support d'impression"
                        >
                            <el-select v-model="form.type_support" multiple style="width: 100%" placeholder="Merci de Choisir une machine">
                                <el-option label="Thermique" value="Thermique"></el-option>
                                <el-option label="Couché" value="Couché"></el-option>
                                <el-option label="PPT" value="PPT"></el-option>
                                <el-option label="PPB" value="PPB"></el-option>
                                <el-option label="PPT" value="PPT"></el-option>
                            </el-select>
                            <!-- <el-radio-group v-model="form.type_support">
                                <el-radio label="Thermique" ></el-radio>
                                <el-radio label="Couché" ></el-radio>
                                <el-radio label="PPT" ></el-radio>
                                <el-radio label="PPB" ></el-radio>
                                <el-radio label="Autres" ></el-radio>
                            </el-radio-group> -->
                        </el-form-item>
                    </div>
                </fieldset>
                
            </el-form>
            <!-- show on edit mode -->
            <span slot="footer"  class="dialog-footer">
                <el-button @click="DialogVisiblePlaques = false">Annuler</el-button>
                <el-button type="primary" v-show="!editmode" @click="createPlaque()">Ajouter</el-button>
                <el-button type="primary" v-show="editmode" @click="updateData()">Valider</el-button>
            </span>
        </el-dialog>
        <!-- end dialogue plaque -->
        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog modal-xl" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Ajouter Nouvelle Plaque</h5>
                    <h5 class="modal-title" v-show="editmode">Modifier la Plaque</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form>
                    <div class="modal-body">
                        <fieldset class="border p-2">
                            <!-- <legend class="w-auto badge badge-success"><h6> Informations de Siège </h6></legend> -->
                            <div class="row">
                                <div class="form-group col-4">
                                    <label>N° Serie:</label>
                                    <input v-model="form.numSerie" type="text" name="numSerie"
                                        class="form-control" :class="{ 'is-invalid': form.errors.has('numSerie') }">
                                    <has-error :form="form" field="numSerie"></has-error>
                                </div>
                                <div class="form-group col-4">
                                    <label>Date:</label>
                                    <input v-model="form.datePlaque" type="date" name="datePlaque"
                                        class="form-control" :class="{ 'is-invalid': form.errors.has('datePlaque') }">
                                    <has-error :form="form" field="datePlaque"></has-error>
                                </div>
                                <div class="form-group col-4">
                                    <label>N° Plan de decoupe :</label>
                                    <input v-model="form.num_PlanDecoupe" type="text" name="num_PlanDecoupe"
                                        class="form-control" :class="{ 'is-invalid': form.errors.has('num_PlanDecoupe') }">
                                    <has-error :form="form" field="num_PlanDecoupe"></has-error>
                                </div>
                                 <div class="form-group col-6">
                                    <label>Fournisseur :</label>
                                    <input v-model="form.fournisseur" placeholder="fournisseur" type="text" name="fournisseur"
                                        class="form-control" :class="{ 'is-invalid': form.errors.has('fournisseur') }">
                                    <has-error :form="form" field="fournisseur"></has-error>
                                </div>
                                 <div class="form-group col-6">
                                    <label>BL / FA N° :</label>
                                    <input v-model="form.blfa" type="text" name="blfa"
                                        class="form-control" :class="{ 'is-invalid': form.errors.has('blfa') }">
                                    <has-error :form="form" field="blfa"></has-error>
                                </div>

                                
                            </div>
                        </fieldset>
                        <!--  -->
                        <fieldset class="border p-2">
                            <!-- <legend class="w-auto badge badge-success"><h6> Résponsable D'Achat </h6></legend> -->
                                <div class="row">
                                    <div class="form-group col-4">
                                        <label>Format Etq en mm:</label>
                                            <div class="row">
                                                <div class="col-4">
                                                    <input v-model="form.format1" placeholder="L" type="number" name="format1"
                                                    class="form-control" :class="{ 'is-invalid': form.errors.has('price') }">
                                                </div>
                                                <div class="col-4">
                                                    <input v-model="form.format2" placeholder="D" type="number" name="format2"
                                                    class="form-control" :class="{ 'is-invalid': form.errors.has('price') }">
                                                </div>
                                            </div>
                                        <has-error :form="form" field="nrc"></has-error>
                                    </div>
                                    <div class="col-8"></div>
                                    <div class="form-group col-4">
                                        <label>Nombre de pose en laize:</label>
                                        <input v-model="form.poseLaize" type="number" name="poseLaize"
                                            class="form-control" :class="{ 'is-invalid': form.errors.has('poseLaize') }">
                                        <has-error :form="form" field="poseLaize"></has-error>
                                    </div>
                                    <div class="form-group col-4">
                                        <label>Nombre de pose en developpe:</label>
                                        <input v-model="form.poseDevlop" type="number" name="poseDevlop"
                                            class="form-control" :class="{ 'is-invalid': form.errors.has('poseDevlop') }">
                                        <has-error :form="form" field="poseDevlop"></has-error>
                                    </div>
                                    <div class="form-group col-4">
                                        <label>Nombre de pose sur la plaque:</label>
                                        <input v-model="form.posePlaque" type="number" name="posePlaque"
                                            class="form-control" :class="{ 'is-invalid': form.errors.has('posePlaque') }">
                                        <has-error :form="form" field="posePlaque"></has-error>
                                    </div>
                                    
                                    <div class="form-group col">
                                        <label>Z Cylindre:</label>
                                        <input v-model="form.ZCylindr" type="number" name="ZCylindr"
                                            class="form-control" :class="{ 'is-invalid': form.errors.has('ZCylindr') }">
                                        <has-error :form="form" field="ZCylindr"></has-error>
                                    </div>
                                    <div class="form-group col">
                                        <label>Espace developpe en mm:</label>
                                        <input v-model="form.espaceDevlop" type="number" name="espaceDevlop"
                                            class="form-control" :class="{ 'is-invalid': form.errors.has('espaceDevlop') }">
                                        <has-error :form="form" field="espaceDevlop"></has-error>
                                    </div>
                                    <div class="form-group col">
                                        <label>Espace Laize en mm :</label>
                                        <input v-model="form.espaceLaize" type="number" name="espaceLaize"
                                            class="form-control" :class="{ 'is-invalid': form.errors.has('espaceLaize') }">
                                        <has-error :form="form" field="espaceLaize"></has-error>
                                    </div>
                                    <div class="form-group col">
                                        <label>Laize papier calculée en mm :</label>
                                        <input v-model="form.laizePapier" type="number" name="laizePapier"
                                            class="form-control" :class="{ 'is-invalid': form.errors.has('laizePapier') }">
                                        <has-error :form="form" field="laizePapier"></has-error>
                                    </div>
                                    <div class="form-group col">
                                        <label>Machine :</label>
                                        <select v-model="form.machine" class="custom-select mr-sm-2" id="machine">
                                            <option disabled selected>choisir la machine</option>
                                            <option value="1">GIDUE 370</option>
                                            <option value="1">MACHINE 2</option>
                                            <option value="1">MACHINE 3</option>
                                        </select>
                                        <has-error :form="form" field="machine"></has-error>
                                    </div>
                                </div>
                        </fieldset>
                        <!--  -->
                        <fieldset class="border p-2">
                            <legend class="w-auto badge badge"><h6><strong> Support d'impression </strong></h6></legend>
                            <div class="row">
                                    <div class="col-3">
                                        <div class="custom-control custom-radio custom-control-inline">
                                            <input type="checkbox" v-model="form.supportImpr" class="form-check-input" id="verificationName">
                                            <label class="form-check-label" for="verificationName">Thermique</label>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="custom-control custom-radio custom-control-inline">
                                            <input type="checkbox" v-model="form.supportImpr1" placeholder="votre remarques ici" class="form-check-input" id="verificationCouleurs">
                                            <label class="form-check-label" for="verificationCouleurs">Couché</label>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="custom-control custom-radio custom-control-inline">
                                            <input type="checkbox" v-model="form.supportImpr2" class="form-check-input" id="verificationCodes">
                                            <label class="form-check-label" for="verificationCodes">PPT</label>
                                        </div>
                                    </div>
                                     <div class="col-3">
                                        <div class="custom-control custom-radio custom-control-inline">
                                            <input type="checkbox" v-model="form.supportImpr3" class="form-check-input" id="verificationCodes">
                                            <label class="form-check-label" for="verificationCodes">PPB</label>
                                        </div>
                                    </div>
                                     <div class="col-3">
                                        <div class="custom-control custom-radio custom-control-inline">
                                            <input type="checkbox" v-model="form.supportImpr4" class="form-check-input" id="verificationCodes">
                                            <label class="form-check-label" for="verificationCodes">Autres</label>
                                        </div>
                                    </div>
                                     <div class="col">
                                        <div v-if="form.supportImpr4 == 'true'" class="custom-control custom-radio custom-control-inline">
                                            <input v-model="form.supportImpr" placeholder="........." type="text" name="supportImpr"
                                            class="form-control" :class="{ 'is-invalid': form.errors.has('supportImpr') }">
                                        </div>
                                    </div>
                            </div>
                        </fieldset>
                        <!--  -->
                    </div>
                    <div class="modal-footer">
                        <el-button type="danger" size="small" data-dismiss="modal" plain>Annuler</el-button>
                        <el-button type="success" v-show="editmode" size="small" @click="updateProduct()" plain>Valider</el-button>
                        <el-button type="success" v-show="!editmode" size="small" @click="createProduct()" plain>Valider</el-button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
    import VueTagsInput from '@johmun/vue-tags-input';
    import {Machine1 , Machine2 ,Machine3, Mandrin, Etiquettes , Clients} from '../devis/Zcylindre';

    export default {
      components: {
          VueTagsInput,
        },
        data () {
            return {
                // formPlaque
                editmode : false,
                DialogVisiblePlaques : false,
                Zcylindre:[],
                plaques: [],
                Machine1: Machine1,
                Machine2: Machine2,
                Machine3: Machine3,
                form: new Form({
                    id:'',
                    num_pfd:'',
                    num_reference:'',
                    date_production:'',
                    numSerie:'',
                    formatMM_L:'',
                    formatMM_D:'',
                    nbrPoseLaize:'',
                    nbrPoseDevlop:'',
                    nbrPoseSurPlaq:'',
                    machine:'',
                    z_cylindre:'',
                    devlop_mm:'',
                    laize_mm:'',
                    laize_papier_mm:'',
                    type_support:'',
                    forme_etq:'',
                }),
                // end plaque
                

                search: '',
                pagesize: 10,
                currpage: 1,
                editmode: false,
                products : [],
                categories: [],
                tag:  '',
                autocompleteItems: [],
            }
        },
        methods: {

            // Plaque
            laizePapierCalculer(){
                var x = 0
                x = (this.form.laize_mm * this.form.nbrPoseLaize)+3*(this.form.nbrPoseLaize-1)+10
                this.form.laize_papier_mm = x;
            },

            totalPosePlaqueCalculer(){
                var xx = 0;
                xx = (this.form.nbrPoseDevlop * this.form.nbrPoseLaize)
                this.form.nbrPoseSurPlaq = xx;
            },

            newPlaque(){
                this.form.reset();
                this.editmode = false;
                this.DialogVisiblePlaques = true;
            },

            changeSelectMachine () {
                console.log(this.form.machine);
                 switch (this.form.machine) {
                     case 'GIDUE-370':
                         this.Zcylindre = this.Machine1;
                         
                         break;
                     case 'NILPITER-B200':
                         this.Zcylindre = this.Machine2;
                        
                         break;
                     case 'ROTOFLEX-400':
                         this.Zcylindre = this.Machine3;
                         
                         break;
                     }
             },

            loadPlaques(page = 1){
                this.$Progress.start();
                axios.get('api/PlaqueDecoupe?page=' + page).then(({ data }) => (this.plaques = data.data));
                this.$Progress.finish();
            },

             createPlaque(){
                this.$Progress.start();

                this.form.post('/api/PlaqueDecoupe')
                .then((data)=>{
                    if(data.data.success){
                    this.DialogVisiblePlaques = false

                    Toast.fire({
                            icon: 'success',
                            title: data.data.message
                        });
                    this.$Progress.finish();
                      this.loadPlaques();
                    //   this.loadDataPrepared();

                    } else {
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });

                    this.$Progress.failed();
                    }
                })
                .catch(()=>{

                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
                })
            },

            updatePlaque(plaque){
                this.editmode = true;
                this.form.reset();
                this.DialogVisiblePlaques = true;
                this.form.fill(plaque);
            },

            updateData(){
              this.$Progress.start();
              this.form.put('/api/PlaqueDecoupe/'+this.form.id)
              .then((response) => {
                  // success
                //   $('#addNew').modal('hide');
                  this.DialogVisiblePlaques = false
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });
                  this.$Progress.finish();
                      //  Fire.$emit('AfterCreate');

                  this.loadPlaques();
              })
              .catch(() => {
                  this.$Progress.fail();
              });

          },

          deletData(id){
              Swal.fire({
                  title: 'Attention !',
                  text: "vous voulez vraiment supprimer Cette Plaque ??",
                  showCancelButton: true,
                  confirmButtonColor: '#67c23a',
                  cancelButtonColor: '#f56c6c',
                  confirmButtonText: 'Oui, supprimez-le ! '
                  }).then((result) => {

                      // Send request to the server
                        if (result.value) {
                              this.form.delete('/api/PlaqueDecoupe/'+id).then(()=>{
                                      Swal.fire(
                                      'Supprimé !',
                                      'La Plaque a été Bien supprimé.',
                                      'success'
                                      );
                                  // Fire.$emit('AfterCreate');
                                  this.loadPlaques();
                              }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                        }
                  })
          },
            // End Plaque

         
            
          loadCategories(){
              axios.get("/api/category/list").then(({ data }) => (this.categories = data.data));
          },
          loadTags(){
              axios.get("/api/tag/list").then(response => {
                  this.autocompleteItems = response.data.data.map(a => {
                      return { text: a.name, id: a.id };
                  });
              }).catch(() => console.warn('Oh. Something went wrong'));
          },
          editModal(product){
              this.editmode = true;
              this.form.reset();
              $('#addNew').modal('show');
              this.form.fill(product);
          },
          newModal(){
              this.editmode = false;
              this.form.reset();
              $('#addNew').modal('show');
          },
          createProduct(){
              this.$Progress.start();

              this.form.post('api/product')
              .then((data)=>{
                if(data.data.success){
                  $('#addNew').modal('hide');

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.$Progress.finish();
                  this.loadProducts();

                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          },
          updateProduct(){
              this.$Progress.start();
              this.form.put('api/product/'+this.form.id)
              .then((response) => {
                  // success
                  $('#addNew').modal('hide');
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });
                  this.$Progress.finish();
                      //  Fire.$emit('AfterCreate');

                  this.loadProducts();
              })
              .catch(() => {
                  this.$Progress.fail();
              });

          },
          deletModal(id){
              Swal.fire({
                  title: 'Attention !',
                  text: "vous voulez vraiment supprimer ce devis",
                  showCancelButton: true,
                  confirmButtonColor: '#67c23a',
                  cancelButtonColor: '#f56c6c',
                  confirmButtonText: 'Oui, supprimez-le ! '
                  }).then((result) => {

                      // Send request to the server
                        if (result.value) {
                              this.form.delete('api/product/'+id).then(()=>{
                                      Swal.fire(
                                      'Supprimé !',
                                      'Votre fichier a été supprimé.',
                                      'success'
                                      );
                                  // Fire.$emit('AfterCreate');
                                  this.loadProducts();
                              }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                        }
                  })
          },
        
         handleCurrentChange(cpage) {
                this.currpage = cpage;
            },
        handleSizeChange(psize) {
                this.pagesize = psize;
            },


        },
        mounted() {
            
        },
        created() {
            this.$Progress.start();

            this.loadPlaques();

            this.$Progress.finish();
        },
        filters: {
            // truncate: function (text, length, suffix) {
            //     return text.substring(0, length) + suffix;
            // },

            
        },
        computed: {
          filteredItems() {
            return this.autocompleteItems.filter(i => {
              return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
            });
          },

          
        },
    }
</script>
