<template>
  <section class="content">
    <div class="container-fluid">
        <el-card class="mb-3 mt-3">
            <div slot="header" class="clearfix text-center">
                <h3><div class="spinner-grow" style="color: white"></div> Liste Des Commandes </h3>
            </div>
        <div class="row">
        <div class="col-6">
            <!-- <el-button type="primary" size="small" icon="el-icon-plus" @click="newModal" plain>Ajouter Devis International</el-button> -->
        </div> 
          <div class="col-6">
              <el-input v-model="search" size="mini" placeholder="Filter : "> 
                  <el-button slot="append" icon="el-icon-search"></el-button>
              </el-input>
          </div>
          <div class="col-12 mt-3">
            <el-table 
                :data="Commandes.filter(data => !search || data.get_fournisseur.name.toLowerCase().includes(search.toLowerCase()) ).slice((currpage - 1) * pagesize, currpage * pagesize)" style="width: 100%"  
                border
                 >
            <el-table-column type="expand">
                <template  slot-scope="scope">
                   <el-table
                    :data="scope.row.get_articles"
                    style="width: 100%">
                        <el-table-column
                            prop="code_article"
                            label="Code Article"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="Nomenclature"
                            label="Nomenclature"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="num_bobines"
                            label="Nombre Bobines"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="laize"
                            label="laize(mm)"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="quantite_ml"
                            label="Quantite ML">
                        </el-table-column>
                        <el-table-column
                            prop="quantite_m2"
                            label="Quantite M²">
                        </el-table-column>
                    </el-table>
                </template>
            </el-table-column>
            <el-table-column label="Fournissuer" >
                <template slot-scope="scope">
                    <span>{{ scope.row.get_fournisseur.name }}</span>
                </template>
            </el-table-column>
            <el-table-column label="devis">
                <template slot-scope="scope">
                    <span>{{ scope.row.devise }}</span>
                </template>
            </el-table-column>
            <el-table-column label="Pays Provonance">
                <template slot-scope="scope">
                    <span>{{ scope.row.pays_provonance }}</span>
                </template>
            </el-table-column>
            <el-table-column label="incoterm">
                <template slot-scope="scope">
                    <span>{{ scope.row.incoterm }}</span>
                </template>
            </el-table-column>
            <el-table-column label="montant">
                <template slot-scope="scope">
                    <span>{{ scope.row.montant }}</span>
                </template>
            </el-table-column>
            <el-table-column label="N° D'enregistrement">
                <template slot-scope="scope">
                    <span>{{ scope.row.num_enregistrement }}</span>
                </template>
            </el-table-column>
            <el-table-column label="Date D'rnregistrement">
                <template slot-scope="scope">
                    <span>{{ scope.row.date_enregistrement }}</span>
                </template>
            </el-table-column>
            <el-table-column v-if="$gate.isAdmin()" width="150" align="center">
                <template slot-scope="{row}">
                        <router-link :to="`/profileCommande/${row.id}`" data-toggle="tooltip" data-placement="top" title="Détails de la Commande">
                            <i class="fa fa-eye green mr-2"></i>
                        </router-link>
                        <el-divider direction="vertical"></el-divider>
                        <a href="#" @click="updateDevis(row)" data-toggle="tooltip" data-placement="top" title="Modifier">
                                <i class="fa fa-edit blue mr-2"></i>
                        </a>
                        <el-divider direction="vertical"></el-divider>
                        <a href="#" @click="deletData(row.id)" data-toggle="tooltip" data-placement="top" title="Supprimer">
                            <i class="fa fa-trash red"></i>
                        </a>
                </template>
            </el-table-column>
            </el-table>
             <!--  -->
            <el-pagination background 
                    class="mt-4"
                    layout="prev, pager, next, sizes, total, jumper"
                    :page-sizes = "[5, 10, 15, 20]"
                    :page-size = "pagesize"
                    :total="Commandes.length"
                    @current-change = "handleCurrentChange" 
                    @size-change = "handleSizeChange" 
                    >
            </el-pagination>
          </div>
        </div>
        </el-card>

        <!-- Modal Update Commande -->
        <el-dialog title="Demander un Devis" :visible.sync="DialogVisible" width="90%" :before-close="handleClose">
            <el-form :model="form" ref="form" >
                        
                        <!-- Articles -->
                        <fieldset class="border p-2">
                            <legend class="w-auto badge"><h5>  </h5></legend>
                            <!-- Start Steps -->
                            <el-steps :active="active" finish-status="success" align-center>
                                <el-step title="Informations De Fournisseur" icon="el-icon-edit-outline"></el-step>
                                <el-step title="Articles" icon="el-icon-box"></el-step>
                                <el-step title="Verification et Confirmation de la Commande" icon="el-icon-folder-checked"></el-step>
                            </el-steps>
                            <!-- fournisseur infos -->
                                <fieldset class="border p-2 mt-5 col-lg-12" v-if="active === 0">
                                    <legend class="w-auto badge"><h5> </h5></legend>
                                    <div class="row">
                                        <el-form-item 
                                            class="col-lg-4" 
                                            label="Fournisseur" 
                                            prop="id_fournisseur"
                                            :rules="{
                                                required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                            }"
                                            >
                                            <el-select v-model="form.id_fournisseur" style="width: 100%" placeholder="CHOISIR UN FOURNISSEUR">
                                                <el-option
                                                    v-for="item in fournisseurs"
                                                    :key="item.id"
                                                    :label="item.name"
                                                    :value="item.id"
                                                ></el-option>
                                            </el-select>
                                        </el-form-item>
                                        <el-form-item 
                                            class="col-lg-4" 
                                            label="Devise" 
                                            prop="devise"
                                            :rules="{
                                                required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                            }"
                                            >
                                            <el-select v-model="form.devise" style="width: 100%" placeholder="CHOISIR DEVISE">
                                                <el-option label="EURO" value="EURO"></el-option>
                                                <el-option label="USD" value="USD"></el-option>
                                                <el-option label="MAD" value="MAD"></el-option>
                                            </el-select>
                                        </el-form-item>
                                        <el-form-item
                                            class="col-lg-4"
                                            label="Pays de Provenance"
                                            prop="pays_provonance"
                                            :rules="{
                                                required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                            }"
                                        >
                                            <el-input v-model="form.pays_provonance" placeholder="Pays de Provenance"></el-input>
                                        </el-form-item>
                                        <el-form-item
                                            class="col-lg-4"
                                            label="Regime"
                                            prop="regime"
                                            :rules="{
                                                required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                            }"
                                        >
                                            <el-input v-model="form.regime" placeholder="Regime"></el-input>
                                        </el-form-item>
                                        <el-form-item
                                            class="col-lg-4"
                                            label="Date de Chargement"
                                            prop="date_chargement"
                                            
                                        >
                                            <el-date-picker type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd"  placeholder="Date de chargement" v-model="form.date_chargement" style="width: 100%;"></el-date-picker>
                                        </el-form-item>
                                    </div>
                                    <fieldset class="border p-2 mt-2">
                                     <legend class="w-auto badge"><h5>INCOTERM</h5></legend>
                                            <el-form-item 
                                                class=" col-lg-12 text-center"
                                                prop="incoterm"
                                                :rules="[
                                                        { required: true, message: 'Ce champ est obligatoire'}
                                                    ]"
                                            >
                                                <el-radio-group v-model="form.incoterm">
                                                    <el-radio label="EX-WORKS (à l'usine)"></el-radio>
                                                    <el-radio label="FCA – Free Carrier (franco transporteur)"></el-radio>
                                                    <el-radio label="Les Incoterms® CPT et CIP"></el-radio>
                                                    <el-radio label="Les Incoterms® DAP, DPU et DDP"></el-radio>
                                                </el-radio-group>
                                            </el-form-item>
                                    </fieldset>
                                    <!-- Images -->
                                    <fieldset v-if="form.incoterm == 'EX-WORKS (à l\'usine)'" class="border p-2 mt-2">
                                        <div class="demo-image__preview text-center">
                                            <el-image
                                                :src="url1"
                                                :preview-src-list="srcList1">
                                            </el-image>
                                        </div>
                                        <el-alert
                                            class="mt-3"
                                            title="Description : "
                                            description="Règle qui impose le moins d'obligations au vendeur, dont l'unique responsabilité consiste à emballer les marchandises et à les mettre à disposition de l'acheteur dans ses propres locaux. En vertu de cette règle, l'acheteur supporte ainsi tous les frais et risques inhérents au chargement et au transport des marchandises jusqu'à leur arrivée à destination.
                                            Les formalités douanières à l'export étant à la charge de l'acheteur, celui-ci peut rencontrer des difficultés dans le pays du vendeur pour obtenir les justificatifs de sortie des marchandises.
                                            C'est pourquoi l'ICC recommande de réserver cette règle aux échanges nationaux ou régionaux n'impliquant pas l'exportation des marchandises et de privilégier la règle FCA en vertu de laquelle les formalités et coûts de dédouanement incombent au vendeur."
                                            type="info"
                                            effect="dark"
                                            :closable="false"
                                            show-icon>
                                        </el-alert>
                                        
                                    </fieldset>
                                    <fieldset v-if="form.incoterm == 'FCA – Free Carrier (franco transporteur)'" class="border p-2 mt-2">
                                        <div class="demo-image__preview text-center">
                                            <el-image
                                                :src="url2"
                                                :preview-src-list="srcList2">
                                            </el-image>
                                            <el-alert
                                                class="mt-3"
                                                title="Description : "
                                                description="Deux options sont possibles pour cet Incoterms® en fonction du lieu de livraison :
                                                            dans les locaux du vendeur, qui procède au chargement des marchandises sur le moyen de transport de l'acheteur (FCA « locaux du vendeur ») ;
                                                            dans tout autre lieu : le vendeur organise l'acheminement des marchandises jusqu'au lieu d'embarquement où elles sont mises à disposition du transporteur prêtes à être déchargées (FCA « autre lieu convenu »).
                                                            En vertu de cette règle, l'acheteur prend en charge la majeure partie du transport, mais lui permet d'être dispensé des formalités dans le pays d'exportation, qui incombent au vendeur."
                                                type="info"
                                                effect="dark"
                                                :closable="false"
                                                show-icon>
                                            </el-alert>
                                        </div>
                                    </fieldset>
                                    <fieldset v-if="form.incoterm == 'Les Incoterms® CPT et CIP'" class="border p-2 mt-2">
                                        <div class="demo-image__preview text-center">
                                            <el-image
                                                :src="url3"
                                                :preview-src-list="srcList3">
                                            </el-image>
                                            <el-alert
                                                class="mt-3"
                                                title="Description : "
                                                description="CPT – Carriage Paid To (port payé jusqu'à)
                                                            Le vendeur supporte le coût du transport jusqu'au lieu de destination mais n'est plus responsable des marchandises, qui voyagent aux risques de l'acheteur. En effet, le transfert des risques intervient au moment de la livraison, dès que les marchandises sont remises au transporteur, tandis que le transfert des coûts à l'acheteur a lieu lorsque les marchandises arrivent à destination.

                                                            CIP – Carriage and Insurance Paid to (port payé assurance comprise jusqu’à)
                                                            Le vendeur supporte les coûts de transport jusqu’au lieu de destination indiqué par l’Incoterms®.
                                                            Règle fréquemment utilisée, notamment pour le transport conteneurisé, le CIP permet de maîtriser l’acheminement des marchandises jusqu’à un point donné.

                                                            De même que pour le CPT, les frais de déchargement au lieu de destination convenu ne sont à la charge du vendeur que si le contrat de transport le prévoit.
                                                            En revanche, à la différence du CPT, le vendeur a l’obligation de souscrire une assurance couvrant les risques liés au transport des marchandises jusqu’au lieu de destination."
                                                type="info"
                                                effect="dark"
                                                :closable="false"
                                                show-icon>
                                            </el-alert>
                                        </div>
                                    </fieldset>
                                    <fieldset v-if="form.incoterm == 'Les Incoterms® DAP, DPU et DDP'" class="border p-2 mt-2">
                                        <div class="demo-image__preview text-center">
                                            <el-image
                                                :src="url4"
                                                :preview-src-list="srcList4">
                                            </el-image>
                                            <el-alert
                                                class="mt-3"
                                                title="Description : "
                                                description="S’agissant des règles Incoterms® D, la livraison étant effectuée dans le pays de destination, le transfert des risques intervient dans celui-ci.

                                                            En vertu de ces Incoterms® dits de « vente à l’arrivée », la marchandise voyage aux risques du vendeur qui assume l’ensemble des risques et des coûts liés au transport des marchandises jusqu’au lieu de destination.

                                                            DAP – Delivered At Place (rendu au lieu de destination)
                                                            Cet Incoterms® signifie que les marchandises sont considérées comme livrées lorsqu’elles sont mises à disposition de l’acheteur à destination sur le moyen de transport arrivant, sans être déchargées. En vertu de cette règle, le vendeur prend en charge le transport des marchandises jusqu’au point de livraison convenu, dans le pays de destination.
                                                            Ainsi, sauf si le contrat de transport en dispose autrement, c’est à l’acheteur qu’incombent les formalités douanières, le paiement des droits et taxes dus en raison de l’importation et le déchargement des marchandises à destination.

                                                            DPU – Delivered at Place Unloaded (rendu au lieu de destination déchargé)
                                                            Le DPU remplace le DAT 2010 et devient une nouvelle règle des Incoterms® 2020.
                                                            Cette règle signifie que les marchandises sont considérées comme livrées, une fois déchargées du moyen de transport et mises à disposition de l’acheteur au lieu de destination convenu (terminal ou autre).
                                                            Dans cette règle Incoterms®, la livraison et l’arrivée à destination interviennent au même point. Le vendeur assume donc tous les risques et coûts liés au transport des marchandises et à leur déchargement jusqu’au lieu désigné.
                                                            Le DPU est la seule règle Incoterms® qui oblige le vendeur à décharger les marchandises à destination.

                                                            DDP – Delivered Duty Paid (rendu droits acquittés)  Règle Incoterms® qui confère le niveau maximal d’obligations au vendeur, qui assume tous les risques et frais, y compris de dédouanement, jusqu’au lieu convenu.
                                                            Ainsi, en vertu de cet Incoterms®, les marchandises sont livrées dédouanées, prêtes à être déchargées au lieu de destination.
                                                            Seuls les frais d’assurance et de déchargement à destination sont à la charge de l’acheteur."
                                                type="info"
                                                effect="dark"
                                                :closable="false"
                                                show-icon>
                                            </el-alert>
                                        </div>
                                    </fieldset>
                                    <!--  -->
                                    <fieldset class="border p-2 mt-2">
                                     <legend class="w-auto badge"><h5></h5></legend>
                                        <div class="col-12 row">
                                            <el-form-item 
                                                class="col-lg-3"
                                                label="Montant en Devise"
                                                prop="montant"
                                                :rules="{
                                                    required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                                }"
                                                >
                                                <el-input v-model="form.montant" type="number" placeholder="Montant en devise"></el-input>
                                            </el-form-item>
                                            <el-form-item 
                                                class="col-lg-3"
                                                label="N° d'enregistrement"
                                                prop="num_enregistrement"
                                                :rules="{
                                                    required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                                }"
                                                >
                                                <el-input v-model="form.num_enregistrement" type="number" placeholder="N° d'enregistrement"></el-input>
                                            </el-form-item>
                                            <el-form-item
                                                class="col-lg-3"
                                                label="Date d'enregistrement"
                                                prop="date_enregistrement"
                                                
                                            >
                                                <el-date-picker format="yyyy-MM-dd" value-format="yyyy-MM-dd" type="date" placeholder="Date d'enregistrement" v-model="form.date_enregistrement" style="width: 100%;"></el-date-picker>
                                            </el-form-item>
                                            <!-- <el-form-item
                                                class="col-lg-3"
                                                label="Date de validité"
                                                prop="date_validite"
                                                
                                            >
                                                <el-date-picker format="yyyy-MM-dd" value-format="yyyy-MM-dd" type="date" placeholder="Date de Validité" v-model="form.date_validite" style="width: 100%;"></el-date-picker>
                                            </el-form-item> -->
                                        </div>
                                    </fieldset>
                                </fieldset>
                            <!-- End Fournisseur infos -->
                            
                            <!-- Articles   -->
                            <fieldset class="border p-2 col-lg-12 mt-5" v-if="active === 1">
                                    <legend class="w-auto badge"><h5> Ajouter Article </h5></legend>
                                    <!--  -->
                                    <tr class="form-group padding: 7px; col-lg-12" v-for="(c, k) in form.articles" :key="k">

                                            <td style="padding: 7px;">
                                                <input class="form-control" placeholder="Code Article" type="text" v-model="c.code_article"
                                                />
                                            </td>
                                            <td style="padding: 7px;">
                                                <input class="form-control" placeholder="Nomenclature" type="text" v-model="c.Nomenclature"
                                                />
                                            </td>
                                            <td style="width: 150px; padding: 7px;">
                                                <input class="form-control" placeholder="Nombre Bobine" type="text"  v-model="c.num_bobines"
                                                />
                                            </td>
                                            <td style="width: 150px; padding: 7px;">
                                                <input class="form-control" placeholder="Laize en(mm)" type="text"  v-model="c.laize"
                                                />
                                            </td>
                                            <td style="width: 150px; padding: 7px;">
                                                <input class="form-control" placeholder="Quantite en ML" type="text"  v-model="c.quantite_ml"
                                                />
                                            </td>
                                            <td style="width: 150px; padding: 7px;">
                                                <input class="form-control" placeholder="Quantite en M²" type="text"  v-model="c.quantite_m2"
                                                />
                                            </td>

                                            <td scope="row" class="trashIconContainer">
                                                <svg
                                                    v-show="form.articles.length > 1"
                                                    @click="deleteRow(k, c)"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 24 24"
                                                    width="24"
                                                    height="24"
                                                    class="ml-2 cursor-pointer"
                                                >
                                                    <path fill="none" d="M0 0h24v24H0z" />
                                                    <path
                                                    fill="#EC4899"
                                                    d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
                                                    />
                                                </svg>
                                            </td>
                                        </tr>
                                        <div class="form-group ml-2 float-right">
                                            <svg  
                                                @click="addNewRow"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 24 24"
                                                    width="24"
                                                    height="24"
                                                    class="ml-2 cursor-pointer"
                                                    >
                                                    <path fill="none" d="M0 0h24v24H0z" />
                                                    <path
                                                    fill="green"
                                                    d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                                                    />
                                                </svg>
                                            </div>
                                    <!--  -->
                            <!-- End Articles -->                       
                            <!-- End Steps -->
                        </fieldset> 
                                <!-- Verification final -->
                                <fieldset class="border p-2 col-lg-12 mt-3" v-if="active === 2">
                                    <legend class="w-auto badge"><h5> </h5></legend>

                                        <el-descriptions title="Information Fournisseur" size="4" border>
                                            <el-descriptions-item><template slot="label"><strong>Fournisseur :</strong></template> {{form.id_fournisseur}}</el-descriptions-item>
                                            <el-descriptions-item ><template slot="label"><strong>Pays de Provenance :</strong></template> {{form.pays_provonance}} </el-descriptions-item>
                                            <el-descriptions-item ><template slot="label"><strong>Regime :</strong></template> {{form.regime}} </el-descriptions-item>
                                            <el-descriptions-item ><template slot="label"><strong>Date de Chargement :</strong></template> {{form.date_chargement}} </el-descriptions-item>
                                            
                                            <el-descriptions-item ><template slot="label"><strong>Incoterm :</strong></template> {{form.incoterm}}</el-descriptions-item>
                                            <el-descriptions-item ><template slot="label"><strong>Montant en devise :</strong></template>{{form.montant}} </el-descriptions-item>
                                            <el-descriptions-item ><template slot="label"><strong>N° d'enregistrement :</strong></template> {{form.num_enregistrement}} </el-descriptions-item>
                                            <el-descriptions-item ><template slot="label"><strong>Date d'enregistrement :</strong></template> {{form.date_enregistrement}} </el-descriptions-item>
                                        </el-descriptions>
                                        
                                        <fieldset class="border p-2 col-lg-12 mt-3">
                                                <legend class="w-auto badge"><h5> Liste des articles </h5></legend>
                                            
                                            <el-table
                                                :data="form.articles"
                                                style="width: 100%">
                                                <el-table-column
                                                fixed
                                                prop="code_article"
                                                label="Code Article"
                                                >
                                                </el-table-column>
                                                <el-table-column
                                                prop="Nomenclature"
                                                label="Nomenclature"
                                                >
                                                </el-table-column>
                                                <el-table-column
                                                prop="num_bobines"
                                                label="Nombre Bobine"
                                                >
                                                </el-table-column>
                                                <el-table-column
                                                prop="laize"
                                                label="Laize (mm)"
                                                >
                                                </el-table-column>
                                                <el-table-column
                                                prop="quantite_ml"
                                                label="Quantite ML"
                                                >
                                                </el-table-column>
                                                <el-table-column
                                                prop="quantite_m2"
                                                label="Quantite M²"
                                                >
                                                </el-table-column>
                                            </el-table>
                                        </fieldset>

                                </fieldset>

                                <!-- End Verification final -->
                            </fieldset>
                            <fieldset class="border p-2 col-lg-12">
                                <el-button v-if="this.active > 0" type="primary" @click="prev" icon="el-icon-arrow-left">L'étape Précédente </el-button>
                                <el-button v-if="this.active < 2" type="primary" class="float-right"  @click="next">L'étape suivante <i class="el-icon-arrow-right"></i></el-button>
                            </fieldset>
                        
                  </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="DialogVisible = false">Annuler</el-button>
                    <el-button  v-if="this.active == 2" type="success" @click="updateData()">Valider</el-button>
                </span>
        </el-dialog>
    </div>
  </section>
</template>

<script>
    import VueTagsInput from '@johmun/vue-tags-input';

    export default {
      components: {
          VueTagsInput,
        },
        data () {
            return {
                search: '',
                pagesize: 10,
                currpage: 1,
                editmode: false,
                active: 0,
                Commandes:[],
                DialogVisible: false,
                fournisseurs:[],

                form: new Form({
                    id : '',
                    id_fournisseur:'',
                    devise:'',
                    pays_provonance:'',
                    regime:'',
                    date_chargement:'',
                    incoterm:'',
                    montant:'',
                    num_enregistrement:'',
                    date_enregistrement:'',
                    date_validite:'',
                    articles: [{  code_article: "",Nomenclature:"",num_bobines:"",laize:"",quantite_ml:"",quantite_m2:"" }],
                    validation:''
                }),
                // End

                // Images Incoterm
                url1: '/images/incoterm/1.png',
                srcList1: ['/images/incoterm/1.png', ],
                url2: '/images/incoterm/2.png',
                srcList2: ['/images/incoterm/2.png', ],
                url3: '/images/incoterm/3.png',
                srcList3: ['/images/incoterm/3.png', ],
                url4: '/images/incoterm/4.png',
                srcList4: ['/images/incoterm/4.png', ],

            }
        },
        methods: {

            // CURD Commandes
            loadCommandes(){
                
                axios.get("api/DevisFournisseur").then(({ data }) => (this.Commandes = data.data.CommandesFournisseur));
            },

            loadFournisseurs(){
                
                axios.get("api/CompteFournisseurs").then(({ data }) => (this.fournisseurs = data.data));
            },

            updateDevis(devis){
                this.editmode = true;
                this.form.reset();
                this.DialogVisible = true;
                this.active=0;
                this.form.fill(devis);
                 this.form.articles = [];
                for (let i = 0; i < devis.get_articles.length; i++) {
                    this.form.articles.push({
                        code_article: devis.get_articles[i].code_article,
                        Nomenclature: devis.get_articles[i].Nomenclature,
                        num_bobines: devis.get_articles[i].num_bobines,
                        laize: devis.get_articles[i].laize,
                        quantite_ml: devis.get_articles[i].quantite_ml,
                        quantite_m2: devis.get_articles[i].quantite_m2,
                    })
                }
            },

            updateData(){
              this.$Progress.start();
              this.form.put('api/DevisFournisseur/'+this.form.id)
              .then((response) => {
                  // success
                  
                this.DialogVisible = false;

                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });
                  this.$Progress.finish();

                  this.loadDevis();
              })
              .catch(() => {
                  this.$Progress.fail();
              });

          },

          deletData(id){
              Swal.fire({
                  title: 'Attention !',
                  text: "vous voulez vraiment supprimer Cette Commande ??",
                  showCancelButton: true,
                  confirmButtonColor: '#67c23a',
                  cancelButtonColor: '#f56c6c',
                  confirmButtonText: 'Oui, supprimez-le ! '
                  }).then((result) => {

                      // Send request to the server
                        if (result.value) {
                              this.form.delete('api/DevisFournisseur/'+id).then(()=>{
                                      Swal.fire(
                                      'Supprimé !',
                                      'Votre Commande a été bien supprimé.',
                                      'success'
                                      );
                                   this.loadDevis();
                              }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                        }
                  })
          },

        //   Autre
        next() {
                this.active++;
            },

        prev() {
                this.active--;
            },

        deleteRow(index, articles) {
                var idx = this.form.articles.indexOf(articles);
                console.log(idx, index);
                if (idx > -1) {
                    this.form.articles.splice(idx, 1);
                }
            },

        addNewRow() {
                this.form.articles.push({
                    code_article: '',
                    Nomenclature: '',
                    num_bobines: '',
                    laize: '',
                    quantite_ml: '',
                    quantite_m2: '',
                })
            },
        handleClose(done) {
                this.$confirm('Voulez-vous vraiment fermer cette boîte de dialogue ?')
                .then(_ => {
                    done();
                })
                .catch(_ => {});
            },

        handleCurrentChange(cpage) {
                this.currpage = cpage;
            },

        handleSizeChange(psize) {
                this.pagesize = psize;
            },


        // End CRUD Commandes

        },
        mounted() {
            
        },
        created() {
            this.$Progress.start();

            this.loadCommandes();
            this.loadFournisseurs();

            this.$Progress.finish();
        },
        filters: {
            
        },
        computed: {
         
        },
    }
</script>
