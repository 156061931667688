<template>
  <section class="content">
    <div class="container-fluid">
        <el-card class="mb-3 mt-3">
            <div slot="header" class="clearfix text-center">
                <h3> Liste Des Comptes Fournisseur </h3>
            </div>
        <div class="row">
        <div class="col-6">
            <el-button type="primary" @click="newCompte()" size="small" icon="el-icon-plus">Nouvelle Ouverture de Compte</el-button>
        </div>
          <div class="col-6">
              <el-input v-model="search" size="mini" placeholder="Filter : "/>
          </div>
          <div class="col-12 mt-3">
            <el-table
                  :data="fournisseurs.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase()) ).slice((currpage - 1) * pagesize, currpage * pagesize)" style="width: 100%"
                  border >
            <el-table-column type="expand">
                <template  slot-scope="props">
                    <div class="row">
                        <div class="col ml-5">
                            <h5>Nature de marchandise vendue</h5>
                            <p class="ml-5"> {{ props.row.NatureMarchandise }}</p>
                            <h5>Responsable Achat :</h5>
                            <p class="ml-5"><strong> Nom Prénom: </strong> {{ props.row.RAchatName }}</p>
                            <p class="ml-5"><strong> E-mail: </strong> {{ props.row.RAchatemail }}</p>
                            <p class="ml-5"><strong> Télé: </strong> {{ props.row.RAchattele }}</p>
                            <h5>Résponsable Commercial :</h5>
                            <p class="ml-5"><strong> Nom Prénom: </strong> {{ props.row.RCommercialName }}</p>
                            <p class="ml-5"><strong> E-mail: </strong> {{ props.row.RCommercialemail }}</p>
                            <p class="ml-5"><strong> Télé: </strong> {{ props.row.RCommercialtele }}</p>
                            <h5>Résponsable Financier :</h5>
                            <p class="ml-5"><strong> Nom Prénom: </strong> {{ props.row.RFinanceName }}</p>
                            <p class="ml-5"><strong> E-mail: </strong> {{ props.row.RFinanceemail }}</p>
                            <p class="ml-5"><strong> Télé: </strong> {{ props.row.RFinancetele }}</p>
                        </div>
                        <div v-if="props.row.RegimeImposition != 'AT'" class="col">
                            <p class="ml-5"><strong> Regime Facturation: </strong> {{ props.row.RegimeImposition }}</p>
                        </div>
                        <div v-if="props.row.RegimeImposition == 'AT'" class="col">
                            <p class="ml-5"><strong> Regime Facturation: </strong> {{ props.row.RegimeImposition }}</p>
                            <h5>Responsable Commerce International :</h5>
                            <p class="ml-5"><strong> Nom Prénom: </strong> {{ props.row.ResponsablATName }}</p>
                            <p class="ml-5"><strong> E-mail: </strong> {{ props.row.ResponsablATemail }}</p>
                            <p class="ml-5"><strong> Télé: </strong> {{ props.row.ResponsablATtele }}</p>
                            <h5>Responsable Transitaire à contactez :</h5>
                            <p class="ml-5"><strong> Nom de Transitaire : </strong> {{ props.row.ResponsablATName2 }}</p>
                            <p class="ml-5"><strong> E-mail de Transitaire : </strong> {{ props.row.ResponsablATemail2 }}</p>
                            <p class="ml-5"><strong> Télé de Transitaire : </strong> {{ props.row.ResponsablATtele2 }}</p>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label=" Nom de societe ">
                <template slot-scope="scope">
                    <span>{{ scope.row.name }}</span>
                </template>
            </el-table-column>
            <el-table-column label=" Siége social ">
                <template slot-scope="scope">
                    <span>{{ scope.row.adressSocial }}</span>
                </template>
            </el-table-column>
            <el-table-column label="ICE">
                <template slot-scope="scope">
                    <span>{{ scope.row.ice }}</span>
                </template>
            </el-table-column>
            <el-table-column label="Forme Juridique">
                <template slot-scope="scope">
                    <span>{{ scope.row.FormJuridique }}</span>
                </template>
            </el-table-column>
            <el-table-column label="Domaine d'activité">
                <template slot-scope="scope">
                    <span>{{ scope.row.DomainActivity }}</span>
                </template>
            </el-table-column>
            <el-table-column label="E-Gerant">
                <template slot-scope="scope">
                    <span>{{ scope.row.GerantName }}</span>
                </template>
            </el-table-column>
            <el-table-column label="Télé">
                <template slot-scope="scope">
                    <span>{{ scope.row.Geranttele }}</span>
                </template>
            </el-table-column>
            
            <el-table-column v-if="$gate.isAdmin()" width="150" align="center">
                <template slot-scope="{row}">
                        <a href="#" @click="updateCompte(row)" data-toggle="tooltip" data-placement="top" title="Modifier">
                                <i class="fa fa-edit blue mr-2"></i>
                        </a>
                        <el-divider direction="vertical"></el-divider>
                        <a href="#" @click="deletData(row.id)" data-toggle="tooltip" data-placement="top" title="Supprimer">
                            <i class="fa fa-trash red"></i>
                        </a>
                </template>
            </el-table-column>
            </el-table>
             <!--  -->
            <el-pagination background
                    class="mt-3"
                    layout="prev, pager, next, sizes, total, jumper"
                    :page-sizes = "[5, 10, 15, 20]"
                    :page-size = "pagesize"
                    :total="fournisseurs.length"
                    @current-change = "handleCurrentChange"
                    @size-change = "handleSizeChange"
                    >
            </el-pagination>
          </div>
        </div>
        </el-card>

        <!-- test upload -->
        

        <!-- end test -->

        <!-- dialog Compte-->
        <el-dialog
            :title="editmode ? 'Modifier Le Compte':'Ouverture De Compte'"
            :visible.sync="DialogVisible"
            width="90%"
            :before-close="handleClose">
            <!-- Body dialog -->
                <el-form :model="form">
                    <!-- Start Steps -->
                        <el-steps :active="active" finish-status="success" align-center>
                            <el-step title="Informations De Fournisseur" icon="el-icon-edit-outline"></el-step>
                            <el-step title="Articles Vendus" icon="el-icon-box"></el-step>
                            <el-step title="Verification et Confirmation" icon="el-icon-folder-checked"></el-step>
                        </el-steps>

                        <div v-if="active === 0">
                            <fieldset class="border p-2 mt-2">
                                    <legend class="w-auto badge"><h5>Type de Fournisseur</h5></legend>
                                    <el-form-item 
                                        class="text-center"
                                        prop="typeFournisseur"
                                    >
                                        <el-radio-group v-model="form.typeFournisseur">
                                            <el-radio label="National" ></el-radio>
                                            <el-radio label="International" ></el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                            </fieldset>

                            <fieldset class="border p-2 mt-2">
                                    <legend class="w-auto badge"><h5>Informations de Siège</h5></legend>
                                    <div class="row">
                                        <el-form-item 
                                            class="text-center col-lg-3"
                                            prop="name"
                                            label="Nom de la societe"
                                        >
                                            <el-input type="text" v-model="form.name" placeholder="Nom de la societe" autocomplete="off"></el-input>
                                        </el-form-item>
                                        <el-form-item 
                                            class="text-center col-lg-3"
                                            prop="adressSocial"
                                            label="Adress Social"
                                        >
                                            <el-input type="text" v-model="form.adressSocial" placeholder="Adress Social" autocomplete="off"></el-input>
                                        </el-form-item>
                                        <el-form-item 
                                            class="text-center col-lg-3"
                                            prop="villeSocial"
                                            label="Ville"
                                        >
                                            <el-input type="text" v-model="form.villeSocial" placeholder="Ville" autocomplete="off"></el-input>
                                        </el-form-item>
                                        <el-form-item 
                                            class="text-center col-lg-3"
                                            prop="paysSocial"
                                            label="Pays"
                                        >
                                            <el-input type="text" v-model="form.paysSocial" placeholder="Pays" autocomplete="off"></el-input>
                                        </el-form-item>
                                        <el-form-item 
                                            class="text-center col-lg-4"
                                            prop="FormJuridique"
                                            label="Forme Juridique"
                                        >
                                            <el-input type="text" v-model="form.FormJuridique" placeholder="Forme Juridique" autocomplete="off"></el-input>
                                        </el-form-item>
                                        <el-form-item 
                                            class="text-center col-lg-4"
                                            prop="DomainActivity"
                                            label="Domaine d'activité"
                                        >
                                            <el-input type="text" v-model="form.DomainActivity" placeholder="Domaine d'activité" autocomplete="off"></el-input>
                                        </el-form-item>
                                        <el-form-item 
                                            class="text-center col-lg-4"
                                            prop="Devise"
                                            label="Devise"
                                        >
                                            <el-input type="text" v-model="form.Devise" placeholder="Devise" autocomplete="off"></el-input>
                                        </el-form-item>
                                        <el-form-item 
                                            class="text-center col-lg-4"
                                            prop="ice"
                                            label="N° ICE"
                                        >
                                            <el-input type="text" v-model="form.ice" placeholder="N° ICE" autocomplete="off"></el-input>
                                        </el-form-item>
                                        <el-form-item 
                                            class="text-center col-lg-4"
                                            prop="NumIF"
                                            label="N° I.F"
                                        >
                                            <el-input type="text" v-model="form.NumIF" placeholder="N° I.F" autocomplete="off"></el-input>
                                        </el-form-item>
                                        <el-form-item 
                                            class="text-center col-lg-4"
                                            prop="NumPatente"
                                            label="N° Patente"
                                        >
                                            <el-input type="text" v-model="form.NumPatente" placeholder="N° Patente" autocomplete="off"></el-input>
                                        </el-form-item>
                                    </div>
                                    
                            </fieldset>
                            
                            <fieldset class="border p-2 mt-2">
                                    <legend class="w-auto badge"><h5>Gérant</h5></legend>
                                    <div class="row">
                                        <el-form-item 
                                            class="text-center col-lg-3"
                                            prop="GerantName"
                                            label="Nom Prénom"
                                        >
                                            <el-input type="text" v-model="form.GerantName" placeholder="Nom Prénom" autocomplete="off"></el-input>
                                        </el-form-item>
                                        <el-form-item 
                                            class="text-center col-lg-3"
                                            prop="Gerantemail"
                                            label="E-mail"
                                        >
                                            <el-input type="email" v-model="form.Gerantemail" placeholder="E-mail" autocomplete="off"></el-input>
                                        </el-form-item>
                                        <el-form-item 
                                            class="text-center col-lg-3"
                                            prop="Geranttele"
                                            label="N° Téléphone(GSM)"
                                        >
                                            <el-input type="text" v-model="form.Geranttele" placeholder="N° Téléphone(GSM)" autocomplete="off"></el-input>
                                        </el-form-item>
                                        <el-form-item 
                                            class="text-center col-lg-3"
                                            prop="Gerantfix"
                                            label="N° Téléphone(FIX)"
                                        >
                                            <el-input type="text" v-model="form.Gerantfix" placeholder="N° Téléphone(FIX)" autocomplete="off"></el-input>
                                        </el-form-item>
                                    </div>
                            </fieldset>

                            <fieldset class="border p-2 mt-2">
                                    <legend class="w-auto badge"><h5>Résponsable Achat</h5></legend>
                                    <div class="row">
                                        <el-form-item 
                                            class="text-center col-lg-3"
                                            prop="RAchatName"
                                            label="Nom Prénom"
                                        >
                                            <el-input type="text" v-model="form.RAchatName" placeholder="Nom Prénom" autocomplete="off"></el-input>
                                        </el-form-item>
                                        <el-form-item 
                                            class="text-center col-lg-3"
                                            prop="RAchatemail"
                                            label="E-mail"
                                        >
                                            <el-input type="email" v-model="form.RAchatemail" placeholder="E-mail" autocomplete="off"></el-input>
                                        </el-form-item>
                                        <el-form-item 
                                            class="text-center col-lg-3"
                                            prop="RAchattele"
                                            label="N° Téléphone(GSM)"
                                        >
                                            <el-input type="text" v-model="form.RAchattele" placeholder="N° Téléphone(GSM)" autocomplete="off"></el-input>
                                        </el-form-item>
                                        <el-form-item 
                                            class="text-center col-lg-3"
                                            prop="RAchatfix"
                                            label="N° Téléphone(FIX)"
                                        >
                                            <el-input type="text" v-model="form.RAchatfix" placeholder="N° Téléphone(FIX)" autocomplete="off"></el-input>
                                        </el-form-item>
                                    </div>
                            </fieldset>

                            <fieldset class="border p-2 mt-2">
                                    <legend class="w-auto badge"><h5>Résponsable Commercial</h5></legend>
                                    <div class="row">
                                        <el-form-item 
                                            class="text-center col-lg-3"
                                            prop="RCommercialName"
                                            label="Nom Prénom"
                                        >
                                            <el-input type="text" v-model="form.RCommercialName" placeholder="Nom Prénom" autocomplete="off"></el-input>
                                        </el-form-item>
                                        <el-form-item 
                                            class="text-center col-lg-3"
                                            prop="RCommercialemail"
                                            label="E-mail"
                                        >
                                            <el-input type="email" v-model="form.RCommercialemail" placeholder="E-mail" autocomplete="off"></el-input>
                                        </el-form-item>
                                        <el-form-item 
                                            class="text-center col-lg-3"
                                            prop="RCommercialtele"
                                            label="N° Téléphone(GSM)"
                                        >
                                            <el-input type="text" v-model="form.RCommercialtele" placeholder="N° Téléphone(GSM)" autocomplete="off"></el-input>
                                        </el-form-item>
                                        <el-form-item 
                                            class="text-center col-lg-3"
                                            prop="RCommercialfix"
                                            label="N° Téléphone(FIX)"
                                        >
                                            <el-input type="text" v-model="form.RCommercialfix" placeholder="N° Téléphone(FIX)" autocomplete="off"></el-input>
                                        </el-form-item>
                                    </div>
                            </fieldset>

                            <fieldset class="border p-2 mt-2">
                                    <legend class="w-auto badge"><h5>Résponsable Financier</h5></legend>
                                    <div class="row">
                                        <el-form-item 
                                            class="text-center col-lg-3"
                                            prop="RFinanceName"
                                            label="Nom Prénom"
                                        >
                                            <el-input type="text" v-model="form.RFinanceName" placeholder="Nom Prénom" autocomplete="off"></el-input>
                                        </el-form-item>
                                        <el-form-item 
                                            class="text-center col-lg-3"
                                            prop="RFinanceemail"
                                            label="E-mail"
                                        >
                                            <el-input type="email" v-model="form.RFinanceemail" placeholder="E-mail" autocomplete="off"></el-input>
                                        </el-form-item>
                                        <el-form-item 
                                            class="text-center col-lg-3"
                                            prop="RFinancetele"
                                            label="N° Téléphone(GSM)"
                                        >
                                            <el-input type="text" v-model="form.RFinancetele" placeholder="N° Téléphone(GSM)" autocomplete="off"></el-input>
                                        </el-form-item>
                                        <el-form-item 
                                            class="text-center col-lg-3"
                                            prop="RFinancefix"
                                            label="N° Téléphone(FIX)"
                                        >
                                            <el-input type="text" v-model="form.RFinancefix" placeholder="N° Téléphone(FIX)" autocomplete="off"></el-input>
                                        </el-form-item>
                                    </div>
                            </fieldset>

                            <fieldset class="border p-2 mt-2">
                                    <legend class="w-auto badge"><h5>Nature de marchandise vendue</h5></legend>
                                    <el-form-item 
                                        class="text-center"
                                        prop="NatureMarchandise"
                                    >
                                        <el-radio-group v-model="form.NatureMarchandise">
                                            <el-radio label="Marchandise en Etat" ></el-radio>
                                            <el-radio label="Matier Premier" ></el-radio>
                                            <el-radio label="Mise a la consommation" ></el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                            </fieldset>

                            <fieldset class="border p-2 mt-2">
                                    <legend class="w-auto badge"><h5>Régime d'imposition</h5></legend>
                                    <el-form-item 
                                        class="text-center"
                                        prop="RegimeImposition"
                                    >
                                        <el-radio-group v-model="form.RegimeImposition">
                                            <el-radio label="AT" ></el-radio>
                                            <el-radio label="TVA" ></el-radio>
                                            <el-radio label="AT / TVA" ></el-radio>
                                            <el-radio label="EXONÉRÉE" ></el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                    <!--  -->
                                    <fieldset v-if="form.RegimeImposition == 'AT'" class="border p-2 mt-2">
                                        <legend class="w-auto badge"><h5>Responsable Commerce International</h5></legend>
                                            <div class="row">
                                                <el-form-item 
                                                    class="text-center col-lg-3"
                                                    prop="ResponsablATName"
                                                    label="Nom Prénom"
                                                >
                                                    <el-input type="text" v-model="form.ResponsablATName" placeholder="Nom Prénom" autocomplete="off"></el-input>
                                                </el-form-item>
                                                <el-form-item 
                                                    class="text-center col-lg-3"
                                                    prop="ResponsablATemail"
                                                    label="E-mail"
                                                >
                                                    <el-input type="email" v-model="form.ResponsablATemail" placeholder="E-mail" autocomplete="off"></el-input>
                                                </el-form-item>
                                                <el-form-item 
                                                    class="text-center col-lg-3"
                                                    prop="ResponsablATtele"
                                                    label="N° Téléphone(GSM)"
                                                >
                                                    <el-input type="text" v-model="form.ResponsablATtele" placeholder="N° Téléphone(GSM)" autocomplete="off"></el-input>
                                                </el-form-item>
                                                
                                                <el-form-item 
                                                    class="text-center col-lg-3"
                                                    prop="Transitairefix"
                                                    label="N° Téléphone(FIX)"
                                                >
                                                    <el-input type="text" v-model="form.Transitairefix" placeholder="N° Téléphone(FIX)" autocomplete="off"></el-input>
                                                </el-form-item>
                                        </div>
                                    </fieldset>
                                    <fieldset v-if="form.RegimeImposition == 'AT'" class="border p-2 mt-2">
                                        <legend class="w-auto badge"><h5>Responsable Transitaire à contactez</h5></legend>
                                            <div class="row">
                                                <el-form-item 
                                                    class="text-center col-lg-3"
                                                    prop="ResponsablATName2"
                                                    label="Nom Prénom"
                                                >
                                                    <el-input type="text" v-model="form.ResponsablATName2" placeholder="Nom Prénom" autocomplete="off"></el-input>
                                                </el-form-item>
                                                <el-form-item 
                                                    class="text-center col-lg-3"
                                                    prop="ResponsablATemail2"
                                                    label="E-mail"
                                                >
                                                    <el-input type="email" v-model="form.ResponsablATemail2" placeholder="E-mail" autocomplete="off"></el-input>
                                                </el-form-item>
                                                <el-form-item 
                                                    class="text-center col-lg-3"
                                                    prop="ResponsablATtele2"
                                                    label="N° Téléphone(GSM)"
                                                >
                                                    <el-input type="text" v-model="form.ResponsablATtele2" placeholder="N° Téléphone(GSM)" autocomplete="off"></el-input>
                                                </el-form-item>
                                                
                                                <el-form-item 
                                                    class="text-center col-lg-3"
                                                    prop="Transitairefix2"
                                                    label="N° Téléphone(FIX)"
                                                >
                                                    <el-input type="text" v-model="form.Transitairefix2" placeholder="N° Téléphone(FIX)" autocomplete="off"></el-input>
                                                </el-form-item>
                                        </div>
                                    </fieldset>
                            </fieldset>

                            <fieldset class="border p-2 mt-2">
                                    <legend class="w-auto badge"><h5>Coordonnées Bancaires</h5></legend>
                                    <div class="row">
                                        <el-form-item 
                                            class="text-center col-lg-4"
                                            prop="BanqueName"
                                            label="Nom de la banque"
                                        >
                                            <el-input type="text" v-model="form.BanqueName" placeholder="Nom de la banque" autocomplete="off"></el-input>
                                        </el-form-item>
                                        <el-form-item 
                                            class="text-center col-lg-4"
                                            prop="AdresseBanque"
                                            label="Adresse de la banque"
                                        >
                                            <el-input type="text" v-model="form.AdresseBanque" placeholder="Adresse de la banque" autocomplete="off"></el-input>
                                        </el-form-item>
                                        <el-form-item 
                                            class="text-center col-lg-4"
                                            prop="villeBanque"
                                            label="Ville"
                                        >
                                            <el-input type="text" v-model="form.villeBanque" placeholder="Ville" autocomplete="off"></el-input>
                                        </el-form-item>
                                        <el-form-item 
                                            class="text-center col-lg-4"
                                            prop="Swift"
                                            label="SWIFT"
                                        >
                                            <el-input type="text" v-model="form.Swift" placeholder="SWIFT" autocomplete="off"></el-input>
                                        </el-form-item>
                                        <el-form-item 
                                            class="text-center col-lg-4"
                                            prop="rib"
                                            label="RIB"
                                        >
                                            <el-input type="text" v-model="form.rib" placeholder="RIB" autocomplete="off"></el-input>
                                        </el-form-item>
                                    </div>
                            </fieldset>
                            <fieldset class="border p-2 mt-2">
                                    <legend class="w-auto badge"><h5>Modalité de Paiement</h5></legend>
                                    <el-form-item 
                                        class="text-center"
                                        prop="ModalitePaiment"
                                    >
                                        <el-radio-group v-model="form.ModalitePaiment">
                                            <el-radio label="30% A la commande" ></el-radio>
                                            <el-radio label="50% A la commande" ></el-radio>
                                            <el-radio label="90% A la commande" ></el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                            </fieldset>

                            <!-- Sucursal -->
                                <fieldset class="border p-2 mt-2">
                                        <legend class="w-auto badge"><h5>L'existance de sucursal</h5></legend>
                                        <el-form-item 
                                            class="text-center"
                                            prop="succursal"
                                        >
                                            <el-radio-group @change="sucursal" v-model="form.succursal">
                                                <el-radio label="OUI"></el-radio>
                                                <el-radio label="Non"></el-radio>
                                            </el-radio-group>
                                        </el-form-item>
                                        <!-- Succursal -->
                                        <div v-if="form.succursal == 'OUI'">
                                            <fieldset class="border p-2 mt-2">
                                                <div class="row">
                                                    <el-form-item 
                                                        class="text-center col-lg-3"
                                                        prop="NomSucursal"
                                                        label="Nom De Succursal"
                                                    >
                                                        <el-input type="text" v-model="form.NomSucursal" placeholder="Nom De Succursal" autocomplete="off"></el-input>
                                                    </el-form-item>
                                                    <el-form-item 
                                                        class="text-center col-lg-3"
                                                        prop="AdressSucursal"
                                                        label="Adress De Succursal"
                                                    >
                                                        <el-input type="text" v-model="form.AdressSucursal" placeholder="Adress De Succursal" autocomplete="off"></el-input>
                                                    </el-form-item>
                                                    <el-form-item 
                                                        class="text-center col-lg-3"
                                                        prop="TeleSucursal"
                                                        label="Télé De Succursal"
                                                    >
                                                        <el-input type="text" v-model="form.TeleSucursal" placeholder="Télé De Succursal" autocomplete="off"></el-input>
                                                    </el-form-item>
                                                </div>
                                            </fieldset>
                                            <fieldset class="border p-2 mt-2">
                                                <legend class="w-auto badge"><h5>Gérant de sucursal</h5></legend>
                                                <div class="row">
                                                    <el-form-item 
                                                        class="text-center col-lg-3"
                                                        prop="GerantNameSucursal"
                                                        label="Nom Prénom"
                                                    >
                                                        <el-input type="text" v-model="form.GerantNameSucursal" placeholder="Nom Prénom" autocomplete="off"></el-input>
                                                    </el-form-item>
                                                    <el-form-item 
                                                        class="text-center col-lg-3"
                                                        prop="GerantSemail"
                                                        label="E-mail"
                                                    >
                                                        <el-input type="email" v-model="form.GerantSemail" placeholder="E-mail" autocomplete="off"></el-input>
                                                    </el-form-item>
                                                    <el-form-item 
                                                        class="text-center col-lg-3"
                                                        prop="GerantStele"
                                                        label="N° Téléphone(GSM)"
                                                    >
                                                        <el-input type="text" v-model="form.GerantStele" placeholder="N° Téléphone(GSM)" autocomplete="off"></el-input>
                                                    </el-form-item>
                                                    <el-form-item 
                                                        class="text-center col-lg-3"
                                                        prop="GerantSfix"
                                                        label="N° Téléphone(FIX)"
                                                    >
                                                        <el-input type="text" v-model="form.GerantSfix" placeholder="N° Téléphone(FIX)" autocomplete="off"></el-input>
                                                    </el-form-item>
                                                </div>
                                        </fieldset>
                                            <fieldset class="border p-2 mt-2">
                                        <legend class="w-auto badge"><h5>Résponsable d'achat de sucursal</h5></legend>
                                        <div class="row">
                                            <el-form-item 
                                                class="text-center col-lg-3"
                                                prop="RAchatSucursalName"
                                                label="Nom Prénom"
                                            >
                                                <el-input type="text" v-model="form.RAchatSucursalName" placeholder="Nom Prénom" autocomplete="off"></el-input>
                                            </el-form-item>
                                            <el-form-item 
                                                class="text-center col-lg-3"
                                                prop="RAchatSucursalemail"
                                                label="E-mail"
                                            >
                                                <el-input type="email" v-model="form.RAchatSucursalemail" placeholder="E-mail" autocomplete="off"></el-input>
                                            </el-form-item>
                                            <el-form-item 
                                                class="text-center col-lg-3"
                                                prop="RAchatSucursaltele"
                                                label="N° Téléphone(GSM)"
                                            >
                                                <el-input type="text" v-model="form.RAchatSucursaltele" placeholder="N° Téléphone(GSM)" autocomplete="off"></el-input>
                                            </el-form-item>
                                            <el-form-item 
                                                class="text-center col-lg-3"
                                                prop="RAchatSucursalfix"
                                                label="N° Téléphone(FIX)"
                                            >
                                                <el-input type="text" v-model="form.RAchatSucursalfix" placeholder="N° Téléphone(FIX)" autocomplete="off"></el-input>
                                            </el-form-item>
                                        </div>
                                </fieldset>
                                <fieldset class="border p-2 mt-2">
                                        <legend class="w-auto badge"><h5>Résponsable Commercial De Sucursal</h5></legend>
                                        <div class="row">
                                            <el-form-item 
                                                class="text-center col-lg-3"
                                                prop="RCommercialSucursalName"
                                                label="Nom Prénom"
                                            >
                                                <el-input type="text" v-model="form.RCommercialSucursalName" placeholder="Nom Prénom" autocomplete="off"></el-input>
                                            </el-form-item>
                                            <el-form-item 
                                                class="text-center col-lg-3"
                                                prop="RCommercialSucursalemail"
                                                label="E-mail"
                                            >
                                                <el-input type="email" v-model="form.RCommercialSucursalemail" placeholder="E-mail" autocomplete="off"></el-input>
                                            </el-form-item>
                                            <el-form-item 
                                                class="text-center col-lg-3"
                                                prop="RCommercialSucursaltele"
                                                label="N° Téléphone(GSM)"
                                            >
                                                <el-input type="text" v-model="form.RCommercialSucursaltele" placeholder="N° Téléphone(GSM)" autocomplete="off"></el-input>
                                            </el-form-item>
                                            <el-form-item 
                                                class="text-center col-lg-3"
                                                prop="RCommercialSucursalfix"
                                                label="N° Téléphone(FIX)"
                                            >
                                                <el-input type="text" v-model="form.RCommercialSucursalfix" placeholder="N° Téléphone(FIX)" autocomplete="off"></el-input>
                                            </el-form-item>
                                        </div>
                                </fieldset>
                                <fieldset class="border p-2 mt-2">
                                        <legend class="w-auto badge"><h5>Résponsable Financier De Sucursal</h5></legend>
                                        <div class="row">
                                            <el-form-item 
                                                class="text-center col-lg-3"
                                                prop="RFinanceSucursalName"
                                                label="Nom Prénom"
                                            >
                                                <el-input type="text" v-model="form.RFinanceSucursalName" placeholder="Nom Prénom" autocomplete="off"></el-input>
                                            </el-form-item>
                                            <el-form-item 
                                                class="text-center col-lg-3"
                                                prop="RFinanceSucursalemail"
                                                label="E-mail"
                                            >
                                                <el-input type="email" v-model="form.RFinanceSucursalemail" placeholder="E-mail" autocomplete="off"></el-input>
                                            </el-form-item>
                                            <el-form-item 
                                                class="text-center col-lg-3"
                                                prop="RFinanceSucursaltele"
                                                label="N° Téléphone(GSM)"
                                            >
                                                <el-input type="text" v-model="form.RFinanceSucursaltele" placeholder="N° Téléphone(GSM)" autocomplete="off"></el-input>
                                            </el-form-item>
                                            <el-form-item 
                                                class="text-center col-lg-3"
                                                prop="RFinanceSucursalfix"
                                                label="N° Téléphone(FIX)"
                                            >
                                                <el-input type="text" v-model="form.RFinanceSucursalfix" placeholder="N° Téléphone(FIX)" autocomplete="off"></el-input>
                                            </el-form-item>
                                        </div>
                                </fieldset>
                                <fieldset class="border p-2 mt-2">
                                        <legend class="w-auto badge"><h5>Coordonnées Bancaires Sucursal</h5></legend>
                                        <div class="row">
                                            <el-form-item 
                                                class="text-center col-lg-4"
                                                prop="BanqueNameSucursal"
                                                label="Nom de la banque"
                                            >
                                                <el-input type="text" v-model="form.BanqueNameSucursal" placeholder="Nom de la banque" autocomplete="off"></el-input>
                                            </el-form-item>
                                            <el-form-item 
                                                class="text-center col-lg-4"
                                                prop="AdresseBanqueSucursal"
                                                label="Adresse de la banque"
                                            >
                                                <el-input type="text" v-model="form.AdresseBanqueSucursal" placeholder="Adresse de la banque" autocomplete="off"></el-input>
                                            </el-form-item>
                                            <el-form-item 
                                                class="text-center col-lg-4"
                                                prop="villeBanqueSucursal"
                                                label="Ville"
                                            >
                                                <el-input type="text" v-model="form.villeBanqueSucursal" placeholder="Ville" autocomplete="off"></el-input>
                                            </el-form-item>
                                            <el-form-item 
                                                class="text-center col-lg-4"
                                                prop="SwiftSucursal"
                                                label="SWIFT"
                                            >
                                                <el-input type="text" v-model="form.SwiftSucursal" placeholder="SWIFT" autocomplete="off"></el-input>
                                            </el-form-item>
                                        </div>
                                </fieldset>
                                    </div>
                            </fieldset>
                            <!-- End Sucursal -->

                        </div>
                    <!-- Articles -->
                        <div v-if="active === 1">
                            <fieldset class="border p-2 col-lg-12 mt-5">
                                        <legend class="w-auto badge"><h5> Ajouter Article </h5></legend>
                                        <div class="row">
                                            <el-form-item class="col-lg-3" label="Categorie">
                                                    <el-select v-model="ArticlesForm.categori_article" @change="changeSelectCategorie()" placeholder="select Categorie">
                                                        <el-option label="Matier Premier" value="Matier Premier"></el-option>
                                                        <el-option label="Consommables" value="Consommables"></el-option>
                                                        <el-option label="Marchandise" value="Marchandise"></el-option>
                                                    </el-select>
                                                </el-form-item>
                                                <el-form-item class="col-lg-3" label="Sous-Categorie">
                                                    <el-select v-model="ArticlesForm.sousCategori_article" @change="changeSousCategorie()" placeholder="select Sous-Categorie">
                                                        <el-option
                                                            v-for="item in Souscategorie"
                                                            :key="item.value"
                                                            :label="item.label"
                                                            :value="item.value"
                                                        ></el-option>
                                                    </el-select>
                                                </el-form-item>
                                                <el-form-item class="col-lg-4" label="Designiation Fournisseur">
                                                    <el-input v-model="ArticlesForm.designiation"></el-input>
                                                </el-form-item>
                                                <el-form-item class="col-lg-4" label="Code Article Fournisseur">
                                                    <el-input v-model="ArticlesForm.Code_Article_four"></el-input>
                                                </el-form-item>
                                                <el-form-item class="col-lg-4" label="Nomenclature International">
                                                    <el-input v-model="ArticlesForm.Nomenclature"></el-input>
                                                </el-form-item>
                                                <el-form-item class="col-lg-4" label="Designiation Interne">
                                                    <el-input v-model="ArticlesForm.designiationIntern"></el-input>
                                                </el-form-item>
                                                <el-form-item class="col-lg-4" label="Code Article Interne">
                                                    <el-input v-model="ArticlesForm.Code_ArticleIntern"></el-input>
                                                </el-form-item>
                                                <el-form-item class="col-lg-4" label="Nomenclature Marocaine">
                                                    <el-input v-model="ArticlesForm.NomenclatureIntern"></el-input>
                                                </el-form-item>
                                                <el-form-item class="col-lg-4" label="Unite Mesure">
                                                    <el-input v-model="ArticlesForm.unite_mesure"></el-input>
                                                </el-form-item>
                                                <!-- <el-form-item v-if="this.sousCategorieSelected != 'mp1'" class="col-lg-3" label="Quantit Unitaire">
                                                    <el-input v-model="ArticlesForm.quantite_ml_bobine"></el-input>
                                                </el-form-item> -->
                                                <el-form-item class="col-lg-2" label="Regime économique">
                                                    <el-select v-model="ArticlesForm.regime_economique" @change="changeRegime()" placeholder="Select Régime économique">
                                                        <el-option label="10" value="10"></el-option>
                                                        <el-option label="22" value="22"></el-option>
                                                        <el-option label="37" value="37"></el-option>
                                                        <el-option label="81" value="81"></el-option>
                                                        <el-option label="Autre" value="Autre"></el-option>
                                                    </el-select>
                                                </el-form-item>
                                                <el-form-item class="col-lg-4" label="Nome Regime économique">
                                                    <el-input v-model="ArticlesForm.regime_economique_name"></el-input>
                                                </el-form-item>
                                        <!-- mp2 marchandise2 -->
                                                <!-- <el-form-item v-if="this.sousCategorieSelected == 'mp2' || this.sousCategorieSelected == 'march1' || this.sousCategorieSelected == 'march5'" class="col-lg-3" label="Dimension">
                                                    <el-input v-model="ArticlesForm.dimension"></el-input>
                                                </el-form-item> -->
                                        <!-- end mp2 marchandise2 -->
                                                <el-form-item class="col-lg-3" label="Ref">
                                                    <el-input v-model="ArticlesForm.referance"></el-input>
                                                </el-form-item>
                                    <!-- mp1 -->
                                            <!-- <div class="col-12 row" v-if="this.sousCategorieSelected == 'mp1'">
                                                <el-form-item class="col-lg-3" label="Nombre Bobine">
                                                    <el-input @change="CalaculQtitML()" v-model="ArticlesForm.NombreBobine"></el-input>
                                                </el-form-item>
                                                <el-form-item class="col-lg-3" label="Quantit ML">
                                                    <el-input @change="CalaculQtitML()" v-model="ArticlesForm.quantite_ml_bobine"></el-input>
                                                </el-form-item>
                                                <el-form-item class="col-lg-3" label="Laize (mm)">
                                                    <el-input @change="CalaculQtitML()" v-model="ArticlesForm.laize_mm"></el-input>
                                                </el-form-item>
                                                <el-form-item class="col-lg-3" label="quantite_ml_total">
                                                    <el-input v-model="ArticlesForm.quantite_ml_total"></el-input>
                                                </el-form-item>
                                                <el-form-item class="col-lg-3" label="quantite_m2_total">
                                                    <el-input v-model="ArticlesForm.quantite_m2_total"></el-input>
                                                </el-form-item>
                                            </div> -->
                                <!-- end mp1 -->
                                                <el-form-item class="col-lg-3" label="Prix Unitaire">
                                                    <el-input v-model="ArticlesForm.prix_unit"></el-input>
                                                </el-form-item>
                                                <!-- <el-form-item class="col-lg-3" label="Prix Total">
                                                    <el-input v-model="ArticlesForm.prix_total"></el-input>
                                                </el-form-item> -->
                                    <!-- mp2 -->
                                            <!-- <div class="col-12 row" v-if="this.sousCategorieSelected == 'mp2'">
                                                <el-form-item class="col-lg-3" label="diametre">
                                                    <el-select v-model="ArticlesForm.diametre" placeholder="Select l'emballage">
                                                        <el-option label="76" value="76"></el-option>
                                                        <el-option label="40" value="40"></el-option>
                                                        <el-option label="Autre" value="Autre"></el-option>
                                                    </el-select>
                                                </el-form-item>
                                                <el-form-item class="col-lg-3" label="nombre_mandrin_kg">
                                                    <el-input v-model="ArticlesForm.nombre_mandrin_kg"></el-input>
                                                </el-form-item>
                                            </div> -->
                                <!-- End mp2 -->
                                <!-- consomable -->
                                            <!-- <div class="col-12 row" v-if="this.sousCategorieSelected == 'consomables'">
                                                <el-form-item class="col-lg-3" label="Emballage">
                                                    <el-select v-model="ArticlesForm.emballage" @change="changePots()" placeholder="Select l'emballage">
                                                        <el-option label="1 KG" value="1"></el-option>
                                                        <el-option label="2.5 KG" value="2.5"></el-option>
                                                        <el-option label="5 KG" value="5"></el-option>
                                                        <el-option label="10 KG" value="10"></el-option>
                                                        <el-option label="25 KG" value="25"></el-option>
                                                    </el-select>
                                                </el-form-item>
                                                <el-form-item class="col-lg-3" label="Quantit pots">
                                                    <el-input @change="changePots()" v-model="ArticlesForm.qtit_pots"></el-input>
                                                </el-form-item>
                                                <el-form-item class="col-lg-3" label="Quantit pots total">
                                                    <el-input @change="CalculConsomablePrix()" v-model="ArticlesForm.qtit_pots_total"></el-input>
                                                </el-form-item>
                                            </div> -->
                            <!-- end Consommable -->
                            <!-- marchandise 2 -->
                                            <!-- <div class="col-lg-3" v-if="this.sousCategorieSelected == 'march2'">
                                                <el-form-item  label="laize cm">
                                                    <el-input v-model="ArticlesForm.laize_cm"></el-input>
                                                </el-form-item>
                                            </div> -->
                        <!-- End marchandise 2 -->
                                            </div>
                                        <!--  -->
                                        <el-button type="success" @click="addNewRow" icon="el-icon-plus" class="float-right">Ajouter l'article</el-button>

                                        <!--  -->
                                <!-- End Articles -->                       
                            </fieldset>
                            <fieldset class="p-2 col-lg-12 mt-3">
                                    <legend class="w-auto badge"><h5> Liste des Articles Vendus </h5></legend>
                                        <div v-for="(c, k) in form.ArticlesVendueF" :key="k">
                                            <el-descriptions class="margin-top" border :title="'Code Article : '+c.Code_Article_four" v-if="c.categori_article != ''">
                                                <template slot="extra">
                                                    <el-button @click="updateArticle(k, c)" size="mini"  data-toggle="tooltip" data-placement="top" title="Modifier l'article" type="primary" icon="el-icon-edit" circle></el-button>
                                                    <el-divider direction="vertical"></el-divider>
                                                    <el-button type="danger" @click="deleteRow(k, c)" size="mini" icon="el-icon-delete" data-toggle="tooltip" data-placement="top" title="Supprimer l'article" circle></el-button>
                                                </template>
                                                <!-- M1 -->
                                                
                                                <el-descriptions-item label="Categorie">{{c.categori_article}}</el-descriptions-item>
                                                <el-descriptions-item label="Sous-Catégorie">{{c.sousCategori_article}}</el-descriptions-item>
                                                <el-descriptions-item label="designiation">{{c.designiation}}</el-descriptions-item>
                                                <el-descriptions-item label="Nomenclature"><el-tag size="small">{{c.Nomenclature}}</el-tag></el-descriptions-item>
                                                <el-descriptions-item label="Code Article">{{c.Code_Article_four}}</el-descriptions-item>
                                                <el-descriptions-item label="Unite Mesure">{{c.unite_mesure}}</el-descriptions-item>
                                                <!-- <el-descriptions-item label="Dimension">{{c.dimension}}</el-descriptions-item> -->
                                                <el-descriptions-item label="Régime économique">{{c.regime_economique}} : {{c.regime_economique_name}}</el-descriptions-item>
                                                <el-descriptions-item label="Ref">{{c.referance}}</el-descriptions-item>
                                                <!-- M1 -->
                                                <!-- <span v-show="c.sousCategori_article == 'Papier' || c.sousCategori_article == 'P.P' || c.sousCategori_article == 'Fruit tag'">
                                                    <el-descriptions-item label="Nombre Bobine">{{c.NombreBobine}}</el-descriptions-item>
                                                    <el-descriptions-item label="Quantité ML Par Bobine">{{c.quantite_ml_bobine}}</el-descriptions-item>
                                                    <el-descriptions-item label="laize (mm)">{{c.laize_mm}}</el-descriptions-item>
                                                    <el-descriptions-item label="Quantité ML ">{{c.quantite_ml_total}}</el-descriptions-item>
                                                    <el-descriptions-item label="Quantité M²">{{c.quantite_m2_total}}</el-descriptions-item>
                                                </span> -->
                                                <!--  -->
                                                <!-- M2 -->
                                                <!-- <span v-show="c.sousCategori_article == 'Mandrin en carton'">
                                                    <el-descriptions-item label="Diametre">{{c.diametre}}</el-descriptions-item>
                                                    <el-descriptions-item label="Nombre Mandrin par kg">{{c.nombre_mandrin_kg}}</el-descriptions-item>
                                                </span> -->
                                                <!--  -->
                                                <!-- Consomation -->
                                                <!-- <span v-show="c.sousCategori_article == 'Ancres' || c.sousCategori_article == 'Assytat' || c.sousCategori_article == 'White Spirit' || c.sousCategori_article == 'Huile 46'">
                                                    <el-descriptions-item label="Emballage">{{c.emballage}}</el-descriptions-item>
                                                    <el-descriptions-item label="Quantit Pots">{{c.qtit_pots}}</el-descriptions-item>
                                                    <el-descriptions-item label="Quantit Pots Total">{{c.qtit_pots_total}}</el-descriptions-item>
                                                </span> -->
                                                <!--  -->
                                                <el-descriptions-item label="Prix Unit ">{{c.prix_unit}}</el-descriptions-item>
                                                <!-- <el-descriptions-item label="Prix Total">{{c.prix_total}}</el-descriptions-item> -->
                                            </el-descriptions>
                                        </div>
                            </fieldset>
                        </div>
                    <!-- Verification final -->
                        <fieldset class="border p-2 col-lg-12 mt-3" v-if="active === 2">
                            <legend class="w-auto badge"><h5> </h5></legend>

                                <el-descriptions title="Information Fournisseur" size="4" border>
                                    <el-descriptions-item><template slot="label"><strong>Fournisseur :</strong></template> {{form.name}}</el-descriptions-item>
                                    <el-descriptions-item ><template slot="label"><strong>typeFournisseur :</strong></template> {{form.typeFournisseur}} </el-descriptions-item>
                                    <el-descriptions-item ><template slot="label"><strong>DomainActivity :</strong></template> {{form.DomainActivity}}</el-descriptions-item>
                                    <el-descriptions-item ><template slot="label"><strong>Devise :</strong></template>{{form.Devise}} </el-descriptions-item>
                                    <el-descriptions-item ><template slot="label"><strong>N° ICE :</strong></template> {{form.ice}} </el-descriptions-item>
                                    <el-descriptions-item ><template slot="label"><strong>Nature de marchandise vendue :</strong></template> {{form.natureMSES}} </el-descriptions-item>
                                    <el-descriptions-item ><template slot="label"><strong>Régime d'imposition :</strong></template> {{form.regime_imposition}} </el-descriptions-item>
                                    <el-descriptions-item ><template slot="label"><strong>Banque :</strong></template> {{form.BanqueName}} </el-descriptions-item>
                                    <el-descriptions-item ><template slot="label"><strong>L'existance de sucursal :</strong></template> {{form.succursal}} </el-descriptions-item>
                                </el-descriptions>
                                
                                <fieldset class="border p-2 col-lg-12 mt-3">
                                        <legend class="w-auto badge"><h5> Liste des Articles </h5></legend>
                                            <div v-for="(c, k) in form.ArticlesVendueF" :key="k">
                                                <el-descriptions class="margin-top" border :title="'Code Article : '+c.Code_Article_four" v-if="c.categori_article != ''">
                                                    <!-- M1 -->
                                                    <el-descriptions-item label="Categorie">{{c.categori_article}}</el-descriptions-item>
                                                    <el-descriptions-item label="Sous-Catégorie">{{c.sousCategori_article}}</el-descriptions-item>
                                                    <el-descriptions-item label="designiation">{{c.designiation}}</el-descriptions-item>
                                                    <el-descriptions-item label="Nomenclature"><el-tag size="small">{{c.Nomenclature}}</el-tag></el-descriptions-item>
                                                    <el-descriptions-item label="Code Article">{{c.Code_Article_four}}</el-descriptions-item>
                                                    <el-descriptions-item label="Unite Mesure">{{c.unite_mesure}}</el-descriptions-item>
                                                    <!-- <el-descriptions-item label="Dimension">{{c.dimension}}</el-descriptions-item> -->
                                                    <el-descriptions-item label="Régime économique">{{c.regime_economique}} : {{c.regime_economique_name}}</el-descriptions-item>
                                                    <el-descriptions-item label="Ref">{{c.referance}}</el-descriptions-item>
                                                    
                                                    <el-descriptions-item label="Prix Unit ">{{c.prix_unit}}</el-descriptions-item>
                                                </el-descriptions>
                                            </div>
                                </fieldset>
                        </fieldset>
                    <!-- End Verification final -->

                    <fieldset class="border p-2 col-lg-12">
                            <el-button v-if="this.active > 0" type="primary" @click="prev" icon="el-icon-arrow-left">L'étape Précédente </el-button>
                            <el-button v-if="this.active < 2" type="primary" class="float-right"  @click="next">L'étape suivante <i class="el-icon-arrow-right"></i></el-button>
                        </fieldset>
                </el-form>
                <!-- ENd body Dialog -->
            <span slot="footer" class="dialog-footer">
                <el-button @click="DialogVisible = false">Cancel</el-button>
                <el-button type="primary" v-if="this.active == 2" v-show="!editmode" @click="createData()">Ajouter</el-button>
                <el-button type="primary" v-if="this.active == 2" v-show="editmode" @click="updateData()">Modifier</el-button>
            </span>
        </el-dialog>
        <!-- Modal -->
        <!-- Dialog Article -->
        <el-dialog
            title="Modifier L'article"
            :visible.sync="DialogArticleVisible"
            width="70%"
            :before-close="handleClose">
              <el-form>
                  <div class="row">
                    <el-form-item class="col-lg-3" label="Categorie">
                            <el-select v-model="ArticlesForm.categori_article" @change="changeSelectCategorie()" placeholder="select Categorie">
                                <el-option label="Matier Premier" value="Matier Premier"></el-option>
                                <el-option label="Consommables" value="Consommables"></el-option>
                                <el-option label="Marchandise" value="Marchandise"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item class="col-lg-3" label="Sous-Categorie">
                            <el-select v-model="ArticlesForm.sousCategori_article" @change="changeSousCategorie()" placeholder="select Sous-Categorie">
                                <el-option
                                    v-for="item in Souscategorie"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item class="col-lg-4" label="Designiation Fournisseur">
                            <el-input v-model="ArticlesForm.designiation"></el-input>
                        </el-form-item>
                        <el-form-item class="col-lg-4" label="Code Article Fournisseur">
                            <el-input v-model="ArticlesForm.Code_Article_four"></el-input>
                        </el-form-item>
                        <el-form-item class="col-lg-4" label="Nomenclature International">
                            <el-input v-model="ArticlesForm.Nomenclature"></el-input>
                        </el-form-item>
                        <el-form-item class="col-lg-4" label="Designiation Interne">
                            <el-input v-model="ArticlesForm.designiationIntern"></el-input>
                        </el-form-item>
                        <el-form-item class="col-lg-4" label="Code Article Interne">
                            <el-input v-model="ArticlesForm.Code_ArticleIntern"></el-input>
                        </el-form-item>
                        <el-form-item class="col-lg-4" label="Nomenclature Marocaine">
                            <el-input v-model="ArticlesForm.NomenclatureIntern"></el-input>
                        </el-form-item>
                        <el-form-item class="col-lg-4" label="Unite Mesure">
                            <el-input v-model="ArticlesForm.unite_mesure"></el-input>
                        </el-form-item>
                        
                        <el-form-item class="col-lg-2" label="Regime économique">
                            <el-select v-model="ArticlesForm.regime_economique" @change="changeRegime()" placeholder="Select Régime économique">
                                <el-option label="10" value="10"></el-option>
                                <el-option label="22" value="22"></el-option>
                                <el-option label="37" value="37"></el-option>
                                <el-option label="81" value="81"></el-option>
                                <el-option label="Autre" value="Autre"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item class="col-lg-4" label="Nome Regime économique">
                            <el-input v-model="ArticlesForm.regime_economique_name"></el-input>
                        </el-form-item>
                
                        <el-form-item class="col-lg-3" label="Ref">
                            <el-input v-model="ArticlesForm.referance"></el-input>
                        </el-form-item>
            
                        <el-form-item class="col-lg-3" label="Prix Unitaire">
                            <el-input v-model="ArticlesForm.prix_unit"></el-input>
                        </el-form-item>
                        
                    </div>
              </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="DialogArticleVisible = false">Cancel</el-button>
                <el-button type="primary" @click="ValiderModificationArticle()">Confirm</el-button>
            </span>
        </el-dialog>
        <!--  -->
        
    </div>
  </section>
</template>

<script>
    import VueTagsInput from '@johmun/vue-tags-input';

    export default {
      components: {
          VueTagsInput,
        },
        data () {
            return {
                DialogVisible: false,
                DialogArticleVisible: false,
                fournisseurs: [],
                search: '',
                pagesize: 10,
                currpage: 1,
                editmode: false,
                active: 0,
                sousCategorieSelected:'',
                indexRow:'',

                ArticlesForm : new Form({categori_article:'',sousCategori_article:'',designiation:'',Nomenclature:'',
                                        Code_Article_four:'',designiationIntern:'',NomenclatureIntern:'',
                                        Code_ArticleIntern:'',unite_mesure:'',prix_unit:'',prix_total:'',dimension:'',regime_economique:'',
                                        regime_economique_name:'',referance:'',prix_unitaire:'',
                                        }),

                form: new Form({
                    id:'',
                    typeFournisseur:'',
                    name:'',
                    adressSocial:'',
                    villeSocial:'',
                    paysSocial:'',
                    FormJuridique:'',
                    DomainActivity:'',
                    Devise:'',
                    ice:'',
                    NumIF:'',
                    NumPatente:'',
                    natureMSES:'',
                    regime:'',
// to add after
                    ficheTechnique:'',
                    articlesCommercePDF:'',
                    Tribunal:'',
                    NumRC:'',
// 
                    GerantName:'',
                    Gerantemail:'',
                    Geranttele:'',
                    Gerantfix:'',
                    RAchatName:'',
                    RAchatemail:'',
                    RAchattele:'',
                    RAchatfix:'',
                    RCommercialName:'',
                    RCommercialemail:'',
                    RCommercialtele:'',
                    RCommercialfix:'',
                    RFinanceName:'',
                    RFinanceemail:'',
                    RFinancetele:'',
                    RFinancefix:'',
                    NatureMarchandise:'',
                    RegimeImposition:'',
                    ResponsablATName:'',
                    ResponsablATemail:'',
                    ResponsablATtele:'',
                    ResponsablATfix:'',
                    ResponsablATName2:'',
                    ResponsablATemail2:'',
                    ResponsablATtele2:'',
                    ResponsablATfix2:'',
                    succursal:'',
                    // 
                    NomSucursal:'',AdressSucursal:'',TeleSucursal:'',
                    GerantNameSucursal:'',GerantSemail:'',GerantStele:'',GerantSfix:'',
                    RAchatSucursalName:'',RAchatSucursalemail:'',RAchatSucursaltele:'',
                    RAchatSucursalfix:'',RCommercialSucursalName:'',RCommercialSucursalemail:'',
                    RCommercialSucursaltele:'',RCommercialSucursalfix:'',RFinanceSucursalName:'',
                    RFinanceSucursalemail:'',RFinanceSucursaltele:'',RFinanceSucursalfix:'',
                    BanqueNameSucursal:'',AdresseBanqueSucursal:'',villeBanqueSucursal:'',SwiftSucursal:'',
                    // 
                    BanqueName:'',
                    AdresseBanque:'',
                    villeBanque:'',
                    Swift:'',
                    ModaliteP:'',
                    ModalitePaiment: '',
                    // articles
                    ArticlesVendueF:[{categori_article:'',sousCategori_article:'',designiation:'',Nomenclature:'',
                                        Code_Article_four:'',designiationIntern:'',NomenclatureIntern:'',
                                        Code_ArticleIntern:'',unite_mesure:'',prix_unit:'',prix_total:'',dimension:'',regime_economique:'',
                                        regime_economique_name:'',referance:'',prix_unitaire:'',
                                        
                                        }]
                }),

                Souscategorie:'',
                consommables: [{ value: 'Ancres', label: 'Ancres'},{ value: 'Assytat',label: 'Assytat'},{ value: 'White Spirit',label: 'White Spirit'},{ value: 'Huile 46',label: 'Huile 46'}],
                matier_premier: [{ value: 'Papier'},{ value: 'P.P'},{ value: 'Fruit tag'},{ value: 'Mandrin en carton'}],
                marchandise : [ { value: 'Cornière en carton'},{ value: 'Filets tubulaires'},{ value: 'Filets de palitisation'},
                                { value: 'Filets cellulosiques'},{ value: 'Feuillards en plastique'},{ value: 'Feuillards métaliques'},
                                { value: 'Barquettes'},{ value: 'Complexes'},{ value: 'Alvéoles'},{ value: 'Agraffes'},
                                { value: 'Papillotes'}],

                file:[],
                file2:'',

            }
        },
        methods: {

            // calcules

            CalaculQtitML(){
                this.ArticlesForm.quantite_ml_total = this.ArticlesForm.NombreBobine * this.ArticlesForm.quantite_ml_bobine;
                this.ArticlesForm.quantite_m2_total = this.ArticlesForm.NombreBobine * this.ArticlesForm.quantite_ml_bobine * this.ArticlesForm.laize_mm;
            },

            changePots(){
                this.ArticlesForm.qtit_pots_total = this.ArticlesForm.qtit_pots * this.ArticlesForm.emballage
            },

            CalculConsomablePrix(){
                this.ArticlesForm.prix_total =  this.ArticlesForm.qtit_pots_total * this.ArticlesForm.prix_unit
            },

            // end calcules
// Articles front crud
            deleteRow(index, ArticlesVendueF) {
                var idx = this.form.ArticlesVendueF.indexOf(ArticlesVendueF);
                
                if (idx > -1) {
                    this.form.ArticlesVendueF.splice(idx, 1);
                }
            },
            addNewRow() {
                this.form.ArticlesVendueF.push(
                {
                    categori_article:this.ArticlesForm.categori_article,
                    sousCategori_article:this.ArticlesForm.sousCategori_article,
                    designiation:this.ArticlesForm.designiation,
                    Nomenclature:this.ArticlesForm.Nomenclature,
                    Code_Article_four:this.ArticlesForm.Code_Article_four,
                    designiationIntern:this.ArticlesForm.designiationIntern,
                    NomenclatureIntern:this.ArticlesForm.NomenclatureIntern,
                    Code_ArticleIntern:this.ArticlesForm.Code_ArticleIntern,
                    unite_mesure:this.ArticlesForm.unite_mesure,
                    prix_unit:this.ArticlesForm.prix_unit,
                    prix_total:this.ArticlesForm.prix_total,
                    dimension:this.ArticlesForm.dimension,
                    regime_economique:this.ArticlesForm.regime_economique,
                    regime_economique_name:this.ArticlesForm.regime_economique_name,
                    referance:this.ArticlesForm.referance,
                    prix_unitaire:this.ArticlesForm.prix_unitaire,
                    
                    }
                )

                // console.log(this.form.ArticlesVendueF);
                this.ArticlesForm.reset();
            },

            updateArticle(index){
                this.ArticlesForm.reset();
                this.indexRow = index;
                
                this.ArticlesForm.categori_article = this.form.ArticlesVendueF[index].categori_article,
                this.ArticlesForm.sousCategori_article = this.form.ArticlesVendueF[index].sousCategori_article,

                this.ArticlesForm.designiation = this.form.ArticlesVendueF[index].designiation,
                this.ArticlesForm.Nomenclature = this.form.ArticlesVendueF[index].Nomenclature,
                this.ArticlesForm.Code_Article_four = this.form.ArticlesVendueF[index].Code_Article_four,

                this.ArticlesForm.designiationIntern = this.form.ArticlesVendueF[index].designiationIntern,
                this.ArticlesForm.NomenclatureIntern = this.form.ArticlesVendueF[index].NomenclatureIntern,
                this.ArticlesForm.Code_ArticleIntern = this.form.ArticlesVendueF[index].Code_ArticleIntern,
                
                this.ArticlesForm.unite_mesure = this.form.ArticlesVendueF[index].unite_mesure,
                this.ArticlesForm.prix_unit = this.form.ArticlesVendueF[index].prix_unit,
                this.ArticlesForm.prix_total = this.form.ArticlesVendueF[index].prix_total,
                this.ArticlesForm.dimension = this.form.ArticlesVendueF[index].dimension,
                this.ArticlesForm.regime_economique = this.form.ArticlesVendueF[index].regime_economique,
                this.ArticlesForm.regime_economique_name = this.form.ArticlesVendueF[index].regime_economique_name,
                this.ArticlesForm.referance = this.form.ArticlesVendueF[index].referance,
                this.ArticlesForm.prix_unitaire = this.form.ArticlesVendueF[index].prix_unitaire
                     
                this.DialogArticleVisible = true;
          },

          ValiderModificationArticle(){
              
            this.form.ArticlesVendueF[this.indexRow].categori_article = this.ArticlesForm.categori_article,
            this.form.ArticlesVendueF[this.indexRow].sousCategori_article = this.ArticlesForm.sousCategori_article,

            this.form.ArticlesVendueF[this.indexRow].designiation = this.ArticlesForm.designiation,
            this.form.ArticlesVendueF[this.indexRow].Nomenclature = this.ArticlesForm.Nomenclature,
            this.form.ArticlesVendueF[this.indexRow].Code_Article_four = this.ArticlesForm.Code_Article_four,

            this.form.ArticlesVendueF[this.indexRow].designiationIntern = this.ArticlesForm.designiationIntern,
            this.form.ArticlesVendueF[this.indexRow].NomenclatureIntern = this.ArticlesForm.NomenclatureIntern,
            this.form.ArticlesVendueF[this.indexRow].Code_ArticleIntern = this.ArticlesForm.Code_ArticleIntern,

            this.form.ArticlesVendueF[this.indexRow].unite_mesure = this.ArticlesForm.unite_mesure,
            this.form.ArticlesVendueF[this.indexRow].prix_unit = this.ArticlesForm.prix_unit,
            this.form.ArticlesVendueF[this.indexRow].prix_total = this.ArticlesForm.prix_total,
            this.form.ArticlesVendueF[this.indexRow].dimension = this.ArticlesForm.dimension,
            this.form.ArticlesVendueF[this.indexRow].regime_economique = this.ArticlesForm.regime_economique,
            this.form.ArticlesVendueF[this.indexRow].regime_economique_name = this.ArticlesForm.regime_economique_name,
            this.form.ArticlesVendueF[this.indexRow].referance = this.ArticlesForm.referance,
            this.form.ArticlesVendueF[this.indexRow].prix_unitaire = this.ArticlesForm.prix_unitaire
            
            this.DialogArticleVisible = false;
            this.ArticlesForm.reset();
          },
// End 

            next() {
                this.active++;
            },

            prev() {
                this.active--;
            },

            changeRegime(){
                switch (this.ArticlesForm.regime_economique) {
                        case '10':
                            this.ArticlesForm.regime_economique_name = 'Mise a la consomation';
                            console.log(this.ArticlesForm.regimeEconomique+' : '+this.ArticlesForm.regime_economique_name)
                            break;
                        case '22':
                            this.ArticlesForm.regime_economique_name = 'Transformation en AT';
                            break;
                        case '37':
                            this.ArticlesForm.regime_economique_name = 'Importation en AT stockée a l\'entrepôt';
                            break;
                        case '81':
                            this.ArticlesForm.regime_economique_name = 'achat local en AT stockée a l\'entrepôt';
                            break;
                        case 'Autre':
                            this.ArticlesForm.regime_economique_name = '';
                            break;
                        }
                
            },

            changeSelectCategorie () {

                    switch (this.ArticlesForm.categori_article) {
                        case 'Consommables':
                            this.Souscategorie = this.consommables;
                            console.log('Consommables');
                            break;
                        case 'Matier Premier':
                            this.Souscategorie = this.matier_premier;
                           console.log('Matier Premier');
                            break;
                        case 'Marchandise':
                            this.Souscategorie = this.marchandise;
                           
                            break;
                        }

                        
                },
            changeSousCategorie () {
                    if(this.ArticlesForm.sousCategori_article == 'Papier' || this.ArticlesForm.sousCategori_article == 'P.P' || this.ArticlesForm.sousCategori_article == 'Fruit tag')
                    {
                        this.sousCategorieSelected = 'mp1'
                    }
                    else if(this.ArticlesForm.sousCategori_article == 'Mandrin en carton')
                    {
                        this.sousCategorieSelected = 'mp2'
                    }
                    else if(this.ArticlesForm.sousCategori_article == 'Ancres' || this.ArticlesForm.sousCategori_article == 'Assytat' || this.ArticlesForm.sousCategori_article == 'White Spirit' || this.ArticlesForm.sousCategori_article == 'Huile 46')
                    {
                        this.sousCategorieSelected = 'consomables'
                    }
                    else if(this.ArticlesForm.sousCategori_article == 'Papillotes')
                    {
                        this.sousCategorieSelected = 'march1'
                    }
                    else if(this.ArticlesForm.sousCategori_article == 'Cornière en carton')
                    {
                        this.sousCategorieSelected = 'march2'
                        // console.log(this.ArticlesForm.sousCategori_article)
                    }
                    else if(this.ArticlesForm.sousCategori_article == 'Filets tubulaires' || this.ArticlesForm.sousCategori_article == 'Filets de palitisation' || this.ArticlesForm.sousCategori_article == 'Filets cellulosiques')
                    {
                        this.sousCategorieSelected = 'march3'
                    }
                    else if(this.ArticlesForm.sousCategori_article == 'Feuillards en plastique' || this.ArticlesForm.sousCategori_article == 'Feuillards métaliques')
                    {
                        this.sousCategorieSelected = 'march4'
                    }
                    else
                    {
                        this.sousCategorieSelected = 'march5'
                    }
                    
                },

            regimeChange(){
                if(this.formArticle.regimeEconomique == '10'){
                    this.formArticle.regimeName = 'Mise a la consomation'
                }else if(this.formArticle.regimeEconomique == '22'){
                    this.formArticle.regimeName = 'Transformation en AT'
                }else if(this.formArticle.regimeEconomique == '37'){
                    this.formArticle.regimeName = 'Achat international en AT depôt privé'
                }else if(this.formArticle.regimeEconomique == '81'){
                    this.formArticle.regimeName = 'local AT depôt privé'
                }
            },

            // End

            onChange(file, fileList) {
                this.file = fileList.slice(-1);;
                console.log(this.file);
            },

            

            submitForm(e) {
            this.$Progress.start();
            e.preventDefault();
            let currentObj = this;
            const config = {
                headers: {
                    "content-type": "multipart/form-data",
                    "X-CSRF-TOKEN": document.querySelector(
                        'meta[name="csrf-token"]'
                    ).content
                }
            };
            // form data
            let formData = new FormData();
            formData.append("file", this.file[0].raw);
            formData.append("typeFournisseur", this.form.typeFournisseur);
            formData.append("succursal", this.form.succursal);
            formData.append("ModaliteP", this.form.ModaliteP);
            // send upload request
            
            axios.post("api/upload", formData, config)
                
                .then(response => {
                    // success
                    // this.DialogVisibleF = false;
                    Toast.fire({
                        icon: "success",
                        title: response.data.message
                    });
                    this.$Progress.finish();
                    
                    // this.loadFiles();
                }
                )
                .catch(function(error) {
                    currentObj.output = error;
                });
                
        },

            newCompte(){
                this.form.reset();
                this.active = 0;
                this.editmode = false;
                this.DialogVisible = true;
            },

            sucursal(){
                if(this.form.succursal == 'Non'){
                    this.form.NomSucursal=''
                    this.form.AdressSucursal=''
                    this.form.TeleSucursal=''
                    this.form.GerantSucursal=''
                    this.form.RAchatSucursalName=''
                    this.form.RAchatSucursalemail=''
                    this.form.RAchatSucursaltele=''
                    this.form.RAchatSucursalfix=''
                    this.form.RCommercialSucursalName=''
                    this.form.RCommercialSucursalemail=''
                    this.form.RCommercialSucursaltele=''
                    this.form.RCommercialSucursalfix=''
                    this.form.RFinanceSucursalName=''
                    this.form.RFinanceSucursalemail=''
                    this.form.RFinanceSucursaltele=''
                    this.form.RFinanceSucursalfix=''
                }else{

                }
            },
            // start CRUD

            loadData(){
                // if(this.$gate.isAdmin()){
                axios.get("api/CompteFournisseurs").then(({ data }) => (this.fournisseurs = data.data));
                // }
            },

            createData(){
              this.$Progress.start();

              this.form.post('api/CompteFournisseurs')
              .then((data)=>{
                if(data.data.success){
                  
                  this.DialogVisible = false;

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.$Progress.finish();
                  this.loadData();

                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          },

          

          updateCompte(compte){
                this.editmode = true;
                this.form.reset();
                this.active = 0;
                this.DialogVisible = true;
                this.form.fill(compte);
                for (let i = 0; i < compte.get_sucursal.length; i++) {
                        this.form.NomSucursal=compte.get_sucursal[i].NomSucursal
                        this.form.AdressSucursal=compte.get_sucursal[i].AdressSucursal
                        this.form.TeleSucursal=compte.get_sucursal[i].TeleSucursal
                        this.form.GerantNameSucursal=compte.get_sucursal[i].GerantNameSucursal
                        this.form.GerantSemail=compte.get_sucursal[i].GerantSemail
                        this.form.GerantStele=compte.get_sucursal[i].GerantStele
                        this.form.GerantSfix=compte.get_sucursal[i].GerantSfix
                        this.form.RAchatSucursalName=compte.get_sucursal[i].RAchatSucursalName
                        this.form.RAchatSucursalemail=compte.get_sucursal[i].RAchatSucursalemail
                        this.form.RAchatSucursaltele=compte.get_sucursal[i].RAchatSucursaltele
                        this.form.RAchatSucursalfix=compte.get_sucursal[i].RAchatSucursalfix
                        this.form.RCommercialSucursalName=compte.get_sucursal[i].RCommercialSucursalName
                        this.form.RCommercialSucursalemail=compte.get_sucursal[i].RCommercialSucursalemail
                        this.form.RCommercialSucursaltele=compte.get_sucursal[i].RCommercialSucursaltele
                        this.form.RCommercialSucursalfix=compte.get_sucursal[i].RCommercialSucursalfix
                        this.form.RFinanceSucursalName=compte.get_sucursal[i].RFinanceSucursalName
                        this.form.RFinanceSucursalemail=compte.get_sucursal[i].RFinanceSucursalemail
                        this.form.RFinanceSucursaltele=compte.get_sucursal[i].RFinanceSucursaltele
                        this.form.RFinanceSucursalfix=compte.get_sucursal[i].RFinanceSucursalfix
                        this.form.BanqueNameSucursal=compte.get_sucursal[i].BanqueNameSucursal
                        this.form.AdresseBanqueSucursal=compte.get_sucursal[i].AdresseBanqueSucursal
                        this.form.villeBanqueSucursal=compte.get_sucursal[i].villeBanqueSucursal
                        this.form.SwiftSucursal=compte.get_sucursal[i].SwiftSucursal
                }

                // console.log(compte.get_sucursal.NomSucursal);
                
                this.form.ArticlesVendueF = [];
                for (let i = 0; i < compte.get_articles.length; i++) {
                    this.form.ArticlesVendueF.push({
                        categori_article: compte.get_articles[i].categori_article,
                        sousCategori_article: compte.get_articles[i].sousCategori_article,
                        designiation: compte.get_articles[i].designiation,
                        Nomenclature: compte.get_articles[i].Nomenclature,
                        Code_Article_four: compte.get_articles[i].Code_Article_four,
                        unite_mesure: compte.get_articles[i].unite_mesure,
                        prix_unit: compte.get_articles[i].prix_unit,
                        prix_total: compte.get_articles[i].prix_total,
                        dimension: compte.get_articles[i].dimension,
                        regime_economique: compte.get_articles[i].regime_economique,
                        regime_economique_name: compte.get_articles[i].regime_economique_name,
                        referance: compte.get_articles[i].referance,
                        prix_unitaire: compte.get_articles[i].prix_unitaire,
                        NombreBobine: compte.get_articles[i].NombreBobine,
                        quantite_ml_bobine: compte.get_articles[i].quantite_ml_bobine,
                        laize_mm: compte.get_articles[i].laize_mm,
                        quantite_ml_total: compte.get_articles[i].quantite_ml_total,
                        quantite_m2_total: compte.get_articles[i].quantite_m2_total,
                        diametre: compte.get_articles[i].diametre,
                        nombre_mandrin_kg: compte.get_articles[i].nombre_mandrin_kg,
                        emballage: compte.get_articles[i].emballage,
                        qtit_pots: compte.get_articles[i].qtit_pots,
                        qtit_pots_total: compte.get_articles[i].qtit_pots_total,
                        laize_cm: compte.get_articles[i].laize_cm,
                    })
                }
            },

          updateData(){
              this.$Progress.start();
              this.form.put('api/CompteFournisseurs/'+this.form.id)
              .then((response) => {
                  // success
                  this.DialogVisible = false;

                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });
                  this.$Progress.finish();
                      //  Fire.$emit('AfterCreate');

                  this.loadData();
              })
              .catch(() => {
                  this.$Progress.fail();
              });

          },
          deletData(id){
              Swal.fire({
                  title: 'Attention !',
                  text: "vous voulez vraiment supprimer ce Compte ??",
                  showCancelButton: true,
                  confirmButtonColor: '#67c23a',
                  cancelButtonColor: '#f56c6c',
                  confirmButtonText: 'Oui, supprimez-le ! '
                  }).then((result) => {

                      // Send request to the server
                        if (result.value) {
                              this.form.delete('api/CompteFournisseurs/'+id).then(()=>{
                                      Swal.fire(
                                      'Supprimé !',
                                      'le Compte Fournisseur a été supprimé.',
                                      'success'
                                      );
                                  this.loadData();
                              }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                        }
                  })
          },

            // End CRUD

        handleClose(done) {
                this.$confirm('Voulez-vous vraiment fermer cette boîte de dialogue ?')
                .then(_ => {
                    done();
                })
                .catch(_ => {});
            }, 

        handleCurrentChange(cpage) {
                this.currpage = cpage;
            },

        handleSizeChange(psize) {
                this.pagesize = psize;
            },


        },
        mounted() {

        },
        created() {
            this.$Progress.start();

            this.loadData();

            this.$Progress.finish();
        },
        filters: {
           
        },
        computed: {
          
        },
    }
</script>
