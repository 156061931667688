<template>
    <section class="content">
<!-- {{ Reservations }} -->
      <div class="container-fluid">
        <el-button type="primary" @click="newData()" size="small" icon="el-icon-plus" >Add New Plateforme</el-button>

        <!-- Start Table -->
        <el-card class="mb-3 mt-3">
          <div class="row">
          <div class="col-6">
              <h4 class="ml-3">Plateformes</h4>
          </div>
            <div class="col-6">
                <el-input v-model="search" @change="filterETC" placeholder="Filter : "> 
                    <el-button slot="append" icon="el-icon-search"></el-button>
                </el-input>
            </div>
            <div class="col-12 mt-3">
              <el-table
                    :data="Plateformes.data" border >
                    
                    <el-table-column label="Title">
                        <template slot-scope="scope">
                            <strong>{{ scope.row.title }}</strong>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="Commission">
                        <template slot-scope="scope">
                            <strong>{{ scope.row.commission }} %</strong>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="Currency">
                        <template slot-scope="scope">
                            <strong>{{ scope.row.currency }}</strong>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="Etat">
                        <template slot-scope="scope">
                            <div v-if="scope.row.etat == 'Active'">
                                <el-tag  type="success" effect="dark" ><strong>{{ scope.row.etat }}</strong></el-tag>
                            </div>
                            <div v-if="scope.row.etat == 'eSIM'">
                                <el-tag  type="warning" effect="dark" ><strong>{{ scope.row.etat }}</strong></el-tag>
                            </div>
                            <div v-if="scope.row.etat == 'Desactive'">
                                <el-tag  type="danger" effect="dark" ><strong>{{ scope.row.etat }}</strong></el-tag>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column width="100" align="center">
                        <template slot-scope="{row}">
                                <a href="#" @click="updateReservation(row)" data-toggle="tooltip" data-placement="top" title="Modifier">
                                        <i class="fa fa-edit blue mr-2"></i>
                                </a>
                                <!-- <el-divider direction="vertical"></el-divider>
                                <a href="#" @click="deletData(row.id)" data-toggle="tooltip" data-placement="top" title="Supprimer">
                                    <i class="fa fa-trash red"></i>
                                </a> -->
                        </template>
                    </el-table-column>
              </el-table>
               
               <pagination class="mt-4" :data="Plateformes" @pagination-change-page="loadData"></pagination>
            </div>
            
          </div>
          </el-card>
        <!-- End Table -->
          
          <!-- dialog -->
          <el-dialog
            :title="editmode ? 'Update Plateforme':'New Plateforme'"
            :visible.sync="DialogVisible_global"
            width="40%"
            :before-close="handleClose">
            <!-- Body dialog -->
                <el-form :model="form_global" ref="form_global" class="demo-ruleForm">
                    <fieldset class="row col-12 border p-2 mt-2">
                        <legend  class="w-auto badge"><h5>Plateforme Infos</h5></legend>
                            <el-form-item class="col-lg-6" label="Title" prop="title" 
                            :rules="[
                                { required: true, message: 'Title is required' }
                            ]">
                                <el-input v-model="form_global.title" autocomplete="off"></el-input>
                            </el-form-item>
                            <el-form-item class="col-lg-6" label="Commission" prop="commission" 
                            :rules="[
                                { required: true, message: 'Commission is required' }
                            ]">
                                <el-input type="text" v-model="form_global.commission" autocomplete="off"></el-input>
                            </el-form-item>
                            <el-form-item class="col-lg-6" label="Currency" prop="currency" 
                            :rules="[
                                { required: true, message: 'Currency is required' }
                            ]">
                                <el-select v-model="form_global.currency" style="width: 100%" placeholder="Select a Currency">
                                        <el-option label="MAD" value="MAD"></el-option>
                                        <el-option label="$" value="$"></el-option>
                                        <el-option label="€" value="€"></el-option>
                                        <el-option label="£" value="£"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item class="col-lg-6" label="Etat" prop="etat" 
                            :rules="[
                                { required: true, message: 'Etat is required' }
                            ]">
                                <el-select v-model="form_global.etat" style="width: 100%" placeholder="Select a Etat">
                                        <el-option label="Active" value="Active"></el-option>
                                        <el-option label="eSIM" value="eSIM"></el-option>
                                        <el-option label="Desactive" value="Desactive"></el-option>
                                </el-select>
                            </el-form-item>
                    </fieldset>
                </el-form>
                <!-- ENd body Dialog -->
                <span slot="footer" class="dialog-footer">
                    <el-button @click="DialogVisible_global = false">Annuler</el-button>
                    <el-button type="primary" v-show="!editmode" @click="submitForm('form_global')">Valider</el-button>
                    <el-button type="primary" v-show="editmode" @click="updateData()">Valider</el-button>
                </span>
          </el-dialog>

          <!-- ENd dialog -->
  
      </div>
     
    </section>
    <!--  -->
  </template>
  
  
  <script>
      import VueTagsInput from '@johmun/vue-tags-input';
      import moment from 'moment'
  
      export default {
        components: {
            VueTagsInput,
          },
          data () {
              return {
                  DialogVisible_global: false,
                  active: 0,
                  Plateformes: [],
                  search: '',
                  pagesize: 10,
                  currpage: 1,
                  editmode: false,
                  products : [],

                  numberValidateForm: {
                    age: ''
                },
                
                  form_global: new Form({
                    id:'',
                    title:'',
                    commission:'',
                    currency:'',
                    etat:'',
                  }),

                  form: new Form({
                      id : '',
                      category : '',
                      name: '',
                      description: '',
                      tags:  [],
                      photo: '',
                      category_id: '',
                      price: '',
                      photoUrl: '',
                  }),
                  categories: [],
                
                  tag:  '',
                  autocompleteItems: [],
              }
          },
          methods: {            
            filterETC(){
                if(this.search != ''){
                    this.$Progress.start();
                    axios.get('api/filterPlateformes/' + this.search).then(({ data }) => (this.Plateformes = data.data));
                    this.$Progress.finish();
                }else{
                    this.loadData();
                }
            },

            // CRUD
            newData(){
                this.form_global.reset();
                this.editmode = false;
                // this.disableDevisPV = false;
                this.DialogVisible_global = true;
                this.active=0;
            },

            createData(){
            this.$Progress.start();

              this.form_global.post('api/plateforme')
              .then((data)=>{
                if(data.data.success){
                 this.DialogVisible_global = false

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.$Progress.finish();
                  this.loadData();
                //   this.loadDataPrepared();

                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          },

          submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.createData();
                } else {
                    console.log('error submit!!');
                    return false;
                }
                });
            },

            loadData(page = 1){
                this.$Progress.start();
                axios.get('api/plateforme?page=' + page).then(({ data }) => (this.Plateformes = data.data));
                this.$Progress.finish();
            },

            updateReservation(reservation){
                this.editmode = true;
                this.form_global.reset();
                this.DialogVisible_global = true;
                this.form_global.fill(reservation);
            },

            updateData(){
              this.$Progress.start();
              this.form_global.put('api/plateforme/'+this.form_global.id)
              .then((response) => {
                  // success
                  this.DialogVisible_global = false
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });
                  this.$Progress.finish();

                  this.loadData();
              })
              .catch(() => {
                  this.$Progress.fail();
              });

          },

          deletData(id){
              Swal.fire({
                  title: 'Attention !',
                  text: "You really want to delete this Plateforme ??",
                  showCancelButton: true,
                  confirmButtonColor: '#67c23a',
                  cancelButtonColor: '#f56c6c',
                  confirmButtonText: 'Yes, Delete-it ! '
                  }).then((result) => {

                      // Send request to the server
                        if (result.value) {
                              this.form_global.delete('api/plateforme/'+id).then(()=>{
                                      Swal.fire(
                                      'Deleted !',
                                      'Plateforme Deleted Successfully',
                                      'success'
                                      );
                                  // Fire.$emit('AfterCreate');
                                  this.loadData();
                              }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                        }
                  })
          },
        //   End CRUD

            handleClose(done) {
                this.$confirm('Are you sure you want to close this dialog ?')
                .then(_ => {
                    done();
                })
                .catch(_ => {});
            },

            handleCurrentChange2(val) {
                this.currentRow = val;
            },

            // END
  
           
            loadProducts(){
  
              // if(this.$gate.isAdmin()){
                axios.get("api/product").then(({ data }) => (this.products = data.data));
              // }
            },
            loadCategories(){
                axios.get("/api/category/list").then(({ data }) => (this.categories = data.data));
            },
            loadTags(){
                axios.get("/api/tag/list").then(response => {
                    this.autocompleteItems = response.data.data.map(a => {
                        return { text: a.name, id: a.id };
                    });
                }).catch(() => console.warn('Oh. Something went wrong'));
            },
            editModal(product){
                this.editmode = true;
                this.form.reset();
                $('#addNew').modal('show');
                this.form.fill(product);
            },
            newModal(){
                this.editmode = false;
                this.form.reset();
                $('#addNew').modal('show');
            },
            createProduct(){
                this.$Progress.start();
  
                this.form.post('api/product')
                .then((data)=>{
                  if(data.data.success){
                    $('#addNew').modal('hide');
  
                    Toast.fire({
                          icon: 'success',
                          title: data.data.message
                      });
                    this.$Progress.finish();
                    this.loadProducts();
  
                  } else {
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
  
                    this.$Progress.failed();
                  }
                })
                .catch(()=>{
  
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
                })
            },
            updateProduct(){
                this.$Progress.start();
                this.form.put('api/product/'+this.form.id)
                .then((response) => {
                    // success
                    $('#addNew').modal('hide');
                    Toast.fire({
                      icon: 'success',
                      title: response.data.message
                    });
                    this.$Progress.finish();
                        //  Fire.$emit('AfterCreate');
  
                    this.loadProducts();
                })
                .catch(() => {
                    this.$Progress.fail();
                });
  
            },
            deletModal(id){
                Swal.fire({
                    title: 'Attention !',
                    text: "vous voulez vraiment supprimer ce devis",
                    showCancelButton: true,
                    confirmButtonColor: '#67c23a',
                    cancelButtonColor: '#f56c6c',
                    confirmButtonText: 'Oui, supprimez-le ! '
                    }).then((result) => {
  
                        // Send request to the server
                          if (result.value) {
                                this.form.delete('api/product/'+id).then(()=>{
                                        Swal.fire(
                                        'Supprimé !',
                                        'Votre fichier a été supprimé.',
                                        'success'
                                        );
                                    // Fire.$emit('AfterCreate');
                                    this.loadProducts();
                                }).catch((data)=> {
                                    Swal.fire("Failed!", data.message, "warning");
                                });
                          }
                    })
            },
          
           handleCurrentChange(cpage) {
                  this.currpage = cpage;
              },
          handleSizeChange(psize) {
                  this.pagesize = psize;
              },
  
  
          },
          mounted() {
              
          },
          created() {
              this.$Progress.start();
  
              this.loadData();
            //   this.loadCategories();
            //   this.loadTags();
  
              this.$Progress.finish();
          },
          filters: {
            OfferDates(value){
                return moment(String(value)).format('MM-DD-YY')
            },
              truncate: function (text, length, suffix) {
                  return text.substring(0, length) + suffix;
              },
          },
          computed: {
            filteredItems() {
              return this.autocompleteItems.filter(i => {
                return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
              });
            },
          },
      }
  </script>
  