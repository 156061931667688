<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">
            <!-- Tabs -->
             <div class="col-12 mb-5">
                    <el-tabs type="border-card">
                        <!-- <el-tab-pane>
                            <span slot="label"><i class="el-icon-more-outline"></i> Activities </span>
                           
                            <div class="row">
                                <div class="col-6">
                                </div>
                                <div class="col-6">
                                    <el-input v-model="search" size="mini" placeholder="Filter : "> 
                                        <el-button slot="append" icon="el-icon-search"></el-button>
                                    </el-input>
                                </div>
                                <div class="col-12 mt-3">
                                    <el-table 
                                        :data="activitys.filter(data => !search || data.userName.toLowerCase().includes(search.toLowerCase()) ).slice((currpage - 1) * pagesize, currpage * pagesize)" style="width: 100%"  
                                        border >
                                    <el-table-column label=" User ">
                                        <template slot-scope="scope">
                                            <span>{{ scope.row.userName }}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label=" Action ">
                                        <template slot-scope="scope">
                                            <div v-if="scope.row.typeAction == 'Déconnecter'">
                                                <el-tag  type="danger" effect="" ><strong>{{ scope.row.typeAction }}</strong></el-tag>
                                            </div>
                                            <div v-else>
                                                <el-tag  type="success" effect="" ><strong>{{ scope.row.typeAction }}</strong></el-tag>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Date">
                                        <template slot-scope="scope">
                                            <span>{{ scope.row.created_at }}</span>
                                        </template>
                                    </el-table-column>
                                    </el-table>
                                    
                                    <el-pagination background 
                                            class="mt-5"
                                            layout="prev, pager, next, sizes, total, jumper"
                                            :page-sizes = "[5, 10, 15, 20]"
                                            :page-size = "pagesize"
                                            :total="activitys.length"
                                            @current-change = "handleCurrentChange" 
                                            @size-change = "handleSizeChange" 
                                            >
                                    </el-pagination>
                                </div>
                            </div>

                        </el-tab-pane> -->
                        <el-tab-pane v-if="$gate.isAdmin()">
                            <span slot="label"><i class="el-icon-user"></i> Utilisateurs</span>
                            <div class="row">
                                <div class="col-6">
                                    <el-button type="primary" @click="newModal()" size="small" icon="el-icon-plus" >Ajouter un nouvel utilisateur</el-button>
                                </div>
                                <div class="col-6">
                                    <el-input v-model="search2" @change="filterUsers" size="mini" placeholder="Filter : "> 
                                        <el-button slot="append" icon="el-icon-search"></el-button>
                                    </el-input>
                                </div>
                                <div class="col-12 mt-3">
                                    <el-table 
                                        :data="users.data"  
                                        border >
                                    <el-table-column label=" Type ">
                                        <template slot-scope="scope">
                                            <span>{{ scope.row.type }}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Name">
                                        <template slot-scope="scope">
                                            <span>{{ scope.row.name }}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Email">
                                        <template slot-scope="scope">
                                            <span>{{ scope.row.email }}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Date Inscription">
                                        <template slot-scope="scope">
                                            <span>{{scope.row.created_at | Dates}}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Action">
                                        <template slot-scope="{row}">
                                            <a href="#" @click="updateUser(row)" data-toggle="tooltip" data-placement="top" title="Modifier">
                                                <i class="fa fa-edit blue"></i>
                                            </a>
                                            <el-divider direction="vertical"></el-divider>
                                            <a href="#" @click="deleteUser(row.id)" data-toggle="tooltip" data-placement="top" title="Supprimer">
                                                <i class="fa fa-trash red"></i>
                                            </a>
                                        </template>
                                    </el-table-column>
                                    </el-table>
                                    <!--  -->
                                    <pagination class="mt-4" :data="users" @pagination-change-page="loadUsers"></pagination>
                                </div>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
             </div> 
            <!-- End Tabs -->
        </div>


        <div v-if="!$gate.isAdmin()">
            <not-found></not-found>
        </div>

        <!-- Modal -->
        <el-dialog :title="editmode ? 'Modifier Utilisateur':'Ajouter Utilisateur'"
            :visible.sync="DialogVisible"
            width="30%"
            :before-close="handleClose">
        <!-- <el-dialog title="Shipping address" :visible.sync="dialogFormVisible"> -->
        <el-form :model="form">
            <el-form-item label="nom et prénom">
                <el-input v-model="form.name" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="E-mail">
                <el-input v-model="form.email" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="Mote de pass">
                <el-input v-model="form.password" show-password autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="Rôle d'utilisateur">
            <el-select v-model="form.type" placeholder="Please select a zone">
                <el-option label="Admin" value="admin"></el-option>
                <el-option label="user" value="user"></el-option>
                <el-option label="Commercial" value="commercial"></el-option>
                <el-option label="Conception" value="conception"></el-option>
                <el-option label="Magasinier" value="magasinier"></el-option>
                <el-option label="Production" value="production"></el-option>
            </el-select>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="DialogVisible = false">Cancel</el-button>
            <el-button type="primary" v-show="!editmode" @click="createUser()">Ajouter</el-button>
            <el-button type="primary" v-show="editmode" @click="updateData()">Valider</el-button>
        </span>
        </el-dialog>
        <!--  -->
        <!-- <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Create New User</h5>
                    <h5 class="modal-title" v-show="editmode">Update User's Info</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form @submit.prevent="editmode ? updateData() : createUser()">
                    <div class="modal-body">
                        <div class="form-group">
                            <label>Name</label>
                            <input v-model="form.name" type="text" name="name"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('name') }">
                            <has-error :form="form" field="name"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Email</label>
                            <input v-model="form.email" type="text" name="email"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('email') }">
                            <has-error :form="form" field="email"></has-error>
                        </div>
                    
                        <div class="form-group">
                            <label>Password</label>
                            <input v-model="form.password" type="password" name="password"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('password') }" autocomplete="false">
                            <has-error :form="form" field="password"></has-error>
                        </div>
                    
                        <div class="form-group">
                            <label>User Role</label>
                            <select name="type" v-model="form.type" id="type" class="form-control" :class="{ 'is-invalid': form.errors.has('type') }">
                                <option value="">Select User Role</option>
                                <option value="admin">Admin</option>
                                <option value="user">Standard User</option>
                            </select>
                            <has-error :form="form" field="type"></has-error>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>
                    </div>
                  </form>
                </div>
            </div>
        </div> -->
    </div>
  </section>
</template>

<script>
    import moment from 'moment'
    export default {
        data () {
            return {
                DialogVisible: false,
                search: '',
                search2: '',
                pagesize: 10,
                currpage: 1,

                editmode: false,
                users : [],
                activitys: [],
                form: new Form({
                    id : '',
                    type : '',
                    name: '',
                    email: '',
                    password: '',
                    email_verified_at: '',
                })
            }
        },
        methods: {

            getResults() {

                  this.$Progress.start();
                  
                  axios.get('api/user').then(({ data }) => (this.users = data.data));

                  this.$Progress.finish();
            },

            getActivitys() {

                  this.$Progress.start();
                  
                  axios.get('api/activityLogs').then(({ data }) => (this.activitys = data.data));

                  this.$Progress.finish();
            },
            updateUser(user){
                this.editmode = true;
                this.form.reset();
                this.DialogVisible = true;
                this.form.fill(user);
            },
            updateData(){
                this.$Progress.start();
                // console.log('Editing data');
                this.form.put('api/user/'+this.form.id)
                .then((response) => {
                    // success
                    // $('#addNew').modal('hide');
                    this.DialogVisible = false
                    Toast.fire({
                      icon: 'success',
                      title: response.data.message
                    });
                    this.$Progress.finish();
                        //  Fire.$emit('AfterCreate');

                    this.loadUsers();
                })
                .catch(() => {
                    this.$Progress.fail();
                });

            },
            
            newModal(){
                this.form.reset();
                this.editmode = false;
                this.DialogVisible = true;
            },
            
            deleteUser(id){
                Swal.fire({
                    title: 'Attention !',
                    text: "Vous voulez vraiment supprimer cet utilisateur",
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Oui, supprimez-le ! '
                    }).then((result) => {

                         if (result.value) {
                                this.form.delete('api/user/'+id).then(()=>{
                                        Swal.fire(
                                            'Supprimé !',
                                            'Votre Devis a été Bien supprimé.',
                                            'success'
                                        );
                                    this.loadUsers();
                                }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                         }
                    })
            },

            loadUsers(page = 1){
                this.$Progress.start();
                axios.get('api/user?page=' + page).then(({ data }) => (this.users = data.data));
                this.$Progress.finish();
            },

            filterUsers(page = 1){
                if(this.search2 != ''){
                    this.$Progress.start();
                    axios.get('api/filterUser/' + this.search2+'?page=' + page).then(({ data }) => (this.users = data.data));
                    this.$Progress.finish();
                }else{
                    this.loadUsers();
                }
            },
          
          createUser(){

              this.form.post('api/user')
              .then((response)=>{
                  this.DialogVisible = false;

                  Toast.fire({
                        icon: 'success',
                        title: response.data.message
                  });

                  this.$Progress.finish();
                  this.loadUsers();

              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          },

        handleCurrentChange(cpage) {
            this.currpage = cpage;
        },
        handleSizeChange(psize) {
            this.pagesize = psize;
        },

        handleClose(done) {
                this.$confirm('Voulez-vous vraiment fermer cette boîte de dialogue ?')
                .then(_ => {
                    done();
                })
                .catch(_ => {});
            },

        },
        mounted() {
            console.log('User Component mounted.')
        },
        created() {

            this.$Progress.start();
            this.loadUsers();
            this.getActivitys();
            this.$Progress.finish();
        },

        filters: {
            // 
            Dates(value){
                return moment(String(value)).format('MM/DD/YYYY HH:mm')
            }
        },
    }
</script>
