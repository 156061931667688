<template>
  <section class="content">
     
    <div class="container-fluid">
        <el-card class="mb-3 mt-3">
            <div slot="header" class="clearfix text-center">
                <h3> Liste Des Devis International </h3>
            </div>
        <div class="row">
        <div class="col-6">
            <el-button type="primary" @click="newDevis()" size="small" icon="el-icon-plus">Devis International</el-button>
        </div>
          <div class="col-6">
              <el-input v-model="search" size="mini" placeholder="Filter : "/>
          </div>
          <div class="col-12 mt-3">
            <el-table 
                :data="Devis.filter(data => !search || data.get_fournisseur.name.toLowerCase().includes(search.toLowerCase()) ).slice((currpage - 1) * pagesize, currpage * pagesize)" style="width: 100%"  
                border
                 >
                <el-table-column type="expand" fixed>
                    <template  slot-scope="scope" >
                    <el-table
                        :data="scope.row.get_articles"
                        style="width: 100%">
                            <el-table-column
                                prop="id_article"
                                label="Article"
                            >
                            </el-table-column>
                            <el-table-column
                                prop="prix_unitaire"
                                label="prix_unitaire"
                            >
                            </el-table-column>
                            <el-table-column
                                prop="NombreBobine"
                                label="NombreBobine"
                            >
                            </el-table-column>
                            <el-table-column
                                prop="quantite_ml_bobine"
                                label="quantite_ml_bobine">
                            </el-table-column>
                            <el-table-column
                                prop="laize_mm"
                                label="laize_mm">
                            </el-table-column>
                        </el-table>
                    </template>
                </el-table-column>
            <el-table-column label="Fournissuer" >
                <template slot-scope="scope">
                    <span>{{ scope.row.get_fournisseur.name }}</span>
                </template>
            </el-table-column>
            <el-table-column label="devis">
                <template slot-scope="scope">
                    <span>{{ scope.row.devise }}</span>
                </template>
            </el-table-column>
            <el-table-column label="Pays Provonance">
                <template slot-scope="scope">
                    <span>{{ scope.row.pays_provonance }}</span>
                </template>
            </el-table-column>
            <!-- <el-table-column label="Date Chargement">
                <template slot-scope="scope">
                    <span>{{ scope.row.date_chargement }}</span>
                </template>
            </el-table-column> -->
            <el-table-column label="incoterm">
                <template slot-scope="scope">
                    <span>{{ scope.row.incoterm }}</span>
                </template>
            </el-table-column>
            <el-table-column label="montant">
                <template slot-scope="scope">
                    <span>{{ scope.row.montant }}</span>
                </template>
            </el-table-column>
            <!-- <el-table-column label="N° D'enregistrement">
                <template slot-scope="scope">
                    <span>{{ scope.row.num_enregistrement }}</span>
                </template>
            </el-table-column> -->
            <!-- <el-table-column label="Date D'rnregistrement">
                <template slot-scope="scope">
                    <span>{{ scope.row.date_enregistrement }}</span>
                </template>
            </el-table-column> -->
            <el-table-column v-if="$gate.isAdmin()" width="150" align="center">
                <template slot-scope="{row}">
                        <a href="#" @click="ValiderDevis(row)" data-toggle="tooltip" data-placement="top" title="Confirmé la Commande">
                            <i class="fa fa-check-square green"></i>
                        </a>
                        <el-divider direction="vertical"></el-divider>
                        <a href="#" @click="ReceptionDevis(row)" data-toggle="tooltip" data-placement="top" title="Reception de devis">
                            <i class="fa fa-file black"></i>
                        </a>
                        <el-divider direction="vertical"></el-divider>
                        <a href="#" @click="updateDevis(row)" data-toggle="tooltip" data-placement="top" title="Modifier">
                                <i class="fa fa-edit blue"></i>
                        </a>
                        <el-divider direction="vertical"></el-divider>
                        <a href="#" @click="deletData(row.id)" data-toggle="tooltip" data-placement="top" title="Supprimer">
                            <i class="fa fa-trash red"></i>
                        </a>
                </template>
            </el-table-column>
            </el-table>
             <!--  -->
            <el-pagination background 
                    layout="prev, pager, next, sizes, total, jumper"
                    :page-sizes = "[5, 10, 15, 20]"
                    :page-size = "pagesize"
                    :total="Devis.length"
                    @current-change = "handleCurrentChange" 
                    @size-change = "handleSizeChange" 
                    >
            </el-pagination>
          </div>
        </div>
        </el-card>

        <!-- Modal Create and update -->
        <el-dialog title="Demander un Devis" :visible.sync="DialogVisible" width="90%" :before-close="handleClose">
            <el-form :model="form" ref="form" >
                        
                        <!-- Articles -->
                        <fieldset class="border p-2">
                            <legend class="w-auto badge"><h5>  </h5></legend>
                            <!-- Start Steps -->
                            <el-steps :active="active" finish-status="success" align-center>
                                <el-step title="Informations De Fournisseur" icon="el-icon-edit-outline"></el-step>
                                <el-step title="Articles" icon="el-icon-box"></el-step>
                                <el-step title="Verification et Confirmation de la Commande" icon="el-icon-folder-checked"></el-step>
                            </el-steps>
                            <!-- fournisseur infos -->
                                <fieldset class="border p-2 mt-5 col-lg-12" v-if="active === 0">
                                    <legend class="w-auto badge"><h5> </h5></legend>
                                    <div class="row">
                                        <el-form-item 
                                            class="col-lg-4" 
                                            label="Fournisseur" 
                                            prop="id_fournisseur"
                                            :rules="{
                                                required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                            }"
                                            >
                                            <el-select v-model="form.id_fournisseur" v-loading.fullscreen.lock="fullscreenLoading" @change="getFournisseur(form.id_fournisseur)" style="width: 100%" placeholder="CHOISIR UN FOURNISSEUR">
                                                <el-option
                                                    v-for="item in fournisseurs"
                                                    :key="item.id"
                                                    :label="item.name"
                                                    :value="item.id"
                                                ></el-option>
                                            </el-select>
                                        </el-form-item>
                                        <el-form-item 
                                            class="col-lg-4" 
                                            label="Devise" 
                                            prop="devise"
                                            :rules="{
                                                required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                            }"
                                            >
                                            <el-input v-model="form.devise" placeholder="Devise"></el-input>

                                            <!-- <el-select v-model="form.devise" style="width: 100%" placeholder="CHOISIR DEVISE">
                                                <el-option label="EURO" value="EURO"></el-option>
                                                <el-option label="USD" value="USD"></el-option>
                                                <el-option label="MAD" value="MAD"></el-option>
                                            </el-select> -->
                                        </el-form-item>
                                        <el-form-item
                                            class="col-lg-4"
                                            label="Pays de Provenance"
                                            prop="pays_provonance"
                                            :rules="{
                                                required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                            }"
                                        >
                                            <el-input v-model="form.pays_provonance" placeholder="Pays de Provenance"></el-input>
                                        </el-form-item>
                                        <!-- <el-form-item
                                            class="col-lg-4"
                                            label="Regime"
                                            prop="regime"
                                            :rules="{
                                                required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                            }"
                                        >
                                            <el-input v-model="form.regime" placeholder="Regime"></el-input>
                                        </el-form-item> -->
                                        <!-- <el-form-item
                                            class="col-lg-4"
                                            label="Date de Chargement"
                                            prop="date_chargement"
                                            
                                        >
                                            <el-date-picker type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd"  placeholder="Date de chargement" v-model="form.date_chargement" style="width: 100%;"></el-date-picker>
                                        </el-form-item> -->
                                    </div>
                                    <fieldset class="border p-2 mt-2">
                                     <legend class="w-auto badge"><h5>Mode de transport</h5></legend>
                                            <el-form-item 
                                                class=" col-lg-12 text-center"
                                                prop="mode_transport"
                                                :rules="[
                                                        { required: true, message: 'Ce champ est obligatoire'}
                                                    ]"
                                            >
                                                <el-radio-group v-model="form.mode_transport">
                                                    <el-radio label="Routier International"></el-radio>
                                                    <el-radio label="Routier National"></el-radio>
                                                    <el-radio label="Maritime"></el-radio>
                                                    <el-radio label="Aérien"></el-radio>
                                                </el-radio-group>
                                            </el-form-item>
                                    </fieldset>
                                    <fieldset class="border p-2 mt-2">
                                     <legend class="w-auto badge"><h5>INCOTERM</h5></legend>
                                            <el-form-item 
                                                class=" col-lg-12 text-center"
                                                prop="incoterm"
                                                :rules="[
                                                        { required: true, message: 'Ce champ est obligatoire'}
                                                    ]"
                                            >
                                                <el-radio-group v-model="form.incoterm">
                                                    <el-radio label="EX-WORKS (à l'usine)"></el-radio>
                                                    <el-radio label="FCA – Free Carrier (franco transporteur)"></el-radio>
                                                    <el-radio label="Les Incoterms® CPT et CIP"></el-radio>
                                                    <el-radio label="Les Incoterms® DAP, DPU et DDP"></el-radio>
                                                </el-radio-group>
                                            </el-form-item>
                                    </fieldset>
                                    <!-- Images -->
                                    <fieldset v-if="form.incoterm == 'EX-WORKS (à l\'usine)'" class="border p-2 mt-2">
                                        <div class="demo-image__preview text-center">
                                            <el-image
                                                :src="url1"
                                                :preview-src-list="srcList1">
                                            </el-image>
                                        </div>
                                        <el-alert
                                            class="mt-3"
                                            title="Description : "
                                            description="Règle qui impose le moins d'obligations au vendeur, dont l'unique responsabilité consiste à emballer les marchandises et à les mettre à disposition de l'acheteur dans ses propres locaux. En vertu de cette règle, l'acheteur supporte ainsi tous les frais et risques inhérents au chargement et au transport des marchandises jusqu'à leur arrivée à destination.
                                            Les formalités douanières à l'export étant à la charge de l'acheteur, celui-ci peut rencontrer des difficultés dans le pays du vendeur pour obtenir les justificatifs de sortie des marchandises.
                                            C'est pourquoi l'ICC recommande de réserver cette règle aux échanges nationaux ou régionaux n'impliquant pas l'exportation des marchandises et de privilégier la règle FCA en vertu de laquelle les formalités et coûts de dédouanement incombent au vendeur."
                                            type="info"
                                            effect="dark"
                                            :closable="false"
                                            show-icon>
                                        </el-alert>
                                        
                                    </fieldset>
                                    <fieldset v-if="form.incoterm == 'FCA – Free Carrier (franco transporteur)'" class="border p-2 mt-2">
                                        <div class="demo-image__preview text-center">
                                            <el-image
                                                :src="url2"
                                                :preview-src-list="srcList2">
                                            </el-image>
                                            <el-alert
                                                class="mt-3"
                                                title="Description : "
                                                description="Deux options sont possibles pour cet Incoterms® en fonction du lieu de livraison :
                                                            dans les locaux du vendeur, qui procède au chargement des marchandises sur le moyen de transport de l'acheteur (FCA « locaux du vendeur ») ;
                                                            dans tout autre lieu : le vendeur organise l'acheminement des marchandises jusqu'au lieu d'embarquement où elles sont mises à disposition du transporteur prêtes à être déchargées (FCA « autre lieu convenu »).
                                                            En vertu de cette règle, l'acheteur prend en charge la majeure partie du transport, mais lui permet d'être dispensé des formalités dans le pays d'exportation, qui incombent au vendeur."
                                                type="info"
                                                effect="dark"
                                                :closable="false"
                                                show-icon>
                                            </el-alert>
                                        </div>
                                    </fieldset>
                                    <fieldset v-if="form.incoterm == 'Les Incoterms® CPT et CIP'" class="border p-2 mt-2">
                                        <div class="demo-image__preview text-center">
                                            <el-image
                                                :src="url3"
                                                :preview-src-list="srcList3">
                                            </el-image>
                                            <el-alert
                                                class="mt-3"
                                                title="Description : "
                                                description="CPT – Carriage Paid To (port payé jusqu'à)
                                                            Le vendeur supporte le coût du transport jusqu'au lieu de destination mais n'est plus responsable des marchandises, qui voyagent aux risques de l'acheteur. En effet, le transfert des risques intervient au moment de la livraison, dès que les marchandises sont remises au transporteur, tandis que le transfert des coûts à l'acheteur a lieu lorsque les marchandises arrivent à destination.

                                                            CIP – Carriage and Insurance Paid to (port payé assurance comprise jusqu’à)
                                                            Le vendeur supporte les coûts de transport jusqu’au lieu de destination indiqué par l’Incoterms®.
                                                            Règle fréquemment utilisée, notamment pour le transport conteneurisé, le CIP permet de maîtriser l’acheminement des marchandises jusqu’à un point donné.

                                                            De même que pour le CPT, les frais de déchargement au lieu de destination convenu ne sont à la charge du vendeur que si le contrat de transport le prévoit.
                                                            En revanche, à la différence du CPT, le vendeur a l’obligation de souscrire une assurance couvrant les risques liés au transport des marchandises jusqu’au lieu de destination."
                                                type="info"
                                                effect="dark"
                                                :closable="false"
                                                show-icon>
                                            </el-alert>
                                        </div>
                                    </fieldset>
                                    <fieldset v-if="form.incoterm == 'Les Incoterms® DAP, DPU et DDP'" class="border p-2 mt-2">
                                        <div class="demo-image__preview text-center">
                                            <el-image
                                                :src="url4"
                                                :preview-src-list="srcList4">
                                            </el-image>
                                            <el-alert
                                                class="mt-3"
                                                title="Description : "
                                                description="S’agissant des règles Incoterms® D, la livraison étant effectuée dans le pays de destination, le transfert des risques intervient dans celui-ci.

                                                            En vertu de ces Incoterms® dits de « vente à l’arrivée », la marchandise voyage aux risques du vendeur qui assume l’ensemble des risques et des coûts liés au transport des marchandises jusqu’au lieu de destination.

                                                            DAP – Delivered At Place (rendu au lieu de destination)
                                                            Cet Incoterms® signifie que les marchandises sont considérées comme livrées lorsqu’elles sont mises à disposition de l’acheteur à destination sur le moyen de transport arrivant, sans être déchargées. En vertu de cette règle, le vendeur prend en charge le transport des marchandises jusqu’au point de livraison convenu, dans le pays de destination.
                                                            Ainsi, sauf si le contrat de transport en dispose autrement, c’est à l’acheteur qu’incombent les formalités douanières, le paiement des droits et taxes dus en raison de l’importation et le déchargement des marchandises à destination.

                                                            DPU – Delivered at Place Unloaded (rendu au lieu de destination déchargé)
                                                            Le DPU remplace le DAT 2010 et devient une nouvelle règle des Incoterms® 2020.
                                                            Cette règle signifie que les marchandises sont considérées comme livrées, une fois déchargées du moyen de transport et mises à disposition de l’acheteur au lieu de destination convenu (terminal ou autre).
                                                            Dans cette règle Incoterms®, la livraison et l’arrivée à destination interviennent au même point. Le vendeur assume donc tous les risques et coûts liés au transport des marchandises et à leur déchargement jusqu’au lieu désigné.
                                                            Le DPU est la seule règle Incoterms® qui oblige le vendeur à décharger les marchandises à destination.

                                                            DDP – Delivered Duty Paid (rendu droits acquittés)  Règle Incoterms® qui confère le niveau maximal d’obligations au vendeur, qui assume tous les risques et frais, y compris de dédouanement, jusqu’au lieu convenu.
                                                            Ainsi, en vertu de cet Incoterms®, les marchandises sont livrées dédouanées, prêtes à être déchargées au lieu de destination.
                                                            Seuls les frais d’assurance et de déchargement à destination sont à la charge de l’acheteur."
                                                type="info"
                                                effect="dark"
                                                :closable="false"
                                                show-icon>
                                            </el-alert>
                                        </div>
                                    </fieldset>
                                    <!-- Modalite paiment -->
                                    <fieldset class="border p-2 mt-2">
                                            <legend class="w-auto badge"><h5>Modalité de paiement proposé a négocier</h5></legend>
                                            <el-form-item 
                                                prop="ModaliteP"
                                            >
                                                <el-radio-group v-model="form.ModaliteP">
                                                    <el-radio label="30% A LA COMMANDE (FACTURE PROFORMA) LE RESTE AU CHARGEMENT"></el-radio><br>
                                                    <el-radio label="30% A LA COMMANDE (FACTURE PROFORMA) LE RESTE APRES RECEPTION DE M/SE"></el-radio><br>
                                                    <el-radio label="30% A LA COMMANDE (FACTURE PROFORMA) LE RESTE APRES RECEPTION DE M/SE à 90 JOURS"></el-radio>
                                                </el-radio-group>
                                            </el-form-item>

                                            <div>
                                                <el-alert
                                                    v-if="form.ModaliteP == '30% A LA COMMANDE (FACTURE PROFORMA) LE RESTE AU CHARGEMENT'"
                                                    title="Condition !"
                                                    description="LA FACTURE DEFINITIVE ET (PROFORMA)NE DOIT PAS DEPASSER 200 000,00DHS (COURS DE PAIEMENT DE RESTE).
                                                                    SI NON BLOCAGE DE CHARGEMENT jUSQU'AU RESOLUTION DE PROBLEMES"
                                                    type="warning"
                                                    effect="dark"
                                                    :closable="false"
                                                    show-icon>
                                                </el-alert>
                                                <!--  -->
                                                <el-alert
                                                    v-if="form.ModaliteP == '30% A LA COMMANDE (FACTURE PROFORMA) LE RESTE APRES RECEPTION DE M/SE'"
                                                    title="Condition !"
                                                    description="LA FACTURE DEFINITIVE NE DOIT PAS ETRE INFERIEUR A CELLE DE LA FACTURE PROFORMA OU EN A FAIT L'ENGAGEMENT.
                                                                    SI NON BLOCAGE DE CHARGEMENT jUSQU'AU RESOLUTION DE PROBLEMES"
                                                    type="warning"
                                                    effect="dark"
                                                    :closable="false"
                                                    show-icon>
                                                </el-alert>
                                                <!--  -->
                                                <el-alert
                                                    v-if="form.ModaliteP == '30% A LA COMMANDE (FACTURE PROFORMA) LE RESTE APRES RECEPTION DE M/SE à 90 JOURS'"
                                                    title="Condition !"
                                                    description="LA FACTURE DEFINITIVE NE DOIT PAS ETRE INFERIEUR A CELLE DE LA FACTURE PROFORMA OU EN A FAIT L'ENGAGEMENT.
                                                                    SI NON BLOCAGE DE CHARGEMENT jUSQU'AU RESOLUTION DE PROBLEMES"
                                                    type="warning"
                                                    effect="dark"
                                                    :closable="false"
                                                    show-icon>
                                                </el-alert>
                                            </div>
                                    </fieldset>
                                    <!--  -->
                                    <fieldset class="border p-2 mt-2">
                                     <legend class="w-auto badge"><h5></h5></legend>
                                        <div class="col-12 row">
                                            <el-form-item 
                                                class="col-lg-3"
                                                label="Montant en Devise"
                                                prop="montant"
                                                :rules="{
                                                    required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                                }"
                                                >
                                                <el-input v-model="form.montant" type="number" placeholder="Montant en devise">
                                                    <template slot="append">{{form.devise}}</template>
                                                </el-input>
                                            </el-form-item>
                                            <el-form-item 
                                                class="col-lg-3"
                                                label="N° d'enregistrement"
                                                prop="num_enregistrement"
                                                :rules="{
                                                    required: true, message: 'Ce champ est obligatoire', trigger: 'blur'
                                                }"
                                                >
                                                <el-input v-model="form.num_enregistrement" type="number" placeholder="N° d'enregistrement"></el-input>
                                            </el-form-item>
                                            <!-- <el-form-item
                                                class="col-lg-3"
                                                label="Date d'enregistrement"
                                                prop="date_enregistrement"
                                                
                                            >
                                                <el-date-picker format="yyyy-MM-dd" value-format="yyyy-MM-dd" type="date" placeholder="Date d'enregistrement" v-model="form.date_enregistrement" style="width: 100%;"></el-date-picker>
                                            </el-form-item> -->
                                            <!-- <el-form-item
                                                class="col-lg-3"
                                                label="Date de validité"
                                                prop="date_validite"
                                                
                                            >
                                                <el-date-picker format="yyyy-MM-dd" value-format="yyyy-MM-dd" type="date" placeholder="Date de Validité" v-model="form.date_validite" style="width: 100%;"></el-date-picker>
                                            </el-form-item> -->
                                        </div>
                                    </fieldset>
                                </fieldset>
                            <!-- End Fournisseur infos -->
                            
                            <!-- Articles   -->
                                <div v-if="active === 1">
                                    <fieldset class="border p-2 col-lg-12 mt-5">
                                                <legend class="w-auto badge"><h5> Ajouter Article </h5></legend>
                                                <div class="row">
                                                     <el-form-item class="col-lg-3" label="Code Article">
                                                            <el-select v-model="ArticlesForm.id_article" @change="selectedArticle(ArticlesForm.id_article)" placeholder="select Categorie">
                                                                <el-option
                                                                    v-for="item in articlesFournis"
                                                                    :key="item.id"
                                                                    :label="item.Code_Article_four"
                                                                    :value="item.id"
                                                                ></el-option>
                                                            </el-select>
                                                        </el-form-item>
                                                        <el-form-item class="col-lg-3" label="Categorie">
                                                            <el-input v-model="categori_article"></el-input>
                                                        </el-form-item>
                                                        <el-form-item class="col-lg-3" label="Sous Categorie">
                                                            <el-input v-model="sousCategori_article"></el-input>
                                                        </el-form-item>
                                                        <el-form-item class="col-lg-3" label="Nomenclature">
                                                            <el-input v-model="Nomenclature"></el-input>
                                                        </el-form-item>
                                                        <el-form-item class="col-lg-3" label="Unite Mesure">
                                                            <el-input v-model="unite_mesure"></el-input>
                                                        </el-form-item>
                                                        <el-form-item class="col-lg-3" label="Regime économique">
                                                            <el-input v-model="regime_economique"></el-input>
                                                        </el-form-item>
                                                <!-- mp2 marchandise2 -->
                                                        <el-form-item v-if="this.sousCategorieSelected == 'mp2' || this.sousCategorieSelected == 'march1' || this.sousCategorieSelected == 'march5'" class="col-lg-3" label="Dimension">
                                                            <el-input v-model="ArticlesForm.dimension"></el-input>
                                                        </el-form-item>
                                                <!-- end mp2 marchandise2 -->
                                                        
                                                        <!-- <el-form-item class="col-lg-3" label="Ref">
                                                            <el-input v-model="ArticlesForm.referance"></el-input>
                                                        </el-form-item> -->
                                            <!-- mp1 -->
                                                    <div class="col-12 row" v-if="this.sousCategorieSelected == 'mp1'">
                                                        <el-form-item class="col-lg-3" label="Nombre Bobine">
                                                            <el-input @change="CalaculQtitML()" v-model="ArticlesForm.NombreBobine"></el-input>
                                                        </el-form-item>
                                                        <el-form-item class="col-lg-3" label="Quantit ML">
                                                            <el-input @change="CalaculQtitML()" v-model="ArticlesForm.quantite_ml_bobine"></el-input>
                                                        </el-form-item>
                                                        <el-form-item class="col-lg-3" label="Laize (mm)">
                                                            <el-input @change="CalaculQtitML()" v-model="ArticlesForm.laize_mm">
                                                                <template slot="append">mm</template>
                                                            </el-input>
                                                        </el-form-item>
                                                        <el-form-item class="col-lg-3" label="Quantite ML Total">
                                                            <el-input v-model="ArticlesForm.quantite_ml_total">
                                                                <template slot="append">ML</template>
                                                            </el-input>
                                                        </el-form-item>
                                                        <el-form-item class="col-lg-3" label="Quantite M² Total">
                                                            <el-input v-model="ArticlesForm.quantite_m2_total">
                                                                <template slot="append">M²</template>
                                                            </el-input>
                                                        </el-form-item>
                                                    </div>
                                        <!-- end mp1 -->
                                                        <el-form-item class="col-lg-3" label="Prix Unit">
                                                            <el-input v-model="ArticlesForm.prix_unit"></el-input>
                                                        </el-form-item>
                                                        <el-form-item class="col-lg-3" label="Prix Total">
                                                            <el-input v-model="ArticlesForm.prix_total"></el-input>
                                                        </el-form-item>
                                            <!-- mp2 -->
                                                    <div class="col-12 row" v-if="this.sousCategorieSelected == 'mp2'">
                                                        <el-form-item class="col-lg-3" label="diametre">
                                                            <el-select v-model="ArticlesForm.diametre" placeholder="Select l'emballage">
                                                                <el-option label="76" value="76"></el-option>
                                                                <el-option label="40" value="40"></el-option>
                                                                <el-option label="Autre" value="Autre"></el-option>
                                                            </el-select>
                                                        </el-form-item>
                                                        <el-form-item class="col-lg-3" label="nombre_mandrin_kg">
                                                            <el-input v-model="ArticlesForm.nombre_mandrin_kg"></el-input>
                                                        </el-form-item>
                                                    </div>
                                        <!-- End mp2 -->
                                        <!-- consomable -->
                                                    <div class="col-12 row" v-if="this.sousCategorieSelected == 'consomables'">
                                                        <el-form-item class="col-lg-3" label="Emballage">
                                                            <el-select v-model="ArticlesForm.emballage" @change="changePots()" placeholder="Select l'emballage">
                                                                <el-option label="1 KG" value="1"></el-option>
                                                                <el-option label="2.5 KG" value="2.5"></el-option>
                                                                <el-option label="5 KG" value="5"></el-option>
                                                                <el-option label="10 KG" value="10"></el-option>
                                                                <el-option label="25 KG" value="25"></el-option>
                                                            </el-select>
                                                        </el-form-item>
                                                        <el-form-item class="col-lg-3" label="Quantit pots">
                                                            <el-input @change="changePots()" v-model="ArticlesForm.qtit_pots"></el-input>
                                                        </el-form-item>
                                                        <el-form-item class="col-lg-3" label="Quantit pots total">
                                                            <el-input @change="CalculConsomablePrix()" v-model="ArticlesForm.qtit_pots_total"></el-input>
                                                        </el-form-item>
                                                    </div>
                                    <!-- end Consommable -->
                                    <!-- marchandise 2 -->
                                                    <div class="col-lg-3" v-if="this.sousCategorieSelected == 'march2'">
                                                        <el-form-item  label="laize cm">
                                                            <el-input v-model="ArticlesForm.laize_cm">
                                                                <template slot="append">cm</template>
                                                            </el-input>
                                                        </el-form-item>
                                                    </div>
                                <!-- End marchandise 2 -->
                                                    </div>
                                                <!--  -->
                                                <el-button type="success" @click="addNewRow" icon="el-icon-plus" class="float-right">Ajouter l'article</el-button>

                                                <!--  -->
                                        <!-- End Articles -->                       
                                    </fieldset>

                                    <fieldset class="p-2 col-lg-12 mt-3">
                                            <legend class="w-auto badge"><h5> Liste des Articles </h5></legend>
                                                <div v-for="(c, k) in form.ArticlesVendueF" :key="k">
                                                    <el-descriptions   el-descriptions class="margin-top" border :title="'Article N° : '+k" v-if="c.id_article != ''">
                                                        <template slot="extra">
                                                            <!-- <el-button @click="updateArticle(k, c)" size="mini"  data-toggle="tooltip" data-placement="top" title="Modifier l'article" type="primary" icon="el-icon-edit" circle></el-button>
                                                            <el-divider direction="vertical"></el-divider> -->
                                                            <el-button type="danger" @click="deleteRow(k, c)" size="mini" icon="el-icon-delete" data-toggle="tooltip" data-placement="top" title="Supprimer l'article" circle></el-button>
                                                        </template>
                                                        <!-- M1 -->
                                                        
                                                        <!-- M1 -->
                                                        <!-- <div v-if="c.NombreBobine != ''"> -->
                                                            <el-descriptions-item label="Nombre Bobine">{{c.NombreBobine}}</el-descriptions-item>
                                                            <el-descriptions-item label="Quantité ML Par Bobine">{{c.quantite_ml_bobine}}</el-descriptions-item>
                                                            <el-descriptions-item label="laize (mm)">{{c.laize_mm}}</el-descriptions-item>
                                                            <el-descriptions-item label="Quantité ML ">{{c.quantite_ml_total}}</el-descriptions-item>
                                                            <el-descriptions-item label="Quantité M²">{{c.quantite_m2_total}}</el-descriptions-item>
                                                        <!-- </div> -->
                                                        <!--  -->
                                                        <!-- M2 -->
                                                        <!-- <div v-if="c.diametre != ''"> -->
                                                            <el-descriptions-item label="Diametre">{{c.diametre}}</el-descriptions-item>
                                                            <el-descriptions-item label="Nombre Mandrin par kg">{{c.nombre_mandrin_kg}}</el-descriptions-item>
                                                        <!-- </div> -->
                                                        <!--  -->
                                                        <!-- Consomation -->
                                                        <!-- <div v-if="c.emballage != ''"> -->
                                                            <el-descriptions-item label="Emballage">{{c.emballage}}</el-descriptions-item>
                                                            <el-descriptions-item label="Quantit Pots">{{c.qtit_pots}}</el-descriptions-item>
                                                            <el-descriptions-item label="Quantit Pots Total">{{c.qtit_pots_total}}</el-descriptions-item>
                                                        <!-- </div> -->
                                                        <!--  -->
                                                        <el-descriptions-item label="Prix Unit ">{{c.prix_unitaire}}</el-descriptions-item>
                                                        <el-descriptions-item label="Prix Total">{{c.prix_total}}</el-descriptions-item>
                                                    </el-descriptions>
                                                </div>
                                    </fieldset>
                                </div>
                                <!-- End Article -->
                                
                                <!-- Verification final -->
                                <fieldset class="border p-2 col-lg-12 mt-3" v-if="active === 2">
                                    <legend class="w-auto badge"><h5> </h5></legend>

                                        <el-descriptions title="Information Fournisseur" size="4" border>
                                            <el-descriptions-item><template slot="label"><strong>Fournisseur :</strong></template> {{form.id_fournisseur}}</el-descriptions-item>
                                            <el-descriptions-item ><template slot="label"><strong>Pays de Provenance :</strong></template> {{form.pays_provonance}} </el-descriptions-item>
                                            <!-- <el-descriptions-item ><template slot="label"><strong>Regime :</strong></template> {{form.regime}} </el-descriptions-item> -->
                                            <!-- <el-descriptions-item ><template slot="label"><strong>Date de Chargement :</strong></template> {{form.date_chargement}} </el-descriptions-item> -->
                                            
                                            <el-descriptions-item ><template slot="label"><strong>Incoterm :</strong></template> {{form.incoterm}}</el-descriptions-item>
                                            <el-descriptions-item ><template slot="label"><strong>Montant en devise :</strong></template>{{form.montant}} </el-descriptions-item>
                                            <el-descriptions-item ><template slot="label"><strong>N° d'enregistrement :</strong></template> {{form.num_enregistrement}} </el-descriptions-item>
                                            <!-- <el-descriptions-item ><template slot="label"><strong>Date d'enregistrement :</strong></template> {{form.date_enregistrement}} </el-descriptions-item> -->
                                        </el-descriptions>
                                        
                                        <fieldset class="border p-2 col-lg-12 mt-3">
                                                <legend class="w-auto badge"><h5> Liste des articles </h5></legend>
                                            
                                            <div v-for="(c, k) in form.ArticlesVendueF" :key="k">
                                                    <el-descriptions class="margin-top" border :title="'Article N° : '+k" v-if="c.id_article != ''">
                                                        <!-- M1 -->
                                                        
                                                        <!-- M1 -->
                                                        <!-- <div v-if="c.NombreBobine != ''"> -->
                                                            <el-descriptions-item label="Nombre Bobine">{{c.NombreBobine}}</el-descriptions-item>
                                                            <el-descriptions-item label="Quantité ML Par Bobine">{{c.quantite_ml_bobine}}</el-descriptions-item>
                                                            <el-descriptions-item label="laize (mm)">{{c.laize_mm}}</el-descriptions-item>
                                                            <el-descriptions-item label="Quantité ML ">{{c.quantite_ml_total}}</el-descriptions-item>
                                                            <el-descriptions-item label="Quantité M²">{{c.quantite_m2_total}}</el-descriptions-item>
                                                        <!-- </div> -->
                                                        <!--  -->
                                                        <!-- M2 -->
                                                        <!-- <div v-if="c.diametre != ''"> -->
                                                            <el-descriptions-item label="Diametre">{{c.diametre}}</el-descriptions-item>
                                                            <el-descriptions-item label="Nombre Mandrin par kg">{{c.nombre_mandrin_kg}}</el-descriptions-item>
                                                        <!-- </div> -->
                                                        <!--  -->
                                                        <!-- Consomation -->
                                                        <!-- <div v-if="c.emballage != ''"> -->
                                                            <el-descriptions-item label="Emballage">{{c.emballage}}</el-descriptions-item>
                                                            <el-descriptions-item label="Quantit Pots">{{c.qtit_pots}}</el-descriptions-item>
                                                            <el-descriptions-item label="Quantit Pots Total">{{c.qtit_pots_total}}</el-descriptions-item>
                                                        <!-- </div> -->
                                                        <!--  -->
                                                        <el-descriptions-item label="Prix Unit ">{{c.prix_unitaire}}</el-descriptions-item>
                                                        <el-descriptions-item label="Prix Total">{{c.prix_total}}</el-descriptions-item>
                                                    </el-descriptions>
                                                </div>
                                        </fieldset>

                                </fieldset>

                                <!-- End Verification final -->
                            </fieldset>
                            <fieldset class="border p-2 col-lg-12">
                                <el-button v-if="this.active > 0" type="primary" @click="prev" icon="el-icon-arrow-left">L'étape Précédente </el-button>
                                <el-button v-if="this.active < 2" type="primary" class="float-right"  @click="next">L'étape suivante <i class="el-icon-arrow-right"></i></el-button>
                            </fieldset>
                        
                  </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="DialogVisible = false">Annuler</el-button>
                    <el-button  v-if="this.active == 2"  v-show="!editmode" type="success" @click="createData()">Valider</el-button>
                    <el-button  v-if="this.active == 2" v-show="editmode" type="success" @click="updateData('devis')">Valider</el-button>
                </span>
        </el-dialog>
        <!-- Dialog Article -->
        <el-dialog
            :title="'Réception de devis | Devis N° : '+ form.id"
            :visible.sync="DialogArticleVisible"
            width="90%"
            :before-close="handleClose">
            
              <fieldset class="border p-2 col-lg-12 mt-3">
                        <legend class="w-auto badge"><h5> </h5></legend>

                            <el-descriptions title="Information Fournisseur" size="4" border>
                                <el-descriptions-item><template slot="label"><strong>Fournisseur :</strong></template> {{form.id_fournisseur}}</el-descriptions-item>
                                <el-descriptions-item ><template slot="label"><strong>Pays de Provenance :</strong></template> {{form.pays_provonance}} </el-descriptions-item>
                                <el-descriptions-item ><template slot="label"><strong>Incoterm :</strong></template> {{form.incoterm}}</el-descriptions-item>
                                <el-descriptions-item ><template slot="label"><strong>Montant en devise :</strong></template>{{form.montant}} </el-descriptions-item>
                                <el-descriptions-item ><template slot="label"><strong>N° d'enregistrement :</strong></template> {{form.num_enregistrement}} </el-descriptions-item>
                            </el-descriptions>
                            
                            <fieldset class="border p-2 col-lg-12 mt-3">
                                    <legend class="w-auto badge"><h5> Liste des articles </h5></legend>
                                
                                <div v-for="(c, k) in form.ArticlesVendueF" :key="k">
                                        <el-descriptions class="margin-top" border :title="'Article N° : '+k" v-if="c.id_article != ''">
                                            <template slot="extra">
                                                <el-button @click="updateArticle(k, c)" size="mini"  data-toggle="tooltip" data-placement="top" title="Modifier le prix d'article" type="primary" icon="el-icon-edit" circle></el-button>
                                            </template>
                                            <!-- <div v-if="c.NombreBobine != ''"> -->
                                                <el-descriptions-item label="Nombre Bobine">{{c.NombreBobine}}</el-descriptions-item>
                                                <el-descriptions-item label="Quantité ML Par Bobine">{{c.quantite_ml_bobine}}</el-descriptions-item>
                                                <el-descriptions-item label="laize (mm)">{{c.laize_mm}}</el-descriptions-item>
                                                <el-descriptions-item label="Quantité ML ">{{c.quantite_ml_total}}</el-descriptions-item>
                                                <el-descriptions-item label="Quantité M²">{{c.quantite_m2_total}}</el-descriptions-item>
                                            <!-- </div> -->
                                            <!--  -->
                                            <el-descriptions-item label="Prix Unit ">{{c.prix_unitaire}}</el-descriptions-item>
                                            <el-descriptions-item label="Prix Total">{{c.prix_total}}</el-descriptions-item>
                                        </el-descriptions>
                                    </div>
                            </fieldset>

                    </fieldset>
            <span slot="footer" class="dialog-footer">
                <el-button @click="DialogArticleVisible = false">Cancel</el-button>
                <el-button type="primary" @click="updateData('price')">Confirm</el-button>
            </span>
        </el-dialog>
        <!-- Dialog Prices -->
        <el-dialog
            title="Modifier L'article"
            :visible.sync="DialogPricesVisible"
            width="30%"
            :before-close="handleClose">
              <el-form>
                  <div class="row">
                    
                        <el-form-item class="col-lg-4" label="Saisie Le Nouveau Prix">
                            <el-input v-model="newPrice"></el-input>
                        </el-form-item>
                        
                    </div>
              </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="DialogPricesVisible = false">Cancel</el-button>
                <el-button type="primary" @click="ValiderModificationArticle()">Confirm</el-button>
            </span>
        </el-dialog>
        <!--  -->


        <!-- Modal Create and update -->
        <el-dialog title="Confirmer Le Devis" :visible.sync="DialogVisible2" width="90%" :before-close="handleClose">
            <el-form :model="form" ref="form" >
                        <fieldset class="border p-2">
                            <legend class="w-auto badge"><h5>  </h5></legend>
                                <!-- Verification final -->
                                <fieldset class="border p-2 col-lg-12 mt-3">
                                    <legend class="w-auto badge"><h5> </h5></legend>

                                        <el-descriptions title="Information Fournisseur" size="4" border>
                                            <el-descriptions-item><template slot="label"><strong>Fournisseur :</strong></template> {{form.id_fournisseur}}</el-descriptions-item>
                                            <el-descriptions-item ><template slot="label"><strong>Pays de Provenance :</strong></template> {{form.pays_provonance}} </el-descriptions-item>
                                            <!-- <el-descriptions-item ><template slot="label"><strong>Regime :</strong></template> {{form.regime}} </el-descriptions-item> -->
                                            <!-- <el-descriptions-item ><template slot="label"><strong>Date de Chargement :</strong></template> {{form.date_chargement}} </el-descriptions-item> -->
                                            
                                            <el-descriptions-item ><template slot="label"><strong>Incoterm :</strong></template> {{form.incoterm}}</el-descriptions-item>
                                            <el-descriptions-item ><template slot="label"><strong>Montant en devise :</strong></template>{{form.montant}} </el-descriptions-item>
                                            <el-descriptions-item ><template slot="label"><strong>N° d'enregistrement :</strong></template> {{form.num_enregistrement}} </el-descriptions-item>
                                            <!-- <el-descriptions-item ><template slot="label"><strong>Date d'enregistrement :</strong></template> {{form.date_enregistrement}} </el-descriptions-item> -->
                                        </el-descriptions>
                                        
                                        <fieldset class="border p-2 col-lg-12 mt-3">
                                                <legend class="w-auto badge"><h5> Liste des articles </h5></legend>
                                            
                                            <el-table
                                                :data="form.articles"
                                                style="width: 100%">
                                                <el-table-column
                                                fixed
                                                prop="code_article"
                                                label="Code Article"
                                                >
                                                </el-table-column>
                                                <el-table-column
                                                prop="Nomenclature"
                                                label="Nomenclature"
                                                >
                                                </el-table-column>
                                                <el-table-column
                                                prop="num_bobines"
                                                label="Nombre Bobine"
                                                >
                                                </el-table-column>
                                                <el-table-column
                                                prop="laize"
                                                label="Laize (mm)"
                                                >
                                                </el-table-column>
                                                <el-table-column
                                                prop="quantite_ml"
                                                label="Quantite ML"
                                                >
                                                </el-table-column>
                                                <el-table-column
                                                prop="quantite_m2"
                                                label="Quantite M²"
                                                >
                                                </el-table-column>
                                            </el-table>
                                        </fieldset>

                                </fieldset>
                                <fieldset class="border p-2 col-lg-12 mt-3">
                                    <legend class="w-auto badge"><h5> </h5></legend>
                                        <el-form-item 
                                            class="text-center col-4"
                                            prop="validation"
                                        >
                                            <el-switch
                                                v-model="form.validation"
                                                active-value="1"
                                                inactive-value="0"
                                                active-color="#13ce66"
                                                inactive-color="#ff4949"
                                                inactive-text="Invalide"
                                                active-text="Valide"
                                            >
                                            </el-switch>
                                        </el-form-item>
                                </fieldset>
                                <!-- End Verification final -->
                            </fieldset>
                  </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="DialogVisible2 = false">Annuler</el-button>
                    <el-button  type="success" @click="ValidationDevis()">Valider</el-button>
                </span>
        </el-dialog>
        
        
    </div>
  </section>
</template>

<script>
    import VueTagsInput from '@johmun/vue-tags-input';

    export default {
      components: {
          VueTagsInput,
        },
        data () {
            return {
                // 
                active: 0,
                search: '',
                pagesize: 10,
                currpage: 1,
                editmode: false,
                fullscreenLoading: false,

                DialogVisible: false,
                DialogVisible2: false,
                DialogArticleVisible : false,
                DialogPricesVisible : false,

                Devis:[],
                fournisseurs:[],
                Onefournisseurs:[],
                OneArticle:[],
                OneDevis:[],
                Nomenclature: '',
                unite_mesure: '',
                sousCategorieSelected:'',
                categori_article:'',
                regime_economique:'',
                sousCategori_article:'',
                articlesFournis:[],

                products:[],
                Souscategorie:[],

                ArticlesForm : new Form({
                                        id_article:'',prix_total:'',dimension:'',prix_unitaire:'',NombreBobine:'',quantite_ml_bobine:'',
                                        laize_mm:'',quantite_ml_total:'',quantite_m2_total:'',diametre:'',nombre_mandrin_kg:'',
                                        emballage:'',qtit_pots:'',qtit_pots_total:'',laize_cm:''}),

                newPrice:'',
                indexRow:'',

                form: new Form({
                    id : '',
                    id_fournisseur:'',
                    devise:'',
                    pays_provonance:'',
                    // regime:'',
                    mode_transport:'',
                    incoterm:'',
                    montant:'',
                    num_enregistrement:'',
                    // date_enregistrement:'',
                    date_validite:'',
                    // articles: [{  code_article: "",Nomenclature:"",num_bobines:"",laize:"",quantite_ml:"",quantite_m2:"" }],

                    ArticlesVendueF:[{id_article:'',prix_total:'',dimension:'',prix_unitaire:'',NombreBobine:'',quantite_ml_bobine:'',
                                        laize_mm:'',quantite_ml_total:'',quantite_m2_total:'',diametre:'',nombre_mandrin_kg:'',
                                        emballage:'',qtit_pots:'',qtit_pots_total:'',laize_cm:''}],
                    validation:'',
                    ModaliteP:''
                }),
                // 
                PricesForm : new Form({ id_devis:'',id_article:'',price:''}),

                formPrices: new Form({
                   PricesArticles:[{id_devis:'',id_article:'',price:''}]
                 }),
                //  
    // End
                // categorie & sous categories
                consommables: [{ value: 'Ancres'},{ value: 'Assytat'},{ value: 'White spirit'},{ value: 'Huile 46'}],
                matier_premier: [{ value: 'Papier'},{ value: 'P.P'},{ value: 'Fruit tag'},{ value: 'Mandrin en carton'}],
                marchandise : [ { value: 'Cornière en carton'},{ value: 'Filets tubulaires'},{ value: 'Filets de palitisation'},
                                { value: 'Filets cellulosiques'},{ value: 'Feuillards en plastique'},{ value: 'Feuillards métaliques'},
                                { value: 'Barquettes'},{ value: 'Complexes'},{ value: 'Alvéoles'},{ value: 'Agraffes'},
                                { value: 'Papillotes'}],
 
                
                
                

                formArticle: new Form({

                    prixTotal:'',
                    codeArticle:'',
                    categorie:'',
                    souscategorie:'',
                    designation:'',
                    nomenclature:'',
                    nombreBobine:'',
                    QtitMlBobine:'',
                    qtitML:'',
                    LaizeMM:'',
                    qtitM2:'',
                    unitMesure:'',
                    prixUnit:'',
                    
                }),
                
                // Images Incoterm
                url1: '/images/incoterm/1.png',
                srcList1: ['/images/incoterm/1.png', ],
                url2: '/images/incoterm/2.png',
                srcList2: ['/images/incoterm/2.png', ],
                url3: '/images/incoterm/3.png',
                srcList3: ['/images/incoterm/3.png', ],
                url4: '/images/incoterm/4.png',
                srcList4: ['/images/incoterm/4.png', ],

                categories: [],
              
                tag:  '',
                autocompleteItems: [],
            }
        },
        methods: {

            updateArticle(index){
                // this.ArticlesForm.reset();
                this.newPrice = '';
                this.indexRow = index;
                
                this.newPrice = this.form.ArticlesVendueF[index].prix_unitaire,
                     
                this.DialogPricesVisible = true;
          },

            addNewRow() {
                this.form.ArticlesVendueF.push(
                {
                    categori_article:this.ArticlesForm.categori_article,
                    sousCategori_article:this.ArticlesForm.sousCategori_article,
                    designiation:this.ArticlesForm.designiation,
                    Nomenclature:this.ArticlesForm.Nomenclature,
                    Code_Article_four:this.ArticlesForm.Code_Article_four,
                    designiationIntern:this.ArticlesForm.designiationIntern,
                    NomenclatureIntern:this.ArticlesForm.NomenclatureIntern,
                    Code_ArticleIntern:this.ArticlesForm.Code_ArticleIntern,
                    unite_mesure:this.ArticlesForm.unite_mesure,
                    prix_unit:this.ArticlesForm.prix_unit,
                    prix_total:this.ArticlesForm.prix_total,
                    dimension:this.ArticlesForm.dimension,
                    regime_economique:this.ArticlesForm.regime_economique,
                    regime_economique_name:this.ArticlesForm.regime_economique_name,
                    referance:this.ArticlesForm.referance,
                    prix_unitaire:this.ArticlesForm.prix_unitaire,
                    
                    }
                )

                // console.log(this.form.ArticlesVendueF);
                this.ArticlesForm.reset();
            },

             // calcules

            CalaculQtitML(){
                this.ArticlesForm.quantite_ml_total = this.ArticlesForm.NombreBobine * this.ArticlesForm.quantite_ml_bobine;
                this.ArticlesForm.quantite_m2_total = (this.ArticlesForm.NombreBobine * this.ArticlesForm.quantite_ml_bobine * this.ArticlesForm.laize_mm) / 1000;
            },

            changePots(){
                this.ArticlesForm.qtit_pots_total = this.ArticlesForm.qtit_pots * this.ArticlesForm.emballage
            },

            CalculConsomablePrix(){
                this.ArticlesForm.prix_total =  this.ArticlesForm.qtit_pots_total * this.ArticlesForm.prix_unit
            },

            // end calcules

            // Start CRUD
            loadDevis(){
                
                axios.get("api/DevisFournisseur").then(({ data }) => (this.Devis = data.data.DevisFournisseur));
            },

            loadFournisseurs(){
                
                axios.get("api/CompteFournisseurs").then(({ data }) => (this.fournisseurs = data.data));
            },
            
            newDevis(){
                this.form.reset();
                this.editmode = false;
                this.DialogVisible = true;
                this.active=0;
            },

            createData(){
              this.$Progress.start();

            //   console.log(this.form.articles);

              this.form.post('api/DevisFournisseur')
              .then((data)=>{
                if(data.data.success){
                  this.DialogVisible = false;

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                    this.$Progress.finish();
                    this.loadDevis();

                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          },

 
         async updateDevis(devis){
                this.editmode = true;
                this.form.reset();
                this.DialogVisible = true;
                this.active=0;
                this.form.fill(devis);
                this.form.ArticlesVendueF = [];
                
                // console.log(devis.id_fournisseur)
                for (let i = 0; i < devis.get_articles.length; i++) {
                    this.form.ArticlesVendueF.push({
                        id_article:devis.get_articles[i].id_article,
                        prix_unitaire:devis.get_articles[i].prix_unitaire,
                        prix_total:devis.get_articles[i].prix_total,
                        dimension:devis.get_articles[i].dimension,
                        NombreBobine:devis.get_articles[i].NombreBobine,
                        quantite_ml_bobine:devis.get_articles[i].quantite_ml_bobine,
                        laize_mm:devis.get_articles[i].laize_mm,
                        quantite_ml_total:devis.get_articles[i].quantite_ml_total,
                        quantite_m2_total:devis.get_articles[i].quantite_m2_total,
                        diametre:devis.get_articles[i].diametre,
                        nombre_mandrin_kg:devis.get_articles[i].nombre_mandrin_kg,
                        emballage:devis.get_articles[i].emballage,
                        qtit_pots:devis.get_articles[i].qtit_pots,
                        qtit_pots_total:devis.get_articles[i].qtit_pots_total,
                        laize_cm:devis.get_articles[i].laize_cm
                    })
                }

                // this.selectedArticle(devis.id_fournisseur)
            },

            ValiderDevis(devis){
                // this.editmode = true;
                this.form.reset();
                this.DialogVisible2 = true;
                // this.active=0;
                
                this.form.fill(devis);
                this.form.articles = [];
                for (let i = 0; i < devis.get_articles.length; i++) {
                    this.form.articles.push({
                        code_article: devis.get_articles[i].code_article,
                        Nomenclature: devis.get_articles[i].Nomenclature,
                        num_bobines: devis.get_articles[i].num_bobines,
                        laize: devis.get_articles[i].laize,
                        quantite_ml: devis.get_articles[i].quantite_ml,
                        quantite_m2: devis.get_articles[i].quantite_m2,
                    })
                }

            },

          updateData(dialog){
              this.$Progress.start();
              this.form.put('api/DevisFournisseur/'+this.form.id)
              .then((response) => {
                  // success
                if(dialog == 'devis'){
                    this.DialogVisible = false;
                }
                if(dialog == 'price'){
                    this.DialogArticleVisible = false;
                }

                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });
                  this.$Progress.finish();

                  this.loadDevis();
              })
              .catch(() => {
                  this.$Progress.fail();
              });

          },

          ValidationDevis(){
              this.$Progress.start();
              this.form.put('api/ValidationDevis/'+this.form.id)
              .then((response) => {
                  // success
                  
                this.DialogVisible2 = false;
                  
                  
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });
                  this.$Progress.finish();

                  this.loadDevis();
              })
              .catch(() => {
                  this.$Progress.fail();
              });

          },
          deletData(id){
              Swal.fire({
                  title: 'Attention !',
                  text: "vous voulez vraiment supprimer ce devis",
                  showCancelButton: true,
                  confirmButtonColor: '#67c23a',
                  cancelButtonColor: '#f56c6c',
                  confirmButtonText: 'Oui, supprimez-le ! '
                  }).then((result) => {

                      // Send request to the server
                        if (result.value) {
                              this.form.delete('api/DevisFournisseur/'+id).then(()=>{
                                      Swal.fire(
                                      'Supprimé !',
                                      'Votre Devis a été bien supprimé.',
                                      'success'
                                      );
                                   this.loadDevis();
                              }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                        }
                  })
          },


            // End CRUD
            // Autre Functions

            // loadData = async () => {
            //     await axios.get("api/indexOne/"+fournisseurID).then(({ data }) => (this.Onefournisseurs = data.data));
            // },

            async getFournisseur(fournisseurID){ 
                this.$Progress.start();
                this.fullscreenLoading = true;
                 let response = await axios.get("api/indexOne/"+fournisseurID)
                this.Onefournisseurs = response.data.data;
                
                this.form.devise = response.data.data[0].Devise
                this.articlesFournis = response.data.data[0].get_articles
                this.fullscreenLoading = false;
                this.$Progress.finish();
            },

            async selectedArticle(article){ 
                this.$Progress.start();
                this.fullscreenLoading = true;
                 let response = await axios.get("api/indexOneArticle/"+article)
                this.OneArticle = response.data.data;
                
                this.Nomenclature = response.data.data[0].Nomenclature
                this.unite_mesure = response.data.data[0].unite_mesure
                this.sousCategori_article = response.data.data[0].sousCategori_article
                this.regime_economique = response.data.data[0].regime_economique
                this.categori_article = response.data.data[0].categori_article
                this.ArticlesForm.prix_unitaire = response.data.data[0].prix_unit
                  
                // 
                if(response.data.data[0].sousCategori_article == 'Papier' || response.data.data[0].sousCategori_article == 'P.P' || response.data.data[0].sousCategori_article == 'Fruit tag')
                    {
                        this.sousCategorieSelected = 'mp1'
                    }
                    else if(response.data.data[0].sousCategori_article == 'Mandrin en carton')
                    {
                        this.sousCategorieSelected = 'mp2'
                    }
                    else if(response.data.data[0].sousCategori_article == 'Ancres' || response.data.data[0].sousCategori_article == 'Assytat' || response.data.data[0].sousCategori_article == 'White Spirit' || response.data.data[0].sousCategori_article == 'Huile 46')
                    {
                        this.sousCategorieSelected = 'consomables'
                    }
                    else if(response.data.data[0].sousCategori_article == 'Papillotes')
                    {
                        this.sousCategorieSelected = 'march1'
                    }
                    else if(response.data.data[0].sousCategori_article == 'Cornière en carton')
                    {
                        this.sousCategorieSelected = 'march2'
                    }
                    else if(response.data.data[0].sousCategori_article == 'Filets tubulaires' || response.data.data[0].sousCategori_article == 'Filets de palitisation' || response.data.data[0].sousCategori_article == 'Filets cellulosiques')
                    {
                        this.sousCategorieSelected = 'march3'
                    }
                    else if(response.data.data[0].sousCategori_article == 'Feuillards en plastique' || response.data.data[0].sousCategori_article == 'Feuillards métaliques')
                    {
                        this.sousCategorieSelected = 'march4'
                    }
                    else
                    {
                        this.sousCategorieSelected = 'march5'
                    }

                // 
                // this.sousCategorieSelected = response.data.data[0].sousCategorieSelected
                this.fullscreenLoading = false;
                this.$Progress.finish();
            },

            ReceptionDevis(devis){

                // this.OneDevis = index;               
                // console.log(index)
                this.editmode = true;
                this.form.reset();
                this.DialogArticleVisible = true;
                this.active=0;
                this.form.fill(devis);
                this.form.ArticlesVendueF = [];
                
                for (let i = 0; i < devis.get_articles.length; i++) {
                    this.form.ArticlesVendueF.push({
                        id_article:devis.get_articles[i].id_article,
                        prix_unitaire:devis.get_articles[i].prix_unitaire,
                        prix_total:devis.get_articles[i].prix_total,
                        dimension:devis.get_articles[i].dimension,
                        NombreBobine:devis.get_articles[i].NombreBobine,
                        quantite_ml_bobine:devis.get_articles[i].quantite_ml_bobine,
                        laize_mm:devis.get_articles[i].laize_mm,
                        quantite_ml_total:devis.get_articles[i].quantite_ml_total,
                        quantite_m2_total:devis.get_articles[i].quantite_m2_total,
                        diametre:devis.get_articles[i].diametre,
                        nombre_mandrin_kg:devis.get_articles[i].nombre_mandrin_kg,
                        emballage:devis.get_articles[i].emballage,
                        qtit_pots:devis.get_articles[i].qtit_pots,
                        qtit_pots_total:devis.get_articles[i].qtit_pots_total,
                        laize_cm:devis.get_articles[i].laize_cm
                    })
                }
                
          },

           UpdatePrice(article){

                // this.OneDevis = index;
                // this.PricesForm.reset();
                // this.indexRow = index; PricesForm : new Form({ id_devis:'',id_article:'',price:''}),

                this.PricesForm.id_devis = article.id_devis,
                this.PricesForm.id_article = article.id_article,
                this.PricesForm.price = article.price,
               
                console.log(article)
                this.DialogPricesVisible = true;
          },

          ValiderModificationArticle(){

            //   this.formPrices.PricesArticles.push({
            //       id_devis:this.PricesForm.id_devis,
            //       id_article:this.PricesForm.id_article,
            //       price:this.PricesForm.price
            //   })
            this.form.ArticlesVendueF[this.indexRow].prix_unitaire = this.newPrice
            
            this.DialogPricesVisible = false;
            this.PricesForm.reset();
          },
          
        //   
        // SendNewPrices(){
        //       this.$Progress.start();

            

        //       this.formPrices.post('api/ReceptionPricesDevis')
        //       .then((data)=>{
        //         if(data.data.success){
        //           this.DialogArticleVisible = false;

        //           Toast.fire({
        //                 icon: 'success',
        //                 title: data.data.message
        //             });
        //             this.$Progress.finish();
        //             this.loadDevis();

        //         } else {
        //           Toast.fire({
        //               icon: 'error',
        //               title: 'Some error occured! Please try again'
        //           });

        //           this.$Progress.failed();
        //         }
        //       })
        //       .catch(()=>{

        //           Toast.fire({
        //               icon: 'error',
        //               title: 'Some error occured! Please try again'
        //           });
        //       })
        //   },

            

            next() {
                // if (this.active++ > 2) this.active = 0;
                this.active++;
            },

            prev() {
                // if (this.active-- > 0) this.active = 0;
                this.active--;
            },

            DateValiditeChange(){
                 const day1 =  180 * 24 * 3600 * 1000
                //  var x = this.form.dateEnregistrement.getTime()

                // console.log(this.form.dateEnregistrement)
                this.form.date_validite = this.form.date_enregistrement.getTime() + day1;

            },

            deleteRow(index, ArticlesVendueF) {
                var idx = this.form.ArticlesVendueF.indexOf(ArticlesVendueF);
                // console.log(idx, index);
                if (idx > -1) {
                    this.form.ArticlesVendueF.splice(idx, 1);
                }
            },


            addNewRow() {
                this.form.ArticlesVendueF.push(
                {
                   
                    id_article:this.ArticlesForm.id_article,
                    prix_unitaire:this.ArticlesForm.prix_unitaire,
                    prix_total:this.ArticlesForm.prix_total,
                    dimension:this.ArticlesForm.dimension,
                    NombreBobine:this.ArticlesForm.NombreBobine,
                    quantite_ml_bobine:this.ArticlesForm.quantite_ml_bobine,
                    laize_mm:this.ArticlesForm.laize_mm,
                    quantite_ml_total:this.ArticlesForm.quantite_ml_total,
                    quantite_m2_total:this.ArticlesForm.quantite_m2_total,
                    diametre:this.ArticlesForm.diametre,
                    nombre_mandrin_kg:this.ArticlesForm.nombre_mandrin_kg,
                    emballage:this.ArticlesForm.emballage,
                    qtit_pots:this.ArticlesForm.qtit_pots,
                    qtit_pots_total:this.ArticlesForm.qtit_pots_total,
                    laize_cm:this.ArticlesForm.laize_cm}
                )

                // console.log(this.form.ArticlesVendueF);
                this.Nomenclature = '',
                this.unite_mesure= '',
                this.sousCategorieSelected='',
                this.categori_article='',
                this.regime_economique='',
                this.sousCategori_article='',
                this.ArticlesForm.reset();
            },

            changeSelectCategorie () {

                    switch (this.formArticle.categorie) {
                        case 'Consommables':
                            this.Souscategorie = this.consommables;
                            
                            break;
                        case 'Matier Premier':
                            this.Souscategorie = this.matier_premier;
                           
                            break;
                        case 'Marchandise':
                            this.Souscategorie = this.marchandise;
                           
                            break;
                        }
                },
            changeSelectSousCategorie () {
                    if(this.formArticle.souscategorie == 'Papier' || this.formArticle.souscategorie == 'P.P' || this.formArticle.souscategorie == 'Fruit tag')
                    {
                        this.sousCategorieSelected = 'mp1'
                    }
                    else if(this.formArticle.souscategorie == 'Mandrin en carton')
                    {
                        this.sousCategorieSelected = 'mp2'
                    }
                    else if(this.formArticle.souscategorie == 'Ancres' || this.formArticle.souscategorie == 'Assytat' || this.formArticle.souscategorie == 'White Spirit' || this.formArticle.souscategorie == 'Huile 46')
                    {
                        this.sousCategorieSelected = 'consomables'
                    }
                    // else(this.formArticle.souscategorie == 'Cornière en Carton')
                    else
                    {
                        this.sousCategorieSelected = 'march1'
                    }
                    
                },

            regimeChange(){
                if(this.formArticle.regimeEconomique == '10'){
                    this.formArticle.regimeName = 'Mise a la consomation'
                }else if(this.formArticle.regimeEconomique == '22'){
                    this.formArticle.regimeName = 'Transformation en AT'
                }else if(this.formArticle.regimeEconomique == '37'){
                    this.formArticle.regimeName = 'Achat international en AT depôt privé'
                }else if(this.formArticle.regimeEconomique == '81'){
                    this.formArticle.regimeName = 'local AT depôt privé'
                }
            },

            removeDomain(item) {
                var index = this.form.domains.indexOf(item);
                if (index !== -1) {
                this.form.domains.splice(index, 1);
                }
            },
            addDomain() {
                this.form.domains.push({
                key: Date.now(),
                codeArticle: '',
                designation:'',
                uniteMesure:'',
                categorie:'',
                souscategorie:'',
                quantite:''
                });
            },

         
          
         
          editModal(product){
              this.editmode = true;
              this.form.reset();
              $('#addNew').modal('show');
              this.form.fill(product);
          },
          newModal(){
              this.editmode = false;
              this.form.reset();
              $('#addNew').modal('show');
          },
        //   dialog element
        handleClose(done) {
                this.$confirm('Voulez-vous vraiment fermer cette boîte de dialogue ?')
                .then(_ => {
                    done();
                })
                .catch(_ => {});
            },
          
        //   
        BonCMDModal(id){
              Swal.fire({
                  title: 'Attention !',
                  text: "vous voulez confirmer la commande ?",
                  showCancelButton: true,
                  confirmButtonColor: '#67c23a',
                  cancelButtonColor: '#f56c6c',
                  confirmButtonText: 'Oui, confirmer-le ! '
                  }).then((result) => {

                      // Send request to the server
                        if (result.value) {
                              this.form.delete('api/product/'+id).then(()=>{
                                      Swal.fire(
                                      'Valider !',
                                      'Votre Commande a été Confirmé.',
                                      'success'
                                      );
                                  // Fire.$emit('AfterCreate');
                                  this.loadProducts();
                              }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                        }
                  })
          },
        
         handleCurrentChange(cpage) {
                this.currpage = cpage;
            },
        handleSizeChange(psize) {
                this.pagesize = psize;
            },


        },
        mounted() {
            
        },
        created() {
            this.$Progress.start();

            this.loadDevis();
            this.loadFournisseurs();

            this.$Progress.finish();
        },
        filters: {
            truncate: function (text, length, suffix) {
                return text.substring(0, length) + suffix;
            },
        },
        computed: {
          filteredItems() {
            return this.autocompleteItems.filter(i => {
              return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
            });
          },
        },
    }
</script>
