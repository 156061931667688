<template>
    <section class="content">
      <div class="container-fluid">
        <el-tabs>
            <el-tab-pane label="Products">
                <el-card class="mb-3 mt-3">
                        <!-- Start Table -->
                        
                        <div class="row">
                            <div class="col-6">
                                <el-button type="primary" @click="newData()" size="small" icon="el-icon-plus" >Add New Product</el-button>
                            </div>
                            <div class="col-6">
                                <el-input v-model="search" @change="filterETC"  placeholder="Filter : "> 
                                    <el-button slot="append" icon="el-icon-search"></el-button>
                                </el-input>
                            </div>
                            <div class="col-12 mt-3">
                            <el-table
                                    :data="Products.data" border >
                                    
                                    <el-table-column label="Title">
                                        <template slot-scope="scope">
                                            <Strong>{{ scope.row.title }}</Strong>
                                        </template>
                                    </el-table-column>
                                    <el-table-column align="center" label="Price Fournisseur" width="150px">
                                        <template slot-scope="scope">
                                            <strong>{{ scope.row.duration }} DH</strong>
                                        </template>
                                    </el-table-column>
                                    <el-table-column width="100" align="center">
                                        <template slot-scope="{row}">
                                                <a href="#" @click="updateReservation(row)" data-toggle="tooltip" data-placement="top" title="Modifier">
                                                        <i class="fa fa-edit blue mr-2"></i>
                                                </a>
                                                <!-- <el-divider direction="vertical"></el-divider>
                                                <a href="#" @click="deletData(row.id)" data-toggle="tooltip" data-placement="top" title="Supprimer">
                                                    <i class="fa fa-trash red"></i>
                                                </a> -->
                                        </template>
                                    </el-table-column>
                            </el-table>
                            
                            <pagination class="mt-4" :data="Products" @pagination-change-page="loadData"></pagination>
                            </div>
                            
                        </div>
                        
                </el-card>
            </el-tab-pane>
            <el-tab-pane label="Statistics">
                <el-card class="mb-3 mt-3">
                <div class="row">
                    <div class="col-4">
                        <div class="row mb-3">
                            <!-- <div class="col-lg-6">
                                <el-select @change="loadSUMByMonth" v-model="month" style="width: 100%" placeholder="Filter By Month">
                                    <el-option label="Filter By Month" value=""></el-option>
                                    <el-option label="January" value="01"></el-option>
                                    <el-option label="February" value="02"></el-option>
                                    <el-option label="March" value="03"></el-option>
                                    <el-option label="April" value="04"></el-option>
                                    <el-option label="May" value="05"></el-option>
                                    <el-option label="June" value="06"></el-option>
                                    <el-option label="July" value="07"></el-option>
                                    <el-option label="August" value="08"></el-option>
                                    <el-option label="September" value="09"></el-option>
                                    <el-option label="October" value="10"></el-option>
                                    <el-option label="November" value="11"></el-option>
                                    <el-option label="December" value="12"></el-option>
                                </el-select>
                            </div> -->
                            <div class="col-lg-12">
                                <el-date-picker
                                    v-model="RangeDate"
                                    @change="filterDate"
                                    type="datetimerange"
                                    value-format="yyyy-MM-dd"
                                    range-separator="To"
                                    start-placeholder="Start date"
                                    end-placeholder="End date">
                                </el-date-picker>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-3">
                    <el-table
                            :data="RapProductPerson" border >
                            <el-table-column align="center" label="Product" width="350px">
                                <template slot-scope="scope">
                                    <strong>{{ scope.row.get_product.title }} {{'(' +scope.row.TotalTravel+ ')'}}</strong>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="Total Adults">
                                <template slot-scope="scope">
                                    <strong>{{ scope.row.TotalAdults }}</strong>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="Total Children">
                                <template slot-scope="scope">
                                    <strong>{{ scope.row.TotalChildren }}</strong>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="Total Persons">
                                <template slot-scope="scope">
                                    <strong>{{ scope.row.TotalPersons }}</strong>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="Fournisseur Price">
                                <template slot-scope="scope">
                                    <strong>{{ scope.row.get_product.duration }} DH</strong>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="Total">
                                <template slot-scope="scope">
                                    <strong class="my-1 text-danger">{{scope.row.TotalPersons*scope.row.get_product.duration }} DH</strong>
                                </template>
                            </el-table-column>
                            <!-- <el-table-column align="center" label="Selling Total">
                                <template slot-scope="scope">
                                    <strong class="my-1 text-success">{{ scope.row.SumPriceNet | FormNumbers2 }} {{ scope.row.currency }} </strong>
                                </template>
                            </el-table-column> -->
                    </el-table>
                    
                    <pagination class="mt-4" :data="RapProductPerson" @pagination-change-page="loadTotalPerso"></pagination>
                    </div>
                    <div class="col-12 mt-3">
                    <el-table
                            :data="RapProductPersonWhats" border >
                            <el-table-column align="center" label="Product" width="350px">
                                <template slot-scope="scope">
                                    <strong>{{ scope.row.get_product.title }} {{'(' +scope.row.TotalTravel+ ')'}}</strong>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="Total Adults">
                                <template slot-scope="scope">
                                    <strong>{{ scope.row.TotalAdults }}</strong>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="Total Children">
                                <template slot-scope="scope">
                                    <strong>{{ scope.row.TotalChildren }}</strong>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="Total Persons">
                                <template slot-scope="scope">
                                    <strong>{{ scope.row.TotalPersons }}</strong>
                                </template>
                            </el-table-column>
                            <!-- <el-table-column align="center" label="Fournisseur Price">
                                <template slot-scope="scope">
                                    <strong>{{ scope.row.get_product.duration }} DH</strong>
                                </template>
                            </el-table-column> -->
                            <el-table-column align="center" label="Total">
                                <template slot-scope="scope">
                                    <strong class="my-1 text-success">{{scope.row.SumPriceNet}} DH</strong>
                                </template>
                            </el-table-column>
                            <!-- <el-table-column align="center" label="Selling Total">
                                <template slot-scope="scope">
                                    <strong class="my-1 text-success">{{ scope.row.SumPriceNet | FormNumbers2 }} {{ scope.row.currency }} </strong>
                                </template>
                            </el-table-column> -->
                    </el-table>
                    
                    <pagination class="mt-4" :data="RapProductPersonWhats" @pagination-change-page="loadTotalPerso"></pagination>
                    </div>
                    
                </div>
                </el-card>
            </el-tab-pane>
        </el-tabs>
        
        <!-- End Table -->
          
          <!-- dialog -->
          <el-dialog
            :title="editmode ? 'Update Product':'New Product'"
            :visible.sync="DialogVisible_global"
            width="70%"
            :before-close="handleClose">
            <!-- Body dialog -->
                <el-form :model="form_global" ref="form_global" class="demo-ruleForm">
                    <fieldset class="row col-12 border p-2 mt-2">
                        <legend  class="w-auto badge"><h5>Product Infos</h5></legend>
                            <el-form-item class="col-lg-9" label="Title" prop="title" 
                            :rules="[
                                { required: true, message: 'Product Title is required' }
                            ]">
                                <el-input v-model="form_global.title" autocomplete="off"></el-input>
                            </el-form-item>
                            <el-form-item class="col-lg-3" label="Price Fournisseur" prop="duration" 
                                :rules="[{ required: true, message: 'Price Fournisseur is required' }]">
                                <el-input type="number" v-model="form_global.duration" autocomplete="off">
                                    <template slot="append"><strong>DH</strong></template>
                                </el-input>
                            </el-form-item>
                            <!-- <el-form-item class="col-lg-3" label="Duration" prop="duration" 
                            :rules="[
                                { required: true, message: 'Product Duration is required' }
                            ]">
                                <el-input type="text" v-model="form_global.duration" autocomplete="off"></el-input>
                            </el-form-item> -->
                    </fieldset>
                </el-form>
                <!-- ENd body Dialog -->
                <span slot="footer" class="dialog-footer">
                    <el-button @click="DialogVisible_global = false">Annuler</el-button>
                    <el-button type="primary" v-show="!editmode" @click="submitForm('form_global')">Valider</el-button>
                    <el-button type="primary" v-show="editmode" @click="updateData()">Valider</el-button>
                </span>
          </el-dialog>

          <!-- ENd dialog -->
  
      </div>
     
    </section>
    <!--  -->
  </template>
  
  
  <script>
      import VueTagsInput from '@johmun/vue-tags-input';
      import moment from 'moment'
      import numeral from 'numeral'
  
      export default {
        components: {
            VueTagsInput,
          },
          data () {
              return {
                  DialogVisible_global: false,
                  active: 0,
                  Products: [],
                  RapProductPerson: [],
                  RapProductPersonWhats: [],
                  search: '',
                  pagesize: 10,
                  currpage: 1,
                  editmode: false,
                  products : [],

                  RangeDate: '',
                  Date1: '',
                  Date2: '',
                  numberValidateForm: {
                    age: ''
                },
                
                  form_global: new Form({
                    id:'',
                    title:'',
                    duration:'',
                  }),

                  form: new Form({
                      id : '',
                      category : '',
                      name: '',
                      description: '',
                      tags:  [],
                      photo: '',
                      category_id: '',
                      price: '',
                      photoUrl: '',
                  }),
                  categories: [],
                
                  tag:  '',
                  autocompleteItems: [],
              }
          },
          methods: {            

            filterETC(){
                if(this.search != ''){
                    this.$Progress.start();
                    axios.get('api/filterProducts/' + this.search).then(({ data }) => (this.Products = data.data));
                    this.$Progress.finish();
                }else{
                    this.loadData();
                }
            },

            filterDate(){
                
                if(this.RangeDate == null){
                    this.loadTotalPerso();
                    this.RapProductPersonW();
                }else{

                    this.Date1 = this.RangeDate[0]
                    this.Date2 = this.RangeDate[1]

                    this.$Progress.start();
                    axios.get('api/RapProdPersoByPlat/'+this.Date1+'/'+this.Date2).then(({ data }) => (this.RapProductPerson = data.data));
                    axios.get('api/RapProdPersoByWhats/'+this.Date1+'/'+this.Date2).then(({ data }) => (this.RapProductPersonWhats = data.data));
                    this.$Progress.finish();
                }
            },

            // CRUD
            newData(){
                this.form_global.reset();
                this.editmode = false;
                // this.disableDevisPV = false;
                this.DialogVisible_global = true;
                this.active=0;
            },

            createData(){
            this.$Progress.start();

              this.form_global.post('api/tours')
              .then((data)=>{
                if(data.data.success){
                 this.DialogVisible_global = false

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.$Progress.finish();
                  this.loadData();
                //   this.loadDataPrepared();

                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          },

          submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.createData();
                } else {
                    console.log('error submit!!');
                    return false;
                }
                });
            },

            loadData(page = 1){
                this.$Progress.start();
                axios.get('api/tours?page=' + page).then(({ data }) => (this.Products = data.data));
                this.$Progress.finish();
            },

            loadTotalPerso(){
                this.$Progress.start();
                axios.get('api/RapProductPerson').then(({ data }) => (this.RapProductPerson = data.data));
                this.$Progress.finish();
            },

            RapProductPersonW(){
                this.$Progress.start();
                axios.get('api/RapProductPersonW').then(({ data }) => (this.RapProductPersonWhats = data.data));
                this.$Progress.finish();
            },

            // loadSUMByMonth(){
                
            //     if(this.month != ""){
            //         this.$Progress.start();
            //         axios.get('api/RapProdPersoByMonth/' + this.month).then(({ data }) => (this.RapProductPerson = data.data));
            //         this.$Progress.finish();
                    
            //     }else{
            //         this.loadTotalPerso();
            //     }
            // },

            updateReservation(reservation){
                this.editmode = true;
                this.form_global.reset();
                this.DialogVisible_global = true;
                this.form_global.fill(reservation);
            },

            updateData(){
              this.$Progress.start();
              this.form_global.put('api/tours/'+this.form_global.id)
              .then((response) => {
                  // success
                  this.DialogVisible_global = false
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });
                  this.$Progress.finish();

                  this.loadData();
              })
              .catch(() => {
                  this.$Progress.fail();
              });

          },

          deletData(id){
              Swal.fire({
                  title: 'Attention !',
                  text: "You really want to delete this Product ??",
                  showCancelButton: true,
                  confirmButtonColor: '#67c23a',
                  cancelButtonColor: '#f56c6c',
                  confirmButtonText: 'Yes, Delete-it ! '
                  }).then((result) => {

                      // Send request to the server
                        if (result.value) {
                              this.form_global.delete('api/tours/'+id).then(()=>{
                                      Swal.fire(
                                      'Deleted !',
                                      'Product Delete Successfully',
                                      'success'
                                      );
                                  // Fire.$emit('AfterCreate');
                                  this.loadData();
                              }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                        }
                  })
          },
        //   End CRUD

            handleClose(done) {
                this.$confirm('Voulez-vous vraiment fermer cette boîte de dialogue ?')
                .then(_ => {
                    done();
                })
                .catch(_ => {});
            },

            handleCurrentChange2(val) {
                this.currentRow = val;
            },

            // END
  
           
            loadProducts(){
  
              // if(this.$gate.isAdmin()){
                axios.get("api/product").then(({ data }) => (this.products = data.data));
              // }
            },
            loadCategories(){
                axios.get("/api/category/list").then(({ data }) => (this.categories = data.data));
            },
            loadTags(){
                axios.get("/api/tag/list").then(response => {
                    this.autocompleteItems = response.data.data.map(a => {
                        return { text: a.name, id: a.id };
                    });
                }).catch(() => console.warn('Oh. Something went wrong'));
            },
            editModal(product){
                this.editmode = true;
                this.form.reset();
                $('#addNew').modal('show');
                this.form.fill(product);
            },
            newModal(){
                this.editmode = false;
                this.form.reset();
                $('#addNew').modal('show');
            },
            createProduct(){
                this.$Progress.start();
  
                this.form.post('api/product')
                .then((data)=>{
                  if(data.data.success){
                    $('#addNew').modal('hide');
  
                    Toast.fire({
                          icon: 'success',
                          title: data.data.message
                      });
                    this.$Progress.finish();
                    this.loadProducts();
  
                  } else {
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
  
                    this.$Progress.failed();
                  }
                })
                .catch(()=>{
  
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
                })
            },
            updateProduct(){
                this.$Progress.start();
                this.form.put('api/product/'+this.form.id)
                .then((response) => {
                    // success
                    $('#addNew').modal('hide');
                    Toast.fire({
                      icon: 'success',
                      title: response.data.message
                    });
                    this.$Progress.finish();
                        //  Fire.$emit('AfterCreate');
  
                    this.loadProducts();
                })
                .catch(() => {
                    this.$Progress.fail();
                });
  
            },
            deletModal(id){
                Swal.fire({
                    title: 'Attention !',
                    text: "vous voulez vraiment supprimer ce devis",
                    showCancelButton: true,
                    confirmButtonColor: '#67c23a',
                    cancelButtonColor: '#f56c6c',
                    confirmButtonText: 'Oui, supprimez-le ! '
                    }).then((result) => {
  
                        // Send request to the server
                          if (result.value) {
                                this.form.delete('api/product/'+id).then(()=>{
                                        Swal.fire(
                                        'Supprimé !',
                                        'Votre fichier a été supprimé.',
                                        'success'
                                        );
                                    // Fire.$emit('AfterCreate');
                                    this.loadProducts();
                                }).catch((data)=> {
                                    Swal.fire("Failed!", data.message, "warning");
                                });
                          }
                    })
            },
          
           handleCurrentChange(cpage) {
                  this.currpage = cpage;
              },
          handleSizeChange(psize) {
                  this.pagesize = psize;
              },
  
  
          },
          mounted() {
              
          },
          created() {
              this.$Progress.start();
  
                this.loadData();
                this.loadTotalPerso();
                this.RapProductPersonW();
  
              this.$Progress.finish();
          },
          filters: {
            OfferDates(value){
                return moment(String(value)).format('MM-DD-YY')
            },
            FormNumbers2(value){
                  return numeral(value).format("0.00");
              },
              truncate: function (text, length, suffix) {
                  return text.substring(0, length) + suffix;
              },
          },
          computed: {
            filteredItems() {
              return this.autocompleteItems.filter(i => {
                return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
              });
            },
          },
      }
  </script>
  