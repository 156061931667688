<template>
    <section class="content">

      <div class="container-fluid">
          <!--  -->
            <el-tabs tab-position="top" label="Plant De Forme De Decoupe" style="margin-bottom: 30px;">
                <el-tab-pane label="PLAQUES A COMMANDER">
                
                    <el-card class="mb-3 mt-3">
                    <div class="row">
                    <div class="col-6">
                        <el-button  type="primary" @click="lancerDemandeDevis()" size="small" icon="el-icon-refresh" >Demande De Devis (PFD)</el-button>
                    </div>
                        <div class="col-6">
                            <el-input v-model="search" @change="filterETC" size="mini" placeholder="Filter : "> 
                                <el-button slot="append" icon="el-icon-search"></el-button>
                            </el-input>
                        </div>
                        <div class="col-12 mt-3">
                            <el-table border :data="devisGlobal.data" ref="multipleTable"  style="width: 100%"  @selection-change="handleSelectionChange">
                                <el-table-column type="selection" width="55"></el-table-column>
                                <el-table-column label="Client" width="250">
                                    <template slot-scope="scope">
                                        <span>{{ scope.row.get_client.name }}</span>
                                    </template>
                                </el-table-column>
                                
                                <el-table-column label="Nom D'étiquette">
                                    <template slot-scope="scope">
                                        <span>{{ scope.row.get_devis_e_t_c.nom_etc }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Etat" width="200">
                                    <template slot-scope="scope">
                                            <el-tag effect="dark">
                                                {{ scope.row.etat_projet }}
                                            </el-tag>
                                    </template>
                                </el-table-column>
                                <el-table-column width="50" align="center">
                                    <template slot-scope="{row}">
                                        <router-link  :to="`/ProfileFactureDevis/${row.id}`" data-toggle="tooltip" data-placement="top" title="Profile">
                                                <i class="fa fa-eye green"></i>
                                        </router-link>
                                    </template>
                                </el-table-column>
                            </el-table>
                        <!--  -->
                        <pagination class="mt-4" :data="devisGlobal" @pagination-change-page="loadDevisGlobal"></pagination>
                        </div>
                        <!-- table Global -->
                    </div>
                    </el-card>
                </el-tab-pane>
                <el-tab-pane label="DEMANDES DE PRIX (PFD)">

                    <el-card class="mb-3 mt-3">
                    <div class="row">
                    <div class="col-6">
                        <!-- <el-button  type="primary" @click="lancerDemandeDevis()" size="small" icon="el-icon-refresh" >Demande De Devis (PFD)</el-button> -->
                    </div>
                        <div class="col-6">
                            <el-input v-model="search" @change="filterETC" size="mini" placeholder="Filter : "> 
                                <el-button slot="append" icon="el-icon-search"></el-button>
                            </el-input>
                        </div>
                        <div class="col-12 mt-3">
                            <el-table border :data="Domandedevis"   style="width: 100%"  @selection-change="handleSelectionChange">
                                <!-- <el-table-column type="selection" width="55"></el-table-column> -->
                                <el-table-column type="expand">
                                    <template  slot-scope="scope" >
                                        <el-table :data="scope.row.get_plaques">
                                            <el-table-column label="Réf" width="50">
                                                <template slot-scope="scope">
                                                    <span>{{ scope.row.id }}</span>
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="Nom D'étiquette" >
                                                <template slot-scope="scope">
                                                    <span>{{ scope.row.get_e_t_c.nom_etc }}</span>
                                                </template>
                                            </el-table-column>
                                        </el-table>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Réf" width="50">
                                    <template slot-scope="scope" >
                                        <span>{{ scope.row.id }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Fournisseur" width="150">
                                    <template slot-scope="scope">
                                        <span>{{ scope.row.get_fournisseur.name }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Type De Demande">
                                    <template slot-scope="scope" >
                                            <el-tag v-if='scope.row.type_de_demande == 1' type="info" effect="dark">
                                                <span span > DEMANDE DE PRIX DES PLAQUES MAGNETIQUE </span>
                                            </el-tag>
                                            <el-tag v-else-if='scope.row.type_de_demande == 2' type="warning" effect="dark">
                                                <span > DEMANDE DE REDUCTION DE L'OFFRE DE PRIX DES PLAQUES MAGNETIQUE </span>
                                            </el-tag>
                                            <el-tag v-else-if='scope.row.type_de_demande == 3' type="success" effect="dark">
                                                <span > COMMANDE VALIDER </span>
                                            </el-tag>
                                            <el-tag v-else type="" effect="dark">
                                                <span> {{scope.row.type_de_demande}} </span>
                                            </el-tag>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Date" align="right" width="150">
                                    <template slot-scope="scope">
                                        <span>{{ scope.row.created_at | RemarqueDates }}</span>
                                    </template>
                                </el-table-column>
                                
                                <el-table-column label="Détails" width="100" align="center">
                                    <template slot-scope="{row}">
                                        <!-- <a href="#" @click="printC(row.id)" data-toggle="tooltip" data-placement="top" title="Imprimer Demande De Prix">
                                            <i class="fa fa-file-pdf orange "></i>
                                        </a> -->
                                        <router-link  :to="`/ProfileFactureDevis/${row.id}`" data-toggle="tooltip" data-placement="top" title="Profile">
                                            <i class="fa fa-eye green"></i>
                                    </router-link>
                                    </template>
                                </el-table-column>
                            </el-table>
                        
                        <pagination class="mt-4" :data="Domandedevis" @pagination-change-page="loadDomandeDevis"></pagination>
                        </div>
                        <!-- table Global -->
                    </div>
                    </el-card>
                </el-tab-pane>
                <!-- <el-tab-pane label="BONS DE COMMANDE">

                </el-tab-pane> -->
            </el-tabs>
          <!-- Demande Devis Plaque -->
        <el-dialog
            :title="'Demande Devis Pour Nouvelle Plaque'"
            :visible.sync="DialogVisibleDevisPlaques"
            width="70%"
            :before-close="handleClose">
            <!-- Body dialog -->
            <fieldset class="border p-2 mt-2">
                <legend class="w-auto badge"><h5>LISTE DES PLAQUE SELECTIONNER</h5></legend>
                    
                    <div class="mt-5" v-for="(c, k) in multipleSelection" :key="k">
                        
                        <el-descriptions  :column="2" size="medium" border>
                                        <el-descriptions-item>
                                            <template slot="label"><strong>Client :</strong></template>
                                            <el-tag type="" effect="plain">
                                                <strong>{{ c.get_client.name}}</strong>
                                            </el-tag>
                                        </el-descriptions-item>
                                        <el-descriptions-item>
                                            <template slot="label"><strong>Pour Etiquette :</strong></template>
                                            <el-tag type="" effect="plain">
                                                <strong>{{ c.get_devis_e_t_c.nom_etc}}</strong>
                                            </el-tag>
                                        </el-descriptions-item>
                                        
                                    </el-descriptions>
                            
                    </div>
            </fieldset>
            <el-form :model="formDevisPlaque">
                
                <fieldset class="border p-2 mt-2">
                    <legend class="w-auto badge"><h5>CHOISIR LE FOURNISSEUR</h5></legend>
                        
                        <el-form-item 
                            prop="id_fournisseur" 
                        >
                            <!-- <el-select v-model="formDevisPlaque.id_fournisseur" v-loading.fullscreen.lock="fullscreenLoading" @change="getFournisseur(formDevisPlaque.id_fournisseur)" style="width: 100%" placeholder="CHOISIR UN FOURNISSEUR"> -->
                            <el-select v-model="formDevisPlaque.id_fournisseur"  style="width: 100%" placeholder="CHOISIR UN FOURNISSEUR">
                                <el-option
                                    v-for="item in fournisseurs"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                        
                    
                </fieldset>
            </el-form>
            <!-- show on edit mode -->
            <span slot="footer"  class="dialog-footer">
                <el-button @click="DialogVisibleDevisPlaques = false">Annuler</el-button>
                <el-button type="primary" @click="createDevisPlaque()">Valider</el-button>
            </span>
        </el-dialog>
        <!-- End Modal -->
          <!-- Modal files -->
          <el-dialog
                title="Ajouter Bon De Commande"
                :visible.sync="DialogVisibleF"
                center>

                <div style="max-width: 500px; margin: 0 auto;">
                            
                            <form
                                @submit="submitForm"
                                class="mt-4"
                                enctype="multipart/form-data"
                            >
                                <div class="input-group">
                                    <div class="custom-file">
                                        <input
                                            type="file"
                                            name="filename"
                                            class="custom-file-input"
                                            id="inputFileUpload"
                                            v-on:change="onFileChange"
                                        />
                                        <label
                                            class="custom-file-label"
                                            for="inputFileUpload"
                                            >Séléctionner Bon De Commande</label
                                        >
                                    </div>
                                    
                                </div>
                                <br />
                                <p class="text-success font-weight-bold">
                                    {{ filename }}
                                </p>
                            
                        <span slot="footer" class="dialog-footer">
                            <el-button type="warning" @click="DialogVisibleF = false">Annuler</el-button>
                            <input  type="submit" class="btn btn-success" value="Enregistrer"  />
                        </span>
                    </form>
                </div>
            </el-dialog>
            <!-- end dialog file -->
          <!-- dialog lancer projet -->
          <el-dialog
              :title="editmode ? 'Lancer Les Projets':'Modifier'"
              :visible.sync="DialogVisible_ProjetLancer"
              width="90%"
              :before-close="handleClose">
              <!-- Body dialog -->
                  <el-form :model="form_projetLancer">
                      <div>
                          <fieldset class="border p-2 col-lg-12 mt-3">
                              <legend class="w-auto badge"><h5> </h5></legend>

                                <fieldset class="border p-2 mt-2">
                                    <legend class="w-auto badge"><h5>Façon de lancer la commande</h5></legend>
                                    <el-descriptions class="margin-top" :column="1" border>
                                            <el-descriptions-item>
                                                <template slot="label"><i class="el-icon-check"></i> Méthode De Lancement  </template>
                                                <el-tag type="danger" effect="dark">
                                                    <strong>{{typeLancementCMD}}</strong>
                                                </el-tag>
                                            </el-descriptions-item>
                                    </el-descriptions>
                                    <el-descriptions v-if="typeLancementCMD == 'Obligation Bon de commande'" class="margin-top" :column="2" border>
                                        <el-descriptions-item v-if="typeLancementCMD == 'Obligation Bon de commande' && filename != ''">
                                            <template slot="label"> <i class="el-icon-document"></i> </template>
                                            <strong>
                                                    {{filename}}
                                            </strong>
                                        </el-descriptions-item>    
                                        <el-descriptions-item>
                                            <template slot="label"><i class="el-icon-upload"></i> </template>
                                            <el-button
                                                type="primary"
                                                class="float-right"
                                                icon="el-icon-plus"
                                                @click="newFile()"
                                                size="small"
                                            >
                                                Ajouter Bon De Commande
                                            </el-button>
                                        </el-descriptions-item>
                                            
                                    </el-descriptions>
                                    <!-- par telephone -->
                                    <el-descriptions v-if="typeLancementCMD == 'Par telephone'" class="margin-top" :column="3" border>
                                        <el-descriptions-item>
                                            <template slot="label"> <i class="el-icon-user"></i> Nom Prénom </template>
                                            <strong>
                                                    {{RLancementCMDName}}
                                            </strong>
                                        </el-descriptions-item> 
                                        <el-descriptions-item>
                                            <template slot="label"> <i class="el-icon-message"></i> E-Mail </template>
                                            <strong>
                                                    {{RLancementCMDemail}}
                                            </strong>
                                        </el-descriptions-item> 
                                        <el-descriptions-item>
                                            <template slot="label"> <i class="el-icon-mobile"></i> N° Télephone </template>
                                            <strong>
                                                    {{RLancementCMDtele}}
                                            </strong>
                                        </el-descriptions-item>    

                                    </el-descriptions>
                                </fieldset>
                                <fieldset class="border p-2 mt-2">
                                    <legend class="w-auto badge"><h5>Liste des Articles</h5></legend>
                                        <!-- {{multipleSelection}} -->
                                        <div class="mt-5" v-for="(c, k) in multipleSelection" :key="k">
                                            <el-descriptions class="margin-top"  :title="'ARTICLE N° : '+(k+1)+' - '+c.nom_etc" direction="vertical" :column="3" border>
                                            <el-descriptions-item :content-style="{'text-align': 'center'}">
                                                <template  slot="label"><strong> QUANTITÉ DEVIS</strong> </template>
                                                <el-tag effect="dark">{{c.quantite_etq  | FormNumbers2}}</el-tag>
                                            </el-descriptions-item>
                                            <el-descriptions-item :content-style="{'text-align': 'center'}">
                                                <template  slot="label"><strong> QUANTITÉ DEJA LANCER</strong> </template>
                                                <el-tag type="success" effect="dark">{{c.quantite_etq_lancer  | FormNumbers2}}</el-tag>
                                            </el-descriptions-item>
                                            <el-descriptions-item :content-style="{'text-align': 'center'}">
                                                <template  slot="label"><strong> QUANTITÉ RESTANT </strong></template>
                                                <el-tag type="danger" effect="dark">{{(c.quantite_etq-c.quantite_etq_lancer)  | FormNumbers2}}</el-tag>
                                            </el-descriptions-item>
                                            <el-descriptions-item :content-style="{'text-align': 'center'}">
                                                <template  slot="label">
                                                    <strong>QUANTITÉ A LANCER</strong>
                                                </template>
                                                <el-input  v-model="c.qtit_lancer" type="number" :min=0 ></el-input>
                                            </el-descriptions-item>
                                            <el-descriptions-item :content-style="{'text-align': 'center'}">
                                                <template slot="label">
                                                    <strong>DATE DE LIVRAISON</strong>
                                                </template>
                                                <el-date-picker v-model="c.date_livraison" format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm" type="datetime" placeholder="Select Date De Livraison"></el-date-picker>
                                            </el-descriptions-item>
                                            <el-descriptions-item :content-style="{'text-align': 'center'}">
                                                <template slot="label">
                                                    <strong>LIEU DE LIVRAISON</strong>
                                                </template>
                                                <el-input  v-model="c.lieu_livraison"></el-input>
                                            </el-descriptions-item>
                                            <el-descriptions-item :span="3" :content-style="{'text-align': 'center'}">
                                                <template slot="label">
                                                    <strong>URGENCE ?</strong>
                                                </template>
                                                <el-radio-group v-model="c.urgence">
                                                    <el-radio label="OUI" ></el-radio>
                                                    <el-radio label="NON" ></el-radio>
                                                </el-radio-group>
                                            </el-descriptions-item>
                                            <el-descriptions-item v-if="c.urgence == 'OUI'" :content-style="{'text-align': 'center'}">
                                                <template slot="label">
                                                    <strong>QUANTITÉ URGENT</strong>
                                                </template>
                                                <el-input  v-model="c.qtit_urgence" type="number"></el-input>
                                            </el-descriptions-item>
                                            <el-descriptions-item v-if="c.urgence == 'OUI'" :content-style="{'text-align': 'center'}">
                                                <template slot="label">
                                                    <strong>DATE DE LIVRAISON</strong>
                                                </template>
                                                <el-date-picker v-model="c.date_livraison_urgence" format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm" type="datetime" placeholder="Select Date De Livraison"></el-date-picker>
                                            </el-descriptions-item>
                                            <el-descriptions-item v-if="c.urgence == 'OUI'" :content-style="{'text-align': 'center'}">
                                                <template slot="label">
                                                    <strong>LIEU DE LIVRAISON</strong>
                                                </template>
                                                <el-input type="text"  v-model="c.lieu_livraison_urgence"></el-input>
                                            </el-descriptions-item>
                                            <el-descriptions-item :content-style="{'text-align': 'center'}">
                                                <template slot="label">
                                                    <strong>REMARQUES</strong>
                                                </template>
                                                <el-input type="textarea" :autosize="{ minRows: 3}" v-model="c.remarque"></el-input>
                                            </el-descriptions-item>
                                        </el-descriptions>
                                        </div>
                                </fieldset>
                          </fieldset>

                      </div>
                  </el-form>
              <!-- ENd body Dialog -->
              <span slot="footer" v-show="editmode"  class="dialog-footer">
                  <el-button @click="CloseDataLancer()">Annuler</el-button>
                  <el-button type="primary" v-show="editmode" @click="createDataLancer()">Lancer Projets</el-button>
              </span>
          </el-dialog>
          
          <!-- dialog devis_Global-->
          <el-dialog
              :title="editmode ? 'MODIFICATION DE DEVIS':'PREPARATION DE NOUVEAU DEVIS ETIQUETTES'"
              :visible.sync="DialogVisible_global"
              width="90%"
              :before-close="handleClose">
              <!-- Body dialog -->
                  <el-form :model="form_global">
                      
                      <div>
                          <fieldset class="border p-2 col-lg-12 mt-3" v-if="active === 0">
                              <legend class="w-auto badge"><h5> </h5></legend>

                              
                              <fieldset v-show="editmode" class="border p-2 mt-2">
                                      <legend class="w-auto badge"><h5>L'état de devis</h5></legend>
                                      <el-form-item 
                                          class="text-center"
                                          prop="type_ISE"
                                      >
                                          <el-radio-group v-model="form_global.Etat_devis">
                                              <el-radio label="A préparer" ></el-radio>
                                              <el-radio label="A Valider" ></el-radio>
                                              <el-radio label="Valider" ></el-radio>
                                          </el-radio-group>
                                      </el-form-item>
                              </fieldset>
                          </fieldset>

                      </div>
                  </el-form>
              <!-- ENd body Dialog -->
              <span slot="footer" v-show="editmode"  class="dialog-footer">
                  <el-button @click="DialogVisible_global = false">Annuler</el-button>
                  <el-button type="primary" v-show="editmode" @click="updateGlobalData()">Valider</el-button>
              </span>
          </el-dialog>
          <!-- End dialog_Global -->
          <!-- dialog devis-->
          <el-dialog
              :title="editmode ? 'MODIFICATION DE DEVIS ETIQUETTES':'PREPARATION DE NOUVEAU DEVIS ETIQUETTES'"
              :visible.sync="DialogVisible"
              width="90%"
              :before-close="handleClose">
              <!-- Body dialog -->
              
                  <el-form :model="form">
                      <div>
                          <fieldset class="border p-2 mt-2">
                                  <legend class="w-auto badge"><h5></h5></legend>
                                  <div class="row">
                                      <el-form-item 
                                          class="col-lg-4" 
                                          prop="quantite_etq" 
                                          label="QUANTITE UNITAIRE"
                                      >
                                          <el-input type="number" :disabled=true v-model="form.quantite_etq" autocomplete="off"></el-input>
                                      </el-form-item>
                                      <el-form-item 
                                          class="col-lg-4" 
                                          prop="quantite_etq_lancer" 
                                          label="QUANTITE A LANCER"
                                      >
                                          <el-input type="number" v-model="form.quantite_etq_lancer" autocomplete="off"></el-input>
                                      </el-form-item>
                                  </div>
                          </fieldset>
                      </div>
                      
                  </el-form>
              <!-- ENd body Dialog -->
              <span slot="footer" class="dialog-footer">
                  <el-button @click="DialogVisible = false">Cancel</el-button>
                  <el-button type="primary" v-show="!editmode" @click="createData()">Ajouter</el-button>
                  <el-button type="primary" v-show="editmode" @click="updateData()">Valider</el-button>
              </span>
          </el-dialog>
          <!-- End dialog -->
          <!-- dialog Preparation -->
          <el-dialog
              title="Préparation Devis"
              :visible.sync="DialogVisibleP"
              width="90%"
              :before-close="handleClose">
              <!-- Body dialog -->
                      <fieldset class="border p-2 mt-2">
                              <legend class="w-auto badge"><h5>INFORMATIONS DE DEVIS</h5></legend>
                               <el-descriptions  border>
                                  <el-descriptions-item><template slot="label"><strong>Nom D'étiquette :</strong></template> {{form.nom_etc}}</el-descriptions-item>
                                  <!-- <el-descriptions-item ><template slot="label"><strong>Client :</strong></template> {{form.id_client}}</el-descriptions-item> -->
                                  <el-descriptions-item><template slot="label"><strong>Format en mm :</strong></template> {{form.formatMM_L}}x{{form.formatMM_D}} </el-descriptions-item>
                                  <el-descriptions-item><template slot="label"><strong>Form d'étiquette :</strong></template> {{form.forme_etq}} </el-descriptions-item>
                                  <el-descriptions-item><template slot="label"><strong>Support :</strong></template> {{form.support_papier}} </el-descriptions-item>
                                  
                                  <el-descriptions-item><template slot="label"><strong>Impression :</strong></template> {{form.impression}}</el-descriptions-item>
                                  <el-descriptions-item><template slot="label"><strong>Nombre Couleurs :</strong></template>{{form.nbr_clouleur}} </el-descriptions-item>
                                  <el-descriptions-item><template slot="label"><strong>Quantité :</strong></template> {{form.quantite_etq}} </el-descriptions-item>
                                  <el-descriptions-item><template slot="label"><strong>Recto :</strong></template> {{form.recto}} </el-descriptions-item>
                                  <el-descriptions-item><template slot="label"><strong>Verso :</strong></template> {{form.verso}} </el-descriptions-item>
  
                                  <el-descriptions-item><template slot="label"><strong>Repiquage :</strong></template> {{form.repiquage}} </el-descriptions-item>
                                  <el-descriptions-item><template slot="label"><strong>Vernis :</strong></template> {{form.vernis}} </el-descriptions-item>
                                  <el-descriptions-item><template slot="label"><strong>Plastification :</strong></template> {{form.plastification}} </el-descriptions-item>
                                  <el-descriptions-item><template slot="label"><strong>Dorure :</strong></template> {{form.dorure}} </el-descriptions-item>
                                  <el-descriptions-item><template slot="label"><strong>Mandrin :</strong></template> {{form.mandrin}} </el-descriptions-item>
  
                                  <el-descriptions-item><template slot="label"><strong>Nombre Etq/Front :</strong></template> {{form.nbr_etq_front}} </el-descriptions-item>
                                  <el-descriptions-item><template slot="label"><strong>Nombre Etq/Bobine :</strong></template> {{form.nbr_etq_bobine}} </el-descriptions-item>
                                  <el-descriptions-item><template slot="label"><strong>Poses Etiquette :</strong></template> {{form.poses_etq}} </el-descriptions-item>
                                  <el-descriptions-item><template slot="label"><strong>Form De Decoupe :</strong></template> {{form.form_decoupe}} </el-descriptions-item>
                                  <el-descriptions-item><template slot="label"><strong>Films et Cliches :</strong></template> {{form.films_cliches}} </el-descriptions-item>
                              </el-descriptions>
                      </fieldset>
                      <el-form :model="form" >
                          <fieldset class="border p-2 mt-2">
                              <legend class="w-auto badge"><h5>PREPARATION</h5></legend>
                              <div class="row">
                                  <el-form-item 
                                      class="col-lg-3" 
                                      prop="Machine" 
                                      label="MACHINE"
                                  >
                                      <el-select v-model="form.Machine" @change="changeSelectMachine" style="width: 100%" placeholder="Merci de Choisir une machine">
                                          <el-option label="GIDUE-370" value="GIDUE-370"></el-option>
                                          <el-option label="NILPITER-B200" value="NILPITER-B200"></el-option>
                                          <el-option label="ROTOFLEX-400" value="ROTOFLEX-400"></el-option>
                                      </el-select>
                                  </el-form-item>
                                  <el-form-item 
                                      class="col-lg-3" 
                                      prop="Z_cylindre" 
                                      label="Z CYLINDRE"
                                  >
                                      <el-select v-model="form.Z_cylindre" style="width: 100%" @change="calculeAnamorph()" placeholder="Merci de Choisir Z Cylindre">
                                          <el-option
                                              v-for="item in Zcylindre"
                                              :key="item.value"
                                              :label="item.label"
                                              :value="item.value"
                                          ></el-option>
                                      </el-select>
                                  </el-form-item>
                                  <el-form-item 
                                      class="col-lg-3" 
                                      prop="nombre_PosesD" 
                                      label="NBR POSES DEVELLOPE"
                                  >
                                      <el-input-number v-model="form.nombre_PosesD" @change="calculePosesT" :min="0" :max="40"></el-input-number>
                                  </el-form-item>
                                  <el-form-item 
                                      class="col-lg-3" 
                                      prop="Nombre_PosesL" 
                                      label="NBR POSES LAIZE"
                                  >
                                      <el-input-number v-model="form.Nombre_PosesL" @change="calculePosesT" :min="0" :max="40"></el-input-number>
                                  </el-form-item>
                                  <el-form-item 
                                      class="col-lg-3" 
                                      prop="anamorphose" 
                                      label="ANAMORPHOSE"
                                  >
                                      <el-input v-model="form.anamorphose" :disabled="true" type="number"></el-input>
                                  </el-form-item>
                                  <el-form-item 
                                      class="col-lg-3" 
                                      prop="formatMM_L" 
                                      label="FORMAT LAIZE (L)"
                                  >
                                      <el-input v-model="form.formatMM_L" :disabled="true" type="number"></el-input>
                                  </el-form-item>
                                  <el-form-item 
                                      class="col-lg-3" 
                                      prop="formatMM_D" 
                                      label="FORMAT DEVLOPPE (D)"
                                  >
                                      <el-input v-model="form.formatMM_D" :disabled="true" type="number"></el-input>
                                  </el-form-item>
                                  <el-form-item 
                                      class="col-lg-3" 
                                      prop="developer_Calculer" 
                                      label="DEVELOPE CALCULER"
                                  >
                                      <el-input v-model="form.developer_Calculer" :disabled="true" type="number"></el-input>
                                  </el-form-item>
                                  <el-form-item 
                                      class="col-lg-3" 
                                      prop="espace_D" 
                                      label="ESPACE DEVELOPPE"
                                  >
                                      <el-input v-model="form.espace_D" :disabled="true" type="number"></el-input>
                                  </el-form-item>
                                  <el-form-item 
                                      class="col-lg-3" 
                                      prop="espace_L" 
                                      label="ESPACE LAIZE"
                                  >
                                      <el-input v-model="form.espace_L" :disabled="true" type="number"></el-input>
                                  </el-form-item>
                                  <el-form-item 
                                      class="col-lg-3" 
                                      prop="nombre_poseT" 
                                      label="NOMBRE POSE TOTAL"
                                  >
                                      <el-input v-model="form.nombre_poseT" :disabled="true" type="number"></el-input>
                                  </el-form-item>
                                  <el-form-item 
                                      class="col-lg-3" 
                                      prop="laize_tehorique" 
                                      label="LAIZE THEORIQUE"
                                  >
                                      <el-input v-model="form.laize_tehorique" :disabled="true" type="number"></el-input>
                                  </el-form-item>
                                  <el-form-item 
                                      class="col-lg-3" 
                                      prop="laize_Stock" 
                                      label="LAIZE EN STOCK"
                                  >
                                      <el-input v-model="form.laize_Stock" type="number"></el-input>
                                  </el-form-item>
                              </div>
                          </fieldset>
                          <!-- Papier Consommé -->
                          <fieldset class="border p-2 mt-2">
                              <legend class="w-auto badge"><h5>QUANTITÉ DE PAPIER CONSOMMÉ</h5></legend>
                              <div class="row">
                                  <el-form-item 
                                      class="col-lg-3" 
                                      prop="qtit_PapierML" 
                                      label="QUANTITE PAPIER (ML)"
                                  >
                                      <el-input v-model="form.qtit_PapierML" :disabled="true" type="text"></el-input>
                                  </el-form-item>
                                  <el-form-item 
                                      class="col-lg-3" 
                                      prop="qtit_PapierM2" 
                                      label="QUANTITE PAPIER (M²)"
                                  >
                                      <el-input v-model="form.qtit_PapierM2" :disabled="true" type="text"></el-input>
                                  </el-form-item>
                                  <el-form-item 
                                      class="col-lg-3" 
                                      prop="nbr_bobine_consomme" 
                                      label="NOMBRE BOBINE CONSOMMÉ"
                                  >
                                      <el-input v-model="form.nbr_bobine_consomme" :disabled="true" type="text"></el-input>
                                  </el-form-item>
                                  <el-form-item 
                                      class="col-lg-3" 
                                  >
                                      <br>
                                      <el-button type="primary" class="mt-2" size="small" icon="el-icon-s-operation" @click="calculeConsomationPP()" >Calculer Consomation Papier</el-button>
                                  </el-form-item>
                                  <div class="col-12">
                                      <strong> Calculer Consomation Papier = </strong><strong> Quantité : </strong> {{form.quantite_etq}} / 1000 / <strong> Nombre de Pose total : </strong> {{form.nombre_poseT}} x
                                      <strong> Anamorphose : </strong> {{form.anamorphose}} + {{form.vr}}
                                  </div>
                                  <div class="col-12">
                                      <strong> Convert en M² = </strong><strong> Quantité Papier en ml : </strong> {{form.qtit_PapierML}}  x <strong> Laize En Stock : </strong> {{form.laize_Stock}} / 1000
                                  </div>
                              </div>
                          </fieldset>
                          <!-- Cout de Papier Consommé -->
                          <fieldset class="border p-2 mt-2">
                              <legend class="w-auto badge"><h5>COUT DE PAPIER CONSOMMÉ</h5></legend>
                              <div class="row">
                                  <el-form-item 
                                      class="col-lg-3" 
                                      prop="prixEURO" 
                                      label="PRIX PAPIER EURO"
                                  >
                                      <el-input v-model="form.prixEURO" type="text"></el-input>
                                  </el-form-item>
                                  
                                  <el-form-item 
                                      class="col-lg-3" 
                                      prop="nbr_bobine_consomme" 
                                      label="COUT DE PAPIER"
                                  >
                                      <el-input v-model="form.cout_Papier" :disabled="true" type="text"></el-input>
                                  </el-form-item>
                                  <el-form-item 
                                      class="col-lg-3" 
                                  >
                                      <br>
                                      <el-button type="primary" class="mt-2" size="small" icon="el-icon-s-operation" @click="calculeCoutPP()" >Calculer le cout de Papier</el-button>
                                  </el-form-item>
                                  <div class="col-12">
                                      <strong> Calculer Cout Papier Consommé = </strong><strong> Quantité M² x (prix EURO x 11.5 + 0.18) = </strong> {{form.qtit_PapierM2}} x ({{form.prixEURO}} x 11.5) +  0.18 
                                  </div>
                              </div>
                          </fieldset>
                          <!-- Tirage -->
                          <fieldset class="border p-2 mt-2">
                              <legend class="w-auto badge"><h5>COUT DE TIRAGE ET IMPRESSION</h5></legend>
                              <div class="row">
                                  <el-form-item 
                                      class="col-lg-3" 
                                      prop="vrTirage" 
                                      label="TIRAGE (Support papier)"
                                  >
                                      <el-input-number v-model="form.vrTirage" :min="250" :max="2000"></el-input-number>
  
                                  </el-form-item>
                                  
                                  <el-form-item 
                                      class="col-lg-3" 
                                      prop="tirage" 
                                      label="TIRAGE"
                                  >
                                      <el-input v-model="form.tirage" :disabled="true" type="text"></el-input>
                                  </el-form-item>
                                  <el-form-item 
                                      class="col-lg-12" 
                                  >
                                      <br>
                                      <strong> Calculer Tirage = </strong><strong> Quantité Papier en ml : </strong> {{form.qtit_PapierML}}  / 2000 x {{form.vrTirage}} Support Papier : {{form.support_papier}}
                                  </el-form-item>
                                  <el-form-item 
                                      class="col-lg-3" 
                                  >
                                      <br>
                                      <el-button type="primary" class="mt-2" size="small" icon="el-icon-s-operation" @click="CalculeTirage()" >Calculer Tirage</el-button>
                                  </el-form-item>
                                  
                              </div>
                          </fieldset>
                          <!-- Mandrin -->
                          <fieldset class="border p-2 mt-2">
                              <legend class="w-auto badge"><h5>FRAIS DE MANDRIN</h5></legend>
                              <div class="row">
                                  <el-form-item 
                                      class="col-lg-2" 
                                      prop="mandrin" 
                                      label="MANDRIN"
                                  >
                                      <el-input v-model="form.mandrin" :disabled="true" type="text"></el-input>
                                      <el-input v-if="form.mandrin =='Autre'" v-model="form.mandrin_autre" :disabled="true" type="text"></el-input>
                                  </el-form-item>
                                  <el-form-item 
                                      class="col-lg-3" 
                                      prop="nbr_mandrin_consomme" 
                                      label="NOMBRE DE MANDRIN CONSOMÉ"
                                  >
                                      <el-input v-model="form.nbr_mandrin_consomme" :disabled="true" type="text"></el-input>
                                  </el-form-item>
                                  <el-form-item 
                                      class="col-lg-3" 
                                      prop="format_mandrin" 
                                      label="FORMAT DE MANDRIN"
                                  >
                                      <el-input v-model="form.format_mandrin" :disabled="true" type="text"></el-input>
                                  </el-form-item>
                                  <el-form-item 
                                      class="col-lg-3" 
                                      prop="cout_revien_mandrin" 
                                      label="COUT DE REVIENT MANDRIN"
                                  >
                                      <el-input v-model="form.cout_revien_mandrin" type="text"></el-input>
                                  </el-form-item>
                                  <el-form-item 
                                      class="col-lg-3" 
                                  >
                                      <el-button type="primary" size="small" class="mt-2" icon="el-icon-s-operation" @click="CalculeMandrin()" >Calcules de Mandrin</el-button>
                                  </el-form-item>
                                  <el-form-item class="col-lg-6" >
                                      <br>
                                      <strong> Nombre de Mandrin Consommé = </strong><strong> Quantité / Nombre Etq/Bobine : </strong> {{form.quantite_etq}}  / {{form.nbr_etq_bobine}}
                                      <br>
                                      <strong> Format de Mandrin = </strong><strong> Format en mm (L)  x Nombre Etq/Front : </strong> {{form.formatMM_L}}  x {{form.nbr_etq_front}}
                                      <br>
                                      <strong> Cout de revient de Mandrin = </strong><strong> Nombre de Mandrin Consommé x 0.5 : </strong> {{form.nbr_mandrin_consomme}} x 0.2       
                                  </el-form-item>
                              </div>
                          </fieldset>
                          <!-- cliché form decoup -->
                          <fieldset class="border p-2 mt-2">
                              <legend class="w-auto badge"><h5>FRAIS DE CLICHÉS</h5></legend>
                              <div class="row">
                                  <el-form-item 
                                      class="col-lg-3" 
                                      prop="frais_recto" 
                                      label="RECTO"
                                  >
                                      <el-input v-model="form.frais_recto"  type="text"></el-input>
                                  </el-form-item>
                                  <el-form-item 
                                      class="col-lg-3" 
                                      prop="frais_verso" 
                                      label="VERSO"
                                  >
                                      <el-input v-model="form.frais_verso"  type="text"></el-input>
                                  </el-form-item>
                                  <el-form-item 
                                      class="col-lg-3" 
                                      prop="FTotal_Cliches" 
                                      label="TOTAL"
                                  >
                                      <el-input v-model="form.FTotal_Cliches"  type="text"></el-input>
                                  </el-form-item>
                                  <el-form-item 
                                      class="col-lg-3" 
                                  >
                                      <br>
                                      <el-button type="primary" size="small" class="mt-2" icon="el-icon-s-operation" @click="CalculeFraisCliches()" >Calculer Frais Clichés</el-button>
                                  </el-form-item>
                                  <el-form-item 
                                      class="col-lg-12" 
                                  >
                                      <strong> Calculer Frais des Clichés Recto = </strong>(<strong> Anamorphose : </strong> {{form.anamorphose}}  x <strong> Laize En Stock : </strong>{{form.laize_Stock}} / 100 ) x <strong> Nobre de Couleurs :  </strong> {{form.nbr_clouleur}} x 0.42 x 1.2
                                  </el-form-item>
                                  <el-form-item 
                                      class="col-lg-12" 
                                  >
                                      <strong> Calculer Frais des Clichés Verso = </strong>(<strong> Anamorphose : </strong> {{form.anamorphose}}  x <strong> Laize En Stock : </strong>{{form.laize_Stock}} / 100 ) x 1
                                  </el-form-item>
                              </div>
                          </fieldset>
                          <fieldset class="border p-2 mt-2">
                              <legend class="w-auto badge"><h5>FRAIS DE FORME DE DECOUPE</h5></legend>
                              <div class="row">
                                  <el-form-item 
                                      class="col-lg-4" 
                                      prop="frais_formDecoup" 
                                      label="SAISIE LES FRAIS DE FORME DE DECOUPE"
                                  >
                                      <el-input v-model="form.frais_formDecoup" type="text"></el-input>
                                  </el-form-item>
                                  <el-form-item 
                                      class="col-lg-4" 
                                      prop="qtit_formDecoup" 
                                      label="SAISIE LES qtit DE FORME DE DECOUPE"
                                  >
                                      <el-input v-model="form.qtit_formDecoup" type="text"></el-input>
                                  </el-form-item>
                                  <el-form-item 
                                      class="col-lg-3" 
                                  >
                                      <br>
                                      <el-button type="primary" size="small" class="mt-2" icon="el-icon-s-operation" @click="CalculeFraisFormDecoup()" >Calculer Frais Forme de decoupe</el-button>
                                  </el-form-item>
                              </div>
                          </fieldset>
                          <!-- cliché form decoup -->
                          <fieldset class="border p-2 mt-2">
                              <legend class="w-auto badge"><h5>COUT DE REVIENT</h5></legend>
                              <div class="row">
                                  <el-form-item 
                                      class="col-lg-3" 
                                      prop="total_frais_formDecoup" 
                                      label="LES FRAIS DE FORME DE DECOUPE"
                                  >
                                      <el-input v-model="form.total_frais_formDecoup"  type="text"></el-input>
                                  </el-form-item>
                                  <br/>
                                  <el-form-item 
                                      class="col-lg-3" 
                                      prop="FTotal_Cliches" 
                                      label="LES FRAIS DE CLICHÉ"
                                  >
                                      <el-input v-model="form.FTotal_Cliches"  type="text"></el-input>
                                  </el-form-item>
                                  <el-form-item class="col-12"></el-form-item>
                                  <el-form-item 
                                      class="col-lg-3" 
                                      prop="cout_hors_FDC" 
                                      label="COUT DE REVIENT"
                                  >
                                      <el-input v-model="form.cout_hors_FDC" :disabled="true" type="text"></el-input>
                                  </el-form-item>
                                  <el-form-item 
                                      class="col-lg-3" 
                                      prop="marge_benif" 
                                      label="MARGE BENIFICIARE (%)"
                                  >
                                      <el-input v-model="form.marge_benif"  type="text"></el-input>
                                  </el-form-item>
                                  <el-form-item 
                                      class="col-lg-3" 
                                      prop="prixHTT" 
                                      label="PRIX UNITAIRE (HT)"
                                  >
                                      <el-input v-model="form.prixHTT"  type="text"></el-input>
                                  </el-form-item>
                                  <el-form-item 
                                      class="col-lg-3" 
                                      prop="prixHTT" 
                                      label="PRIX PAR 1000"
                                  >
                                      <el-input v-model="form.prixParMill"  type="text"></el-input>
                                  </el-form-item>
                                  
                                  <el-form-item 
                                      class="col-lg-12" 
                                  >
                                      <strong> Cout de revient =  Cout Papier + Tirage + mandrin + recto + verso + Form de decoup / Quantité </strong> 
                                  </el-form-item>
                                   <!-- Hors Form decoup -->
                                   <div class="col-lg-6">COUT DE REVIENT SANS FRAIS DE FORME DE DECOUPE :</div>
                                   <el-form-item 
                                      class="col-lg-3" 
                                      prop="totalSansFD" 
                                  >
                                      <el-input v-model="form.totalSansFD" type="text"></el-input>
                                  </el-form-item>
                                  <!-- Hors Cliché -->
                                  <div class="col-lg-6">COUT DE REVIENT SANS FRAIS DE CLICHE :</div>
                                  <el-form-item 
                                      class="col-lg-3"
                                      prop="totalSansC" 
                                  >
                                      <el-input v-model="form.totalSansC" type="text"></el-input>
                                  </el-form-item>
                                   <!-- Hors Form decoup et Cliché -->
                                   <div class="col-lg-6">COUT DE REVIENT SANS FRAIS DE FORME DE DECOUPE ET CLICHE :</div>
                                   <el-form-item 
                                      class="col-lg-3" 
                                      prop="totalSansFDC" 
                                  >
                                      <el-input v-model="form.totalSansFDC" type="text"></el-input>
                                  </el-form-item>
                                  <!-- calculer -->
                                  <el-form-item 
                                      class="col-lg-12" 
                                  >
                                      <br>
                                      <el-button type="primary" size="small" class="mt-2" icon="el-icon-s-operation" @click="CalculeMargeBenific()" >Calculer les Couts Final</el-button>
                                  </el-form-item>
                              </div>
                          </fieldset>
                          <!-- cliché form decoup -->
                          <!-- <fieldset class="border p-2 mt-2">
                              <legend class="w-auto badge"><h5>Estimation Durée</h5></legend>
                              <div class="row">
                                  <el-form-item 
                                      class="col-lg-3" 
                                      prop="duree_production" 
                                      label="Estimation Durée de Production "
                                  >
                                      <el-input v-model="form.duree_production" :disabled="true" type="text"> <template slot="append">H</template></el-input>
                                  </el-form-item>
                                  <el-form-item 
                                      class="col-lg-3" 
                                      prop="duree_conditionement" 
                                      label="Estimation Durée de Conditionement"
                                  >
                                      <el-input v-model="form.duree_conditionement" :disabled="true" type="text"> <template slot="append">H</template></el-input>
                                  </el-form-item>
                                  <el-form-item 
                                      class="col-lg-3" 
                                      prop="duree_conditionement" 
                                      label="Estimation Durée de Conditionement"
                                  >
                                      <el-date-picker v-model="form.estimation_livraison" type="date"></el-date-picker>
                                  </el-form-item>
                                  <el-form-item 
                                      class="col-lg-3" 
                                  >
                                      <br>
                                      <el-button type="primary" size="small" class="mt-2" icon="el-icon-s-operation" @click="CalculeDuree()" >Calculer Durées Production</el-button>
                                  </el-form-item>
                              </div>
                          </fieldset>  -->
                      </el-form>
                  <!-- ENd body Dialog -->
                  <span slot="footer" class="dialog-footer">
                      <el-button @click="DialogVisibleP = false">Cancel</el-button>
                      <!-- <el-button type="primary" v-show="!editmode" @click="createData()">Ajouter</el-button> -->
                      <el-button type="primary" v-show="editmode" @click="PreparationData()">Valider</el-button>
                  </span>
          </el-dialog>
          <!-- end Dialog Preparation -->
          <!-- Invoice Global Devis-->
        <div
                        class="modal fade"
                        id="InvoiceGlobalDevis"
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="InvoiceGlobalDevis"
                        aria-hidden="true"
                    >
                        <div class="modal-dialog modal-xl" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title text-right">Devis</h5>
                                    <button
                                        type="button"
                                        class="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <!-- invoice -->
                                <div class="modal-body" id="printGlobalDevis">
                                    <div class="row" v-for="d in OnedevisPlaque" :key="d.id">
                                        <div class="col-12">
                                            <div class="card">
                                                <div class="card-body p-0">
                                                    <div class="row">
                                                        <div class="col-12 text-center">
                                                            <img
                                                                class="comapny_logo_invoice"
                                                                src="/images/logo.png"
                                                                alt="brand-logo-for-invoice"
                                                                style="height:90px"
                                                            />
                                                        </div>
                                                    </div>

                                                    <hr class="my-2" />

                                            <div class="page-content container" >
                                                <div class="page-header text-blue-d2">
                                                    <h2 v-if='d.type_de_demande == 3' class="page-title text-center text-secondary-d1">
                                                        A NOTRE CHER FOURNISSEUR
                                                    </h2>
                                                    <h2 v-else-if='d.type_de_demande == 1' class="page-title text-center text-secondary-d1">
                                                        DEMANDE DE PRIX DES PLAQUES MAGNETIQUE
                                                    </h2>
                                                    <h2 v-else class="page-title text-center text-secondary-d1">
                                                        DEMANDE DE REDUCTION DE L'OFFRE DE PRIX DES PLAQUES MAGNETIQUE
                                                    </h2>
                                                </div>
                                                
                                                <div class="row">
                                                    <div class="col-6"></div>
                                                    <div class="text-95 col-6 align-self-start d-sm-flex justify-content-end">
                                                        <p class="page-title text-secondary-d1">
                                                            <strong>Date  :</strong>  -- / -- / --
                                                        </p>
                                                    </div>

                                                </div>
                            
                                                <div class="container px-0" >
                                                    <div class="row mt-4">
                                                        <div class="col-12 col-lg-12">
                                                        
                                                            <hr/>

                                                            <div class="row">
                                                                <div class="col-sm-6">
                                                                    <div>
                                                                        <div class="text-sm text-grey-m2 align-middle">N° DE LA DEMANDE : PL. MA 0001</div>
                                                                        <div class="text-sm text-grey-m2 align-middle">PLAN DE FORME DE DECOUPE N° : --- ,DATE : ---</div>
                                                                        <div class="text-sm text-grey-m2 align-middle ml-5">FOURNISSEUR : ---</div>
                                                                        <div class="text-sm text-grey-m2 align-middle ml-5">ADRESSE : --- </div>
                                                                        <div class="text-sm text-grey-m2 align-middle ml-5">PAYS :</div>
                                                                        <div class="text-sm text-grey-m2 align-middle ml-5">TEL :</div>
                                                                        <div class="text-sm text-grey-m2 align-middle ml-5">E-MAIL :</div>
                                                                        <div class="text-sm text-grey-m2 align-middle">CHARGE D'AFFAIRE</div>
                                                                        <div class="text-sm text-grey-m2 align-middle ml-5">NOM :</div>
                                                                        <div class="text-sm text-grey-m2 align-middle ml-5">E-MAIL :</div>
                                                                        <div class="text-sm text-grey-m2 align-middle ml-5">TEL :</div>
                                                                    </div>
                                                                    
                                                                </div>
                                                                <!-- /.col -->

                                                            </div>
                                                            <hr />
                                                            <div class="mt-4">
                                                                <div class="row border-b-2 brc-default-l2"></div>

                                                                <!-- or use a table instead -->
                                                                <strong>CHER FOURNISSEUR </strong>
                                                                <br>
                                                                <p class="ml-3" v-if="d.type_de_demande == 1">
                                                                    MERCI DE NOUS FAIRE PARVENIR VOTRE MAILLEUR OFFRE DE PRIX CONCERNANT LES ARTICLES SUIVANTS SELON LES <br>
                                                                    SPECIFITES ET CARACTERISTIQUES TECHNIQUES DEMANDEES.
                                                                </p>

                                                                <p class="ml-3" v-else-if="d.type_de_demande == 2">
                                                                    NOUS AVONS BIEN EXAMINER VOTRE OFFRE DE PRIX N° DATE : -- / -- / --<br>
                                                                    NOUS AVONS CONSTATES QUE VOTRE PRIX PROPOSE EST EXCESSIF, ON VOUS DEMANDE CHER FOURNISSEUR
                                                                    DE FAIRE EFFORT POUR NOUS PROPOSER UNE REDUCTION AINSI QU'UN MEILLEUR DELAI PAIEMENT. <br>
                                                                </p>

                                                                <p class="ml-3" v-else>
                                                                    NOUS AVONS L'HONNEUR DE VOUS ADRESSER CE BON DE COMMANDE N° DATE : -- / -- / --<br>
                                                                    AFIN DE COMMENCER L'ORDRE DE SERVICE SELON LES NORMES ET CARACTERISTIQUES TECHNIQUES EXIGÉES.
                                                                    A CET EFFET, NOUS VOUS DEMANDONS DE PREPARER LA FACTURE PRO FORMA QUI VA PORTER TOUT LES INFORMATIONS SUIVANTES A SAVOIR : <br>
                                                                </p>

                                                                <div v-if="d.type_de_demande == 3" class="row mt-3">
                                                                    <div class="col-12 ml-3 text-grey-d2 text-95 mt-2 mt-lg-0">
                                                                        DESIGNATION D'ARTICLE : Forme De Decoupe : ---
                                                                    </div>
                                                                    <div class="col-12 ml-3 text-grey-d2 text-95 mt-2 mt-lg-0">
                                                                        QUANTITE : ---
                                                                    </div>
                                                                    <div class="col-12 ml-3 text-grey-d2 text-95 mt-2 mt-lg-0">
                                                                        NOMENCLATURE : ---
                                                                    </div>
                                                                    <div class="col-12 ml-3 text-grey-d2 text-95 mt-2 mt-lg-0">
                                                                        POIDS BRUT ET NET EN KG : ---
                                                                    </div>
                                                                    <div class="col-12 ml-3 text-grey-d2 text-95 mt-2 mt-lg-0">
                                                                        VALEUR EN DEVIS : ---
                                                                    </div>
                                                                    <div class="col-12 ml-3 text-grey-d2 text-95 mt-2 mt-lg-0">
                                                                        PAYS D'ORIGINE, PAYS DE PROVENANCE : ---
                                                                    </div>
                                                                    <div class="col-12 ml-3 text-grey-d2 text-95 mt-2 mt-lg-0">
                                                                        INCOTERM : ---
                                                                    </div>
                                                                    <div class="col-12 ml-3 text-grey-d2 text-95 mt-2 mt-lg-0">
                                                                        TRANSPORT : ---
                                                                    </div>
                                                                    <div class="col-12 ml-3 text-grey-d2 text-95 mt-2 mt-lg-0">
                                                                        COORDONEES BANCAIRES ET MODALITE DE PAIEMENT : ---
                                                                    </div>

                                                                </div>
                                                                <p class="ml-3" v-if="d.type_de_demande == 3">
                                                                    DANS L'ATTENTE DE VOTRE RETOUR, VEUILLEZ ACCEPTER CHER FOURNISSEUR, NOUS SINCERES SALUTATIONS.
                                                                </p>

                                                                <table class="table table-bordered" v-if="d.type_de_demande != 3">
                                                                        <thead>
                                                                            <tr>
                                                                                <th scope="col">Réferance</th>
                                                                                <th scope="col">Désignation</th>
                                                                                <th scope="col">Quantité</th>
                                                                                <th scope="col">P.U.(H.T)</th>
                                                                                <th scope="col">TOTAL.(H.T)</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody v-for="(c, k) in d.get_plaques" :key="k">
                                                                            <tr>
                                                                                <th scope="row">{{c.get_e_t_c.id}}</th>
                                                                                <td>Forme De Decoupe : {{c.get_e_t_c.formatMM_L}}x{{c.get_e_t_c.formatMM_D}}-{{c.get_e_t_c.forme_etq}},
                                                                                    Z-Cylindre : {{c.get_e_t_c.Z_cylindre}}, Nombre De Pose Laize : {{c.get_e_t_c.Nombre_PosesL}} , 
                                                                                    Nombre De Pose Developpe : {{c.get_e_t_c.nombre_PosesD}}, Support (PAPIER) : {{c.get_e_t_c.support_papier}},
                                                                                    Laize Support Plan Du Travail Calcule En mm {{c.get_e_t_c.support_papier}}, Machine : {{c.get_e_t_c.Machine}}
                                                                                </td>
                                                                                <td>1</td>
                                                                                <td>---</td>
                                                                                <td>---</td>
                                                                            </tr>
                                                                        </tbody>
                                                                </table>

                                                                <hr/>

                                                                <div v-if="d.type_de_demande != 3" class="text-center">
                                                                    <strong class="text-secondary-d1 text-105">SIGNATURES :</strong>
                                                                    <p class="text-secondary-d1 text-105">CHARGE COMANDES</p>
                                                                </div>
                                                                <div v-else class="text-center">
                                                                    <strong class="text-secondary-d1 text-105">SIGNATURES :</strong>
                                                                    <div class="row">
                                                                        <div class="col-6">
                                                                            <p class="text-secondary-d1 text-105">Hassan EL AISSARI</p>
                                                                        </div>
                                                                        <div class="col-6">
                                                                            <p class="text-secondary-d1 text-105">Abdelaali EL AISSARI</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <hr />

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr class="my-2" />
                                            <!-- footer -->
                                            <div class=" flex-row-reverse text-center p-4">
                                                <div class="py-3 px-5">
                                                    <div>
                                                        Lot Yamna Ismail,Avenue Med. VI Route de Biougra - Ait Meloul -B.P.N° 1810 Z.I.Ait Melloul - Tél /Fax: 05 28 24 51 90
                                                    </div>
                                                    <div>
                                                        SARL au Capital de 300 000.00 DH - R.C: 3545 Inzegan -lf : 06 95 04 67 - Patente : 49866952- CNSS : 7341845
                                                    </div>
                                                    <div>
                                                        N° Compte B.P .Ait Melloul : 101022212117001481001534 - Email: aakabar@gmail.com
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                </div>
                        <div class="modal-footer">
                            <button
                                class="btn btn-primary"
                                style="float: right;"
                                @click="printC()"
                            >
                                <span class="fa fa-print"></span>
                            </button>
                        </div>
                    </div>
                    <!-- end invoice  -->
                </div>
        </div>
        <!-- End Invoice Global Devis -->
          <!-- Invoice -->
          <div
                  class="modal fade"
                  id="Invoice"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="Invoice"
                  aria-hidden="true"
              >
                  <div class="modal-dialog modal-xl" role="document">
                      <div class="modal-content">
                          <div class="modal-header">
                              <h5 class="modal-title text-right">Devis</h5>
                              <button
                                  type="button"
                                  class="close"
                                  data-dismiss="modal"
                                  aria-label="Close"
                              >
                                  <span aria-hidden="true">&times;</span>
                              </button>
                          </div>
                          <!-- invoice -->
                          <div class="modal-body" id="printMe">
                              <div class="row">
                                  <div class="col-12">
                                      <div class="card">
                                          <div class="card-body p-0">
                                              <div class="row p-5">
                                                  <div class="col-12 text-center">
                                                      <img
                                                          class="comapny_logo_invoice"
                                                          src="/images/logo.png"
                                                          alt="brand-logo-for-invoice"
                                                          style="height:90px"
                                                      />
                                                  </div>
                                              </div>
  
                                              <hr class="my-2" />
  
                                        <div class="page-content container" >
                                            <div class="row">
                                                <div class="page-header col-6 text-blue-d2">
                                                    <h2 class="page-title text-secondary-d1">
                                                        OFFRE DE PRIX 
                                                        <small class="page-info">
                                                            <i class="fa fa-angle-double-right text-80"></i>
                                                            N° : #{{id_offer}}-{{dateOffre | OfferDates}}
                                                        </small>
                                                    </h2>
                                                </div>
                                                <div class="text-95 col-6 align-self-start d-sm-flex justify-content-end">
                                                    <p class="page-title text-secondary-d1">
                                                        Date  :  {{dateOffre}}
                                                    </p>
                                                </div>
                    
                                            </div>
                        
                                            <div class="container px-0">
                                                <div class="row mt-4">
                                                    <div class="col-12 col-lg-12">
                                                    
                                                        <hr />
                    
                                                        <div class="row">
                                                            <div class="col-sm-6">
                                                                <div>
                                                                    <strong class="text-sm text-grey-m2 align-middle">CLIENT :</strong>
                                                                    <span class="text-600 text-110 text-blue align-middle">{{nameClient}}</span>
                                                                </div>
                                                                <div class="text-grey-m2">
                                                                    <div class="my-1">
                                                                        <strong>ADRESSE :</strong> {{AdressClient}}
                                                                    </div>
                                                                    <div class="my-1">
                                                                    </div>
                                                                    <!-- <div class="my-1"><i class="fa fa-phone fa-flip-horizontal text-secondary"></i> <b class="text-600">111-111-111</b></div> -->
                                                                </div>
                                                            </div>
                                                            <!-- /.col -->
                    
                                                            <div class="text-95 col-sm-6 align-self-start d-sm-flex justify-content-end">
                                                                <hr class="d-sm-none" />
                                                                <div class="text-grey-m2">
                    
                                                                    <!-- <div class="my-2"><i class="fa fa-circle text-blue-m2 text-xs mr-1"></i> <span class="text-600 text-90">Consultation N° :</span> #111-222</div> -->
                    
                                                                    <div class="my-2"><i class="fa fa-circle text-blue-m2 text-xs mr-1"></i> <strong class="text-600 text-90">Affaire Suivi Par:</strong> {{userName}}</div>
                    
                                                                </div>
                                                            </div>
                                                            <!-- /.col -->
                                                        </div>
                                                        <hr />
                                                        <div class="mt-4">
                                                            <div class="row border-b-2 brc-default-l2"></div>
                    
                                                            <!-- or use a table instead -->
                                                            <table class="table table-bordered">
                                                                    <thead>
                                                                        <tr>
                                                                        <th scope="col">Réferance</th>
                                                                        <th scope="col">Quantité</th>
                                                                        <th scope="col">Désignation</th>
                                                                        <th scope="col">Unité</th>
                                                                        <th scope="col">TAUX T.V.A</th>
                                                                        <th scope="col">P.U.(H.T)</th>
                                                                        <th scope="col">TOTAL.(H.T)</th>
                                                                        <th scope="col">TOTAL T.V.A</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody v-for="(c, k) in Offer_devis" :key="k">
                                                                        <tr v-if="type_ISE == 'inclus'">
                                                                            <th scope="row">{{c.id}}</th>
                                                                            <td>{{c.quantite_etq}}</td>
                                                                            <td>{{c.nom_etc}}</td>
                                                                            <td>Unité</td>
                                                                            <td>{{tvaCentage}}%</td>
                                                                            <td>{{c.prixHTT | FormNumbers}}</td>
                                                                            <td>{{c.prixHTT * c.quantite_etq | FormNumbers}}</td>
                                                                            <td>{{((c.prixHTT * c.quantite_etq)*tvaCentage2) | FormNumbers}}</td>
                                                                        </tr>
                                                                        <tr v-if="type_ISE == 'exclus' || type_ISE == 'separee'">
                                                                            <th scope="row">{{c.id}}</th>
                                                                            <td>{{c.quantite_etq}}</td>
                                                                            <td>{{c.nom_etc}}</td>
                                                                            <td>Unité</td>
                                                                            <td>{{tvaCentage}} %</td>
                                                                            <td>{{c.totalSansFDC | FormNumbers}}</td>
                                                                            <td>{{c.totalSansFDC * c.quantite_etq | FormNumbers}}</td>
                                                                            <td>{{((c.totalSansFDC * c.quantite_etq)*tvaCentage2) | FormNumbers}}</td>
                                                                        </tr>
                                                                        <tr v-if="type_ISE == 'separee' && c.impression == 'OUI'" >
                                                                            <th>--</th>
                                                                            <td>{{c.qtit_formDecoup}}</td>
                                                                            <td>Forme de decoupe</td>
                                                                            <td>Unité</td>
                                                                            <td>20 %</td>
                                                                            <td>{{c.frais_formDecoup | FormNumbers}}</td>
                                                                            <td>{{c.total_frais_formDecoup | FormNumbers}}</td>
                                                                            <td>{{c.total_frais_formDecoup*0.2 | FormNumbers}}</td>
                                                                        </tr>
                                                                        <tr v-if="type_ISE == 'separee' && c.impression == 'OUI'">
                                                                            <th>--</th>
                                                                            <td>{{c.nbr_clouleur}}</td>
                                                                            <td>Cliché</td>
                                                                            <td>Unité</td>
                                                                            <td>20 %</td>
                                                                            <td>{{(c.FTotal_Cliches / c.nbr_clouleur) | FormNumbers}}</td>
                                                                            <td>{{c.FTotal_Cliches | FormNumbers}}</td>
                                                                            <td>{{c.FTotal_Cliches*0.2 | FormNumbers}}</td>
                                                                        </tr>
                                                                    </tbody>
                                                            </table>
                    
                                                            <div class="row mt-3">
                                                                <div class="col-12 col-sm-7 text-grey-d2 text-95 mt-2 mt-lg-0">
                                                                    ARRETER LE PRESENT OFFRE DE PRIX A LA SOMME DE : <br/>
                                                                    <strong>{{this.GlobalTotalTTC | NumberToLetterM}}</strong>
                                                                </div>
                    
                                                                <div class="col-12 col-sm-5 text-grey text-90 order-first order-sm-last">
                                                                    <div class="row my-2">
                                                                        <div class="col-7 text-right">
                                                                            MONTANT (H.T)
                                                                        </div>
                                                                        <div class="col-5">
                                                                            <span class="text-120 text-secondary-d1">{{GlobalTotalHT | FormNumbers}}</span>
                                                                        </div>
                                                                    </div>
                    
                                                                    <div class="row my-2">
                                                                        <div class="col-7 text-right">
                                                                            T.V.A ({{tvaCentage}}%)
                                                                        </div>
                                                                        <div class="col-5">
                                                                            <span class="text-110 text-secondary-d1">{{tva | FormNumbers}}</span>
                                                                        </div>
                                                                    </div>
                    
                                                                    <div class="row my-2 align-items-center bgc-primary-l3 p-2">
                                                                        <div class="col-7 text-right">
                                                                            TOTAL (T.T.C)
                                                                        </div>
                                                                        <div class="col-5">
                                                                            <span class="text-150 text-success-d3 opacity-2">{{GlobalTotalTTC | FormNumbers}}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                    
                                                            <hr />
                    
                                                            <div class="mt-5 mb-5">
                                                                <strong class="text-secondary-d1 text-105 mt-5 mb-5">SIGNATURES :</strong>
                                                                <!-- <VueQRCodeComponent :size="200" class="float-right px-4 " text="dazdaz"></VueQRCodeComponent> -->
                                                            </div>
                                                            <hr />
                    
                                                            <div>
                                                                <strong class="text-secondary-d1 text-105">REGIME DE FACTURATION :</strong> {{GlobalModalitePaiment}}
                                                            </div>
                                                            <hr />
                                                            <div v-if="impression == 'OUI'">
                                                                <p class="text-secondary-d1 text-105"><strong>Formes de découpes films et clichés :</strong>
                                                                    <span v-if="form_decoupe == 'Non'">les frais de la nouvelle forme de découpe seront facturées en (TTC) sur le client.</span>
                                                                    <span v-if="films_cliches == 'Non'">les frais des films et clichés seront facturées en (TTC) sur le client. </span></p>
                                                            </div>
                                                            <hr />
                                                            <div v-if="GlobalModalitePaiment == 'AT'">
                                                                <span class="text-secondary-d1 text-105"><strong>Condition d'admission temporaire (A.T) :</strong><br/>
                                                                    1 - Le respect de la réglementation relative aux régimes economiques de douane. <br/>
                                                                    2 - Taxe sur la valeur ajoutée : vente en suspentions de la taxe sur la valeur ajoutée en vertu de l'article 92 du code géneral des impots. <br/>
                                                                    3 - cette marchandise est strictement distinée à l'export. <br/>
                                                                    4 - le client exportateur d'engage a liquider la totalité de la marchandise avant 90 jours de sa date de livraison : l'enregistrement de la cession ne doit pas depasser 90 jours de la date de la premiére livraison conformément à la réglementation douaniére. <br/>
                                                                    le client s'engage à assumer toute la responsabilité vis-à-vis de la douane en ce qui concerne une eventuelle irrégularité et erreur dans la declaration (cession AT). à cette effet le client devra payer toute sorte de pénalité eventuelle générée par la douane.<br/>
                                                                    5 - le client doit assurer le suivi pour validation de la DUM de cession en mettant à la disponibilité de l'ADII les documents demandé. <br/>
                                                                    6 - les honoraires et tout les frais de la prestation de chaque cession douaniére sera à la charge de client.<br/>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                      <hr class="my-2 mt-5" />
                                      <!-- footer -->
                                      <div class=" flex-row-reverse text-center p-4">
                                          <div class="py-3 px-5">
                                              <div>
                                                  Lot Yamna Ismail,Avenue Med. VI Route de Biougra - Ait Meloul -B.P.N° 1810 Z.I.Ait Melloul - Tél /Fax: 05 28 24 51 90
                                              </div>
                                              <div>
                                              SARL au Capital de 300 000.00 DH - R.C: 3545 Inzegan -lf : 06 95 04 67 - Patente : 49866952- CNSS : 7341845
                                              </div>
                                              <div>
                                              N° Compte B.P .Ait Melloul : 101022212117001481001534 - Email: aakabar@gmail.com
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="modal-footer">
                      <button
                          class="btn btn-primary"
                          style="float: right;"
                          @click="printC()"
                      >
                          <span class="fa fa-print"></span>
                      </button>
                  </div>
              </div>
              <!-- end invoice  -->
          </div>
  </div>
          <!-- End Invoice -->
      </div>
      
    </section>
    
  </template>
  
  <style>
      .el-table .info-row {
          background: #A0CFFF
      }
  
      .el-table .warning-row {
          background: oldlace
      }
  
      .el-table .success-row {
          background: #f0f9eb;
      }
  </style>
  
  <script>
      import VueQRCodeComponent from 'vue-qrcode-component'
      import VueTagsInput from '@johmun/vue-tags-input';
      import moment from 'moment'
      import numeral from 'numeral'
      import { NumberToLetter } from 'convertir-nombre-lettre';
      import {Machine1 , Machine2 ,Machine3, Mandrin, Etiquettes , Clients} from '../devis/Zcylindre';
  
      export default {
        components: {
          VueQRCodeComponent,
            VueTagsInput,
          },
          data () {
              return {
                  active: 0,
                //   
                  DialogVisible_ProjetLancer: false,
                  projet_client:'',
                  typeLancementCMD:'',
                  lieu_livraison:'',
                  RLancementCMDName:'',
                  RLancementCMDemail:'',
                  RLancementCMDtele:'',
                  projetETQ: [],
                  DialogVisibleF: false,
                  filename : '',
                  file: "",
                //   
                  disableDevisPV: false,
                  DialogVisible: false,
                  DialogVisible_global: false,
                  DialogVisibleP: false,
                  devisETQ: [],
                  devisGlobal: [],
                  Domandedevis: [],
                  devisETQPrepared: [],
                  clients: [],
                  dateOffre : new Date().toISOString().slice(0,10),
                  id_offer: '',
                  validationBTN: '',
                  // Print Offer de prix
                  // Offer_Client: [],
                  // start offer devis
                  Offer_devis: [],
                  nameClient : '',
                  teleClient : '',
                  emailClient : '',
                  AdressClient : '',
                  type_ISE:'',
                  userName : '',
                  GlobalModalitePaiment : '',
                  GlobalTotalHT : '',
                  tva : '',
                  tvaCentage : '',
                  tvaCentage2 : '',
                  GlobalTotalTTC : '',
                  form_decoupe : '',
                  films_cliches : '',
                  
                  // End Print Offer de prix
                  // prixEURO : '',
                  // rules validation formulair
                  // rules: {
                  //     typeEtiquette: [
                  //         { required: true, message: 'Please etiquette type', trigger: 'blur' },
                  //     ],
                  ArticlesForm : new Form({
                          typeEtiquette: '',
                          nom_etc:'',
                          conception:'',
                          formatMM_L:'',
                          formatMM_D : '',
                          forme_etq:'',
                          support_papier : '', 
                          quantite_etq:'',
                          impression:'',
                          nbr_clouleur:'',
                          couleurs: [{  couleur: "",ref:"",formule:"" }],
                          recto:'Non',
                          verso:'Non',
                          verso_couleur:'',
                          verso_ref:'',
                          verso_formule:'',
                          sens_sortie:'',
                          type_sense_sortie:'',
                          repiquage:'',
                          vernis:'',
                          vernis_type:'',
                          plastification:'',
                          plastification_type:'',
                          dorure:'',
                          dorure_type:'',
                          couleur_dorure:'',
                          nbr_etq_bobine : '',
                          nbr_etq_front: '',
                          mandrin: '',
                          mandrin_autre:'',
                          poses_etq: '',
                          form_decoupe: '',
                          form_decoupe_id: '',
                          films_cliches: '',
                          films_cliches_id: '',
                  }),
                      
                  // },
                  // end rules
                  form_projetLancer : new Form({
                    id:'',
                    ProjetLancer: [{  id_etc: "",id_client: "",form_decoupe:"",qtit_lancer:"",date_livraison:"",lieu_livraison:"",
                    remarque:"--",qtit_urgence:"",urgence:"",lieu_livraison_urgence:"",date_livraison_urgence:""}],
                    // filename : '',
                    id_bon_cmd:''
                  }),

                  form_global: new Form({
                      id:'',
                      
                      id_client:'',
                      existClient:'',
                      dateDevis : new Date().toISOString().slice(0,10),
                      type_ISE:'',
                      Etat_devis:'',
                      client: [{  name: "",tele:"",email:"" }],
                    //   NumberToLetterValue:19987,
                      
                      ArticlesCommander : [{
                          typeEtiquette: '',
                          nom_etc:'',
                          conception:'',
                          formatMM_L:'',
                          formatMM_D : '',
                          forme_etq:'',
                          support_papier : '', 
                          quantite_etq:'',
                          impression:'',
                          nbr_clouleur:'',
                          couleurs: [{  couleur: "",ref:"",formule:"" }],
                          recto:'Non',
                          verso:'Non',
                          verso_couleur:'',
                          verso_ref:'',
                          verso_formule:'',
                          sens_sortie:'',
                          type_sense_sortie:'',
                          repiquage:'',
                          vernis:'',
                          vernis_type:'',
                          plastification:'',
                          plastification_type:'',
                          dorure:'',
                          dorure_type:'',
                          couleur_dorure:'',
                          nbr_etq_bobine : '',
                          nbr_etq_front: '',
                          mandrin: '',
                          mandrin_autre:'',
                          poses_etq: '',
                          form_decoupe: '',
                          form_decoupe_id: '',
                          films_cliches: '',
                          films_cliches_id: '',
                      }],
                      modalite_paiment: '',
                      // Preparation
                      Machine:'',
                      Z_cylindre : '',
                      nombre_PosesD : '',
                      Nombre_PosesL : '',
                      anamorphose : '',
                      developer_Calculer: '',
                      espace_D: '',
                      espace_L: '',
                      nombre_poseT : '',
                      laize_tehorique: '',
                      laize_Stock: '',
                      qtit_PapierML : '',
                      qtit_PapierM2 : '' ,
                      cout_Papier : '' ,
                      prixEURO : '',
                      nbr_bobine_consomme : '' ,
                      vrTirage: '',
                      vr:'',
                      tirage : '' ,
                      nbr_mandrin_consomme : '' ,
                      format_mandrin : '' ,
                      cout_revien_mandrin : '' ,
                      frais_recto : '' ,
                      frais_verso : '' ,
                      FTotal_Cliches : '' ,
                      frais_formDecoup : '' ,
                      cout_hors_FDC : '' ,
                      marge_benif : 0 ,
                      prixHTT : '' ,
                      prixParMill : '' ,
                      duree_production : '' ,
                      duree_conditionement : '' ,
                      estimation_livraison : '' ,
                      preparation:'',
                      // Validation
                      validation1: '',
                      validation2: '',
                      validation3: '',
                  }),
  
                  form: new Form({
                      id:'',
                      
                      id_client:'',
                      existClient:'',
                      dateDevis : new Date().toISOString().slice(0,10),
                      client: [{  name: "",tele:"",email:"" }],
  
                          typeEtiquette: '',
                          nom_etc:'',
                          conception:'',
                          formatMM_L:'',
                          formatMM_D : '',
                          forme_etq:'',
                          support_papier : '', 
                          quantite_etq:'',
                          quantite_etq_lancer:'',
                          impression:'',
                          nbr_clouleur:'',
                          couleurs: [{  couleur: "",ref:"",formule:"" }],
                          recto:'Non',
                          verso:'Non',
                          verso_couleur:'',
                          verso_ref:'',
                          verso_formule:'',
                          sens_sortie:'',
                          type_sense_sortie:'',
                          repiquage:'',
                          vernis:'',
                          vernis_type:'',
                          plastification:'',
                          plastification_type:'',
                          dorure:'',
                          dorure_type:'',
                          couleur_dorure:'',
                          nbr_etq_bobine : '',
                          nbr_etq_front: '',
                          mandrin: '',
                          mandrin_autre:'',
                          poses_etq: '',
                          form_decoupe: '',
                          form_decoupe_id: '',
                          films_cliches: '',
                          films_cliches_id: '',
                      
                      modalite_paiment: '',
                      // Preparation
                      Machine:'',
                      Z_cylindre : '',
                      nombre_PosesD : '',
                      Nombre_PosesL : '',
                      anamorphose : '',
                      developer_Calculer: '',
                      espace_D: '',
                      espace_L: '',
                      nombre_poseT : '',
                      laize_tehorique: '',
                      laize_Stock: '',
                      qtit_PapierML : '',
                      qtit_PapierM2 : '' ,
                      cout_Papier : '' ,
                      prixEURO : '',
                      nbr_bobine_consomme : '' ,
                      vrTirage: '',
                      vr:'',
                      tirage : '' ,
                      nbr_mandrin_consomme : '' ,
                      format_mandrin : '' ,
                      cout_revien_mandrin : '' ,
                      frais_recto : '' ,
                      frais_verso : '' ,
                      FTotal_Cliches : '' ,
                      frais_formDecoup : '' ,
                      total_frais_formDecoup : '' ,
                      qtit_formDecoup : '' ,
                      totalSansFDC : '' ,
                      totalSansFD : '' ,
                      totalSansC : '' ,
                      cout_hors_FDC : '' ,
                      marge_benif : 0 ,
                      prixHTT : '' ,
                      prixParMill : '' ,
                      duree_production : '' ,
                      duree_conditionement : '' ,
                      estimation_livraison : '' ,
                      preparation:'',
                      // Validation
                      validation1: '',
                      validation2: '',
                      validation3: '',
                  }),
  
                  // print: new Form ({
                  //     nomCLient:'',
                      
  
                  // }),
                  
              //Static Data  
              Etiquettes : Etiquettes,
              // Clients : Clients,
              Machine1: Machine1,
              Machine2: Machine2,
              Machine3: Machine3,
              Mandrin_Stock: Mandrin,
              Mandrin_Stock2:[],
              Zcylindre:[],
              tableZ:[],
              // 
              search: '',
              pagesize: 10,
              currpage: 1,
              
              search1: '',
              pagesize1: 10,
              currpage1: 1,
  
              editmode: false,
  
              // autocompleteItems: [],
              formDevisPlaque: new Form({ 
                    id_projet:'',
                    PlaquesCommander: [],
                    id_fournisseur:'',
                    type_de_demande:'',
                    id_etc:'',
                    rayon_coin:'',
                    rayon_coin_valeur:'',
                    qtit:'',
                    prix_unit:'',
                    method_envoi:'',
                    date_livraison:'',
                    modalite_paiment:'',
                }),

              multipleSelection: [],
              fournisseurs: [],
              OnedevisPlaque: [],
              DialogVisibleDevisPlaques: false,

              }
          },
          methods: {

            // demande devis
            async loadOneDevisPlaque(idDevis){
                    this.$Progress.start();
                        await axios.get("/api/GetOneGlobalDevis/"+idDevis).then(({ data }) => (this.OnedevisPlaque = data.data))
                    this.$Progress.finish();
            },

            async printC(idDevis) {
                await this.loadOneDevisPlaque(idDevis);
                this.$htmlToPaper("printGlobalDevis");
            },
            
            loadFournisseurs(){
                axios.get("/api/CompteFournisseurs").then(({ data }) => (this.fournisseurs = data.data));
            },

            lancerDemandeDevis(){
                if(this.multipleSelection.length == 0){
                    this.$notify.error({
                        title: 'ATTENTION',
                        message: 'MERCI DE SELECTIONNER UNE OU PLUSIEURS PLAQUES A COMMANDER !!',
                        offset: 100,
                    });
                    
                }else{
                    this.loadFournisseurs();
                    this.formDevisPlaque.reset();
                    this.editmode = false;
                    this.DialogVisibleDevisPlaques = true;
                    console.log(this.multipleSelection);
                }
                
            },

            multipleDevisPlaque(){
                this.formDevisPlaque.PlaquesCommander = [];
                this.formDevisPlaque.type_de_demande = 1;

                for (let i = 0; i < this.multipleSelection.length; i++) {
                    this.formDevisPlaque.PlaquesCommander.push({
                        id_etc: this.multipleSelection[i].id_etc ,
                        id_fournisseur: this.multipleSelection[i].id_fournisseur ,
                        id_projet: this.multipleSelection[i].id,
                        type_de_demande: 1,
                    })
                }
            },

            async createDevisPlaque(){
 
                this.$Progress.start();
                await this.multipleDevisPlaque()

                this.formDevisPlaque.post('/api/DevisPlaqueGlobal')
                .then((data)=>{
                    if(data.data.success){
                    this.DialogVisibleDevisPlaques = false

                    Toast.fire({
                            icon: 'success',
                            title: data.data.message
                        });
                    this.$Progress.finish();
                    this.loadDevisGlobal();
                    this.loadDomandeDevis();

                    } else {
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });

                    this.$Progress.failed();
                    }
                })
                .catch(()=>{

                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
                })
                    
                },


            // end demande devis
              // change inputes

              
  
              next() {
                  // if (this.active++ > 2) this.active = 0;
                  this.active++;
              },
  
              prev() {
                  // if (this.active-- > 0) this.active = 0;
                  this.active--;
              },
  
              ImpressionCHanger(){
                  if(this.form.impression == 'Non'){
                      
                      this.form.nbr_clouleur='0'
                      this.form.couleurs=[],
                      this.form.recto='Non'
                      this.form.verso='Non'
                      this.form.verso_couleur=''
                      this.form.verso_ref=''
                      this.form.verso_formule=''
                      this.form.sens_sortie=''
                      this.form.type_sense_sortie=''
                      this.form.vernis='',
                      this.form.vernis_type='',
                      this.form.plastification='',
                      this.form.plastification_type='',
                      this.form.dorure='',
                      this.form.dorure_type='',
                      this.form.couleur_dorure=''
                  }
                  if(this.form.impression == 'OUI'){
                       this.form.couleurs=[{  couleur: "",ref:"",formule:"" }]
                  }
              },
              
              MandrinChange(){
                  if(this.form.mandrin != 'Autre'){
                      
                      this.form.mandrin_autre=''
                  }
              },
  
              // end condition of change inputs
  
              loadData(page = 1){
                  this.$Progress.start();
                  axios.get('api/devisETQ?page=' + page).then(({ data }) => (this.devisETQ = data.data.DevisETCToPrepared));
                  this.$Progress.finish();
              },
  
              loadDevisGlobal(page = 1){
                  this.$Progress.start();
                  axios.get('api/ProjetLancer?page=' + page).then(({ data }) => (this.devisGlobal = data.data.ProjetPlaque));
                  this.$Progress.finish();
              },

              loadDomandeDevis(page = 1){
                  this.$Progress.start();
                  axios.get('api/DevisPlaqueGlobal?page=' + page).then(({ data }) => (this.Domandedevis = data.data));
                  this.$Progress.finish();
              },
  
              loadDataPrepared(page = 1){
                  this.$Progress.start();
                  axios.get('api/devisETQ?page=' + page).then(({ data }) => (this.devisETQPrepared = data.data.DevisETCPrepared));
                  this.$Progress.finish();
              },
             
  
              loadClients(){
                  axios.get("api/indexClients").then(({ data }) => (this.clients = data.data));
                  // console.log("load CLient ok");
              },
  
              ExistClientCHange(){
                  if(this.form.existClient == 'Non'){
                      this.form.client = [{  name:"",tele:"",email:"" }];
                      // this.loadClients();
                      // console.log(this.form.existClient);
                      // console.log(this.clients);
                  }else{
                      this.form.id_client = '';
                  }
              },
  
              RectoCouleurCHange(){
                  if(this.form.recto == 'Non'){
                      this.form.couleurs=[{  couleur: "",ref:"",formule:"" }]
                      this.fomr.nbr_clouleur = ''
                  }
              },
  
              VersoCouleurCHange(){
                  if(this.form.verso == 'Non'){
                      this.form.verso_couleur=''
                      this.form.verso_ref=''
                      this.form.verso_formule=''
                  }
              },
  
  
              filterETC(){
                  if(this.search != ''){
                      this.$Progress.start();
                      axios.get('api/filterETC/' + this.search).then(({ data }) => (this.devisETQ = data.data));
                      this.$Progress.finish();
                  }else{
                      this.loadData();
                  }
              },
  
              filterETCPrepared(){
                  if(this.search1 != ''){
                      this.$Progress.start();
                      axios.get('api/filterETCPrepared/' + this.search1).then(({ data }) => (this.devisETQPrepared = data.data));
                      this.$Progress.finish();
                  }else{
                      this.loadDataPrepared();
                  }
              },
  
              newDevis(){
                  this.form.reset();
                  this.editmode = false;
                  this.disableDevisPV = false;
                  this.DialogVisible_global = true;
                  this.active=0;
              },

              lancerProjets(row){

                this.form_projetLancer.reset();
                this.editmode = true;
                // this.disableDevisPV = false;
                this.DialogVisible_ProjetLancer = true;
                // this.active=0;
                this.typeLancementCMD = row.get_client.typeLancementCMD;
                this.RLancementCMDName = row.get_client.RLancementCMDName;
                this.RLancementCMDemail = row.get_client.RLancementCMDemail;
                this.RLancementCMDtele = row.get_client.RLancementCMDtele;
                
                this.projetETQ = row.get_devis

              },
  
              async updateDevisGlobal(devisGlobal){
                  
                  await this.get_clientDevis(devisGlobal);
  
                  this.form_global.reset();
                  this.editmode = true;
                  this.disableDevisPV = false;
                  this.DialogVisible_global = true;
                  this.active=0;
                 
                  this.form_global.id = devisGlobal.id,
                  this.form_global.id_client = devisGlobal.id_client,
                  this.form_global.existClient = devisGlobal.existClient,
                  this.form_global.dateDevis = devisGlobal.dateDevis,
                  this.form_global.modalite_paiment = devisGlobal.modalite_paiment,
                  this.form_global.type_ISE = devisGlobal.type_ISE
                  this.form_global.Etat_devis = devisGlobal.Etat_devis
              },
  
              updateDevis1(devis){
                  this.editmode = true;
                  this.disableDevisPV = false;
                  this.active=0;
                  this.form.reset();
                  this.DialogVisible = true;
                  this.form.fill(devis);
                  this.form.existClient = 'Non';
                  this.form.couleurs = [];
                  for (let i = 0; i < devis.get_couleurs.length; i++) {
                      this.form.couleurs.push({
                          id_couleur: devis.get_couleurs[i].id,
                          couleur: devis.get_couleurs[i].couleur,
                          ref: devis.get_couleurs[i].ref,
                          formule: devis.get_couleurs[i].formule,
                      })
                  }
              },
              updateDevis(devis){
                  this.editmode = true;
                  this.disableDevisPV = true;
                  this.active=0;
                  this.form.reset();
                  this.DialogVisible = true;
                  this.form.fill(devis);
                  this.form.existClient = 'Non';
                  this.form.couleurs = [];
                  for (let i = 0; i < devis.get_couleurs.length; i++) {
                      this.form.couleurs.push({
                          id_couleur: devis.get_couleurs[i].id,
                          couleur: devis.get_couleurs[i].couleur,
                          ref: devis.get_couleurs[i].ref,
                          formule: devis.get_couleurs[i].formule,
                      })
                  }
              },
              
  
              PreparationDevis(devis){
                  this.editmode = true;
                  this.form.reset();
                  this.DialogVisibleP = true;
                  this.form.fill(devis);
              },
  
              tableRowClassName({row, rowIndex}) {
                  
                  if (row.preparation == 0) {                 
                      return 'info-row';
                  } else if (row.preparation == 1 && row.validation3 == 0) {
                      return 'warning-row';
                  }else if (row.preparation == 1 && row.validation3 == 1) {
                      return 'success-row';
                  }
                  return '';
              },

            handleSelectionChange(val) {
                this.multipleSelection = val;
            },

            
  
            // lancer cmd

            // file bon de commander
            newFile(){
                this.filename ='';
                
                this.DialogVisibleF = true;
            },
            getFile(file) {
                window.open("/upload/"+file, "_blank");
            },
            onFileChange(e) {
                // console.log(e.target.files[0].name);
                this.filename = "FICHIER SÉLECTIONNÉ : " +e.target.files[0].name;
                this.file = e.target.files[0];
            },

            submitForm(e) {
            this.$Progress.start();
            
            e.preventDefault();
            // let currentObj = this;
            // const config = {
            //     headers: {
            //         "content-type": "multipart/form-data",
            //         "X-CSRF-TOKEN": document.querySelector(
            //             'meta[name="csrf-token"]'
            //         ).content
            //     }
            // };

            // let formData = new FormData();
            // formData.append("file", this.file);

            this.DialogVisibleF = false;
            
            this.$Progress.finish();
            axios.post("api/store_file", formData, config)
                
                .then(response => {
                    this.DialogVisibleF = false;
                    Toast.fire({
                        icon: "success",
                        title: response.data.message
                    });
                    this.$Progress.finish();
                    
                    // this.loadFiles();
                }
                )
                .catch(function(error) {
                    currentObj.output = error;
                });
                
        },

        async  UploadFile() {
            // this.$Progress.start();
            
            // e.preventDefault();
            if(this.file !=''){
                let currentObj = this;
                    const config = {
                        headers: {
                            "content-type": "multipart/form-data",
                            "X-CSRF-TOKEN": document.querySelector(
                                'meta[name="csrf-token"]'
                            ).content
                        }
                    };

                    let formData = new FormData();
                    formData.append("file", this.file);

                await axios.post("api/store_file", formData, config)
                        
                        .then(response => {
                            this.DialogVisibleF = false;
                            Toast.fire({
                                icon: "success",
                                title: response.data.message
                            });
                            
                            this.form_projetLancer.id_bon_cmd = response.data.data.id
                            this.$Progress.finish();  
                        }
                        )
                        .catch(function(error) {
                            currentObj.output = error;
                        });
                    }

                this.lancerProjects();
        },
        // end bon de commande
            setCurrent(row) {
                this.$refs.multipleTable.setCurrentRow(row);
                console.log("clear rows");
            },
            CloseDataLancer(){
                this.DialogVisible_ProjetLancer = false;
                this.multipleSelection = [{  id_etc: "",qtit_lancer:"",date_livraison:"",lieu_livraison:"",
                    remarque:"--",urgence:"",qtit_urgence:"",lieu_livraison_urgence:"",date_livraison_urgence:""}]
            },

            lancerProjects(){
                this.form_projetLancer.ProjetLancer=[];
                var etat = "--";
                for (let i = 0; i < this.multipleSelection.length; i++) {
                    if(this.multipleSelection[i].impression == "Non" && this.multipleSelection[i].form_decoupe == "Non"){
                        etat = "PFD à préparer"
                    }
                    else if(this.multipleSelection[i].impression == "Non" && this.multipleSelection[i].form_decoupe == "OUI"){
                        etat = "OF à preparer"
                    }
                    else if(this.multipleSelection[i].impression == "OUI" && this.multipleSelection[i].form_decoupe == "Non"){
                        etat = "PFD et conception à préparer"
                    }
                    else if(this.multipleSelection[i].impression == "OUI" && this.multipleSelection[i].form_decoupe == "OUI"){
                        etat = "Conception à préparer"
                    }else{
                        etat = "x"
                    }
                    if(this.multipleSelection[i].urgence == "OUI"){
                        
                        this.form_projetLancer.ProjetLancer.push({
                        id_etc: this.multipleSelection[i].id ,
                        id_client: this.multipleSelection[i].id_client ,
                        qtit_lancer: this.multipleSelection[i].qtit_lancer,
                        etat_projet : etat,
                        //
                        date_livraison: this.multipleSelection[i].date_livraison,
                        lieu_livraison: this.multipleSelection[i].lieu_livraison,
                        remarque: this.multipleSelection[i].remarque,
                        urgence: this.multipleSelection[i].urgence,
                        //
                        qtit_urgence: this.multipleSelection[i].qtit_urgence,
                        lieu_livraison_urgence: this.multipleSelection[i].lieu_livraison_urgence,
                        date_livraison_urgence: this.multipleSelection[i].date_livraison_urgence,
                    })
                    }else{
                        this.form_projetLancer.ProjetLancer.push({
                            id_etc: this.multipleSelection[i].id ,
                            id_client: this.multipleSelection[i].id_client ,
                            qtit_lancer: this.multipleSelection[i].qtit_lancer,
                            etat_projet : etat,
                            //
                            date_livraison: this.multipleSelection[i].date_livraison,
                            lieu_livraison: this.multipleSelection[i].lieu_livraison,
                            remarque: this.multipleSelection[i].remarque,
                            urgence: this.multipleSelection[i].urgence,
                            // 
                            qtit_urgence: 0,
                            lieu_livraison_urgence: "-",
                            date_livraison_urgence: new Date().toISOString().slice(0,10),
                        })
                    }
                    
                }
            },


              async createDataLancer(){
             
              this.$Progress.start();

              await this.UploadFile();
           
                this.form_projetLancer.post('api/ProjetLancer')
                    .then((data)=>{
                    if(data.data.success){
                    this.DialogVisible_ProjetLancer = false
    
                        Toast.fire({
                            icon: 'success',
                            title: data.data.message
                        });
                        this.$Progress.finish();
                        this.loadDevisGlobal();
                        
                        this.multipleSelection = []
    
                    } else {
                        Toast.fire({
                            icon: 'error',
                            title: 'Some error occured! Please try again'
                        });
    
                        this.$Progress.failed();
                    }
                    })
                    .catch(()=>{
    
                        Toast.fire({
                            icon: 'error',
                            title: 'Some error occured! Please try again'
                        });
                    })
            },
  
            updateGlobalData(){
              this.$Progress.start();
                this.form_global.put('api/UpdateDevisGlobal/'+this.form_global.id)
                .then((response) => {
                  // this.editmode = false;
                  this.DialogVisible_global = false
                    Toast.fire({
                      icon: 'success',
                      title: response.data.message
                    });
                    this.loadDevisGlobal();
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$Progress.fail();
                });
                
              // console.log(this.form_global.id);
            },
  
            updateData(){
                this.$Progress.start();
                this.form.put('api/devisETQ/'+this.form.id)
                .then((response) => {
                    // success
                  //   $('#addNew').modal('hide');
                    this.DialogVisible = false
                    Toast.fire({
                      icon: 'success',
                      title: response.data.message
                    });
                    this.$Progress.finish();
                        //  Fire.$emit('AfterCreate');
  
                    this.loadDevisGlobal();
                  //   this.loadDataPrepared();
                })
                .catch(() => {
                    this.$Progress.fail();
                });
  
            },
  
            PreparationData(){
                this.$Progress.start();
                this.form.preparation = 1;
                this.form.put('api/preparationETQ/'+this.form.id)
                .then((response) => {
                    // success
                    this.DialogVisibleP = false
                    this.form.preparation = 0;
                    Toast.fire({
                      icon: 'success',
                      title: response.data.message
                    });
                    this.$Progress.finish();
  
                    this.loadDevisGlobal();
                  //   this.loadDataPrepared();
                })
                .catch(() => {
                    this.$Progress.fail();
                });
  
            },
  
            deletData(id){
                Swal.fire({
                    title: 'Attention !',
                    text: "vous voulez vraiment supprimer ce devis",
                    showCancelButton: true,
                    confirmButtonColor: '#67c23a',
                    cancelButtonColor: '#f56c6c',
                    confirmButtonText: 'Oui, supprimez-le ! '
                    }).then((result) => {
  
                        // Send request to the server
                          if (result.value) {
                                this.form.delete('api/devisETQ/'+id).then(()=>{
                                        Swal.fire(
                                        'Supprimé !',
                                        'Votre Devis a été Bien supprimé.',
                                        'success'
                                        );
                                    // Fire.$emit('AfterCreate');
                                    this.loadData();
                                    this.loadDataPrepared();
                                }).catch((data)=> {
                                    Swal.fire("Failed!", data.message, "warning");
                                });
                          }
                    })
            },
  
            deletDevisGlobal(id){
                Swal.fire({
                    title: 'Attention !',
                    text: "vous voulez vraiment supprimer ce devis",
                    showCancelButton: true,
                    confirmButtonColor: '#67c23a',
                    cancelButtonColor: '#f56c6c',
                    confirmButtonText: 'Oui, supprimez-le ! '
                    }).then((result) => {
  
                        // Send request to the server
                          if (result.value) {
                                this.form.delete('api/destroyDevisGlobal/'+id).then(()=>{
                                        Swal.fire(
                                        'Supprimé !',
                                        'Votre Devis a été Bien supprimé.',
                                        'success'
                                        );
                                    // Fire.$emit('AfterCreate');
                                    this.loadDevisGlobal();
                                  //   this.loadDataPrepared();
                                }).catch((data)=> {
                                    Swal.fire("Failed!", data.message, "warning");
                                });
                          }
                    })
            },
  
  
  // ENd CRUD Devis
  
          //   Multiple Couleurs
              deleteRow(index, couleurs) {
                  var idx = this.form.couleurs.indexOf(couleurs);
                  console.log(idx, index);
                  if (idx > -1) {
                      this.form.couleurs.splice(idx, 1);
                  }
              },
              addNewRowGlobal() {
                  this.ArticlesForm.couleurs.push({
                      couleur: '',
                      ref: '',
                      formule: '',
                  })
              },
  
              addNewRow() {
                  this.form.couleurs.push({
                      couleur: '',
                      ref: '',
                      formule: '',
                  })
              },
  
              // End Multiple Couleurs
  
              // multiple devis
              addNewRowDevis() {
                  var imprission=''
                  var front=''
  
                  if(this.ArticlesForm.nbr_etq_front >= 2){
                      front = this.ArticlesForm.nbr_etq_front+'P'
                  }
  
                  if(this.ArticlesForm.impression == 'OUI'){
                      imprission = 'IMPRIMÉ'
                  }
  
                  this.form_global.ArticlesCommander.push(
                  {
                      typeEtiquette:this.ArticlesForm.typeEtiquette,
                      nom_etc:this.ArticlesForm.typeEtiquette +' SUR '+this.ArticlesForm.support_papier+' '+this.ArticlesForm.formatMM_L+'x'+this.ArticlesForm.formatMM_D
                              +' '+front+' '+imprission +' '+this.ArticlesForm.nom_etc.toUpperCase(),
                      // 
                      conception:this.ArticlesForm.conception,
                      formatMM_L:this.ArticlesForm.formatMM_L,
                      formatMM_D:this.ArticlesForm.formatMM_D,
                      forme_etq:this.ArticlesForm.forme_etq,
                      support_papier:this.ArticlesForm.support_papier,
                      quantite_etq:this.ArticlesForm.quantite_etq,
                      impression:this.ArticlesForm.impression,
                      nbr_clouleur:this.ArticlesForm.nbr_clouleur,
                      couleurs: this.ArticlesForm.couleurs,
                      recto:this.ArticlesForm.recto,
                      verso:this.ArticlesForm.verso,
                      verso_couleur:this.ArticlesForm.verso_couleur,
                      verso_ref:this.ArticlesForm.verso_ref,
                      verso_formule:this.ArticlesForm.verso_formule,
                      sens_sortie:this.ArticlesForm.sens_sortie,
                      type_sense_sortie:this.ArticlesForm.type_sense_sortie,
                      repiquage:this.ArticlesForm.repiquage,
                      vernis:this.ArticlesForm.vernis,
                      vernis_type:this.ArticlesForm.vernis_type,
                      plastification:this.ArticlesForm.plastification,
                      plastification_type:this.ArticlesForm.plastification_type,
                      dorure:this.ArticlesForm.dorure,
                      dorure_type:this.ArticlesForm.dorure_type,
                      couleur_dorure:this.ArticlesForm.couleur_dorure,
                      nbr_etq_bobine:this.ArticlesForm.nbr_etq_bobine,
                      nbr_etq_front:this.ArticlesForm.nbr_etq_front,
                      mandrin:this.ArticlesForm.mandrin,
                      mandrin_autre:this.ArticlesForm.mandrin_autre,
                      poses_etq:this.ArticlesForm.poses_etq,
                      form_decoupe:this.ArticlesForm.form_decoupe,
                      form_decoupe_id:this.ArticlesForm.form_decoupe_id,
                      films_cliches:this.ArticlesForm.films_cliches,
                      films_cliches_id:this.ArticlesForm.films_cliches_id,
                      }
                  )
  
                  // console.log(this.form.ArticlesCommander);
                  this.ArticlesForm.reset();
                  
              },
  
              deleteRowDevis(index, ArticlesCommander) {
                  var idx = this.form_global.ArticlesCommander.indexOf(ArticlesCommander);
                  // console.log(idx, index);
                  if (idx > -1) {
                      this.form_global.ArticlesCommander.splice(idx, 1);
                  }
              },
              // End Multiple devis
  
              handleClose(done) {
                  this.$confirm('Voulez-vous vraiment fermer cette boîte de dialogue ?')
                  .then(_ => {
                      done();
                  })
                  .catch(_ => {});
              },
  
              handleCurrentChange2(val) {
                  this.currentRow = val;
              },
  
              
  // Start Calculs
  
              calculePosesT(){
                  var total;
                  var espaceD;
                  var calculeD;
  
                   total = (this.form.Nombre_PosesL * this.form.nombre_PosesD);
                   calculeD = this.form.anamorphose / this.form.nombre_PosesD
                   espaceD = calculeD - this.form.formatMM_D;
  
                   if (!isNaN(total)) {
                      this.form.nombre_poseT = total;
                      this.form.espace_D = espaceD.toFixed(2);
                      this.form.developer_Calculer = calculeD.toFixed(2);
                  } else {
                      this.form.nombre_poseT = '0';
                      this.form.espace_D = '0';
                      this.form.developer_Calculer = '0';
                  }
  
                  this.calculLaizeTH();
                  
              },
  
              changeSelectClient(){
                console.log('client selected ok');
              },

               changeSelectMachine () {
                   
                      switch (this.form.Machine) {
                          case 'GIDUE-370':
                              this.Zcylindre = this.Machine1;
                              
                              break;
                          case 'NILPITER-B200':
                              this.Zcylindre = this.Machine2;
                             
                              break;
                          case 'ROTOFLEX-400':
                              this.Zcylindre = this.Machine3;
                              
                              break;
                          }
  
  
                  },
              // calcul anamorphose
              calculeAnamorph(){
                  var  total;
  
                  total = (this.form.Z_cylindre * 3.175);
                  this.form.espace_L = 3;
                   if (!isNaN(total)) {
                      this.form.anamorphose = total.toFixed(2);
                  } else {
                      this.form.anamorphose = '0.00'
                  }
  
                  this.calculePosesT();
              },
  
              calculLaizeTH(){
                  var totalTH;
  
                  totalTH = (this.form.formatMM_L * this.form.Nombre_PosesL  + (Number(this.form.espace_L)  * (this.form.Nombre_PosesL - 1))) + 10 ;
                  // totalTH = this.form.formatL * this.form.nbrPosesL
  
                   if (!isNaN(totalTH)) {
                      this.form.laize_tehorique = totalTH;
                  } else {
                      this.form.laize_tehorique = '0'
                  }
              },
  
              // 
              
              // quantité papier ..
              calculeConsomationPP(){
                  var vr = 0;
                  var vrTirage = 0;
                  var qpc;
                  var bobine;
  
                  // for papier calcul
  
                  if(this.form.recto == 'oui' && this.form.verso == 'oui'){
                      vr = 600;
                  }else if(this.form.recto == 'oui' && this.form.verso == 'non'){
                      vr = 200;
                  }else {
                      vr = 150;
                  }
  
                  // for tirage calcu
                  if(this.form.recto == 'OUI' && this.form.verso == 'OUI' && this.form.support_papier == '-'){
                      
                      vrTirage = 600;
                  }else if(this.form.recto == 'OUI' && this.form.verso == 'OUI' && this.form.support_papier != '-'){
                      
                      vrTirage = 800;
                  }else if(this.form.recto == 'OUI' && this.form.verso == 'Non' && this.form.support_papier == '-'){
                      
                      vrTirage = 500;
                  }else if(this.form.recto == 'OUI' && this.form.verso == 'Non' && this.form.support_papier != '-'){
                      
                      vrTirage = 700;
                  }
                  // else if(this.form.recto == 'Non' && this.form.verso == 'Non' && this.form.support_papier == '-'){
                      
                  //     vrTirage = 280;
                  // }
                  else {
                      
                      vrTirage = 300;
                  }
  
                  this.form.vr = vr;
                  this.form.vrTirage = vrTirage;
  
                  qpc = (((this.form.quantite_etq/1000)/this.form.nombre_poseT)*this.form.anamorphose)+vr;
                  bobine = qpc / 2000;
  
                  if (!isNaN(qpc)) {
                      this.form.qtit_PapierML = qpc.toFixed(3);
                      this.form.nbr_bobine_consomme = bobine.toFixed(3);
                  } else {
                      this.form.qtit_PapierML = '0';
                      this.form.nbr_bobine_consomme = '0'
                  }
                  
                  this.ConvertMLtoM2();
              },
  
              
  
              ConvertMLtoM2(){
                  var total;
                   total = (this.form.qtit_PapierML * this.form.laize_Stock)/1000;
  
                   if (!isNaN(total)) {
                      this.form.qtit_PapierM2 = total.toFixed(2);
                  } else {
                      this.form.qtit_PapierM2 = '0'
                  }
  
                  this.calculLaizeTH();
              },
  
              calculeCoutPP(){
                  var cout;
  
                  cout = this.form.qtit_PapierM2*(this.form.prixEURO * 11.5 + 0.18)
  
                  if (!isNaN(cout)) {
                      this.form.cout_Papier = cout.toFixed(2);
                  } else {
                      this.form.cout_Papier = '0'
                  }
                  
              },
  
              // 
              CalculeTirage(){
                  var total;
                   total = (this.form.qtit_PapierML / 2000) * this.form.vrTirage;
  
                   if (!isNaN(total)) {
                      this.form.tirage = total.toFixed(3);
                  } else {
                      this.form.tirage = '0'
                  }
  
                  // this.calculLaizeTH();
              },
  
              CalculeDuree(){
                  var total1;
                  var total2;
  
                   total1 = ((this.form.qtit_PapierML * 35)/2000)/60;
                   total2 = ((this.form.qtit_PapierML * 40)/2000)/60;
  
                  console.log(this.form.qtit_PapierML , total1 , )
                   if (!isNaN(total1)) {
                      this.form.duree_production = total1.toFixed(0);
                      this.form.duree_conditionement = total2.toFixed(0);
                  } else {
                      this.form.duree_conditionement = '0';
                      this.form.duree_production = '0'
                  }
  
                  // this.calculLaizeTH();
              },
  
              // 
              CalculeMandrin(){
                  var total;
                  var format;
                  var cout;
                  var array = [];
  
                   total = this.form.quantite_etq / this.form.nbr_etq_bobine;
                   format = this.form.formatMM_L * this.form.nbr_etq_front;
                   cout = total * 0.5;
  
                   if (!isNaN(total)) {
                      this.form.nbr_mandrin_consomme = total.toFixed(0);
                      this.form.format_mandrin = format;
                      this.form.cout_revien_mandrin = cout.toFixed(2);
                  } else {
                      this.form.nbr_mandrin_consomme = '0';
                      this.form.format_mandrin = '0';
                      this.form.cout_revien_mandrin = '0'
                  }
                  
                  // for (let index = 0; index < this.Mandrin_Stock.length; index++) {
                      
                  //     if(this.form.format_mandrin >= this.Mandrin_Stock[index].value && this.form.format_mandrin - 10 <= this.Mandrin_Stock[index].value){
                  //         console.log(this.Mandrin_Stock[index].label)
                  //     }                    
                  // }
              },
  
              CalculeFraisFormDecoup(){
                  var total =0;
  
                  total = this.form.frais_formDecoup*this.form.qtit_formDecoup;
              
                  this.form.total_frais_formDecoup = total;
              },
  
              CalculeFraisCliches(){
                  var totalRecto;
                  var totalVerso;
                  var total;
                  var verso;
                  if(this.form.verso == "Non"){
                      verso = 0;
                  }else{
                      verso = 1
                  }
  
                   totalRecto = ((this.form.anamorphose * this.form.laize_Stock) / 100 ) * this.form.nbr_clouleur * 0.42 * 1.2;
                   totalVerso = ((this.form.anamorphose * this.form.laize_Stock) / 100 )*verso;
                   total = totalRecto + totalVerso;
  
                   if (!isNaN(totalRecto) || totalRecto > 0) {
                      this.form.frais_recto = totalRecto.toFixed(2);
                      this.form.frais_verso = totalVerso.toFixed(2);
                      this.form.FTotal_Cliches = total.toFixed(2);
                  } else {
                      this.form.frais_recto = '0';
                      this.form.frais_verso = '0';
                      this.form.FTotal_Cliches = '0';
                  }
  
                  // this.calculLaizeTH();
              },
  
              // 
              CalculeMargeBenific(){
                  var total = 0;
                  var total2 = 0;
                  var total3 = 0;
                  // var totalSansFDC,totalSansFD,totalSansC = 0;
                  // 
                  var totalSansFDC,totalSansFD,totalSansC = 0;
                  // var totalSansFDC = 0;
  
                  total = (Number(this.form.cout_Papier) + Number(this.form.cout_revien_mandrin) + Number(this.form.tirage) + Number(this.form.FTotal_Cliches) + Number(this.form.frais_formDecoup)) / this.form.quantite_etq;
                  
                  totalSansFDC = (Number(this.form.cout_Papier) + Number(this.form.cout_revien_mandrin) + Number(this.form.tirage)) / this.form.quantite_etq;
                  totalSansFD = (Number(this.form.cout_Papier) + Number(this.form.cout_revien_mandrin) + Number(this.form.tirage) + Number(this.form.FTotal_Cliches)) / this.form.quantite_etq;
                  totalSansC = (Number(this.form.cout_Papier) + Number(this.form.cout_revien_mandrin) + Number(this.form.tirage) + Number(this.form.frais_formDecoup)) / this.form.quantite_etq;
  
                  totalSansFDC = totalSansFDC + (totalSansFDC * (this.form.marge_benif / 100));
                  totalSansFD = totalSansFD + (totalSansFD * (this.form.marge_benif / 100));
                  totalSansC = totalSansC + (totalSansC * (this.form.marge_benif / 100));
                  total2 = (this.form.cout_hors_FDC * (this.form.marge_benif / 100)) + total;
                  
                  total3 = total2*1000;
  
                  if (!isNaN(total2)) { this.form.prixHTT = total2.toFixed(3); } else { this.form.prixHTT = '0' }
  
                  if (!isNaN(totalSansFDC)) { this.form.totalSansFDC  = totalSansFDC.toFixed(3)} else {this.form.totalSansFDC  = '0' }
                  
                  if (!isNaN(totalSansFD)) { this.form.totalSansFD  = totalSansFD.toFixed(3)} else {this.form.totalSansFD  = '0' }
                  
                  if (!isNaN(totalSansC)) { this.form.totalSansC  = totalSansC.toFixed(3)} else {this.form.totalSansC  = '0' }
  
                  if (!isNaN(total3)) { this.form.prixParMill = total3.toFixed(3); } else { this.form.prixParMill = '0' }
  
                  if (!isNaN(total)) { this.form.cout_hors_FDC = total.toFixed(3); } else { this.form.cout_hors_FDC = '0' }
  
                  // this.calculLaizeTH();
              },
  
  // End Calcules
  // Invoice
            //   printC() {
            //       this.$htmlToPaper("printMe");
            //   },

                
  
              get_clientDevis(row){
                  var total=0;
                  var total1 =0;
                  var total2= 0;
                  var total3 = 0;
                  // this.Offer_Client = row.get_client;
                  this.Offer_devis = row.get_devis;
                  this.id_offer = row.id
                  this.form_decoupe = row.form_decoupe;
                  this.films_cliches = row.films_cliches;
                  
                  this.userName = row.get_user.name;
                  this.nameClient = row.get_client.name;
                  this.teleClient = row.get_client.tele;
                  this.emailClient = row.get_client.email;
                  this.AdressClient = row.get_client.siegeSocial;
                  this.GlobalModalitePaiment = row.modalite_paiment;
                  this.type_ISE = row.type_ISE;
  
                  console.log(this.type_ISE);
  
                  if(this.GlobalModalitePaiment == "TVA"){
                      this.tvaCentage = 20;
                      this.tvaCentage2 = 0.2;
                  } else {
                      this.tvaCentage = 0;
                      this.tvaCentage2 = 0;
                  }
  
                  for (let i = 0; i < row.get_devis.length; i++) {
                      if(this.type_ISE == 'inclus'){
                          total += (this.Offer_devis[i].quantite_etq * this.Offer_devis[i].prixHTT)
  
                          if(this.tvaCentage == 20){
                              this.tva = Number(total*0.2);
                          }else{
                              this.tva = Number(total*0);
                          }
                      }
  
                      if(this.type_ISE == 'exclus'){
                          total += (this.Offer_devis[i].quantite_etq * this.Offer_devis[i].totalSansFDC)
                          if(this.tvaCentage == 20){
                              this.tva = Number(total*0.2);
                          }else{
                              this.tva = Number(total*0);
                          }
                      }
  
                      if(this.type_ISE == 'separee'){
  
                          total1 = Number(this.Offer_devis[i].quantite_etq * this.Offer_devis[i].totalSansFDC),
                          total2 = Number(this.Offer_devis[i].FTotal_Cliches),
                          total3 = Number(this.Offer_devis[i].total_frais_formDecoup),
                          total +=  (Number(this.Offer_devis[i].quantite_etq * this.Offer_devis[i].totalSansFDC) + Number(this.Offer_devis[i].FTotal_Cliches) + Number(this.Offer_devis[i].total_frais_formDecoup))
  
                          if(this.tvaCentage == 0){
                              this.tva = Number(total2*0.2)+Number(total3*0.2);
                          }
                      }
                  }
  
                  this.GlobalTotalHT = total;
                  if(this.tvaCentage == 20){
                      this.tva = total*this.tvaCentage2;
                  }
  
                  var x = total + this.tva;
                 
                  this.GlobalTotalTTC = x.toFixed(0);
  
              },
  
              async printGlobalDevis(row){
                  
                  await this.get_clientDevis(row),
  
                  // console.log(NumberToLetter(this.GlobalTotalTTC)+' : '+this.GlobalTotalTTC)
  
                  this.$htmlToPaper("printMe");
              },
  
               InvoiceModal() {
              this.editmode = false;
              this.form.reset();
              $("#Invoice").modal("show");
          },
  
  // End Invoice
  
          handleCurrentChange(cpage) {
                  this.currpage = cpage;
              },
          handleSizeChange(psize) {
                  this.pagesize = psize;
              },
  
  
          },
          mounted() {
  
          },
          created() {
              this.$Progress.start();
  
              this.loadData();
              this.loadDevisGlobal();
              this.loadDomandeDevis();
              this.loadClients();
  
              this.$Progress.finish();
          },
          filters: {
              OfferDates(value){
                  return moment(String(value)).format('MM-DD-YY')
              },
              FormNumbers(value){
                  return numeral(value).format("0,0.00");
              },
              FormNumbers2(value){
                  return numeral(value).format("0,0");
              },
              NumberToLetterM(value){
                  // console.log(value);
                  var x = NumberToLetter(value);
                  return x.toUpperCase();
              },
              RemarqueDates(value){
                return moment(String(value)).format('MM/DD/YYYY HH:mm')
            }
          },
          computed: {
          
          },
      }
  </script>
  <style>
    /* @import './checkstyle.css'; */
    /*  */
    .el-row {
      margin-bottom: 20px;
    }
  
    last-child {
        margin-bottom: 0;
      }
    .el-col {
      border-radius: 4px;
    }
    .bg-purple-dark {
      background: #99a9bf;
    }
    .bg-purple {
      background: #d3dce6;
    }
    .bg-purple-light {
      background: #e5e9f2;
    }
    .grid-content {
      border-radius: 4px;
      min-height: 36px;
    }
    .row-bg {
      padding: 10px 0;
      background-color: #f9fafc;
    }
  </style>