<template>
    <section class="content">
<!-- {{ SpendsByMonth }} -->
      <div class="container-fluid">
        
        <div class="row row-cols-1 row-cols-md-2 row-cols-xl-4">
                <div class="col-lg-4">
                    <div class="card radius-10 border-start border-0 border-3 border-danger">
                        <div class="card-body">
                            <div class="d-flex align-items-center">
                                <div>
                                    <strong class="mb-0 text-secondary">Total Unpaid</strong>
                                    <h4 class="my-1 text-danger">{{CountSpend.countUnpaid}} DH</h4>
                                    <!-- <p class="mb-0 font-13 text-danger">{{(CountBooking.countCancelled*100 /CountBooking.countTotal) | FormNumbers2}} %</p> -->
                                </div>
                                <div class="widgets-icons-2 rounded-circle bg-gradient-bloody text-white ms-auto"><i class="fa fa-ban"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="card radius-10 border-start border-0 border-3 border-success">
                        <div class="card-body">
                            <div class="d-flex align-items-center">
                                <div>
                                    <strong class="mb-0 text-secondary">Total Paid</strong>
                                    <h4 class="my-1 text-success">{{CountSpend.countPaid}} DH</h4>
                                    <!-- <p class="mb-0 font-13 text-danger">{{(CountBooking.countCancelled*100 /CountBooking.countTotal) | FormNumbers2}} %</p> -->
                                </div>
                                <div class="widgets-icons-2 rounded-circle bg-gradient-ohhappiness text-white ms-auto"><i class="fa fa-check-square"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="card radius-10 border-start border-0 border-3 border-warning">
                        <div class="card-body">
                            <div class="d-flex align-items-center">
                                <div>
                                    <strong class="mb-0 text-secondary">The Rest</strong>
                                    <h4 class="my-1 text-orange">{{CountSpend.countPaid - CountSpend.countUnpaid}} DH</h4>
                                </div>
                                <div class="widgets-icons-2 rounded-circle bg-gradient-blooker text-white ms-auto"><i class="fa fa-cogs"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <!--  -->
       
        <!-- Start Table -->
        <div class="mb-3 mt-3">
                <el-card class="mb-3 mt-3">
                <div class="row">
                    <div class="col-lg-4">
                        <el-button type="primary" @click="newSpend()" size="small" icon="el-icon-plus" >Add New Spend</el-button>
                    </div>
                    <div class="col-lg-4 mt-3">
                        <el-select v-model="searchUser" @change="loadSUMByUser()"  filterable  style="width: 100%" placeholder="Select Plateforme">
                            <el-option label="Filter By Driver" value=""></el-option>
                            <el-option
                                v-for="item in Drivers"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                            ></el-option>
                        </el-select>
                    </div>
                    <div class="col-lg-4 mt-3">
                        <el-select @change="loadSUMByMonth()" v-model="month" style="width: 100%" placeholder="Filter By Month">
                            <el-option label="Filter By Month" value=""></el-option>
                            <el-option label="January" value="01"></el-option>
                            <el-option label="February" value="02"></el-option>
                            <el-option label="March" value="03"></el-option>
                            <el-option label="April" value="04"></el-option>
                            <el-option label="May" value="05"></el-option>
                            <el-option label="June" value="06"></el-option>
                            <el-option label="July" value="07"></el-option>
                            <el-option label="August" value="08"></el-option>
                            <el-option label="September" value="09"></el-option>
                            <el-option label="October" value="10"></el-option>
                            <el-option label="November" value="11"></el-option>
                            <el-option label="December" value="12"></el-option>
                        </el-select>
                    </div>

                        <div class="col-12 mt-3">
                        <el-table
                                :data="AllSpends.data" border >
                                <el-table-column type="expand">
                                <template slot-scope="props">
                                    <el-descriptions class="margin-top" :column="1" border>
                                        <el-descriptions-item>
                                            <template slot="label">
                                                <i class="el-icon-link"></i>
                                                Note
                                            </template>
                                            {{ props.row.note}}
                                        </el-descriptions-item>
                                    </el-descriptions>
                                </template>
                            </el-table-column>
                                <el-table-column label="Driver" align="center">
                                    <template slot-scope="scope">
                                        <strong>{{ scope.row.get_driver.name }}</strong>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Type of Spend" align="center">
                                    <template slot-scope="scope">
                                        <strong>{{ scope.row.type }}</strong>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Amount" align="center">
                                    <template slot-scope="scope">
                                        <strong>{{ scope.row.price }} DH</strong>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Date" align="center">
                                    <template slot-scope="scope">
                                        <strong>{{ scope.row.Date | OfferDates }}</strong>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Etat" width="100px" align="center">
                                    <template slot-scope="scope">
                                        <div v-if="scope.row.etat == 'Paid'">
                                            <el-tag  type="success" effect="dark" ><strong>INCOME</strong></el-tag>
                                        </div>
                                        <div v-if="scope.row.etat == 'Unpaid'">
                                            <el-tag  type="danger" effect="dark" ><strong>SPEND</strong></el-tag>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column width="100" align="center">
                                    <template slot-scope="{row}">
                                            <a href="#" @click="updateSpend(row)" data-toggle="tooltip" data-placement="top" title="Update">
                                                    <i class="fa fa-edit blue mr-2"></i>
                                            </a>
                                            <el-divider direction="vertical"></el-divider>
                                            <a href="#" @click="deletDataSpend(row.id)" data-toggle="tooltip" data-placement="top" title="Delete">
                                                <i class="fa fa-trash red"></i>
                                            </a>
                                    </template>
                                </el-table-column>
                        </el-table>
                        
                        <pagination class="mt-4" :limit="10" :keep-length="10" :data="AllSpends" @pagination-change-page="loadAllSpends"></pagination>
                        </div>
                        
                    </div>
                </el-card>
            </div>
        <!-- End Table -->
            <div class="mb-3 mt-3">
                <div v-show="!dashData" class="col-lg-4 mt-3 mb-3">
                    <el-button type="primary" @click="getChartPie()" size="small" icon="el-icon-plus" >Show Data</el-button>
                </div>
                <div v-show="dashData" class="row mb-5"  v-if="$gate.isAdmin()">
                    <!-- <div class="col-lg-6 mt-3">
                        <el-card>
                            <div slot="header" class="clearfix">
                                <strong> Statistics </strong>
                            </div>
                            <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
                        </el-card>
                    </div> -->
                    <div class="col-lg-6 mt-3">
                        
                        <!-- <el-select @change="loadSpendsByMonth" v-model="SelectedYear" style="width: 100%" placeholder="Filter By Year">
                            <el-option label="Filter By Year" value=""></el-option>
                            <el-option label="2023" value="2023"></el-option>
                            <el-option label="2024" value="2024"></el-option>
                        </el-select> -->
                        <el-card>
                            <div slot="header" class="clearfix">
                                <strong> Total Spend By Month </strong>
                            </div>
                            <apexchart type="bar" :options="chartOptionsSpends" :series="seriesSpends"></apexchart>
                        </el-card>
                    </div>
                </div>
            </div>
          
          <!-- dialog -->
          <el-dialog
            :title="editmode ? 'Update Spend':'New Spend'"
            :visible.sync="DialogVisible_spend"
            width="90%"
            :before-close="handleClose">
            <!-- Body dialog -->
                <el-form :model="form_spending" ref="form_spending" class="demo-ruleForm">
                    <fieldset class="row  border p-2 mt-2">
                        <legend  class="w-auto badge"><h5>Infos</h5></legend>
                            <el-form-item class="col-lg-4" label="Type" prop="type"
                                :rules="[
                                    { required: true, message: 'Type of Spend is required' }
                                ]">
                                <el-select @change="typechanged" v-model="form_spending.type"  style="width: 100%" placeholder="Type Of Spend">
                                        <el-option label="INCOME" value="Income"></el-option>
                                        <el-option label="Gas-oil" value="Gas-oil"></el-option>
                                        <el-option label="Food" value="Food"></el-option>
                                        <el-option label="Maintenance" value="Maintenance"></el-option>
                                        <el-option label="Tickets" value="Tickets"></el-option>
                                        <el-option label="Autre" value="Autre"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item class="col-lg-4" label="Amount" prop="price" 
                            :rules="[
                                { required: true, message: 'Amount is required' }
                            ]">
                                <el-input v-model="form_spending.price"  type="number" autocomplete="off">
                                    <template slot="append"><strong>DH</strong></template>
                                </el-input>
                            </el-form-item>
                            <!-- <el-form-item class="col-lg-3" label="Etat" prop="etat"
                                :rules="[
                                    { required: true, message: 'Etat of Spend is required' }
                                ]">
                                <el-select v-model="form_spending.etat" style="width: 100%" placeholder="Etat Of Spend">
                                    <el-option label="Unpaid" value="Unpaid"></el-option>    
                                    <el-option label="Paid" value="Paid"></el-option>
                                </el-select>
                            </el-form-item> -->
                            <el-form-item class="col-lg-4" label="Date" prop="Date" >
                                <el-date-picker placeholder="Pick a date" v-model="form_spending.Date" value-format="yyyy-MM-dd" style="width: 100%;"></el-date-picker>
                            </el-form-item>
                            <el-form-item class="col-lg-12" label="Description (Optional)" prop="note">
                                <el-input type="textarea" v-model="form_spending.note" autocomplete="off"></el-input>
                            </el-form-item>
                    </fieldset>
                </el-form>
                <!-- ENd body Dialog -->
                <span slot="footer" class="dialog-footer">
                    <el-button @click="DialogVisible_spend = false">Annuler</el-button>
                    <el-button type="primary" v-show="!editmode" @click="submitFormSpend('form_spending')">Valider</el-button>
                    <el-button type="primary" v-show="editmode" @click="updateDataSpend()">Valider</el-button>
                </span>
          </el-dialog>

          <!-- ENd dialog -->
          <!-- Modal files Bon Livraison -->
        
            <!-- end dialog file Bon Livraison -->
  
      </div>
     
    </section>
    <!--  -->
  </template>
  
  
  <script>
      import VueTagsInput from '@johmun/vue-tags-input';
      import moment from 'moment'
  
      export default {
        components: {
            VueTagsInput,
          },
          data () {
              return {

                // Chart

                 // Bar1
                chartOptionsSpends: {
                    chart: {
                    id: 'vuechart-exampleX'
                    },
                    xaxis: {
                    categories: []
                    }
                },
                seriesSpends: [{
                    name: 'Completed',
                    data: []
                }],

                // seriesSpends: [{
                //         name: 'Spends',
                //         data: []
                //     }, 
                // ],
                // chartOptionsSpends: {
                //     chart: {
                //     type: 'bar',
                //     },
                //     plotOptions: {
                //     bar: {
                //         horizontal: false,
                //         columnWidth: '55%',
                //         endingShape: 'rounded'
                //     },
                //     },
                //     dataLabels: {
                //     enabled: false
                //     },
                //     stroke: {
                //     show: true,
                //     width: 2,
                //     colors: ['transparent']
                //     },
                //     xaxis: {
                //     categories: [],
                //     },
                //     yaxis: {
                //         title: {
                //             text: 'dh (Spend)'
                //         }
                //     },
                //     fill: {
                //     opacity: 1
                //     },
                   
                // },
                series: [{
                            name: 'Gas-Oil',
                            data: []
                        }, 
                        {
                            name: 'Food',
                            data: []
                        }, 
                    ],
                chartOptions: {
                    chart: {
                    id: 'vuechart-exampleX',
                    type: 'bar',
                    
                    },
                    plotOptions: {
                    bar: {
                        horizontal: false,
                        endingShape: 'rounded'
                    },
                    },
                    dataLabels: {
                    enabled: false
                    },
                    stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                    },
                    xaxis: {
                    categories: [],
                    },
                    yaxis: {
                    title: {
                        text: 'dh (Spend)'
                    }
                    },
                    fill: {
                    opacity: 1
                    },
                    
                },

                // End Chart
                  DialogVisible_global: false,
                  DialogVisible_spend : false,
                  active: 0,
                  Hotels: [],
                  search: '',
                  month: '',
                  searchEtat: '',
                  searchCity: '',
                  searchHotels: '',
                  searchDate: '',
                  searchUser: '',
                  pagesize: 10,
                  currpage: 1,
                  editmode: false,
                  dashData: false,
                  products : [],
                  Drivers : [],
                  SpendsByMonth : [],
                  SelectedYear : '2023',

                //   
                DialogVisiblePrices:false,
                filename:'',


                  numberValidateForm: {
                    age: ''
                },
                
                  form_global: new Form({
                    id:'',
                    name:'',
                    email:'',
                    tele:'',
                    ville:'',
                    stars:'',
                    commercial_name:'',
                    commercial_email:'',
                    commercial_tele:'',
                    type:'',
                  }),

                  // spending
                Spending: [],
                AllSpends: [],
                CountSpend: [],

                form_spending : new Form({
                    id:'',
                    id_driver: '',
                    type: '',
                    price: '',
                    etat: '',
                    Date: '',
                    note: '',
                }),

                  autocompleteItems: [],
              }
          },
          methods: {       

            getChartPie(){

                var mydataG = [];
                var mydataF = [];
                var mydataM = [];
                // var mydataA = [];

                // this.loadSpendsByMonth();
                for (let i = 0; i < this.SpendsByMonth.SpendingMonth.length; i++) {
                   
                    // this.chartOptions.xaxis.categories.push(this.SpendsByMonth.SpendingMonth[i].month)
                    // this.chartOptionsSpends.xaxis.categories.push(this.SpendsByMonth.SpendingMonth[i].month)
                }

                for (let i = 0; i < this.SpendsByMonth.spendingG.length; i++) {
                   
                    mydataG.push(this.SpendsByMonth.spendingG[i].Price)
                }

                for (let i = 0; i < this.SpendsByMonth.spendingF.length; i++) {
                   
                    mydataF.push(this.SpendsByMonth.spendingF[i].Price)
                }
                
                for (let i = 0; i < this.SpendsByMonth.spendingM.length; i++) {
                   
                    mydataM.push(this.SpendsByMonth.spendingM[i].Price)
                    this.chartOptionsSpends.xaxis.categories.push(this.SpendsByMonth.spendingM[i].month)
                }
               
                this.series = [
                    {data: mydataG},
                    {data: mydataF},
                ]
                this.seriesSpends = [
                   
                    {data: mydataM},
                ]

                this.dashData = true;
            },
            
            typechanged(){

                if(this.form_spending.type == 'Income'){
                    this.form_spending.etat = 'Paid'
                }else{
                    this.form_spending.etat = 'Unpaid'
                }
            },

            loadAllDrivers(){
                
                this.$Progress.start();
                    axios.get('api/getUsers').then(({ data }) => (this.Drivers = data.data));
                this.$Progress.finish();
            },

            loadAllSpends(page = 1){
                
                this.$Progress.start();
                    axios.get('api/indexspending?page=' + page).then(({ data }) => (this.AllSpends = data.data));
                this.$Progress.finish();
            },

            loadSpendsByMonth(){
                this.$Progress.start();
                axios.get('api/SpendTotalByMonth/'+this.SelectedYear).then(({ data }) => (this.SpendsByMonth = data.data));
                this.$Progress.finish();  
            },

            

            // loadCalculsSpendByDriver(){
                
            //     this.$Progress.start();
            //         axios.get('api/DashCountSpendByUserFilter'+ this.searchUser).then(({ data }) => (this.CountSpend = data.data));
            //     this.$Progress.finish();
            // },

            loadSUMByUser(){
                
                if(this.searchUser != ""){
                    this.$Progress.start();
                     axios.get('api/DashCountSpendByUserFilter/' + this.searchUser).then(({ data }) => (this.CountSpend = data.data));
                    this.$Progress.finish();
                    
                }else{
                    this.loadAllSpends();
                }
                
            },

            loadSUMByMonth(){
                
                if(this.month != ""){
                    this.$Progress.start();
                     axios.get('api/filterSpendsDateAdmin/' + this.month).then(({ data }) => (this.AllSpends = data.data));
                    this.$Progress.finish();
                    
                }else{
                    this.loadAllSpends();
                }
                
            },

            // filterDate(){
            //     if(this.searchDate == null){
            //         this.loadAllSpends();
            //     }else{
            //         this.$Progress.start();
            //             axios.get('api/filterSpendsDate?page=' + page).then(({ data }) => (this.AllSpends = data.data));
            //         this.$Progress.finish();
            //     }
            // },

            

            loadCalculsSpend(){
                
                this.$Progress.start();
                    axios.get('api/DashCountSpend').then(({ data }) => (this.CountSpend = data.data));
                this.$Progress.finish();
            },

            

            newSpend(){
                this.form_spending.reset();
                this.editmode = false;
                // this.disableDevisPV = false;
                this.DialogVisible_spend = true;
                this.active=0;
            },

            submitFormSpend(formName) {
                this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.createData();
                } else {
                    console.log('error submit!!');
                    return false;
                }
                });
            },

            createData(){
            this.$Progress.start();

              this.form_spending.post('api/spending')
              .then((data)=>{
                if(data.data.success){
                 this.DialogVisible_spend = false

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.$Progress.finish();
                  this.loadAllSpends();
                  this.loadCalculsSpend();

                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          },

          updateSpend(spending){
                
                this.editmode = true;
                this.form_spending.reset();
                this.form_spending.fill(spending);
                this.DialogVisible_spend = true;
            },

            updateDataSpend(){
              this.$Progress.start();
              this.form_spending.put('api/spending/'+this.form_spending.id)
              .then((response) => {
                  // success
                  this.DialogVisible_spend = false
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });
                  this.$Progress.finish();

                  this.loadAllSpends();
                  this.loadCalculsSpend();
              })
              .catch(() => {
                  this.$Progress.fail();
              });

          },

          deletDataSpend(id){
              Swal.fire({
                  title: 'Attention !',
                  text: "You really want to delete this Spend ??",
                  showCancelButton: true,
                  confirmButtonColor: '#67c23a',
                  cancelButtonColor: '#f56c6c',
                  confirmButtonText: 'Yes, Delete-it ! '
                  }).then((result) => {

                      // Send request to the server
                        if (result.value) {
                              this.form_spending.delete('api/spending/'+id).then(()=>{
                                      Swal.fire(
                                      'Deleted !',
                                      'Spend Deleted Successfully',
                                      'success'
                                      );
                                  // Fire.$emit('AfterCreate');
                                  this.loadAllSpends();
                                  this.loadCalculsSpend();
                              }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                        }
                  })
          },

                // 

            filterType(){
                if(this.searchType != ""){
                    this.$Progress.start();
                    axios.get('api/filterType/' + this.searchType).then(({ data }) => (this.Hotels = data.data));
                    this.$Progress.finish();
                }else{
                    this.loadData();
                }
            },

            filterHotels(){
                if(this.searchHotels != ""){
                    this.$Progress.start();
                    axios.get('api/filterHotels/' + this.searchHotels).then(({ data }) => (this.Hotels = data.data));
                    this.$Progress.finish();
                }else{
                    this.loadData();
                }
            },

            filterCity(){
                if(this.searchCity != ""){
                    this.$Progress.start();
                    axios.get('api/filterCity/' + this.searchCity).then(({ data }) => (this.Hotels = data.data));
                    this.$Progress.finish();
                }else{
                    this.loadData();
                }
            },

            filterETC(){
                if(this.search != ''){
                    this.$Progress.start();
                    axios.get('api/filterHotels/' + this.search).then(({ data }) => (this.Hotels = data.data));
                    this.$Progress.finish();
                }else{
                    this.loadData();
                }
            },
            // CRUD
            newData(){
                this.form_global.reset();
                this.editmode = false;
                // this.disableDevisPV = false;
                this.DialogVisible_global = true;
                this.active=0;
            },

        //     createData(){
        //     this.$Progress.start();

        //       this.form_global.post('api/hotels')
        //       .then((data)=>{
        //         if(data.data.success){
        //          this.DialogVisible_global = false

        //           Toast.fire({
        //                 icon: 'success',
        //                 title: data.data.message
        //             });
        //           this.$Progress.finish();
        //           this.loadData();
        //         //   this.loadDataPrepared();

        //         } else {
        //           Toast.fire({
        //               icon: 'error',
        //               title: 'Some error occured! Please try again'
        //           });

        //           this.$Progress.failed();
        //         }
        //       })
        //       .catch(()=>{

        //           Toast.fire({
        //               icon: 'error',
        //               title: 'Some error occured! Please try again'
        //           });
        //       })
        //   },

          

            loadData(page = 1){
                this.$Progress.start();
                axios.get('api/hotels?page=' + page).then(({ data }) => (this.Hotels = data.data));
                this.$Progress.finish();
            },

            updateHotel(reservation){
                this.editmode = true;
                this.form_global.reset();
                this.DialogVisible_global = true;
                this.form_global.fill(reservation);
            },

            updateData(){
              this.$Progress.start();
              this.form_global.put('api/hotels/'+this.form_global.id)
              .then((response) => {
                  // success
                  this.DialogVisible_global = false
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });
                  this.$Progress.finish();

                  this.loadData();
              })
              .catch(() => {
                  this.$Progress.fail();
              });

          },

          deletData(id){
              Swal.fire({
                  title: 'Attention !',
                  text: "You really want to delete this Hotel ??",
                  showCancelButton: true,
                  confirmButtonColor: '#67c23a',
                  cancelButtonColor: '#f56c6c',
                  confirmButtonText: 'Yes, Delete-it ! '
                  }).then((result) => {

                      // Send request to the server
                        if (result.value) {
                              this.form_global.delete('api/hotels/'+id).then(()=>{
                                      Swal.fire(
                                      'Deleted !',
                                      'Hotel Deleted Successfully',
                                      'success'
                                      );
                                  // Fire.$emit('AfterCreate');
                                  this.loadData();
                              }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                        }
                  })
          },
        //   End CRUD

            handleClose(done) {
                this.$confirm('Are you sure you want to close this dialog ?')
                .then(_ => {
                    done();
                })
                .catch(_ => {});
            },

            handleCurrentChange2(val) {
                this.currentRow = val;
            },

            // END
  
           
            loadProducts(){
  
              // if(this.$gate.isAdmin()){
                axios.get("api/product").then(({ data }) => (this.products = data.data));
              // }
            },
            loadCategories(){
                axios.get("/api/category/list").then(({ data }) => (this.categories = data.data));
            },
            loadTags(){
                axios.get("/api/tag/list").then(response => {
                    this.autocompleteItems = response.data.data.map(a => {
                        return { text: a.name, id: a.id };
                    });
                }).catch(() => console.warn('Oh. Something went wrong'));
            },
            editModal(product){
                this.editmode = true;
                this.form.reset();
                $('#addNew').modal('show');
                this.form.fill(product);
            },
            newModal(){
                this.editmode = false;
                this.form.reset();
                $('#addNew').modal('show');
            },
            createProduct(){
                this.$Progress.start();
  
                this.form.post('api/product')
                .then((data)=>{
                  if(data.data.success){
                    $('#addNew').modal('hide');
  
                    Toast.fire({
                          icon: 'success',
                          title: data.data.message
                      });
                    this.$Progress.finish();
                    this.loadProducts();
  
                  } else {
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
  
                    this.$Progress.failed();
                  }
                })
                .catch(()=>{
  
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
                })
            },
            updateProduct(){
                this.$Progress.start();
                this.form.put('api/product/'+this.form.id)
                .then((response) => {
                    // success
                    $('#addNew').modal('hide');
                    Toast.fire({
                      icon: 'success',
                      title: response.data.message
                    });
                    this.$Progress.finish();
                        //  Fire.$emit('AfterCreate');
  
                    this.loadProducts();
                })
                .catch(() => {
                    this.$Progress.fail();
                });
  
            },
            deletModal(id){
                Swal.fire({
                    title: 'Attention !',
                    text: "you really want to delete this Hotel ??",
                    showCancelButton: true,
                    confirmButtonColor: '#67c23a',
                    cancelButtonColor: '#f56c6c',
                    confirmButtonText: 'Yes, Delete-it ! '
                    }).then((result) => {
  
                        // Send request to the server
                          if (result.value) {
                                this.form.delete('api/product/'+id).then(()=>{
                                        Swal.fire(
                                        'Deleted !',
                                        'Votre fichier a été supprimé.',
                                        'success'
                                        );
                                    // Fire.$emit('AfterCreate');
                                    this.loadProducts();
                                }).catch((data)=> {
                                    Swal.fire("Failed!", data.message, "warning");
                                });
                          }
                    })
            },
          
           handleCurrentChange(cpage) {
                  this.currpage = cpage;
              },
          handleSizeChange(psize) {
                  this.pagesize = psize;
              },
  
  
          },
          mounted() {
              
          },
          created() {
            this.$Progress.start();
            this.loadSpendsByMonth();
            this.loadCalculsSpend();
            this.loadAllSpends();
            this.loadAllDrivers();
            this.$Progress.finish();
          },
          filters: {
            OfferDates(value){
                return moment(String(value)).format('DD-MM-YYYY')
            },
              truncate: function (text, length, suffix) {
                  return text.substring(0, length) + suffix;
              },
          },
          computed: {
            filteredItems() {
              return this.autocompleteItems.filter(i => {
                return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
              });
            },
          },
      }
  </script>
  